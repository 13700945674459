import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetProductDisplaySortComponent } from './product-display-sort.component'
import { NzTableModule } from 'ng-zorro-antd/table'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzTagModule } from 'ng-zorro-antd/tag'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzAvatarModule } from 'ng-zorro-antd/avatar'
import { NzPaginationModule } from 'ng-zorro-antd/pagination'
import { FormsModule } from '@angular/forms'
import { NzModalModule } from 'ng-zorro-antd/modal'
import { NzMessageModule } from 'ng-zorro-antd/message'

@NgModule({
  declarations: [KetProductDisplaySortComponent],
  entryComponents: [KetProductDisplaySortComponent],
  imports: [
    CommonModule,
    FormsModule,
    NzTableModule,
    NzInputModule,
    NzSelectModule,
    NzButtonModule,
    NzModalModule,
    NzMessageModule,
    NzTagModule,
    NzIconModule,
    NzAvatarModule,
    NzPaginationModule,
  ],
  exports: [KetProductDisplaySortComponent],
})
export class KetProductDisplaySortModule {}
