import { merge } from 'lodash'
import { cloneDeep } from 'lodash'
import { Component, Input, OnInit, ViewChild, TemplateRef } from '@angular/core'
import { UtilService } from '@cms/services/util.service'
import { ConfirmpaymentModalComponent } from '@cms/system/modal/confirmpayment/confirmpayment.component'
import { Store } from '@ngrx/store'
import {
  actions_app,
  default_order_search_model,
  IChannelsState,
  IOrdersState,
  NgStore,
  selectors_channels,
  selectors_orders,
} from '@store/ketshopweb-store'
import { actions_orders } from '@store/ketshopweb-store'
import * as moment from 'moment'
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer'
import { SafeAny } from '@ketshopweb/ui/core/types'
import { Router } from '@angular/router'
import { PermissionDirective } from '@cms/dirctives/permission.directive'
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal'
import { check_operation } from './check_operation'
import { CmsOrdersService } from 'ketshopweb-services/orders.service'
import { KetshopwebSDK } from 'ketshopweb-sdk'
import { AuthenService } from '@cms/services/authen.service'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'ket-orders',
  templateUrl: './ket-orders.component.html',
  styleUrls: ['./ket-orders.component.scss'],
})
export class KetOrdersComponent extends NgStore implements OnInit {
  // Inject
  constructor(
    private utilService: UtilService,
    private drawerService: NzDrawerService,
    public store: Store<SafeAny>,
    private router: Router,
    private nzModalService: NzModalService,
    private ServiceOrders: CmsOrdersService,
    private _authen: AuthenService,
  ) {
    super(store)
  }

  permission: PermissionDirective = new PermissionDirective()
  private ketshopwebSDKGeneral = KetshopwebSDK.general

  // Dom
  @ViewChild('filterAll', { static: true }) public filterAll: TemplateRef<SafeAny>

  // Input
  @Input() from: IOrdersState.FromType = 'order'
  @Input() selectionMode = false
  @Input() showFilterAssignee = true
  @Input() selection_name = ''
  @Input() ignoreOption: IOrdersState.IgnoreOption[] = []
  @Input() is_operation = false
  @Input() operation: {
    type: 'status'
    value: any
  } = {
    type: 'status',
    value: '-',
  }
  search_option_default = {
    created_by: '-',
    withdraw_by: '',
    user_ids: [],
    assignee_ids: [],
    is_require_users: false,
    is_full_column: false,
    is_full_details: false,
    product_id: null,
    reserved: false,
    sale_page_id: null,
    promotion_id: null,
    promotion_type: null,
    ignore_marketplace: false,
  }
  @Input() search_option: {
    created_by: string
    withdraw_by: string
    user_ids: any[]
    is_require_users: boolean
    is_full_column: boolean
    is_full_details: boolean
    product_id: number | null
    reserved: boolean // require product_id
    sale_page_id: number | null
    promotion_id: number | null
    promotion_type: IOrdersState.PromotionType | null
    ignore_marketplace: boolean
    assignee_ids?: number[]
  }
  // @Input() height_table: number = 550
  // @Input() is_height_table: boolean = false
  @Input() modalRef: NzModalRef

  // State
  // nzScroll = {
  //   x: '1040px'
  // } as {
  //   x: string
  //   y?: string
  // }

  options = {
    order: true,
    status: true,
    customer: true,
    total: true,
    payment: true,
    delivery: true,
    create_by: true,
  }
  search_model = default_order_search_model()
  search_model_export = default_order_search_model()
  filter_model = default_order_search_model()
  orders: Partial<IOrdersState.OrdersState['list']['']> = {
    data: [],
    count: 0,
  }
  is_loading = false
  list_channel: Partial<IChannelsState.ChannelsState['list_channel']> = {
    all: [],
    website: [],
    marketplace: [],
  }
  channel_tags = [] as string[]
  rangePicker: string[] = []
  filterRangePicker: string[] = []
  filterComp: NzDrawerRef<any>
  order_status_service: Partial<IOrdersState.OrdersState['order_status_img']> = {}
  types_of_payment_service: Partial<IOrdersState.OrdersState['types_of_payment_service']> = {}
  types_of_delivery_service: Partial<IOrdersState.OrdersState['types_of_delivery_service']> = {}
  sell_channel: IChannelsState.SellChannel[] = []
  channels_selector = false
  filter_tags = [] as IOrdersState.FilterTag[]
  filter_assignees = []

  is_select_all = false
  select_list: {
    from: string
    list: {
      data: IOrdersState.OrderSearchItem[]
      apply_data: IOrdersState.OrderSearchItem[]
    }
  } = {
    from: '',
    list: {
      data: [],
      apply_data: [],
    },
  }
  list_staff: Partial<IOrdersState.OrdersState['list_staff']> = []
  date_time_display_render = true
  export_order = false
  authen: any = {
    staff_login: false,
  }
  api_path: any = ''
  access_token: any = ''

  onDestroy() {}

  searchOption(model: IOrdersState.SearchModel) {
    model.user_ids = this.search_option.user_ids || []
    // model.assignee_ids = this.search_option.assignee_ids || []
    model.is_full_column = this.search_option.is_full_column
    model.is_full_details = this.search_option.is_full_details
    model.is_require_users = this.search_option.is_require_users
    model.created_by = this.search_option.created_by
    model.withdraw_by = this.search_option.withdraw_by
    model.product_id = this.search_option.product_id
    model.reserved = this.search_option.reserved
    model.sale_page_id = this.search_option.sale_page_id
    model.promotion_id = this.search_option.promotion_id
    model.promotion_type = this.search_option.promotion_type
    model.ignore_marketplace = this.search_option.ignore_marketplace
  }

  async ngOnInit() {
    this.api_path = environment.api_path
    this.access_token = this._authen.getToken().access_token

    this.search_option = merge(this.search_option_default, this.search_option)
    if (this.selectionMode && this.selection_name) {
      this.store.dispatch(
        actions_orders.InitSelectOrders({
          from: this.selection_name,
        }),
      )
    }
    this.setIgnoreOption()
    this.order_status_service = await this.storeQuery(selectors_orders.order_status_img)
    this.types_of_payment_service = await this.storeQuery(selectors_orders.types_of_payment_service)

    this.types_of_delivery_service = await this.storeQuery(selectors_orders.types_of_delivery_service)
    // this.list_staff = await this.storeQuery(selectors_orders.list_staff);

    // console.log( this.list_staff )
    // SearchOrders
    this.search_model = default_order_search_model(this.search_option.user_ids)
    this.filter_model = default_order_search_model(this.search_option.user_ids)
    this.searchOption(this.search_model)
    this.searchOption(this.search_model_export)
    this.searchOption(this.filter_model)
    this.search_model.assignee_ids = this.search_option['assignee_ids']
    // search orders
    this.onSearch(true)

    /*
      subscribe: model search
    */
    this.subscribe(
      `[${this.from}] search_model_from`,
      selectors_orders.search_model_from({
        from: this.from,
      }),
      async ({ from, model, filter_tags }) => {
        const search_model = cloneDeep(model)
        this.searchOption(search_model)
        let nfilter_tags = cloneDeep(filter_tags)
        // search_option.created_by
        if (this.search_option.created_by && this.search_option.created_by !== '-') {
          nfilter_tags = nfilter_tags.filter((x) => x.type !== 'created_by')
        }
        this.filter_tags = nfilter_tags
        if (this.from == from) {
          this.search_model = cloneDeep(search_model)
          if (this.search_model.startdate && this.search_model.enddate) {
            this.rangePicker[0] = this.search_model.startdate
            this.rangePicker[1] = this.search_model.enddate
            this.filterRangePicker[0] = this.search_model.startdate
            this.filterRangePicker[1] = this.search_model.enddate
            this.reloadDateTimeDisplay()
          }
          this.filter_model = cloneDeep(search_model)
        }
        this.list_staff = await this.storeQuery(selectors_orders.list_staff)

        this.filter_assignees = []
        this.filter_model.assignee_ids.forEach((id) => {
          const chk = this.list_staff.find((x) => x.id == id)
          if (chk) {
            this.filter_assignees.push(chk)
          }
        })
      },
    )

    /*
      subscribe: order data
    */
    this.subscribe(
      `[${this.from}] list_order_from`,
      selectors_orders.list_order_from({
        from: this.from,
      }),
      async ({ from, list }) => {
        if (from == this.from) {
          /*
           [DEV-1958] Get ข้อมูลตามเงื่อนไขแต่ละ Module (From)
           [DEV-1959] เพิ่มข้อมูล เบอร์ติดต่อ

           ข้อมูลตามเงื่อนไขแต่ละ Module
           [1.] order : Order ทั้งหมด https://dev.ketshoptest.com/system/e-commerce/order
           [2.] teamReport : เฉพาะ Staff คนนั้น Sale, Warehouse
            - [2.1] ReportTeamSale : https://dev.ketshoptest.com/system/users/report/21
            - [2.2] ReportTeamWarehouse : https://dev.ketshoptest.com/system/users/report/41
           [3.] customerDetail : เฉพาะลูกค้าคนนั้น https://dev.ketshoptest.com/system/users/customer/16
           [4.] HistoryOrderForExtensions : ChatsAppComponent, SystemComponent
            - [4.1] กรณีเปิดใน ChatsAppComponent : เฉพาะลูกค้าคนนั้น
            - [4.2] กรณีเปิดใน SystemComponent : Order ทั้งหมด 
           [5.] inventoryReport : เฉพาะที่มีสินค้านั้นใน order https://dev.ketshoptest.com/system/product/inventory/report/1465
           [6.] salepageReport : เฉพาะ Sape page นั้น https://dev.ketshoptest.com/system/e-commerce/sale-page-report/7
           [7.] promotionReport : เฉพาะที่ใช้โปรโมชั่น คูปอง, เงื่อนไข, ของแถม 
            - [7.1] คูปอง https://dev.ketshoptest.com/system/e-commerce/promotion/report/coupon/4
            - [7.2] เงื่อนไข https://dev.ketshoptest.com/system/e-commerce/promotion/report/condition/4
            - [7.3] ของแถม https://dev.ketshoptest.com/system/e-commerce/promotion/report/product/1
           [8.] other : สำหรับหน้าอื่น ๆ นอกเหนือจาก module 1-7 ค่ะ จะแสดง order ทั้งหมด แต่ไม่แสดง ปุ่ม clone กับ ปุ่ม confirm payment   
           Module ที่ใช้ SelectionMode
           [9.] OrderMultiSelectOperation : จัดการหลายรายการ ใช้ SelectionMode
           จะแสดง Order ทั้งหมด แต่จะ disabled ไม่ให้เลือก มีเงื่อนไขที่ขึ้นอยู่กับ function
            - [9.1] ปรับเป็นสถานะ ยกเลิก ยกเว้นสถานะ เสร็จสิ้น, ยกเลิก
            - [9.2] ปรับเป็นสถานะ จัดเตรียมสิินค้า ยกเว้นสถานะ จัดส่ง, เสร็จสิ้น, ยกเลิก
            - [9.3] ปรับเป็นสถานะ เสร็จสิ้น เฉพาะสถานะจัดส่งที่เลือกได้
           [10.] ModalPrintOrders : พิิมพ์ใบสั่งซื้อ ใช้ SelectionMode
       */
          this.orders.count = list.count
          const data = cloneDeep(list.data)
          const market_place = await this.storeQuery(selectors_channels.list_channel)
          const select_list = await this.storeQuery(
            selectors_orders.select_list_order_from({
              from: this.selection_name,
            }),
          )
          data.map((obj: IOrdersState.OrderSearchItem) => {
            obj['isClone'] = true
            if (market_place.marketplace.find((x) => x.value == obj.channel)) {
              obj['isClone'] = false
            }
            if (this.selectionMode) {
              obj['selected'] = select_list.list.apply_data.find((x) => x.id == obj.id) ? true : false
              obj['disabled'] = false
            }
          })
          if (this.is_operation) {
            this.checkOperation(data)
          }
          this.orders.data = cloneDeep(data)
          if (this.selectionMode) {
            this.is_select_all = this.orders.data.every((x) => x.selected == true)
          }
        }
      },
    )

    /*
      subscribe: loading state
    */
    this.subscribe(`[${this.from}] is_loading`, selectors_orders.is_loading, (is_loading) => {
      this.is_loading = is_loading
    })

    /*
      subscribe: ช่องทางการขาย
    */
    this.subscribe(`[${this.from}] list_channel`, selectors_channels.list_channel, (list_channel) => {
      this.list_channel = cloneDeep(list_channel)
    })

    /*
      subscribe: ช่องทางที่มีการขาย
    */
    this.subscribe(`[${this.from}] sell_channel`, selectors_channels.sell_channel, (sell_channel) => {
      this.sell_channel = cloneDeep(sell_channel)
    })

    if (this.selection_name && this.selectionMode) {
      /*
      subscribe: data ที่ select
    */
      this.subscribe(
        `[${this.from}[${this.selection_name}] select_list_order_from`,
        selectors_orders.select_list_order_from({
          from: this.selection_name,
        }),
        (select_list) => {
          if (select_list.from == this.selection_name) {
            this.select_list = cloneDeep(select_list)
            if (this.orders.data.length > 0) {
              this.is_select_all = this.orders.data.every((x) => x.selected == true)
            }
          }
        },
      )
    }

    this.authen = {
      ...this.authen,
      ...this._authen.getJwtDecode(),
    }

    await this.ketshopwebSDKGeneral.services.general.admin
      .search({ status: 0 })
      .then((res) => {
        console.log(res, this.authen.id)

        const a = res.userPermission.find((x) => x.user_id == this.authen.id && x.type == 'export_order')
        console.log(a, this.authen.user_role == 'export_order', this.authen.user_role == 'administrator')

        if (a || this.authen.user_role == 'export_order' || this.authen.user_role == 'administrator') {
          this.export_order = true
        }
        console.log(this.export_order)
      })
      .catch((err) => {
        console.log(err)
      })

    /// update from any window
    window['_refreshordersearch'] = async () => {
      const modal = await this.storeQuery(
        selectors_orders.search_model_from({
          from: this.from,
        }),
      )
      this.store.dispatch(
        actions_orders.SearchOrders({
          from: this.from,
          input: cloneDeep(modal.model),
        }),
      )
    }
  }

  async checkInuseActivity(obj: IOrdersState.OrderSearchItem) {
    try {
      const rs = await this.utilService.inuseActivity({ type: 'order', param_id: obj.id })
      return rs
    } catch (err) {
      console.error(err)
    }
  }

  objectValue<T>(v: T) {
    return Object.values(v)
  }

  onCloseFilterTag(tag: IOrdersState.FilterTag) {
    if (tag.onClose) {
      const last_model = tag.onClose()
      // console.log('last_model', last_model)
      this.search_model = cloneDeep(last_model)
      this.onSearch(true)
    }
  }

  // ****************
  // ***** Main *****
  // ****************

  onSelect(order: IOrdersState.OrderSearchItem) {
    if (order.disabled) {
      order.selected = false
      return
    }
    this.store.dispatch(
      actions_orders.SelectOrders({
        from: this.selection_name,
        data: cloneDeep([order]),
        action: order.selected ? 'add' : 'del',
      }),
    )
  }

  onSelectAllThisPage() {
    if (!this.is_select_all) {
      this.store.dispatch(
        actions_orders.SelectOrders({
          from: this.selection_name,
          data: cloneDeep(this.select_list.list.data),
          action: 'del',
        }),
      )
      this.orders.data.forEach((c) => (c.selected = false))
      this.is_select_all = false
    } else {
      this.store.dispatch(
        actions_orders.SelectOrders({
          from: this.selection_name,
          data: cloneDeep(this.orders.data.filter((x) => !x.disabled)),
          action: 'add',
        }),
      )
      this.orders.data.forEach((c) => {
        if (!c.disabled) c.selected = true
      })
      this.is_select_all = true
    }
  }

  onSearch(clear_page = false) {
    if (clear_page) this.search_model.page = 1
    this.store.dispatch(
      actions_orders.SearchOrders({
        from: this.from,
        input: cloneDeep(this.search_model),
      }),
    )
  }

  reloadDateTimeDisplay() {
    this.date_time_display_render = false
    setTimeout(() => {
      this.date_time_display_render = true
    }, 10)
  }

  clearFilter() {
    this.search_model = default_order_search_model(this.search_option.user_ids)
    this.search_model.startdate = moment().subtract(6, 'months').format('YYYY/MM/DD 00:00:00')
    this.search_model.enddate = moment().format('YYYY/MM/DD 23:59:59')

    this.rangePicker[0] = this.search_model.startdate
    this.rangePicker[1] = this.search_model.enddate
    this.searchOption(this.search_model)
    this.reloadDateTimeDisplay()
    this.onSearch(true)
  }

  clearFilterDrawer() {
    this.filter_model = default_order_search_model(this.search_option.user_ids)
    this.filter_model.startdate = moment().subtract(6, 'months').format('YYYY/MM/DD 00:00:00')
    this.filter_model.enddate = moment().format('YYYY/MM/DD 23:59:59')
    this.filterRangePicker[0] = this.filter_model.startdate
    this.filterRangePicker[1] = this.filter_model.enddate
    this.searchOption(this.filter_model)
    this.reloadDateTimeDisplay()
  }

  // [DEV-1964] เปลี่ยน page
  pageChange(page: number) {
    this.search_model.page = page
    this.onSearch()
  }
  // [DEV-1964] เปลี่ยน pageSize 10, 20, 50
  pageSizeChange(pageSize: number) {
    localStorage.setItem(
      'page_size_backend',
      JSON.stringify({
        global: pageSize,
      }),
    )
    this.search_model.perpage = pageSize
    this.onSearch()
  }

  dateRangeChange(is_filter = false) {
    console.log('this.filterRangePicker', JSON.stringify(this.rangePicker, null, 4))
    if (is_filter) {
      if (this.filterRangePicker.length === 2) {
        this.filter_model.startdate = moment(this.filterRangePicker[0]).format('YYYY/MM/DD 00:00:00')
        this.filter_model.enddate = moment(this.filterRangePicker[1]).format('YYYY/MM/DD 23:59:59')
      } else {
        this.filter_model.startdate = moment().subtract(6, 'months').format('YYYY/MM/DD 00:00:00')
        this.filter_model.enddate = moment().format('YYYY/MM/DD 23:59:59')
      }
    } else {
      if (this.rangePicker.length === 2) {
        this.search_model.startdate = moment(this.rangePicker[0]).format('YYYY/MM/DD 00:00:00')
        this.search_model.enddate = moment(this.rangePicker[1]).format('YYYY/MM/DD 23:59:59')
      } else {
        this.search_model.startdate = moment().subtract(6, 'months').format('YYYY/MM/DD 00:00:00')
        this.search_model.enddate = moment().format('YYYY/MM/DD 23:59:59')
      }
      this.reloadDateTimeDisplay()
      this.onSearch(true)
    }
  }

  onFilterApply() {
    this.search_model = cloneDeep(this.filter_model)
    this.onSearch(true)
    if (this.filterComp) {
      try {
        this.filterComp.close(null)
      } catch {}
      this.filterComp = null
    }
    setTimeout(() => {
      try {
        (document.getElementsByClassName('cdk-overlay-backdrop').item(0) as HTMLElement).click()
      } catch {}
    }, 100)
  }

  onFilterCancel() {
    this.filter_model = cloneDeep(this.search_model)
    this.filterRangePicker = cloneDeep(this.rangePicker)
    if (this.filterComp) {
      try {
        this.filterComp.close(null)
      } catch {}
      this.filterComp = null
    }
  }

  onVisible(event: boolean) {
    // console.log('event', event)
    this.channels_selector = event
    // console.log('[1]---', this.search_model)
    // console.log('[2]---', this.filter_model)
    if (!event) {
      this.filter_model = cloneDeep(this.search_model)
    }
  }

  // [DEV-1965] Check มี User กำลังทำการแก้ไขรายการนี้อยู่ หรือไม่ก่อน เปิดรายละเอียด
  async view(order: IOrdersState.OrderSearchItem) {
    if (!this.permission.checkPermission(['administrator', 'webmaster', 'staff', 'sale', 'store', 'branch_manager'])) {
      this.nzModalService.error({
        nzTitle: 'Error',
        nzContent: `Permission denied`,
      })
      return
    }
    // const inuse: any = await this.checkInuseActivity(order)
    // if (!inuse.available) {
    //   this.nzModalService.error({
    //     nzTitle: 'Error',
    //     nzContent: `${inuse.user} กำลังทำการแก้ไขรายการนี้อยู่`,
    //   })
    //   return
    // }
    const url = `${location.origin}/system/e-commerce/order-detail/${order.ordercode}`
    window.open(url, '_blank', 'menubar=no,toolbar=no,location=no,width=1280,height=720')
  }

  // [DEV-1966] Clone Order : ยกเว้น Order Marketplace
  clone(obj: IOrdersState.OrderSearchItem) {
    if (!this.permission.checkPermission(['administrator', 'webmaster', 'staff', 'sale', 'branch_manager'])) {
      this.nzModalService.error({
        nzTitle: 'Error',
        nzContent: `Permission denied`,
      })
      return
    }

    this.router.navigate([`${this.router.url}/create-v2`], { queryParams: { clone: obj.ordercode } })
  }

  // filter
  openFilters() {
    if (this.filter_model.sell_channel && this.filter_model.sell_channel.length) {
      const _sell_ch = cloneDeep(this.filter_model.sell_channel)
      this.filter_model.sell_channel = []
      for (const obj of _sell_ch) {
        for (const s of this.sell_channel) {
          if (s.channel_id == obj.channel_id && s.channel == obj.channel) {
            this.filter_model.sell_channel.push(s)
          }
        }
      }
    }
    this.filterComp = this.drawerService.create({
      nzTitle: null,
      nzContent: this.filterAll,
      nzWidth: '360px',
      nzWrapClassName: 'max-w-filter',
      nzClosable: false,
      nzBodyStyle: { padding: '0px' },
    })
  }

  // Pending Review
  setIgnoreOption() {
    // มีผลกับการแสดง column และการใช้งาน filter
    try {
      for (const obj of this.ignoreOption) {
        this.options[obj] = false
      }
    } catch (error) {}
  }

  // ✅ Done!
  copyLinkOrderPage(ordercode: SafeAny) {
    this.store.dispatch(
      actions_app.Copy({
        action: 'order',
        val: ordercode,
      }),
    )
  }

  // ✅ Done!
  copyTrackingCode(trackingcode: SafeAny) {
    this.store.dispatch(
      actions_app.Copy({
        action: 'text',
        val: trackingcode,
        message: 'คัดลอก Tracking Code สำเร็จ!',
      }),
    )
  }

  // ✅ Done หลักฐานโอนเงิน
  confirmPayment(order: SafeAny) {
    const sub = this.drawerService
      .create<ConfirmpaymentModalComponent, SafeAny>({
        nzTitle: 'รายการแจ้งชำระเงิน',
        nzWidth: '70%',
        nzContent: ConfirmpaymentModalComponent,
        nzContentParams: {
          ordercode: order.ordercode,
          status: order.status,
        },
      })
      .afterClose.subscribe((data: SafeAny) => {
        this.onSearch()
        sub.unsubscribe()
      })
  }

  // ****************
  // *** Addition ***
  // ****************

  convertDate(date_str: string) {
    const date_format = moment(date_str).locale('th').format('DD/MM/YYYY, HH:mm:ss')
    return date_format
  }

  apply() {
    this.store.dispatch(
      actions_orders.ApplySelectOrders({
        from: this.selection_name,
      }),
    )
    this.onClose()
  }

  onClose() {
    try {
      this.modalRef.close(null)
    } catch {}
  }

  checkOperation(orders: IOrdersState.OrderSearchItem[]) {
    check_operation(this.store, this.operation, this.selection_name, this.select_list, orders)
  }

  //   export() {
  //     console.log(this.search_model_export)
  //     this.search_model_export.channel_name = ''
  //     this.search_model_export.created_by = ''
  //     this.search_model_export.paymenttype = ''
  //     this.search_model_export.status = ''
  //     this.search_model_export.startdate = this.search_model.startdate
  //     this.search_model_export.enddate = this.search_model.enddate
  //     this.search_model_export.perpage = -1
  //     this.ServiceOrders.simpleSearch(this.search_model_export)
  //       .toPromise()
  //       .then((res) => {
  //         console.log(res)
  //       })
  //       .catch((err) => {
  //         console.log(err)
  //       })
  //   }
  // }

  exportOrder() {
    $('#order-export').submit()
  }
}

// ********************
// ***** customer *****
// ********************

// bill_lastname
// bill_tel
// bill_name
// name
// tax_no
// username
// customer_note

// ********************
// ***** Order *****
// ********************
// id
// created_at
// created_by
// change_status
// status
// status_timeline
// order_complete
// order_date
// order_expire
// ordercode
// totals
// cod ?? order_type?

// ********************
// ***** Payment *****
// ********************
// confirmpayments
// paymenttype

// ********************
// ***** Delivery *****
// ********************
// delivery
// delivery_transfer_received
// delivery_type
// shippingorder
// shippop
// trackcode
// freeshipping
// freeshipping_manual

// ********************
// ***** Channel *****
// ********************
// from_social
// channel
// channel_name
// channel_social_name

// ********************
// isClone ??
// purchase_id ??
// note ?? Staff?
