import { Injectable } from '@angular/core'

interface PageSizeBackend {
  global: number
}

@Injectable({
  providedIn: 'root',
})
export class Etc {
  isBackEnd() {
    const search = location.href.search(`${window.location.origin}/system`)
    // console.log("isbackend", search);
    if (search == -1) {
      return false
    } else {
      return true
    }
  }

  getCurrentLang() {
    const backend = this.isBackEnd()
    let lang = 'lang1'

    if (backend) {
      lang = localStorage.getItem('lang_backend')
      if (!lang) {
        localStorage.setItem('lang_backend', 'lang1')
      }
      lang = localStorage.getItem('lang_backend')
    } else {
      lang = localStorage.getItem('lang')
      if (!lang) {
        lang = 'lang1'
      }
    }
    return lang
  }

  getPageSize(modelName = 'global'): number {
    const pageSizeBackend = localStorage.getItem('page_size_backend')
    try {
      if (!pageSizeBackend) throw new Error('local storage page_size_backend not exist')
      const model = JSON.parse(pageSizeBackend) as PageSizeBackend
      const size = model[modelName]
      return size || 10
    } catch (err) {
      return 10
    }
  }

  setPageSize(pageSize = 10, modelName = 'global') {
    const pageSizeBackend = localStorage.getItem('page_size_backend')
    try {
      if (!pageSizeBackend) throw new Error('local storage page_size_backend not exist')
      const model = JSON.parse(pageSizeBackend) as PageSizeBackend
      model[modelName] = pageSize
      localStorage.setItem('page_size_backend', JSON.stringify(model))
    } catch (err) {
      const model = {
        [modelName]: pageSize,
      }
      localStorage.setItem('page_size_backend', JSON.stringify(model))
    }
  }

  getFilterChannel(modelName: string): string[] {
    const channelsBackend = localStorage.getItem('channels_backend')
    try {
      if (!channelsBackend) throw new Error('local storage channels_backend not exist')
      const model = JSON.parse(channelsBackend)
      const channels = model[modelName]
      return channels || []
    } catch (err) {
      return []
    }
  }

  setFilterChannel(modelName: string, channels: string[]) {
    const channelsBackend = localStorage.getItem('channels_backend')
    try {
      if (!channelsBackend) throw new Error('local storage channels_backend not exist')
      const model = JSON.parse(channelsBackend)
      model[modelName] = channels
      localStorage.setItem('channels_backend', JSON.stringify(model))
    } catch (err) {
      const model = {
        [modelName]: channels,
      }
      localStorage.setItem('channels_backend', JSON.stringify(model))
    }
  }

  orderstatus_img() {
    return {
      '-1': { img: '/assets/images/core-imgs/icon/order-status5.png', name: 'ยกเลิก' },
      '0': { img: '/assets/images/core-imgs/icon/order-status0.png', name: 'Pending' },
      '1': { img: '/assets/images/core-imgs/icon/order-status1.png', name: 'รอชำระเงิน' },
      '2': { img: '/assets/images/core-imgs/icon/order-status2.png', name: 'ได้รับเงิน' },
      '3': { img: '/assets/images/core-imgs/icon/order-status3.png', name: 'จัดเตรียมสินค้า' },
      '3.5': { img: '/assets/images/core-imgs/icon/order-status3_5.png', name: 'เตรียมจัดส่ง' },
      '4': { img: '/assets/images/core-imgs/icon/order-status4.png', name: 'จัดส่ง' },
      '5': { img: '/assets/images/core-imgs/icon/order-success.png', name: 'เสร็จสิ้น' },
    }
  }

  payment_img() {
    return {
      '-2': { img: '/assets/images/core-imgs/icon/payment-cash.png', name: 'รอชำระเงิน' },
      '-1': { img: '/assets/images/core-imgs/icon/payment-cash.png', name: 'ชำระเงินสด' },
      '1': { img: '/assets/images/core-imgs/icon/payment-cash.png', name: 'ชำระเงินสด' },
      '2': { img: '/assets/images/core-imgs/icon/payment-transfer.png', name: 'โอนผ่านธนาคาร' },
      '3': { img: '/assets/images/core-imgs/icon/payment-credit.png', name: 'เครดิตการ์ด' },
      '5': { img: '/assets/images/core-imgs/icon/payment-qrcode.png', name: 'QR CODE' },
      '4': { img: '/assets/images/core-imgs/icon/payment-cod.png', name: 'เก็บเงินปลายทาง' },
      '6': { img: '/assets/images/core-imgs/icon/payment-crypto.svg', name: 'Crypto currency' },
      '7': { img: '/assets/images/core-imgs/icon/payment-cash.png', name: 'อื่นๆ' },
    }
  }

  delivery_type() {
    return {
      normal: { name: 'ขนส่งทั่วไป' },
      auto: { name: 'ขนส่งอัตโนมัติ' },
      auto_normal: { name: 'ขนส่งอัตโนมัติ' },
      direct: { name: 'ขนส่ง Direct' },
      shippop: { name: 'ขนส่ง Shippop' },
      international: { name: 'ขนส่งต่างประเทศ' },
    }
  }
}
