import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetResponsiveFileDirective } from './responsive-file.directive'

@NgModule({
  declarations: [KetResponsiveFileDirective],
  imports: [CommonModule],
  exports: [KetResponsiveFileDirective],
})
export class KetResponsiveFileModule {}
