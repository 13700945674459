import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import * as jwt_decode from 'jwt-decode'
import * as jsr from 'jsrsasign'
import { environment } from 'src/environments/environment'
import { Store } from '@ngrx/store'
import { SET_CURRENT_USER_CLIENT } from '../store/action'
import { KetshopwebSDK } from 'ketshopweb-sdk'

@Injectable({
  providedIn: 'root',
})
export class AuthenService {
  constructor(
    private _http: HttpClient,
    private store: Store<{ app_store: any }>,
  ) {}

  loginCode(data: any) {
    return this._http.post('/login/code', data).toPromise()
  }

  login(data: any) {
    return this._http.post('/login/web', data).toPromise()
  }

  forgotpassword(data: any) {
    return this._http.post('/login/resetpassword', data).toPromise()
  }

  checkForgotPassword(data: any) {
    return this._http.post('/login/confirmresetpassword', data).toPromise()
  }

  login_frontend(data: any) {
    return this._http.post('/login/front', data).toPromise()
  }

  refresh(refresh_token: string) {
    return this._http.post('/login/token', { refresh_token: refresh_token })
  }

  profile() {
    return this._http.get('/profile').toPromise()
  }

  updateProfile(obj: any) {
    return this._http.put('/profile', obj).toPromise()
  }

  updateSocialLogin(obj: any) {
    return this._http.post('/profile/updateSocialLogin', obj).toPromise()
  }

  updateUsernamePassword(obj: any) {
    return this._http.post('/profile/updateUsernamePassword', obj).toPromise()
  }

  changePassword(obj: any) {
    return this._http.put('/profile/changepassword', obj).toPromise()
  }

  TOKEN_NAME = 'access_token'
  USER = 'user'
  TOKEN_CLIENT_NAME = 'client_access_token'

  getToken(): any {
    // console.log(JSON.parse(localStorage.getItem(this.getKeyName())));
    // console.log(localStorage.getItem(this.getKeyName()));
    return JSON.parse(localStorage.getItem(this.getKeyName()))
  }

  setToken(tokens: any): void {
    let token = ''
    try {
      const user = jwt_decode(tokens.access_token)
      let key = ''
      if (user.user_role == 'customer') {
        key = this.TOKEN_CLIENT_NAME
      } else {
        key = this.TOKEN_NAME
      }
      token = JSON.stringify(tokens)
      if (!token || token == '') {
        localStorage.removeItem(key)
      } else {
        if (!window['seo']) {
          localStorage.setItem(key, token)
        }
      }
    } catch (err) {
      if (!window['seo']) {
        localStorage.setItem(this.TOKEN_NAME, token)
      }
    }
  }

  setTokenClient(tokens: any): void {
    const token = JSON.stringify(tokens)
    // console.log(token);

    if (!token || token == '') {
      localStorage.removeItem(this.TOKEN_CLIENT_NAME)
    } else {
      if (!window['seo']) {
        localStorage.setItem(this.TOKEN_CLIENT_NAME, token)
      }
    }

    this.setClientProfile()
    // this.store.dispatch(actions_cart.Init())
    KetshopwebSDK.cart('customer')
      .action.init_cart()
      .catch((err) => {
        console.log('Init Cart Error =>', err)
      })
  }

  removeToken() {
    try {
      const user = jwt_decode(this.getToken().access_token)
      let key = ''
      if (user.user_role == 'customer') {
        key = 'client_access_token'
      } else {
        key = 'access_token'
      }
      localStorage.removeItem(key)
    } catch (err) {}
  }

  getUserJwtDecode() {
    try {
      const user = jwt_decode(localStorage.getItem('client_access_token'))
      if (user.user_role == 'customer') {
        return user
      } else {
        return false
      }
    } catch (err) {
      return false
    }
  }

  getJwtDecode() {
    try {
      const user = jwt_decode(localStorage.getItem(this.getKeyName()))
      return user
    } catch (err) {
      return false
    }
  }

  getKeyName() {
    let key = ''
    const url = window.location.href
    // console.log('getKeyName '+url.search(`${window.location.origin}/system`))
    if (url.search(`${window.location.origin}/system`) == -1) {
      key = this.TOKEN_CLIENT_NAME
    } else {
      key = this.TOKEN_NAME
    }

    return key
  }

  setClientProfile() {
    try {
      if (this.getKeyName() == 'client_access_token') {
        const user = jwt_decode(this.getToken().access_token)
        this.store.dispatch(new SET_CURRENT_USER_CLIENT(user))
      }
    } catch (e) {}
  }

  resetUserStore() {
    const url = window.location.href
    // console.log('getKeyName '+url.search(`${window.location.origin}/system`))
    if (url.search(`${window.location.origin}/system`) == -1) {
    } else {
      //this.removeToken()
      this.store.dispatch(new SET_CURRENT_USER_CLIENT({}))
    }
  }

  getAuthenToken(): string {
    try {
      const access_token = JSON.parse(localStorage.getItem(this.getKeyName())).access_token
      const decode = jwt_decode(access_token)
      if (!decode.id) {
        localStorage.removeItem(this.getKeyName())
        this.resetUserStore()
        return ''
      }
      return access_token
    } catch (err) {
      localStorage.removeItem(this.getKeyName())
      return ''
    }
  }

  getRefreshToken() {
    try {
      return JSON.parse(localStorage.getItem(this.getKeyName())).refresh_token
    } catch (err) {
      return false
    }
  }

  isTokenExpired(token?: any): boolean {
    // console.log('0');
    try {
      // console.log('1');
      if (!token) token = this.getToken()
      // console.log('2');
      if (!token) return true
      const cert = environment.public_key
      // console.log('3');
      try {
        const get_cert = jsr.KEYUTIL.getKey(cert)
        //console.log(new Date(), jsr.KJUR.jws.IntDate.getNow() );
        // console.log(token.access_token, cert);
        // window['jsr'] = jsr;
        const check = jsr.KJUR.jws.JWS.verifyJWT(token.access_token, get_cert, { alg: ['RS512'] })
        // console.log('4', check);
        if (!check) {
          this.resetUserStore()
          // console.log('5');
          return true
        } else {
          // console.log('6');
          return false
        }
      } catch (err) {
        // console.log('7');
        this.resetUserStore()
        return true
      }
    } catch (err) {
      // console.log('8');
      // console.log(err);
      this.resetUserStore()
      return true
    }
  }
}
