import { IOrders } from 'libs/ketshopweb-services/types/IOrders'

export namespace IPurchaseOrderHtml {
  export enum Layout {
    A4FUll = 0,
    A4Half = 1,
    A46N = 2,
    A46NSku = 3,
    A46N2 = 4,
    A46N2Sku = 5,
    A48Label = 8,
  }

  export type Order = IOrders.GetPurchaseOrderPrintDataResponse[number]

  export type Option = {
    layout: IPurchaseOrderHtml.Layout
    include_note?: boolean
  }
}
