import { Injectable, inject } from '@angular/core'
import { SettingService } from '@cms/services/setting.service'
import { IOption } from '@cms/types'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { from } from 'rxjs'
import { map } from 'rxjs/operators'
import { actions_pages } from './actions'

@Injectable()
export class PagesEffects {
  private actions$ = inject(Actions)
  private settingService = inject(SettingService)
  private store = inject(Store)

  $LoadWizardSetup = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions_pages.LoadWizardSetup),
        map(() =>
          from(this.settingService.searchOption({ pop_key: ['wizard_setup'] })).subscribe({
            next: (res: IOption[]) => {
              for (const r of res) {
                if (r.pop_key === 'wizard_setup') {
                  this.store.dispatch(actions_pages.SetWizardSetup({ wizard_setup: r.pop_value == '1' }))
                }
              }
            },
            error: (err) => {
              console.log('error', err)
            },
          }),
        ),
      ),
    { dispatch: false },
  )

  $ToggleWizardSetup = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions_pages.ToggleWizardSetup),
        map((action) =>
          from(
            this.settingService.saveOption([{ pop_key: 'wizard_setup', pop_value: action.wizard_setup ? '1' : '0' }]),
          ).subscribe({
            next: () => {
              this.store.dispatch(actions_pages.LoadWizardSetup())
            },
            error: (err) => {
              console.log('error', err)
            },
          }),
        ),
      ),
    { dispatch: false },
  )
}
