import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { IExtension } from './types/IExtension'

@Injectable({
  providedIn: 'root',
})
export class CmsExtensionService {
  private http = inject(HttpClient)

  list_extension() {
    return this.http.get<IExtension.Extension[]>('/services/ketshopweb-general/extension/list_extension')
  }

  get_dobybot_connection() {
    return this.http.get<boolean>('/services/ketshopweb-general/extension/dobybot/connection')
  }

  get_dobybot() {
    return this.http.get<IExtension.Dobybot>('/services/ketshopweb-general/extension/dobybot')
  }

  save_dobybot(data: IExtension.Dobybot['config']) {
    return this.http.put<IExtension.Dobybot>('/services/ketshopweb-general/extension/dobybot', data)
  }

  check_zwiz_status() {
    return this.http.get<IExtension.IZwiz.CheckStatusResponse>(
      '/services/ketshopweb-general/extension/zwiz/check_status',
    )
  }

  add_zwiz_token(data: { pageurl: string }) {
    return this.http.post<any>('/services/ketshopweb-general/extension/zwiz/add_token', data)
  }

  partial_update(
    id: number,
    data: {
      zwiz_connect?: number
    },
  ) {
    return this.http.patch<IExtension.IZwiz.PageData['chat_channel']>(
      `/services/ketshopweb-general/extension/zwiz/chat_channels/${id}`,
      data,
    )
  }

  connect_channels(data: { pageid: string; provider_id: string }) {
    return this.http.put<any>('/services/ketshopweb-general/extension/zwiz/connect_channels', data)
  }

  disconnect_channels(data: { provider_id: string }) {
    return this.http.put<any>('/services/ketshopweb-general/extension/zwiz/disconnect_channels', data)
  }
}
