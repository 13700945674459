import { qr_point_print_label_a4 } from './qr_point_print_label_a4'
import { order_html } from './order_html'
import { product_print_label_a10 } from './product_print_label_a10'
import { product_export_label_a4 } from './product_export_label_a4'
import { product_print_label_a4 } from './product_print_label_a4'
import { warehouse_transfer_full_a4_html } from './warehouse_transfer_full_a4'
import { warehouse_transfer_landscape_a4_html } from './warehouse_transfer_landscape_a4'
import { product_export_label_a10 } from './product_export_label_a10'
import { purchase_order } from './purchase_order/purchase_order'
export { IPurchaseOrderHtml } from './purchase_order/types'
export { IProductPrintLabel } from './product_print_label_a4'
export { IQrPointPrintLabel } from './qr_point_print_label_a4'
export const html_ts = {
  product_print_label_a4,
  product_export_label_a4,
  product_print_label_a10,
  product_export_label_a10,
  order_html,
  warehouse_transfer_full_a4_html,
  warehouse_transfer_landscape_a4_html,
  purchase_order,
  qr_point_print_label_a4,
}
