import { createAction, props } from '@ngrx/store'
import { IWarehouseState } from './models'
import { HttpErrorResponse } from '@angular/common/http'
import { IWarehouse } from 'ketshopweb-services'

export const actions_warehouse = {
  GetWarehouse: createAction('[CMS][Warehouse/API] GetWarehouse'),
  Loadding: createAction('[CMS][Warehouse] Loadding', props<{ loadding: boolean }>()),
  GetWarehouseSuccess: createAction(
    '[CMS][Warehouse] GetWarehouseSuccess',
    props<{ list: IWarehouseState.WarehouseState['list'] }>(),
  ),
  RemoveWarehouse: createAction('[CMS][Warehouse/API] RemoveWarehouse', props<{ warehouse_id: number }>()),
  CreateWarehouse: createAction(
    '[CMS][Warehouse/API] CreateWarehouse',
    props<{
      account_based_on?: IWarehouseState.WarehouseAccountBasedOn
      warehouse_type?: IWarehouseState.WarehouseType
      name: string
      full_address: string
      branch_code?: string
      org_name?: string
      tax_no?: string
      org_phone?: string
      pin?: string
      bill_prefix?: string
      tax_request_qr: boolean
      subdistrict: string
      district: string
      province: string
      zipcode: string
      tel: string
    }>(),
  ),
  UpdateWarehouse: createAction(
    '[CMS][Warehouse/API] UpdateWarehouse',
    props<{
      account_based_on?: IWarehouseState.WarehouseAccountBasedOn
      warehouse_type?: IWarehouseState.WarehouseType
      warehouse_id: number
      name: string
      full_address: string
      branch_code?: string
      org_name?: string
      tax_no?: string
      org_phone?: string
      pin?: string
      bill_prefix?: string
      tax_request_qr: boolean
      subdistrict: string
      district: string
      province: string
      zipcode: string
      tel: string
    }>(),
  ),
  GetWarehouseInfo: createAction('[CMS][Warehouse/API] GetWarehouseInfo'),
  UpdateWarehouseInfo: createAction(
    '[CMS][Warehouse/API] UpdateWarehouseInfo',
    props<{ info: Partial<IWarehouseState.WarehouseState['info']> }>(),
  ),
  GetWarehouseInfoSuccess: createAction(
    '[CMS][Warehouse] GetWarehouseInfoSuccess',
    props<{ info: IWarehouseState.WarehouseState['info'] }>(),
  ),
  WarehouseTransfer: {
    Get: createAction(
      '[CMS][Warehouse/API] GetWarehouseTransfer',
      props<{
        model: IWarehouseState.ListWarehouseTransferModel
      }>(),
    ),
    SetSearchModel: createAction(
      '[CMS][Warehouse] GetWarehouseTransfer',
      props<{
        model: IWarehouseState.ListWarehouseTransferModel
      }>(),
    ),
    Success: createAction(
      '[CMS][Warehouse] GetWarehouseTransferSuccess',
      props<{ list: IWarehouseState.WarehouseState['warehouse_transfer']['list'] }>(),
    ),
    Loadding: createAction('[CMS][Warehouse] GetWarehouseTransferLoadding', props<{ loadding: boolean }>()),
    Create: createAction(
      '[CMS][Warehouse/API] CreateWarehouseTransfer',
      props<{
        state: 'print' | null
        model: IWarehouseState.CreateWarehouseTransfer
      }>(),
    ),
    CreateSuccess: createAction(
      '[CMS][Warehouse/API] CreateWarehouseTransferSuccess',
      props<{ detail: IWarehouse.WarehouseTransferDetail }>(),
    ),
    CreateError: createAction(
      '[CMS][Warehouse/API] CreateWarehouseTransferError',
      props<{ error: HttpErrorResponse | any }>(),
    ),
  },
  WarehouseProducts: {
    Selected: {
      Init: createAction(
        '[CMS][WarehouseProducts] InitSelectProducts',
        props<{
          from: string
        }>(),
      ),
      Select: createAction(
        '[CMS][WarehouseProducts] SelectProducts',
        props<{
          action: 'add' | 'del'
          from: string
          data: IWarehouseState.WarehouseProductItem[]
        }>(),
      ),
      Apply: createAction(
        '[CMS][WarehouseProducts] ApplySelectProducts',
        props<{
          from: string
        }>(),
      ),
      Clear: createAction(
        '[CMS][WarehouseProducts] ClearSelectProducts',
        props<{
          from: string
        }>(),
      ),
    },
    SetLoading: createAction<string, { val: boolean; from: string }>(
      '[CMS][WarehouseProducts] SetLoading',
      props<{
        from: string
        val: boolean
      }>(),
    ),
    SetSearchModel: createAction(
      '[CMS][WarehouseProducts] SetSearchModel',
      props<{
        from: string
        input: IWarehouseState.WarehouseProductSearchModel
      }>(),
    ),
    SearchProducts: createAction(
      '[CMS][WarehouseProducts/Api] SearchProducts',
      props<{
        from: string
        input: IWarehouseState.WarehouseProductSearchModel
      }>(),
    ),
    SetProducts: createAction(
      '[CMS][WarehouseProducts] SetProducts',
      props<{
        from: string
        data: {
          data: IWarehouseState.WarehouseProductItem[]
          count: number
        }
      }>(),
    ),
  },
  WarehouseInventory: {
    SetLoading: createAction(
      '[CMS][WarehouseInventory] SetLoading',
      props<{
        val: boolean
      }>(),
    ),
    Search: createAction(
      '[CMS][WarehouseInventory/Api] Search',
      props<{
        input: IWarehouseState.IInventorySearchModel
      }>(),
    ),
    Set: createAction(
      '[CMS][WarehouseInventory] SET',
      props<{
        data: IWarehouseState.IInventoryItem[]
        count: number
      }>(),
    ),
    SetSearchModel: createAction(
      '[CMS][WarehouseInventory] SetSearchModel',
      props<{
        input: IWarehouseState.IInventorySearchModel
      }>(),
    ),
  },
}
