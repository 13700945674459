import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

import { LangGuard } from './lang.guard'
import { DeliveryViewComponent } from './system/delivery-view/delivery-view.component'
import { AuthenGuard } from './authen.guard'

const routes: Routes = [
  {
    path: 'system/login',
    canActivate: [LangGuard],
    loadChildren: () => import('./system/modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'system/forgotpassword',
    loadChildren: () =>
      import('./system/modules/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule),
  },
  {
    path: 'system/e-commerce/view/delivery/:trackcode',
    canActivate: [AuthenGuard],
    component: DeliveryViewComponent,
  },
  {
    path: 'system/e-commerce/order-detail',
    loadChildren: () => import('./system/order/order-detail/order-detail.module').then((m) => m.OrderDetailModule),
  },
  {
    path: 'system/messenger',
    redirectTo: 'system/chats-app',
    pathMatch: 'full',
  },
  {
    path: 'system/line-chat',
    redirectTo: 'system/chats-app',
    pathMatch: 'full',
  },
  {
    path: 'system/chats-app',
    canActivate: [AuthenGuard],
    loadChildren: () => import('./system/chats-app/chats-app.module').then((m) => m.ChatsAppModule),
  },
  {
    path: 'system',
    loadChildren: () => import('./system/system.module').then((m) => m.SystemModule),
  },
  {
    path: 'confirmregister',
    loadChildren: () =>
      import('./front/modules/confirmregister/confirmregister.module').then((m) => m.ConfirmregisterModule),
  },
  {
    path: 'forgotpassword',
    loadChildren: () =>
      import('./front/modules/forgotpassword/forgotpassword.module').then((m) => m.ForgotpasswordModule),
  },
  {
    path: 'orderlink',
    loadChildren: () => import('./pages/order-link-page/order-link-page.module').then((m) => m.OrderLinkPageModule),
  },
  // {
  //   path: 'orderlink-old',
  //   loadChildren: () => import('./pages/order-link-page-old/order-link-page.module').then((m) => m.OrderLinkPageModule),
  // },
  {
    path: 'crypto-redirect',
    loadChildren: () =>
      import('./pages/page-credit-redirect/credit-redirect.module').then((x) => x.CreditRedirectModule),
  },
  {
    path: 'credit-redirect',
    loadChildren: () =>
      import('./pages/page-credit-redirect/credit-redirect.module').then((x) => x.CreditRedirectModule),
  },
  {
    path: 'sale-page',
    loadChildren: () => import('./pages/sale-page/render-sale-page.module').then((m) => m.RenderSalePageModule),
  },
  {
    path: 'order-page',
    loadChildren: () => import('./pages/order-page/order-page.module').then((m) => m.OrderPageModule),
  },
  {
    path: 'tax-request',
    loadChildren: () => import('./pages/page-tax-request/page-tax-request.module').then((m) => m.PageTaxRequestModule),
  },
  {
    path: '',
    canActivate: [LangGuard],
    loadChildren: () => import('./render/render-frontend.module').then((m) => m.RenderFrontendModule),
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
