import { createAction, props } from '@ngrx/store'

export const actions_pages = {
  SetPageVersion: createAction(
    '[CMS][Pages] SetPageVersion',
    props<{
      data: any
    }>(),
  ),
  SetCurrentRef: createAction(
    '[CMS][Pages] SetCurrentRef',
    props<{
      data: any
    }>(),
  ),
  ShowHistoryVersion: createAction(
    '[CMS][Pages] ShowHistoryVersion',
    props<{
      data: any
    }>(),
  ),
  LoadWizardSetup: createAction('[CMS][Pages] LoadWizardSetup'),
  SetWizardSetup: createAction(
    '[CMS][Pages] SetWizardSetup',
    props<{
      wizard_setup: boolean
    }>(),
  ),
  ToggleWizardSetup: createAction(
    '[CMS][Pages] ToggleWizardSetup',
    props<{
      wizard_setup: boolean
    }>(),
  ),
}
