import { environment } from 'src/environments/environment'
import Hashids from 'hashids/dist/hashids'

export function encode_ids(ids: string[]) {
  let public_key = environment.public_key
  public_key = public_key.replace(/(\r\n|\n|\r)/gm, '')
  const hashids = new Hashids(public_key)
  const code = hashids.encode(ids)
  return code
}
