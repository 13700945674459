import { Component, OnInit, Input } from '@angular/core'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzMessageService } from 'ng-zorro-antd/message'
import { UtilService } from '@cms/services/util.service'
import { PromotionService } from '@cms/services/promotion.service'
import { cloneDeep } from 'lodash'
import * as moment from 'moment'
import { FeatureService } from '@cms/services/feature.service'
import { ICmsCustomer, KetshopwebCustomerService } from '@cms/services/ketshopweb-customer.service'
import { IKetshopwebPromotionService } from '@cms/services/ketshopweb-promotion.service'

@Component({
  selector: 'app-modal-add-edit-discount-condition',
  templateUrl: './modal-add-edit-discount-condition.component.html',
  styleUrls: ['./modal-add-edit-discount-condition.component.css'],
})
export class ModalAddEditDiscountConditionComponent implements OnInit {
  @Input() data: any
  constructor(
    private modal: NzModalRef,
    private message: NzMessageService,
    private Service_Util: UtilService,
    private Service_Promotion: PromotionService,
    private Service_Feature: FeatureService,
    private Service_Ketshopweb_Customer: KetshopwebCustomerService, // private settingService: SettingService,
  ) {}
  rangePicker: any = []
  currentdatetime: any
  message_duration = 4000
  model: any = {
    max_use: 0,
    use: 0,
    is_use_total: false,
    total_type: 'value',
    status: 1,
    title: '',
    total: 0,
    freeshipping: false,
    is_use_max_discount: false,
    max_discount: 0,
    startdate: '',
    enddate: '',
    discount: 1,
    discount_type: 'normal',
  }
  feature_shipping_inter = false
  promo_type = 'condition'
  promo_id = 0
  member_level_select = [0, 1]
  @Input() member_levels = [] as ICmsCustomer.MemberLevelCms[]
  @Input() apply_member_level = [] as IKetshopwebPromotionService.PromoApplyMemberLevelEntity[]
  @Input() member_level_status = false

  async ngOnInit() {
    // const pageOptions: any = await this.settingService.searchOption({ pop_key: ['member_level_status'] })
    // console.log('pageOptions', pageOptions)
    // for (const pageOption of pageOptions) {
    //   if (pageOption.pop_key == 'member_level_status') {
    //     this.member_level_status = Boolean(Number(pageOption.pop_value))
    //   }
    // }
    this.feature_shipping_inter = await this.Service_Feature.getFeature('SHIPPING_INTER')
    if (this.data.state == 'create' && !this.data.hasOwnProperty('model')) {
      this.currentdatetime = await this.Service_Util.getDateTime()
      this.rangePicker = [
        moment(this.currentdatetime.date).format('YYYY-MM-DD'),
        moment(this.currentdatetime.date).add(1, 'month').format('YYYY-MM-DD'),
      ]
      this.model.startdate = moment(this.rangePicker[0]).format('YYYY-MM-DD')
      this.model.enddate = moment(this.rangePicker[1]).format('YYYY-MM-DD')
    } else {
      this.model = cloneDeep(this.data.model)
      this.rangePicker = [this.model.startdate, this.model.enddate]
      if (!this.model.hasOwnProperty('max_use')) this.model.max_use = 0
      if (!this.model.hasOwnProperty('total_type')) this.model.total_type = 'value'
      if (this.data.state == 'create') {
        this.model.use = 0
      }
      if (this.model.promo_apply_member_level) {
        this.applyMemberLevel(this.model.promo_apply_member_level)
      }
    }

    // member_level
    this.memberLevelList()
    this.applyMemberLevel(this.apply_member_level)
  }

  applyMemberLevel(inputs: IKetshopwebPromotionService.PromoApplyMemberLevelEntity[]) {
    if (Array.isArray(inputs) && inputs.length != 0) {
      this.member_level_select = inputs.flatMap((x) => (x.is_active ? [x.member_level_id] : []))
    }
  }

  discountTypeChange() {
    if (this.model.discount_type == 'normal' && this.model.is_use_max_discount) {
      this.model.is_use_max_discount = false
      this.model.max_discount = 0
    }
  }

  useMaxDiscountChange() {
    if (!this.model.is_use_max_discount) {
      this.model.max_discount = 0
    }
  }

  async memberLevelList() {
    if (Array.isArray(this.member_levels) && this.member_levels.length != 0) {
      return
    }

    try {
      const memberLevelFromService = await this.Service_Ketshopweb_Customer.listLevelTitle()

      this.member_levels = this.Service_Ketshopweb_Customer.mapMemberLevelEntityToCms(memberLevelFromService)
    } catch (err) {
      console.error('memberLevelList', err)
    }
  }

  dateRangeChange() {
    try {
      this.model.startdate = moment(this.rangePicker[0]).format('YYYY-MM-DD')
      this.model.enddate = moment(this.rangePicker[1]).format('YYYY-MM-DD')
    } catch (e) {
      console.log(e)
      this.model.startdate = ''
      this.model.enddate = ''
    }
  }

  validate() {
    if (!this.model.title) {
      this.message.error('โปรดกรอกชื่อส่วนลด', { nzDuration: this.message_duration })
      return false
    } else if (this.model.startdate == '' || this.model.enddate == '') {
      this.message.error('โปรดเลือก ระยะเวลาให้ถูกต้อง', { nzDuration: this.message_duration })
      return false
    } else if (Number(this.model.discount) < 0) {
      this.message.error('มูลค่าต้องมากกว่า 0', { nzDuration: this.message_duration })
      return false
    } else if (Number(this.model.discount) > 100 && this.model.discount_type == 'percent') {
      this.message.error('มูลค่าต้องไม่เกิน 100 %', { nzDuration: this.message_duration })
      return false
    } else if (Number(this.model.max_discount) == 0 && this.model.is_use_max_discount) {
      this.message.error('ส่วนลดสูงสุดต้องมากกว่า 0', { nzDuration: this.message_duration })
      return false
    }
    return true
  }

  async ok() {
    console.log(this.model)

    if (!this.validate()) {
      return false
    }
    try {
      if (this.data.state == 'create') {
        const result = await this.Service_Promotion.createDiscountCondition(this.model)
        if (result.hasOwnProperty('id')) {
          this.promo_id = result['id']
        }
        this.modal.close(true)
      } else {
        // console.log(this.model);
        const result = await this.Service_Promotion.updateDiscountCondition(this.model)
        if (result.hasOwnProperty('id')) {
          this.promo_id = result['id']
        } else {
          this.promo_id = this.model.id
        }
        this.modal.close(true)
      }
    } catch (err) {
      console.error('ok?', err)
      this.message.error(err.error.message, { nzDuration: 5000 })
    }
  }

  debug() {
    console.log('data: ', this.data)
    console.log('model: ', this.model)
  }
}
