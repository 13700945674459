import { HttpClient } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'

export namespace IChannels {
  export type IChannelType = '' | 'messenger' | 'line_chat' | 'line_bot'

  export interface Channel {
    id: number
    name: string
    picture: string
    provider_id: string
    provider_name: string
    token_date: string
    type: IChannelType
    unread_count: number
    created_at: string
    updated_at: string
    chat_version: string
    old_provider_id: string
    quota_limit: number
    quota_usage: number
    security_issue: number
  }

  export interface LocalSetting {
    channel_id: number
    sound: boolean
  }
}

@Injectable({
  providedIn: 'root',
})
export class KetOmniChatService {
  private http = inject(HttpClient)

  getCustomerUsers({ chat_id, provider_id }) {
    return this.http.get(
      `/services/omni-chat/customers/users?chat_id=${chat_id}&provider_id=${provider_id}`,
    ) as Observable<{
      data: { id: number; name: string; lastname: string }[]
    }>
  }
  getChannels() {
    return this.http.post('/services/omni-chat/channels/list', {}) as Observable<{
      count: number
      data: IChannels.Channel[]
    }>
  }
}
