/* eslint-disable @typescript-eslint/no-explicit-any */
import { cloneDeep } from 'lodash'
import { Observable } from 'rxjs'
// import * as moment from 'moment'
import { qr_to_base64 } from './qr_to_base64'
import { IMemberPoint } from 'ketshopweb-sdk/src/promotion/types'

declare const JSZip: {
  new (): {
    file(name: string, data: any): void
    generateAsync(option: { type: 'blob' }): Promise<Blob>
  }
}

function loadJSZip() {
  return new Promise((resolve) => {
    const lib = 'https://cdnjs.cloudflare.com/ajax/libs/jszip/3.10.1/jszip.min.js'
    // if not exist load lib
    if (!document.querySelector(`script[src="${lib}"]`)) {
      const script = document.createElement('script')
      script.src = lib
      document.head.appendChild(script)
      script.onload = () => {
        resolve(1)
      }
    } else {
      resolve(1)
    }
  })
}

export function qr_point_export_label(type: 'qr', qr_point: IMemberPoint.DetailsQrPointInput) {
  const images = [] as Uint8Array[]
  loadJSZip().then(() => {
    new Observable<IMemberPoint.QrPointsDetailsEntity[]>((ob) => {
      let req_data: IMemberPoint.QrPointsDetailsEntity[] = []
      for (const qr of qr_point.details) {
        if (req_data.length < 36) {
          req_data.push(qr)
        } else {
          ob.next(cloneDeep(req_data))
          req_data = []
          req_data.push(qr)
        }
      }
      ob.next(cloneDeep(req_data))
      setTimeout(() => {
        ob.complete()
      }, 100)
    }).subscribe({
      next: async (req_data) => {
        for (const item of req_data) {
          if (type == 'qr') {
            const qrcode_url = (await qr_to_base64(qr_point.id + ',' + item.code, {
              scale: 4,
              width: 150,
              margin: 1,
              type: 'image/png',
            })) as string

            // png base64 url to binary
            const binary = atob(qrcode_url.split(',')[1])
            const array = []
            for (let i = 0; i < binary.length; i++) {
              array.push(binary.charCodeAt(i))
            }
            const bytes = new Uint8Array(array)
            images.push(bytes)
          }
        }
      },
      complete: () => {
        if (images.length === 1) {
          // มีรูปเพียงรูปเดียว ไม่ต้อง Zip
          const imgBlob = new Blob([images[0]], { type: 'image/png' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(imgBlob)
          link.download = `${qr_point.name}.png`
          link.click()
        } else {
          const zip = new JSZip()
          images.forEach((img, index) => {
            zip.file(`${qr_point.name}_${index}.png`, img)
          })
          zip.generateAsync({ type: 'blob' }).then((content) => {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(content)
            link.download = `${qr_point.name}.zip`
            link.click()
          })
        }
      },
    })
  })
}
