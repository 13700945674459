import { Component, OnInit, Input } from '@angular/core'
import { ProductsService } from '@cms/services/products.service'
import { Etc } from '@cms/util/etc'
import { NzModalRef } from 'ng-zorro-antd/modal'
declare let alasql
@Component({
  selector: 'app-modal-search-by-tag',
  templateUrl: './modal-search-by-tag.component.html',
  styleUrls: ['./modal-search-by-tag.component.css'],
})
export class ModalSearchByTagComponent implements OnInit {
  @Input() data: any = []
  allTag: any = []
  selected: any = []
  model: any = { page: 1, perpage: 99999999, search: '' }
  tag_display: any = []
  count = 0
  current_lang = 'lang1'

  constructor(
    private Service_Product: ProductsService,
    private modal: NzModalRef,
    private _etc: Etc,
  ) {}

  async ngOnInit() {
    //console.log(this.data)
    this.current_lang = this._etc.getCurrentLang()
    console.log(this.current_lang)
    this.allTag = await this.Service_Product.listProductTag()
    this.selected = [...this.data.tags]
    console.log(this.selected)
    this.allTag.forEach((data) => {
      data.active = false
      this.selected.forEach((tag) => {
        if (tag == data.id) {
          data.active = true
        }
      })
    })
    this.search()
  }

  async search(clearpage = false) {
    if (clearpage) {
      this.model.page = 1
    }
    const lang = this.current_lang
    const offset_page = Number(this.model.perpage) * (Number(this.model.page) - 1)
    let sql = 'SELECT * FROM ? WHERE 1=1 '
    let sql_count = 'SELECT COUNT(*) as total FROM ? WHERE 1=1'
    const bindparam = [this.allTag]

    if (this.model.search !== '') {
      sql += `AND (${lang} LIKE ?  ) `
      sql_count += `AND (${lang} LIKE ?  ) `
      bindparam.push(`%${this.model.search}%`)
    }

    sql += 'ORDER BY sort  IS NULL,sort ,id DESC '
    sql += `LIMIT ${this.model.perpage} OFFSET ${offset_page}`

    this.tag_display = await alasql(sql, bindparam)

    const rs = await alasql(sql_count, bindparam)
    this.count = rs[0].total
    if (this.tag_display.length == 0 && this.model.page > 1) {
      this.model.page = this.model.page - 1
      this.search()
      return
    }
  }

  selectedTag(obj) {
    if (obj.active) {
      for (let i = 0; i < this.selected.length; i++) {
        if (this.selected[i] == obj.id) {
          this.selected.splice(i, 1)
        }
      }
    } else {
      this.selected.push(obj.id)
    }
    obj.active = !obj.active
  }

  ok() {
    console.log(this.selected)
    this.modal.close(this.selected)
  }
}
