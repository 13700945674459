import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetSanitizeHtmlPipe } from './sanitize-html.pipe'

@NgModule({
  declarations: [KetSanitizeHtmlPipe],
  imports: [CommonModule],
  exports: [KetSanitizeHtmlPipe],
})
export class KetSanitizeHtmlModule {}
