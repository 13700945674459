import { createAction, props } from '@ngrx/store'
import { ITeamsState } from './models'

interface GetReportById {
  startdate: string
  enddate: string
  seller_id: number
  created_by: string
}
export const actions_teams = {
  GetReportById: createAction<string, { model: GetReportById }>(
    '[CMS][Teams] GetReportById',
    props<{
      model: GetReportById
    }>(),
  ),
  SetReport: createAction<string, { data: ITeamsState.Report }>(
    '[CMS][Teams] SetReport',
    props<{
      data: ITeamsState.Report
    }>(),
  ),
  SetReportProvince: createAction<string, { data: ITeamsState.Province[] }>(
    '[CMS][Teams] SetReportProvince',
    props<{
      data: ITeamsState.Province[]
    }>(),
  ),
  SetReportBestSeller: createAction<string, { data: ITeamsState.BestSeller[] }>(
    '[CMS][Teams] SetReportBestSeller',
    props<{
      data: ITeamsState.BestSeller[]
    }>(),
  ),
}
