import { Injectable, inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map } from 'rxjs/operators'
import { actions_client_user } from './actions'

@Injectable()
export class ClientUuserEffects {
  // inject
  private actions$ = inject(Actions)

  // $LogCounter = createEffect(() =>
  // this.actions$.pipe(
  //     ofType(actions_client_user.Increment),
  //     map((action) => {
  //         console.log('[ClientUuserEffects]', 'Increment');
  //     })
  // )
  // ,{ dispatch: false });
}
