import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'app-modal-transfer-slip',
  templateUrl: './modal-transfer-slip.component.html',
  styleUrls: ['./modal-transfer-slip.component.css'],
})
export class ModalTransferSlipComponent implements OnInit {
  page_name = 'confirm-payment'
  @Input() data: any = []

  constructor() {}

  ngOnInit() {
    console.log('transfer-slip')
    console.log(this.data)
    console.log(this.data.fromPage)
  }
}
