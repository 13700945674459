import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
@Injectable({
  providedIn: 'root',
})
export class ShopeeService {
  constructor(private _http: HttpClient) {}

  shippingDocument(data: any): Promise<any> {
    return this._http.post('/services/shopee/order/shippingDocument', data).toPromise() as Promise<any>
  }

  getShippingParameter(data: any): Promise<any> {
    // return new Promise((r,j)=>{
    //   r({
    //     "info_needed": {
    //         "dropoff": [],
    //         "pickup": [
    //             "address_id",
    //             "pickup_time_id"
    //         ]
    //     },
    //     "dropoff": {
    //         "branch_list": null,
    //         "slug_list": null
    //     },
    //     "pickup": {
    //         "address_list": [
    //             {
    //                 "address_id": 200001266,
    //                 "region": "TH",
    //                 "state": "จังหวัดนครปฐม",
    //                 "city": "อำเภอพุทธมณฑล",
    //                 "district": "",
    //                 "town": "",
    //                 "address": "73/107-110 หมู่5 (ข้างบีควิค) ต.ศาลายา อ.พุทธมณฑล จ.นครปฐม 73170\nโทร 0917471459",
    //                 "zipcode": "73170",
    //                 "address_flag": [],
    //                 "time_slot_list": [
    //                     {
    //                         "date": 1698915600,
    //                         "pickup_time_id": "1698915600"
    //                     },
    //                     {
    //                         "date": 1699002000,
    //                         "pickup_time_id": "1699002000"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "address_id": 29784762,
    //                 "region": "TH",
    //                 "state": "จังหวัดนครปฐม",
    //                 "city": "อำเภอพุทธมณฑล",
    //                 "district": "",
    //                 "town": "",
    //                 "address": "999 มหิดล ประตู6  มหาวิทยาลัยมหิดลศาลายา ตำบลศาลายา ",
    //                 "zipcode": "73170",
    //                 "address_flag": [],
    //                 "time_slot_list": [
    //                     {
    //                         "date": 1698915600,
    //                         "pickup_time_id": "1698915600"
    //                     },
    //                     {
    //                         "date": 1699002000,
    //                         "pickup_time_id": "1699002000"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "address_id": 28784350,
    //                 "region": "TH",
    //                 "state": "จังหวัดปทุมธานี",
    //                 "city": "อำเภอสามโคก",
    //                 "district": "",
    //                 "town": "",
    //                 "address": "Extracare logistics เลขที่ 24/4 ซอยเทศบาล 3หมู่ 2 ต.สามโคก",
    //                 "zipcode": "12160",
    //                 "address_flag": [
    //                     "default_address",
    //                     "pickup_address",
    //                     "return_address"
    //                 ],
    //                 "time_slot_list": [
    //                     {
    //                         "date": 1698915600,
    //                         "pickup_time_id": "1698915600"
    //                     },
    //                     {
    //                         "date": 1699002000,
    //                         "pickup_time_id": "1699002000"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "address_id": 28602410,
    //                 "region": "TH",
    //                 "state": "จังหวัดสมุทรปราการ",
    //                 "city": "อำเภอบางพลี",
    //                 "district": "",
    //                 "town": "",
    //                 "address": "14/5 หมู่ 5 ซอยกิ่งแก้ว 42 ( ร้านค้าขวามือรั้วสีฟ้า)",
    //                 "zipcode": "10540",
    //                 "address_flag": [],
    //                 "time_slot_list": [
    //                     {
    //                         "date": 1698915600,
    //                         "pickup_time_id": "1698915600"
    //                     },
    //                     {
    //                         "date": 1699002000,
    //                         "pickup_time_id": "1699002000"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "address_id": 14134112,
    //                 "region": "TH",
    //                 "state": "จังหวัดสมุทรปราการ",
    //                 "city": "อำเภอบางพลี",
    //                 "district": "",
    //                 "town": "",
    //                 "address": "39 หมู่ 6 ถ.บางนาตราด ต.บางแก้ว",
    //                 "zipcode": "10540",
    //                 "address_flag": [],
    //                 "time_slot_list": [
    //                     {
    //                         "date": 1698915600,
    //                         "pickup_time_id": "1698915600"
    //                     },
    //                     {
    //                         "date": 1699002000,
    //                         "pickup_time_id": "1699002000"
    //                     }
    //                 ]
    //             },
    //             {
    //                 "address_id": 93029643,
    //                 "region": "TH",
    //                 "state": "จังหวัดปทุมธานี",
    //                 "city": "อำเภอคลองหลวง",
    //                 "district": "",
    //                 "town": "",
    //                 "address": "เลขที่ 40/64 หมู่บ้านพรธิสาร3, ต.คลองหก, อำเภอคลองหลวง",
    //                 "zipcode": "12120",
    //                 "address_flag": [],
    //                 "time_slot_list": [
    //                     {
    //                         "date": 1698915600,
    //                         "pickup_time_id": "1698915600"
    //                     },
    //                     {
    //                         "date": 1699002000,
    //                         "pickup_time_id": "1699002000"
    //                     }
    //                 ]
    //             }
    //         ]
    //     }
    // });
    // })
    return this._http.post('/services/shopee/order/getShippingParameter', data).toPromise() as Promise<any>
  }
  deliveredShopeeOrderBatch(data: any): Promise<any> {
    return this._http.post('/services/shopee/order/deliveredOrderBatch', data).toPromise() as Promise<any>
  }
  deliveredShopeeOrder(data: any): Promise<any> {
    return this._http.post('/services/shopee/order/deliveredShopeeOrder', data).toPromise() as Promise<any>
  }
  checkConnection(): Promise<any> {
    return this._http.get('/services/shopee/shop/checkConnection').toPromise() as Promise<any>
  }
  syncProductsState(shop_id: number): Promise<any> {
    return this._http.get('/services/shopee/product/syncProductsState/' + shop_id).toPromise() as Promise<any>
  }
  unsyncProducts(shop_id: number): Promise<any> {
    return this._http.get('/services/shopee/product/unsyncProducts/' + shop_id).toPromise() as Promise<any>
  }
  syncProducts(shop_id: number): Promise<any> {
    return this._http.get('/services/shopee/product/syncProducts/' + shop_id).toPromise() as Promise<any>
  }
  syncProductsByID(shop_id: number,item_id: number): Promise<any> {
    return this._http.get('/services/shopee/product/syncProductsByID/' + shop_id + '/'+item_id).toPromise() as Promise<any>
  }
  unListItem(data: any): Promise<any> {
    return this._http.post('/services/shopee/product/unListItem/', data).toPromise() as Promise<any>
  }
  searchSyncProduct(data: any, shop_id: number): Promise<any> {
    return this._http.post('/services/shopee/product/searchSyncProduct/' + shop_id, data).toPromise() as Promise<any>
  }
  searchKetshopShopeeOrder(data: any): Promise<any> {
    return this._http.post('/services/shopee/order/searchKetshopShopeeOrder', data).toPromise() as Promise<any>
  }
  syncOrdersState() {
    return this._http.get('/services/shopee/order/syncOrdersState').toPromise() as Promise<any>
  }
  syncOrders() {
    return this._http.get('/services/shopee/order/syncOrders').toPromise() as Promise<any>
  }
  syncOrderByOrderNumber(data) {
    return this._http.post('/services/shopee/order/syncOrderByOrderNumber', data).toPromise() as Promise<any>
  }
  createOrderKetshop(data) {
    return this._http.post('/services/shopee/order/createOrderKetshop', data).toPromise() as Promise<any>
  }
  listShop(): Promise<any> {
    return this._http.get('/services/shopee/shop/listShop').toPromise() as Promise<any>
  }

  getCategory(shop_id: number): Promise<any> {
    return this._http.get('/services/shopee/product/getCategory/' + shop_id).toPromise() as Promise<any>
  }
  authorizationShopUrl(): Promise<any> {
    return this._http.get('/services/shopee/shop/authorizationShopUrl').toPromise() as Promise<any>
  }

  searchKetshopProductToShopee(data: any): Promise<any> {
    return this._http.post('/services/shopee/product/searchKetshopProductToShopee', data).toPromise() as Promise<any>
  }
  searchKetshopShopeeProduct(data: any): Promise<any> {
    return this._http.post('/services/shopee/product/searchKetshopShopeeProduct', data).toPromise() as Promise<any>
  }
  getProductShopeeByItemID(item_id) {
    return this._http.get('/services/shopee/product/byItemID/' + item_id).toPromise() as Promise<any>
  }
  uploadImage(data: any): Promise<any> {
    return this._http.post('/services/shopee/product/uploadImage', data).toPromise() as Promise<any>
  }

  createProduct(data: any): Promise<any> {
    return this._http.post('/services/shopee/product/createProduct', data).toPromise() as Promise<any>
  }
  updateProduct(data: any): Promise<any> {
    return this._http.post('/services/shopee/product/updateProduct', data).toPromise() as Promise<any>
  }

  cancelAuthorizationShopUrl(): Promise<any> {
    return this._http.get('/services/shopee/shop/cancelAuthorizationShopUrl').toPromise() as Promise<any>
  }
  getLogistic(shop_id: number): Promise<any> {
    return this._http.get('/services/shopee/product/getLogistic/' + shop_id).toPromise() as Promise<any>
  }

  searchBrand(data: any): Promise<any> {
    return this._http.post('/services/shopee/product/searchBrand', data).toPromise() as Promise<any>
  }
  getCategoryAttribute(data: any): Promise<any> {
    return this._http.post('/services/shopee/product/categoryAttribute', data).toPromise() as Promise<any>
  }
}
