import { Component, OnInit, Input } from '@angular/core'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { UtilService } from '@cms/services/util.service'
import { TranslateService } from '@cms/services/translate.service'

declare let fbq: (event: string, action: string, data: any) => void
@Component({
  selector: 'ket-modal-quotation',
  templateUrl: './modal-quotation.component.html',
  styleUrls: ['./modal-quotation.component.scss']
})
export class KetModalQuotationComponent implements OnInit {
  @Input() data: { product: { id: string } }
  page_name = 'product-detail'
  message_duration = 4000
  constructor(
    private message: NzMessageService,
    private modal: NzModalRef,
    private Service_Util: UtilService,
    private Service_Translate: TranslateService
    
  ) {}

  model: { firstname: string, lastname: string, company: string, taxId: string, email: string, tel: string, address: string, message: string, product_id?: string, link?: string } = {
    firstname: '',
    lastname: '',
    company: '',
    taxId: '',
    email: '',
    tel: '',
    address: '',
    message: '',
  }
  error_msg = 'โปรดกรอกข้อมูลให้ครบถ้วน'

  ngOnInit() {
    this.model.product_id = this.data.product.id
    this.model.link = decodeURI(location.href)
    this.error_msg = this.Service_Translate.translate(this.error_msg, this.page_name)
  }

  ok() {
    if (this.model.firstname == '' || this.model.lastname == '' || this.model.taxId == '' || this.model.email == '' || this.model.tel == '' || this.model.address == '') {
      this.message.error(this.error_msg, { nzDuration: this.message_duration })
      return false
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailPattern.test(this.model.email)) {
      this.message.error('Invalid email format', { nzDuration: this.message_duration })
      return false
    }

    this.Service_Util.sendMailQuotationProduct(this.model)
      .then((res: unknown) => {
        console.log(res)
        //   this.message.success("Success", { nzDuration: this.message_duration });
        try {
          fbq('trackCustom', 'Quotation', this.model)
        } catch (err) {}
        this.modal.close(true)
      })
      .catch((data: unknown) => {
        console.log(data)
      })
    console.log(this.data.product)
    return false
  }
}