import { h, hydrate, Fragment } from 'preact'
import { qr_to_base64 } from '../func/qr_to_base64'

export const withdraw_html = {
  async render(data: any) {
    // sort by qty
    data.products = data.products.sort((a: any, b: any) => b.qty - a.qty)
    const updated_ref_qr = await qr_to_base64(data.updated_ref, {
      scale: 0,
      width: 75,
      margin: 1,
    })

    console.log(data)

    const Main = (
      <Fragment>
        <div class="page-single">
          <div style="padding: 10px;border: 1px solid #000;margin-bottom: 10px;">
            <div class="row">
              <div class="col-auto">
                <div title={data.updated_ref}>
                  <img style={{ width: '100%' }} src={updated_ref_qr} alt={data.updated_ref} />
                </div>
              </div>
              <div class="col" style="padding-left: 12px;">
                <p>
                  Batch name : <strong style="font-size: 18px">{data.shipping_group_name}</strong>
                </p>
                <p>Batch number : {data.updated_ref}</p>
                <p>จำนวน: {data.details.length} ออเดอร์</p>
                <p>
                  แก้ไขล่าสุดโดย {data.updated_by} เมื่อ {data.updated_at}
                </p>
              </div>
            </div>
          </div>
          <table class="table-product">
            <thead>
              <tr>
                <th class="td-number">ลำดับ</th>
                <th class="td-product">สินค้า</th>
                <th class="td-qty">จำนวน</th>
              </tr>
            </thead>
            <tbody>
              {data.products.map((obj: any, i: number) => {
                if (obj.prop_enable == 1) {
                  obj.title_lang1 = `${obj.title_lang1} (${obj.property_info})`
                }
                if (obj.prop_enable == 2) {
                  obj.title_lang1 = `${obj.title_lang1} (${obj.property_info} - ${obj.property_info2})`
                }

                return (
                  <tr>
                    <td class="td-number">{i + 1}</td>
                    <td class="td-product">
                      <div class="row">
                        <div class="col-auto">
                          <figure class="product-image">
                            <img src={obj.feature_img} />
                          </figure>
                        </div>
                        <div class="col" style="padding-left: 8px;">
                          <div style="margin-bottom: 4px;">{obj.title_lang1}</div>
                          <div style="font-size: 12px;">SKU : {obj.sku}</div>
                        </div>
                      </div>
                    </td>
                    <td class="td-qty">{obj.qty}</td>
                  </tr>
                )
              })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={2} style="text-align:right">
                  <strong>รวมทั้งหมด</strong>
                </td>
                <td class="td-qty">
                  <strong>{data.product_total}</strong>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div class="pagebreak"></div>
      </Fragment>
    )

    const ctx = document.createElement('body')
    hydrate([Main, withdraw_html_css], ctx)
    return `<!DOCTYPE html>
        <html>
            <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>พิมพ์ใบจัดสินค้า ${data.shipping_group_name}</title>
            </head>
            ${ctx.outerHTML}
        </html>
        `
  },
}

const withdraw_html_css = (
  <style>
    {`*{
                margin: 0;
                padding: 0;
                box-sizing: border-box;
                font-size: 14px;
            }
            body{
                font-family: sans-serif;
            }
            .page-single {
                font-size: 14px;
                padding: 15px;
            }
            .pagebreak{
                page-break-after: always
            }
            .row{
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
            }
            .col{
                -ms-flex-preferred-size: 0;
                flex-basis: 0;
                -webkit-box-flex: 1;
                -ms-flex-positive: 1;
                flex-grow: 1;
                max-width: 100%;
            }
            .col-auto{
                -webkit-box-flex: 0;
                -ms-flex: 0 0 auto;
                flex: 0 0 auto;
                width: auto;
                max-width: none;
            }
            .col,
            .col-auto{
                position: relative;
            }
            p{
                margin-bottom: 0px;
            }
            .table-product{
                width: 100%;
                border-collapse: collapse;
                border-spacing: 2px;
            }
            .table-product tr th,
            .table-product tr td{
                border-bottom: 1px solid #000;
                padding: 8px 10px;
            }
            .td-number{
                width: 40px;
                text-align: center;
            }
            .td-product{
                text-align: left;
            }
            .td-qty{
                width: 100px;
                text-align: left;
            }
            .product-image{
                width: 40px;
                height: 40px;
            }
            .product-image img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }`}
  </style>
)
