import { NzSelectModule } from 'ng-zorro-antd/select'
import { NzMessageModule } from 'ng-zorro-antd/message'
import { NzModalModule } from 'ng-zorro-antd/modal'
import { FormsModule } from '@angular/forms'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetBlogDisplaySortComponent } from './blog-display-sort.component'
import {
  NzAvatarModule,
  NzButtonModule,
  NzIconModule,
  NzInputModule,
  NzPaginationModule,
  NzTableModule,
  NzTagModule,
  NzToolTipModule,
  NzTreeSelectModule,
} from 'ng-zorro-antd'

@NgModule({
  declarations: [KetBlogDisplaySortComponent],
  entryComponents: [KetBlogDisplaySortComponent],
  imports: [
    CommonModule,
    NzButtonModule,
    NzInputModule,
    NzSelectModule,
    NzTableModule,
    NzPaginationModule,
    NzIconModule,
    NzTagModule,
    NzToolTipModule,
    FormsModule,
    NzModalModule,
    NzMessageModule,
    NzAvatarModule,
    NzTreeSelectModule,
  ],
  exports: [KetBlogDisplaySortComponent],
})
export class KetBlogDisplaySortModule {}
