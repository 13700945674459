import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetModalAddproductPromotionComponent } from './modal-addproduct-promotion.component'
import { FormsModule } from '@angular/forms'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzInputNumberModule } from 'ng-zorro-antd/input-number'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzTableModule } from 'ng-zorro-antd/table'
import { NzTagModule } from 'ng-zorro-antd/tag'
import { NzModalModule } from 'ng-zorro-antd/modal'
import { NzPaginationModule } from 'ng-zorro-antd/pagination'
import { KetProductItemInlineModule } from '../ket-product-components/product-item-inline'

@NgModule({
  declarations: [KetModalAddproductPromotionComponent],
  imports: [
    CommonModule,
    FormsModule,
    // AntD
    NzInputModule,
    NzInputNumberModule,
    NzSelectModule,
    NzCheckboxModule,
    NzButtonModule,
    NzIconModule,
    NzTableModule,
    NzTagModule,
    NzModalModule,
    NzPaginationModule,

    KetProductItemInlineModule,
  ],
  exports: [KetModalAddproductPromotionComponent],
  entryComponents: [KetModalAddproductPromotionComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class KetModalAddproductPromotionModule {}
