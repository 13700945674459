import { KetOrdersModule } from '@ketshopweb/ui/ket-order-components/ket-orders'
import { NzEmptyModule } from 'ng-zorro-antd/empty'
import { NzPaginationModule } from 'ng-zorro-antd/pagination'
import { Ng2GoogleChartsModule } from 'ng2-google-charts'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzTagModule } from 'ng-zorro-antd/tag'
import { NzTableModule } from 'ng-zorro-antd/table'
import { NzRadioModule } from 'ng-zorro-antd/radio'
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { NzTabsModule } from 'ng-zorro-antd/tabs'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'
import { NzAvatarModule } from 'ng-zorro-antd/avatar'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReportTeamSaleComponent } from './report-team-sale.component'
import { FormsModule } from '@angular/forms'
import { KetCardStatisticModule } from '@ketshopweb/ui/card-statistic'
import { NzPopoverModule } from 'ng-zorro-antd/popover'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { KetPermissionModule } from '@ketshopweb/ui/permission'
import { KetProductItemInlineModule } from '@ketshopweb/ui/ket-product-components/product-item-inline'

@NgModule({
  declarations: [ReportTeamSaleComponent],
  imports: [
    CommonModule,
    NzAvatarModule,
    NzToolTipModule,
    NzTabsModule,
    NzSelectModule,
    FormsModule,
    NzDatePickerModule,
    NzRadioModule,
    NzTableModule,
    NzTagModule,
    NzInputModule,
    Ng2GoogleChartsModule,
    NzPopoverModule,
    NzPaginationModule,
    NzEmptyModule,
    NzIconModule,
    NzButtonModule,

    KetCardStatisticModule,
    KetPermissionModule,
    KetOrdersModule,
    KetProductItemInlineModule,
  ],
  exports: [ReportTeamSaleComponent],
})
export class ReportTeamSaleModule {}
