import { Action, createReducer, on } from '@ngrx/store'
import { IClientUuserState } from './models'
import { actions_client_user } from './actions'
export const ClientUuserFEATURE_KEY = 'client_user'

export const initialClientUuserState: IClientUuserState.ClientUuserState = {
  me: null,
}

const reducer = createReducer(
  initialClientUuserState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  on(actions_client_user.SetMe, (state, action) => {
    return {
      ...state,
      me: action.me,
    }
  }),
)

export function ClientUuserReducer(state: IClientUuserState.ClientUuserState, action: Action) {
  return reducer(state, action)
}
