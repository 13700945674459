import { cloneDeep } from 'lodash'
import { Action, createReducer, on } from '@ngrx/store'
import { IOrdersState } from './models'
import * as moment from 'moment'
import { actions_orders } from './actions'

export const FEATURE_KEY = 'orders'

export const default_order_search_model = (user_ids: number[] = []): IOrdersState.SearchModel => {
  let size = 20
  try {
    const p = localStorage.getItem('page_size_backend')
    if (p) {
      const page = JSON.parse(p) as {
        global: number
      }
      size = page.global || 20
    }
  } catch {}
  return cloneDeep({
    channel_name: '-',
    startdate: moment().subtract(6, 'months').format('YYYY/MM/DD 00:00:00'),
    enddate: moment().format('YYYY/MM/DD 23:59:59'),
    status: '-',
    ordercode: '',
    email: '',
    trackcode: '',
    search: '',
    paymenttype: '-',
    created_by: '-',
    totals: '',
    page: 1,
    perpage: Number(size),
    confirm_payment: '1',
    assignee_ids: [],
    channels: [],
    is_tax_no: false,
    is_note: false,
    user_ids: user_ids,
    is_full_details: false,
    is_full_column: false,
    sell_channel: [],
    tags: [],
  })
}
export const clear_order_search_model = (model: IOrdersState.SearchModel) => {
  const new_model = cloneDeep(model)
  const fields = ['channel_name', 'status', 'paymenttype', 'created_by']
  for (const key in new_model) {
    if (fields.includes(key) && new_model[key] == '-') new_model[key] = ''
  }
  return new_model
}

export const initialOrdersState: IOrdersState.OrdersState = {
  is_loading: false,
  search_model: {},
  list: {},
  select_list: {},
  order_status_img: {
    '-1': { status: -1, img: '/assets/images/core-imgs/icon/order-status5.png', name: 'ยกเลิก' },
    '0': { status: 0, img: '/assets/images/core-imgs/icon/order-status0.png', name: 'Pending' },
    '1': { status: 1, img: '/assets/images/core-imgs/icon/order-status1.png', name: 'รอชำระเงิน' },
    '2': { status: 2, img: '/assets/images/core-imgs/icon/order-status2.png', name: 'ได้รับเงิน' },
    '3': { status: 3, img: '/assets/images/core-imgs/icon/order-status3.png', name: 'จัดเตรียมสินค้า' },
    '3.5': { status: 3.5, img: '/assets/images/core-imgs/icon/order-status3_5.png', name: 'เตรียมจัดส่ง' },
    '4': { status: 4, img: '/assets/images/core-imgs/icon/order-status4.png', name: 'จัดส่ง' },
    '5': { status: 5, img: '/assets/images/core-imgs/icon/order-success.png', name: 'เสร็จสิ้น' },
  },
  types_of_payment_service: {
    '-2': { id: -2, img: '/assets/images/core-imgs/icon/payment-cash.png', name: 'รอชำระเงิน' },
    '-1': { id: -1, img: '/assets/images/core-imgs/icon/payment-cash.png', name: 'ชำระเงินสด (marketplace)' },
    '-5': { id: -5, img: '/assets/images/core-imgs/icon/payment-redempoint.png', name: 'Redempoint' },
    '1': { id: 1, img: '/assets/images/core-imgs/icon/payment-cash.png', name: 'ชำระเงินสด' },
    '2': { id: 2, img: '/assets/images/core-imgs/icon/payment-transfer.png', name: 'โอนผ่านธนาคาร' },
    '3': { id: 3, img: '/assets/images/core-imgs/icon/payment-credit.png', name: 'เครดิตการ์ด' },
    '5': { id: 5, img: '/assets/images/core-imgs/icon/payment-qrcode.png', name: 'QR CODE' },
    '4': { id: 4, img: '/assets/images/core-imgs/icon/payment-cod.png', name: 'เก็บเงินปลายทาง (COD)' },
    '6': { id: 6, img: '/assets/images/core-imgs/icon/payment-crypto.svg', name: 'Crypto currency' },
    '7': { id: 7, img: '/assets/images/core-imgs/icon/payment-alipay.png', name: 'Alipay' },
    '8': { id: 8, img: '/assets/images/core-imgs/icon/payment-truemoney.png', name: 'True money' },
  },
  types_of_delivery_service: {
    normal: { name: 'ขนส่งทั่วไป' },
    auto: { name: 'ขนส่งอัตโนมัติ' },
    auto_normal: { name: 'ขนส่งอัตโนมัติ' },
    direct: { name: 'ขนส่ง Direct' },
    shippop: { name: 'ขนส่ง Shippop' },
    international: { name: 'ขนส่งต่างประเทศ' },
  },
  list_staff: [],
}

const reducer = createReducer(
  initialOrdersState,
  on(actions_orders.SetLoading, (state, action) => {
    return {
      ...state,
      is_loading: action.val,
    }
  }),
  on(actions_orders.SetSearchModel, (state, action) => {
    const search_model = cloneDeep(state.search_model)
    search_model[action.from] = action.input
    return {
      ...state,
      search_model: search_model,
    }
  }),
  on(actions_orders.SetOrders, (state, action) => {
    const list = cloneDeep(state.list)
    if (!list[action.from]) {
      list[action.from] = {
        data: [],
        count: 0,
      }
    }
    list[action.from] = {
      data: action.data.data,
      count: action.data.count,
    }
    return {
      ...state,
      list: list,
    }
  }),
  on(actions_orders.InitSelectOrders, (state, action) => {
    const select_list = cloneDeep(state.select_list)
    if (!select_list[action.from]) {
      select_list[action.from] = {
        data: [],
        apply_data: [],
      }
    }
    select_list[action.from].data = cloneDeep(select_list[action.from].apply_data)
    return {
      ...state,
      select_list: select_list,
    }
  }),
  on(actions_orders.SelectOrders, (state, action) => {
    const select_list = cloneDeep(state.select_list)
    if (!select_list[action.from]) {
      select_list[action.from] = {
        data: [],
        apply_data: [],
      }
    }
    action.data.forEach((order) => {
      const find_index = select_list[action.from].data.findIndex((x) => x.id == order.id)
      if (action.action == 'add' && find_index == -1) {
        select_list[action.from].data.push(order)
      } else if (action.action == 'del' && find_index !== -1) {
        select_list[action.from].data.splice(find_index, 1)
      }
    })
    return {
      ...state,
      select_list: select_list,
    }
  }),
  on(actions_orders.ApplySelectOrders, (state, action) => {
    const select_list = cloneDeep(state.select_list)
    if (!select_list[action.from]) {
      select_list[action.from] = {
        data: [],
        apply_data: [],
      }
    }
    select_list[action.from].apply_data = cloneDeep(select_list[action.from].data)
    return {
      ...state,
      select_list: select_list,
    }
  }),
  on(actions_orders.ClearSelectOrders, (state, action) => {
    const select_list = cloneDeep(state.select_list)
    select_list[action.from] = {
      data: [],
      apply_data: [],
    }
    return {
      ...state,
      select_list: select_list,
    }
  }),
  on(actions_orders.SetStaff, (state, action) => {
    return {
      ...state,
      list_staff: action.data,
    }
  }),
)

export function Reducer(state: IOrdersState.OrdersState, action: Action) {
  return reducer(state, action)
}
