import { cloneDeep } from 'lodash'
import { actions_teams } from './actions'
import { Action, createReducer, on } from '@ngrx/store'
import { ITeamsState } from './models'

export const TeamsState_FEATURE_KEY = 'teams'

export const initialTeamsState: ITeamsState.TeamsState = {
  report: {
    cancel_count: 0,
    cancel_total: 0,
    count: 0,
    paid_avg: 0,
    paid_count: 0,
    paid_total: 0,
    pending_count: 0,
    pending_total: 0,
    total: 0,
    graph_data: [],
  },
  report_item: {
    province: [],
    best_seller: [],
  },
}

const reducer = createReducer(
  initialTeamsState,
  on(actions_teams.SetReport, (state, action) => {
    return {
      ...state,
      report: action.data,
    }
  }),
  on(actions_teams.SetReportProvince, (state, action) => {
    const report_item = cloneDeep(state.report_item)
    report_item.province = action.data
    return {
      ...state,
      report_item: report_item,
    }
  }),
  on(actions_teams.SetReportBestSeller, (state, action) => {
    const report_item = cloneDeep(state.report_item)
    report_item.best_seller = action.data
    return {
      ...state,
      report_item: report_item,
    }
  }),
)

export function TeamsStateReducer(state: ITeamsState.TeamsState, action: Action) {
  return reducer(state, action)
}
