import { Pipe, PipeTransform } from '@angular/core'
import { KetHasDataDomService } from './has-data-dom.service'

@Pipe({
  name: 'ketHasDataDom',
})
export class KetHasDataDomPipe implements PipeTransform {
  constructor(private service: KetHasDataDomService) {}

  transform(value: any, args?: any): any {
    //console.log(value)
    return this.service.uid()
  }
}
