import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetModalPaymentTestComponent } from './modal-payment-test.component'
import { FormsModule } from '@angular/forms'
import {
  NzAlertModule,
  NzButtonModule,
  NzIconModule,
  NzInputModule,
  NzInputNumberModule,
  NzRadioModule,
  NzSelectModule,
  NzSpinModule,
} from 'ng-zorro-antd'
import { KetPayCreditModule } from '../pay-credit'

@NgModule({
  declarations: [KetModalPaymentTestComponent],
  entryComponents: [KetModalPaymentTestComponent],
  imports: [
    CommonModule,
    FormsModule,

    NzRadioModule,
    NzInputNumberModule,
    NzButtonModule,
    NzIconModule,
    NzInputModule,
    KetPayCreditModule,
    NzAlertModule,
    NzSelectModule,
    NzSpinModule,
  ],
  exports: [KetModalPaymentTestComponent],
})
export class KetModalPaymentTestModule {}
