import { createFeatureSelector, createSelector } from '@ngrx/store'
import { ITeamsState } from './models'
import { TeamsState_FEATURE_KEY } from './reducer'

const selectState = createFeatureSelector<ITeamsState.TeamsState>(TeamsState_FEATURE_KEY)

const report = createSelector(selectState, (state: ITeamsState.TeamsState) => state.report)

const report_item = createSelector(selectState, (state: ITeamsState.TeamsState) => state.report_item)

const report_item_province = createSelector(report_item, (report_item) => report_item.province)
const report_item_best_seller = createSelector(report_item, (report_item) => report_item.best_seller)

export const selectors_teams = {
  report,
  report_item_province,
  report_item_best_seller,
}
