import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { BlogDisplayComponent } from './blog-display.component'

const routes: Routes = [{ path: '', component: BlogDisplayComponent }]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BlogDisplayRoutingModule {}
