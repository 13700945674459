import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-font-setting',
  templateUrl: './font-setting.component.html',
  styleUrls: ['./font-setting.component.css'],
})
export class FontSettingComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
