import { Fragment, h, hydrate } from 'preact'
import { TmpOrderHeader } from './tmp_order_header'
import { TmpOrderAddress } from './tmp_order_address'
import { TmpOrderProduct } from './tmp_order_product'
import { TmpOrderNote } from './tmp_order_note'
import { IPurchaseOrderHtml } from './types'
import { OrderA4Full } from './order_a4_full_html'
import { OrderA4Half } from './order_a4_half_html'
import { qr_to_base64 } from 'libs/ketshopweb-services/func/qr_to_base64'
import * as moment from 'moment'

export const purchase_order = {
  to_element: async (order: IPurchaseOrderHtml.Order, option: IPurchaseOrderHtml.Option,cus_id:number) => {  
    const qr_ordercode = (await qr_to_base64(`${cus_id}-${order.ordercode}`, {
      scale: 0,
      width: 120,
      margin: 1,
    })) as string

    return (
      <Fragment>
        <TmpOrderHeader order={order} qr_ordercode={qr_ordercode} />
        <TmpOrderAddress order={order} />
        <TmpOrderProduct order={order} />
        <TmpOrderNote order={order} option={option} />
      </Fragment>
    )
  },
  generate: async (orders: IPurchaseOrderHtml.Order[], option: IPurchaseOrderHtml.Option , cus_id : number) => {
    if (option.layout == IPurchaseOrderHtml.Layout.A4FUll) {
      const elements: h.JSX.Element[] = []
      for (const order of orders) {
        elements.push(await purchase_order.to_element(order, option,cus_id))
      }

      return [<OrderA4Full elements={elements} />]
    }

    if (option.layout == IPurchaseOrderHtml.Layout.A4Half) {
      const elements: ([h.JSX.Element, h.JSX.Element] | [h.JSX.Element])[] = []
      for (let i = 0; i < orders.length; i += 2) {
        const order = orders[i]
        const order2 = orders[i + 1]
        const item = [await purchase_order.to_element(order, option,cus_id)] as
          | [h.JSX.Element, h.JSX.Element]
          | [h.JSX.Element]
        if (order2) {
          item.push(await purchase_order.to_element(order2, option,cus_id))
        }

        elements.push(item)
      }

      return [<OrderA4Half elements={elements} />]
    }

    return []
  },
  to_html: (Tsx: h.JSX.Element[], orientation: 'landscape' | 'portrait' = 'portrait', batch = '') => {
    const ctx = document.createElement('body')
    hydrate(Tsx, ctx)
    return `<!DOCTYPE html>
        <html>
            <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>พิมพ์ใบสั่งซื้อ ${batch} ${moment().format('YYYY-MM-DD HH_mm_ss')}</title>
            </head>
            <style>
              @page{
                size: ${orientation};
              }
            </style>
            ${ctx.outerHTML}
        </html>
        `
  },
}
