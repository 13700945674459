import { Component, OnInit } from '@angular/core'
import { SettingService } from '@cms/services/setting.service'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalService } from 'ng-zorro-antd/modal'

@Component({
  selector: 'app-setting-theme',
  templateUrl: './setting-theme.component.html',
  styleUrls: ['./setting-theme.component.css'],
})
export class SettingThemeComponent implements OnInit {
  constructor(
    private Service_Setting: SettingService,
    private message: NzMessageService,
    private modal: NzModalService,
  ) {}
  message_duration: 4000
  model: any = {}

  async ngOnInit() {
    this.model = await this.Service_Setting.listTheme()
  }

  opendemo(obj) {
    // console.log(obj.t_linkdemo)
    obj.t_linkdemo = `${obj.t_linkdemo}`.replace('http://', 'https://')
    window.open(obj.t_linkdemo, '_blank')
  }

  changeTheme(obj) {
    this.modal.confirm({
      nzTitle: 'ยืนยันการเปลี่ยน Theme',
      nzContent: 'ข้อมูล Menu ,Section และ Element จะหายไป คุณแน่ใจว่า จะเปลี่ยน Theme หรือ ไม่?',
      nzOnOk: () => {
        $('.theme-loader').show()
        const _data = {
          t_id: obj.t_id,
        }
        this.Service_Setting.changeTheme(_data)
          .then((res: any) => {
            location.reload()
          })
          .catch((data) => {
            $('.theme-loader').hide()
            this.message.error(data.error.message, { nzDuration: this.message_duration })
          })
        return false
      },
    })
  }
}
