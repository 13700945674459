import { Injectable, inject } from '@angular/core'
import { FeatureService } from '@cms/services/feature.service'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { from, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { actions_features } from './actions'
import { IFeaturesState } from './models'

@Injectable()
export class FeaturesEffects {
  // inject
  private actions$ = inject(Actions)
  private featureService = inject(FeatureService)
  private store = inject(Store)

  $LoadFeatures = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions_features.LoadFeatures),
        mergeMap((action) =>
          from(this.featureService.getAllFeature()).pipe(
            map((res: IFeaturesState.Feature[]) => {
              this.store.dispatch(
                actions_features.SetFeatures({
                  features: res,
                }),
              )
            }),
            catchError((err: any) => {
              console.error(err)
              return of(err)
            }),
          ),
        ),
      ),
    { dispatch: false },
  )
}
