import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'staticLang',
})
export class StaticLangPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return null
  }
}
