import { Component, OnInit, Input } from '@angular/core'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { CustomerService } from '@cms/services/customer.service'
import { UtilService } from '@cms/services/util.service'
import { DeliveryService } from '@cms/services/delivery.service'
import { FeatureService } from '@cms/services/feature.service'
import { SettingService } from '@cms/services/setting.service'
import { AuthenService } from '@cms/services/authen.service'

@Component({
  selector: 'ket-modal-add-edit-sending-address',
  templateUrl: './modal-add-edit-sending-address.component.html',
  styleUrls: ['./modal-add-edit-sending-address.component.scss'],
})
export class KetModalAddEditSendingAddressComponent implements OnInit {
  @Input() data: any
  @Input() renderType: 'backend' | 'frontend' = 'frontend'
  page_name = 'member-profile'
  constructor(
    private modal: NzModalRef,
    private message: NzMessageService,
    private Service_Customer: CustomerService,
    private Service_Util: UtilService,
    private Service_Feature: FeatureService,
    private Service_Delivery: DeliveryService,
    private settingService: SettingService,
    private authService: AuthenService
  ) {}

  model = {
    id: 0,
    user_id: 0,
    name: '',
    address1: '',
    subdistrict: null,
    district: null,
    province: null,
    zipcode: '',
    tel: '',
    country_name: 'Thailand',
    country_alpha2_code: 'TH',
  }
  model_error = {
    name: false,
    address1: false,
    subdistrict: false,
    district: false,
    province: false,
    zipcode: false,
    tel: false,
  }

  province: any[] = []
  tambon_list: any[] = []
  amphur_list: any[] = []
  message_duration = 4000
  countries: { country_name: string; country_alpha2_code: string }[] = []
  feature_shipping_inter = false
  shipping_inter_enable = false
  address_layout = 1

  async ngOnInit() {
    this.settingService
      .searchOption({
        pop_key: ['address_layout'],
      })
      .then((res: any[]) => {
        for (const obj of res) {
          if (obj.pop_key == 'address_layout') {
            if (!obj.pop_value) obj.pop_value = '1'
            this.address_layout = parseInt(obj.pop_value)
          }
        }
      })
      .catch((err) => console.error(err))
    this.shipping_inter_enable = false
    if (this.renderType === 'backend') {
      this.feature_shipping_inter = await this.Service_Feature.getFeature('SHIPPING_INTER')
      if (this.feature_shipping_inter) {
        this.countries = await this.Service_Delivery.getAvailbleCountry()
        this.shipping_inter_enable = true
      }
    }
    if (this.renderType === 'frontend') {
      const checkInter = (await this.Service_Delivery.checkInterShippingEnable()) as any
      if (checkInter.status) {
        this.countries = await this.Service_Delivery.getAvailbleCountry()
        this.shipping_inter_enable = true
      }
    }

    if (!this.shipping_inter_enable && this.data.state == 'edit' && this.hasInterAddress(this.data.model)) {
      this.countries = [
        {
          country_name: 'Thailand',
          country_alpha2_code: 'TH',
        },
        {
          country_name: this.data.model.country_name,
          country_alpha2_code: this.data.model.country_alpha2_code,
        },
      ]
    }

    this.Service_Util.getProvince().then((res: any[]) => {
      this.province = res
    })

    if (this.data.state == 'edit') {
      this.model.name = this.data.model.name || ''
      this.model.address1 = this.data.model.address1 || ''
      this.model.subdistrict = this.data.model.subdistrict || null
      this.model.district = this.data.model.district || null
      this.model.province = this.data.model.province || null
      this.model.zipcode = this.data.model.zipcode || ''
      this.model.tel = this.data.model.tel || ''
      this.model.id = this.data.model.id
      if (this.data.model.country_alpha2_code) {
        this.model.country_name = this.data.model.country_name || ''
        this.model.country_alpha2_code = this.data.model.country_alpha2_code || ''
      }
    }
    if (this.data.model && this.data.model.user_id) {
      this.model.user_id = this.data.model.user_id
    }
    this.searchTambon(this.model.subdistrict)
    this.searchAmphur(this.model.district)
  }
  searchTambon(event) {
    event = event || ''
    const obj = {
      name: event,
    }
    this.Service_Util.getSubDistrict(obj)
      .then((res: any) => {
        this.tambon_list = res
        // console.log(this.tambon_list);
      })
      .catch((data) => {
        console.log(data.error.message)
      })
  }
  searchAmphur(event) {
    event = event || ''
    const obj = {
      name: event,
    }
    this.Service_Util.getDistrict(obj)
      .then((res: any) => {
        this.amphur_list = res
        // console.log(this.amphur_list);
      })
      .catch((data) => {
        console.log(data.error.message)
      })
  }

  phoneNumber(phone: string) {
    const phoneRe = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    const digits = `${phone || ''}`.replace(/\D/g, '')
    if (phoneRe.test(digits)) {
      return digits
    } else {
      return false
    }
  }

  async ok() {
    const validate = this.validateDeliAddress(this.model)
    if (validate.error) {
      // this.message.error(validate.message, { nzDuration: this.message_duration });
      return false
    }

    if (this.data.state == 'create') {
      try {
        const data = await this.Service_Customer.createSendingAddress(this.model).catch((err) => {
          try {
            console.error(err)
            this.message.error(err.error.message, { nzDuration: this.message_duration })
          } catch {}
          return null
        })
        if (!data) {
          return false
        }

        if (this.authService.getJwtDecode().user_role == 'customer') {
          const me = await this.Service_Customer.me().catch((err) => {
            try {
              console.error(err)
              this.message.error(err.error.message, { nzDuration: this.message_duration })
            } catch {}
            return null
          })
          if (!me) {
            return false
          }
          if (!me.address || me.address.length === 0) {
            this.message.error('Please fill in the address.', { nzDuration: this.message_duration })
            return false
          }
        }

        try {
          if (this.data.customer._newaddress) {
            const _model = { ...this.model }
            const full_name = _model.name

            const last_name = `${full_name}`.trim().split(' ')
            if (last_name.length > 2) {
              for (let i = last_name.length - 1; i >= 0; i--) {
                if (last_name[i] == '') {
                  last_name.splice(Number(i), 1)
                }
              }
            }

            _model['name'] = last_name[0]
            _model['lastname'] = last_name[1] || '-'

            await this.Service_Customer.updateProfile(_model)
          }
        } catch (err) {}
        this.modal.close(data)
      } catch (e) {
        this.message.error(e.error.message, { nzDuration: this.message_duration })
      }
    } else {
      try {
        const data = await this.Service_Customer.updateSendingAddress(this.model)
        this.modal.close(data)
      } catch (e) {
        this.message.error(e.error.message, { nzDuration: this.message_duration })
      }
    }
  }
  close() {
    this.modal.close(true)
  }
  onCountryChange() {
    this.model.country_alpha2_code = this.countries.find(
      (x) => x.country_name == this.model.country_name,
    ).country_alpha2_code
    this.model.province = ''
    this.model.district = ''
    this.model.subdistrict = ''
    this.model.zipcode = ''
    if (this.model.country_alpha2_code == 'TH') {
      if (this.model.tel && `${this.model.tel}`.length > 10) {
        this.model.tel = ''
      }
    }
  }

  hasInterAddress(data) {
    if (!data) {
      return false
    }
    return data.country_alpha2_code && data.country_alpha2_code != 'TH'
  }

  validateDeliAddress(model: {
    name: string
    tel: string
    address1: string
    subdistrict: string
    district: string
    province: string
    zipcode: string
    country_alpha2_code: string
  }): { error: boolean; message: string } {
    let is_error = false
    if (!model.country_alpha2_code) {
      is_error = true
      // return { error: true, message: "โปรดกรอกประเทศ" }
    }
    if (!model.name) {
      this.model_error.name = true
      is_error = true
      // return { error: true, message: "โปรดกรอกชื่อ-นามสกุล" }
    }
    if (!/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$/im.test(model.tel.replace(/\D/g, ''))) {
      console.log(model.tel)

      this.model_error.tel = true
      is_error = true
      // return { error: true, message: "โปรดกรอกเบอร์โทรศัพท์" }
    }
    if (!model.address1) {
      this.model_error.address1 = true
      is_error = true
      // return { error: true, message: "โปรดกรอกเบอร์ที่อยู่" }
    }
    if (model.country_alpha2_code === 'TH') {
      if (!model.subdistrict) {
        this.model_error.subdistrict = true
        is_error = true
        // return { error: true, message: "โปรดกรอกตำบล/แขวง" }
      }
      if (!model.district) {
        this.model_error.district = true
        is_error = true
        // return { error: true, message: "โปรดกรอกอำเภอ/เขต" }
      }
    }
    if (!model.province) {
      this.model_error.province = true
      is_error = true
      // return { error: true, message: "โปรดกรอกจังหวัด" }
    }
    if (!model.zipcode) {
      this.model_error.zipcode = true
      is_error = true
      // return { error: true, message: "โปรดกรอกรหัสไปรษณีย์" }
    }

    return { error: is_error, message: '' }
  }

  addressChange(data: any) {
    const subdistrict = data ? data.district : ''
    const district = data ? data.city : ''
    const zipcode = data ? data.zipcode : ''
    const province = data ? data.province : ''

    this.model.province = province
    this.model.district = district
    this.model.subdistrict = subdistrict
    this.model.zipcode = zipcode

    this.searchAmphur(district)
    this.searchTambon(subdistrict)

    this.onKeyupAndClearError('subdistrict')
    this.onKeyupAndClearError('district')
    this.onKeyupAndClearError('province')
    this.onKeyupAndClearError('zipcode')
  }

  onKeyupAndClearError(key: string) {
    this.model_error[key] = false
  }
}
