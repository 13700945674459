import {
  Component,
  OnInit,
  Input,
  forwardRef,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core'
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms'
import { OnChangeType, OnTouchedType } from '../core/types/control-value-accessor'
import { cloneDeep, groupBy } from 'lodash'

export interface CheckboxGroup {
  checked: boolean
  label: string
  value: any
  checkboxes: Checkbox[]
}

export interface Checkbox {
  checked: boolean
  label: string
  value: any
  img: string
}

@Component({
  selector: 'ket-multiple-checkbox',
  templateUrl: './multiple-checkbox.component.html',
  styleUrls: ['./multiple-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => KetMultipleCheckboxComponent),
      multi: true,
    },
  ],
})
export class KetMultipleCheckboxComponent implements OnInit, ControlValueAccessor, OnChanges, OnDestroy {
  onChange: OnChangeType = () => {}
  onTouched: OnTouchedType = () => {}
  Model: any[]

  constructor() {}

  @Input() visible?: boolean = false
  @Input() prefix?: string = ''
  @Input() placeholder?: string = ''
  @Input() showSelectAll?: boolean = true
  @Input() checkAllLabel?: string = 'Check All'
  @Input() checkAllValue?: any = null
  @Input() options? = [] as CheckboxGroup[]
  @Input() optionIsChangeOnApply = false
  @Input() labels?: string[]
  @Input() labelIschangeOnApply = true

  @Input() buttonClass?: string
  @Input() checkboxStatusWrapperClass?: string

  @Output() onSelectAllChange?: EventEmitter<boolean> = new EventEmitter()
  @Output() onVisibleChange?: EventEmitter<boolean> = new EventEmitter()
  @Output() onApplyChange?: EventEmitter<boolean> = new EventEmitter()

  checkAllModel = {
    checked: false,
    checkboxes: [] as Checkbox[],
  }
  checkboxModels = [] as CheckboxGroup[]
  is_apply = false

  ngOnInit(): void {
    this.is_apply = false
    this.checkAllModel = {
      checked: false,
      checkboxes: [] as Checkbox[],
    }
    if (this.optionIsChangeOnApply) {
      this.checkboxModels = cloneDeep(this.options)
    } else {
      this.checkboxModels = this.options
    }

    for (let i = 0; i < this.checkboxModels.length; i++) {
      for (let j = 0; j < this.checkboxModels[i].checkboxes.length; j++) {
        this.checkAllModel.checkboxes.push(this.checkboxModels[i].checkboxes[j])
      }
    }
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy')
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('changes', changes)
    if (changes && changes.options && changes.options && changes.options.currentValue) {
      this.options = changes.options.currentValue
      this.ngOnInit()
    }
  }

  writeValue(value: any[]) {
    if (!value) return []
    this.Model = value || []

    for (const val of this.Model) {
      const find = this.checkAllModel.checkboxes.find((x) => x.value === val)
      if (find) {
        find.checked = true
      }
    }

    let checkedCount = 0
    this.checkboxModels.forEach((x) => {
      if (x.checkboxes.every((y) => y.checked)) {
        x.checked = true
        checkedCount++
      } else {
        x.checked = false
      }
    })

    if (checkedCount === this.checkboxModels.length) {
      this.checkAllModel.checked = true
    }

    if (this.labels) {
      this.getLabels()
    }
  }

  registerOnChange(fn: OnChangeType): void {
    console.log(this.checkAllModel)
    this.onChange = fn
  }

  registerOnTouched(fn: OnTouchedType): void {
    this.onTouched = fn
  }

  onCheckAll() {
    this.checkAllModel.checkboxes.forEach((x) => (x.checked = this.checkAllModel.checked))
    this.checkboxModels.forEach((x) => (x.checked = this.checkAllModel.checked))
    this.onSelectAllChange.emit(this.checkAllModel.checked)
    this.onCheckboxChange()
  }

  onCheckGroup(group: CheckboxGroup) {
    group.checkboxes.forEach((x) => (x.checked = group.checked))
    this.checkAllModel.checked = this.checkAllModel.checkboxes.every((x) => x.checked) ? true : false
    this.onCheckboxChange()
  }

  onCheck(group: CheckboxGroup) {
    group.checked = group.checkboxes.every((x) => x.checked) ? true : false
    this.checkAllModel.checked = this.checkAllModel.checkboxes.every((x) => x.checked) ? true : false
    this.onCheckboxChange()
  }

  onCheckboxChange() {
    if (!this.labelIschangeOnApply && this.labels) {
      this.getLabels()
    }
    const selectVals = this.checkAllModel.checkboxes.filter((x) => x.checked)
    if (selectVals.length === this.checkAllModel.checkboxes.length && this.checkAllValue) {
      const vals = groupBy<string>(this.checkAllValue)
      this.onChange(vals)
      return
    }
    const vals = groupBy<string>(selectVals.map((x) => x.value))
    // console.log('vals', vals)
    this.onChange(Object.keys(vals))
  }

  onVisible() {
    if (!this.visible && this.optionIsChangeOnApply) {
      this.cloneCheckboxModels(this.options, this.checkboxModels)
    } else if (!this.visible && !this.is_apply) {
      console.log('onVisible to default')
      for (const obj of this.checkboxModels) {
        obj.checkboxes.map((a) => {
          a.checked = false
          return a
        })
        this.onCheck(obj)
      }
    }
    this.onVisibleChange.emit(this.visible)
  }

  onApply() {
    this.is_apply = true
    if (this.optionIsChangeOnApply) {
      this.cloneCheckboxModels(this.checkboxModels, this.options)
    }
    if (this.labelIschangeOnApply && this.labels) {
      this.getLabels()
    }
    this.onApplyChange.emit(true)
    try {
      (document.getElementsByClassName('cdk-overlay-backdrop').item(0) as HTMLElement).click()
    } catch {}
  }

  cloneCheckboxModels(source: CheckboxGroup[], target: CheckboxGroup[]) {
    for (let i = 0; i < source.length; i++) {
      target[i].checked = source[i].checked
      for (let j = 0; j < source[i].checkboxes.length; j++) {
        target[i].checkboxes[j].checked = source[i].checkboxes[j].checked
      }
    }
  }

  getLabels() {
    this.labels = []
    this.labels.splice(0, this.labels.length)
    if (this.checkAllModel.checked) {
      this.labels.push(this.checkAllLabel)
      return
    }
    for (const group of this.options) {
      if (group.checked) {
        this.labels.push(group.label)
        continue
      }
      const selected = group.checkboxes.filter((x) => x.checked)
      if (selected.length > 0) {
        this.labels.push(...selected.map((x) => x.label))
      }
    }
  }
}
