import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core'
import { UtilService } from '@cms/services/util.service'
import { apiError } from '@cms/interface/interface'

@Component({
  selector: 'select-sub-district',
  template: `
    <input
      [disabled]="disabled"
      type="text"
      nz-input
      [(ngModel)]="Model"
      (ngModelChange)="onSearch()"
      placeholder="แขวง/ตำบล..."
      [nzAutocomplete]="autosubdistrict"
      name="autosubdistrict"
    />
    <nz-autocomplete #autosubdistrict>
      <nz-auto-option *ngFor="let obj of List" [nzValue]="obj.tambon_name">{{ obj.tambon_name }}</nz-auto-option>
    </nz-autocomplete>
  `,
})
export class SelectSubDistrictComponent implements OnInit, OnChanges, AfterViewInit {
  @Output() valueChange = new EventEmitter()
  @Input() data?: string = ''
  @Input() limit?: number = 10
  @Input() disabled?: boolean = false

  List: any[] = []
  Model: string

  constructor(private _util: UtilService) {}

  ngOnInit() {
    this.Model = this.data || ''
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.onSearch()
    }, 150)
  }

  onModelChange() {
    this.valueChange.emit(this.Model)
  }

  onSearch(state = true) {
    this._util
      .getSubDistrict({
        name: this.Model || '',
        limit: this.limit,
      })
      .then((res: any[]) => {
        this.List = res
        //console.log(res)
      })
      .catch((e: apiError) => {})

    this.onModelChange()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      if (changes.data.previousValue !== undefined) {
        this.Model = changes.data.currentValue
      }
    }
  }
}
