import { qr_to_base64 } from 'libs/ketshopweb-services/func/qr_to_base64'
import { Component, OnInit, Input, OnDestroy } from '@angular/core'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal'
import { OrderService } from '@cms/services/order.service'
import { Router } from '@angular/router'
import { pathLangPipe } from '@cms/pipes/path-lang.pipe'
import { interval } from 'rxjs'
import { TranslateService } from '@cms/services/translate.service'
import { UtilService } from '@cms/services/util.service'
import { SettingService } from '@cms/services/setting.service'
import { CmsPaymentGateway } from 'ketshopweb-services/payment-gateway.service'
declare let QRCode: any

@Component({
  selector: 'app-modal-qr',
  templateUrl: './modal-qr.component.html',
  styleUrls: ['./modal-qr.component.css'],
})
export class ModalQrComponent implements OnInit, OnDestroy {
  @Input() data: any
  page_name = 'confirm-order'
  domain: string
  message_duration: number
  order: any
  subscription: any
  thankyou: string

  qr_render = false
  expire_days = 7
  qrcode_url = ''
  constructor(
    private message: NzMessageService,
    private modal: NzModalRef,
    private modalService: NzModalService,
    private Service_Order: OrderService,
    private router: Router,
    private path_lang: pathLangPipe,
    private Service_Translate: TranslateService,
    private utilService: UtilService,
    private Service_Setting: SettingService,
    private Service_Payment: CmsPaymentGateway,
  ) {}

  async ngOnInit() {
    // console.log(this.modal);

    this.domain = window.location.hostname
    this.thankyou = this.path_lang.transform('thankyou')
    const option: any = await this.Service_Setting.searchOption({ pop_key: ['order_expire'] }).catch((err) => {
      console.error(err)
      return []
    })
    try {
      const expire_days = JSON.parse(option.find((x) => x.pop_key === 'order_expire').pop_value)
      this.expire_days = expire_days.status > 0 ? expire_days.day : 7
    } catch (e) {
      console.log(e)
    }

    // this.Service_Order.ordercode

    console.log(this.data.text)

    if (this.data.optional.guest) {
      this.Service_Order.ordercode(this.data.optional.ordercode)
        .then((res: any) => {
          console.log(res)
          if (res.status != 1 || this.data.text == null) {
            // this.router.navigate([`/`]);
            if (res.status > 1) {
              this.message.success(this.Service_Translate.translate('คำสั่งซื้อได้รับชำระแล้ว', 'confirm-payment'))
            }
            if (res.status == -1) {
              this.message.warning(this.Service_Translate.translate('คำสั่งซื้อถูกยกเลิก', 'confirm-payment'))
            }
            // this.modal.close()x
            return
          }
          this.order = res
          this.qr_render = true
          setTimeout(async () => {
            try {
              // new QRCode($('#qr_code')[0], { width: 250, height: 250, text: this.order.qr.link })
              this.qrcode_url = (await qr_to_base64(this.data.text, {
                scale: 4,
                width: 250,
                margin: 1,
              })) as string
            } catch (err) {}
          }, 10)
          const interval_checkstatus = interval(1000)
          this.subscription = interval_checkstatus.subscribe(this.checkOrderStatus.bind(this))
        })
        .catch((data) => {
          console.log(data)
        })
    } else {
      this.Service_Order.orderByID(this.data.orderid)
        .then((res: any) => {
          console.log(res)
          if (res.status != 1 || this.data.text == null) {
            // this.router.navigate([`/`]);
            if (res.status > 1) {
              this.message.success(this.Service_Translate.translate('คำสั่งซื้อได้รับชำระแล้ว', 'confirm-payment'))
            }
            if (res.status == -1) {
              this.message.warning(this.Service_Translate.translate('คำสั่งซื้อถูกยกเลิก', 'confirm-payment'))
            }
            // this.modal.close()
            return
          }
          this.order = res
          console.log('ORDERRRR')
          this.qr_render = true
          setTimeout(async () => {
            try {
              // new QRCode($('#qr_code')[0], { width: 250, height: 250, text: this.order.qr.link })
              this.qrcode_url = (await qr_to_base64(this.data.text, {
                scale: 4,
                width: 250,
                margin: 1,
              })) as string
            } catch (err) {}
          }, 10)
          const interval_checkstatus = interval(1000)
          this.subscription = interval_checkstatus.subscribe(this.checkOrderStatus.bind(this))
        })
        .catch((data) => {
          console.log(data)
        })
    }
  }
  checkOrderStatus(n) {
    console.log(n)
    // console.log(this);
    this.Service_Order.checkOrderStatus(this.order.ordercode).then((res: any) => {
      if (res.status == 2) {
        if (this.data.state == 'confirmorder') {
          this.router.navigate([`${this.thankyou}`])
          this.modal.close()
        } else {
          const model = {
            order: this.order,
            paymentType: this.order.paymenttype,
          }
          localStorage.last_order = JSON.stringify(model)
          this.router.navigate([`${this.thankyou}`])
          this.modal.close()
        }
      }
    })
  }
  ok() {
    if (this.data.optional.guest) {
      // this.router.navigate([`/`]);
      // this.modal.close();
      this.modalService.confirm({
        nzTitle: `กรุณาชำระเงินภายใน ${this.expire_days} วัน`,
        nzContent: `คุณสามารถชำระเงินภายหลังได้ที่ลิงค์นี้ <a href="${
          location.origin
        }/order-page/${this.utilService.enCodeId(this.data.optional.ordercode)}" target="_blank">${
          location.origin
        }/order-page/${this.utilService.enCodeId(this.data.optional.ordercode)}</b>`,
        nzOkText: 'เสร็จสิ้น',
        nzOkType: 'danger',
        nzOnOk: () => {
          this.router.navigate([`/`])
          this.modal.close()
        },
        nzCancelText: 'ยกเลิก',
        nzOnCancel: () => {},
      })
    } else {
      const memberhistory = this.path_lang.transform('mem-history')
      this.router.navigate([`${memberhistory}`])
      this.modal.close()
    }

    // console.log('test');
  }

  ngOnDestroy() {
    console.log('NG DESTROY')
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  saveQrCode() {
    const src = $('#qr_code > img').attr('src')
    const ele = document.createElement('a')
    ele.href = src
    ele.download = 'qrcode.png'
    ele.click()
    ele.remove()
  }
}
