import { Component, OnInit, Input, HostListener, AfterViewInit, ChangeDetectorRef } from '@angular/core'
import { NzDrawerRef } from 'ng-zorro-antd/drawer'
import { OrderService } from '@cms/services/order.service'
import { Etc } from '@cms/util/etc'
import { NzModalService } from 'ng-zorro-antd/modal'
import { UtilService } from '@cms/services/util.service'
import { ModalExportOrderComponent } from '@cms/system/modal/modal-export-order/modal-export-order.component'
import { PermissionDirective } from '@cms/dirctives/permission.directive'
import { FeatureService } from '@cms/services/feature.service'
import { ExtensionService } from '@cms/services/extension.service'

@Component({
  selector: 'app-send-to-peak',
  templateUrl: './send-to-peak.component.html',
  styleUrls: ['./send-to-peak.component.css'],
})
export class SendToPeakComponent implements OnInit, AfterViewInit {
  @Input() user?: any
  @Input() user_id?: any = ''
  @Input() peak_account = false

  _permission: PermissionDirective = new PermissionDirective()
  currentdatetime: any
  rangePicker: any = []
  allcreateby: any = []
  loading = true
  shippopimg: any = {}
  order: any = []
  total: any = 0
  statusimg: any
  paymentimg: any
  usernameSearch = ''
  modelsearch: any = {
    startdate: '',
    enddate: '',
    status: '4',
    ordercode: '',
    email: '',
    trackcode: '',
    search: '',
    paymenttype: '',
    created_by: '',
    page: 1,
    perpage: 10,
    facebook_id: '',
    user_id: '',
  }
  modelsearch_report: any = {
    startdate: '',
    enddate: '',
    ordercode: '',
  }
  currenttab: any = 0
  order_report: any = {}

  heightTable = 550

  search_by_user_state = true
  feature = false

  select_order: any[] = []

  constructor(
    private drawerRef: NzDrawerRef<string>,
    private Service_Util: UtilService,
    private Service_Order: OrderService,
    private modal: NzModalService,
    private _etc: Etc,
    private chRef: ChangeDetectorRef,
    private Service_Feature: FeatureService,
    private _extenion: ExtensionService,
  ) {}

  async ngOnInit() {
    //console.log(this.user)
    this.feature = await this.Service_Feature.getFeature('PRODUCT_PAGE')
    if (!this.feature) {
      return
    }

    try {
      if (this.user.info.id) {
        this.search_by_user_state = false
        this.modelsearch.facebook_id = this.user.info.id
      }
    } catch (err) {
      this.search_by_user_state = true
    }

    try {
      if (this.user_id) {
        this.search_by_user_state = false
        this.modelsearch.user_id = this.user_id
      }
    } catch (err) {
      this.search_by_user_state = true
    }

    try {
      if (this.peak_account) {
        this.peak_account = true
        this.modelsearch = {
          startdate: '',
          enddate: '',
          status: '4',
          ordercode: '',
          email: '',
          trackcode: '',
          search: '',
          paymenttype: '',
          created_by: '',
          page: 1,
          perpage: 10,
          facebook_id: '',
          user_id: '',
          peak: true,
          peak_status: '1',
        }
      }
    } catch (err) {}

    this.statusimg = this._etc.orderstatus_img()
    this.paymentimg = this._etc.payment_img()
    this.currentdatetime = await this.Service_Util.getDateTime()
    this.allcreateby = await this.Service_Order.getcreatedby()
    this.shippopimg = this.Service_Util.getShippopImage()

    this.search()

    window['_refreshordersearch'] = () => {
      //console.log('_refreshordersearch')
      this.search()
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize($event: any) {
    this.heightTable = this.tableHeight($event.target.innerHeight)
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.heightTable = this.tableHeight(window.innerHeight)
    }, 150)
  }

  tableHeight(height: number) {
    return height - 346
  }

  close() {
    this.drawerRef.close()
  }

  async checkInuseActivity(obj) {
    try {
      // console.log(obj);
      const rs = await this.Service_Util.inuseActivity({ type: 'order', param_id: obj.id })
      return rs
    } catch (err) {
      console.error(err)
    }
  }

  async edit(obj) {
    if (!this._permission.checkPermission(['administrator', 'webmaster', 'staff', 'sale', 'store'])) {
      this.modal.error({
        nzTitle: 'Error',
        nzContent: `Permission denied`,
      })
      return
    }
    const inuse: any = await this.checkInuseActivity(obj)
    if (!inuse.available) {
      this.modal.error({
        nzTitle: 'Error',
        nzContent: `${inuse.user} กำลังทำการแก้ไขรายการนี้อยู่`,
      })
      return
    }

    const url = '/system/e-commerce/order-detail/' + obj.ordercode
    window.open(url, '_blank', 'menubar=no,toolbar=no,location=no,width=1280,height=720')
  }

  pagechage(page) {
    this.modelsearch.page = page
    this.search()
  }

  searchreport() {
    this.Service_Order.orderreportstatus(this.modelsearch_report)
      .then((res: any) => {
        this.order_report = res
        console.log(res)
      })
      .catch((data) => {
        console.log(data.error.message)
      })
  }

  search() {
    this.loading = true
    this.order = []
    this.Service_Order.search(this.modelsearch)
      .then((res: any) => {
        //console.log(res);
        this.order = []

        for (const obj of res.data) {
          if (this.select_order.find((x) => x.ordercode == obj.ordercode)) {
            obj['select'] = true
            this.order = [...this.order, obj]
          } else {
            obj['select'] = false
            this.order = [...this.order, obj]
          }
        }

        this.total = res.count
        this.chRef.detectChanges()
      })
      .catch((data) => {
        console.log(data.error.message)
      })
      .finally(() => {
        this.loading = false
        this.chRef.detectChanges()
      })
    // console.log(this.rangePicker);
  }

  tapPeakChange(e: any) {
    // console.log(e)

    this.modelsearch.peak_status = '5'

    if (e.index == 0) {
      this.modelsearch.peak_type = 'quotations'
    }
    if (e.index == 1) {
      this.modelsearch.peak_type = 'invoices'
    }
    if (e.index == 2) {
      this.modelsearch.peak_type = 'receipts'
    }

    this.search()
  }

  resendToPeak(orderid: any) {
    if (orderid) {
      this.loading = true
      this._extenion
        .createQuotationByOrders({
          ids: [orderid],
        })
        .then((res) => {
          this.search()
        })
        .catch((err) => {
          console.log(err)
          this.search()
        })
    }
  }

  _alert() {
    const _m = this.modal.warning({
      nzTitle: 'แจ้งเตือนการใช้งาน',
      nzContent: `ไม่สามารถส่งได้มากกว่า 10 ราการต่อครั้ง`,
    })
  }

  createReceiptsByOrder() {
    const ids = []
    for (const obj of this.select_order) {
      ids.push(obj.id)
    }

    if (ids.length > 10) {
      this._alert()
      return
    }

    if (ids.length > 5) {
      const email = localStorage.getItem('_email')
      const _m = this.modal.warning({
        nzTitle: 'ระบบกำลังดำเนินการตามคำขอของท่าน',
        nzContent: `เนื่องจากมีการส่งมากกว่า 5 รายการ ระบบจะแจ้งผลไปยัง อีเมลของท่าน ( ${email} ) เมื่อดำเนินการเสร็จสิ้น`,
      })
      var _s = _m.afterClose.subscribe((s) => {
        $('.theme-loader').fadeIn()
        this.loading = true
        this._extenion
          .createReceiptsByOrder({
            send_mail: true,
            ids: ids,
          })
          .then((res) => {
            $('.theme-loader').fadeOut()
            this.close()
          })
          .catch((err) => {
            console.log(err)
            $('.theme-loader').fadeOut()
            this.close()
          })

        _s.unsubscribe()
      })
    } else {
      $('.theme-loader').fadeIn()
      this.loading = true
      this._extenion
        .createReceiptsByOrder({
          send_mail: true,
          ids: ids,
        })
        .then((res) => {
          $('.theme-loader').fadeOut()
          this.close()
        })
        .catch((err) => {
          console.log(err)
          $('.theme-loader').fadeOut()
          this.close()
        })
    }
  }

  selectOrder(obj: any) {
    if (this.select_order.length > 9) {
      this._alert()
      return
    }
    if (!this.select_order.find((x) => x.ordercode == obj.ordercode)) {
      obj['select'] = true
      this.select_order = [...this.select_order, obj]
    }
  }

  del_selectOrder(obj: any) {
    this.select_order = this.select_order.filter((d) => d.ordercode !== obj.ordercode)
    const orderIndex = this.order.findIndex((x) => x.ordercode == obj.ordercode)
    if (orderIndex !== -1) {
      this.order[orderIndex]['select'] = false
    }
  }

  selectAll() {
    // console.log(this.select_order.length)
    if (this.select_order.length > 9) {
      this._alert()
      return
    }
    for (const obj of this.order) {
      this.selectOrder(obj)
    }
  }

  selectCustomer(obj: any) {
    console.log(obj)

    this.usernameSearch = `${obj.name} ${obj.lastname}`
    this.modelsearch.search = `${obj.username}`
    this.search()
  }

  onSearchChange(e: any) {
    this.usernameSearch = `${e}`

    if (`${e}` == '') {
      this.modelsearch.search = ''
      this.search()
    }
  }

  export() {
    const modal = this.modal.create({
      nzTitle: 'Export',
      nzContent: ModalExportOrderComponent,
      nzStyle: { top: '20px' },
      // nzWidth: "750px",
      nzKeyboard: false,
      nzMaskClosable: false,
      nzOnOk: (componentInstance) => {
        return componentInstance.ok()
      },
      nzOnCancel: (componentInstance) => {},
      nzComponentParams: {
        data: {},
      },
    })
  }
}
