import { Component, OnInit } from '@angular/core'
import { FeatureService } from '@cms/services/feature.service'
import { ExtensionService } from '@cms/services/extension.service'
import { NzModalService } from 'ng-zorro-antd/modal'

import { PermissionDirective } from '@cms/dirctives/permission.directive'
import { ModalEditLazadaProductComponent } from '../modal/modal-edit-lazada-product/modal-edit-lazada-product.component'
import { ModalGetLazadaOrderComponent } from '../modal/modal-get-lazada-order/modal-get-lazada-order.component'
import { ModalJDProductComponent } from '../modal/modal-jd-product/modal-jd-product.component'
import { ModalGetJDOrderComponent } from '../modal/modal-get-jd-order/modal-get-jd-order.component'
declare let alasql: any

@Component({
  selector: 'app-jd',
  templateUrl: './jd.component.html',
  styleUrls: ['./jd.component.css'],
})
export class JDComponent implements OnInit {
  _permission: PermissionDirective = new PermissionDirective()
  feature = false
  model_search_product: any = {
    startdate: '',
    enddate: '',
    search: '',
    page: 1,
    perpage: 10,
  }
  product: any = []
  total_product = 0

  model_search_order: any = {
    page: 1,
    perpage: 10,
    order_id: '',
    order_code: '',
    startdate: '',
    enddate: '',
  }
  order: any = []
  total_order = 0
  total_sku = 0

  constructor(
    private Service_Feature: FeatureService,
    private Service_Extension: ExtensionService,
    private modal: NzModalService,
  ) {}

  async ngOnInit() {
    this.feature = await this.Service_Feature.getFeature('JDCENTRAL')
    if (!this.feature) {
      return
    }

    this.searchProduct()
    this.searchOrder()
  }

  syncProduct() {
    $('.theme-loader').fadeIn()
    this.Service_Extension.syncProductJD()
      .then((res) => {
        $('.theme-loader').hide()
        this.modal.success({
          nzTitle: 'ดำเนินการดึงสินค้า จาก JD Central',
          nzContent: `ขณะนี้ระบบกำลังดำเนินการดึงสินค้า จาก JD Central
                เมื่อดำเนินการเสร็จสิ้น ระบบจะแจ้งผลการเชื่อมต่อสินค้า ไปยังอีเมล
                เจ้าของเว็บไซต์ (Admin)`,
        })
      })
      .catch((data) => {
        $('.theme-loader').hide()
        console.log(data)
      })
  }

  openModalAddProduct() {
    const modal = this.modal
      .create({
        nzTitle: null,
        nzContent: ModalJDProductComponent,
        nzStyle: { top: '10px' },
        nzBodyStyle: {
          padding: '0px',
          background: '#f6f6f6',
        },
        nzClassName: 'modal-medium',
        nzWrapClassName: 'vertical-center-modal',
        nzWidth: '90%',
        nzKeyboard: false,
        nzMaskClosable: false,
        nzFooter: null,
        nzOnOk: (componentInstance) => {
          return componentInstance.ok()
        },
        nzOnCancel: (componentInstance) => {},
        nzComponentParams: {
          data: {
            state: 'create',
          },
        },
      })
      .afterClose.subscribe((result) => {
        modal.unsubscribe()
        console.log(result)
        if (result) {
          this.modal.success({
            nzTitle: 'Complete',
            nzContent: '',
          })
          this.searchProduct()
        }
      })
  }

  async viewOrder(obj: any) {
    console.log(obj)
    if (!this._permission.checkPermission(['administrator', 'webmaster', 'staff', 'sale', 'store'])) {
      this.modal.error({
        nzTitle: 'Error',
        nzContent: `Permission denied`,
      })
      return
    }

    const url = `${window.location.origin}/system/e-commerce/order-detail/${obj.ordercode}`
    window.open(url, '_blank', 'menubar=no,toolbar=no,location=no,width=1280,height=720')
  }
  sort(e: any) {
    this.model_search_product.sort = e.value == 'descend' ? 'desc' : e.value == 'ascend' ? 'asc' : ''
    this.model_search_product.order_by = e.value == null ? '' : e.key
    this.searchProduct()
  }
  searchProduct() {
    this.Service_Extension.searchJDProduct(this.model_search_product)
      .then((res: any) => {
        console.log(res)
        res.data.forEach((obj) => {
          obj.saleAttrs = alasql('SELECT * FROM ? ORDER BY orderSort', [obj.saleAttrs])
        })
        this.product = res.data
        this.total_product = res.count
        this.total_sku = res.total_sku
      })
      .catch((data) => {
        console.log(data)
      })
  }

  pagechageProduct(page) {
    this.model_search_product.page = page
    this.searchProduct()
  }

  getByOrderNumber() {
    const modal = this.modal
      .create({
        nzTitle: null,
        nzContent: ModalGetJDOrderComponent,
        nzBodyStyle: {
          // 'padding': '0px',
          // 'background': '#f6f6f6'
        },
        nzWidth: '500px',
        nzWrapClassName: 'vertical-center-modal',
        nzClassName: 'modal-medium',
        // nzKeyboard: false,
        // nzMaskClosable: false,
        nzFooter: null,
        nzOnOk: (componentInstance) => {
          return componentInstance.ok()
        },
        nzOnCancel: (componentInstance) => {},
        nzComponentParams: {
          data: {},
        },
      })
      .afterClose.subscribe((result) => {
        modal.unsubscribe()
        this.searchProduct()
      })
  }

  editProduct(obj) {
    const modal = this.modal
      .create({
        nzTitle: null,
        nzContent: ModalJDProductComponent,
        // nzStyle: { top: '20px' },
        nzBodyStyle: {
          padding: '0px',
          background: '#f6f6f6',
        },
        nzClassName: 'modal-medium',
        nzWrapClassName: 'vertical-center-modal',
        nzWidth: '90%',
        nzKeyboard: false,
        nzMaskClosable: false,
        nzFooter: null,
        nzOnOk: (componentInstance) => {
          return componentInstance.ok()
        },
        nzOnCancel: (componentInstance) => {},
        nzComponentParams: {
          data: {
            state: 'edit',
            productId: obj.productId,
          },
        },
      })
      .afterClose.subscribe((result) => {
        modal.unsubscribe()
        console.log(result)
        if (result) {
          this.modal.success({
            nzTitle: 'Complete',
            nzContent: '',
          })
          this.searchProduct()
        }
      })
  }

  syncOrderJD() {
    $('.theme-loader').show()
    this.Service_Extension.syncOrderJD()
      .then((res) => {
        console.log(res)
        this.modal.success({
          nzTitle: '',
          nzContent: `ขณะนี้ระบบกำลังดำเนินการดึงข้อมูล จาก JDCentral
  ซึ่งจะทำการดึงข้อมูลสำเร็จภายใน 5 นาที`,
        })
        $('.theme-loader').hide()
        this.searchOrder()
      })
      .catch((data) => {
        $('.theme-loader').hide()
        console.log(data.error.message)
      })
  }

  searchOrder() {
    this.Service_Extension.searchOrderJD(this.model_search_order)
      .then((res: any) => {
        this.order = res.data
        console.log(res.data)
        this.total_order = res.count
      })
      .catch((data) => {
        console.log(data.error.message)
      })
  }

  pagechageOrder(page) {
    this.model_search_order.page = page
    this.searchOrder()
  }
}
