import { Action, createReducer, on } from '@ngrx/store'
import { IWarehouseState } from './models'
import { actions_warehouse } from './actions'
import { cloneDeep, has } from 'lodash'

export const WarehouseFEATURE_KEY = 'warehouse'

export const initialWarehouseState: IWarehouseState.WarehouseState = {
  is_loading: false,
  list: [],
  info: {
    status: false,
    logo_status: false,
    logo: '',
    org_name: '',
    org_address: '',
    tax_no: '',
    org_phone: '',
    receipt: '',
    org_tax_phone: '',
    province: '',
    district: '',
    subdistrict: '',
    zipcode: ''
  },
  warehouse_transfer: {
    is_loading: false,
    list: {
      data: [],
      count: 0,
    },
    search_model: {
      page: 1,
      per_page: 10,
      warehouse_id: '',
      type: '',
      status: '',
      transfer_code: '',
    },
  },
  select_list: {},
  product_search_model: {},
  warehouse_product: {},
  is_loading_warehouse_product: {},
  warehouse_inventory: {
    data: [],
    count: 0,
  },
  warehouse_inventory_search_model: {
    warehouse_id: '',
    channels: [],
    channel_id: '',
    status: '',
    category_id: '',
    search: '',
    page: 1,
    per_page: 10,
    sort: 'DESC',
    sort_by: IWarehouseState.IInventorySearchSort.Id,
    type: 'normal',
  },
  warehouse_inventory_loading: false,
}

const reducer = createReducer(
  initialWarehouseState,
  on(actions_warehouse.Loadding, (state, { loadding }) => {
    return {
      ...state,
      is_loading: loadding,
    }
  }),
  on(actions_warehouse.GetWarehouseSuccess, (state, { list }) => {
    return {
      ...state,
      list,
    }
  }),
  on(actions_warehouse.GetWarehouseInfoSuccess, (state, { info }) => {
    return {
      ...state,
      info,
    }
  }),
  on(actions_warehouse.WarehouseTransfer.Loadding, (state, { loadding }) => {
    return {
      ...state,
      warehouse_transfer: {
        ...state.warehouse_transfer,
        is_loading: loadding,
      },
    }
  }),
  on(actions_warehouse.WarehouseTransfer.Success, (state, { list }) => {
    return {
      ...state,
      warehouse_transfer: {
        ...state.warehouse_transfer,
        list,
      },
    }
  }),
  on(actions_warehouse.WarehouseProducts.Selected.Init, (state, action) => {
    const select_list = cloneDeep(state.select_list)
    if (!select_list[action.from]) {
      select_list[action.from] = {
        data: [],
        apply_data: [],
      }
    }
    select_list[action.from].data = cloneDeep(select_list[action.from].apply_data)
    return {
      ...state,
      select_list: select_list,
    }
  }),
  on(actions_warehouse.WarehouseProducts.Selected.Select, (state, action) => {
    const select_list = cloneDeep(state.select_list)
    if (!select_list[action.from]) {
      select_list[action.from] = {
        data: [],
        apply_data: [],
      }
    }
    action.data.forEach((order) => {
      const find_index = select_list[action.from].data.findIndex((x) => x.id == order.id)
      if (action.action == 'add' && find_index == -1) {
        select_list[action.from].data.push(order)
      } else if (action.action == 'del' && find_index !== -1) {
        select_list[action.from].data.splice(find_index, 1)
      }
    })
    return {
      ...state,
      select_list: select_list,
    }
  }),
  on(actions_warehouse.WarehouseProducts.Selected.Apply, (state, action) => {
    const select_list = cloneDeep(state.select_list)
    if (!select_list[action.from]) {
      select_list[action.from] = {
        data: [],
        apply_data: [],
      }
    }
    select_list[action.from].apply_data = cloneDeep(select_list[action.from].data)
    return {
      ...state,
      select_list: select_list,
    }
  }),
  on(actions_warehouse.WarehouseProducts.Selected.Clear, (state, action) => {
    const select_list = cloneDeep(state.select_list)
    select_list[action.from] = {
      data: [],
      apply_data: [],
    }
    return {
      ...state,
      select_list: select_list,
    }
  }),
  on(actions_warehouse.WarehouseProducts.SetSearchModel, (state, action) => {
    const search_model = cloneDeep(state.product_search_model)
    search_model[action.from] = action.input
    return {
      ...state,
      product_search_model: search_model,
    }
  }),
  on(actions_warehouse.WarehouseProducts.SetProducts, (state, action) => {
    const list = cloneDeep(state.warehouse_product)
    if (!list[action.from]) {
      list[action.from] = {
        data: [],
        count: 0,
      }
    }
    list[action.from] = {
      data: action.data.data,
      count: action.data.count,
    }
    return {
      ...state,
      warehouse_product: list,
    }
  }),
  on(actions_warehouse.WarehouseProducts.SetLoading, (state, action) => {
    const is_loading = cloneDeep(state.is_loading_warehouse_product)
    is_loading[action.from] = action.val
    return {
      ...state,
      is_loading_warehouse_product: is_loading,
    }
  }),
  on(actions_warehouse.WarehouseInventory.SetLoading, (state, action) => {
    return {
      ...state,
      warehouse_inventory_loading: action.val,
    }
  }),
  on(actions_warehouse.WarehouseInventory.SetSearchModel, (state, action) => {
    // const search_model = cloneDeep(state.warehouse_inventory_search_model)
    // search_model = action.input
    return {
      ...state,
      warehouse_inventory_search_model: { ...state.warehouse_inventory_search_model, ...action.input },
    }
  }),
  on(actions_warehouse.WarehouseInventory.Set, (state, action) => {
    let data = cloneDeep(state.warehouse_inventory)
    data = {
      data: action.data,
      count: action.count,
    }
    return {
      ...state,
      warehouse_inventory: data,
    }
  }),
)

export function WarehouseReducer(state: IWarehouseState.WarehouseState, action: Action) {
  return reducer(state, action)
}
