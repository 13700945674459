import { Component, OnInit, Output, EventEmitter } from '@angular/core'
import { ExtensionService } from '@cms/services/extension.service'
import { NzModalService } from 'ng-zorro-antd/modal'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzDrawerRef } from 'ng-zorro-antd/drawer'
import { OrderService } from '@cms/services/order.service'
// import { cloneDeep } from 'lodash'
import { Etc } from '@cms/util/etc'
// import * as moment from 'moment'
import { IPeakAccount, PeakAccountService } from '@cms/services/peak-account.service'
import { SafeAny } from '@ketshopweb/ui/core/types'
import { cloneDeep } from 'lodash'
import { ShopeeService } from '@cms/services/shopee.service'
import { LazadaService } from '@cms/services/lazada.service'
import { TikTokService } from '@cms/services/tiktok.service'
import { LineShoppingService } from '@cms/services/line-shopping.service'
declare let navigator: SafeAny

@Component({
  selector: 'app-extension-peak',
  templateUrl: './peak.component.html',
  styleUrls: ['./peak.component.css'],
})
export class PeakComponent implements OnInit {
  @Output() back = new EventEmitter()
  constructor(
    private Service_Extension: ExtensionService,
    private message: NzMessageService,
    private modal: NzModalService,
    private Service_Order: OrderService,
    private _etc: Etc,
    private drawerRef: NzDrawerRef<string>,
    private peakAccountService: PeakAccountService,
    private Service_Shopee: ShopeeService,
    private Service_Lazada: LazadaService,
    private Service_TikTok: TikTokService,
    private Service_LineShopping: LineShoppingService,
  ) {}
  tabselected: any = 'desc'
  model: any = {}
  message_duration = 4000
  _temp_option: any
  _temp_custom1: any
  _temp_is_tax = true
  allcreateby: any = []
  modelsearch: any = {
    startdate: '',
    enddate: '',
    status: '',
    ordercode: '',
    email: '',
    trackcode: '',
    search: '',
    paymenttype: '',
    created_by: '',
    page: 1,
    perpage: 10,
    peak: true,
    peak_status: '1',
  }
  statusimg: any = []
  usernameSearch: any = ''
  paymentimg: any = []
  loading: any = false
  order: any = []
  total: any = 0
  peak_account: IPeakAccount.AccountsEntity & {
    client_token: string
  } = {
    client_token: '',
    option: 1,
    option_cod: 'include',
    product_set: 1,
    shipping_option: {
      lazada: true,
      shopee: true,
      line_shopping: true,
      tiktok: true,
    },
    marketplace_option: {
      lazada: true,
      shopee: true,
      line_shopping: true,
      tiktok: true,
    }
  }
  peak_model: {
    is_tax: boolean
    option_cod: 'include' | 'exclude'
    option: 1 | 2 | 3
    client_token: string
    status: number
    product_set: 1 | 2
    payment_option: {
      shopee: {
        main_payment: {
          type: number
          code: string
        }
        subsidy_payment:  {
          type: number
          code: string
        }
      },
      lazada: {
        main_payment: {
          type: number
          code: string
        }
        subsidy_payment:  {
          type: number
          code: string
        }
      },
      tiktok: {
        main_payment: {
          type: number
          code: string
        }
        subsidy_payment:  {
          type: number
          code: string
        }
      },
      line_shopping: {
        main_payment: {
          type: number
          code: string
        }
        subsidy_payment:  {
          type: number
          code: string
        }
      }
    }
    shipping_option: {
      lazada: boolean
      shopee: boolean
      line_shopping: boolean
      tiktok: boolean
    }
    marketplace_option: {
      lazada: boolean
      shopee: boolean
      line_shopping: boolean
      tiktok: boolean
    }
  } = {
    is_tax: true,
    option_cod: 'include',
    option: 1,
    client_token: '',
    status: 0,
    product_set: 1,
    payment_option: {
      shopee: {
        main_payment: {
          type: 1,
          code: ""
        },
        subsidy_payment:  {
          type: 1,
          code: ""
        }
      },
      lazada: {
        main_payment: {
          type: 1,
          code: ""
        },
        subsidy_payment:  {
          type: 1,
          code: ""
        }
      },
      tiktok: {
        main_payment: {
          type: 1,
          code: ""
        },
        subsidy_payment:  {
          type: 1,
          code: ""
        }
      },
      line_shopping: {
        main_payment: {
          type: 1,
          code: ""
        },
        subsidy_payment:  {
          type: 1,
          code: ""
        }
      }
    },
    shipping_option: {
      lazada: true,
      shopee: true,
      line_shopping: true,
      tiktok: true,
    },
    marketplace_option: {
      lazada: true,
      shopee: true,
      line_shopping: true,
      tiktok: true,
    },
  }
  is_model_change = false
  _shopee = false;
  _lazada = false;
  _tiktok = false;
  _line_shopping = false;

  async ngOnInit() {
    this.statusimg = this._etc.orderstatus_img()
    this.paymentimg = this._etc.payment_img()
    this.allcreateby = await this.Service_Order.getcreatedby()
    this._shopee = await this.Service_Shopee.listShop().then((data)=>{
      return data.length ? true:false;
    }).catch((err) => {
      return false;
    })
    this._lazada = await this.Service_Lazada.listShop().then((data)=>{
      return data.length ? true:false;
    }).catch((err) => {
      return false;
    })
    this._tiktok = await this.Service_TikTok.listShop().then((data)=>{
      return data.length ? true:false;
    }).catch((err) => {
      return false;
    })
    this._line_shopping = await this.Service_LineShopping.listShop().then((data)=>{
      return data.length ? true:false;
    }).catch((err) => {
      return false;
    })


    this.peakAccountService
      .accountInfo()
      .then(async (res) => {
        if (!res) return
        this.peak_account = cloneDeep(res as SafeAny)
        this.peak_account.client_token = res.api_key
        this.peak_model.client_token = res.api_key
        this.peak_model.is_tax = res.is_tax ? true : false
        this.peak_model.option_cod = res.option_cod
        this.peak_model.option = res.option
        this.peak_model.status = res.status
        this.peak_model.product_set = res.product_set
        this.peak_model.shipping_option = cloneDeep(res.shipping_option)
        this.peak_model.marketplace_option = cloneDeep(res.marketplace_option)
        // console.log('peak_model',this.peak_model)
        // console.log('peak_account',this.peak_account)
      })
      .catch(() => {})

    // this.Service_Extension.search({ name: 'Peak' }).then((res: any) => {
    //   console.log(res);
    //   if(!res.hasOwnProperty('is_tax')){
    //     res['is_tax'] = true;
    //   }
    //   this.model = res;
    //   this._temp_is_tax = this.model['is_tax'];
    //   console.log(this.model);
    //   this._temp_option = (this.model.option != null)? this.model.option: "2";
    //   this._temp_custom1 = (this.model.custom1 != null) ? cloneDeep(this.model.custom1) : { cod: "include"};
    // }).catch(data => {

    // });

    window['_check_extenion']()
  }
  selectCustomer(obj: any) {
    console.log(obj)

    this.usernameSearch = `${obj.name} ${obj.lastname}`
    this.modelsearch.search = `${obj.username}`
    this.search()
  }
  resend(obj) {}

  onSearchChange(e: any) {
    this.usernameSearch = `${e}`

    if (`${e}` == '') {
      this.modelsearch.search = ''
      this.search()
    }
  }
  search() {
    /*
    this.loading = true;
    this.Service_Order.search(this.modelsearch).then((res: any) => {
      console.log(res);
      this.order = res.data;
      this.total = res.count;
    }).catch((data) => {
      console.log(data.error.message);
    }).finally(() => {
      this.loading = false;
    });
    // console.log(this.rangePicker);
    */
  }
  pagechage(page) {
    this.modelsearch.page = page
    this.search()
  }
  setSelected(tab) {
    this.tabselected = tab
  }
  copylink(key) {
    // this.model.connected = 1;
    try {
      navigator.clipboard.writeText(key).then((res: any) => {
        this.message.success('copy success', { nzDuration: this.message_duration })
      })
    } catch (err) {}
  }
  change_key() {
    this.model.connected = 0
  }

  delete_key() {
    this.modal.confirm({
      nzTitle: `ต้องการยกเลิกการเชื่อมต่อกับ Peak Account?`,
      nzContent: '',
      nzOkText: 'Yes',
      nzOkType: 'danger',
      nzOnOk: () => {
        $('.theme-loader').show()
        this.peak_model.status = 0
        this.peakAccountService
          .connectAndDisconnect(this.peak_model)
          .then((res: any) => {
            this.ngOnInit()
            $('.theme-loader').fadeOut()
          })
          .catch((err) => {
            $('.theme-loader').fadeOut()
          })
      },
      nzCancelText: 'No',
      nzOnCancel: () => {},
    })
  }

  save() {
    // this.model.option = this._temp_option;
    // this.model.custom1 = this._temp_custom1;
    // this.model.is_tax = this._temp_is_tax;
    // this.connect()
    // checck marketplace_option change
    const marketplace_option = this.peak_model.marketplace_option
    const old_marketplace_option = this.peak_account.marketplace_option
    let is_marketplace_option_change = false
    for (const k in marketplace_option) {
      if (marketplace_option[k] !== old_marketplace_option[k]) {
        is_marketplace_option_change = true
        break
      }
    }
    if (is_marketplace_option_change) {
      this.modal.confirm({
        nzTitle: 'ต้องการบันทึกการตั้งค่าใหม่หรือไม่?',
        nzContent: 'การที่กลับไปมาเปิดให้ส่ง Order marketplace ไปที่ Peak Account หลังจากที่ปิด ระบบจะทำการส่งออเดอร์ที่มีการอัปเดตไปที่ Peak Account ได้ และในกรณีของระบบจะทำการส่งออเดอร์ที่ยังไม่ได้มีการส่งไปที่ Peak Account ย้อนหลัง 2 วัน',
        nzOkText: 'ยืนยัน',
        nzOkType: 'primary',
        nzOnOk: () => {
          this.connect()
        },
        nzCancelText: 'ยกเลิก',
        nzOnCancel: () => {},
      })
    } else {
      this.connect()
    }
  }

  connect() {
    $('.theme-loader').fadeIn()
    this.peak_model.status = 1
    this.peakAccountService
      .connectAndDisconnect(this.peak_model)
      .then((res: any) => {
        this.is_model_change = false
        this.ngOnInit()
        $('.theme-loader').fadeOut()
      })
      .catch((err) => {
        $('.theme-loader').fadeOut()
        this.modal.warning({
          nzTitle: 'ไม่สามารถเชื่อมต่อกับ Peak Account ได้',
          nzContent: 'กรุณาตรวจสอบการเชื่อมต่อกับ Peak Account อีกครั้ง',
          nzOnOk: () => {},
        })
      })
  }

  _back() {
    this.drawerRef.close()
  }

  onValueChange() {
    for (const k in this.peak_model) {
      if (typeof this.peak_model[k] !== 'object') {
        if (typeof this.peak_model[k] == 'boolean') {
          this.peak_account[k] = Boolean(this.peak_account[k])
        }
        if (this.peak_model[k] !== this.peak_account[k]) {
          this.is_model_change = true
          // console.log(k, this.peak_model[k], this.peak_account[k])
          return
        }
      } else if (JSON.stringify(this.peak_model[k]) !== JSON.stringify(this.peak_account[k])) {
        this.is_model_change = true
        // console.log(k, JSON.stringify(this.peak_model[k]), JSON.stringify(this.peak_account[k]))
        return
      }
    }
    // console.log('is_model_change', this.is_model_change)
    this.is_model_change = false
  }
}
