import { Component, OnInit, Input } from '@angular/core'
@Component({
  selector: 'ket-card-statistic',
  templateUrl: './card-statistic.component.html',
  styleUrls: ['./card-statistic.component.scss'],
})
export class KetCardStatisticComponent implements OnInit {
  @Input() type = 'fill'

  @Input() color = 'blue'
  // color [blue, violet, yellow, silver, red, red-light, green, cyan, gray]

  @Input() icon = null
  @Input() title: string = null
  @Input() tooltip: string = null

  @Input() value: any = null
  @Input() currency = true
  @Input() suffix: string = null

  @Input() value_2: any = null
  @Input() currency_2 = false
  @Input() suffix_2: string = null

  @Input() time = false
  @Input() data = ''

  constructor() {}

  ngOnInit(): void {}
}
