import { Component, OnInit, Input } from '@angular/core'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal'
import { cloneDeep } from 'lodash'
import { ExtensionService } from '@cms/services/extension.service'
import { LazadaService } from '@cms/services/lazada.service'

declare let alasql: any

@Component({
  selector: 'app-modal-get-lazada-order',
  templateUrl: './modal-get-lazada-order.component.html',
  styleUrls: ['./modal-get-lazada-order.component.css'],
})
export class ModalGetLazadaOrderComponent implements OnInit {
  @Input() data: any

  constructor(
    private Service_Lazada: LazadaService,
    private modalService: NzModalService,
    private modalRef: NzModalRef,
  ) {}

  validateOrderId = false

  model: any = {
    order_id: '',
  }
  type: any
  lazada_shop: any = []
  shop_id: any = null

  async ngOnInit() {
    this.type = this.data.type
    this.lazada_shop = await this.Service_Lazada.listShop()
    if (this.lazada_shop[0]) {
      this.shop_id = this.lazada_shop[0].shop_id
    }
  }

  ok() {
    console.log(this.model.order_id)
    if (!this.model.order_id && this.type == 'byID') {
      this.validateOrderId = true
      return false
    }
    if (!this.shop_id) {
      this.modalService.error({
        nzTitle: 'กรุณาเลือกร้านค้า',
      })
      return false
    }
    $('.theme-loader').fadeIn()
    const obj = {
      shop_id: this.shop_id,
      order_id_list: [this.model.order_id],
    }

    if (this.type == 'byID') {
      this.Service_Lazada.syncLazadaOrderByOrderID(obj)
        .then((res: any) => {
          $('.theme-loader').fadeOut()
          this.modalService.success({
            nzTitle: 'อยู่ระหว่างดำเนินการดึงข้อมูล จาก Lazada',
            nzContent: 'ขณะนี้ระบบกำลังดำเนินการดึงข้อมูล จาก Lazada ซึ่งจะทำการดึงข้อมูลสำเร็จภายใน 5 นาที',
            nzOkText: 'ตกลง',
            nzWrapClassName: 'vertical-center-modal',
          })
          this.modalRef.close(true)
        })
        .catch((data) => {
          $('.theme-loader').fadeOut()
          this.modalService.error({
            nzTitle: data.error.message,
          })
        })
    }

    return false
  }

  close() {
    this.modalRef.close(true)
  }
}
