import { Component, OnInit, Input } from '@angular/core'
import * as moment from 'moment'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-modal-error',
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.css'],
})
export class ModalErrorComponent implements OnInit {
  @Input() data: any

  ngOnInit() {
    console.log(this.data)
  }

  ok() {}
}
