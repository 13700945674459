import { createFeatureSelector, createSelector } from '@ngrx/store'
import { cloneDeep } from 'lodash'
import { selectors_channels } from '../channels'
import { IOrdersState } from './models'
import { default_order_search_model, FEATURE_KEY } from './reducer'

const selectState = createFeatureSelector<IOrdersState.OrdersState>(FEATURE_KEY)

const search_model = createSelector(selectState, (state: IOrdersState.OrdersState) => state.search_model)

const list_order = createSelector(selectState, (state: IOrdersState.OrdersState) => state.list)

const select_list_order = createSelector(selectState, (state: IOrdersState.OrdersState) => state.select_list)

const is_loading = createSelector(selectState, (state: IOrdersState.OrdersState) => state.is_loading)

const order_status_img = createSelector(selectState, (state: IOrdersState.OrdersState) => state.order_status_img)

const types_of_payment_service = createSelector(
  selectState,
  (state: IOrdersState.OrdersState) => state.types_of_payment_service,
)

const types_of_delivery_service = createSelector(
  selectState,
  (state: IOrdersState.OrdersState) => state.types_of_delivery_service,
)

const list_staff = createSelector(selectState, (state: IOrdersState.OrdersState) => state.list_staff)

// by props
const search_model_from = (props: { from: string }) =>
  createSelector(
    search_model,
    order_status_img,
    types_of_payment_service,
    selectors_channels.list_channel,
    (data, order_status_img, types_of_payment_service, list_channel) => {
      const model: IOrdersState.SearchModel = cloneDeep(data[props.from] || default_order_search_model())
      const filter_tags: IOrdersState.FilterTag[] = []
      // channels
      if (model.channels.length === 0 || model.channels.length == list_channel.all.length) {
        // :: ขออนุญาติปิด func ส่วนนี้นะคะ ถ้าเป็น ทุกช่องทางขาย จะไม่แสดงค่ะ
        // filter_tags.push({
        //     type: 'channels',
        //     name: 'ทุกช่องทางขาย',
        //     value: model.channels,
        //     is_close: false
        // })
      } else {
        const channels = list_channel.all.filter((x) => model.channels.includes(x.value))
        const channel_website = channels.filter((x) => x.group == 'website')
        const channel_marketplace = channels.filter((x) => x.group == 'marketplace')
        if (channel_website.length == list_channel.website.length) {
          filter_tags.push({
            type: 'channels_website',
            name: 'ทั้งหมดบนเว็บไซต์',
            value: channel_website.map((x) => x.value),
            is_close: true,
            onClose: () => {
              model.channels = model.channels.filter((x) => !channel_website.map((x) => x.value).includes(x as any))
              return model
            },
          })
        } else {
          channel_website.forEach((c) => {
            filter_tags.push({
              type: 'channel_website',
              name: c.name,
              img: c.img,
              value: c.value,
              is_close: true,
              onClose: () => {
                model.channels = model.channels.filter((x) => x !== c.value)
                return model
              },
            })
          })
        }

        if (channel_marketplace.length == list_channel.marketplace.length) {
          filter_tags.push({
            type: 'channels_marketplace',
            name: 'ทั้งหมดบนมาร์เก็ตเพลส',
            value: channel_marketplace.map((x) => x.value),
            is_close: true,
            onClose: () => {
              model.channels = model.channels.filter((x) => !channel_marketplace.map((x) => x.value).includes(x as any))
              return model
            },
          })
        } else {
          channel_marketplace.forEach((c) => {
            filter_tags.push({
              type: 'channel_marketplace',
              name: c.name,
              img: c.img,
              value: c.value,
              is_close: true,
              onClose: () => {
                model.channels = model.channels.filter((x) => x !== c.value)
                return model
              },
            })
          })
        }
      }

      // status
      // order_status_img
      if (model.status && model.status !== '-') {
        const status = order_status_img[model.status]
        filter_tags.push({
          type: 'status',
          name: status.name,
          img: status.img,
          value: status.status,
          is_close: true,
          onClose: () => {
            model.status = '-'
            return model
          },
        })
      }

      // paymenttype
      // types_of_payment_service
      if (model.paymenttype && model.paymenttype !== '-') {
        const paymenttype = types_of_payment_service[model.paymenttype]
        filter_tags.push({
          type: 'status',
          name: paymenttype.name,
          img: paymenttype.img,
          value: paymenttype.id,
          is_close: true,
          onClose: () => {
            model.paymenttype = '-'
            return model
          },
        })
      }

      // channel_name
      // sell_channel
      if(model.sell_channel && model.sell_channel.length){
        for(let s of model.sell_channel){
          filter_tags.push({
            type: 'status',
            name: s.channel_name || s.channel_social_name,
            value: s,
            img: s.img,
            is_close: true,
            onClose: () => {
              let ind = model.sell_channel.findIndex(x=>x.channel_id == s.channel_id);
              if(ind > -1){
                model.sell_channel.splice(ind,1);
              }
              return model
            },
          })
        }
      }
      

      // ฉพาะที่มีหมายเหตุ
      if (model.is_note) {
        filter_tags.push({
          type: 'status',
          name: 'มีหมายเหตุ',
          value: model.is_note,
          is_close: true,
          onClose: () => {
            model.is_note = false
            return model
          },
        })
      }
      // เฉพาะที่ออกใบกำกับภาษี
      if (model.is_tax_no) {
        filter_tags.push({
          type: 'status',
          name: 'ออกใบกำกับภาษี',
          value: model.is_tax_no,
          is_close: true,
          onClose: () => {
            model.is_tax_no = false
            return model
          },
        })
      }

      // สร้างโดย
      if (model.created_by && model.created_by != '-') {
        filter_tags.push({
          type: 'created_by',
          name: model.created_by,
          value: model.created_by,
          is_close: true,
          onClose: () => {
            model.created_by = '-'
            return model
          },
        })
      }

      return {
        from: props.from,
        model: model,
        filter_tags: filter_tags,
      }
    },
  )

const list_order_from = (props: { from: string }) =>
  createSelector(list_order, (data) => {
    return {
      from: props.from,
      list: data[props.from] || {
        data: [],
        count: 0,
      },
    }
  })
//select_list_order
const select_list_order_from = (props: { from: string }) =>
  createSelector(select_list_order, (data) => {
    return {
      from: props.from,
      list: data[props.from] || {
        data: [],
        apply_data: [],
      },
    }
  })

export const selectors_orders = {
  search_model,
  search_model_from,
  list_order_from,
  list_order,
  is_loading,
  order_status_img,
  types_of_payment_service,
  types_of_delivery_service,
  select_list_order,
  select_list_order_from,
  list_staff,
}
