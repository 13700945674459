import { SafeAny } from '@ketshopweb/ui/core/types'
import { cloneDeep } from 'lodash'
import { Observable } from 'rxjs'
import { IProductsState } from '@store/ketshopweb-store'
import { html_ts, IProductPrintLabel } from '../html_ts'
import * as moment from 'moment'
import { format_money } from './format_money'
import { qr_to_base64 } from './qr_to_base64'
declare let JsBarcode: SafeAny

export function product_export_label(
  type: 'qr' | 'barcode',
  size: 'A4' | 'A10',
  products: IProductsState.ProductItem[],
) {
  let print: IProductPrintLabel
  if (size == 'A4') {
    print = html_ts.product_export_label_a4.init()
  } else if (size == 'A10') {
    print = html_ts.product_export_label_a10.init()
  }

  new Observable<IProductsState.ProductItem[]>((ob) => {
    let req_data: IProductsState.ProductItem[] = []
    for (const product of products) {
      if (req_data.length < 30) {
        req_data.push(product)
      } else {
        ob.next(cloneDeep(req_data))
        req_data = []
        req_data.push(product)
      }
    }
    ob.next(cloneDeep(req_data))
    setTimeout(() => {
      ob.complete()
    }, 100)
  }).subscribe({
    next: async (req_data) => {
      const page_print = print.page()
      for (const item of req_data) {
        const item_model = {
          title: '',
          price: '',
          img: '',
        }
        let title = item.title_lang1
        if (item.prop_enable == 1) title += ' ' + item.property_info
        if (item.prop_enable == 2) title += ' ' + item.property_info2
        item_model.title = title
        item_model.price = `฿${format_money(item.price)}`
        if (type == 'qr') {
          const qrcode_url = (await qr_to_base64(item.sku, {
            scale: 4,
            width: 120,
            margin: 1,
          })) as string
          item_model.img = qrcode_url
          console.log(qrcode_url)
        } else if (type == 'barcode') {
          try {
            const canvas = document.createElement('canvas')
            // Option
            // https://github.com/lindell/JsBarcode
            JsBarcode(canvas, item.barcode, {
              format: item.barcode_format,
              lineColor: '#000',
              width: 1,
              height: 30,
              displayValue: true,
            })
            const barcode_url = canvas.toDataURL('image/png')
            item_model.img = barcode_url
          } catch {
            const canvas = document.createElement('canvas')
            canvas.width = 220
            canvas.height = 65
            const ctx = canvas.getContext('2d')
            ctx.fillStyle = '#f4f4f4'
            ctx.fillRect(15, 5, 190, 50)
            const barcode_url = canvas.toDataURL('image/png')
            item_model.img = barcode_url
          }
        }
        page_print.add_item(type == 'qr' ? 'qrcode' : 'barcode', item_model)
      }
    },
    complete: () => {
      // const _w = window.open()
      setTimeout(() => {
        // document.write(print.render(`Products-Label-${moment().format('YYYY-MM-DD-THH_mm')}`))
        const html = print.render(`Products-Label-${moment().format('YYYY-MM-DD-THH_mm')}`)
        // to blob url
        const blob = new Blob([html], { type: 'text/html' })
        const blob_url = URL.createObjectURL(blob)
        window.open(blob_url)
      }, 20)
    },
  })
}
