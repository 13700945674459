import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetOrderTagsSelectorComponent } from './order-tags-selector.component'
import {
  NzAvatarModule,
  NzButtonModule,
  NzCheckboxModule,
  NzInputModule,
  NzRadioModule,
  NzSelectModule,
  NzTagModule,
} from 'ng-zorro-antd'
import { FormsModule } from '@angular/forms'
import { InfiniteScrollModule } from '@cms/libs/ngx-infinite-scroll/ngx-infinite-scroll'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzSpinModule } from 'ng-zorro-antd/spin'

@NgModule({
  declarations: [KetOrderTagsSelectorComponent],
  imports: [
    CommonModule,
    NzInputModule,
    NzIconModule,
    NzSelectModule,
    NzAvatarModule,
    NzCheckboxModule,
    NzRadioModule,
    FormsModule,
    InfiniteScrollModule,
    NzTagModule,
    NzButtonModule,
    NzSpinModule,
  ],
  exports: [KetOrderTagsSelectorComponent],
})
export class KetOrderTagsSelectorModule {}
