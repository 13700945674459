import { Component, OnInit, Input } from '@angular/core'
import { UtilService } from '@cms/services/util.service'

import { NzModalService } from 'ng-zorro-antd/modal'

import * as moment from 'moment'
import { Router } from '@angular/router'
import { TeamService } from '@cms/services/team.service'
import { ProductsService } from '@cms/services/products.service'
import { OrderService } from '@cms/services/order.service'
import { Etc } from '@cms/util/etc'
import { environment } from 'src/environments/environment'
import { AuthenService } from '@cms/services/authen.service'

@Component({
  selector: 'app-report-team-warehouse',
  templateUrl: './report-team-warehouse.component.html',
  styleUrls: ['./report-team-warehouse.component.css'],
})
export class ReportTeamWarehouseComponent implements OnInit {
  @Input() id: any
  rangePicker: any = []
  currentdatetime: any
  productlog: any = []
  totalLog = 0
  loadingLog = false
  loading = false
  order: any = []
  total = 0
  statusimg: any
  paymentimg: any
  shippopimg: any
  api_path: string
  access_token: string
  team_data: any = []

  model_log: any = {
    startdate: '',
    enddate: '',
    search: '',
    page: 1,
    perpage: 10,
    created_by: null,
  }
  model_order: any = {
    status: '',
    ordercode: '',
    trackcode: '',
    search: '',
    paymenttype: '',
    created_by: '',
    page: 1,
    perpage: 10,
  }
  constructor(
    private router: Router,
    private ServiceTeam: TeamService,
    private Service_Util: UtilService,
    private Service_Product: ProductsService,
    private Service_Order: OrderService,
    private _etc: Etc,
    private modal: NzModalService,
    private _authen: AuthenService,
  ) {}
  async ngOnInit() {
    // console.log(this.id);
    this.api_path = environment.api_path
    this.access_token = this._authen.getToken().access_token
    this.statusimg = this._etc.orderstatus_img()
    this.paymentimg = this._etc.payment_img()
    this.shippopimg = this.Service_Util.getShippopImage()
    const team: any = await this.ServiceTeam.getTeamById(this.id)
    this.model_log.created_by = team.usr_name
    this.model_order.withdraw_by = team.usr_name
    this.currentdatetime = await this.Service_Util.getDateTime()
    this.rangePicker = [
      moment(this.currentdatetime.date).add(-1, 'month').format('YYYY-MM-DD'),
      moment(this.currentdatetime.date).format('YYYY-MM-DD'),
    ]
    this.dateRangeChange()
    this.searchOrder()
    this.team_data = await this.ServiceTeam.getTeamById(this.id)
  }

  dateRangeChange() {
    try {
      this.model_log.startdate = moment(this.rangePicker[0]).format('YYYY-MM-DD')
      this.model_log.enddate = moment(this.rangePicker[1]).format('YYYY-MM-DD')
      // this.modelsearch_report.startdate = moment(this.rangePicker[0]).format('YYYY-MM-DD');
      // this.modelsearch_report.enddate = moment(this.rangePicker[1]).format('YYYY-MM-DD');
    } catch (e) {
      console.log(e)
      // this.modelsearch.startdate = "";
      // this.modelsearch.enddate = "";
      // this.modelsearch_report.startdate = "";
      // this.modelsearch_report.enddate = "";
    }
    this.searchProductLog()
  }

  searchProductLog() {
    this.loadingLog = true
    this.Service_Product.searchProductLog(this.model_log)
      .then((res: any) => {
        console.log(res)
        this.productlog = res.data
        this.totalLog = res.count
        this.loadingLog = false
      })
      .catch((data) => {
        this.loadingLog = false
      })
  }
  pagechageLog(page) {
    this.model_log.page = page
    this.searchProductLog()
  }

  searchOrder() {
    this.loading = true
    this.Service_Order.searchByWithDraw(this.model_order)
      .then((res: any) => {
        console.log(res)
        this.loading = false
        this.order = res.data
        this.total = res.count
      })
      .catch((data) => {
        this.loading = false
      })
  }

  pagechage(page) {
    this.model_order.page = page
    this.searchOrder()
  }

  async checkInuseActivity(obj) {
    try {
      const rs = await this.Service_Util.inuseActivity({ type: 'order', param_id: obj.id })
      console.log(rs)
      return rs
    } catch (err) {
      console.error(err)
    }
  }

  async edit(obj) {
    const inuse: any = await this.checkInuseActivity(obj)
    if (!inuse.available) {
      this.modal.error({
        nzTitle: 'Error',
        nzContent: `${inuse.user} กำลังทำการแก้ไขรายการนี้อยู่`,
      })
      return
    }

    const url = window.location.origin + '/system/e-commerce/order-detail/' + obj.ordercode
    window.open(url, '_blank', 'menubar=no,toolbar=no,location=no,width=1280,height=720')
    // let url = this.router.url + '/edit/' + obj.ordercode;
    // window.open(url, '_blank', "menubar=no,toolbar=no,location=no,width=1100,height=660");
  }

  export() {
    $('#report-warehouse-export').submit()
  }

  backto_teams() {
    this.router.navigate(['/system/users/teams'])
  }
}
