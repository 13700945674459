import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core'
import { KetshopwebPromotionService } from '@cms/services/ketshopweb-promotion.service'
import { Store } from '@ngrx/store'
import { NgStoreCore, selectors_client_user } from '@store/ketshopweb-store'
// import { EventEmitter } from 'protractor'
@Component({
  selector: 'ket-modal-tag-promo-point',
  templateUrl: './modal-tag-promo-point.component.html',
  styleUrls: ['./modal-tag-promo-point.component.scss'],
})
export class KetModalTagPromoPointComponent implements OnInit {
  constructor(
    private store: Store<{ app_store: any }>,
    private Service_KetshopwebPromotion: KetshopwebPromotionService,
  ) {}

  private ngStoreCore: NgStoreCore = new NgStoreCore(this.store)

  @Input() product_setting: any
  @Input() promo: any
  @Input() size = false
  @Input() system = false
  @Output() Onpromo = new EventEmitter()

  member_level_id = 0

  promo_MemberLevel = false
  async ngOnInit() {
    this.ngStoreCore.subscribe('[client_user] me', selectors_client_user.get_me, (res) => {
      if (!res) return
      if (Object.prototype.hasOwnProperty.call(res, 'user_member_level') && res.user_member_level.member_level_id) {
        this.member_level_id = res.user_member_level.member_level_id as unknown as number
      }
    })
    console.log(this.member_level_id)

    const model = {
      promo_type: 'multiplied_points',
      promo_id: this.promo.promotion_id,
      member_level_id: this.member_level_id,
      is_active: true,
    }

    await this.Service_KetshopwebPromotion.listApplyMemberLevel(model)
      .then((res) => {
        if (res.length > 0 && this.member_level_id != 0) {
          this.promo_MemberLevel = true
        } else {
          this.Onpromo.emit(this.promo.promotion_id)
        }
      })
      .catch((err) => console.log(err))
  }
}
