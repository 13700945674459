import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { ClientUuserEffects } from './effects'
import { ClientUuserFEATURE_KEY, ClientUuserReducer } from './reducer'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(ClientUuserFEATURE_KEY, ClientUuserReducer),
    EffectsModule.forFeature([ClientUuserEffects]),
  ],
})
export class ClientUuserSateModule {}
