import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

export namespace ILazada {
  export interface Shop {
    shop_id: string
    shop_name: string
  }
}

@Injectable({
  providedIn: 'root',
})
export class LazadaService {
  constructor(private _http: HttpClient) {}

  checkLazadaOrderDetail(data:any){
    return this._http.post(`/services/lazada/order/checkLazadaOrderDetail`, data).toPromise() as Promise<any>
  }

  resendReadyToShipByGroup(data: any) {
    return this._http.post(`/services/lazada/order/resendReadyToShipByGroup`, data).toPromise() as Promise<any>
  }

  resendReadyToShip(data: any) {
    return this._http.post(`/services/lazada/order/resendReadyToShip`, data).toPromise() as Promise<any>
  }

  resendPack(data: any) {
    return this._http.post(`/services/lazada/order/resendPack`, data).toPromise() as Promise<any>
  }

  authorizationShopUrl(): Promise<{ url: string }> {
    return this._http.get('/services/lazada/shop/authorizationShopUrl').toPromise() as Promise<{ url: string }>
  }

  removeShop(shop_id: any): Promise<ILazada.Shop[]> {
    return this._http.get(`/services/lazada/shop/remove/${shop_id}`).toPromise() as Promise<any>
  }

  listShop(): Promise<ILazada.Shop[]> {
    return this._http.get('/services/lazada/shop/listShop').toPromise() as Promise<ILazada.Shop[]>
  }
  searchKetshopLazadaProduct(data: any): Promise<any> {
    return this._http.post('/services/lazada/product/searchKetshopLazadaProduct', data).toPromise() as Promise<any>
  }
  getCategory(): Promise<any> {
    return this._http.get('/services/lazada/product/getCategory').toPromise() as Promise<any>
  }
  getCategoryAttribute(data: any): Promise<any> {
    return this._http.post('/services/lazada/product/categoryAttribute', data).toPromise() as Promise<any>
  }
  searchKetshopProductToLazada(data: any): Promise<any> {
    return this._http.post('/services/lazada/product/searchKetshopProductToLazada', data).toPromise() as Promise<any>
  }
  searchBrand(data: any): Promise<any> {
    return this._http.post('/services/lazada/product/searchBrand', data).toPromise() as Promise<any>
  }
  searchKetshopLazadaOrder(data: any): Promise<any> {
    return this._http.post('/services/lazada/order/searchKetshopLazadaOrder', data).toPromise() as Promise<any>
  }
  checkConnection(): Promise<any> {
    return this._http.get('/services/lazada/shop/checkConnection').toPromise() as Promise<any>
  }
  updateProduct(data: any): Promise<any> {
    return this._http.post('/services/lazada/product/updateProduct', data).toPromise() as Promise<any>
  }
  createProduct(data: any): Promise<any> {
    return this._http.post('/services/lazada/product/createProduct', data).toPromise() as Promise<any>
  }
  getProductLazadaByItemID(item_id) {
    return this._http.get('/services/lazada/product/byItemID/' + item_id).toPromise() as Promise<any>
  }
  deliveredLazadaOrder(data: any): Promise<any> {
    return this._http.post('/services/lazada/order/deliveredLazadaOrder', data).toPromise() as Promise<any>
  }
  syncProductsState(shop_id: number): Promise<any> {
    return this._http.get('/services/lazada/product/syncProductsState/' + shop_id).toPromise() as Promise<any>
  }
  unsyncProducts(shop_id: number): Promise<any> {
    return this._http.get('/services/lazada/product/unsyncProducts/' + shop_id).toPromise() as Promise<any>
  }
  syncProducts(shop_id: number): Promise<any> {
    return this._http.get('/services/lazada/product/syncProducts/' + shop_id).toPromise() as Promise<any>
  }
  syncProductsByID(shop_id:number,item_id: number): Promise<any> {
    return this._http.get('/services/lazada/product/syncProductsByID/' + shop_id + '/' + item_id).toPromise() as Promise<any>

  }
  deliveredLazadaOrderBatch(data: any): Promise<any> {
    return this._http.post('/services/lazada/order/deliveredOrderBatch', data).toPromise() as Promise<any>
  }
  shippingDocument(data: any) {
    return this._http.post('/services/lazada/order/shippingDocument', data).toPromise() as Promise<any>
  }
  shippingDocumentV1(data: any) {
    return this._http.post('/services/lazada/order/shippingDocumentV1', data).toPromise() as Promise<any>
  }
  searchSyncProduct(data: any, shop_id: number): Promise<any> {
    return this._http.post('/services/lazada/product/searchSyncProduct/' + shop_id, data).toPromise() as Promise<any>
  }
  syncOrdersState() {
    return this._http.get('/services/lazada/order/syncOrdersState').toPromise() as Promise<any>
  }
  syncOrders() {
    return this._http.get('/services/lazada/order/syncOrders').toPromise() as Promise<any>
  }

  syncLazadaOrderByOrderID(data: any): Promise<any> {
    return this._http.post('/services/lazada/order/syncOrderByOrderID', data).toPromise() as Promise<any>
  }

  createOrderKetshop(data: any): Promise<any> {
    return this._http.post('/services/lazada/order/createOrderKetshop', data).toPromise() as Promise<any>
  }
}
