import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { select, Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import { cloneDeep } from 'lodash'
import { UtilService } from '@cms/services/util.service'
@Injectable({
  providedIn: 'root',
})
export class KetTranslateService {
  _subscribe: Subscription = null

  constructor(
    private _http: HttpClient,
    private store: Store<any>,
    private _util: UtilService,
  ) {
    if (!this._subscribe) {
      this.store.pipe(select('app_store', 'LANG_FORMAT')).subscribe((res: any[]) => {
        // console.log(res);
        this.data = cloneDeep(res)
      })
    }
  }

  data: any[] = []

  async translateAsync(text, from, type = 'text') {
    // console.log('=============    translate ===================');
    // let lang = localStorage.getItem('lang') || 'lang1';
    if (!this.data.length) {
      this.data = await this._util
        .getAllLangFormat()
        .then((data: any) => data)
        .catch((res) => [])
    }
    const lang = this.getCurrentLang()
    let result = this.data
      .filter((x) => x.from == from)
      .find((x) => `${x.default}`.trim().toLocaleUpperCase() == `${text}`.trim().toLocaleUpperCase())

    if (result == 'undefined') {
      result = undefined
    }
    if (!result) {
      const obj = {
        type: type,
        from: from || 'other',
        text: text,
      }
      this._http
        .post('/services/v2/ketcms-frontend/utils/lang_format', obj)
        .toPromise()
        .then((resp: any) => {})
        .catch((err: any) => {})

      return text
    }
    // console.log(result[lang])
    return result[lang]
  }

  translate(text, from, type = 'text') {
    // console.log('=============    translate ===================');
    // let lang = localStorage.getItem('lang') || 'lang1';

    const lang = this.getCurrentLang()
    let result = this.data
      .filter((x) => x.from == from)
      .find((x) => `${x.default}`.trim().toLocaleUpperCase() == `${text}`.trim().toLocaleUpperCase())

    if (result == 'undefined') {
      result = undefined
    }
    if (!result) {
      const obj = {
        type: type,
        from: from || 'other',
        text: text,
      }
      this._http
        .post('/services/v2/ketcms-frontend/utils/lang_format', obj)
        .toPromise()
        .then((resp: any) => {})
        .catch((err: any) => {})

      return text
    }
    // console.log(result[lang])
    return result[lang]
  }

  isBackEnd() {
    const search = location.href.search(`${window.location.origin}/system`)
    // console.log("isbackend", search);
    if (search == -1) {
      return false
    } else {
      return true
    }
  }

  getCurrentLang() {
    const backend = this.isBackEnd()
    let lang = 'lang1'

    if (backend) {
      lang = localStorage.getItem('lang_backend')
      if (!lang) {
        localStorage.setItem('lang_backend', 'lang1')
      }
      lang = localStorage.getItem('lang_backend')
    } else {
      lang = localStorage.getItem('lang')
      if (!lang) {
        lang = 'lang1'
      }
    }
    return lang
  }
}
