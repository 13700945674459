import { NzModalService } from 'ng-zorro-antd/modal'
import { Injectable, inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, concatMap, delay, map, mergeMap, switchMap, tap } from 'rxjs/operators'
import { actions_warehouse } from './actions'
import { KetshopwebServices } from 'ketshopweb-services'
import { Store } from '@ngrx/store'
import { IWarehouseState } from './models'
import { Observable, Subscription, of } from 'rxjs'
import { cloneDeep, has } from 'lodash'
import { initialWarehouseState } from './reducer'
import { SafeAny } from '@ketshopweb/ui/core/types'
import { store_utils } from 'libs/ketshopweb-store/func'
import { selectors_warehouse } from './selectors'

@Injectable()
export class WarehouseEffects {
  // inject
  private actions$ = inject(Actions)
  private WarehouseService = inject(KetshopwebServices.WarehouseService)
  private store = inject(Store)
  private nzModalService = inject(NzModalService)

  private subscription_search: {
    [key: string]: Subscription
  } = {}

  $GetWarehouse = createEffect(() =>
    this.actions$.pipe(
      ofType(actions_warehouse.GetWarehouse),
      tap(() => this.store.dispatch(actions_warehouse.Loadding({ loadding: true }))), // Set loadding
      mergeMap((_) =>
        this.WarehouseService.list_warehouse().pipe(
          catchError((error) => {
            console.log(error)
            this.store.dispatch(actions_warehouse.Loadding({ loadding: false }))
            this.nzModalService.error({
              nzTitle: 'เกิดข้อผิดพลาด',
              nzContent: error.error.message,
            })
            return [] as Partial<IWarehouseState.WarehouseState['list']>
          }),
        ),
      ), // API Call
      map((list) => {
        this.store.dispatch(actions_warehouse.Loadding({ loadding: false }))
        return actions_warehouse.GetWarehouseSuccess({ list: list as IWarehouseState.WarehouseState['list'] })
      }),
    ),
  )

  $RemoveWarehouse = createEffect(() =>
    this.actions$.pipe(
      ofType(actions_warehouse.RemoveWarehouse),
      tap(() => this.store.dispatch(actions_warehouse.Loadding({ loadding: true }))), // Set loadding
      mergeMap((action) =>
        this.WarehouseService.remove_warehouse(action.warehouse_id).pipe(
          catchError((error) => {
            console.log('remove_warehouse', error)
            this.store.dispatch(actions_warehouse.Loadding({ loadding: false }))
            this.nzModalService.error({
              nzTitle: 'เกิดข้อผิดพลาด',
              nzContent: error.error.message,
            })
            return of([] as Partial<IWarehouseState.WarehouseState['list']>)
          }),
        ),
      ), // API Call Remove
      mergeMap((_) =>
        this.WarehouseService.list_warehouse().pipe(
          catchError((error) => {
            console.log('list_warehouse', error)
            this.store.dispatch(actions_warehouse.Loadding({ loadding: false }))
            this.nzModalService.error({
              nzTitle: 'เกิดข้อผิดพลาด',
              nzContent: error.error.message,
            })
            return [] as Partial<IWarehouseState.WarehouseState['list']>
          }),
        ),
      ), // API Call Get List
      map((list) => {
        this.store.dispatch(actions_warehouse.Loadding({ loadding: false }))
        return actions_warehouse.GetWarehouseSuccess({ list: list as IWarehouseState.WarehouseState['list'] })
      }),
    ),
  )

  $CreateWarehouse = createEffect(() =>
    this.actions$.pipe(
      ofType(actions_warehouse.CreateWarehouse),
      tap(() => this.store.dispatch(actions_warehouse.Loadding({ loadding: true }))), // Set loadding
      mergeMap((action) =>
        this.WarehouseService.create_warehouse({
          account_based_on: action.account_based_on,
          type: action.warehouse_type,
          name: action.name,
          full_address: action.full_address,
          branch_code: action.branch_code,
          org_name: action.org_name,
          tax_no: action.tax_no,
          org_phone: action.org_phone,
          ...(has(action, 'pin') && { pin: action.pin }),
        }).pipe(
          catchError((error) => {
            console.log('create_warehouse', error)
            this.store.dispatch(actions_warehouse.Loadding({ loadding: false }))
            this.nzModalService.error({
              nzTitle: 'เกิดข้อผิดพลาด',
              nzContent: error.error.message,
            })
            return of([] as Partial<IWarehouseState.WarehouseState['list']>)
          }),
        ),
      ), // API Call Create
      mergeMap((_) =>
        this.WarehouseService.list_warehouse().pipe(
          catchError((error) => {
            console.log('list_warehouse', error)
            this.store.dispatch(actions_warehouse.Loadding({ loadding: false }))
            this.nzModalService.error({
              nzTitle: 'เกิดข้อผิดพลาด',
              nzContent: error.error.message,
            })
            return [] as Partial<IWarehouseState.WarehouseState['list']>
          }),
        ),
      ), // API Call Get List
      map((list) => {
        this.store.dispatch(actions_warehouse.Loadding({ loadding: false }))
        return actions_warehouse.GetWarehouseSuccess({ list: list as IWarehouseState.WarehouseState['list'] })
      }),
    ),
  )

  $UpdateWarehouse = createEffect(() =>
    this.actions$.pipe(
      ofType(actions_warehouse.UpdateWarehouse),
      tap(() => this.store.dispatch(actions_warehouse.Loadding({ loadding: true }))), // Set loadding
      mergeMap((action) =>
        this.WarehouseService.update_warehouse(action.warehouse_id, {
          account_based_on: action.account_based_on,
          type: action.warehouse_type,
          name: action.name,
          full_address: action.full_address,
          branch_code: action.branch_code,
          org_name: action.org_name,
          tax_no: action.tax_no,
          org_phone: action.org_phone,
          ...(has(action, 'pin') && { pin: action.pin }),
        }).pipe(
          catchError((error) => {
            console.log('update_warehouse', error)
            this.store.dispatch(actions_warehouse.Loadding({ loadding: false }))
            this.nzModalService.error({
              nzTitle: 'เกิดข้อผิดพลาด',
              nzContent: error.error.message,
            })
            return of([] as Partial<IWarehouseState.WarehouseState['list']>)
          }),
        ),
      ), // API Call Update
      mergeMap((_) =>
        this.WarehouseService.list_warehouse().pipe(
          catchError((error) => {
            console.log('list_warehouse', error)
            this.store.dispatch(actions_warehouse.Loadding({ loadding: false }))
            this.nzModalService.error({
              nzTitle: 'เกิดข้อผิดพลาด',
              nzContent: error.error.message,
            })
            return [] as Partial<IWarehouseState.WarehouseState['list']>
          }),
        ),
      ), // API Call Get List
      map((list) => {
        this.store.dispatch(actions_warehouse.Loadding({ loadding: false }))
        return actions_warehouse.GetWarehouseSuccess({ list: list as IWarehouseState.WarehouseState['list'] })
      }),
    ),
  )

  $GetWarehouseInfo = createEffect(() =>
    this.actions$.pipe(
      ofType(actions_warehouse.GetWarehouseInfo),
      tap(() => this.store.dispatch(actions_warehouse.Loadding({ loadding: true }))), // Set loadding
      mergeMap((_) =>
        this.WarehouseService.warehouse_info().pipe(
          catchError((error) => {
            console.log(error)
            this.store.dispatch(actions_warehouse.Loadding({ loadding: false }))
            this.nzModalService.error({
              nzTitle: 'เกิดข้อผิดพลาด',
              nzContent: error.error.message,
            })
            return cloneDeep(initialWarehouseState.info) as SafeAny
          }),
        ),
      ), // API Call
      map((info) => {
        this.store.dispatch(actions_warehouse.Loadding({ loadding: false }))
        return actions_warehouse.GetWarehouseInfoSuccess({ info: info as IWarehouseState.WarehouseState['info'] })
      }),
    ),
  )

  $UpdateWarehouseInfo = createEffect(() =>
    this.actions$.pipe(
      ofType(actions_warehouse.UpdateWarehouseInfo),
      tap(() => this.store.dispatch(actions_warehouse.Loadding({ loadding: true }))), // Set loadding
      mergeMap(({ info }) =>
        this.WarehouseService.update_warehouse_info({
          status: info.status,
          logo_status: info.logo_status,
          logo: info.logo,
          org_name: info.org_name,
          org_address: info.org_address,
          tax_no: info.tax_no,
          org_phone: info.org_phone,
          receipt: info.receipt,
        }).pipe(
          catchError((error) => {
            console.log(error)
            this.store.dispatch(actions_warehouse.Loadding({ loadding: false }))
            this.nzModalService.error({
              nzTitle: 'เกิดข้อผิดพลาด',
              nzContent: error.error.message,
            })
            return cloneDeep(initialWarehouseState.info) as SafeAny
          }),
        ),
      ), // API Call
      map((info) => {
        this.store.dispatch(actions_warehouse.Loadding({ loadding: false }))
        return actions_warehouse.GetWarehouseInfoSuccess({ info: info as IWarehouseState.WarehouseState['info'] })
      }),
    ),
  )

  $WarehouseTransferGet = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions_warehouse.WarehouseTransfer.Get),
        tap(() => this.store.dispatch(actions_warehouse.WarehouseTransfer.Loadding({ loadding: true }))), // Set loadding
        map((action) => {
          // from
          const from = 'WarehouseTransferGet'
          if (this.subscription_search[from]) {
            this.subscription_search[from].unsubscribe()
            this.store.dispatch(actions_warehouse.WarehouseTransfer.Loadding({ loadding: false }))
          }
          this.subscription_search[from] = new Observable((ob) => {
            this.store.dispatch(actions_warehouse.WarehouseTransfer.Loadding({ loadding: true }))
            ob.next()
          })
            .pipe(
              delay(300),
              tap(() => {
                this.store.dispatch(
                  actions_warehouse.WarehouseTransfer.SetSearchModel({ model: cloneDeep(action.model) }),
                )
              }),
              mergeMap(() =>
                this.WarehouseService.warehouse_transfer_get(cloneDeep(action.model)).pipe(
                  catchError((error) => {
                    console.log(error)
                    this.store.dispatch(actions_warehouse.WarehouseTransfer.Loadding({ loadding: false }))
                    this.nzModalService.error({
                      nzTitle: 'เกิดข้อผิดพลาด',
                      nzContent: error.error.message,
                    })
                    return cloneDeep(initialWarehouseState.warehouse_transfer.list) as SafeAny
                  }),
                ),
              ),
            )
            .subscribe({
              next: (value) => {
                this.store.dispatch(actions_warehouse.WarehouseTransfer.Loadding({ loadding: false }))
                this.store.dispatch(
                  actions_warehouse.WarehouseTransfer.Success({
                    list: value as IWarehouseState.WarehouseState['warehouse_transfer']['list'],
                  }),
                )
              },
              complete() {
                console.log('complete')
                this.store.dispatch(actions_warehouse.WarehouseTransfer.Loadding({ loadding: false }))
              },
              error(err) {
                this.store.dispatch(actions_warehouse.WarehouseTransfer.Loadding({ loadding: false }))
                console.log('error', err)
              },
            })
        }),
      ),
    { dispatch: false },
  )

  $SearchProducts = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions_warehouse.WarehouseProducts.SearchProducts),
        map((action) => {
          const model = cloneDeep(action.input) as IWarehouseState.WarehouseProductSearchModel
          if (this.subscription_search[action.from]) {
            this.subscription_search[action.from].unsubscribe()
            this.store.dispatch(
              actions_warehouse.WarehouseProducts.SetLoading({
                from: action.from,
                val: false,
              }),
            )
          }
          this.store.dispatch(
            actions_warehouse.WarehouseProducts.SetSearchModel({
              from: action.from,
              input: cloneDeep(model),
            }),
          )
          this.subscription_search[action.from] = new Observable((ob) => {
            this.store.dispatch(
              actions_warehouse.WarehouseProducts.SetLoading({
                from: action.from,
                val: true,
              }),
            )
            ob.next()
          })
            .pipe(
              delay(300),
              mergeMap(() => this.WarehouseService.search_product(model)),
            )
            .subscribe({
              next: (value) => {
                this.store.dispatch(
                  actions_warehouse.WarehouseProducts.SetProducts({
                    from: action.from,
                    data: {
                      data: value.data,
                      count: value.count,
                    },
                  }),
                )
                this.store.dispatch(
                  actions_warehouse.WarehouseProducts.SetLoading({
                    from: action.from,
                    val: false,
                  }),
                )
              },
              complete() {
                console.log('complete')
                this.store.dispatch(
                  actions_warehouse.WarehouseProducts.SetLoading({
                    from: action.from,
                    val: false,
                  }),
                )
              },
              error(err) {
                this.store.dispatch(
                  actions_warehouse.WarehouseProducts.SetLoading({
                    from: action.from,
                    val: false,
                  }),
                )
                console.log('error', err)
              },
            })
        }),
      ),
    { dispatch: false },
  )

  $CreateWarehouseTransfer = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions_warehouse.WarehouseTransfer.Create),
        tap(() => {
          $('.theme-loader').show()
        }), // Set loadding
        switchMap(({ model }) => {
          return this.WarehouseService.create_warehouse_transfer(model).pipe(
            concatMap((data) => this.WarehouseService.warehouse_transfer_by_id(data.id)),
            map((detail) => {
              this.store.dispatch(actions_warehouse.WarehouseTransfer.CreateSuccess({ detail }))
              console.log('WarehouseTransfer[Create][Success]', detail)
            }),
            catchError((error) => {
              this.store.dispatch(actions_warehouse.WarehouseTransfer.CreateError({ error }))
              this.nzModalService.error({
                nzTitle: 'เกิดข้อผิดพลาด',
                nzContent: error.error.message,
              })
              return of([])
            }),
          )
        }),
        map(async (data) => {
          const model = await store_utils.store_query(this.store, selectors_warehouse.warehouse_transfer.search_model)
          this.store.dispatch(actions_warehouse.WarehouseTransfer.Get({ model: cloneDeep(model) }))
          $('.theme-loader').hide()
        }),
      ),
    { dispatch: false },
  )

  $searchInventory = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions_warehouse.WarehouseInventory.Search),
        map((action) => {
          const model = cloneDeep(action.input) as IWarehouseState.IInventorySearchModel
          if (this.subscription_search[action.type]) {
            this.subscription_search[action.type].unsubscribe()
            this.subscription_search[action.type] = null
            this.store.dispatch(
              actions_warehouse.WarehouseInventory.SetLoading({
                val: false,
              }),
            )
          }
          this.subscription_search[action.type] = new Observable((ob) => {
            this.store.dispatch(
              actions_warehouse.WarehouseInventory.SetSearchModel({
                input: cloneDeep(model),
              }),
            )
            this.store.dispatch(
              actions_warehouse.WarehouseInventory.SetLoading({
                val: true,
              }),
            )
            ob.next()
          })
            .pipe(
              delay(300),
              switchMap(() => this.WarehouseService.search_inventory(model)),
            )
            .subscribe({
              next: (value) => {
                this.store.dispatch(
                  actions_warehouse.WarehouseInventory.Set({
                    data: value.data,
                    count: value.count,
                  }),
                )
                this.store.dispatch(
                  actions_warehouse.WarehouseInventory.SetLoading({
                    val: false,
                  }),
                )
              },
              complete() {
                console.log('complete')
                this.store.dispatch(
                  actions_warehouse.WarehouseInventory.SetLoading({
                    val: false,
                  }),
                )
              },
              error(err) {
                this.store.dispatch(
                  actions_warehouse.WarehouseInventory.SetLoading({
                    val: false,
                  }),
                )
                console.log('error', err)
              },
            })
        }),
      ),
    { dispatch: false },
  )
}
