import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class UserAgentService {
  getIosVersion() {
    const user_agent = navigator.userAgent
    const ios_version = user_agent.split(' ')
    const rs = {
      is_ios: false,
      major: 0,
      minor: 0,
      patch: 0,
    }
    ios_version.forEach((item, index) => {
      if (item == 'iPhone' && ios_version[index + 1] == 'OS') {
        rs.is_ios = true
        const version = ios_version[index + 2].split('_')
        rs.major = Number(version[0])
        rs.minor = Number(version[1])
        rs.patch = Number(version.length > 1 ? version[2] : 0)
      }
    })
    return rs
  }
}
