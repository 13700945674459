import { Component, OnInit, Input } from '@angular/core'
import { ConfirmPaymentService } from '@cms/services/confirmpayment.service'
import { NzModalService } from 'ng-zorro-antd/modal'
import { NzNotificationService } from 'ng-zorro-antd/notification'

import { ModalViewSlipComponent } from '@cms/system/modal/modal-view-slip/modal-view-slip.component'
import { ModalEditConfirmpaymentComponent } from '@cms/system/modal/modal-edit-confirmpayment/modal-edit-confirmpayment.component'
import { ModalCreateConfirmpaymentComponent } from '@cms/system/modal/modal-create-confirmpayment/modal-create-confirmpayment.component'
import * as moment from 'moment'
import { ModalExportConfirmpaymentComponent } from '@cms/system/modal/modal-export-confirmpayment/modal-export-confirmpayment.component'
import { FeatureService } from '@cms/services/feature.service'
import { Router } from '@angular/router'

@Component({
  selector: 'app-confirmpayment-modal',
  templateUrl: './confirmpayment.component.html',
  styleUrls: ['./confirmpayment.component.css'],
})
export class ConfirmpaymentModalComponent implements OnInit {
  @Input() ordercode: any = ''
  @Input() order_status = 1

  allstatus: any = []
  model: any = {
    search: '',
    status: '',
    from: 'bank',
    ordercode: '',
    date: '',
    page: 1,
    perpage: 10,
  }
  statusimg: any = {
    '1': '/assets/images/core-imgs/icon/slip-verification-success.png',
    '0': '/assets/images/core-imgs/icon/slip-verification-pending.png',
    '-1': '/assets/images/core-imgs/icon/slip-verification-error.png',
  }
  loading = false
  date: any
  confirmpayment: any = []
  total: any = 0
  feature = true

  constructor(
    private Service_ConfirmPayment: ConfirmPaymentService,
    private notification: NzNotificationService,
    private modalService: NzModalService,
    private Service_Feature: FeatureService,
    private router: Router,
  ) {}

  async ngOnInit() {
    if (this.ordercode) {
      this.model.ordercode = this.ordercode
    }
    // console.log(this.Service_ConfirmPayment);
    this.feature = await this.Service_Feature.getFeature('PRODUCT_PAGE')
    if (!this.feature) {
      return
    }
    this.allstatus = await this.Service_ConfirmPayment.confirmstatus()
    for (const obj of this.allstatus) {
      if (obj.status == -1) {
        obj.name = 'ไม่ผ่านการตรวจสอบ'
      } else if (obj.status == 1) {
        obj.name = 'ผ่านการตรวจสอบ'
      } else if (obj.status == 0) {
        obj.name = 'รอตรวจสอบ'
      }
    }
    this.allstatus.unshift({ status: '', name: 'All' })
    this.search()
  }

  dateChange(event) {
    console.log(event)
    if (!event) {
      this.model.date = ''
    } else {
      this.model.date = moment(event).format('YYYY-MM-DD')
    }
    this.search()
  }
  pagechage(page) {
    this.model.page = page
    this.search()
  }
  createConfirmpayment() {
    const modal = this.modalService
      .create({
        nzTitle: 'Confirm Payment',
        nzContent: ModalCreateConfirmpaymentComponent,
        nzStyle: { top: '20px' },
        // nzWidth: "95%",
        nzKeyboard: false,
        nzMaskClosable: false,
        nzComponentParams: {
          ordercode: this.ordercode,
          data: {},
        },
        nzOnOk: (componentInstance) => {
          return componentInstance.ok()
        },
        nzOnCancel: (componentInstance) => {},
      })
      .afterClose.subscribe((result) => {
        modal.unsubscribe()
        this.search()
      })
  }
  export() {
    const modal = this.modalService.create({
      nzTitle: 'Export',
      nzContent: ModalExportConfirmpaymentComponent,
      nzStyle: { top: '20px' },
      // nzWidth: "700",
      nzKeyboard: false,
      nzMaskClosable: false,
      nzOnOk: (componentInstance) => {
        return componentInstance.ok()
      },
      nzOnCancel: (componentInstance) => {},
      nzComponentParams: {
        data: {},
      },
    })
  }
  edit(obj) {
    // ModalEditConfirmpaymentComponent
    console.log('ModalEditConfirmpaymentComponent : \n', obj.ordercode)
    const modal = this.modalService
      .create({
        nzTitle: `การแจ้งชำระเงิน Order No. ${obj.ordercode}`,
        nzContent: ModalEditConfirmpaymentComponent,
        nzWidth: '65%',
        nzWrapClassName: 'vertical-center-modal',
        nzClassName: 'max-w-720',
        nzOkText: 'บันทึก',
        nzCancelText: 'ยกเลิก',
        nzKeyboard: false,
        nzMaskClosable: false,
        nzOnOk: (componentInstance) => {
          return componentInstance.ok()
        },
        nzOnCancel: (componentInstance) => {},
        nzComponentParams: {
          data: {
            confirm: obj,
          },
        },
      })
      .afterClose.subscribe((result) => {
        modal.unsubscribe()
        this.search()
      })
  }

  viewslip(obj) {
    const modal = this.modalService.create({
      nzTitle: 'หลักฐานการโอนเงิน',
      nzContent: ModalViewSlipComponent,
      nzWrapClassName: 'vertical-center-modal',
      // nzWidth: "95%",
      nzFooter: null,
      nzKeyboard: false,
      nzMaskClosable: false,
      nzOnOk: (componentInstance) => {
        return componentInstance.ok()
      },
      nzOnCancel: (componentInstance) => {},
      nzComponentParams: {
        data: {
          img: obj.picture,
        },
      },
    })
  }
  search() {
    this.loading = true
    this.Service_ConfirmPayment.search(this.model)
      .then((res: any) => {
        console.log(res)

        this.confirmpayment = res.data
        this.total = res.count
      })
      .catch((data) => {
        console.log(data.error.message)
      })
      .finally(() => {
        this.loading = false
      })
  }

  async view(obj) {
    const url = window.location.origin + '/system/e-commerce/order-detail/' + obj
    window.open(url, '_blank', 'menubar=no,toolbar=no,location=no,width=1280,height=720')
  }
}
