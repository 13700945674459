export namespace IChannelsState {
  export interface ChannelsState {
    list_channel: {
      all: Channel[]
      marketplace: Channel[]
      website: Channel[]
      pos: Channel[]
      warehouse: any[]
    }
    sell_channel: SellChannel[]
  }

  export enum MarketplaceChannelType {
    Lazada = 'lazada',
    Shopee = 'shopee',
    JdCentral = 'jd-central',
    LineShopping = 'line_shopping',
    Tiktok = 'tiktok',
  }

  export enum WebsiteChannelType {
    Facebook = 'facebook',
    LineA = 'line@',
    Line = 'line',
    Instagram = 'instagram',
    Twitter = 'twitter',
    Wechat = 'wechat',
    Website = 'website',
    App = 'app',
    KetMarket = 'ket-market',
    SalePage = 'sale-page',
    LineBot = 'line_bot',
    Shop = 'shop',
    Other = 'other',
  }

  export enum OtherChannelType {
    Pos = 'pos',
  }

  type ChannelType = MarketplaceChannelType | WebsiteChannelType | OtherChannelType

  export interface Channel {
    color: string
    name: string
    img: string
    imgapp: string
    group: 'marketplace' | 'website' | 'pos' | 'warehouse'
    value: ChannelType
    // front state
    checked?: boolean
  }

  export interface SellChannel {
    channel: ChannelType
    channel_id: string
    channel_name: string
    channel_social_name: string
    img?: string
  }
}
