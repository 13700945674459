import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetPathLangPipe } from './path-lang.pipe'

@NgModule({
  declarations: [KetPathLangPipe],
  imports: [CommonModule],
  providers: [KetPathLangPipe],
  exports: [KetPathLangPipe],
})
export class KetPathLangModule {}
