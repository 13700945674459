import { cloneDeep } from 'lodash'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import { IChannelsState } from './models'
import { FEATURE_KEY } from './reducer'

const selectState = createFeatureSelector<IChannelsState.ChannelsState>(FEATURE_KEY)

const list_channel = createSelector(selectState, (state: IChannelsState.ChannelsState) => state.list_channel)

const sell_channel = createSelector(selectState, (state: IChannelsState.ChannelsState) => {
  const sell_channel = cloneDeep(state.sell_channel)
  const list_channel = state.list_channel
  sell_channel.map((item) => {
    const ch = list_channel.all.find((c) => c.value == item.channel)
    if (ch) {
      item.img = ch.img
    }
  })
  return sell_channel
})

export const selectors_channels = {
  list_channel,
  sell_channel,
}
