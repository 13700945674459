import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ProductsService } from '@cms/services/products.service'
import { IProduct } from '@cms/types'
import { cloneDeep } from 'lodash'
import { NzModalService } from 'ng-zorro-antd'
import { KetModalAddproductPromotionComponent } from '../modal-addproduct-promotion'
@Component({
  selector: 'ket-select-promo-participating-product',
  templateUrl: './select-promo-participating-product.component.html',
  styleUrls: ['./select-promo-participating-product.component.scss'],
})
export class KetSelectPromoParticipatingProductComponent implements OnInit {
  @Input() data: {
    product_ids: number[]
  } = { product_ids: [] }
  @Output() onProductsChange = new EventEmitter()
  products: IProduct.IProductSearchResponse['data'][number][] = []

  constructor(
    private nzModalService: NzModalService,
    private productsService: ProductsService,
  ) {}

  ngOnInit(): void {
    if (this.data.product_ids.length > 0) {
      this.productsService
        .searchProduct({
          ids: this.data.product_ids,
          page: 1,
          perpage: this.data.product_ids.length,
        })
        .then((res) => {
          this.products = res.data
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }

  addProduct() {
    const title = 'เพิ่มสินค้าร่วมรายการ'
    const modal = this.nzModalService
      .create({
        nzTitle: title,
        nzContent: KetModalAddproductPromotionComponent,
        nzWidth: '80%',
        nzKeyboard: false,
        nzMaskClosable: false,
        nzClosable: false,
        nzOkText: title,
        nzCancelText: 'ยกเลิก',
        nzWrapClassName: 'vertical-center-modal',
        nzStyle: { 'padding-bottom': '0px' },
        nzMaskStyle: {
          background: 'transparent',
        },
        nzOnOk: (componentInstance) => {
          return componentInstance.ok()
        },
        nzComponentParams: {
          type: '',
          selectedProducts: cloneDeep(this.products),
        },
      })
      .afterClose.subscribe((result) => {
        modal.unsubscribe()
        if (result) {
          this.products = result
          this.onProductsChange.emit(result)
        }
      })
  }

  removeProduct(index: number) {
    this.products.splice(index, 1)
    this.onProductsChange.emit(this.products)
  }

  clearProduct() {
    this.products = []
    console.log('CLEARR!!!!!')
    console.log(this.products)
    this.onProductsChange.emit(this.products)
  }
}
