import { Action, createReducer, on } from '@ngrx/store'
import { IFeaturesState } from './models'
import { actions_features } from './actions'
export const FeaturesFEATURE_KEY = 'features'

export const initialFeaturesState: IFeaturesState.FeaturesState = {
  feature: {},
}

const reducer = createReducer(
  initialFeaturesState,
  on(actions_features.SetFeatures, (state, action) => {
    return {
      ...state,
      feature: action.features.reduce((acc, curr) => ({ ...acc, [curr.name]: curr }), {}),
    }
  }),
)

export function FeaturesReducer(state: IFeaturesState.FeaturesState, action: Action) {
  return reducer(state, action)
}
