import { h, Fragment } from 'preact'
import { IPurchaseOrderHtml } from './types'
import { format_money } from 'libs/ketshopweb-services/func/format_money'
import { isEmpty, isObject } from 'lodash'

const getTitle = (obj: {
  prop_enable: number
  title_lang1: string
  properties_desc: string
  property_info: string
  properties_desc2: string
  property_info2: string
}) => {
  let title = obj.title_lang1

  if (obj.prop_enable == 1) {
    title += ` (${obj.properties_desc} - ${obj.property_info})`
  }

  if (obj.prop_enable == 2) {
    title += ` (${obj.properties_desc} - ${obj.property_info} | ${obj.properties_desc2} - ${obj.property_info2})`
  }

  return title
}

const TmpProduct = (obj: IPurchaseOrderHtml.Order['details'][number], i: number) => {
  return (
    <tr key={i}>
      <td class="table-product-no">{i + 1}</td>
      <td class="table-product-detail">
        <div class="product-wrapper">
          <div class="product-image">
            <img src={obj.feature_img} />
          </div>
          <div class="product-detail">
            <div>{getTitle(obj)}</div>
            <div class="text-small">
              <span>SKU : {obj.sku}</span>
            </div>
            {!isEmpty(obj.barcode) && <span> | Barcode : {obj.barcode}</span>}
            {!isEmpty(obj.product_set) &&
              obj.product_set.map((set, j) => {
                return (
                  <div key={j}>
                    <div>
                      x{Math.floor(set.qty / obj.qty)} {getTitle(set)}
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </td>
      <td class="table-product-price">
        {obj.temp_price > 0 && (
          <div class="text-small">
            <s>{format_money(obj.temp_price)}</s>
          </div>
        )}
        <div>{format_money(obj.product_price)} </div>
      </td>
      <td class="table-product-qty">{obj.qty}</td>
      <td class="table-product-total">{format_money(obj.price)}</td>
    </tr>
  )
}

const TmpFreeItem = (free: IPurchaseOrderHtml.Order['free_item'][number], i: number) => {
  return (
    <tr key={i}>
      <td class="table-product-no">{i + 1}</td>
      <td class="table-product-detail">
        <div class="product-wrapper">
          <div class="product-image">
            <img src={free.feature_img} />
          </div>
          <div class="product-detail">
            <div>
              {getTitle(free)} {free.promotion_type == 'product_point' ? `| ใช้แต้มแลก ${free.value} แต้ม` : ''}
            </div>
            <div class="text-small">SKU : {free.sku}</div>
            {!isEmpty(free.barcode) && <span> | Barcode : {free.barcode}</span>}
            <div>
              {!isEmpty(free.product_set) &&
                free.product_set.map((set, j) => {
                  return (
                    <div key={j}>
                      <div>
                        x{Math.floor(set.qty / free.qty)} {getTitle(set)}
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </td>
      <td class="table-product-price">
        <span>0.00</span>
      </td>
      <td class="table-product-qty">{free.qty}</td>
      <td class="table-product-total">0.00</td>
    </tr>
  )
}

export const TmpOrderProduct = ({ order }: { order: IPurchaseOrderHtml.Order }) => {
  let promotion_state = false
  let promotion_name = ''

  order.promotion.forEach((obj) => {
    if (obj.promotion_type == 'coupon') {
      promotion_state = true
      promotion_name = `ส่วนลดคูปอง / Coupon : ${obj.coupon}`
      if (obj.value_type == 'percent') {
        promotion_name += ` ( ${obj.value}% )`
      }
    } else if (obj.promotion_type == 'condition') {
      promotion_state = true
      promotion_name = `ส่วนลดโปรโมชั่น / Promotion : ${obj.title}`
      if (obj.value_type == 'percent') {
        promotion_name += ` ( ${obj.value}% )`
      }
    }
  })

  let shipping_name = ''
  let shipping_price: string | number = '0'

  if (order.freeshipping == 1) {
    shipping_name = 'ฟรีค่าขนส่ง / Free'
  }
  if (order.purchase_id && order.freeshipping == 0) {
    shipping_price = order.delicharge
    if (order.delichargeaddon > 0) {
      shipping_price = order.delicharge + order.delichargeaddon
    }

    if (isObject(order.delivery) && order.delivery.name) {
      shipping_name = order.delivery.name
    }
  }
  if (!order.purchase_id && order.freeshipping == 0 && order.user_id !== -1) {
    shipping_price = order.delicharge

    if (isObject(order.delivery) && order.delivery.name) {
      shipping_name = order.delivery.name
    }
  }
  shipping_price = format_money(shipping_price)

  if (order.user_id == -1) {
    shipping_name = 'รับหน้าร้าน / Pickup'
  }

  // alert('order.amount_no_vat: '+order.amount_no_vat)

  return (
    <Fragment>
      <table class="table-product-wrapper">
        <thead>
          <tr>
            <th class="table-product-no">No.</th>
            <th class="table-product-detail">Product</th>
            <th class="table-product-price">Price</th>
            <th class="table-product-qty">QTY</th>
            <th class="table-product-total">Total (฿)</th>
          </tr>
        </thead>
        <tbody>
          {order.details.map((obj, i) => TmpProduct(obj, i))}

          {order.free_item.length > 0 && (
            <Fragment>
              <tr>
                <td colSpan={5}>
                  <strong>สินค้าฟรี / Free item</strong>
                </td>
              </tr>
              {order.free_item.map((free, i) => TmpFreeItem(free, i))}
            </Fragment>
          )}
        </tbody>
        <tfoot style="display: table-row-group">
          {/* <tr>
            <td colSpan={4} class="text-right">
              ราคารวมสินค้า / Amount
            </td>
            <td class="text-right">{format_money(order.amount)}</td>
          </tr> */}
          {!order.amount_no_vat ? (
            <tr>
              <td colSpan={4} class="text-right">
                ราคารวมสินค้า / Amount
              </td>
              <td class="text-right">{format_money(order.amount)} ฿</td>
            </tr>
          ) : (
            <Fragment>
              <tr>
                <td colSpan={4} class="text-right">
                  มูลค่ารายการยกเว้นภาษี / Amount (VAT Excluded)
                </td>
                <td class="text-right">{format_money(order.amount_no_vat)} ฿</td>
              </tr>
              <tr>
                <td colSpan={4} class="text-right">
                  มูลค่ารายการที่คำนวณภาษี / Amount (VAT Included)
                </td>
                <td class="text-right">{format_money(order.amount - order.amount_no_vat)} ฿</td>
              </tr>
            </Fragment>
          )}
          {order.discountaddon > 0 && (
            <tr>
              <td colSpan={4} class="text-right">
                ส่วนลดพิเศษ / Discount
              </td>
              <td class="text-right">-{format_money(order.discountaddon)}</td>
            </tr>
          )}
          {promotion_state && (
            <tr>
              <td colSpan={4} class="text-right">
                {promotion_name}
              </td>
              <td class="text-right">-{format_money(order.discount)}</td>
            </tr>
          )}
          {order.vat > 0 && (
            <tr>
              <td colSpan={4} class="text-right">
                ภาษีมูลค่าเพิ่ม {order.vatrate}%
              </td>
              <td class="text-right">{format_money(order.vat)}</td>
            </tr>
          )}
          <tr>
            <td colSpan={4} class="text-right">
              {shipping_name} ({Number(order.weight).toFixed(2)} กรัม / g)
            </td>
            <td class="text-right">{shipping_price}</td>
          </tr>
          {order.cod == 1 && (
            <tr>
              <td colSpan={4} class="text-right">
                ค่าบริการ COD / COD Fee
              </td>
              <td class="text-right">{Number(order.cod_value).toFixed(2)}</td>
            </tr>
          )}
          <tr>
            <td colSpan={4} class="text-right">
              <strong>ราคารวมทั้งหมด / Total</strong>
            </td>
            <td class="text-right">
              <strong>{format_money(order.totals)}</strong>
            </td>
          </tr>
        </tfoot>
      </table>
    </Fragment>
  )
}
