import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { SettingThemeComponent } from './setting-theme.component'
import { AuthenGuard } from '@cms/authen.guard'
import { PermissionGuard } from '@cms/app.permission.guard'

const routes: Routes = [{ 
  path: '', 
  component: SettingThemeComponent, 
  data: { role: ['administrator', 'webmaster', 'editor'] }, 
  canActivate: [AuthenGuard, PermissionGuard],
}]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingThemeRoutingModule {}
