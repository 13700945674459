import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ISandclockbooks } from './types'
import { inject } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class CmsFrontendService {
  public http: HttpClient = inject(HttpClient)

  prefix = '/services/plugins-api/sandclockbooks'

  getMenuFilter() {
    return this.http.get<ISandclockbooks.MenuFilter[]>(`${this.prefix}/frontend/menu_filters`)
  }

  getMenuFilterById(id: number) {
    return this.http.get<ISandclockbooks.MenuFilter>(`${this.prefix}/frontend/menu_filters/${id}`)
  }

  createMenuFilter(data: { lang1: string; lang2: string; lang3: string; lang4: string; type: string }) {
    return this.http.post<ISandclockbooks.MenuFilter>(`${this.prefix}/frontend/menu_filters`, data)
  }

  updateMenuFilter(id: number, data: { lang1: string; lang2: string; lang3: string; lang4: string; type: string }) {
    return this.http.put<ISandclockbooks.MenuFilter>(`${this.prefix}/frontend/menu_filters/${id}`, data)
  }

  sortMenuFilters(data: { data: { id: number }[] }) {
    return this.http.put<{ status: boolean; message: string }>(`${this.prefix}/frontend/menu_filters/sort`, data)
  }

  destroyMenuFilterById(id: number) {
    return this.http.delete<{ status: boolean; message: string }>(`${this.prefix}/frontend/menu_filters/${id}`)
  }

  getMenuFilterDetail(id: number) {
    return this.http.get<ISandclockbooks.MenuFilterDetail[]>(`${this.prefix}/frontend/menu_filter_details/${id}`)
  }

  getMenuFilterDetailById(id: number) {
    return this.http.get<ISandclockbooks.MenuFilterDetail>(`${this.prefix}/frontend/menu_filter_details/${id}`)
  }

  createMenuFilterDetail(data: {
    lang1: string
    lang2: string
    lang3: string
    lang4: string
    type: string
    image: string
    value: string
    menu_filter_type: string
  }) {
    return this.http.post<ISandclockbooks.MenuFilterDetail>(`${this.prefix}/frontend/menu_filter_details`, data)
  }

  sortMenuFilterDetails(data: { data: { id: number }[] }) {
    return this.http.put<{ status: boolean; message: string }>(`${this.prefix}/frontend/menu_filter_details/sort`, data)
  }

  updateMenuFilterDetail(
    id: number,
    data: {
      lang1: string
      lang2: string
      lang3: string
      lang4: string
      type: string
      image: string
      value: string
      menu_filter_type: string
    },
  ) {
    return this.http.put<ISandclockbooks.MenuFilterDetail>(`${this.prefix}/frontend/menu_filter_details/${id}`, data)
  }

  destroyMenuFilterDetailById(id: number) {
    return this.http.delete<{ status: boolean; message: string }>(`${this.prefix}/frontend/menu_filter_details/${id}`)
  }

  getBlogRelateds(permalink: string, data?: { limit: number }) {
    const params = {}

    if (data) {
      for (const key in data) {
        if (data[key]) {
          params[key] = data[key]
        }
      }
    }
    return this.http.get<any>(`${this.prefix}/frontend/blogs/${permalink}/blog_relateds`, { params })
  }
}
