import { Component, OnInit, Input } from '@angular/core'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzMessageService } from 'ng-zorro-antd/message'
import { UtilService } from '@cms/services/util.service'
import { ProductsService } from '@cms/services/products.service'
import { FrontsService } from '@cms/services/fronts.service'
import { BlogsService } from '@cms/services/blogs.service'
import { IKetshopwebFrontendService, KetshopwebFrontendService } from '@cms/services/ketshopweb-frontend.service'
@Component({
  selector: 'app-modal-create-menu',
  templateUrl: './modal-create-menu.component.html',
  styleUrls: ['./modal-create-menu.component.css'],
})
export class ModalCreateMenuComponent implements OnInit {
  @Input() data: any
  readonly menuType = IKetshopwebFrontendService.MenuType

  constructor(
    private modal: NzModalRef,
    private message: NzMessageService,
    private Service_Util: UtilService,
    private Service_Product: ProductsService,
    private Service_Front: FrontsService,
    private Service_Blog: BlogsService,
    private ketshopwebFrontendService: KetshopwebFrontendService,
  ) {}
  model = {
    type: 'page',
    lang1: '',
    lang2: '',
    lang3: '',
    lang4: '',
    path: '',
    enable: 0,
    is_column_menu: false,
  } as IKetshopwebFrontendService.CreateMenuData
  en1 = 1
  en0 = 0
  lang: any = []
  validate: any
  disablelink = false
  message_duration = 4000
  allcategory: any = []
  allBlogCategory: any[] = []

  modelsearchproduct: any = {
    lang: 'lang1',
    status: '',
    search: '',
    cate_id: '',
    group: '',
    page: 1,
    perpage: 10,
    ids: '',
    orderby: 'id',
    sort: 'desc',
  }
  products: any = []
  total_product = 0
  selected_product: any = null

  modelsearchblog: any = {
    lang: 'lang1',
    status: '',
    search: '',
    cate_id: '',
    page: 1,
    perpage: 10,
    sort: 'desc',
  }
  blogs: any = []
  total_blog = 0
  selected_blog: any = null
  is_column_menu = false
  async ngOnInit() {
    this.lang = await this.Service_Util.getLangs()
    this.is_column_menu = this.data.is_column_menu ? true : false
    switch (this.data.type) {
      case 'page':
        this.model.type = IKetshopwebFrontendService.MenuType.Page
        this.validate = this.validatePage
        break
      case IKetshopwebFrontendService.MenuType.ExternalLink:
      case 'link':
        this.model.type = IKetshopwebFrontendService.MenuType.ExternalLink
        this.model.path = 'https://'
        this.validate = this.validateLink
        break
      case IKetshopwebFrontendService.MenuType.ProductCategory:
      case 'productcategory':
        this.model.type = IKetshopwebFrontendService.MenuType.ProductCategory
        this.validate = this.validatePage
        this.model.path = '-1'
        this.allcategory = await this.Service_Product.productMainCategory()
        this.allcategory.unshift({
          id: '-1',
          lang1: 'Uncategory',
          lang2: 'Uncategory',
          lang3: 'Uncategory',
          lang4: 'Uncategory',
        })
        break
      case IKetshopwebFrontendService.MenuType.BlogCategory:
      case 'blogcategory':
        this.model.type = IKetshopwebFrontendService.MenuType.BlogCategory
        this.validate = this.validatePage
        this.model.path = '-1'
        this.allBlogCategory = (await this.Service_Blog.allCategory()) as any[]
        // this.allcategory = await this.Service_Product.productMainCategory();
        // this.allcategory.unshift({ id: "-1", lang1: "Uncategory", lang2: "Uncategory", lang3: "Uncategory", lang4: "Uncategory" });
        break
      case 'product':
        this.model.type = IKetshopwebFrontendService.MenuType.Product
        this.validate = this.validateProductDetail
        this.searchProduct()
        break
      case 'blog':
        this.model.type = IKetshopwebFrontendService.MenuType.Blog
        this.validate = this.validateBlogDetail
        this.searchBlog()
        break
      case 'confirmpayment':
        this.model.type = IKetshopwebFrontendService.MenuType.InternalLink
        this.disablelink = true
        this.model.path = '-CONFIRMPAYMENT'
        this.validate = this.passValidate
        // confirmpayment
        break
      case 'trackingno':
        this.model.type = IKetshopwebFrontendService.MenuType.InternalLink
        this.disablelink = true
        this.model.path = '-TRACKING'
        this.validate = this.passValidate
        break
      case 'search-order':
        this.model.type = IKetshopwebFrontendService.MenuType.InternalLink
        this.disablelink = true
        this.model.path = '-SEARCH-ORDER'
        this.validate = this.passValidate
        break
      case 'topic':
        this.model.type = IKetshopwebFrontendService.MenuType.Topic
        this.validate = this.validatePage
        break
    }
  }

  searchProduct() {
    this.Service_Product.searchProduct(this.modelsearchproduct)
      .then((res: any) => {
        console.log(res)
        this.products = res.data
        this.total_product = res.count
      })
      .catch((data) => {
        console.log(data.error.message)
      })
  }
  selectedProduct(obj) {
    this.selected_product = obj
    this.model.lang1 = this.selected_product.title_lang1
    this.model.lang2 = this.selected_product.title_lang2
    this.model.lang3 = this.selected_product.title_lang3
    this.model.lang4 = this.selected_product.title_lang4
    this.removespecialchar('lang1')
    this.removespecialchar('lang2')
    this.removespecialchar('lang3')
    this.removespecialchar('lang4')
    this.model.path = this.selected_product.id
  }
  pagechage_product(page) {
    this.modelsearchproduct.page = page
    this.searchProduct()
  }

  searchBlog() {
    this.Service_Blog.searchBlog(this.modelsearchblog)
      .then((res: any) => {
        console.log(res)
        this.blogs = res.data
        this.total_blog = res.count
      })
      .catch((data) => {
        console.log(data.error.message)
      })
  }

  selectedBlog(obj) {
    this.selected_blog = obj
    this.model.lang1 = this.selected_blog.post_title_lang1
    this.model.lang2 = this.selected_blog.post_title_lang2
    this.model.lang3 = this.selected_blog.post_title_lang3
    this.model.lang4 = this.selected_blog.post_title_lang4
    this.removespecialchar('lang1')
    this.removespecialchar('lang2')
    this.removespecialchar('lang3')
    this.removespecialchar('lang4')
    this.model.path = this.selected_blog.post_id
  }

  pagechage_blog(page) {
    this.modelsearchblog.page = page
    this.searchBlog()
  }

  passValidate() {
    return true
  }

  removespecialchar(lang) {
    try {
      this.model[lang] = this.model[lang].replace(/[\\\/*"%#?'\.]/g, '')
    } catch (error) {
      console.log(error)
    }
  }
  validateBlogDetail() {
    for (let i = 0; i < this.lang.length; i++) {
      if (this.model[this.lang[i].ref] == '' || this.model[this.lang[i].ref] == undefined) {
        this.message.error('Please fill menu name (' + this.lang[i].name + ') ', { nzDuration: this.message_duration })
        return false
      }
    }

    if (!this.model.path) {
      this.message.error('Please fill select some blog ', { nzDuration: this.message_duration })
      return false
    }
    return true
  }
  validateProductDetail() {
    for (let i = 0; i < this.lang.length; i++) {
      if (this.model[this.lang[i].ref] == '' || this.model[this.lang[i].ref] == undefined) {
        this.message.error('Please fill menu name (' + this.lang[i].name + ') ', { nzDuration: this.message_duration })
        return false
      }
    }

    if (!this.model.path) {
      this.message.error('Please fill select some product ', { nzDuration: this.message_duration })
      return false
    }
    return true
  }

  validateLink() {
    if (!/^(http:\/\/|https:\/\/)/g.test(this.model.path)) {
      this.model.path = 'https://' + this.model.path
    }

    for (let i = 0; i < this.lang.length; i++) {
      if (this.model[this.lang[i].ref] == '' || this.model[this.lang[i].ref] == undefined) {
        this.message.error('Please fill menu name (' + this.lang[i].name + ') ', { nzDuration: this.message_duration })
        return false
      }
    }

    if (this.model.path.trim() == '') {
      this.message.error('Please fill link ', { nzDuration: this.message_duration })
      return false
    }

    return true
  }

  validatePage() {
    for (let i = 0; i < this.lang.length; i++) {
      if (this.model[this.lang[i].ref] == '' || this.model[this.lang[i].ref] == undefined) {
        this.message.error('Please fill menu name (' + this.lang[i].name + ') ', { nzDuration: this.message_duration })
        return false
      }
    }
    return true
  }
  async ok() {
    this.model['is_column_menu'] = this.is_column_menu
    console.log(this.model)
    const chk = this.validate()
    if (!chk) {
      return false
    }
    try {
      await this.ketshopwebFrontendService.createMenu(this.model)
      // await this.Service_Front.createMenu(this.model);
    } catch (e) {
      this.message.error(e.error.message, { nzDuration: this.message_duration })
      return false
    }
    return true
  }
}
