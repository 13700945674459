import { Action, createReducer, on } from '@ngrx/store'
import { IAppState } from './models'
import { actions_app } from './actions'

export const FEATURE_KEY = 'app'

export const initialAppState: IAppState.AppState = {
  warehouse_inventory_changes: Date.now(),
  utm: null,
}

const reducer = createReducer(
  initialAppState,
  on(actions_app.WarehouseInventoryChanges, (state) => {
    return {
      ...state,
      warehouse_inventory_changes: Date.now(),
    }
  }),
  on(actions_app.SetUTM, (state, action) => {
    return {
      ...state,
      utm: action.val,
    }
  }),
)

export function Reducer(state: IAppState.AppState, action: Action) {
  return reducer(state, action)
}
