import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KetModalTagPromoPointComponent } from './modal-tag-promo-point.component';

@NgModule({
  declarations: [
    KetModalTagPromoPointComponent
  ],
  imports: [
    CommonModule
  ],
  exports:[
    KetModalTagPromoPointComponent
  ]
})
export class KetModalTagPromoPointModule { }