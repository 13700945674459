import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetAssigneeSelectorComponent } from './assignee-selector.component'
import { FormsModule } from '@angular/forms'
import {
  NzAvatarModule,
  NzCheckboxModule,
  NzIconModule,
  NzInputModule,
  NzRadioModule,
  NzSelectModule,
} from 'ng-zorro-antd'
import { InfiniteScrollModule } from '@cms/libs/ngx-infinite-scroll/ngx-infinite-scroll'

@NgModule({
  declarations: [KetAssigneeSelectorComponent],
  imports: [
    CommonModule,
    FormsModule,
    NzInputModule,
    NzIconModule,
    NzSelectModule,
    NzAvatarModule,
    NzCheckboxModule,
    NzRadioModule,
    InfiniteScrollModule,
  ],
  exports: [KetAssigneeSelectorComponent],
})
export class KetAssigneeSelectorModule {}
