import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import * as moment from 'moment'
import { FeatureService } from '@cms/services/feature.service'

@Injectable({
  providedIn: 'root',
})
export class KetFeatureLandingService {
  constructor(
    private _http: HttpClient,
    private featureService: FeatureService,
  ) {}

  private feature: any = null
  private date_time: any

  async _init() {
    console.log('_init feature ')
    const date: any = await this.getDateTime()
    this.date_time = date.date_time
    const obj = await this.featureService.getAllFeature()
    this.feature = obj
    Object.freeze(this.feature)
    console.log(this.feature)
  }

  getDateTime() {
    return new Promise((reslove) => {
      return this._http
        .get('/services/v2/ketcms-frontend/utils/datetime')
        .toPromise()
        .then((res: any) => reslove(res))
        .catch(() => {
          const mm = moment()
          reslove({
            date: mm.format('YYYY-MM-DD'),
            date_string: mm.toString(),
            date_time: mm.format('YYYY-MM-DD HH:mm:ss'),
            time: mm.format('HH:mm:ss'),
          })
        })
    }) as Promise<{ date: string; date_string: string; date_time: string; time: string }>
  }

  async getFeature(key = null) {
    if (this.feature === null) {
      await this._init()
    }
    if (key) {
      const _f = this.feature.find((x) => x.name == key)
      if (key == 'ORDER_MARKETPLACE') {
        const shopee = this.feature.find((x) => x.name == 'SHOPEE')
        const lazada = this.feature.find((x) => x.name == 'LAZADA')
        const jd = this.feature.find((x) => x.name == 'JDCENTRAL')

        if (shopee.value == '0' && lazada.value == '0' && jd.value == '0') {
          console.log('ordermarketplace return false')
          return false
        } else {
          console.log('ordermarketplace return true')
          return true
        }
      }
      if (key == 'SALEPAGE') {
        if (!_f) {
          return false
        }
      }
      if (!_f) {
        return true
      }

      if (_f.hasOwnProperty('type') && _f.type) {
        if (_f.type == 'status') {
          if (_f.value == '1') {
            return true
          } else if (_f.value == '0') {
            return false
          }
        } else if (_f.type == 'date') {
          const daypass = moment(this.date_time).diff(moment(_f.value), 'second')
          if (daypass > 0) {
            return false
          } else {
            return true
          }
        }
      } else {
        if (
          _f.name == 'FBCHAT' ||
          _f.name == 'PROMOTION' ||
          _f.name == 'PREMADE' ||
          _f.name == 'BLOG_PAGE' ||
          _f.name == 'PRODUCT_PAGE' ||
          _f.name == 'PREMADE' ||
          _f.name == 'CUSTOM_ELEMENT' ||
          _f.name == 'LINECHAT' ||
          _f.name == 'PROMOTION' ||
          _f.name == 'MEMBER_POINT' ||
          _f.name == 'FREE_ITEM' ||
          _f.name == 'SECTION_BOOTSTRAP' ||
          _f.name == 'OMNICHAT'
        ) {
          if (_f.value == '1') {
            return true
          } else if (_f.value == '0') {
            return false
          } else {
            const daypass = moment(this.date_time).diff(moment(_f.value), 'second')
            if (daypass > 0) {
              return false
            } else {
              return true
            }
          }
        } else {
          if (_f.value == '1') {
            return true
          } else if (_f.value == '0') {
            return false
          }
        }
      }
    }
    return this.feature
  }
}
