import { IProducts } from './IProducts'

export namespace IWarehouse {
  export interface WarehouseTransferTimelineEntity {
    created_at?: string | null
    created_by?: string | null
    id?: number
    is_comment?: number | null
    message?: string | null
    updated_at?: string | null
    updated_by?: string | null
    warehouse_transfer_id?: number | null
  }

  export interface WarehouseTransferDetailsEntity {
    amount?: number | null
    feature_img?: string | null
    id?: number
    name?: string | null
    price?: number | null
    product_id?: number | null
    prop_enable?: number | null
    properties_desc?: string | null
    properties_desc2?: string | null
    property_info?: string | null
    property_info2?: string | null
    sku?: string | null
    warehouse_transfer_id?: number | null
    product: IProducts.ProductPostsEntity
  }

  export interface WarehouseTransferDetail {
    created_at?: string | null
    created_by?: string | null
    id?: number
    note?: string | null
    status?: number | null
    transfer_code?: string | null

    /** transfer,receive,withdraw,adjust */
    type?: string | null
    updated_at?: string | null
    updated_by?: string | null

    /** warehouse_id */
    warehouse_receiver?: number | null

    /** warehouse_id */
    warehouse_sender?: number | null
    warehouse: {
      receiver: {
        id: number
        name: string
      }
      sender: {
        id: number
        name: string
      }
    }
    timeline: WarehouseTransferTimelineEntity[]
    details: WarehouseTransferDetailsEntity[]
  }

  export enum WarehouseTransferState {
    ConfirmAll = 'confirm_all',
    CancelAll = 'cancel_all',
  }

  export enum WarehouseTransfer {
    Transfer = 'transfer',
    Receive = 'receive',
    Withdraw = 'withdraw',
    Adjust = 'adjust',
  }

  export interface UpdateWarehouseInventoryModel {
    activity_note: string
    product_id: number
    is_edit_price?: boolean
    products: {
      error_message?: string
      id: number
      instock: number
      old_instock: number
      changed: number
      calculated_marketplace_changed: number
      sku: string
      status: number
      name: string
      feature_img: string
      prop_enable: number
      property_info: string
      property_info2: string
      properties_desc: string
      properties_desc2: string
      product_type: string
      warehouse: {
        error_message?: string
        channel_logo: string
        channel_name: string
        warehouse_id: number
        instock: number
        old_instock: number
        product_id: number
        changed: number
        normal_price: number
        discount_price: number
      }[]
      marketplaces: {
        error_message?: string
        channel: string
        channel_logo: string
        channel_name: string
        channel_id: string
        channel_inventory_id: string
        channel_product_id: string
        instock: number
        old_instock: number
        changed: number
        sku: string
      }[]
    }[]
  }
}
