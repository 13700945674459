import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { ITrcloudGateway } from './types/ITrcloudGateway'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class CmsTrcloudGatewayService {
  private http = inject(HttpClient)

  getSettingTrcloud() {
    return this.http.get(
      `/services/ketshopweb-accounts/trcloud/get_setting_trcloud`,
    ) as Observable<ITrcloudGateway.GetSettingTrcloud>
  }

  createAccount(input: ITrcloudGateway.CreateAccountBody) {
    return this.http.post(`/services/ketshopweb-accounts/trcloud/connect_trcloud`, input) as Observable<{
      status: string
      id: number
      data: {
        api_url: string
        company_id: number
        encrypt_head: string
        origin: string
        passkey: string
        status: boolean
      }
    }>
  }

  getTrcloudWarehouse() {
    return this.http.get(`/services/ketshopweb-accounts/trcloud/get_trcloud_warehouse`) as Observable<{
      data: ITrcloudGateway.TrcloudWarehousedetail[]
    }>
  }

  searchContact(input: ITrcloudGateway.ContactSearchBody) {
    return this.http.post<{ data: ITrcloudGateway.Contact[]; count: number }>(
      '/services/ketshopweb-accounts/contact/search_contact',
      input,
    )
  }

  getTrcloudOption() {
    return this.http.get<ITrcloudGateway.TrcloudOption>('/services/ketshopweb-accounts/trcloud/get_trcloud_option')
  }

  listTrcloudWarehouse() {
    return this.http.get<ITrcloudGateway.ListTrcloudWarehouse>(
      '/services/ketshopweb-accounts/trcloud/list_trcloud_warehouse',
    )
  }

  listwarehousetrcloud() {
    return this.http.get<{ data: ITrcloudGateway.TrcloudWarehouse[] }>(
      '/services/ketshopweb-accounts/trcloud/list_warehouse_trcloud',
    )
  }

  SaveTrcloudOption(input: ITrcloudGateway.SaveTrcloudOption) {
    return this.http.post('/services/ketshopweb-accounts/trcloud/save_trcloud_option', input)
  }
  searchTaxinfo(input: ITrcloudGateway.TaxinfoSearchBody) {
    return this.http.post<{ data: ITrcloudGateway.TaxinfoSearcRes[]; count: number }>(
      '/services/ketshopweb-customer/search/tax_info',
      input,
    )
  }

  searchProduct(input: ITrcloudGateway.ProductSearchBody) {
    return this.http.post<{
      data: ITrcloudGateway.ProductSearchRes[]
      report: {
        sync_ketshop: number
      }
      count: number
    }>('/services/ketshopweb-accounts/trcloud/search_product', input)
  }

  checkProviderConnect() {
    return this.http.get('/services/ketshopweb-accounts/trcloud/check_provider_connect')
  }

  updateFormTrcloud(input: ITrcloudGateway.UpdateFormTrcloud) {
    return this.http.post<{ status: string; id: number }>(
      '/services/ketshopweb-accounts/trcloud/update_form_trcloud',
      input,
    )
  }
  updatePrefixAccount(input: ITrcloudGateway.UpdatePrefixAccount) {
    return this.http.post<{ status: string; id: number }>(
      '/services/ketshopweb-accounts/account/update_prefix_account',
      input,
    )
  }
  getAccount() {
    return this.http.get(
      '/services/ketshopweb-accounts/account/get_account',
    ) as Observable<ITrcloudGateway.GetAccountResponse>
  }
  providerConnect(
    save_account: ITrcloudGateway.ProviderConnect[],
    save_warehouse: { warehouse_id: number; id: number }[],
  ) {
    return this.http.post<{ status: string }>('/services/ketshopweb-accounts/trcloud/provider_connect', {
      save_account,
      save_warehouse,
    })
  }

  resendQuotation(input: { ordercode: string }) {
    return this.http.post(`/services/ketshopweb-accounts/billing/update_status_provider_request`, input)
  }

  disconnectTrcloud(input: { id: number; type: string }) {
    return this.http.post<{ status: string; id: number }>(
      `/services/ketshopweb-accounts/trcloud/disconnect_trcloud`,
      input,
    )
  }

  searchProductTrcloud(input: { search: string; start: number; limit: number }) {
    return this.http.post<{ product_id: string; product_name: string; thumbnail: string }[]>(
      '/services/ketshopweb-accounts/trcloud/search_product_trcloud',
      input,
    )
  }

  connectProductTrcloud(input: ITrcloudGateway.ConnectProductTrcloud) {
    return this.http.post<{ status: string; id: number }>(
      '/services/ketshopweb-accounts/trcloud/connect_product_trcloud',
      input,
    )
  }
  disConnectProductTrcloud(input: { product_id: string }) {
    return this.http.post<{ status: string; id: number }>(
      '/services/ketshopweb-accounts/trcloud/disconnect_product_trcloud',
      input,
    )
  }

  SyncTrcloud() {
    return this.http.get<{ status: string }>(`/services/ketshopweb-accounts/trcloud/sync_trcloud`)
  }

  SyncAllProductTrcloud(input?: { rebaseSkus?: boolean }) {
    return this.http.post<{ status: string }>(`/services/ketshopweb-accounts/trcloud//sync_all_product`, input)
  }

  // search_trcloud_order
  searchTrcloudOrder(input: { page: number; per_page: number; search: string; start_date: string; end_date: string }) {
    return this.http
      .post<{
        data: {
          id: number
          web_id: number
          provider_type: string
          provider_bill_ref: string
          provider_bill_refid: string
          trcloud_acc_id: number
          check_hash: string
          is_order: number
          ordercode: string
          docs_type: string
          data: string
          error_message: any
          created_at: string
          updated_at: string
          ref_type: string
          ref_id: number
          docs: {
            docs_type: string
          }[]
        }[]
        count: number
      }>('/services/ketshopweb-accounts/trcloud/search_trcloud_order', input)
      .toPromise()
  }

  // re_create_trcloud_order
  reCreateTrcloudOrder(input: { id: number }) {
    return this.http.post<{ status: string }>('/services/ketshopweb-accounts/trcloud/re_create_trcloud_order', input)
  }

  //get_trcloud_webhook_options
  getTrcloudWebhookOptions() {
    return this.http.get<ITrcloudGateway.TrcloudWebhook>(
      '/services/ketshopweb-accounts/trcloud/get_trcloud_webhook_options',
    )
  }
}
