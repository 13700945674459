import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { title } from 'process'
import { element } from 'protractor'
import { Observable } from 'rxjs'

export namespace ICmsCustomer {
  export type CustomerType = 'member' | 'guest'
  export enum ECustomerType {
    Member = 'member',
    Guest = 'guest',
  }

  export interface ISimpleSearchInput {
    search: string
    type: CustomerType | ''
    with_channel?: boolean
    page: number
    perpage: number
    user_ids?: any
  }

  export interface WishlistSearchInput {
    page: number
    page_size: number
    user_id: number
  }
  export interface WishlistSearchResponse {
    count: number
    data: {
      id?: string
      title_lang1?: string | null
      feature_img?: string | null
      sku?: string | null
      prop_enable?: number | null
      properties_main?: number | null
      property_info?: string | null
      property_info2?: string | null
      properties_desc?: string | null
      properties_desc2?: string | null
      product_type?: string
    }[]
  }

  export interface WishlistGetInput {
    product_id: number
  }

  export interface MemberLevelCms {
    id: number
    title: string
    set_title?: string
    is_active: boolean
    set_active?: boolean
    bg_color: string
    set_bg_color?: string
    require_total: number
    set_require_total?: number
  }

  export interface SetLevelTitleInput {
    member_level_id: number
    title: string
    is_active?: boolean | null
    require_total?: number
    bg_color?: string
    set_require_total?: number
  }

  export interface ListLevelTitleInput {
    member_level_id?: number
    title?: string
  }

  export interface MemberLevelEntity {
    id: number
    is_active?: number | null
    require_total?: number | null
    title?: string | null
    bg_color?: string | null
  }

  export interface SetUserMemberLevelInput {
    member_level_id: number
    user_id: number | string
  }
}

@Injectable({
  providedIn: 'root',
})
export class KetshopwebCustomerService {
  constructor(private _http: HttpClient) {}

  simpleSearch(input: ICmsCustomer.ISimpleSearchInput) {
    return this._http.post('/services/ketshopweb-customer/search/simpleSearch', input)
  }

  listMergeable(id:number) {
    return this._http.get('/services/ketshopweb-customer/customers/mergeable/'+id)
  }

  mergeCustomer(data){
    return this._http.post('/services/ketshopweb-customer/customers/merge',data);
  }

  createWishlist(input: { product_id: number }) {
    return this._http.post('/services/ketshopweb-customer/wishlists', input)
  }
  deleteWishlist(wishlist_id: number) {
    return this._http.delete(`/services/ketshopweb-customer/wishlists/${wishlist_id}`)
  }
  searchWishlist(input: ICmsCustomer.WishlistSearchInput) {
    return this._http.post(
      '/services/ketshopweb-customer/wishlists/search',
      input,
    ) as Observable<ICmsCustomer.WishlistSearchResponse>
  }
  getWishlist(data: ICmsCustomer.WishlistGetInput) {
    const params = {}
    Object.keys(data).forEach((x) => {
      if (data[x]) params[x] = data[x]
    })
    return this._http.get('/services/ketshopweb-customer/wishlists', {
      params,
    }) as Observable<ICmsCustomer.WishlistSearchResponse>
  }
  getWishlistCount() {
    return this._http.get('/services/ketshopweb-customer/wishlists/count') as Observable<number>
  }

  defaultListMemberLevelCms(totalLevel = 10) {
    const memberLevels: ICmsCustomer.MemberLevelCms[] = [
      {
        id: 0,
        title: 'Guest',
        set_title: 'Guest',
        is_active: true,
        set_active: true,
        bg_color: '#007bff',
        set_bg_color: '#007bff',
        require_total: 0,
        set_require_total: 0,
      },
      {
        id: 1,
        title: 'Member',
        set_title: 'Member',
        is_active: true,
        set_active: true,
        bg_color: '#007bff',
        set_bg_color: '#007bff',
        require_total: 0,
        set_require_total: 0,
      },
    ]

    for (let index = 2; index <= totalLevel; index++) {
      memberLevels.push({
        id: index,
        title: `Level ${index}`,
        set_title: `Level ${index}`,
        is_active: false,
        set_active: false,
        bg_color: '#007bff',
        set_bg_color: '#007bff',
        require_total: 0,
        set_require_total: 0,
      })
    }

    return memberLevels
  }

  memberLevelCmsToEntity(input: ICmsCustomer.MemberLevelCms) {
    return {
      id: input.id,
      title: input.set_title || input.title,
      bg_color: input.bg_color || input.bg_color,
      is_active: Number(input.set_active) || Number(input.is_active),
    } as ICmsCustomer.MemberLevelEntity
  }

  mapMemberLevelCmsToEntity(input: ICmsCustomer.MemberLevelCms[] = this.defaultListMemberLevelCms()) {
    return input.map((element) => {
      return this.memberLevelCmsToEntity(element)
    }) as ICmsCustomer.MemberLevelEntity[]
  }

  memberLevelEntityToCms(input: ICmsCustomer.MemberLevelEntity) {
    return {
      id: input.id,
      title: input.title,
      set_title: input.title,
      bg_color: input.bg_color,
      set_bg_color: input.bg_color,
      is_active: Boolean(input.is_active),
      set_active: Boolean(input.is_active),
      require_total: input.require_total || 0,
      set_require_total: input.require_total || 0,
    } as ICmsCustomer.MemberLevelCms
  }

  mapMemberLevelEntityToCms(input: ICmsCustomer.MemberLevelEntity[]) {
    const result = this.defaultListMemberLevelCms()
    return result.map((element) => {
      const tmpEntity = input.find((x) => x.id == element.id)
      if (tmpEntity) {
        return this.memberLevelEntityToCms(tmpEntity)
      }
      return element
    }) as ICmsCustomer.MemberLevelCms[]
  }

  setLevelTitle(inputs: ICmsCustomer.SetLevelTitleInput[]) {
    return this._http.put('/services/ketshopweb-customer/memberlevel', inputs).toPromise()
  }

  listLevelTitle(data = {} as ICmsCustomer.ListLevelTitleInput) {
    // console.log('console', data)
    const params = {}
    Object.keys(data).forEach((x) => {
      if (data[x]) params[x] = data[x]
    })
    return this._http
      .get('/services/ketshopweb-customer/memberlevel', {
        params,
      })
      .toPromise() as Promise<ICmsCustomer.MemberLevelEntity[]>
  }

  setUserMemberLevel(input: ICmsCustomer.SetUserMemberLevelInput) {
    return this._http.post('/services/ketshopweb-customer/memberlevel', input).toPromise()
  }
}
