import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { FeaturesEffects } from './effects'
import { FeaturesFEATURE_KEY, FeaturesReducer } from './reducer'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FeaturesFEATURE_KEY, FeaturesReducer),
    EffectsModule.forFeature([FeaturesEffects]),
  ],
})
export class FeaturesSateModule {}
