import { createFeatureSelector, createSelector } from '@ngrx/store'
import { IPagesState } from './models'
import { PagesFEATURE_KEY } from './reducer'

const selectState = createFeatureSelector<IPagesState.PagesState>(PagesFEATURE_KEY)

const page_version = createSelector(selectState, (state: IPagesState.PagesState) => state.page_version)
const current_ref = createSelector(selectState, (state: IPagesState.PagesState) => state.current_ref)
const show_history_version = createSelector(selectState, (state: IPagesState.PagesState) => state.show_history_version)
const wizard_setup = createSelector(selectState, (state: IPagesState.PagesState) => state.wizard_setup)

export const selectors_pages = {
  page_version,
  current_ref,
  show_history_version,
  wizard_setup,
}
