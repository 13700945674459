import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetTranslateDirective } from './translate.directive'
import { KetTranslatePipe } from './translate.pipe'

@NgModule({
  declarations: [KetTranslateDirective, KetTranslatePipe],
  imports: [CommonModule],
  exports: [KetTranslateDirective, KetTranslatePipe],
})
export class KetTranslateModule {}
