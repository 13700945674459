import { cloneDeep } from 'lodash'
import { Observable } from 'rxjs'
import { html_ts, IQrPointPrintLabel } from '../html_ts'
import * as moment from 'moment'
import { qr_to_base64 } from './qr_to_base64'
import { IMemberPoint } from 'ketshopweb-sdk/src/promotion/types'

export function qr_point_print_label(type: 'qr', size: 'A4', qr_point: IMemberPoint.DetailsQrPointInput) {
  const print: IQrPointPrintLabel = html_ts.qr_point_print_label_a4.init()

  new Observable<IMemberPoint.QrPointsDetailsEntity[]>((ob) => {
    let req_data: IMemberPoint.QrPointsDetailsEntity[] = []
    for (const qr of qr_point.details) {
      if (req_data.length < 36) {
        req_data.push(qr)
      } else {
        ob.next(cloneDeep(req_data))
        req_data = []
        req_data.push(qr)
      }
    }
    ob.next(cloneDeep(req_data))
    setTimeout(() => {
      ob.complete()
    }, 100)
  }).subscribe({
    next: async (req_data) => {
      const page_print = print.page()
      for (const item of req_data) {
        const item_model = {
          title: '',
          code: '',
          img: '',
        }
        item_model.title = qr_point.name
        item_model.code = item.code
        if (type == 'qr') {
          const qrcode_url = (await qr_to_base64(qr_point.id + ',' + item.code, {
            scale: 4,
            width: 120,
            margin: 1,
          })) as string
          item_model.img = qrcode_url
        }
        page_print.add_item('qrcode', item_model)
      }
    },
    complete: () => {
      const _w = window.open()
      setTimeout(() => {
        _w.document.write(print.render(`qr-point-${moment().format('YYYY-MM-DD-THH_mm')}`))
      }, 20)
    },
  })
}
