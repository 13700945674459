import { createFeatureSelector, createSelector } from '@ngrx/store'
import { IFeaturesState } from './models'
import { FeaturesFEATURE_KEY } from './reducer'
import { state } from '@angular/animations'
import * as moment from 'moment'

const selectState = createFeatureSelector<IFeaturesState.FeaturesState>(FeaturesFEATURE_KEY)

const wishlist = createSelector(selectState, (state: IFeaturesState.FeaturesState) => state.feature['WISHLIST'])
const member_point = createSelector(selectState, (state: IFeaturesState.FeaturesState) => state.feature['MEMBER_POINT'])

const all_feature = (feature: string[]) =>
  createSelector(selectState, (state) => {
    const model = {}

    feature.map((feature_key) => {
      const _feature = state.feature[feature_key]
      if (!_feature) return null
      let val: any = _feature.value
      if (_feature.type == 'status') {
        val = _feature.value == '1' ? true : false
      }
      if (_feature.type == 'qty') {
        val = +_feature.value
      }
      if (_feature.type == 'date') {
        const daypass = moment().diff(moment(_feature.value), 'second')
        if (daypass > 0) {
          val = false
        } else {
          val = true
        }
      }
      model[feature_key] = val
    })
    return model
  })

export const selectors_features = {
  wishlist,
  member_point,
  all_feature,
}
