import { SafeAny } from '@ketshopweb/ui/core/types'
import { Component, forwardRef, OnInit, Input } from '@angular/core'
import { Store } from '@ngrx/store'
import { NgStore, selectors_channels, selectors_orders } from '@store/ketshopweb-store'
import { cloneDeep, groupBy } from 'lodash'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { OnChangeType, OnTouchedType } from '@ketshopweb/ui/core/types/control-value-accessor'
import { OrderService } from '@cms/services/order.service'

declare let alasql
@Component({
  selector: 'ket-order-tags-selector',
  templateUrl: './order-tags-selector.component.html',
  styleUrls: ['./order-tags-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => KetOrderTagsSelectorComponent),
      multi: true,
    },
  ],
})
export class KetOrderTagsSelectorComponent extends NgStore implements ControlValueAccessor {
  onChange: OnChangeType = () => {}
  onTouched: OnTouchedType = () => {}

  @Input() tags: number[] = []
  search_model = {
    sort: 5,
    search: '',
    page: 1,
    perpage: 10,
  }

  @Input() type: 'CompleteTagData' | 'AnotherValue' = 'CompleteTagData'

  data: {
    Id: number
    Tagname: string
    BgColor: string
    UpdatedAt: string
    UpdatedBy: string
    checked: boolean
  }[] = []

  constructor(
    public store: Store<SafeAny>,
    private Service_Order: OrderService,
  ) {
    super(store)
  }

  isSpinning = false
  total = 0
  onDestroy() {}

  async writeValue(value: any[]) {
    this.search(false)
  }

  onScroll() {
    if (this.search_model.perpage < this.total) {
      this.isSpinning = true
      this.search(true)
    }
  }

  async search(next, clearpage = false) {
    if (next) {
      this.search_model.perpage += 10
    }
    if (clearpage) {
      this.search_model.perpage = 10
    }
    await this.Service_Order.search_order_tags(this.search_model).then(
      (res: {
        data: {
          Id: number
          Tagname: string
          BgColor: string
          UpdatedAt: string
          UpdatedBy: string
          checked: boolean
        }[]
        count: number
      }) => {
        this.data = res.data
        this.total = res.count
        this.data = this.data.map((item) => {
          if (this.tags.includes(item.Id)) {
            return { ...item, checked: true }
          } else {
            return { ...item, checked: false }
          }
        })
        setTimeout(() => {
          this.isSpinning = false
        }, 500)
      },
    )
  }

  registerOnChange(fn: OnChangeType): void {
    this.onChange = fn
  }

  registerOnTouched(fn: OnTouchedType): void {
    this.onTouched = fn
  }

  onCheckState() {
    const selectVals: any = []
    this.data.forEach((c) => {
      if (c.checked) selectVals.push(c)
    })
    const selectedTags = this.data.filter((x) => x.checked === true)
    const unselectedTags = this.data.filter((x) => x.checked === false)

    let value = cloneDeep(this.data)
    for (const tag of selectedTags) {
      if (!this.tags.some((existingTag) => existingTag === tag.Id)) {
        this.tags.push(tag.Id)
      }

      if (!value.some((existingTag) => existingTag.Id === tag.Id)) {
        value.push(tag)
      }
    }

    for (const tag of unselectedTags) {
      if (this.tags.some((existingTag) => existingTag === tag.Id)) {
        this.tags = this.tags.filter((existingTag) => existingTag !== tag.Id)
      }
      if (value.some((existingTag) => existingTag.Id === tag.Id)) {
        value = value.filter((existingTag) => existingTag.Id !== tag.Id)
      }
    }

    if (this.type == 'CompleteTagData') {
      this.onChange(value)
      console.log(value)
    } else {
      this.onChange(this.tags)
    }
  }

  check(item) {
    const m = this.data.find((x) => x.Id == item.Id)
    if (m) {
      m.checked = item.checked
    }

    this.onCheckState()
  }
}
