import { Component, OnInit, Input } from '@angular/core'
import { ProductsService } from '@cms/services/products.service'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { find, cloneDeep } from 'lodash'
import { CmsWarehouseService } from 'ketshopweb-services/warehouse.service'
@Component({
  selector: 'app-modal-addproduct',
  templateUrl: './modal-addproduct.component.html',
  styleUrls: ['./modal-addproduct.component.css'],
})
export class ModalAddproductComponent implements OnInit {
  @Input() data: any
  @Input() type = 'product'
  @Input() disabel_set = false

  constructor(
    private Service_Product: ProductsService,
    private Service_Warehouse: CmsWarehouseService,
    private modal: NzModalRef,
  ) {}
  model: any = {
    lang: 'lang1',
    status: '',
    search: '',
    cate_id: '',
    group: '',
    page: 1,
    perpage: 25,
    ids: '',
    orderby: '8',
    sort: 'desc',
    group_id: '',
    type: '',
    id: 0,
  }
  model_warehouse: any = {
    warehouse_id: '',
    page: 1,
    page_size: 25,
    status: '',
    search: '',
    cate_id: '',
  }
  product: any = []
  total: any = 0
  selecteditem: any = []
  allcategory: any = []
  limitProduct = 0
  isSelectQty = true
  _sortBy: any[] = [
    { id: '3', name: 'สินค้าล่าสุด' },
    { id: '2', name: 'สินค้าเก่าสุด' },
    { id: '0', name: 'สินค้าจาก ก - ฮ' },
    { id: '1', name: 'สินค้าจาก ฮ - ก' },
    { id: '4', name: 'ราคา ต่ำ - สูง' },
    { id: '5', name: 'ราคา สูง - ต่ำ' },
    { id: '8', name: 'สินค้าขายดี' },
    { id: '7', name: 'สินค้าแนะนำ' },
  ]
  async ngOnInit() {
    if (localStorage.getItem('orderby')) {
      this.model.orderby = localStorage.getItem('orderby')
    }
    //  console.log('this.datathis.datathis.datathis.data',this.data)
    if (!this.data.warehouse_id) {
      this.data.warehouse_id = -1
    }
    this.allcategory = await this.Service_Product.productMainCategory()
    this.allcategory.unshift({
      id: '-1',
      lang1: 'Uncategory',
      lang2: 'Uncategory',
      lang3: 'Uncategory',
      lang4: 'Uncategory',
    })
    this.allcategory.unshift({ id: '', lang1: 'All', lang2: 'All', lang3: 'All', lang4: 'All' })

    this.search()
    this.selecteditem = cloneDeep(this.data.details)
    this.limitProduct = this.data.hasOwnProperty('limitProduct') ? this.data.limitProduct : 0
    this.isSelectQty = this.data.hasOwnProperty('isSelectQty') ? this.data.isSelectQty : true
  }
  pagechage(page) {
    this.model.page = page
    this.search()
  }
  deleteSelected(i) {
    this.selecteditem.splice(i, 1)
  }
  addProduct(data) {
    const ids = []
    let check = undefined
    const chk = this.selecteditem.filter(function (o) {
      return o.id === data.id
    }) as any[]

    if (data.product_type == 'set') {
      for (const index of data.product_set) {
        ids.push(index.product_id)
      }

      if (chk) {
        for (const product of chk) {
          const a = product.product_set.filter((x) => ids.includes(x.product_id)) as []
          console.log(a.length == ids.length)

          if (a.length == ids.length) {
            check = product
          }
        }
      }
    } else {
      if (chk.length > 0) {
        check = chk[0]
      }
    }

    //

    if (!check) {
      data.qty = 1
      if (!this.limitProduct) {
        this.selecteditem.push(cloneDeep(data))
      } else {
        if (this.limitProduct === this.selecteditem.length) return
        this.selecteditem.push(cloneDeep(data))
      }
    } else {
      check.qty++
    }
  }
  search() {
    if (this.type == 'product_set') {
      this.model.type = 'normal'
      this.model.id = this.data.product_id
    }

    if (this.data.warehouse_id == -1) {
      this.Service_Product.searchProduct(this.model)
        .then((res: any) => {
          localStorage.setItem('orderby', this.model.orderby)
          this.product = res.data
          this.total = res.count
        })
        .catch((data) => {
          console.log(data.error.message)
        })
    } else {
      this.model_warehouse.warehouse_id = this.data.warehouse_id
      this.model_warehouse.page = this.model.page
      this.model_warehouse.page_size = this.model.page_size
      this.model_warehouse.cate_id = this.model.cate_id
      this.model_warehouse.search = this.model.search
      this.Service_Warehouse.search_product(this.model_warehouse)
        .toPromise()
        .then((res: any) => {
          console.log(res)
          for (const p of res.data) {
            p.inventory = p.instock
          }
          this.product = res.data
          this.total = res.count
        })
        .catch((data) => {
          console.log(data.error.message)
        })
    }
  }
  async ok() {
    if (this.type == 'product_set') {
      for (const index of this.selecteditem) {
        if (index.group_id) {
          const search = cloneDeep(this.model)
          search.perpage = -1
          search.group_id = index.group_id
          await this.Service_Product.searchProduct(search)
            .then((res: any) => {
              index['product_property'] = []
              // index['qty'] = 1
              // index['max_stock'] = res.data[0].instock
              for (const product of res.data) {
                if (product.group_id && product.group_id == index.group_id && product.id !== index.id) {
                  index['product_property'].push(product)
                }

                // if (product.instock < index['max_stock']) {
                //   index['max_stock'] = product.instock
                // }
              }
            })
            .catch((data) => {
              console.log(data.error.message)
            })
        }
      }
    }
    this.modal.close(this.selecteditem)
  }

  set_property_info(Event, data, number) {
    for (const index of data.product) {
      if (number == 1) {
        if (index.property_info == Event && (index.property_info2 == data.property_info2 || index.prop_enable == 1)) {
          console.log(index.instock)
          if (index.instock / data.qty >= 1) {
            data.sku = index.sku
            data.instock = index.instock
            data.feature_img = index.feature_img
            data.product_id = index.id
            data.property_info = index.property_info
            data.property_info2 = index.property_info2 || null
            data.status = index.status
            data.title_lang1 = index.title_lang1
          } else {
            const a = data.product.find((x) => x.property_info == Event && x.instock != 0)
            data.sku = a.sku
            data.instock = a.instock
            data.feature_img = a.feature_img
            data.product_id = a.id
            data.property_info = a.property_info
            data.property_info2 = a.property_info2 || null
            data.status = a.status
            data.title_lang1 = a.title_lang1
          }
        }
      } else {
        if (index.property_info2 == Event && index.property_info == data.property_info) {
          if (index.instock / data.qty >= 1) {
            data.sku = index.sku
            data.instock = index.instock
            data.feature_img = index.feature_img
            data.product_id = index.id
            data.property_info = index.property_info
            data.property_info2 = index.property_info2
            data.status = index.status
            data.title_lang1 = index.title_lang1
          } else {
            const a = data.product.find((x) => x.property_info2 == Event && x.instock != 0)
            data.sku = a.sku
            data.instock = a.instock
            data.feature_img = a.feature_img
            data.product_id = a.id
            data.property_info = a.property_info
            data.property_info2 = a.property_info2
            data.status = a.status
            data.title_lang1 = a.title_lang1
          }
        }
      }
    }
  }
}
