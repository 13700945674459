import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { IDelivery } from './types/IDelivery'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class CmsDeliveryService {
  private http = inject(HttpClient)

  searchShippingGroups(input: IDelivery.SearchShippingGroups) {
    return this.http.post(
      `/services/ketshopweb-delivery/shipping_group/search`,
      input,
    ) as Observable<IDelivery.SearchShippingGroupsResponse>
  }

  confirmShippingGroupQuantity(id: number) {
    return this.http.post(`/services/ketshopweb-delivery/shipping_group/${id}/confirm_quantities`, {}) as Observable<{
      status: number
      message: string
      data: {
        id: number
        confirm_quantity_at: string
        confirm_quantity_by: string
      }
    }>
  }

  searchShippingGroupOrdersById(id: number, input: IDelivery.SearchShippingGroupOrdersById) {
    return this.http.post(
      `/services/ketshopweb-delivery/shipping_group/${id}/orders/search`,
      input,
    ) as Observable<IDelivery.SearchShippingGroupOrdersByIdResponse>
  }

  getShippingGroupPackagedCountById(id: number) {
    return this.http.get(
      `/services/ketshopweb-delivery/shipping_group/${id}/packaged_count`,
    ) as Observable<IDelivery.GetShippingGroupPackagedCountByIdResponse>
  }

  getShippingGroupHistoryById(id: number, updated_ref: string) {
    return this.http.get(
      `/services/ketshopweb-delivery/shipping_group/${id}/histories?updated_ref=${updated_ref}`,
    ) as Observable<IDelivery.GetShippingGroupHistoryByIdResponse>
  }

  getShippingGroupById(id: number) {
    return this.http.get(
      `/services/ketshopweb-delivery/shipping_group/${id}`,
    ) as Observable<IDelivery.GetShippingGroupByIdResponse>
  }

  // /services/ketshopweb-delivery
  deliveryAll(type: string) {
    return this.http.get(`/services/ketshopweb-delivery/delivery/all${type ? `?type=${type}` : ''}`) as Observable<any>
  }

  deliveryById(id: number) {
    return this.http.get(`/services/ketshopweb-delivery/delivery/by/${id}`) as Observable<any>
  }

  deliveryOverview() {
    return this.http.get(`/services/ketshopweb-delivery/delivery/deliveryOverview`) as Observable<any>
  }

  channel_name(data: { exclude_channels?: string[]; exclude_channel_groups?: string[] } = {}) {
    const params = {}
    Object.keys(data).forEach((x) => {
      if (data[x]) params[x] = data[x]
    })

    return this.http.get(`/services/ketshopweb-delivery/delivery/channel_name`, { params }) as Observable<{
      channel: {
        channel_id: string
        channel: string
        channel_name: string
        label?: string
        img: string
        group?: string
      }[]
      webuser: {
        id: number
        usr_name: string
        name: string
        lastname: string
        user_role: string
        picture: string
        tel: string
        email: string
      }[]
    }>
  }

  getshippinggrouptype() {
    return this.http.get(`/services/ketshopweb-delivery/shipping_group/get_shipping_group_type`) as Observable<
      {
        id: number
        name: string
        img: string
        shipping_group_type: string
        courier_code?: string
        group: string
      }[]
    >
  }

  createPacking(data: { shipping_group_id: number; order_id: number }) {
    return this.http.post('/services/ketshopweb-delivery/packings', data) as Observable<IDelivery.PackingEntity>
  }

  getPackingByOrderId(order_id: number) {
    return this.http.get(`/services/ketshopweb-delivery/packings`, {
      params: { order_id: String(order_id) },
    }) as Observable<IDelivery.PackingEntity>
  }

  startRecordingPacking(packing_id: number, data: { session_id: string }) {
    return this.http.post(
      `/services/ketshopweb-delivery/packings/${packing_id}/recording/start`,
      data,
    ) as Observable<IDelivery.PackingEntity>
  }

  stopRecordingPacking(packing_id: number) {
    return this.http.post(
      `/services/ketshopweb-delivery/packings/${packing_id}/recording/stop`,
      {},
    ) as Observable<IDelivery.PackingEntity>
  }

  cancelRecordingPacking(packing_id: number) {
    return this.http.post(
      `/services/ketshopweb-delivery/packings/${packing_id}/recording/cancel`,
      {},
    ) as Observable<IDelivery.PackingEntity>
  }

  updatePacking(data: { packed: number; ordercode: string; shipping_group_id: number }) {
    return this.http.post(
      `/services/ketshopweb-delivery/packings/update/packed`,
      data,
    ) as Observable<IDelivery.PackingEntity>
  }
}
