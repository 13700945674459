import { Component, OnInit, Input } from '@angular/core'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { EmailService } from '@cms/services/email.service'

@Component({
  selector: 'app-modal-add-email',
  templateUrl: './modal-add-email.component.html',
  styleUrls: ['./modal-add-email.component.css'],
})
export class ModalAddEmailComponent implements OnInit {
  @Input() data: any
  message_duration = 4000
  domain: string = '@' + location.hostname
  free = 0
  model: any = {
    email: '',
    password: '',
    confirmpassword: '',
    quota: 0,
  }
  constructor(
    private modal: NzModalRef,
    private message: NzMessageService,
    private Service_Email: EmailService,
  ) {}

  ngOnInit() {
    this.free = this.data.information.MAXQOUTA - this.data.information.quota_use
  }

  ok() {
    this.model.email = this.model.email.toLowerCase()

    if (this.model.email == '') {
      this.message.error('โปรดกรอกชื่ออีเมล', { nzDuration: this.message_duration })
      return false
    } else if (this.model.password == '') {
      this.message.error('โปรดกรอกรหัสผ่าน', { nzDuration: this.message_duration })
      return false
    } else if (this.model.password != this.model.confirmpassword) {
      this.message.error('รหัสผ่านไม่ตรงกัน', { nzDuration: this.message_duration })
      return false
    } else if (!/^(?=.*[0-9])(?=.*[a-zA-Z])[0-9a-zA-Z]{9,}$/.test(this.model.password)) {
      this.message.error(
        'รหัสผ่านต้องมากกว่า 8 ตัวอักษร โดยรหัสต้องเป็นตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น และต้องมีตัวเลขอย่างน้อย 1 ตัว',
        { nzDuration: this.message_duration },
      )
      return false
    } else if (!/^[0-9]*$/g.test(this.model.quota)) {
      this.message.error('พื่นที่ต้องเป็นตัวเลขเท่านั้น', { nzDuration: this.message_duration })
      return false
    } else if (this.model.quota <= 0) {
      this.message.error('พื่นที่ต้องมากกว่า 0', { nzDuration: this.message_duration })
      return false
    } else if (Number(this.model.quota) > Number(this.free)) {
      this.message.error('พื้นที่ไม่พอใช้งาน', { nzDuration: this.message_duration })
      return false
    }
    $('.theme-loader').show()
    this.Service_Email.createEmailAccount(this.model)
      .then((res: any) => {
        this.modal.close(true)
      })
      .catch((data: any) => {
        this.message.error(data.error.message, { nzDuration: this.message_duration })
        return false
      })
      .finally(() => {
        $('.theme-loader').hide()
      })

    console.log(this.model)
    return false
  }
}
