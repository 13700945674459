import { Directive, ElementRef, AfterViewInit, Input, Renderer2, OnChanges, SimpleChanges } from '@angular/core'

@Directive({
  selector: '[font]',
})
export class FontDirective implements AfterViewInit, OnChanges {
  constructor(
    private el: ElementRef,
    private render: Renderer2,
  ) {}

  @Input() font: string

  ngAfterViewInit() {
    this.render.setStyle(this.el.nativeElement, 'font-family', this.font)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.font) {
      //console.log('font changed', changes.font);
      if (changes.font.previousValue !== undefined) {
        this.font = changes.font.currentValue
        //console.log('change')
        this.render.setStyle(this.el.nativeElement, 'font-family', this.font)
      }
    }
  }
}
