import { Component, OnInit, Input } from '@angular/core'
import { SettingService } from '@cms/services/setting.service'
@Component({
  selector: 'ket-product-item-inline',
  templateUrl: './product-item-inline.component.html',
  styleUrls: ['./product-item-inline.component.scss'],
})
export class KetProductItemInlineComponent implements OnInit {
  constructor(private Service_Setting: SettingService) {}

  @Input() showBadgeSold = false
  @Input() qty = false
  @Input() showFlashsaleLabel = false
  @Input() showStatus = true
  @Input() showSku_Variant = true
  @Input() showProductSet = false
  @Input() showFlashsale = false
  @Input() showSKU = false
  @Input() showMarketplace = false
  @Input() showPointRedemptionStatus = false
  @Input() promotion = []

  @Input() imgSize = 'md'
  @Input() products: {
    feature_img: string
    title_lang1: string
    product_type: string
    sku: string
    property_info: string
    property_info2: string
    properties_desc: string
    properties_desc2: string
    prop_enable: number
    status: number
    id: number
    qty?: number
    instock: number
    inventory: number
    temp_price: number
    product_price: number
    totalprice: number
    status_redemption?: number
    product_set?: {
      instock: number
      qty: number
      price: number
      group_id: string
      sku: string
      title_lang1: string
      feature_img: string
      prop_enable: number
      id: number
      property_info: string
      property_info2: string
      properties_desc: string
      properties_desc2: string
      pre_order?: number
    }[]
    marketplace_inventory?: {
      channel: string
      channel_name: string
    }[]
    pre_order?: number
    vat_rate?: number
    vat_option?: string
    promotion?: []
  } = {
    feature_img: '',
    title_lang1: '',
    product_type: '',
    sku: '',
    property_info: '',
    property_info2: '',
    properties_desc: '',
    properties_desc2: '',
    prop_enable: 0,
    status: 0,
    id: 0,
    qty: 1,
    instock: 0,
    temp_price: 0,
    product_price: 0,
    totalprice: 0,
    inventory: 0,
    product_set: [],
    marketplace_inventory: [],
    promotion: [],
  }

  _option: any = {}
  async ngOnInit() {
    const pop_key = ['product_setting']
    const option: any = await this.Service_Setting.searchOption({ pop_key: pop_key }).catch((err) => {
      console.error(err)
      return []
    })
    option.forEach((obj) => {
      if (obj.pop_key == 'product_setting') {
        const data = JSON.parse(obj.pop_value)
        this._option[obj.pop_key] = data
      } else {
        this._option[obj.pop_key] = obj.pop_value
      }
    })
  }
}
