import { h, Fragment } from 'preact'
import { CssGlobal } from './css_global'

export const OrderA4Full = ({ elements }: { elements: h.JSX.Element[] }) => (
  <Fragment>
    <CssGlobal />
    {elements.map((x, i) => {
      return (
        <Fragment key={i}>
          <div class="page-a4-portrait">
            <div class="page-a4-full">{x}</div>
          </div>
          <div class="pagebreak"></div>
        </Fragment>
      )
    })}
  </Fragment>
)
