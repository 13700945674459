import { h, Fragment } from 'preact'
import { IPurchaseOrderHtml } from './types'
import { TmpHeaderJT } from './tmp_header_jt'
import * as moment from 'moment'
import { TmpOrderProductShipingJt } from './tmp_order_product_shipping_jt'

export const TmpBodyShippingJT = ({ order, barcode ,qr_ordercode,layout ,number , all_page}: { order: IPurchaseOrderHtml.Order; barcode: string ,qr_ordercode:string ,layout:number,number:number , all_page:number}) => {  
  return (
    <Fragment>
        <div className={`tmt-break-page ${layout === 2 || layout === 3 ||  layout === 4 ? 'size-4x6' : 'size-a4'}`}>
          
          <div class="flex-row-between" style={`${layout === 8  ? 'max-height: 305px' : ''}`}>
            <div class="flex-row-between text-vertical-lr text-rotate-D180" style={`${layout == 8 || layout === 4 ? 'font-size:10px' : 'font-size:18px'}`}>
              <div style={`${layout === 8 || layout === 4 ? 'padding-bottom: 10px;' : 'padding-bottom: 100px;'} `}>{order.ordercode}</div>
              <div style={`${layout === 8 || layout === 4 ? 'padding-bottom: 10px;' : 'padding-bottom: 100px;'} `}>{order.ordercode}</div>
              <div class="flex-row" style="padding-bottom: 50px;">
                <div class="col-auto p-0 mt-2 text-nowrap">ใบที่ {number}/{all_page}</div>
                <div class="col-auto p-0 mt-2 text-nowrap">{moment().format('YYYY-MM-DD')}</div>
                <div class="col-auto p-0 mt-2 text-nowrap">{moment().format('HH:mm:ss')}</div>
              </div>
            </div>
            <div class={`w-100`} style={`${layout === 8 ? 'max-height: 305px' : ''}`}>
              <TmpHeaderJT order={order} barcode={barcode}  layout={layout} />
              {layout !== 8 && layout !== 4 &&<TmpOrderProductShipingJt order={order} qr_ordercode={qr_ordercode}  layout={layout}/>}
            </div>
            <div class="flex-row-between text-vertical-lr" style={`${layout === 8 || layout === 4 ? 'font-size:10px; padding-top: 50px;' : 'font-size:18px; padding-top: 50px;'}`}>
              <div style={`${layout === 8 || layout === 4 ? 'padding-bottom: 10px;' : 'padding-bottom: 100px;'} `}>{order.ordercode}</div>
              <div style={`${layout === 8 || layout === 4 ? 'padding-bottom: 10px;' : 'padding-bottom: 100px;'} `}>{order.ordercode}</div>
              <div class="">{order.ordercode}</div>
            </div>
          </div>
          <div className={` flex-row-between  text-center pt-1 pb-2 px-4 ${layout == 8 || layout === 4 ? 'font-xs' : 'font-s'}`}>
            {order.shipping_group && order.shipping_group.shipping_group_name && <div class="text-jnt-red">{order.shipping_group.shipping_group_name || ''}</div>}
            {order.shipping_group && order.shipping_group.updated_ref && <div>{order.shipping_group.updated_ref || ''}</div>}
            <div class="text-jnt-red">{moment(order.created_at).format('YYYYMMDD-HHmmss')}</div>
          </div>
        </div>
    </Fragment>
  )
}
