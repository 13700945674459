import { createFeatureSelector, createSelector } from '@ngrx/store'
import { IAppState } from './models'
import { FEATURE_KEY } from './reducer'

const selectState = createFeatureSelector<IAppState.AppState>(FEATURE_KEY)

const warehouse_inventory_changes = createSelector(
  selectState,
  (state: IAppState.AppState) => state.warehouse_inventory_changes,
)

const get_utm = createSelector(selectState, (state: IAppState.AppState) => state.utm)

export const selectors_app = {
  warehouse_inventory_changes,
  get_utm,
}
