import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root',
})
export class PromotionService {
  constructor(private _http: HttpClient) {}

  // listFlashSaleProduct(data:any = {}){
  //     return this._http.post('/promotion/listFlashSaleProduct',data).toPromise();
  // }

  listFlashSale(data: any = {}) {
    return this._http.get('/services/v2/ketcms-frontend/promotion/list_flash_sale', data).toPromise()
  }

  deletePromotionFlashSale(id: any) {
    return this._http.delete('/promotion/promotionFlashSale/' + id).toPromise()
  }

  updatePromotionFlashSale(data: any) {
    return this._http.post('/promotion/updatePromotionFlashSale', data).toPromise()
  }
  searchPromotionFlashSale(data: any) {
    return this._http.post('/promotion/searchPromotionFlashSale', data).toPromise()
  }
  createPromotionFlashSale(data: any) {
    return this._http.post('/promotion/createPromotionFlashSale', data).toPromise()
  }
  getPromotionFlashSaleByID(id: any) {
    return this._http.get('/promotion/promotionFlashSale/' + id).toPromise()
  }

  updatePromotionFreeItem(data: any) {
    return this._http.post('/promotion/updatePromotionFreeItem', data).toPromise()
  }

  deletePromotionFreeItem(id: any) {
    return this._http.delete('/promotion/promotionFreeItem/' + id).toPromise()
  }

  getPromotionFreeItemByID(id: any) {
    return this._http.get('/promotion/promotionFreeItem/' + id).toPromise()
  }
  searchPromotionFreeItem(data: any) {
    return this._http.post('/promotion/searchPromotionFreeItem', data).toPromise()
  }
  createPromotionFreeItem(data: any) {
    return this._http.post('/promotion/createPromotionFreeItem', data).toPromise()
  }

  activePromotion() {
    return this._http.get('/promotion/activePromotion').toPromise()
  }
  getPromotion() {
    return this._http.get('/promotion').toPromise()
  }
  checkCoupon(obj: any) {
    // return this._http.post('/promotion/checkCoupon', obj).toPromise()
    return this._http.post('/services/ketshopweb-promotion/coupons/checkCoupon', obj).toPromise()
  }
  changeCurrentPromotion(obj: any) {
    return this._http.post('/promotion/changeCurrentPromotion', obj).toPromise()
  }

  searchCoupon(obj: any) {
    return this._http.post('/promotion/searchCoupon', obj).toPromise()
  }

  searchCouponGroup(id: number, obj: any) {
    return this._http.post('/promotion/searchCouponGroup/' + id, obj).toPromise()
  }

  createCoupon(obj: any) {
    return this._http.post('/promotion/coupon', obj).toPromise()
  }

  createCouponGroup(obj: any) {
    return this._http.post('/promotion/coupon/group', obj).toPromise()
  }

  updateCoupon(obj: any) {
    return this._http.put('/promotion/coupon/' + obj.id, obj).toPromise()
  }

  deleteCoupon(id) {
    return this._http.delete('/promotion/coupon/' + id).toPromise()
  }

  searchDiscountCondition(obj: any) {
    return this._http.post('/promotion/searchDiscountCondition', obj).toPromise()
  }

  createDiscountCondition(obj: any) {
    return this._http.post('/promotion/discountCondition', obj).toPromise()
  }

  updateDiscountCondition(obj: any) {
    return this._http.put('/promotion/discountCondition/' + obj.id, obj).toPromise()
  }
  deleteDiscountCondition(id) {
    return this._http.delete('/promotion/discountCondition/' + id).toPromise()
  }

  getCouponByID(id) {
    return this._http.get('/promotion/coupon/' + id).toPromise()
  }
  getConditionByID(id) {
    return this._http.get('/promotion/discountCondition/' + id).toPromise()
  }

  reportOverview(data) {
    return this._http.post('/promotion/reportOverview', data).toPromise()
  }

  reportCustomerType(data) {
    return this._http.post('/promotion/reportCustomerType', data).toPromise()
  }

  reportOrderChannel(data) {
    return this._http.post('/promotion/reportOrderChannel', data).toPromise()
  }

  reportBestSeller(data) {
    return this._http.post('/promotion/reportBestSeller', data).toPromise()
  }

  reportOverviewOrder(data) {
    return this._http.post('/promotion/reportOverviewOrder', data).toPromise()
  }

  getReportOrder(data) {
    return this._http.post('/promotion/getReportOrder', data).toPromise()
  }

  createPromotionPoint(data: any) {
    return this._http.post('/services/ketshopweb-promotion/multiplied-points/CreateMultipliedPoints', data).toPromise()
  }
  searchPromotionPoint(data: any) {
    return this._http.post('/services/ketshopweb-promotion/multiplied-points/SearchMultipliedPoints', data).toPromise()
  }

  getPromotionPointeByID(id: any) {
    return this._http.get('/services/ketshopweb-promotion/multiplied-points/' + id).toPromise()
  }

  updatePromotionPointe(obj: any) {
    return this._http
      .put('/services/ketshopweb-promotion/multiplied-points/UpdatePromotionPointe/' + obj.id, obj)
      .toPromise()
  }

  deleteMultipliedPoints(id) {
    console.log(id)

    return this._http
      .delete(`/services/ketshopweb-promotion/multiplied-points/DeleteMultipliedPoints/${id}`)
      .toPromise()
  }
}
