import { SafeAny } from '@ketshopweb/ui/core/types'
import { Component, NgModule, OnDestroy } from '@angular/core'
import { DefaultProjectorFn, MemoizedSelector, Store } from '@ngrx/store'
import { Subscription } from 'rxjs'

export class NgStoreCore {
  constructor(public store: Store<SafeAny>) {}

  protected subscription: {
    [key: string]: Subscription
  } = {}

  public subscribe<State, Result, ProjectorFn = DefaultProjectorFn<Result>>(
    uid: string,
    selector: MemoizedSelector<State, Result, ProjectorFn>,
    res: (res: Result) => void,
  ) {
    if (this.subscription[uid]) {
      this.subscription[uid].unsubscribe()
    }
    this.subscription[uid] = this.store.select(selector).subscribe(res)
  }

  public unsubscribe() {
    console.log('[NgStore] unsubscribe')
    for (const key in this.subscription) {
      if (this.subscription[key]) this.subscription[key].unsubscribe()
    }
  }

  public unsubscribeById(uid: string) {
    if (this.subscription[uid]) {
      this.subscription[uid].unsubscribe()
    }
  }
  // storeQuery
  public storeQuery<State, Result, ProjectorFn = DefaultProjectorFn<Result>>(
    selector: MemoizedSelector<State, Result, ProjectorFn>,
  ): Promise<Result> {
    return new Promise((s, j) => {
      const sub = this.store.select(selector).subscribe((res) => {
        s(res)
        setTimeout(() => sub.unsubscribe(), 1)
      })
    })
  }
}

@Component({
  template: '',
})
export abstract class NgStore extends NgStoreCore implements OnDestroy {
  ngOnDestroy() {
    this.unsubscribe()
    this.onDestroy()
  }
  public abstract onDestroy(): void
}

@NgModule({
  declarations: [NgStore as any],
  imports: [],
  exports: [NgStore as any],
})
export class NgStoreModule {}
