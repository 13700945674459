import { Pipe, PipeTransform } from '@angular/core'
import { Etc } from '../util/etc'

@Pipe({
  name: 'hashDataDom',
})
export class HashDataDomPipe implements PipeTransform {
  constructor(private _etc: Etc) {}
  transform(value: any, args?: any): any {
    //console.log(value)
    return this._etc.uid()
  }
}
