import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core'
import { NzModalService } from 'ng-zorro-antd/modal'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzDrawerRef } from 'ng-zorro-antd/drawer'
import { KetSettingGbprimepayService } from './setting-gbprimepay.service'
import { FeatureService } from '@cms/services/feature.service'
import { KetModalMessageComponent } from '../modal-message'
declare let navigator: any
@Component({
  selector: 'ket-gbpay',
  templateUrl: './modal-gbpay.component.html',
  styleUrls: ['./modal-gbpay.component.css'],
})
export class KetModalGbpayComponent implements OnInit {
  @Output() back = new EventEmitter()
  @Input() model: any
  constructor(
    private service: KetSettingGbprimepayService,
    private message: NzMessageService,
    private modal: NzModalService,
    private drawerRef: NzDrawerRef<string>,
    private Service_Feature: FeatureService,
  ) {}

  message_duration = 4000
  feature_epayment = false

  async ngOnInit() {
    this.feature_epayment = await this.Service_Feature.getFeature('EPAYMENT')
  }

  copylink(key) {
    // this.model.connected = 1;
    try {
      navigator.clipboard.writeText(key).then((res: any) => {
        this.message.success('copy success', { nzDuration: this.message_duration })
      })
    } catch (err) {}
  }

  // change_key() {
  //   this.model.connected = 0
  // }
  // disconnect() {
  //   $('#preloadwraper').fadeIn()
  //   this.service
  //     .disconnectGbPay(this.model)
  //     .then((res: any) => {
  //       $('#preloadwraper').hide()
  //       // this.message.success("Success")
  //       this.ngOnInit()
  //       this.modal.create<KetModalMessageComponent>({
  //         nzTitle: null,
  //         nzContent: KetModalMessageComponent,
  //         nzClosable: false,
  //         nzVisible: true,
  //         nzFooter: null,
  //         nzWidth: '350px',
  //         nzBodyStyle: {
  //           padding: '1rem',
  //         },
  //         nzWrapClassName: 'vertical-center-modal',
  //         nzComponentParams: {
  //           type: 'success',
  //           title: 'บันทึกเรียบร้อยแล้ว',
  //         },
  //       })
  //     })
  //     .catch((data) => {
  //       // this.modal.error({
  //       //   nzTitle: 'Error',
  //       //   nzContent: data.error.message
  //       // });

  //       $('#preloadwraper').hide()
  //       this.modal.create<KetModalMessageComponent>({
  //         nzTitle: null,
  //         nzContent: KetModalMessageComponent,
  //         nzClosable: false,
  //         nzVisible: true,
  //         nzFooter: null,
  //         nzWidth: '350px',
  //         nzBodyStyle: {
  //           padding: '1rem',
  //         },
  //         nzWrapClassName: 'vertical-center-modal',
  //         nzComponentParams: {
  //           type: 'error',
  //           // btnClass: "btn-primary",
  //           title: 'ไม่สามารถบันทึกได้',
  //           message: data.error.message,
  //         },
  //       })
  //     })
  // }
  // confirmDisconnect() {
  //   this.modal.confirm({
  //     nzTitle: 'ต้องการยกเลิกการเชื่อมต่อ GB PRIME PAY',
  //     nzContent: null,
  //     nzWrapClassName: 'vertical-center-modal',
  //     nzOkText: 'ใช่',
  //     nzCancelText: 'ไม่',
  //     nzOkType: 'danger',
  //     nzOnOk: () => {
  //       this.disconnect()
  //     },
  //   })
  // }
  // connect() {
  //   console.log(this.model)
  //   if (!this.model.api_key || !this.model.api_secret || !this.model.custom_key) {
  //     this.modal.create<KetModalMessageComponent>({
  //       nzTitle: null,
  //       nzContent: KetModalMessageComponent,
  //       nzClosable: false,
  //       nzVisible: true,
  //       nzFooter: null,
  //       nzWidth: '350px',
  //       nzBodyStyle: {
  //         padding: '1rem',
  //       },
  //       nzWrapClassName: 'vertical-center-modal',
  //       nzComponentParams: {
  //         type: 'error',
  //         // btnClass: "btn-primary",
  //         title: 'ไม่สามารถบันทึกได้',
  //         message: 'โปรดกรอกข้อมูลให้ครบถ้วน',
  //       },
  //     })
  //     return
  //   }

  //   // return;
  //   $('#preloadwraper').fadeIn()
  //   this.service
  //     .connectGbPay(this.model)
  //     .then((res: any) => {
  //       $('#preloadwraper').hide()
  //       // this.message.success("Success")
  //       this.ngOnInit()
  //       this.modal.create<KetModalMessageComponent>({
  //         nzTitle: null,
  //         nzContent: KetModalMessageComponent,
  //         nzClosable: false,
  //         nzVisible: true,
  //         nzFooter: null,
  //         nzWidth: '350px',
  //         nzBodyStyle: {
  //           padding: '1rem',
  //         },
  //         nzWrapClassName: 'vertical-center-modal',
  //         nzComponentParams: {
  //           type: 'success',
  //           title: 'บันทึกเรียบร้อยแล้ว',
  //         },
  //       })
  //     })
  //     .catch((data) => {
  //       // this.modal.error({
  //       //   nzTitle: 'Error',
  //       //   nzContent: data.error.message
  //       // });
  //       $('#preloadwraper').hide()
  //       this.modal.create<KetModalMessageComponent>({
  //         nzTitle: null,
  //         nzContent: KetModalMessageComponent,
  //         nzClosable: false,
  //         nzVisible: true,
  //         nzFooter: null,
  //         nzWidth: '350px',
  //         nzBodyStyle: {
  //           padding: '1rem',
  //         },
  //         nzWrapClassName: 'vertical-center-modal',
  //         nzComponentParams: {
  //           type: 'error',
  //           // btnClass: "btn-primary",
  //           title: 'ไม่สามารถบันทึกได้',
  //           message: data.error.message,
  //         },
  //       })
  //     })
  // }

  _back() {
    this.drawerRef.close()
  }
}
