import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router'

// const _defaultUrlSerializer = new DefaultUrlSerializer();

export default class CustomUrlSerializer implements UrlSerializer {
  private _defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer()

  constructor() {}

  parse(url: string): UrlTree {
    // Encode parentheses
    url = url.replace(/\(/g, '%28').replace(/\)/g, '%29')
    // Use the default serializer.

    return this._defaultUrlSerializer.parse(url)
    // const _defaultUrlSerializer = new DefaultUrlSerializer();
    // return _defaultUrlSerializer.parse(url)
  }

  serialize(tree: UrlTree): string {
    // const _defaultUrlSerializer = new DefaultUrlSerializer();
    return this._defaultUrlSerializer.serialize(tree).replace(/%28/g, '(').replace(/%29/g, ')')
    // return _defaultUrlSerializer.serialize(tree).replace(/%28/g, '(').replace(/%29/g, ')');
  }
}
