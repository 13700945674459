import { SafeAny } from '@ketshopweb/ui/core/types'
import { Component, forwardRef, OnInit, Input } from '@angular/core'
import { Store } from '@ngrx/store'
import { NgStore, selectors_channels, selectors_orders } from '@store/ketshopweb-store'
import { cloneDeep, groupBy } from 'lodash'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { OnChangeType, OnTouchedType } from '@ketshopweb/ui/core/types/control-value-accessor'

declare let alasql
@Component({
  selector: 'ket-assignee-selector',
  templateUrl: './assignee-selector.component.html',
  styleUrls: ['./assignee-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => KetAssigneeSelectorComponent),
      multi: true,
    },
  ],
})
export class KetAssigneeSelectorComponent extends NgStore implements ControlValueAccessor  {
  onChange: OnChangeType = () => {}
  onTouched: OnTouchedType = () => {}

  list_staff:any = [];
  list_staff_display:any = [];
  search_model = {
    search: '',
    page: 1,
    perpage: 10,
  }

  constructor( public store: Store<SafeAny> ) { 
    super(store)
  }

  onDestroy() {

  }

  async writeValue(value: any[]) {
    const list_staff = await this.storeQuery(selectors_orders.list_staff);
    // console.log('list_stafflist_stafflist_stafflist_stafflist_stafflist_stafflist_staff');
    // console.log(list_staff,value);
    this.list_staff = cloneDeep(list_staff);
   
    if (!value || value.length === 0) {
      this.onCheckState()
      await this.search(false);
      return []
    }

    this.list_staff.forEach((c) => {
      if (value.includes(c.id)) {
        c.checked = true
      }
    })
    await this.search(false);
  

    this.onCheckState()

  }

  onScroll(){
    this.search(true);
  }

  async search(next,clearpage = false){
    if(next){
      this.search_model.page++;
    }
    if (clearpage) {
      this.search_model.page = 1
    }
    const offset_page = Number(this.search_model.perpage) * (Number(this.search_model.page) - 1)
    let sql = 'SELECT * FROM ? WHERE 1=1 '
    const bindparam = [this.list_staff]
    if (this.search_model.search !== '') {
      sql += `AND (usr_name LIKE ? OR name LIKE ? ) `
      bindparam.push(`%${this.search_model.search}%`)
    }
     sql += `ORDER BY checked DESC,name  LIMIT ${this.search_model.perpage} OFFSET ${offset_page}`

     let res = await alasql(sql, bindparam);
     
    //  this.productListSearch = this.productListSearch.concat(res.data)
    if (next) {
      this.list_staff_display = this.list_staff_display.concat(res)
    }else{
      this.list_staff_display = res;
    }
  

  }

  registerOnChange(fn: OnChangeType): void {
    this.onChange = fn
  }

  registerOnTouched(fn: OnTouchedType): void {
    this.onTouched = fn
  }

  onCheckState() {
    const selectVals:any = [];
    this.list_staff.forEach((c) => {
      if (c.checked) selectVals.push(c)
    })

    const vals = selectVals.map((x) => x.id)
    console.log('Object.keys(vals)  klaalkklawelkfawekl',vals)
    this.onChange(vals)
  }

  check(item){
    const m = this.list_staff.find((x) => x.id == item.id)
    if (m) {
      m.checked = item.checked
    }
    this.onCheckState()
  }



}