import { h, hydrate, Fragment } from 'preact'
import { IWarehouse } from '../types/IWarehouse'
import * as moment from 'moment'
import { qr_to_base64 } from '../func/qr_to_base64'
import { IWarehouseState } from '@store/ketshopweb-store'

export const warehouse_transfer_landscape_a4_html = {
  async render(data: {
    transfer: IWarehouse.WarehouseTransferDetail
    info: Partial<IWarehouseState.WarehouseState['info']>
  }) {
    const info = data.info
    const transfer = data.transfer
    
    transfer.details = transfer.details.filter((item) => item.product.product_type == 'normal')
    let title = ''
    title = transfer.type == 'transfer' ? 'พิมพ์ใบโอนสินค้าครึ่งใบ' : title
    title = transfer.type == 'receive' ? 'พิมพ์ใบรับสินค้าครึ่งใบ' : title
    title = transfer.type == 'withdraw' ? 'พิมพ์ใบเบิกสินค้าครึ่งใบ' : title
    title = transfer.type == 'adjust' ? 'พิมพ์ใบปรับจำนวนสินค้าครึ่งใบ' : title
    title = transfer.type == 'adjust' ? 'พิมพ์ใบปฏิเสธสินค้าครึ่งใบ' : title

    const transfer_code = await qr_to_base64(transfer.transfer_code, {
      scale: 0,
      width: 75,
      margin: 1,
    })
    const LandScapeA4 = () => (
      <div class="page-half">
        <div class="page-header">
          <div class="tf-company" style={{ visibility: info.status ? 'visible' : 'hidden' }}>
            <div class="tf-company-row">
              {info.logo ? (
                <div class="tf-company-logo">
                  <img src={`${location.origin}${info.logo}`} />
                </div>
              ) : null}
              {info.org_name ? (
                <div class="tf-company-info">
                  <div class="font-subtitle">{info.org_name}</div>
                  <div class="font-small">
                    {info.org_address || '-'} {info.org_phone ? <span>{info.org_phone}</span> : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div class="tf-document">
            <div class="tf-document-row">
              <div class="tf-document-info">
                <div class="font-title">
                  {/* <!-- ใบโอนสินค้า, ใบรับสินค้า, ใบเบิกสินค้า, ใบปรับจำนวนสินค้า --> */}
                  {transfer.type == 'transfer' ? 'ใบโอนสินค้า' : ''}
                  {transfer.type == 'receive' ? 'ใบรับสินค้า' : ''}
                  {transfer.type == 'withdraw' ? 'ใบเบิกสินค้า' : ''}
                  {transfer.type == 'adjust' ? 'ใบปรับจำนวนสินค้า' : ''}
                  {transfer.type == 'reject' ? 'ใบปฏิเสธสินค้า' : ''}
                </div>
                <div class="font-small">เลขที่เอกสาร : {transfer.transfer_code}</div>
                {transfer.transfer ? (<div class="font-small">เลขที่เอกสารอ้างอิง : {transfer.transfer.transfer_code}</div>): null}
                <div class="font-small">สร้างโดย : {transfer.created_by}</div>
                <div class="font-small">วันที่สร้าง : {moment(transfer.created_at).format('DD/MM/YYYY, HH:mm:ss')}</div>
              </div>
              <div class="tf-document-qrcode">
                <img src={transfer_code} alt={transfer.transfer_code} />
              </div>
              <div class="tf-document-warehouse">
                <div class="tf-document-warehouse">
                  {/* <!-- แสดงในกรณีที่เป็นการโอน --> */}
                  {/* <strong class="font-small">ต้นทาง : คลังออนไลน์, ปลายทาง : ลาดพร้าว</strong> */}
                  {/* <!-- แสดงในกรณีที่เป็นการรับเข้า, เบิกออก และปรับจำนวน --> */}
                  {/* <strong>คลัง : คลังออนไลน์</strong> */}
                  {transfer.type == IWarehouse.WarehouseTransfer.Transfer || (transfer.type == IWarehouse.WarehouseTransfer.Reject && transfer.warehouse.sender.name != transfer.warehouse.receiver.name) ? (
                    <strong class="font-small">
                      ต้นทาง : {transfer.warehouse.sender.name}
                      <br />
                      ปลายทาง : {transfer.warehouse.receiver.name}
                    </strong>
                  ) : (
                    <strong>คลัง : {transfer.warehouse.sender.name}</strong>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <table class="table-tf-product">
          <thead>
            <tr>
              <th width="60" class="text-center">
                ลำดับ
              </th>
              <th>สินค้า</th>
              <th width="80" class="text-right">
                จำนวน
              </th>
            </tr>
          </thead>
          <tbody>
            {transfer.details.map((item, index) => (
              <tr>
                <td class="text-center">{index + 1}</td>
                <td>
                  <div class="tf-product">
                    {/* <div class="tf-product-cover">
                      <img src={`${location.origin}${item.feature_img}`} />
                    </div> */}
                    <div class="tf-product-detail">
                      {item.prop_enable > 0 ? (
                        <div class="mb-1">
                          {/* ชื่อสินค้า (คุณสมบัติ1 - คุณสมบัติ2) */}
                          {item.name}{' '}
                          {item.prop_enable === 1
                            ? item.properties_desc
                            : item.properties_desc + ' - ' + item.properties_desc2}
                        </div>
                      ) : (
                        <div class="mb-1">
                          {/* ชื่อสินค้า*/}
                          {item.name}
                        </div>
                      )}
                      <div class="font-small">SKU : {item.sku}</div>
                      <div class="font-small">Barcode : {item.product && item.product.barcode ? item.product.barcode : '-'}</div>
                    </div>
                  </div>
                </td>
                <td class="text-right">{item.amount}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={2} class="text-right">
                <strong>รวมทั้งหมด</strong>
              </td>
              <td class="text-right">
                {/* <strong>xx</strong> */}
                <strong>{transfer.details.reduce((a, b) => a + b.amount, 0)}</strong>
              </td>
            </tr>
          </tfoot>
        </table>
        <div class="tf-note">
          <strong>หมายเหตุ : </strong> {transfer.note || '-'}
        </div>
        <div class="tf-signature">
          <div class="tf-signature-row">
            <div class="tf-signature-column">
              <hr class="line-dotted" />
              <div>ผู้ส่ง</div>
            </div>
            <div class="tf-signature-column">
              <hr class="line-dotted" />
              <div>ผู้รับ</div>
            </div>
          </div>
        </div>
      </div>
    )
    const Main = (
      <Fragment>
        <div class="page-a4">
          <LandScapeA4 />
          <LandScapeA4 />
        </div>
      </Fragment>
    )
    const ctx = document.createElement('body')
    hydrate([Main, warehouse_transfer_landscape_a4_css], ctx)
    // <meta name="viewport" content="width=device-width, initial-scale=1.0">
    return `<!DOCTYPE html>
        <html>
            <head>
              <meta charset="UTF-8">
              <title>${title}</title>
            </head>
            ${ctx.outerHTML}
        </html>
        `
  },
}

const warehouse_transfer_landscape_a4_css = (
  <style>
    {`*{
      padding: 0;
      margin: 0;
      box-sizing: border-box;
    }
    html,
    body{
      font-family: 'Arial', 'helvetica', sans-serif;
      height: 100%;
    }
    .page-a4{
      width: 297mm;
      height: 100%;
      margin: 24px auto;
      page-break-after: always;
      font-size: 8pt;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .page-half{
        height: 100%;
        padding: 0 8px;
    }
    .page-half:first-child{
        border-right: 1px dashed #000;
    }
    .page-header{
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      line-height: 1.25;
      margin-bottom: 8px;
    }
    .tf-company{
      -webkit-box-flex: 0;
      -ms-flex: 0 0 55%;
      flex: 0 0 55%;
      max-width: 55%;
      padding-right: 8px;
    }
    .tf-document{
      -webkit-box-flex: 0;
      -ms-flex: 0 0 45%;
      flex: 0 0 45%;
      max-width: 45%;
      padding: 4px;
      border: 1px solid #000;
    }
    .tf-company-row{
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .tf-company-logo{
      -webkit-box-flex: 0;
      -ms-flex: 0 0 120px;
      flex: 0 0 120px;
      max-width: 120px;
      aspect-ratio: 25/7;
      margin-bottom: 4px;
    }
    .tf-company-info{
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .tf-company-logo img{
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: left;
    }
    .tf-document-row{
      display: grid;
      grid-template-columns: 1fr 48px;
      grid-column-gap: 4px;
      grid-row-gap: 2px;
    }
    .tf-document-qrcode img{
      display: block;
      width: 100%;
    }
    .tf-document-warehouse{
      grid-column-start: 1;
      grid-column-end: 3;
    }
    .table-tf-product{
      width: 100%;
      border-collapse: collapse;
      border: 1px solid #000;
    }
    .table-tf-product thead tr,
    .table-tf-product tfoot tr{
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;
    }
    .table-tf-product tr th,
    .table-tf-product tr td{
      padding: 4px 8px;
      text-align: left;
    }
    .tf-product{
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 8px;
      align-items: center;
    }
    .tf-note{
      margin-top: 8px;
    }
    .tf-signature{
      margin-top: 8px;
    }
    .tf-signature-row{
      display: grid;
      grid-template-columns: 1fr 1fr;
      text-align: center;
    }
    .line-dotted{
      width: 80%;
      border-top: 0;
      border-bottom: 1px dotted #000;
      margin: 40px auto 4px auto;
    }

    .font-title{
      font-size: 11pt;
      font-weight: bold;
    }
    .font-subtitle{
      font-size: 9pt;
      font-weight: bold;
    }
    .font-small{
      font-size: 8pt;
    }
    .text-left{
      text-align: left!important;
    }
    .text-center{
      text-align: center!important;
    }
    .text-right{
      text-align: right!important;
    }
    .mb-1{
        margin-bottom: 2px;
    }
    @media print{
      @page {
        size: A4 landscape;
      }
      .page-a4{
        width: 100%;
        margin: initial;
      }
    }`}
  </style>
)
