import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetModalForgotPasswordComponent } from './modal-forgot-password.component'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { KetTranslateModule } from '@ketshopweb/ui/translate'
import { FormsModule } from '@angular/forms'
import { NzFormModule } from 'ng-zorro-antd/form'

@NgModule({
  declarations: [KetModalForgotPasswordComponent],
  entryComponents: [KetModalForgotPasswordComponent],
  imports: [CommonModule, NzInputModule, NzIconModule, NzButtonModule, KetTranslateModule, FormsModule, NzFormModule],
  exports: [KetModalForgotPasswordComponent],
})
export class KetModalForgotPasswordModule {}
