import { Component, OnInit, Input } from '@angular/core'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzMessageService } from 'ng-zorro-antd/message'
import { UtilService } from '@cms/services/util.service'
import { cloneDeep } from 'lodash'

@Component({
  selector: 'app-modal-edit-lang-message',
  templateUrl: './modal-edit-lang-message.component.html',
  styleUrls: ['./modal-edit-lang-message.component.css'],
})
export class ModalEditLangMessageComponent implements OnInit {
  @Input() data: any
  message_duration = 4000
  model: any = {}
  constructor(
    private modal: NzModalRef,
    private message: NzMessageService,
    private Service_Util: UtilService,
  ) {}

  ngOnInit() {
    this.model = cloneDeep(this.data.message)
    console.log(this.data)
  }

  async ok() {
    for (let i = 0; i < this.data.current_uselang.length; i++) {
      const current = this.data.current_uselang[i]
      if (this.model[current.ref] == '') {
        this.message.error(`Please Fill Message (${current.name})`)
        return false
      }
    }
    try {
      await this.Service_Util.saveLangFormat(this.model)
      this.modal.close(this.model)
    } catch (e) {
      this.message.error(e.error.message, { nzDuration: this.message_duration })
      return false
    }
  }
}
