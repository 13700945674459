import { cloneDeep } from 'lodash'
import { ActionTypes, All } from './action'
import { CURRENT_PAGE, CURRENT_USER, LANGS } from '../interface/interface'
import { ITrigger } from '@cms/types'

export interface AppState {
  CURRENT_PAGE: CURRENT_PAGE
  CURRENT_USER: CURRENT_USER
  LANGS: LANGS[]
  PROVINCE: any[]
  PAGE_LINK: CURRENT_PAGE[]
  CURRENT_USER_CLIENT: any
  CART_STORE: any
  LANG_FORMAT: any
  SALE_PAGE_SELECT_PRODUCT: any
  COUNT_MESSENGER: number
  PRODUCT_STORE: any
  TRIGGER: ITrigger
}

const InitialState: AppState = {
  CURRENT_PAGE: undefined,
  CURRENT_USER: {
    facebook_id: null,
    google_id: null,
    line_id: null,
    cms_version: null,
    create_by: '',
    create_date: '',
    customer_id: null,
    email: '',
    id: null,
    lastname: '',
    name: '',
    picture: '',
    status_use: '',
    tel: '',
    update_by: null,
    update_date: '',
    user_role: '',
    usr_name: '',
    is_mobile: true,
    all_warehouse: false,
  },
  LANGS: [],
  PROVINCE: [],
  PAGE_LINK: [],
  CURRENT_USER_CLIENT: undefined,
  CART_STORE: {
    details: [],
  },
  LANG_FORMAT: [],
  SALE_PAGE_SELECT_PRODUCT: {},
  COUNT_MESSENGER: 0,
  PRODUCT_STORE: {
    data: [],
  },
  TRIGGER: {
    type: '',
    ref: '',
  },
}

export function AppStore(state = InitialState, action: All): AppState {
  const new_state = cloneDeep(state)
  switch (action.type) {
    case ActionTypes.SET_TRIGGER:
      return {
        ...state,
        TRIGGER: {
          ...action.playload,
        },
      }
    case ActionTypes.SET_CURRENT_PAGE:
      return {
        ...state,
        CURRENT_PAGE: action.playload,
      }
    case ActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        CURRENT_USER: action.playload,
      }
    case ActionTypes.SET_LANGS:
      return {
        ...state,
        LANGS: action.playload,
      }
    case ActionTypes.SET_PROVINCE:
      return {
        ...state,
        PROVINCE: action.playload,
      }
    case ActionTypes.SET_PAGE_LINK:
      return {
        ...state,
        PAGE_LINK: action.playload,
      }
    case ActionTypes.SET_CURRENT_USER_CLIENT:
      return {
        ...state,
        CURRENT_USER_CLIENT: action.playload,
      }

    case ActionTypes.SET_LANG_FORMAT:
      new_state.LANG_FORMAT = action.playload
      return {
        ...new_state,
      }
    case ActionTypes.SALE_PAGE_SELECT_PRODUCT:
      new_state.SALE_PAGE_SELECT_PRODUCT = action.playload
      return {
        ...new_state,
      }
    case ActionTypes.SET_COUNT_MESSENGER:
      new_state.COUNT_MESSENGER = action.playload
      return {
        ...new_state,
      }
    case ActionTypes.UPDATE_PRODUCT_STORE:
      new_state.PRODUCT_STORE = { ...new_state.PRODUCT_STORE, ...action.playload }
      return {
        ...new_state,
      }
    default:
      return {
        ...state,
      }
  }
}
