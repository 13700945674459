import { Observable, Subscription } from 'rxjs'
import { cloneDeep, merge } from 'lodash'
import { inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { delay, map, mergeMap } from 'rxjs/operators'
import { actions_orders } from './actions'
import { clear_order_search_model, default_order_search_model } from './reducer'
import { IOrdersState } from './models'
import { Store } from '@ngrx/store'
import { KetshopwebServices } from 'ketshopweb-services'

@Injectable()
export class OrdersEffects {
  private actions$ = inject(Actions)
  private ketshopwebOrdersService = inject(KetshopwebServices.OrdersService)
  private store = inject(Store)

  private subscription_search: {
    [key: string]: Subscription
  } = {}

  $searchOrders = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions_orders.SearchOrders),
        map((action) => {
          const model = cloneDeep(action.input) as IOrdersState.SearchModel
          if (this.subscription_search[action.from]) {
            this.subscription_search[action.from].unsubscribe()
            this.store.dispatch(
              actions_orders.SetLoading({
                val: false,
              }),
            )
          }
          this.store.dispatch(
            actions_orders.SetSearchModel({
              from: action.from,
              input: cloneDeep(model),
            }),
          )
          this.subscription_search[action.from] = new Observable((ob) => {
            this.store.dispatch(
              actions_orders.SetLoading({
                val: true,
              }),
            )
            ob.next()
          })
            .pipe(
              delay(300),
              mergeMap(() => this.ketshopwebOrdersService.simpleSearch(clear_order_search_model(model))),
            )
            .subscribe({
              next: (value) => {
                // console.log('OrdersEffects',action.type, value)
                this.store.dispatch(
                  actions_orders.SetOrders({
                    from: action.from,
                    data: {
                      data: value.data,
                      count: value.count,
                    },
                  }),
                )
                this.store.dispatch(
                  actions_orders.SetLoading({
                    val: false,
                  }),
                )
              },
              complete() {
                console.log('complete')
                this.store.dispatch(
                  actions_orders.SetLoading({
                    val: false,
                  }),
                )
              },
              error(err) {
                this.store.dispatch(
                  actions_orders.SetLoading({
                    val: false,
                  }),
                )
                console.log('error', err)
              },
            })
        }),
      ),
    { dispatch: false },
  )

  $LoadStaff = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions_orders.LoadStaff),
        map((action) => {
          this.ketshopwebOrdersService.createdby().subscribe({
            next: (value) => {
              this.store.dispatch(
                actions_orders.SetStaff({
                  data: cloneDeep(value),
                }),
              )
            },
            error(err) {},
          })
        }),
      ),
    { dispatch: false },
  )
}
