import { Injectable } from '@angular/core'
@Injectable({
  providedIn: 'root',
})
export class KetHasDataDomService {
  constructor() {}

  random_string(amount) {
    let text = ''
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    for (let i = 0; i < amount; i++) text += possible.charAt(Math.floor(Math.random() * possible.length))

    return text
  }

  uid() {
    const date: any = new Date()
    let year: any = date.getUTCFullYear() - 2000
    let mon: any = date.getUTCMonth() + 1
    let day: any = date.getUTCDate()
    let hour: any = date.getUTCHours()
    let min: any = date.getUTCMinutes()
    let sec: any = date.getUTCSeconds()
    let milisec: any = date.getMilliseconds()
    const random = this.random_string(6)
    if (milisec < 10) {
      milisec = '0' + milisec.toString()
    }
    if (sec < 10) {
      sec = '0' + sec.toString()
    }
    if (min < 10) {
      min = '0' + min.toString()
    }
    if (hour < 10) {
      hour = '0' + hour.toString()
    }
    if (day < 10) {
      day = '0' + day.toString()
    }
    if (mon < 10) {
      mon = '0' + mon.toString()
    }
    if (year < 10) {
      year = '0' + year.toString()
    }
    const numStr: any =
      year.toString() +
      mon.toString() +
      day.toString() +
      hour.toString() +
      min.toString() +
      sec.toString() +
      milisec.toString()
    const num: any = parseInt(numStr)
    let stkNum: any = num.toString(36)
    stkNum += random
    return stkNum
  }
}
