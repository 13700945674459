import {
  ElementRef,
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from '@angular/core'

@Component({
  selector: 'filemanager',
  templateUrl: './filemanager.component.html',
  styleUrls: ['./filemanager.component.less'],
})
export class KetFilemanagerComponent implements OnInit, OnChanges {
  @ViewChild('media', {
    static: true,
  })
  media: ElementRef
  @Input() ID: any
  @Input() _template: any
  @Input() json = false
  @Input() multiple = 0
  @Input() checkAll = 0
  @Input() type = 'image'
  _type = 1
  @Output() image = new EventEmitter()
  _akey: any
  constructor(private el: ElementRef) {
    // console.log('CRONSTRUCTOR mediamanager')
  }

  async ngOnInit() {
    let __akey = null
    try {
      const _token = JSON.parse(localStorage.getItem('access_token'))
      __akey = _token.access_token
    } catch (e) {}
    this._akey = __akey
    if (this.type == 'all') {
      this._type = 0
    } else if (this.type == 'image') {
      this._type = 1
    } else {
      this._type = 0
    }
    console.log(this._type, this.type)
  }

  select_image(e: any) {
    console.log(e)
    if (this.json || this.multiple == 1) {
      this.image.emit(e)
    } else {
      this.image.emit(e.path)
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ID) {
      if (changes.ID.previousValue !== undefined) {
        this.ID = changes.ID.currentValue
      }
    }
  }
}
