import { Directive, AfterViewInit, Input, ElementRef } from '@angular/core'

@Directive({
  selector: '[image-size]',
})
export class ImageSizeDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  @Input('image-size') data: {
    width: string
    height: string
  }

  ngAfterViewInit() {
    //this.render.setElementStyle(this.el.nativeElement, 'font-family', this.font)
    setTimeout(() => {
      try {
        let size = '800x800'
        const item = this.data
        const nativeElement = this.el.nativeElement as HTMLLinkElement
        if (Number(item.width) > 0 && Number(item.height) > 0) {
          size = `${item.width}x${item.height}`
        } else {
          const image = nativeElement.children.item(0) as HTMLImageElement
          if (image) {
            size = `${image.naturalWidth}x${image.naturalHeight}`
          }
        }
        // set attr.data-size
        nativeElement.setAttribute('data-size', size)
      } catch (error) {}
    }, 1000)
  }
}
