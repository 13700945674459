import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetSettingGbprimepayComponent } from './setting-gbprimepay.component'
import { NzSwitchModule } from 'ng-zorro-antd/switch'
import { FormsModule } from '@angular/forms'
import { NzTagModule } from 'ng-zorro-antd/tag'
import { NzAvatarModule } from 'ng-zorro-antd/avatar'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzMessageModule } from 'ng-zorro-antd/message'
import { NzDrawerModule } from 'ng-zorro-antd/drawer'
import { KetModalGbpayComponent } from './modal-gbpay.component'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { KetFeatureLandingModule } from '../feature-landing'
import { NzModalModule } from 'ng-zorro-antd/modal'
import { KetModalMessageModule } from '../modal-message'
import { NzInputNumberModule } from 'ng-zorro-antd/input-number'
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox'
import { NzTableModule } from 'ng-zorro-antd/table'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { NzPopoverModule } from 'ng-zorro-antd/popover'
import { KetModalGbpayTestModule } from '../modal-gbpay-test'
import { KetModalPaymentTestModule } from '../modal-payment-test'

@NgModule({
  declarations: [KetSettingGbprimepayComponent, KetModalGbpayComponent],
  entryComponents: [KetModalGbpayComponent],
  imports: [
    CommonModule,
    NzSwitchModule,
    FormsModule,
    NzTagModule,
    NzAvatarModule,
    NzButtonModule,
    NzMessageModule,
    NzDrawerModule,
    NzInputModule,
    NzToolTipModule,
    NzIconModule,
    KetFeatureLandingModule,
    NzModalModule,
    KetModalMessageModule,
    NzInputModule,
    NzInputNumberModule,
    NzCheckboxModule,
    NzTableModule,
    NzSelectModule,
    NzPopoverModule,
    KetModalPaymentTestModule,
  ],
  exports: [KetSettingGbprimepayComponent],
})
export class KetSettingGbprimepayModule {}
