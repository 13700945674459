import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root',
})
export class BlogsService {
  constructor(private _http: HttpClient) {}

  searchBlog(data: any) {
    return this._http.post('/blog/search', data).toPromise()
  }

  searchBlogFront(data: any) {
    // return this._http.post('/blog/searchFront', data).toPromise()
    return this._http.post(`/services/ketshopweb-frontend/blog/searchBlogFront`, data).toPromise()
  }

  getBlogRelatedsFront(permalink: string, data?: { limit: number }) {
    const params = {}

    if (data) {
      for (const key in data) {
        if (data[key]) {
          params[key] = data[key]
        }
      }
    }
    return this._http.get<any>(`/services/ketshopweb-frontend/blog/${permalink}/blog_relateds_front`, { params })
  }

  saveBlogRelateds(
    blog_id: number,
    data: {
      blog_related_ids: number[]
      related_blog_setting: string
      related_title_lang1: string
      related_title_lang2: string
      related_title_lang3: string
      related_title_lang4: string
    },
  ) {
    return this._http.post(`/services/ketshopweb-frontend/blog/${blog_id}/blog_relateds`, data)
  }

  getBlogRelateds(id: number, data?: { limit: number }) {
    const params = {}

    if (data) {
      for (const key in data) {
        if (data[key]) {
          params[key] = data[key]
        }
      }
    }
    return this._http.get(`/services/ketshopweb-frontend/blog/${id}/blog_relateds`, { params })
  }

  listBlogTag() {
    return this._http.get('/blog/listBlogTag').toPromise()
  }

  BlogCategory() {
    return this._http.get('/blog/category').toPromise()
  }
  BlogDetail(obj) {
    return this._http.post('/blog/blogDetail', obj).toPromise()
  }
  allCategory() {
    return this._http.get('/blog/allCategory').toPromise()
  }
  updateCategory(obj) {
    return this._http.put('/blog/category/' + obj.id, obj).toPromise()
  }
  updateCategorySort(obj) {
    return this._http.post('/blog/categorySort', obj).toPromise()
  }

  createCategory(obj) {
    return this._http.post('/blog/category', obj).toPromise()
  }

  deleteCategory(id) {
    return this._http.delete('/blog/category/' + id).toPromise()
  }

  blogById(id: number) {
    return this._http.get(`/blog/${id}`).toPromise()
  }

  createBlog(obj) {
    return this._http.post(`/blog`, obj).toPromise()
  }

  updateBlog(obj) {
    return this._http.put(`/blog/${obj.post_id}`, obj).toPromise()
  }

  deleteBlog(id) {
    return this._http.delete(`/blog/${id}`).toPromise()
  }

  getCategoryIDS(obj: any) {
    return this._http.post('/blog/searchCategoryByID', obj).toPromise()
  }

  getKetshopNews() {
    return this._http.get(`/blog/ketshopweb_new`).toPromise()
  }

  getKetshopFeature() {
    return this._http.get(`/blog/ketshopweb_feature`).toPromise()
  }

  getKetshopRecommend() {
    return this._http.get(`/blog/ketshopweb_recommend`).toPromise()
  }

  searchBlogIndex(data: any) {
    return this._http.post('/blog/searchBlogIndex', data).toPromise()
  }

  getBlogFront() {
    return this._http.get(`/services/ketshopweb-frontend/blog/allBlogBySort`).toPromise()
  }

  searchBlogststem(data: any) {
    // return this._http.post('/blog/searchFront', data).toPromise()
    return this._http.post(`/services/ketshopweb-frontend/blog/searchBlogststem`, data).toPromise()
  }

  updateBlog_v2(obj) {
    return this._http.put(`/services/ketshopweb-frontend/blog/updateBlog`, { data: obj }).toPromise()
  }

  category(data?: { page_post_id?: number; page_post_permalink?: string; lang?: string }) {
    const params = {}

    if (data) {
      for (const key in data) {
        if (data[key] !== undefined) {
          params[key] = data[key]
        }
      }
    }
    return this._http
      .get(`/services/ketshopweb-frontend/blog/category`, {
        params,
      })
      .toPromise()
  }
}
