import { Component, OnInit, Input, HostListener, AfterViewInit, ChangeDetectorRef } from '@angular/core'
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer'
import { OrderService } from '@cms/services/order.service'
import { Etc } from '@cms/util/etc'
import { NzModalService } from 'ng-zorro-antd/modal'
import { UtilService } from '@cms/services/util.service'
import { ModalExportOrderComponent } from '@cms/system/modal/modal-export-order/modal-export-order.component'
import { PermissionDirective } from '@cms/dirctives/permission.directive'
import { FeatureService } from '@cms/services/feature.service'
import { ExtensionService } from '@cms/services/extension.service'
import { apiError } from '@cms/interface/interface'
import { NzNotificationService } from 'ng-zorro-antd/notification'

import * as moment from 'moment'
import { NzMessageService } from 'ng-zorro-antd/message'
import { ModalErrorComponent } from '@cms/system/modal/modal-error/modal-error.component'

declare let navigator: any

@Component({
  selector: 'app-order-zort',
  templateUrl: './order-zort.component.html',
  styleUrls: ['./order-zort.component.css'],
})
export class OrderZortComponent implements OnInit, AfterViewInit {
  _permission: PermissionDirective = new PermissionDirective()

  // @Input() from?: any = ""

  constructor(
    private drawerRef: NzDrawerRef<string>,
    private drawerService: NzDrawerService,
    private Service_Util: UtilService,
    private Service_Order: OrderService,
    private modal: NzModalService,
    private _etc: Etc,
    private chRef: ChangeDetectorRef,
    private Service_Feature: FeatureService,
    private _extenion: ExtensionService,
    private message: NzMessageService,
    private _noti: NzNotificationService,
  ) {}
  feature = false
  heightTable = 550
  modelsearch: any = {
    status: '',
    send_status: -1,
    method: '',
    ordercode: '',
    email: '',
    trackcode: '',
    search: '',
    paymenttype: '',
    created_by: '',
    page: 1,
    perpage: 10,
  }
  modelsearch_success: any = {
    status: '',
    send_status: 1,
    method: '',
    ordercode: '',
    email: '',
    trackcode: '',
    search: '',
    paymenttype: '',
    created_by: '',
    page: 1,
    perpage: 10,
  }
  shippopimg: any = {}
  statusimg: any
  paymentimg: any
  order: any = []
  order_success: any = []
  loading = false
  loading_success = false
  total = 0
  total_success = 0
  first_load_error = true
  first_load_success = true
  all_error = 0
  all_success = 0

  action: any = [
    { value: 'ADDORDER', name: 'สร้างออเดอร์' },
    { value: 'EDITORDERINFO', name: 'อัพเดทรายละเอียดการจัดส่ง' },
    { value: 'UPDATEKETORDERPAYMENT', name: 'ชำระครบ' },
    { value: 'UPDATEKETORDERSTATUS', name: 'โอนสินค้า' },
    { value: 'VOIDORDER', name: 'ยกเลิกออเดอร์' },
  ]

  async ngOnInit() {
    this.feature = await this.Service_Feature.getFeature('ZORT')
    this.statusimg = this._etc.orderstatus_img()
    this.paymentimg = this._etc.payment_img()
    this.search()
    this.search_success()
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.heightTable = this.tableHeight(window.innerHeight)
    }, 150)
  }

  close() {
    this.drawerRef.close()
  }

  tableHeight(height: number) {
    // if (this.peak_account) {
    //   return (height - 210)
    // } else {
    height = height + 80
    return height - 346
    // }
  }

  async checkInuseActivity(obj) {
    try {
      // console.log(obj);
      const rs = await this.Service_Util.inuseActivity({ type: 'order', param_id: obj.id })
      return rs
    } catch (err) {
      console.error(err)
    }
  }

  async viewOrder(obj: any) {
    console.log(obj)
    if (!this._permission.checkPermission(['administrator', 'webmaster', 'staff', 'sale', 'store'])) {
      this.modal.error({
        nzTitle: 'Error',
        nzContent: `Permission denied`,
      })
      return
    }
    const inuse: any = await this.checkInuseActivity(obj)
    if (!inuse.available) {
      this.modal.error({
        nzTitle: 'Error',
        nzContent: `${inuse.user} กำลังทำการแก้ไขรายการนี้อยู่`,
      })
      return
    }

    const url = `${window.location.origin}/system/e-commerce/order-detail/${obj.ordercode}`
    window.open(url, '_blank', 'menubar=no,toolbar=no,location=no,width=1280,height=720')
  }

  pagechage(page) {
    this.modelsearch.page = page
    this.search()
  }

  pagechage_success(page) {
    this.modelsearch_success.page = page
    this.search_success()
  }

  search_success(clearpage = false) {
    if (clearpage) {
      this.modelsearch_success.page = 1
    }
    this.loading_success = true
    this.Service_Order.searchZortLog(this.modelsearch_success)
      .then((res: any) => {
        console.log(res)
        res.data.forEach((o) => {
          const _action = this.action.find((x) => x.value == o.method)
          if (_action) {
            o.action = _action.name
          } else {
            o.action = 'unknown'
          }
        })
        console.log(res.data)
        this.order_success = res.data
        this.total_success = res.total
        if (this.first_load_success) {
          this.all_success = res.total
          this.first_load_success = false
        }
        this.chRef.detectChanges()
      })
      .catch((data) => {
        console.log(data)
        console.log(data.error.message)
      })
      .finally(() => {
        this.loading_success = false
        this.chRef.detectChanges()
      })
  }

  search(clearpage = false) {
    if (clearpage) {
      this.modelsearch.page = 1
    }
    this.loading = true
    this.Service_Order.searchZortLog(this.modelsearch)
      .then((res: any) => {
        console.log(res)
        res.data.forEach((o) => {
          o.hide_error = false
          if (`${o.error_message}`.length > 100) {
            o.hide_error = true
          }
          const _action = this.action.find((x) => x.value == o.method)
          if (_action) {
            o.action = _action.name
          } else {
            o.action = 'unknown'
          }
        })
        console.log(res.data)
        this.order = res.data
        this.total = res.total
        if (this.first_load_error) {
          this.all_error = res.total
          this.first_load_error = false
        }
        this.chRef.detectChanges()
      })
      .catch((data) => {
        console.log(data)
        console.log(data.error.message)
      })
      .finally(() => {
        this.loading = false
        this.chRef.detectChanges()
      })
  }

  resend(obj) {
    console.log(obj)
    $('.theme-loader').show()
    this.Service_Order.resendZort(obj)
      .then((res: any) => {
        $('.theme-loader').hide()
        if (res.send_status == -1) {
          // error
          // this.modal.error({
          //   nzTitle: 'Response Zort Status',
          //   nzContent: res.error_message
          // });
          const modal = this.modal
            .create({
              nzTitle: 'Response Zort Status',
              nzContent: ModalErrorComponent,
              nzStyle: { top: '20px' },
              nzBodyStyle: { padding: '10px' },
              nzWidth: '40%',
              nzKeyboard: false,
              nzFooter: null,
              nzMaskClosable: false,
              nzOnOk: (componentInstance) => {
                return componentInstance.ok()
              },
              nzOnCancel: (componentInstance) => {},
              nzComponentParams: {
                data: {
                  res: res,
                },
              },
            })
            .afterClose.subscribe((result) => {
              modal.unsubscribe()
              console.log(result)
              if (result) {
                // this.searchCustomer("");
              }
            })
        } else if (res.send_status == 1) {
          // complete
          this.modal.success({
            nzTitle: 'Response Zort Status',
            nzContent: `ส่งสำเร็จ`,
          })
        }
        console.log(res)
        this.search()
        this.search_success()
      })
      .catch((data) => {
        $('.theme-loader').hide()
        console.log(data)
      })
  }
}
