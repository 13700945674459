import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetOwlCarouselDirective } from './owl-carousel.directive'

@NgModule({
  declarations: [KetOwlCarouselDirective],
  imports: [CommonModule],
  exports: [KetOwlCarouselDirective],
})
export class KetOwlCarouselModule {}
