import { Component, OnInit } from '@angular/core'
import { FeatureService } from '@cms/services/feature.service'
import { ExtensionService } from '@cms/services/extension.service'
import { NzModalService } from 'ng-zorro-antd/modal'
// import { ModalCreateShopeeProductComponent } from '../modal/modal-create-shopee-product/modal-shopee-product';
// import { ModalEditShopeeProductComponent } from '../modal/modal-edit-shopee-product/modal-edit-shopee-product.component';
import { PermissionDirective } from '@cms/dirctives/permission.directive'
import { ShopeeService } from '@cms/services/shopee.service'

@Component({
  selector: 'app-shopee',
  templateUrl: './shopee.component.html',
  styleUrls: ['./shopee.component.css'],
})
export class ShopeeComponent implements OnInit {
  _permission: PermissionDirective = new PermissionDirective()
  feature = false
  product_complete: any = []
  total_product_complete = 0
  select_tap = 1
  loading = false
  model_search_product_complete = {
    startdate: '',
    enddate: '',
    status: null,
    search: '',
    complete: 1,
    page: 1,
    perpage: 10,
    orderby: 'id',
    sort: 'desc',
  }
  shopee_shop: any = []

  statusname: any = {
    NORMAL: 'ปกติ',
    UNLIST: 'ไม่แสดง',
    BANNED: 'ถูกระงับ',
  }
  model_search_product: any = {}
  data_search_product: any = {}
  haveShop = false
  constructor(
    private Service_Feature: FeatureService,
    private Service_Shopee: ShopeeService,
    private Service_Extension: ExtensionService,
    private modal: NzModalService,
  ) {}

  /* ไม่ใช้แล้ว */

  async ngOnInit() {
    this.feature = await this.Service_Feature.getFeature('SHOPEE')
    if (!this.feature) {
      return
    }
    this.shopee_shop = await this.Service_Shopee.listShop()
    if (this.shopee_shop.length) {
      this.haveShop = true
      this.shopee_shop.forEach((o) => {
        this.model_search_product['shop_' + o.shop_id] = {
          shop_id: o.shop_id,
          page: 1,
          perpage: 10,
          search: '',
        }
        this.data_search_product['shop_' + o.shop_id] = {
          data: [],
          total: 0,
        }
        this.searchProduct(o.shop_id)
      })
    }

    // this.drawerRef.nzTitle = "kkkkk";
    // console.log();
  }

  pagechageProduct(page, shop_id) {
    this.model_search_product['shop_' + shop_id].page = page
    this.searchProduct(shop_id)
  }

  changeStatus(item, unlist) {
    // let obj = {
    //     shop_id: item.shop_id,
    //     item_id: item.item_id,
    //     unlist: unlist
    // }
    // $('.theme-loader').fadeIn();
    // this.Service_Extension.unListItem(obj).then((data: any) => {
    //     $('.theme-loader').hide();
    //     this.searchProduct(item.shop_id);
    // }).catch((data) => {
    //     $('.theme-loader').hide();
    //     this.modal.error(data.error.message);
    // });
  }

  searchProduct(shop_id) {
    this.Service_Extension.searchShopeeProduct(this.model_search_product['shop_' + shop_id])
      .then((res: any) => {
        console.log(res)

        res.data.forEach((element) => {
          element.img = null
          if (element.images && element.images.image_id_list) {
            element.img = `https://cf.shopee.co.th/file/${element.images.image_id_list[0]}`
          }
          element.expand = false
        })
        this.data_search_product['shop_' + shop_id].data = res.data
        this.data_search_product['shop_' + shop_id].total = res.count
      })
      .catch((data) => {
        console.log(data)
      })
  }

  // changeStatus(item,unlist){
  //     let obj = {
  //         item_id: item.item_id,
  //         unlist: unlist
  //     }
  //     $('.theme-loader').fadeIn();
  //     this.Service_Extension.unListItem(obj).then((data:any)=>{
  //         $('.theme-loader').hide();
  //         this.searchProductCompelte();
  //     }).catch((data)=>{
  //         $('.theme-loader').hide();
  //         this.modal.error(data.error.message);
  //     });
  // }

  syncProductShopee(shop_id) {
    return
    // $('.theme-loader').fadeIn();
    // this.Service_Extension.syncProductShopee().then((res) => {
    //     $('.theme-loader').hide();
    //     this.modal.success({
    //         nzTitle: 'ดำเนินการดึงสินค้า จาก Shopee',
    //         nzContent: `ขณะนี้ระบบกำลังดำเนินการดึงสินค้า จาก Shopee
    //         เมื่อดำเนินการเสร็จสิ้น ระบบจะแจ้งผลการเชื่อมต่อสินค้า ไปยังอีเมล
    //         เจ้าของเว็บไซต์ (Admin)`
    //     });
    // }).catch((data) => {
    //     $('.theme-loader').hide();
    //     console.log(data);
    // })
  }

  openModalAddProduct(shop_id) {
    // const modal = this.modal.create({
    //     nzTitle: null,
    //     nzContent: ModalCreateShopeeProductComponent,
    //     // nzStyle: { top: '20px' },
    //     nzBodyStyle: {
    //         'padding': '0px',
    //         'background': '#f6f6f6'
    //     },
    //     nzClassName: 'modal-medium',
    //     nzWrapClassName: 'vertical-center-modal',
    //     nzWidth: "90%",
    //     nzKeyboard: false,
    //     nzMaskClosable: false,
    //     nzFooter: null,
    //     nzOnOk: (componentInstance:any) => {
    //         return componentInstance.ok();
    //     },
    //     nzOnCancel: (componentInstance) => {
    //     },
    //     nzComponentParams: {
    //         data: {
    //             shop_id: shop_id
    //         }
    //     }
    // }).afterClose.subscribe((result) => {
    //     modal.unsubscribe()
    //     console.log(result);
    //     if (result) {
    //         this.modal.success({
    //             nzTitle: 'Complete',
    //             nzContent: ''
    //         });
    //         // this.searchProductCompelte();
    //     }
    // });
  }

  editProduct(obj) {
    // console.log("open edit");
    // const modal = this.modal.create({
    //     nzTitle: null,
    //     nzContent: ModalEditShopeeProductComponent,
    //     nzBodyStyle: {
    //         'padding': '0px',
    //         'background': '#f6f6f6'
    //     },
    //     nzWidth: "90%",
    //     nzWrapClassName: 'vertical-center-modal',
    //     nzClassName: 'modal-medium',
    //     nzKeyboard: false,
    //     nzMaskClosable: false,
    //     nzFooter: null,
    //     nzOnOk: (componentInstance:any) => {
    //         return componentInstance.ok();
    //     },
    //     nzOnCancel: (componentInstance) => {
    //     },
    //     nzComponentParams: {
    //         data: {
    //             item: obj
    //         }
    //     }
    // }).afterClose.subscribe((result) => {
    //     modal.unsubscribe()
    //     // this.searchProductCompelte();
    // });
  }

  // deleteProduct(item) {
  //     // console.log(item);
  //     this.modal.confirm({
  //         nzTitle: `Are you sure to delete item ${item.name} ?`,
  //         nzContent: '',
  //         nzOkText: 'Yes',
  //         nzOkType: 'danger',
  //         nzOnOk: () => {
  //             $('.theme-loader').fadeIn();
  //             this.Service_Extension.deleteProductShopee(item).then((res) => {
  //                 console.log(res);
  //                 $('.theme-loader').hide();
  //                 this.modal.success({
  //                     nzTitle: 'Delete item success',
  //                     nzContent: ''
  //                 });

  //                 this.searchProductCompelte();
  //             }).catch((data) => {
  //                 $('.theme-loader').hide();
  //                 console.log(data.error.message);
  //                 this.modal.error({
  //                     nzTitle: 'Error',
  //                     nzContent: data.error.message,
  //                     nzOnOk: () => {

  //                     }
  //                 });
  //             });
  //         },
  //         nzCancelText: 'No',
  //         nzOnCancel: () => { }
  //     });

  // }
}
