import { Injectable, NgZone, Inject } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { DOCUMENT } from '@angular/common'

declare const Zone: any

export function dropEndingSlash(str: string) {
  return str.endsWith('/') ? str.slice(0, -1) : str
}

interface LocalState {
  idle: boolean
  timeOut: number
}

@Injectable({
  providedIn: 'root',
})
export class NgCoreService {
  private initialUrl = dropEndingSlash(window && window.location && window.location.pathname) || ''
  private imState = new BehaviorSubject<LocalState>({
    idle: false,
    timeOut: 5 * 1000, // 5 seconds timeout as default
  })
  private initApp = new Event('AngularInitialized', {
    bubbles: true,
    cancelable: false,
  })
  private appReady = new Event('AngularReady', {
    bubbles: true,
    cancelable: false,
  })
  private appTimeout = new Event('AngularTimeout', {
    bubbles: true,
    cancelable: false,
  })

  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private zone: NgZone,
  ) {}

  private async simpleTimeout() {
    /** zone not available, use a timeout instead. */
    console.warn('Scully is using timeouts, add the needed polyfills instead!')
    await new Promise((r) => setTimeout(r, this.imState.value.timeOut))
    this._document.dispatchEvent(this.appReady)
  }

  public setPupeteerTimeoutValue(milliseconds: number) {
    this.imState.next({ ...this.imState.value, timeOut: milliseconds })
  }

  private setState(key: string, value: any) {
    this.imState.next({ ...this.imState.value, [key]: value })
  }

  zoneTimeOut: any = null
  async zoneIdleCheck() {
    console.log('zoneIdleCheck', 1)
    clearTimeout(this.zoneTimeOut)
    // this.zone.run time out
    this.zoneTimeOut = setTimeout(() => {
      console.log('zoneTimeOut')
      try {
        document.body.classList.remove('fouc')
        // document.getElementsByTagName('app-root').item(0).classList.remove('fouc')
        // document.getElementById('fouc').style.display = 'none'
      } catch (err) {}
    }, 1500)
    /** run the actual check for 'idle' outsides zone, otherwise it will never come to an end. */
    this.zone.runOutsideAngular(() => {
      console.log('zoneIdleCheck', 2)
      let tCancel = null
      const monitor = () => {
        console.log('zoneIdleCheck', 3)
        clearTimeout(tCancel)
        // console.log('this.zone.onStable', this.zone.isStable)
        this.zone.run(() => {
          console.log('zoneIdleCheck', 4)
          /** run this inside the zone, and give the app 250Ms to wrap up, before scraping starts */
          tCancel = setTimeout(() => {
            this._document.dispatchEvent(this.appReady)
            this.setState('idle', true)
            console.log('zone Run')
            console.log('zoneIdleCheck', 5)
          }, 250)
        })
      }
      monitor()
    })
  }
}
