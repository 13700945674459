import { IOrdersState } from '@store/ketshopweb-store'
import { Loader, LoaderOptions } from 'google-maps'

export class Etc {
  google: any

  orderstatus_img() {
    return {
      '-1': { img: '/assets/images/core-imgs/icon/order-status5.png', name: 'ยกเลิก' },
      '0': { img: '/assets/images/core-imgs/icon/order-status0.png', name: 'Pending' },
      '1': { img: '/assets/images/core-imgs/icon/order-status1.png', name: 'รอชำระเงิน' },
      '2': { img: '/assets/images/core-imgs/icon/order-status2.png', name: 'ได้รับเงิน' },
      '3': { img: '/assets/images/core-imgs/icon/order-status3.png', name: 'จัดเตรียมสินค้า' },
      '3.5': { img: '/assets/images/core-imgs/icon/order-status3_5.png', name: 'เตรียมจัดส่ง' },
      '4': { img: '/assets/images/core-imgs/icon/order-status4.png', name: 'จัดส่ง' },
    }
  }
  payment_img() {
    return {
      '-2': { img: '/assets/images/core-imgs/icon/payment-cash.png', name: 'รอชำระเงิน' },
      '-1': { img: '/assets/images/core-imgs/icon/payment-cash.png', name: 'ชำระเงินสด' },
      '1': { img: '/assets/images/core-imgs/icon/payment-cash.png', name: 'ชำระเงินสด' },
      '2': { img: '/assets/images/core-imgs/icon/payment-transfer.png', name: 'โอนผ่านธนาคาร' },
      '3': { img: '/assets/images/core-imgs/icon/payment-credit.png', name: 'เครดิตการ์ด' },
      '5': { img: '/assets/images/core-imgs/icon/payment-qrcode.png', name: 'QR CODE' },
      '4': { img: '/assets/images/core-imgs/icon/payment-cod.png', name: 'เก็บเงินปลายทาง' },
      '6': { img: '/assets/images/core-imgs/icon/payment-crypto.svg', name: 'Crypto currency' },
      '7': { img: '/assets/images/core-imgs/icon/payment-transfer.png', name: 'อื่นๆ' },
    }
  }

  channel_img() {
    return {
      website: { name: 'เว็บไซต์', img: '/assets/images/sell-channel/ch-website.jpg' },
      'jd-central': { name: 'JD Central', img: '/assets/images/sell-channel/ch-jdcentral.jpg' },
      lazada: { name: 'Lazada', img: '/assets/images/sell-channel/ch-lazada.jpg' },
      line_shopping: { name: 'Line Shopping', img: '/assets/images/sell-channel/ch-lineshopping.jpg' },
      shopee: { name: 'Shopee', img: '/assets/images/sell-channel/ch-shopee.jpg' },
      tiktok: { name: 'TikTok', img: '/assets/images/sell-channel/ch-tiktokshop.jpg' },
      pos: { name: 'Pos', img: '/assets/images/sell-channel/ch-pos.png' },
    }
  }

  isBackEnd() {
    const search = location.href.search(`${window.location.origin}/system`)
    // console.log("isbackend", search);
    if (search == -1) {
      return false
    } else {
      return true
    }
  }

  async initGoogleMap(key) {
    try {
      key = key || '-'
      const loader = new Loader(key, {})
      this.google = await loader.load()
    } catch (err) {}
  }

  getGoogleMap() {
    return this.google
  }

  getCurrentLang() {
    const backend = this.isBackEnd()
    let lang = 'lang1'

    if (backend) {
      lang = localStorage.getItem('lang_backend')
      if (!lang) {
        localStorage.setItem('lang_backend', 'lang1')
      }
      lang = localStorage.getItem('lang_backend')
    } else {
      lang = localStorage.getItem('lang')
      if (!lang) {
        lang = 'lang1'
      }
    }
    return lang
  }

  setCurrentLang(lang) {
    const backend = this.isBackEnd()
    if (backend) {
      localStorage.setItem('lang_backend', lang)
    } else {
      localStorage.setItem('lang', lang)
    }
  }

  filter_seo_desc(desc, length = 200) {
    if (desc == null) {
      return ''
    }
    desc = desc.replace(/<\s*br\/*>/gi, ' ')
    desc = desc.replace(/<\s*a.*href="(.*?)".*>(.*?)<\/a>/gi, ' ')
    desc = desc.replace(/<\s*\/*.+?>/gi, ' ')
    desc = desc.replace(/ {2,}/gi, ' ')
    desc = desc.replace(/\n+\s*/gi, ' ')
    desc = desc.replace(/&nbsp;/, ' ')

    if (desc.length > length) {
      desc = desc.substring(0, length) + '...'
    }

    return desc
  }

  random_string(amount) {
    let text = ''
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    for (let i = 0; i < amount; i++) text += possible.charAt(Math.floor(Math.random() * possible.length))

    return text
  }

  uid() {
    const date: any = new Date()
    let year: any = date.getUTCFullYear() - 2000
    let mon: any = date.getUTCMonth() + 1
    let day: any = date.getUTCDate()
    let hour: any = date.getUTCHours()
    let min: any = date.getUTCMinutes()
    let sec: any = date.getUTCSeconds()
    let milisec: any = date.getMilliseconds()
    const random = this.random_string(6)
    if (milisec < 10) {
      milisec = '0' + milisec.toString()
    }
    if (sec < 10) {
      sec = '0' + sec.toString()
    }
    if (min < 10) {
      min = '0' + min.toString()
    }
    if (hour < 10) {
      hour = '0' + hour.toString()
    }
    if (day < 10) {
      day = '0' + day.toString()
    }
    if (mon < 10) {
      mon = '0' + mon.toString()
    }
    if (year < 10) {
      year = '0' + year.toString()
    }
    const numStr: any =
      year.toString() +
      mon.toString() +
      day.toString() +
      hour.toString() +
      min.toString() +
      sec.toString() +
      milisec.toString()
    const num: any = parseInt(numStr)
    let stkNum: any = num.toString(36)
    stkNum += random
    return stkNum
  }

  cookieTime(m: number) {
    const date = new Date()
    const minutes = m
    date.setTime(date.getTime() + minutes * 60 * 1000)
    return date
  }

  getStyleHeader(data: any, _this: any = null) {
    // console.log(data);
    let s = ``
    if (data.options.backgroundHeader) {
      s = `
      <style>
        #dynamic_components_0 .setting-icon-wrap{
          top: unset!important;
          bottom: 15px!important;
        }
        .header_logo,
        .header_top,
        .header_social{
          background-color: rgba(255, 255, 255, 0)!important;
        }
        app-page-not-found{
          display: block;
          padding-top: 120px;
        }
        ${
          data.options.backgroundColorMenu
            ? `.header_header{
          background-color: var(--header-backgroundcolor)!important;
        }`
            : `.header_header{ background-color: rgba(255, 255, 255, 0)!important; }`
        }
  
  
        .header_mainmenu.sticky{
          box-shadow: rgba(0, 0, 0, 0.13) 0px 0px 11px;
        }
  
      </style>
      `
    } else {
      s = `
        <style>
  
          .header_header{
            background-color: var(--header-backgroundcolor)!important;
          }
  
          .header_header{
            box-shadow: rgba(0, 0, 0, 0.13) 0px 0px 11px;
          }
  
        </style>
        `
    }

    if (_this && _this.renderType == 'backend') {
      s += `
      <style>
        .header_mainmenu.sticky{
          position: relative!important;
          box-shadow: unset;
        }
  
      </style>
      `
    }

    let header_template = ''
    if (data.options.header_template == '1') {
      header_template = `
      @media (min-width: 992px){
        .header_mainmenu .navbar-nav > .nav-item,
        .header_mainmenu .navbar-nav > .nav-item > .nav-link{
          border: 0;
          border-radius: 0;
        }
        .header_mainmenu .navbar-nav > .nav-item{
          padding: 16px 12px;
        }
        .header_mainmenu .navbar-nav > .nav-item > .nav-link{
          padding: 0;
        }
        .header_mainmenu .navbar-nav > .nav-item,
        .header_mainmenu .navbar-nav > .nav-item > .nav-link{
          color: var(--menu-fontcolor);
        }
        .header_mainmenu .navbar-nav > .nav-item:active,
        .header_mainmenu .navbar-nav > .nav-item:focus,
        .header_mainmenu .navbar-nav > .nav-item:hover,
        .header_mainmenu .navbar-nav > .nav-item.selected{
          background-color: var(--menu-background_hover);
          color: var(--menu-font_hover);
        }
        .header_mainmenu .navbar-nav > .nav-item:active > .nav-link,
        .header_mainmenu .navbar-nav > .nav-item:focus > .nav-link,
        .header_mainmenu .navbar-nav > .nav-item:hover > .nav-link,
        .header_mainmenu .navbar-nav > .nav-item.selected > .nav-link{
          color: var(--menu-font_hover);
        }
      }
      `
    }

    if (data.options.header_template == '2') {
      header_template += `
      @media (min-width: 992px){
        .header_mainmenu{
          padding: 8px 0;
        }
        .header_mainmenu .navbar-nav > ._header_menu,
        .header_mainmenu .navbar-nav > ._header_menu > .nav-link{
          border: 0;
          border-radius: 0;
        }
        .header_mainmenu .navbar-nav > ._header_menu{
          padding: 0 2px;
        }
        .header_mainmenu .navbar-nav > ._header_menu > .nav-link{
          padding: 8px 12px;
        }
        .header_mainmenu .navbar-nav > ._header_menu:hover > .nav-link,
        .header_mainmenu .navbar-nav > ._header_menu.selected > .nav-link,
        .header_mainmenu .navbar-nav > ._header_menu.hover > .nav-link{
          border: 0;
          border-radius: 0;
          background-color: var(--menu-background_hover);
          color: var(--menu-font_hover);
        }
      }
      `
    }

    if (data.options.header_template == '3') {
      header_template = `
      @media (min-width: 992px){
        .header_mainmenu{
          padding: 8px 0;
        }
        .header_mainmenu .navbar-nav > ._header_menu,
        .header_mainmenu .navbar-nav > ._header_menu > .nav-link{
          border: 0;
          border-radius: 24px;
        }
        .header_mainmenu .navbar-nav > ._header_menu{
          padding: 0 2px;
        }
        .header_mainmenu .navbar-nav > ._header_menu > .nav-link{
          padding: 8px 12px;
        }
        .header_mainmenu .navbar-nav > ._header_menu:hover > .nav-link,
        .header_mainmenu .navbar-nav > ._header_menu.selected > .nav-link,
        .header_mainmenu .navbar-nav > ._header_menu.hover > .nav-link{
          background-color: var(--menu-background_hover);
          color: var(--menu-font_hover);
          border-radius: 24px;
        }
      }
      `
    }

    if (data.options.header_template == '4') {
      header_template = `
      @media (min-width: 992px){
        .header_mainmenu{
          padding: 8px;
        }
        .header_mainmenu .navbar-nav > ._header_menu,
        .header_mainmenu .navbar-nav > ._header_menu > .nav-link{
          background: transparent;
          border-radius: 0;
        }
        .header_mainmenu .navbar-nav > ._header_menu{
          padding: 0 12px;
        }
        .header_mainmenu .navbar-nav > ._header_menu > .nav-link{
          padding: 10px 0;
          border-bottom : 2px solid transparent;
        }
        .header_mainmenu .navbar-nav > ._header_menu > .nav-link{
          color: var(--menu-fontcolor);
          border-radius: 0;
          background: transparent;
        }
        .header_mainmenu .navbar-nav > ._header_menu:hover > .nav-link,
        .header_mainmenu .navbar-nav > ._header_menu.selected > .nav-link{
          color: var(--menu-font_hover);
          border-bottom-color: var(--menu-border_hover);
          border-radius: 0;
          background: transparent;
        }
      }
  
  
      `
    }

    if (data.options.header_template == '5') {
      header_template = `
      @media (min-width: 992px){
        .header_mainmenu{
          padding: 8px 0;
        }
        .header_mainmenu .navbar-nav > ._header_menu,
        .header_mainmenu .navbar-nav > ._header_menu > .nav-link{
          background: transparent;
          border-radius: 0;
        }
        .header_mainmenu .navbar-nav > ._header_menu{
          padding: 0 2px;
        }
        .header_mainmenu .navbar-nav > ._header_menu > .nav-link{
          padding: 8px 12px;
          border: 2px solid transparent;
        }
        .header_mainmenu .navbar-nav > .nav-item._header_menu:hover > .nav-link,
        .header_mainmenu .navbar-nav > .nav-item._header_menu.selected > .nav-link{
          background: transparent;
          border-radius: 0;
          color: var(--menu-font_hover);
          border-color: var(--menu-border_hover);
        }
      }
      `
    }

    if (data.options.header_template == '6') {
      header_template = `
      @media (min-width: 992px){
        .header_mainmenu{
          padding: 8px 0;
        }
        .header_mainmenu .navbar-nav > ._header_menu,
        .header_mainmenu .navbar-nav > ._header_menu > .nav-link{
          background: transparent;
          border-radius: 24px;
        }
        .header_mainmenu .navbar-nav > ._header_menu{
          padding: 0 2px;
        }
        .header_mainmenu .navbar-nav > ._header_menu > .nav-link{
          padding: 8px 12px;
          border: 2px solid transparent;
        }
        .header_mainmenu .navbar-nav > .nav-item._header_menu:hover > .nav-link,
        .header_mainmenu .navbar-nav > .nav-item._header_menu.selected > .nav-link{
          color: var(--menu-font_hover);
          border-color: var(--menu-border_hover);
          background: transparent;
          border-radius: 24px
        }
      }
      `
    }

    s += `
    <style>
      ${header_template}
  
      .collapse:not(.show) {
        display: none!important;
      }
      .collapse.show{
        display: block!important;
      }
    </style>
    `
    if (_this && _this.header.init.modalopen) {
      s += `
      <style>
        ${header_template}
  
        .item-example,
        .nav-item-example{
          display: block!important;
        }
        .nav-item-show{
          display: none!important;
        }
  
      </style>
      `
    }

    s = s.replace(/(\r\n|\n|\r)/gm, '')
    s = s.replace(/ {8}/g, '')

    return s
  }

  genStyleColor(theme_color: any, options: any, modal_status: any, advance_css: any, hide_lang_tab: any) {
    try {
      const color = theme_color

      let s = `
        <style>

            :root {
                --bg-primarycolor: ${color.color_primary}1a;
                --primarycolor: ${color.color_primary};
                --primarycolor-e8: ${color.color_primary}e8;
                --primarycolor-b0: ${color.color_primary}b0;
                --primarycolor-1a: ${color.color_primary}1a;
                --button-backgroundcolor: ${color.button.backgroundcolor};
                --button-backgroundcolor-e8: ${color.button.backgroundcolor}e8;
                --button-backgroundcolor-b0: ${color.button.backgroundcolor}b0;
                --button-backgroundcolor-1a: ${color.button.backgroundcolor}1a;
                --button-backgroundcolorhover: ${color.button.backgroundcolorhover};
                --button-border: ${color.button.border}px;
                --button-bordercolor: ${color.button.bordercolor};
                --button-bordercolorhover: ${color.button.bordercolorhover};
                --button-borderhover: ${color.button.borderhover}px;
                --button-borderradius: ${color.button.borderradius}px;
                --button-borderradiushover: ${color.button.borderradiushover}px;
                --button-fontcolor: ${color.button.fontcolor};
                --button-fontcolorhover: ${color.button.fontcolorhover};

                --footer-backgroundcolor: ${color.footer.backgroundcolor};
                --footer-font_hover: ${color.footer.font_hover};
                --footer-fontcolor: ${color.footer.fontcolor};

                --header-backgroundcolor: ${color.header.backgroundcolor};
                --header-backgroundcolor_logo: ${color.header.backgroundcolor_logo};

                --menu-background_hover: ${color.menu.background_hover};
                --menu-border_hover: ${color.menu.border_hover};
                --menu-font_hover: ${color.menu.font_hover};
                --menu-fontcolor: ${color.menu.fontcolor};

                --product-backgroundcolor: ${color.product.backgroundcolor};
                --product-fontcolor: ${color.product.fontcolor};

                --socialfooter-backgroundcolor: ${color.socialfooter.backgroundcolor};
                --socialfooter-fontcolor: ${color.socialfooter.fontcolor};

                --socialheader-backgroundcolor: ${color.socialheader.backgroundcolor};

                --submenu-background_hover: ${color.submenu.background_hover};
                --submenu-font_hover: ${color.submenu.font_hover};

                --topbar-backgroundcolor: ${color.topbar.backgroundcolor};
                --topbar-font_hover: ${color.topbar.font_hover};
                --topbar-fontcolor: ${color.topbar.fontcolor};

            }

            .nav-mobile .nav-link{
              color:  ${color.menu.fontcolor}!important;
            }


            .tmt-btn-all{
              background-color: ${color.button.backgroundcolor};
              color:  ${color.button.fontcolor};
              border: solid  ${color.button.border}px;
              border-color:  ${color.button.bordercolor};
              border-radius: ${color.button.borderradius}px;
            }

            .tmt-btn-all:hover{
              background-color: ${color.button.backgroundcolorhover};
              color:  ${color.button.fontcolorhover};
              border: solid  ${color.button.borderhover}px;
              border-color:  ${color.button.bordercolorhover};
              border-radius: ${color.button.borderradiushover}px;
            }

            .tmt-btn-all:focus{
              background-color: ${color.button.backgroundcolor};
              color:  ${color.button.fontcolor};
              border: solid  ${color.button.border}px;
              border-color:  ${color.button.bordercolor};
              border-radius: ${color.button.borderradius}px;
            }

            .tmt-btn-all.hover{
              background-color: ${color.button.backgroundcolorhover};
              color:  ${color.button.fontcolorhover};
              border: solid  ${color.button.borderhover}px;
              border-color:  ${color.button.bordercolorhover};
              border-radius: ${color.button.borderradiushover}px;
            }


            .btn-outline-tmt{
              background-color: ${color.button.backgroundcolorhover};
              color:  ${color.button.fontcolorhover};
              border: solid  ${color.button.borderhover}px;
              border-color:  ${color.button.bordercolorhover};
              border-radius: ${color.button.borderradiushover}px;
            }

            .btn-outline-tmt:hover{
              background-color: ${color.button.backgroundcolor};
              color:  ${color.button.fontcolor};
              border: solid  ${color.button.border}px;
              border-color:  ${color.button.bordercolor};
              border-radius: ${color.button.borderradius}px;
            }

            .btn-outline-tmt:focus{
              background-color: ${color.button.backgroundcolorhover};
              color:  ${color.button.fontcolorhover};
              border: solid  ${color.button.borderhover}px;
              border-color:  ${color.button.bordercolorhover};
              border-radius: ${color.button.borderradiushover}px;
            }

            .btn-outline-tmt.hover{
              background-color: ${color.button.backgroundcolor};
              color:  ${color.button.fontcolor};
              border: solid  ${color.button.border}px;
              border-color:  ${color.button.bordercolor};
              border-radius: ${color.button.borderradius}px;
            }

            .fr-view .tmt-btn-all{
              background-color: ${color.button.backgroundcolor}!important;
              color:  ${color.button.fontcolor}!important;
              border: solid  ${color.button.border}px!important;
              border-color:  ${color.button.bordercolor}!important;
              border-radius: ${color.button.borderradius}px!important;
            }

            .fr-view .tmt-btn-all:hover,
            .fr-view .tmt-btn-all:focus,
            .fr-view .tmt-btn-all.hover{
              background-color: ${color.button.backgroundcolorhover}!important;
              color:  ${color.button.fontcolorhover}!important;
              border: solid  ${color.button.borderhover}px!important;
              border-color:  ${color.button.bordercolorhover}!important;
              border-radius: ${color.button.borderradiushover}px!important;
            }

            .fr-view .tmt-btn-all:hover span,
            .fr-view .tmt-btn-all:focus span{
              color:  ${color.button.fontcolorhover}!important;
            }



            .color_render_wrap .ant-btn-primary{
              background-color: ${color.button.backgroundcolor};
              color:  ${color.button.fontcolor};
              border: solid  ${color.button.border}px;
              border-color:  ${color.button.bordercolor};
              border-radius: ${color.button.borderradius}px;
            }
            .color_render_wrap .ant-btn-primary:hover{
              background-color: ${color.button.backgroundcolorhover};
              color:  ${color.button.fontcolorhover};
              border: solid  ${color.button.borderhover}px;
              border-color:  ${color.button.bordercolorhover};
              border-radius: ${color.button.borderradiushover}px;
            }
            .color_render_wrap .ant-btn-default{
              border: solid  ${color.button.border}px;
              border-radius: ${color.button.borderradius}px;
            }
            .color_render_wrap .ant-btn-default:hover{
              border: solid  ${color.button.borderhover}px;
              border-radius: ${color.button.borderradiushover}px;
            }

            .color_render_wrap .ant-btn-primary:focus{
              background-color: ${color.button.backgroundcolor};
              color:  ${color.button.fontcolor};
              border: solid  ${color.button.border}px;
              border-color:  ${color.button.bordercolor};
              border-radius: ${color.button.borderradius}px;
            }

            .color_render_wrap .ant-btn-primary.hover{
              background-color: ${color.button.backgroundcolorhover};
              color:  ${color.button.fontcolorhover};
              border: solid  ${color.button.borderhover}px;
              border-color:  ${color.button.bordercolorhover};
              border-radius: ${color.button.borderradiushover}px;
            }


            .ant-pagination-item-active,
            .ant-pagination-item:focus,
            .ant-pagination-item:hover {
              border-color:  ${color.button.bordercolor};
              font-weight: 500;
              background-color: ${color.button.backgroundcolor};
              color:  ${color.button.fontcolor};
            }
            .ant-pagination-item-active:focus,
            .ant-pagination-item-active:hover{
              border-color:  ${color.button.bordercolor};
              font-weight: 500;
              background-color: ${color.button.backgroundcolor};
              color:  ${color.button.fontcolor};
            }
            .ant-pagination-item-active:focus a,
            .ant-pagination-item-active:hover a,
            .ant-pagination-item:focus a,
            .ant-pagination-item:hover a{
              color:  ${color.button.fontcolor};
            }

            .btn_search_color{
              background-color: var(--button-backgroundcolor);
              color:  var(--button-fontcolor);
            }
            .btn_search_color:hover{
              background-color: var(--button-backgroundcolor);
              color:  var(--button-fontcolor);
            }
            app-page-product04 .ant-slider-handle,
            app-page-product04 .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open){
              border-color:  ${color.button.backgroundcolor};
            }

            app-page-product04 .ant-slider-track{
              background-color: ${color.button.backgroundcolor};
            }

            app-page-product04 .ant-slider:hover .ant-slider-track{
              background-color: ${color.button.backgroundcolor};
            }


            #wh-widget-send-button {
              z-index: 99!important;
            }


            .static-bag{
                ${options.btn_cart_status ? 'display: inline-block!important;' : 'display: none!important;'}
                ${
                  options.btn_cart_transparent
                    ? `background-color: ${color.ecommerce.cartbuttoncolor};`
                    : 'background-color: rgba(250, 199, 105, 0);'
                }
            }
            .static-bag:hover{
                ${
                  options.btn_cart_transparent
                    ? `background-color: ${color.ecommerce.cartbuttonhover};`
                    : 'background-color: rgba(250, 199, 105, 0);'
                }
            }
            .static-top{
                ${options.btn_back_to_top_status ? 'display: inline-block!important;' : 'display: none!important;'}
                ${
                  options.btn_back_to_top_transparent
                    ? `background-color: ${color.ecommerce.backtotopbackgroundcolor};`
                    : 'background-color: rgba(250, 199, 105, 0);'
                }
            }
            .static-top i{
                color: ${color.ecommerce.backtotopfontcolor};
            }
            .static-top:hover{
                ${
                  options.btn_back_to_top_transparent
                    ? `background-color: ${color.ecommerce.backtotophover};`
                    : 'background-color: rgba(250, 199, 105, 0);'
                }
            }


            .item-example,
            .nav-item-example{
              display: ${modal_status ? 'block' : 'none'}!important;
            }
            .nav-item-show{
              display: ${modal_status ? 'none' : 'block'}!important;
            }

            .static-bag .number-on-bag-float{
              background-color: ${color.ecommerce.cartbuttonbgfontcolor};
              color: ${color.ecommerce.cartbuttonfontcolor};
            }

            ${advance_css}

            .header_mainmenu.sticky{
              background-color: var(--header-backgroundcolor)!important;
              box-shadow: rgba(0, 0, 0, 0.13) 0px 0px 11px;
          }

          ${hide_lang_tab ? '.header_language{     display: none!important; }' : ''}

        </style>
    `

      s = s.replace(/(\r\n|\n|\r)/gm, '')
      s = s.replace(/ {8}/g, '')

      return s
    } catch (err) {
      //console.log(err.message)
      return ''
    }
  }

  getFlexTemplate() {
    return [
      {
        category: 'product',
        style: 'product_01',
        label: 'สินค้ารูปแบบ 01',
        diaplay: '/assets/images/core-imgs/flex-message/flex-product-01.png',
        thumbnail: '/assets/images/core-imgs/flex-message/flex-product-01-thumbnail.png',
      },
      {
        category: 'product',
        style: 'product_02',
        label: 'สินค้ารูปแบบ 02',
        diaplay: '/assets/images/core-imgs/flex-message/flex-product-02.png',
        thumbnail: '/assets/images/core-imgs/flex-message/flex-product-02-thumbnail.png',
      },
      {
        category: 'image',
        style: 'image_01',
        label: 'รูปภาพ',
        diaplay: '/assets/images/core-imgs/flex-message/flex-image-01.png',
        thumbnail: '/assets/images/core-imgs/flex-message/flex-image-01.png',
      },
    ]
  }

  getFlexStyle(style: any) {
    const flex = this.getFlexTemplate().find((result) => result.style == style) as any
    return flex
  }
}
