import { AfterViewInit, Directive, ElementRef, HostBinding, Input } from '@angular/core'

// appLazyLoad
@Directive({
  selector: 'img[appLazyLoad]',
})
export class LazyLoadDirective implements AfterViewInit {
  @HostBinding('attr.src') srcAttr = null
  @Input() src: string

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    if (!window['seo']) {
      this.canLazyLoad() ? this.lazyLoadImage() : this.loadImage()
    }
  }

  private canLazyLoad() {
    return window && 'IntersectionObserver' in window
  }

  private lazyLoadImage() {
    const obs = new IntersectionObserver((entries) => {
      entries.forEach(({ isIntersecting }) => {
        //console.log(isIntersecting, this.src)
        if (isIntersecting) {
          this.loadImage()
          obs.unobserve(this.el.nativeElement)
        } else {
          this.loadThumbs()
          //obs.unobserve(this.el.nativeElement);
        }
      })
    })
    obs.observe(this.el.nativeElement)
  }

  private loadImage() {
    this.srcAttr = this.src
    //this._style = this.style
    this.el.nativeElement.style.filter = 'blur(0px)'
  }

  private loadThumbs() {
    const _src = this.src.replace('/upload-img', '/thumbs')
    this.srcAttr = _src
    this.el.nativeElement.style.filter = 'blur(6px)'
    //this._style = "filter: blur(6px);"
    //    filter: blur(6px);
  }
}
