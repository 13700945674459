import { Component, OnInit, Input, AfterViewInit } from '@angular/core'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { OrderService } from '@cms/services/order.service'
import { Router } from '@angular/router'
import { pathLangPipe } from '@cms/pipes/path-lang.pipe'

@Component({
  selector: 'app-modal-credit-redirect',
  templateUrl: './modal-credit-redirect.component.html',
  styleUrls: ['./modal-credit-redirect.component.css'],
})
export class ModalCreditRedirectComponent implements OnInit, AfterViewInit {
  @Input() data: any
  page_name = 'confirm-order'
  message_duration = 4000
  pending_request = false
  constructor(
    private message: NzMessageService,
    private modal: NzModalRef,
    private Service_Order: OrderService,
    private router: Router,
    private path_lang: pathLangPipe,
  ) {}

  ngOnInit() {}
  ngAfterViewInit(): void {
    $(this.data.preload).fadeOut()
    setTimeout(() => {
      console.log(this.data.html)
      // console.log($("#div_credit_redirect"));
      // $('#div_credit_redirect').html("");
      $('#div_credit_redirect').html(this.data.html)
      // console.log(this.data.html);

      // $(this.data.html).find('form').attr('target', '_blank').append('<h4 style="margin-bottom: 15px;">ยืนยันการชำระเงินด้วย Credit/Debit Card </h4><input id="credit-submit" class="btn btn-credit-submit tmt-btn-all" type="submit" name="SUBMIT" value="SUBMIT" style="min-width:140px;" />').appendTo('#div_credit_redirect');
      $('#credit-submit').click(() => {
        $(this.data.preload).fadeIn()
        $('#div_credit_redirect').find('form').hide()
        this.modal.close()
      })
    }, 200)
  }
}
