import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ISandclockbooks } from './types'
import { inject } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class CmsProductsService {
  public http: HttpClient = inject(HttpClient)

  prefix = '/services/plugins-api/sandclockbooks'

  getCustomTags(product_id?: number) {
    const params = {}

    if (product_id) {
      params['product_id'] = product_id
    }
    return this.http.get<ISandclockbooks.CustomTag[]>(`${this.prefix}/products/custom_tags`, { params })
  }

  isProductPreorder(product_id: number) {
    return this.http.get<{ pre_order: number }>(`${this.prefix}/products/${product_id}/preorder`)
  }

  partialUpdateProduct(product_id: number, data: { pre_order: boolean; custom_tag_ids: number[] }) {
    return this.http.patch<{
      error: boolean
      message: string
    }>(`${this.prefix}/products/${product_id}`, data)
  }

  getProductRelateds(product_id: number) {
    return this.http.get<ISandclockbooks.ProductRelated[]>(`${this.prefix}/products/${product_id}/product_relateds`)
  }

  saveProductRelateds(product_id: number, data: { product_related_ids: number[] }) {
    return this.http.post<{ error: boolean; message: string }>(
      `${this.prefix}/products/${product_id}/product_relateds`,
      data,
    )
  }
}
