import { createAction, props } from '@ngrx/store'
import { IFeaturesState } from './models'

export const actions_features = {
  LoadFeatures: createAction<string>('[CMS][Features/API] LoadFeatures'),
  SetFeatures: createAction<string, { features: IFeaturesState.Feature[] }>(
    '[CMS][Orders] SetFeatures',
    props<{
      features: IFeaturesState.Feature[]
    }>(),
  ),
}
