import { createAction, props } from '@ngrx/store'
import { IOrdersState } from './models'

export const actions_orders = {
  SetLoading: createAction<string, { val: boolean }>(
    '[CMS][Orders] SetLoading',
    props<{
      val: boolean
    }>(),
  ),
  SearchOrders: createAction<string, { input: IOrdersState.SearchModel; from: string }>(
    '[CMS][Orders/Api] SearchOrders',
    props<{
      from: string
      input: IOrdersState.SearchModel
    }>(),
  ),
  SetOrders: createAction<string, { data: { data: IOrdersState.OrderSearchItem[]; count: number }; from: string }>(
    '[CMS][Orders] setOrders',
    props<{
      from: string
      data: { data: IOrdersState.OrderSearchItem[]; count: number }
    }>(),
  ),
  SetSearchModel: createAction<string, { input: IOrdersState.SearchModel; from: string }>(
    '[CMS][Orders] SetSearchModel',
    props<{
      from: string
      input: IOrdersState.SearchModel
    }>(),
  ),
  InitSelectOrders: createAction<string, { from: string }>(
    '[CMS][Orders] InitSelectOrders',
    props<{
      from: string
    }>(),
  ),
  SelectOrders: createAction<string, { data: IOrdersState.OrderSearchItem[]; from: string; action: 'add' | 'del' }>(
    '[CMS][Orders] SelectOrders',
    props<{
      action: 'add' | 'del'
      from: string
      data: IOrdersState.OrderSearchItem[]
    }>(),
  ),
  ApplySelectOrders: createAction<string, { from: string }>(
    '[CMS][Orders] ApplySelectOrders',
    props<{
      from: string
    }>(),
  ),
  ClearSelectOrders: createAction<string, { from: string }>(
    '[CMS][Orders] ClearSelectOrders',
    props<{
      from: string
    }>(),
  ),
  LoadStaff: createAction<string>('[CMS][Orders/Api] LoadStaff'),
  SetStaff: createAction<string, { data: Partial<IOrdersState.OrdersState['list_staff']> }>(
    '[CMS][Orders/Api] SetStaff',
    props<{
      data: Partial<IOrdersState.OrdersState['list_staff']>
    }>(),
  ),
}
