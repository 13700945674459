import { createAction, props } from '@ngrx/store'

interface ICopyAction {
  val: string
  message?: string
  action: 'text' | 'order'
}
interface IAlertMessageAction {
  duration?: number
  message: string
  types: 'warning' | 'success' | 'error' | 'info' | 'loading'
}

interface ISetUTM {
  val: string
}

export const actions_app = {
  Copy: createAction<string, ICopyAction>('[CMS][App] Copy', props<ICopyAction>()),
  AlertMessage: createAction<string, IAlertMessageAction>('[CMS][App] AlertMessage', props<IAlertMessageAction>()),
  WarehouseInventoryChanges: createAction<string>('[CMS][App] WarehouseInventoryChanges'),
  SetUTM: createAction<string, ISetUTM>('[CMS][App] SetUTM', props<ISetUTM>()),
}
