import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Store, select } from '@ngrx/store'
import { Etc } from '../util/etc'
import { cloneDeep } from 'lodash'
@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  data: any[] = []
  _subscribe: any = null
  constructor(
    private _http: HttpClient,
    private store: Store<{ app_store: any }>,
    private _etc: Etc,
  ) {
    //console.log('=============   Constructor translate ===================');
    if (!this._subscribe) {
      this._subscribe = this.store.pipe(select('app_store', 'LANG_FORMAT')).subscribe((res: any[]) => {
        // console.log(res);
        this.data = cloneDeep(res)
      })
    }
  }

  translate(text, from, type = 'text') {
    // console.log('=============    translate ===================');
    // console.log(text);
    // let lang = localStorage.getItem('lang') || 'lang1';
    const lang = this._etc.getCurrentLang()
    let result = this.data
      .filter((x) => x.from == from)
      .find((x) => {
        // console.log('ddd', `${x.default}`.trim().toLocaleUpperCase(), `${text}`.trim().toLocaleUpperCase())
        return `${x.default}`.trim().toLocaleUpperCase() == `${text}`.trim().toLocaleUpperCase()
      })
    // console.log('result', result)
    // console.log(text, lang, result)
    if (result == 'undefined') {
      result = undefined
    }
    if (!result) {
      const obj = {
        type: type,
        from: from || 'other',
        text: text,
      }
      console.log(obj)

      this.data.push({
        default: `${text}`.trim(),
        from: from,
        lang1: `${text}`.trim(),
        lang2: `${text}`.trim(),
        lang3: `${text}`.trim(),
        lang4: `${text}`.trim(),
      })

      this._http
        .post('/services/v2/ketcms-frontend/utils/lang_format', obj)
        .toPromise()
        .then((resp: any) => {})
        .catch((err: any) => {})

      // console.log(text)
      return text
    }
    // console.log(result[lang])
    return result[lang]
  }

  thaiUnicodeDecoder(sentence: string) {
    const uniArr = this.thaiUnicodeDetector(sentence)
    if (uniArr.length === 0) return sentence
    let converted = sentence
    for (const i in uniArr) {
      converted = converted.replace(uniArr[i], this.unicodeToChar(uniArr[i]))
    }
    return converted
  }

  thaiUnicodeDetector(sentence: string) {
    const regex = /(\u0e[0-9a-f]{2})/g
    const found = [...sentence.match(regex)]
    return found ? found : []
  }

  unicodeToChar(text) {
    return text.replace(/u[\dA-F]{4}/gi, function (match) {
      return String.fromCharCode(parseInt(match.replace(/u/g, ''), 16))
    })
  }
}
