export const custom_genedenim = {
  product: {
    detail: true,
  },
  header_custom: {
    genedenim: true,
  },
  sections: [
    {
      init: {
        name: 'Full Cover Slide',
        img: '/assets/images/section/full-cover-slide.jpg',
        enable: 1,
        selector: 'ses-full-cover-slide',
        drag: false,
        modalopen: false,
        have_element: false,
        uid: '',
      },
      data: {
        slides: [
          {
            titleText: {
              fontFamily: '',
              fontSize: 'large',
              value: '',
              overlayStatus: false,
            },
            linkText: {
              fontFamily: '',
              value: '',
              href: '',
            },
            cover: {
              type: 'image',
              media: {
                src: '',
                type: '',
              },
            },
          },
        ],
      },
      elements: [],
    },
    {
      init: {
        name: 'Product Collection',
        img: '/assets/images/section/section-product-collection.jpg',
        enable: 1,
        selector: 'section-product-collection',
        drag: false,
        modalopen: false,
        have_element: false,
        uid: '',
      },
      data: {
        fullwidth: false,
        paddingRightLeft: true,
        paddingTopBottom: 50,
        layoutClass: 'collection_01',
        productList: {
          ids: [],
        },
        slideOption: {
          autoplay: false,
          autoplayTimeout: 3500,
          nav: false,
          dots: false,
        },
        slides: [
          {
            type: 'image',
            media: {
              src: '',
              type: '',
            },
          },
        ],
        setting: {
          layout: {
            column_padding_top_bottom: {
              size_adjust: true,
              screen_size_setting: {
                status: false,
                all: {
                  all: 16,
                  top: 16,
                  bottom: 0,
                },
                desktop: {
                  all: 16,
                  top: 16,
                  bottom: 0,
                },
                tablet: {
                  all: 16,
                  top: 16,
                  bottom: 0,
                },
                mobile: {
                  all: 16,
                  top: 16,
                  bottom: 0,
                },
              },
            },
          },
          background: {
            color: {
              status: false,
              value: '#ffffff',
            },
            image: {
              status: false,
              path: '',
              position: 'center',
              repeat: false,
              size: 'cover',
              opacity: 1,
            },
          },
        },
      },
      elements: [],
    },
    {
      init: {
        name: 'Fit Guide',
        img: '/assets/images/section/section-fit-guide.jpg',
        enable: 1,
        selector: 'section-fit-guide',
        drag: false,
        modalopen: false,
        have_element: false,
        uid: '',
      },
      data: {
        fullwidth: true,
        paddingRightLeft: false,
        paddingTopBottom: 50,
        slideOption: {
          autoplay: false,
          autoplayTimeout: 3500,
        },
        slides: [
          {
            type: 'image',
            media: {
              src: '',
              type: '',
            },
            titleText: {
              value: '',
            },
            descText: {
              value: '',
            },
            link: {
              refFrom: '',
              href: '',
            },
          },
        ],
        titleText: {
          fontFamily: '',
        },
        descText: {
          fontFamily: '',
        },
        setting: {
          layout: {
            column_padding_top_bottom: {
              size_adjust: true,
              screen_size_setting: {
                status: false,
                all: {
                  all: 16,
                  top: 16,
                  bottom: 0,
                },
                desktop: {
                  all: 16,
                  top: 16,
                  bottom: 0,
                },
                tablet: {
                  all: 16,
                  top: 16,
                  bottom: 0,
                },
                mobile: {
                  all: 16,
                  top: 16,
                  bottom: 0,
                },
              },
            },
          },
          background: {
            color: {
              status: false,
              value: '#ffffff',
            },
            image: {
              status: false,
              path: '',
              position: 'center',
              repeat: false,
              size: 'cover',
              opacity: 1,
            },
          },
        },
      },
      elements: [],
    },
    {
      init: {
        name: 'Styling Section',
        img: '/assets/images/section/section-styling.jpg',
        enable: 1,
        selector: 'section-styling',
        drag: false,
        modalopen: false,
        have_element: false,
        uid: '',
      },
      data: {
        fullwidth: false,
        paddingRightLeft: false,
        paddingTopBottom: 50,
        setting: {
          layout: {
            column_padding_top_bottom: {
              size_adjust: true,
              screen_size_setting: {
                status: false,
                all: {
                  all: 16,
                  top: 16,
                  bottom: 0,
                },
                desktop: {
                  all: 16,
                  top: 16,
                  bottom: 0,
                },
                tablet: {
                  all: 16,
                  top: 16,
                  bottom: 0,
                },
                mobile: {
                  all: 16,
                  top: 16,
                  bottom: 0,
                },
              },
            },
          },
          background: {
            color: {
              status: false,
              value: '#ffffff',
            },
            image: {
              status: false,
              path: '',
              position: 'center',
              repeat: false,
              size: 'cover',
              opacity: 1,
            },
          },
        },
      },
      elements: [],
    },
    {
      init: {
        name: 'Products Custom',
        selector: 'page-products-custom',
        img: '/assets/images/section/s-product.jpg',
        enable: 1,
        drag: false,
        modalopen: false,
        have_element: false,
        uid: '',
      },
      data: {
        cate_id: '',
      },
      elements: [],
    },
  ],
}
