import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { IBusiness } from './types/IBusiness'

@Injectable({
  providedIn: 'root',
})
export class CmsBusinessService {
  private http = inject(HttpClient)

  searchOrderQr(data: { sort: string; order_by: string; type: string; page: number; page_size: number }) {
    return this.http.post<IBusiness.SearchOrderQrResponse>(`/services/business/orders/qr/search`, data)
  }
}
