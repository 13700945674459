import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http'
import { registerLocaleData } from '@angular/common'
import en from '@angular/common/locales/en'
import { CookieModule } from 'ngx-cookie'
import { Etc } from './util/etc'
import { APIInterceptor } from './http.middleware'
import { FontFaceComponent } from './components/font-face'
import { StaticLangPipe } from './pipes/static-lang.pipe'
import { ModalEventPopupComponent } from './pages/modal/modal-event-popup/modal-event-popup.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FontSettingModule } from './system/font-setting/font-setting.module'
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n'
import { ButtonSettingModule } from './system/button-setting/button-setting.module'
import { MenuModule } from './system/menu/menu.module'
import { BlogDisplayModule } from './system/blog-display/blog-display.module'
import { ProductDisplayModule } from './system/product-display/product-display.module'
import { SettingThemeModule } from './system/setting/setting-theme/setting-theme.module'
import { EmailModule } from './system/email/email.module'
import { DeliveryViewComponent } from './system/delivery-view/delivery-view.component'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { UrlSerializer } from '@angular/router'
import CustomUrlSerializer from './url-serialize'
import { KetshopwebStoreModule } from '@store/ketshopweb-store'
// import { NzUpdateHostClassService } from 'ng-zorro-antd';
import { KetshopwebServices } from 'ketshopweb-services'
import { SortablejsModule } from 'ngx-sortablejs'
import * as Sandclockbooks from 'ketshopweb-privates/sandclockbooks'

registerLocaleData(en)

const customUrlSerializer = new CustomUrlSerializer()
const CustomUrlSerializerProvider = {
  provide: UrlSerializer,
  useValue: customUrlSerializer,
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http)
}

@NgModule({
  declarations: [
    AppComponent,
    // TMTLayoutDirective,
    FontFaceComponent,
    StaticLangPipe,
    ModalEventPopupComponent,
    DeliveryViewComponent,
  ],
  entryComponents: [ModalEventPopupComponent],
  imports: [
    BrowserModule,
    // NoopAnimationsModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    KetshopwebStoreModule,
    AppRoutingModule,
    HttpClientModule,
    CookieModule.withOptions(),
    FontSettingModule,
    ButtonSettingModule,
    MenuModule,
    BlogDisplayModule,
    ProductDisplayModule,
    SettingThemeModule,
    EmailModule,
    SortablejsModule.forRoot({}),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      multi: true,
    },
    CustomUrlSerializerProvider,
    Etc,
    // NzUpdateHostClassService,
    StaticLangPipe,
    { provide: NZ_I18N, useValue: en_US },
    KetshopwebServices.OmniChatService,
    KetshopwebServices.OrdersService,
    KetshopwebServices.ReportService,
    KetshopwebServices.ProductsService,
    KetshopwebServices.PaymentGateway,
    KetshopwebServices.WarehouseService,
    KetshopwebServices.DeliveryService,
    KetshopwebServices.TrcloudGatewayService,
    KetshopwebServices.ExtensionService,
    KetshopwebServices.LineShoppingService,
    KetshopwebServices.BusinessService,
    KetshopwebServices.PromotionsService,
    Sandclockbooks.FrontendService,
    Sandclockbooks.ProductsService,
    Sandclockbooks.BlogsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
