import { Pipe, PipeTransform } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { Etc } from '@ketshopweb/ui/core/services/etc.service'
import { Subscription } from 'rxjs'

@Pipe({
  name: 'ketPathLang',
})
export class KetPathLangPipe implements PipeTransform {
  PageLink: any[] = []
  _subscribe: Subscription = null

  constructor(
    private store: Store<any>,
    private _etc: Etc,
  ) {
    if (!this._subscribe) {
      this.store.pipe(select('app_store', 'PAGE_LINK')).subscribe((res: any[]) => {
        this.PageLink = res
      })
    }
  }

  transform(obj: any, key = '', prefix = '', type = '') {
    //console.log(this.PageLink);

    if (this.PageLink.length == 0) {
      try {
        const setPageLink = JSON.parse(localStorage.getItem('SET_PAGE_LINK'))
        if (Array.isArray(setPageLink)) {
          this.PageLink = setPageLink
        }
      } catch (err) {}
    }

    if (typeof obj == 'string') {
      type = obj
    }

    let langs: any = JSON.parse(localStorage.getItem('langs'))

    try {
      if (langs.length > 0) {
      } else {
        langs = []
      }
    } catch (err) {
      langs = []
    }

    // var lang = localStorage.getItem('lang') || "lang1";
    const lang = this._etc.getCurrentLang()
    let lang_data = langs.find((x: any) => x.ref == lang)
    if (!lang_data) {
      lang_data = langs.find((x: any) => x.ref == 'lang1')
    }
    let pathType = ''
    if (type !== '') {
      let pageLink: any = this.PageLink.find((x) => x.page == type)
      if (!pageLink) {
        try {
          this.PageLink = JSON.parse(localStorage.getItem('SET_PAGE_LINK'))
          // pageLink = this.PageLink.find(x => x.page == type)
          if (this.PageLink && Array.isArray(this.PageLink)) {
            pageLink = this.PageLink.find((x) => x.page == type)
          }
        } catch (err) {}
      } else if (typeof obj == 'string') {
        pathType = `${pageLink[lang]}`
        if (key !== '') {
          pathType = `${pageLink[lang]}/${key}`
        }
      } else {
        pathType = `${pageLink[lang]}/`
      }
    }

    const path: string = window.location.pathname
    const search: number = path.search('/system/')
    if (search !== -1) {
      lang_data.name = ''

      if (typeof obj == 'object') {
        return decodeURI(`${prefix}/${pathType}${obj[`${key}${lang}`]}`)
      } else {
        //console.log(prefix,'=',pathType)
        // const _path = decodeURI(`${prefix}/${pathType}`)
        //console.log(_path)
        return decodeURI(`${prefix}/${pathType}`)
      }
    }

    if (typeof obj == 'object') {
      if (langs.length > 1) {
        return decodeURI(`${prefix}/${lang_data.name}/${pathType}${obj[`${key}${lang}`]}`)
      } else {
        return decodeURI(`${prefix}/${pathType}${obj[`${key}${lang}`]}`)
      }
    } else {
      if (langs.length > 1) {
        return decodeURI(`${prefix}/${lang_data.name}/${pathType}`)
      } else {
        return decodeURI(`${prefix}/${pathType}`)
      }
    }
  }
}
