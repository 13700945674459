import { h, Fragment } from 'preact'
import { IPurchaseOrderHtml } from './types'
import { format_money } from 'libs/ketshopweb-services/func/format_money'
import { isEmpty, isObject } from 'lodash'
import { getTitle } from './tmp_order_product'

const TmpProduct = (obj: IPurchaseOrderHtml.Order['details'][number], i: number, layout: number) => {
  return (
    <tr key={i}>
      <td class={`${layout == 0 || layout == 1 ? 'table-product-no-head': 'table-product-no'} p-2`}colSpan={1}>{i + 1}
      </td>
      {layout !== 2 &&  layout !== 3 && (
        <td class="col-product-img text-center">
          <div class="product-image">
            <img src={obj.feature_img} />
          </div>
        </td>
      )}
      <td class="table-product-detail " colSpan={layout == 2 || layout == 3  ? 5 : 1}>
        <div class="product-wrapper">
          <div class="product-detail">
            {layout !== 3 && layout !== 5  && (<div class="product-name">{getTitle(obj , layout)}</div>)}
            {layout !== 2  && (
              <div class="product-sku">
                <span>{layout !== 3 && 'SKU : '}{obj.sku}</span>
              </div>
            )}
            {layout !== 2 &&  layout !== 3 && (<div>{!isEmpty(obj.barcode) && <span> | Barcode : {obj.barcode}</span>}</div>)}
            {!isEmpty(obj.product_set) &&
              obj.product_set.map((set, j) => {
                return (
                  <div key={j}>
                    <div>
                      x{Math.floor(set.qty / obj.qty)} 
                      {layout !== 3 &&  <span>{getTitle(set ,layout)}</span>}
                      {layout === 3 &&  <span class="product-sku ml-2" >{set.sku}</span>}
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </td>
      {layout !== 2 &&  layout !== 3 && (<td class="col-product-price text-right">
        {obj.temp_price > 0 && (
          <div>
            <s>{format_money(obj.temp_price)}</s>
          </div>
        )}
        <div>{format_money(obj.product_price)} </div>
      </td>)}
      <td class={`${layout == 0 || layout == 1 ? 'col-product-qty-head': 'product-sku'} p-2 text-right`}>{obj.qty}</td>
      {layout !== 2 &&  layout !== 3 && (<td class="col-product-total text-right">{format_money(obj.price)}</td>)}
    </tr>
  )
}
const TmpFreeItem = (free: IPurchaseOrderHtml.Order['free_item'][number], i: number,layout: number) => {
  return (
    <tr key={i}>
      <td class="table-product-no" colSpan={1}>{i + 1}</td>
      {layout !== 2 &&  layout !== 3 && ( <td class="col-product-img text-center">
        <div class="product-image">
          <img src={free.feature_img} />
        </div>
      </td>)}
      <td class="table-product-detail" colSpan={layout == 2 || layout == 3 ? 5 : 1}>
        <div class="product-wrapper">
          <div class="product-detail">
           {layout !== 3  && (<div class="product-name">
              {getTitle(free)} {free.promotion_type == 'product_point' ? `| ใช้แต้มแลก ${free.value} แต้ม` : ''}
            </div>)}
            {layout !== 2 &&  layout !== 3 && (<div class="product-sku">{layout !== 3 && 'SKU : '}{free.sku}</div>)}
            {!isEmpty(free.barcode) && <span> | Barcode : {free.barcode}</span>}
            <div>
              {!isEmpty(free.product_set) &&
                free.product_set.map((set, j) => {
                  return (
                    <div key={j}>
                      <div>
                        x{Math.floor(set.qty / free.qty)} 
                        {layout !== 3 &&  <span>{getTitle(set ,layout)}</span>}
                        {layout === 3 &&  <span class="product-sku ml-2" >{set.sku}</span>}
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </td>
      {layout !== 2 &&  layout !== 3 && (<td class="col-product-price text-right">
        <span>0.00</span>
      </td>)}
      <td class="col-product-qty text-right">{free.qty}</td>
      {layout !== 2 &&  layout !== 3 && (<td class="col-product-total text-right">0.00</td>)}
    </tr>
  )
}

export const TmpOrderProductShipingJt = ({
  order,
  qr_ordercode,
  layout,
}: {
  order: IPurchaseOrderHtml.Order
  qr_ordercode: string
  layout: number
}) => {
  let promotion_state = false
  let promotion_name = ''

  order.promotion.forEach((obj) => {
    if (obj.promotion_type == 'coupon') {
      promotion_state = true
      promotion_name = `ส่วนลดคูปอง / Coupon : ${obj.coupon}`
      if (obj.value_type == 'percent') {
        promotion_name += ` ( ${obj.value}% )`
      }
    } else if (obj.promotion_type == 'condition') {
      promotion_state = true
      promotion_name = `ส่วนลดโปรโมชั่น / Promotion : ${obj.title}`
      if (obj.value_type == 'percent') {
        promotion_name += ` ( ${obj.value}% )`
      }
    }
  })

  let shipping_name = ''
  let shipping_price: string | number = '0'

  if (order.freeshipping == 1) {
    shipping_name = 'ฟรีค่าขนส่ง / Free'
  }
  if (order.purchase_id && order.freeshipping == 0) {
    shipping_price = order.delicharge
    if (order.delichargeaddon > 0) {
      shipping_price = order.delicharge + order.delichargeaddon
    }

    if (isObject(order.delivery) && order.delivery.name) {
      shipping_name = order.delivery.name
    }
  }
  if (!order.purchase_id && order.freeshipping == 0 && order.user_id !== -1) {
    shipping_price = order.delicharge

    if (isObject(order.delivery) && order.delivery.name) {
      shipping_name = order.delivery.name
    }
  }
  shipping_price = format_money(shipping_price)

  if (order.user_id == -1) {
    shipping_name = 'รับหน้าร้าน / Pickup'
  }

  // alert('order.amount_no_vat: '+order.amount_no_vat)

  return (
    <Fragment>
      <div class="table-product-wrapper   p-2">
        <table class="mt-2">
          <thead>
            <tr>
              <th class="table-product-no-head" >{layout == 2 || layout == 3 ? '#' : 'No.'}</th>
              {layout !== 2 &&  layout !== 3 && <th class="col-product-img text-center">Image</th>}
              <th class="col-product-detail" colSpan={layout == 2 || layout == 3 ? 5 : 1} >{layout === 3 ? 'SKU' : 'Product'}</th>
              {layout !== 2 &&  layout !== 3 && <th class="col-product-price text-right">Price/Unit</th>}
              <th class="col-product-qty-head text-right">QTY</th>
              {layout !== 2 &&  layout !== 3 && <th class="col-product-total text-right">Total</th>}
            </tr>
          </thead>
          <tbody>
            {order.details.map((obj, i) => TmpProduct(obj, i, layout))}

            {order.free_item.length > 0 && (
              <Fragment>
                <tr>
                  <td colSpan={5}>
                    <strong>สินค้าฟรี / Free item</strong>
                  </td>
                </tr>
                {order.free_item.map((free, i) => TmpFreeItem(free, i, layout))}
              </Fragment>
            )}
          </tbody>
          {layout !== 2 &&  layout !== 3 &&(<tfoot style="display: table-row-group">
            {!order.amount_no_vat ? (
              <tr>
                <td colSpan={4} class="text-right">
                  ราคารวมสินค้า / Amount
                </td>
                <td class="text-right">{format_money(order.amount)}</td>
              </tr>
            ) : (
              <Fragment>
                <tr>
                  <td colSpan={4} class="text-right">
                    มูลค่ารายการยกเว้นภาษี / Amount (VAT Excluded)
                  </td>
                  <td class="text-right">{format_money(order.amount_no_vat)} ฿</td>
                </tr>
                <tr>
                  <td colSpan={4} class="text-right">
                    มูลค่ารายการที่คำนวณภาษี / Amount (VAT Included)
                  </td>
                  <td class="text-right">{format_money(order.amount - order.amount_no_vat)} ฿</td>
                </tr>
              </Fragment>
            )}
            {order.discountaddon > 0 && (
              <tr>
                <td colSpan={4} class="text-right">
                  ส่วนลดพิเศษ / Discount
                </td>
                <td class="text-right">-{format_money(order.discountaddon)}</td>
              </tr>
            )}
            {promotion_state && (
              <tr>
                <td colSpan={4} class="text-right">
                  {promotion_name}
                </td>
                <td class="text-right">-{format_money(order.discount)}</td>
              </tr>
            )}
            {order.vat > 0 && (
              <tr>
                <td colSpan={4} class="text-right">
                  ภาษีมูลค่าเพิ่ม {order.vatrate}%
                </td>
                <td class="text-right">{format_money(order.vat)}</td>
              </tr>
            )}
            <tr>
              <td colSpan={4} class="text-right">
                {shipping_name} ({Number(order.weight).toFixed(2)} กรัม / g)
              </td>
              <td class="text-right">{shipping_price}</td>
            </tr>
            {order.cod == 1 && (
              <tr>
                <td colSpan={4} class="text-right">
                  ค่าบริการ COD / COD Fee
                </td>
                <td class="text-right">{Number(order.cod_value).toFixed(2)}</td>
              </tr>
            )}
            <tr>
              <td colSpan={4} class="text-right">
                <strong>ราคารวมทั้งหมด / Total</strong>
              </td>
              <td class="text-right">
                <strong>{format_money(order.totals)}</strong>
              </td>
            </tr>
          </tfoot>)}
        </table>

        <div class="flex-row-between">
          <div class="box-note mt-3" style="width: 80%;">
             {layout !== 2 &&  layout !== 3 &&(
              <div>
              <strong>หมายเหตุลูกค้า/Customer Notes</strong>
              </div>)}
              {layout !== 2 &&  layout !== 3 &&(<div>-</div>)}
          </div>
          <div style="width: 100px;" class='mt-3'>
            <img class="w-100" src={qr_ordercode} />
            <div class="text-center"> QR Order </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
