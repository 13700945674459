import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetPayCreditComponent } from './pay-credit.component'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NgxMaskModule } from 'ngx-mask'
import { FormsModule } from '@angular/forms'
import { NzProgressModule } from 'ng-zorro-antd/progress'
import { KetTranslateModule } from '../translate'

@NgModule({
  declarations: [KetPayCreditComponent],
  imports: [
    CommonModule,
    NzInputModule,
    NzIconModule,
    NgxMaskModule,
    FormsModule,
    NzProgressModule,
    KetTranslateModule,
  ],
  exports: [KetPayCreditComponent],
})
export class KetPayCreditModule {}
