import { createFeatureSelector, createSelector } from '@ngrx/store'
import { AppState, AppStore } from './state'

export const Store: any = {
  app_store: AppStore,
}

const selectState = createFeatureSelector<AppState>('app_store')

const province = createSelector(selectState, (state: AppState) => state.PROVINCE)

export const AppStoreSelector = {
  province,
}
