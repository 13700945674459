/* eslint-disable no-async-promise-executor */
import { cloneDeep } from 'lodash'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { IProductSetting } from '@cms/types'
import { environment } from 'src/environments/environment'
import { Observable } from 'rxjs'

export namespace IKetshopwebFrontendService {
  export interface SearchPublicOrder {
    page: number
    page_size: number
    tel: string
  }

  export interface SearchPublicOrderResult {
    count: number
    data: {
      ordercode: string
      status: number
      cod: string
      expire: boolean
      has_cf_payment: boolean
      delivery?: {
        cod: boolean
      }
      name: string
      created_at: string
    }[]
  }

  export interface SearchIndexInput {
    page: number
    perpage: number
    lang?: 'lang1' | 'lang2' | 'lang3' | 'lang4'
    search?: string
    properties?: string
    cate_id?: number
    min?: string
    max?: string
    sort?: string
    tagname?: string
    tags?: number[]
    company_ids?: number[]
    brand_ids?: number[]
    wishlist_only?: boolean
  }

  export interface SearchIndexResponse {
    count: number
    data: any[]
  }

  export interface SearchFrontInput {
    page: number
    perpage: number
    lang?: 'lang1' | 'lang2' | 'lang3' | 'lang4'
    groupingsize?: boolean
    sort?: number | string
    sort_by?: number | string
    include_flash_sale?: boolean
    flash_sale_id?: number
    search?: string
    group?: string
    cate_id?: number | string
    ids?: number | number[]
    tag_id?: number
    status?: number
    type?: string
  }

  export interface SearchFrontResponse {
    count: number
    data: any[]
  }

  export enum MenuType {
    Product = 'product',
    Static = 'static',
    Blog = 'blog',
    Page = 'page',
    Topic = 'topic',
    ExternalLink = 'external_link',
    InternalLink = 'internal_link',
    ProductCategory = 'product_category',
    BlogCategory = 'blog_category',
    ProductTag = 'product_tag',
  }

  export interface CreateMenuData {
    lang1: string
    lang2: string
    lang3: string
    lang4: string
    type: MenuType
    enable: number
    path?: string
    is_column_menu?: boolean
  }

  export interface UpdateMenuData {
    page_id: number
    path?: string
    lang1: string
    lang2: string
    lang3: string
    lang4: string
    SEO_TITLE_lang1?: string
    SEO_TITLE_lang2?: string
    SEO_TITLE_lang3?: string
    SEO_TITLE_lang4?: string
    SEO_KEYWORD_lang1?: string
    SEO_KEYWORD_lang2?: string
    SEO_KEYWORD_lang3?: string
    SEO_KEYWORD_lang4?: string
    SEO_DES_lang1?: string
    SEO_DES_lang2?: string
    SEO_DES_lang3?: string
    SEO_DES_lang4?: string
    SEO_IMAGE_lang1?: string
    SEO_IMAGE_lang2?: string
    SEO_IMAGE_lang3?: string
    SEO_IMAGE_lang4?: string
    is_canonical?: number
    canonical_page_id?: number
    SEO_TITLE?: string
    SEO_KEYWORD?: string
    SEO_DES?: string
    SEO_IMAGE?: string
  }

  export interface ColumnMenu {
    columns: {
      column_menu_id: number
      image: string
      title_lang1: string
      title_lang2: string
      title_lang3: string
      title_lang4: string
      link: string
      type: MenuType.ExternalLink | MenuType.Page
      page: Menu
      menu: {
        link: string
        type: MenuType.ExternalLink | MenuType.Page
        title_lang1: string
        title_lang2: string
        title_lang3: string
        title_lang4: string
        page: Menu
      }[]
    }[]
    pattern: string
  }

  export interface Menu {
    title_lang1: string
    title_lang2: string
    title_lang3: string
    title_lang4: string
    path: string
    type: MenuType
    page: any
    childrens?: Menu[]
    column_menu?: ColumnMenu
  }
  export interface SearchForm {
    status: number
    start_date: string
    end_date: string
    search_create_by: string
    search_form_name: string
    page: number
    perpage: number
  }

  export interface Form {
    id?: number
    status: number
    updated_at?: string
    updated_by?: string
    created_by?: string
    created_at?: string
    limit_response: number
    form_name_lang1: string
    form_name_lang2?: any
    form_name_lang3?: any
    form_name_lang4?: any
    description_lang1: any
    description_lang2?: any
    description_lang3?: any
    description_lang4?: any
    form_question: FormQuesrtion[]
  }

  export interface FormQuesrtion {
    id?: number
    question_name?: string
    question_name_lang1?: string
    question_name_lang2?: string
    question_name_lang3?: string
    question_name_lang4?: string
    form_id?: number
    required_name_lang1: string
    required_name_lang2?: string
    required_name_lang3?: string
    required_name_lang4?: string
    option_type: string
    other: number
    required_status: number | boolean
    created_at?: any
    created_by?: any
    updated_at?: any
    updated_by?: any
    status_question: number
    form_aws: FormAw[]
  }

  export interface FormAw {
    id?: number
    question_id?: number
    option_name_lang1: string
    option_name_lang2?: any
    option_name_lang3?: any
    option_name_lang4?: any
    option_sort: number
    created_at?: any
    created_by?: any
    updated_at?: any
    updated_by?: any
  }

  export interface FormAwsDetail {
    id: number
    question_id: number
    answer_name: string
    answer_id: number
    user_id: string
    form_id: number
    created_at: any
    created_by: any
    updated_at: any
    updated_by: any
  }
}

// memory_cache
const listsectionelementFront: any = {}
let PAGE_OPTIONS: any[] = []

@Injectable({
  providedIn: 'root',
})
export class KetshopwebFrontendService {
  constructor(private _http: HttpClient) {}

  cacheCall(_promise: Observable<any>, key1: string, key2: string = null) {
    return new Promise((resolve, reject) => {
      if (key2) {
        // console.log('cacheCall',key1,key2, 'memory_cache[key1][key2]', memory_cache[key1][key2]);
        if (window['memory_cache'][key1][key2]) {
          return resolve(window['memory_cache'][key1][key2])
        } else {
          console.log('_promise 1')
          return _promise
            .toPromise()
            .then((res) => {
              window['memory_cache'][key1][key2] = res
              resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
        }
      }
      if (window['memory_cache'][key1]) {
        return resolve(window['memory_cache'][key1])
      } else {
        console.log('_promise 2')
        _promise
          .toPromise()
          .then((res) => {
            window['memory_cache'][key1] = res
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      }
    })
  }

  listsectionelementFront(model: { type: string; page: string }) {
    console.log('listsectionelementFront')
    return this._http.post('/services/ketshopweb-frontend/pages/listsectionelementFront', model)
  }

  getPageEleAndSectionFront(
    data: {
      type: string
      page: string
      link_path: string
      cat_path: string
    },
    lang = 'lang1',
  ) {
    return new Promise((resolve, reject) => {
      const key = `${data.type}_${data.page}_${lang}_${data.link_path}_${data.cat_path}`
      if (listsectionelementFront[key]) {
        console.log('getPageEleAndSectionFront CASE 1')
        resolve(listsectionelementFront[key])
      } else {
        console.log('getPageEleAndSectionFront CASE 2')
        this.listsectionelementFront(data)
          .toPromise()
          .then((res) => {
            listsectionelementFront[key] = res
            // console.log(listsectionelementFront);
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      }
    })
  }

  getPageLinkAll() {
    return this._http.get('/services/v2/ketcms-frontend/pages/pagelink_all').toPromise()
  }

  base_option = [
    'theme_value',
    'default_no_image',
    'favicon_icon',
    'fb_chat_plugin',
    'seo_title',
    'seo_keywords',
    'seo_description',
    'logo',
    'google_webmaster_tool',
    'maintenance_setting',
    'maintenance_text',
    'event_popup_enable',
    'event_popup_mobile',
    'event_popup_desktop',
    'google_map_api_key',
    'pdpa',
    'pdpa_type',
    'headerfix',
    'header_template',
    'header_layout',
    'header_font_style',
    'header_background',
    'header_font_size',
    'bag_icon',
    'white_bag_icon',
    'white_logo',
    'social_header_setting',
    'header_background_menu',
    'header_background_full_width',
    'member_level_status',
    'btn_back_to_top_status',
    'btn_back_to_top_transparent',
    'btn_cart_status',
    'btn_cart_transparent',
    'advance_css',
    'ecommerce_setting',
    'facebook',
    'twitter',
    'instagram',
    'youtube',
    'pinterest',
    'tumblr',
    'line',
    'vimeo',
    'flickr',
    'telephone',
    'email',
    'language_display',
    'icon_facebook',
    'icon_twitter',
    'icon_instagram',
    'icon_vimeo',
    'icon_flickr',
    'icon_tumblr',
    'icon_line',
    'icon_youtube',
    'icon_pinterest',
    'icon_email',
    'icon_tel',
    'product_img_class',
    'price_setting',
    'add_to_cart_now',
    'add_to_cart_now_setting',
    'product_setting',
    'sku_setting',
    'catalog_template_setting',
    'product_blog_title_font',
    'product_list_font_size',
    'product_img_crop',
    'product_default_filter',
    'product_display_item_perpage',
    'show_uncategory',
    'limit_product_names_display',
    'product_font_bold',
    'price_font_bold',
    'product_varaint_img',
    'track_script',
    'google_tag_manager_head',
    'google_tag_manager_body',
    'google_adword_remarketing',
    'pixel_code_id',
    'tiktok_pixel',
    'line_pixel_head',
    'line_pixel_event',
    'facebook_domain_verify',
    'custom_script_head',
    'custom_script_body',
    'custom_script_footer',
    'tiktok_pixel_id',
    'tiktok_pixel_access_token',
    'wishlist_setting',
    'whatshelp_button',
    'blog_template_setting',
    'sidebarcontent-1',
    'blog_list_font_style',
    'blog_list_font_size',
    'blog_setting',
    'blog_default_filter',
    'blog_detail_template',
    'show_stock',
    'member_point',
    'product_review',
    'product_details_short_desc_pos',
    'product_details_gallery_pos',
    'product_details_prop_style',
    'catalog_social_share_setting',
    'weight_setting',
    'catalog_contact_setting',
  ]
  is_option_processed = false
  async searchOption(
    obj: {
      pop_key: string[]
    },
    force_get = false,
  ) {
    if (!this.is_option_processed) {
      this.is_option_processed = true
    } else {
      while (this.is_option_processed) {
        // sleep 100ms
        console.log(`wait for searchOption 10ms`)
        await new Promise((resolve) => setTimeout(resolve, 10))
      }
    }
    //{pop_key: []}
    if (force_get) {
      this.is_option_processed = false
      return this._http.post('/services/v2/ketcms-frontend/utils/search_option', obj).toPromise()
    }

    // base_option gruop
    let rs: any[] = []
    const keys = {
      pop_key: [],
    }
    obj.pop_key.push(...this.base_option)
    // console.log('keys', keys)

    return new Promise(async (resolve, reject) => {
      try {
        // console.log(option)
        for (const key of obj.pop_key) {
          if (PAGE_OPTIONS.find((x) => x.pop_key == key)) {
            rs.push(PAGE_OPTIONS.find((x) => x.pop_key == key))
            // remove key from this.base_option
            const index = this.base_option.indexOf(key)
            if (index > -1) {
              this.base_option.splice(index, 1)
            }
          } else {
            // keys.pop_key.push(key)
            if (!keys.pop_key.includes(key)) {
              keys.pop_key.push(key)
            }
          }
        }
        if (obj.pop_key.length == rs.length) {
          this.is_option_processed = false
          return resolve(cloneDeep(rs))
        }
        try {
          // console.log('pop_key', JSON.stringify(keys))
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const resp: any = await this._http.post('/services/v2/ketcms-frontend/utils/search_option', keys).toPromise()
          if (!resp.length) {
            for (const key of keys.pop_key) {
              PAGE_OPTIONS.push({
                pop_key: key,
                pop_value: '',
              })
            }
            console.log('fake keys', keys)
          }
          rs = [...rs, ...resp]

          for (const _new of resp) {
            if (!PAGE_OPTIONS.find((x) => x.pop_key == _new.pop_key)) {
              PAGE_OPTIONS.push(_new)
              // remove key from this.base_option
              const index = this.base_option.indexOf(_new.pop_key)
              if (index > -1) {
                this.base_option.splice(index, 1)
              }
            }
            // remove key from keys
            const index = keys.pop_key.indexOf(_new.pop_key)
            if (index > -1) {
              keys.pop_key.splice(index, 1)
            }
          }
          // add fake data
          for (const key of keys.pop_key) {
            console.log('fake key', key)
            PAGE_OPTIONS.push({
              pop_key: key,
              pop_value: '',
            })
          }

          this.is_option_processed = false
          return resolve(cloneDeep(rs))
        } catch (err) {
          this.is_option_processed = false
          return reject(err)
        }
      } catch (e) {
        try {
          const resp: any = await this._http.post('/services/v2/ketcms-frontend/utils/search_option', keys).toPromise()
          this.is_option_processed = false
          return resolve(resp)
        } catch (err) {
          this.is_option_processed = false
          return reject(err)
        }
      }
    })
  }

  getSocialContact(): Promise<{
    [name: string]: {
      image: string
      ref: string
    }
  }> {
    return new Promise(async (reslove, reject) => {
      const social = {} as {
        [name: string]: {
          image: string
          ref: string
        }
      }
      const firstOptions = (await this.searchOption({
        pop_key: [
          'facebook',
          'twitter',
          'instagram',
          'youtube',
          'pinterest',
          'tumblr',
          'line',
          'vimeo',
          'flickr',
          'telephone',
          'email',
        ],
      })) as {
        pop_key: string
        pop_value: string
      }[]
      for (const opt of firstOptions) {
        if (opt.pop_value && opt.pop_value !== '#') {
          social[opt.pop_key] = {
            image: '',
            ref: opt.pop_value,
          }
        }
      }
      const secondOptions = (await this.searchOption({
        pop_key: [
          'icon_facebook',
          'icon_twitter',
          'icon_instagram',
          'icon_vimeo',
          'icon_flickr',
          'icon_tumblr',
          'icon_line',
          'icon_youtube',
          'icon_pinterest',
          'icon_email',
          'icon_tel',
        ],
      })) as {
        pop_key: string
        pop_value: string
      }[]
      for (const opt of secondOptions) {
        const prop = opt.pop_key.split('_')[1]
        if (opt.pop_key === 'icon_tel' && Object.prototype.hasOwnProperty.call(social, 'telephone')) {
          social.telephone.image = opt.pop_value
        } else if (prop && Object.prototype.hasOwnProperty.call(social, prop)) {
          social[prop].image = opt.pop_value
        }
      }
      reslove(social)
    })
  }

  clearTempPageOption() {
    PAGE_OPTIONS = []
  }

  getDefaultProductOption(): IProductSetting.Setting {
    const setting: IProductSetting.Setting = {
      border_radius: 0,
      border: 0,
      border_size: 1,
      border_color: '#eee',
      name_position: 'left',
      name_color: '#000',
      font_price: '',
      font_size_price: 16,
      font_color_price: '#000',
      load_more: '0',
      messenger_button: {
        link: '',
        status: false,
      },
      line_button: {
        link: '',
        status: false,
      },
      sale_tag_display: {
        type: 'text',
        size: '',
      },
      promo_point_display: {
        type: '1',
        size: '',
      },
      sold_out: {
        status: false,
        type: 'template',
        opacity: 100,
        template: {
          background: '#000',
          font_color: '#fff',
          font_family: '',
        },
        image: {
          url: '/assets/images/default/soldout.webp',
        },
      },
    }
    return setting
  }

  savePage(
    page_id: number,
    data: {
      data: {
        data: { [key: string]: any }
        elements: any[]
        init: { [key: string]: any } & { id: string | number }
        custom_name?: string
      }[]
      delete_elements: number[]
      delete_sections: number[]
      lang?: string
    },
  ) {
    return this._http
      .put(`/services/ketshopweb-frontend/pages/${page_id}`, {
        ...data,
        version: environment.version,
      })
      .toPromise()
  }

  listsectionelement(data: { lang?: string; type: string; page: string }) {
    return this._http.post('/services/ketshopweb-frontend/pages/listsectionelement', data).toPromise()
  }

  getPageVersion(page_id, ref) {
    return this._http.get(`/services/ketshopweb-frontend/pages/version/${page_id}/${ref}`).toPromise()
  }

  getProductDetailPageVersion(product_id, ref) {
    return this._http.get(`/services/ketshopweb-frontend/pages/productVersion/${product_id}/${ref}`).toPromise()
  }

  listPageVersion(obj) {
    return this._http.post(`/services/ketshopweb-frontend/pages/listPageVersion`, obj).toPromise()
  }

  listProductDetailPageVersion(obj) {
    return this._http.post(`/services/ketshopweb-frontend/pages/listProductDetailPageVersion`, obj).toPromise()
  }

  getPageProduct(data: { permalink?: string; id?: string; lang?: string }) {
    let suffix = ''
    const option = {}
    if (data.permalink) {
      suffix = `/${data.permalink}`
    } else if (data.id) {
      if (!option['params']) option['params'] = {}
      option['params']['id'] = data.id
    }
    return this._http.get(`/services/ketshopweb-frontend/pages/products${suffix}`, option).toPromise()
  }

  savePageProduct(
    permalink: string,
    data: {
      data: {
        data: { [key: string]: any }
        elements: any[]
        init: { [key: string]: any } & { id: string | number }
        custom_name?: string
      }[]
      delete_elements: number[]
      delete_sections: number[]
      lang?: string
    },
  ) {
    return this._http
      .put(`/services/ketshopweb-frontend/pages/products/${permalink}`, {
        ...data,
        version: environment.version,
      })
      .toPromise()
  }

  searchPublicOrder(model: IKetshopwebFrontendService.SearchPublicOrder) {
    return this._http
      .post(`/services/ketshopweb-frontend/orders/search/public`, model)
      .toPromise() as Promise<IKetshopwebFrontendService.SearchPublicOrderResult>
  }

  searchProductIndex(data: IKetshopwebFrontendService.SearchIndexInput) {
    data['page'] = Number(data['page'] || 1)
    data['perpage'] = Number(data['perpage'] || 20)
    data['cate_id'] = Number(data['cate_id'])
    if (!data['cate_id']) {
      // data['cate_id'] = 0;
      // delete data['cate_id']
    }
    return (
      this._http
        // .post('/services/ketshopweb-frontend/products/searchProductIndex', data)
        .post('/services/v2/ketcms-frontend/products/search_index', data)
        .toPromise() as Promise<IKetshopwebFrontendService.SearchIndexResponse>
    )
  }

  getProductGroup(data: { permalink: string }) {
    return this._http.post('/services/ketshopweb-frontend/products/productGroup', data).toPromise() as Promise<
      IKetshopwebFrontendService.SearchIndexResponse['data']
    >
  }

  getProductRelated(data: { permalink: string }) {
    return this._http.post('/services/ketshopweb-frontend/products/productRelated', data).toPromise() as Promise<
      IKetshopwebFrontendService.SearchIndexResponse['data']
    >
  }

  searchProductFront(
    data: IKetshopwebFrontendService.SearchFrontInput,
    is_cache = true,
  ): Promise<IKetshopwebFrontendService.SearchFrontResponse> {
    if (is_cache) {
      const key = JSON.stringify(data)
      return this.cacheCall(
        this._http.post('/services/ketshopweb-frontend/products/searchFront', data),
        'search_front',
        key,
      ) as Promise<IKetshopwebFrontendService.SearchFrontResponse>
    }
    return this._http
      .post('/services/ketshopweb-frontend/products/searchFront', data)
      .toPromise() as Promise<IKetshopwebFrontendService.SearchFrontResponse>
  }

  getMenus() {
    return this._http.get('/services/ketshopweb-frontend/pages/menuFront').toPromise() as Promise<
      IKetshopwebFrontendService.Menu[]
    >
  }

  getMenuBack() {
    return this._http.get('/services/ketshopweb-frontend/pages/menuBack').toPromise()
  }

  getMenuPageList() {
    return this._http.get('/services/ketshopweb-frontend/pages/menuPageList').toPromise()
  }

  createMenu(data: IKetshopwebFrontendService.CreateMenuData) {
    return this._http.post('/services/ketshopweb-frontend/pages/menu', data).toPromise()
  }

  deleteMenu(id: number) {
    return this._http.delete(`/services/ketshopweb-frontend/pages/menu/${id}`).toPromise()
  }

  updateMenuName(data: IKetshopwebFrontendService.UpdateMenuData) {
    return this._http.post('/services/ketshopweb-frontend/pages/updateMenuName', data).toPromise()
  }

  updateMenuSort(data: any[]) {
    return this._http.post('/services/ketshopweb-frontend/pages/updateMenuSort', data).toPromise()
  }

  updateMenuEnable(data: any) {
    return this._http.post('/services/ketshopweb-frontend/pages/updateMenuEnable', data).toPromise()
  }

  searchForm(data: IKetshopwebFrontendService.SearchForm) {
    return this._http.post('/services/ketshopweb-frontend/forms/searchForm', data).toPromise()
  }

  createForm(data: IKetshopwebFrontendService.Form) {
    return this._http.post('/services/ketshopweb-frontend/forms/createForm', data).toPromise()
  }

  getForm(id: number) {
    return this._http.get(`/services/ketshopweb-frontend/forms/getForm/${id}`).toPromise()
  }

  updateForm(data: IKetshopwebFrontendService.Form) {
    return this._http.post('/services/ketshopweb-frontend/forms/updateForm', data).toPromise()
  }
  deleteForm(id: number) {
    return this._http.delete(`/services/ketshopweb-frontend/forms/form/${id}`).toPromise()
  }
  searchFormReport(id: string, model: any) {
    return this._http.post(`/services/ketshopweb-frontend/forms/searchFormReport/${id}`, model).toPromise()
  }
  searchFormGraph(id: string, model: any) {
    return this._http.post(`/services/ketshopweb-frontend/forms/searchFormGraph/${id}`, model).toPromise()
  }
  getFormFont(id: number) {
    return this._http.get(`/services/ketshopweb-frontend/forms/getFormFront/${id}`).toPromise()
  }

  searchtablesheets(id: string, model: any) {
    return this._http.post(`/services/ketshopweb-frontend/forms/searchsheets/${id}`, model).toPromise()
  }
  exportsheetsform(id: string, model: any) {
    return this._http.post(`/services/ketshopweb-frontend/forms/exportsheetsform/${id}`, model).toPromise()
  }
  saveanswer(model: any) {
    return this._http.post(`/services/ketshopweb-frontend/forms/saveanswer`, model).toPromise()
  }
  validation(model: any) {
    return this._http.post(`/services/ketshopweb-frontend/trunstile/validation`, model).toPromise()
  }
  getcreatedby() {
    return this._http.get('/services/ketshopweb-frontend/forms/createdby').toPromise()
  }

  save_privilege(obj: any) {
    return this._http.post('/services/ketshopweb-frontend/privilege/save', obj).toPromise()
  }

  section_privilege() {
    return this._http.get('/services/ketshopweb-frontend/privilege/section_privilege').toPromise()
  }
}
