import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetJoinPipe } from './join.pipe'

@NgModule({
  declarations: [KetJoinPipe],
  imports: [CommonModule],
  exports: [KetJoinPipe],
})
export class KetJoinModule {}
