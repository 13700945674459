import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { EmailRoutingModule } from './email-routing.module'
import { EmailComponent } from './email.component'
import { Ng2GoogleChartsModule } from 'ng2-google-charts'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzProgressModule } from 'ng-zorro-antd/progress'
import { NzTableModule } from 'ng-zorro-antd/table'
import { KetPermissionModule } from '@ketshopweb/ui/permission'
import { NzModalModule } from 'ng-zorro-antd/modal'
import { NzMessageModule } from 'ng-zorro-antd/message'
import { ModalsModule } from '@cms/shared/modals.module'
import { NzGridModule } from 'ng-zorro-antd/grid'
import { KetFeatureLandingModule } from '@ketshopweb/ui/feature-landing'

@NgModule({
  declarations: [EmailComponent],
  imports: [
    CommonModule,
    EmailRoutingModule,
    Ng2GoogleChartsModule,
    NzButtonModule,
    NzProgressModule,
    NzTableModule,
    KetPermissionModule,
    NzModalModule,
    NzMessageModule,
    ModalsModule,
    NzGridModule,
    KetFeatureLandingModule,
  ],
})
export class EmailModule {}
