import { Action, createReducer, on } from '@ngrx/store'
import { IPageOptionsState } from './models'
import { actions_page_options } from './actions'
import { cloneDeep } from 'lodash'
export const PageOptionsFEATURE_KEY = 'page_options'

export const initialPageOptionsState: IPageOptionsState.PageOptionsState = {
  page_option: {
    add_to_cart_now: {
      pop_value: 0,
    } as IPageOptionsState.AddToCartNow,
    add_to_cart_now_setting: {
      pop_value: {
        format: '',
        align: '',
        font: '',
      },
    } as IPageOptionsState.AddToCartNowSetting,
    wishlist_setting: {
      pop_value: {
        status: false,
      },
    } as IPageOptionsState.WishlistSetting,
  },
}

const formatValue = (p: IPageOptionsState.PageOption) => {
  switch (p.pop_key) {
    case 'wishlist_setting':
    case 'add_to_cart_now_setting':
      try {
        p.pop_value = JSON.parse(p.pop_value)
      } catch (err) {
        console.error(err)
      }
      break
    case 'add_to_cart_now':
      p.pop_value = Number(p.pop_value)
      break
    default:
      //
      break
  }
  return p
}

const reducer = createReducer(
  initialPageOptionsState,
  on(actions_page_options.SetPageOptions, (state, action) => {
    const page_option = cloneDeep(action.page_options).reduce(
      (acc, curr) => ({ ...acc, [curr.pop_key]: formatValue(curr) }),
      {},
    )
    return {
      ...state,
      page_option: {
        ...state.page_option,
        ...page_option,
      },
    }
  }),
)

export function PageOptionsReducer(state: IPageOptionsState.PageOptionsState, action: Action) {
  return reducer(state, action)
}
