import { Action, createReducer, on } from '@ngrx/store'
import { IPagesState } from './models'
import { actions_pages } from './actions'
export const PagesFEATURE_KEY = 'pages'

export const initialPagesState: IPagesState.PagesState = {
  page_version: {
    page: null,
    version: [],
  },
  show_history_version: null,
  current_ref: null,
  wizard_setup: false,
}

const reducer = createReducer(
  initialPagesState,
  on(actions_pages.SetPageVersion, (state, action) => {
    return {
      ...state,
      page_version: action.data,
    }
  }),
  on(actions_pages.SetCurrentRef, (state, action) => {
    return {
      ...state,
      current_ref: action.data,
    }
  }),
  on(actions_pages.ShowHistoryVersion, (state, action) => {
    return {
      ...state,
      show_history_version: action.data,
    }
  }),
  on(actions_pages.SetWizardSetup, (state, action) => {
    return {
      ...state,
      wizard_setup: action.wizard_setup,
    }
  }),
)

export function PagesReducer(state: IPagesState.PagesState, action: Action) {
  return reducer(state, action)
}
