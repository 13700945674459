import { Component, Input, OnInit } from '@angular/core'
import { KetFeatureLandingService } from './feature-landing.service'
@Component({
  selector: 'ket-feature-landing',
  templateUrl: './feature-landing.component.html',
  styleUrls: ['./feature-landing.component.less'],
})
export class KetFeatureLandingComponent implements OnInit {
  constructor(private ketFeatureLandingService: KetFeatureLandingService) {}

  @Input('ketType') type: string
  // type == 'ORDER' ||
  // type == 'ORDERLINK' ||
  // type == 'PROMOTION' ||
  // type == 'FREE_ITEM' ||
  // type == 'PRODUCT_PAGE' ||
  // type == 'REPORT_PRODUCT' ||
  // type == 'BLOG_PAGE' ||
  // type == 'MAILTAB' ||
  // type == 'REPORT_STAFF' ||
  // type == 'ORDER_MARKETPLACE' ||
  // type == 'OTHER'
  @Input('ketSrc') src: string = null
  @Input('ketKey') key: string | string[] = null

  feature = true

  async ngOnInit() {
    if (Array.isArray(this.key)) {
      for (const key of this.key) {
        const feature = await this.ketFeatureLandingService.getFeature(key)
        console.log('key', key, feature)
        if (!feature) {
          this.feature = feature
          break
        } else {
          this.feature = feature
        }
      }
    } else {
      this.feature = await this.ketFeatureLandingService.getFeature(this.key)
      console.log('key', this.key, this.feature)
    }
    if (!this.feature) {
      return
    }
  }
}
