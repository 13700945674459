import { Component, OnInit, Input } from '@angular/core'
import { UtilService } from '@cms/services/util.service'
import { apiError } from '@cms/interface/interface'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { pathLangPipe } from '@cms/pipes/path-lang.pipe'
import { IKetshopwebFrontendService } from '@cms/services/ketshopweb-frontend.service'

declare let navigator: any

@Component({
  selector: 'app-modal-show-link-page',
  templateUrl: './modal-show-link-page.component.html',
  styleUrls: ['./modal-show-link-page.component.css'],
})
export class ModalShowLinkPageComponent implements OnInit {
  @Input() data: any

  langs: any[] = []
  link: any = {
    lang1: '',
    lang2: '',
    lang3: '',
    lang4: '',
  }

  constructor(
    private _util: UtilService,
    private _noti: NzNotificationService,
    protected pathLang: pathLangPipe,
  ) {}

  ngOnInit() {
    console.log(this.data)
    this.loadLang()
  }

  loadLang() {
    this._util
      .getLangs()
      .then((res: any[]) => {
        this.langs = res
        // console.log(res)
        this.langs.forEach((o) => {
          this.link[o.ref] = this.getLink(o)
        })
      })
      .catch((e: apiError) => {
        console.log(e.error.message)
      })
  }

  getLink(lang) {
    console.log(lang, this.data.page)

    console.log(this.pathLang.transform('blog-detail'))
    // if (this.data.page.defaul_stat == 0) {
    //   const blog_detail = this.pathLang.transform('blog-detail')
    //   return `${window.location.origin}/${lang.name}${blog_detail}/${this.data.page.page['permalink_' + lang.ref]}`
    // }

    // if (this.data.page.defaul_stat == -1 || this.data.page.defaul_stat == 1) {
    //   return `${window.location.origin}/${lang.name}/${this.data.page[lang.ref]}`
    // }

    // if (this.data.page.defaul_stat == 2) {
    //   return `${this.data.page.path}`
    // }

    // if (this.data.page.defaul_stat == 3) {
    //   const product_page = this.pathLang.transform('Products')
    //   return `${window.location.origin}/${lang.name}${product_page}/${this.data.page.path}/${this.data.page[lang.ref]}`
    // }

    // if (this.data.page.defaul_stat == 4) {
    //   console.log(this.data.page)
    //   const product_detail = this.pathLang.transform('product-detail')
    //   return `${window.location.origin}/${lang.name}${product_detail}/${this.data.page.page['permalink_' + lang.ref]}`
    // }

    switch (this.data.page.type) {
      case IKetshopwebFrontendService.MenuType.Blog:
        const blog_detail = this.pathLang.transform('blog-detail')
        const link_blog_detail = encodeURIComponent(this.data.page.page['permalink_' + lang.ref])
        return `${window.location.origin}/${lang.name}${blog_detail}/${link_blog_detail}`
      case IKetshopwebFrontendService.MenuType.Static:
      case IKetshopwebFrontendService.MenuType.Page:
        const link_page = encodeURIComponent(this.data.page[lang.ref])
        return `${window.location.origin}/${lang.name}/${link_page}`
      case IKetshopwebFrontendService.MenuType.InternalLink:
      case IKetshopwebFrontendService.MenuType.ExternalLink:
        return `${this.data.page.path}`
      case IKetshopwebFrontendService.MenuType.ProductCategory:
        const product_page = this.pathLang.transform('Products')
        const link_cate = encodeURIComponent(this.data.page[lang.ref])
        return `${window.location.origin}/${lang.name}${product_page}/${this.data.page.path}/${link_cate}`
      case IKetshopwebFrontendService.MenuType.Product:
        console.log(this.data.page)
        const product_detail = this.pathLang.transform('product-detail')
        const link_product = encodeURIComponent(this.data.page.page['permalink_' + lang.ref])
        return `${window.location.origin}/${lang.name}${product_detail}/${link_product}`
      case IKetshopwebFrontendService.MenuType.BlogCategory:
        const blog_page = this.pathLang.transform('Blog')
        const link_blog_cate = encodeURIComponent(this.data.page.page[lang.ref])
        return `${window.location.origin}/${lang.name}${blog_page}/${link_blog_cate}`
      default:
        return
    }
    // return `${window.location.origin}/${obj.name}/${this.data.page[obj.ref]}`
  }

  path(obj: any) {}

  copy(obj: any) {
    try {
      const text = this.getLink(obj)
      navigator.clipboard.writeText(text).then((res: any) => {
        this._noti.create('success', 'copy success', '')
      })
    } catch (err) {}
  }
}
