import { AppSateModule } from './+state/app/app.module'
import { NgModule } from '@angular/core'
import { Store } from '@cms/store/store'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { ChannelsSateModule } from './+state/channels'
import { OrdersSateModule } from './+state/orders'
import { TeamsSateModule } from './+state/teams'
import { ProductsSateModule } from './+state/products'
import { WarehouseSateModule } from './+state/warehouse'
import { PagesSateModule } from './+state/pages'
import { FeaturesSateModule } from './+state/features'
import { PageOptionsSateModule } from './+state/page_options'
import { ClientUuserSateModule } from './+state/client_user'

@NgModule({
  imports: [
    StoreModule.forRoot(Store),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      name: 'KetshopwebStore',
      maxAge: 25, // Retains last 25 states
      logOnly: false, // Restrict extension to log-only mode
    }),
    OrdersSateModule,
    ChannelsSateModule,
    AppSateModule,
    TeamsSateModule,
    ProductsSateModule,
    WarehouseSateModule,
    PagesSateModule,
    FeaturesSateModule,
    PageOptionsSateModule,
    ClientUuserSateModule,
  ],
})
export class KetshopwebStoreModule {}
