import { Injectable } from '@angular/core'
import { Etc } from '../util/etc'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root',
})
export class ExtensionService {
  constructor(
    private _etc: Etc,
    private _http: HttpClient,
  ) {}

  updateOrderMaketPlaceOrderCreateStatus(data) {
    return this._http.post('/extension/updateOrderMaketPlaceOrderCreateStatus', data).toPromise()
  }

  searchGoogleProductCategory(data) {
    return this._http.post('/extension/searchGoogleProductCategory', data).toPromise()
  }

  getFacebookCatalog() {
    return this._http.get('/extension/facebookCatalog').toPromise()
  }

  updateFacebookCatalog(data) {
    return this._http.post('/extension/updateFacebookCatalog', data).toPromise()
  }

  marketplace() {
    return this._http.get('/extension/marketPlace').toPromise()
  }

  createOrderKetshopFromLazada(data) {
    return this._http.post('/lazada/orderKetshop', data).toPromise()
  }

  searchSummaryOrderMarketPlace(data = null) {
    return this._http.post('/extension/searchSummaryOrderMarketPlace', data).toPromise()
  }

  // JD

  checkJDTokenExpire() {
    return this._http.get('/jd-central/checkTokenExpire').toPromise()
  }
  disconnectJDCentral(data: any) {
    return this._http.post('/jd-central/disconnect', data).toPromise()
  }

  connectJD(data: any) {
    return this._http.post('/jd-central/connect', data).toPromise()
  }
  searchJDProduct(data: any) {
    return this._http.post('/jd-central/searchJDProduct', data).toPromise()
  }

  searchKetshopProductToJD(data) {
    return this._http.post('/jd-central/searchKetshopProductToJD', data).toPromise()
  }
  getJDCategory() {
    return this._http.get('/jd-central/getJDCategory').toPromise()
  }
  getJDCategoryByIDs(data) {
    return this._http.post('/jd-central/getJDCategoryByIDs', data).toPromise()
  }
  getJDCategoryAttr(data) {
    return this._http.post('/jd-central/getJDCategoryAttr', data).toPromise()
  }

  uploadImageJD(data) {
    return this._http.post('/jd-central/uploadImage', data).toPromise()
  }
  createProductJD(data) {
    return this._http.post('/jd-central/createProduct', data).toPromise()
  }
  updateProductJD(data) {
    return this._http.post('/jd-central/updateProductJD', data).toPromise()
  }
  getBase64Image(data) {
    return this._http.post('/jd-central/getBase64Image', data).toPromise()
  }
  getProductJDByID(data) {
    return this._http.post('/jd-central/getProductJDByID', data).toPromise()
  }
  syncProductJD(data = null) {
    return this._http.post('/jd-central/syncProduct', data).toPromise()
  }
  searchOrderJD(data: any) {
    return this._http.post('/jd-central/searchOrderJD', data).toPromise()
  }
  syncOrderJD() {
    return this._http.post('/jd-central/syncOrderJD', null).toPromise()
  }
  addOrderJDToQueue(data: any) {
    return this._http.post('/jd-central/addOrderJDToQueue', data).toPromise()
  }
  createOrderKetshopFromJD(data: any) {
    return this._http.post('/jd-central/orderKetshop', data).toPromise()
  }
  deleteProductJD(data: any) {
    return this._http.post('/jd-central/deleteProduct', data).toPromise()
  }

  /// LAZADA
  checkLazadaTokenExpire() {
    return this._http.get('/lazada/checkTokenExpire').toPromise()
  }
  syncOrderLazada() {
    return this._http.post('/lazada/syncOrderLazada', null).toPromise()
  }
  disconnectLazada(data: any) {
    return this._http.post('/lazada/disconnect', data).toPromise()
  }

  connectLazada(data: any) {
    return this._http.post('/lazada/connect', data).toPromise()
  }
  searchLazadaProduct(data: any) {
    return this._http.post('/lazada/searchLazadaProduct', data).toPromise()
  }
  syncProductLazada(data = null) {
    return this._http.post('/lazada/syncProduct', data).toPromise()
  }
  updateProductLazada(data: any) {
    return this._http.post('/lazada/updateProduct', data).toPromise()
  }
  getProductLazadaByID(id) {
    return this._http.get('/lazada/productLazada/' + id).toPromise()
  }
  searchOrderLazada(data: any) {
    return this._http.post('/lazada/searchOrderLazada', data).toPromise()
  }
  // addOrderLazadaToQueue(data:any){
  //     return this._http.post('/lazada/addOrderLazadaToQueue', data).toPromise();
  // }

  deleteProductLazada(data: any) {
    return this._http.post('/lazada/deleteProduct', data).toPromise()
  }

  // deliveredLazadaOrder(data:any){
  //     return this._http.post('/lazada/deliveredLazadaOrder', data).toPromise();
  // }

  // SHOPEE
  // checkShopeeExpire() {
  //     return this._http.post('/shopee/checkShopeeExpire', null).toPromise();
  // }
  // checkShopeeConnection(){
  //     return this._http.post('/shopee/checkShopeeConnection', null).toPromise();
  // }
  // createOrderKetshopFromShopee(data:any){
  //     return this._http.post('/shopee/orderKetshop', data).toPromise();
  // }
  // syncShopeeOrderByOrdersn(data: any) {
  //     return this._http.post('/shopee/syncShopeeOrderByOrdersn', data).toPromise();
  // }

  // syncProductShopee(data = null){
  //     return this._http.post('/shopee/syncProductShopee', data).toPromise();
  // }

  // unListItem(data){
  //     return this._http.post('/shopee/unListItem', data).toPromise();
  // }

  // getShopeeLogistic(data=null){
  //     return this._http.post('/shopee/getLogistic', data).toPromise();
  // }

  // getShopeeItemCategory(data=null){
  //     return this._http.post('/shopee/itemCategory', data).toPromise();
  // }

  // getShopeeItemAttribute(data = null){
  //     return this._http.post('/shopee/itemAttributes', data).toPromise();
  // }

  getShopeeLogisticInfo(data) {
    return this._http.post('/shopee/getLogisticInfo', data).toPromise()
  }

  deliveredShopeeOrder(data) {
    return this._http.post('/shopee/deliveredShopeeOrder', data).toPromise()
  }
  updateShopeeOrderTrackingCode(data) {
    return this._http.post('/shopee/updateShopeeOrderTrackingCode', data).toPromise()
  }

  // createProductShopee(data){
  //     return this._http.post('/shopee/createProductShopee', data).toPromise();
  // }

  searchKetshopProductToShopee(data) {
    return this._http.post('/shopee/searchProductKetshop', data).toPromise()
  }

  searchShopeeProduct(data) {
    return this._http.post('/shopee/searchProductShopee', data).toPromise()
  }

  // updateProductDetailShopee(data) {
  //     return this._http.post('/shopee/updateProductDetailShopee', data).toPromise();
  // }

  updateProductShopee(data) {
    return this._http.post('/shopee/updateProductShopee', data).toPromise()
  }

  getProductShopeeByID(id) {
    return this._http.get('/shopee/productShopee/' + id).toPromise()
  }

  deleteProductShopee(data) {
    return this._http.post('/shopee/deleteProductShopee', data).toPromise()
  }

  getShopeeShopInfo() {
    return this._http.post('/shopee/shopInfo', null).toPromise()
  }

  syncOrderShopee() {
    return this._http.post('/shopee/syncOrderShopee', null).toPromise()
  }

  searchOrderShopee(data) {
    return this._http.post('/shopee/searchOrderShopee', data).toPromise()
  }

  cancelAuthorizationShopUrl(data) {
    return this._http.post('/shopee/cancelAuthorizationShopUrl', data).toPromise()
  }

  authorizationShopUrl(data) {
    return this._http.post('/shopee/authorizationShopUrl', data).toPromise()
  }

  // END SHOPEE
  addZwizToken(data) {
    return this._http.post('/extension/addZwizToken', data).toPromise()
  }

  checkZwizStatus(data = null) {
    return this._http.post('/extension/checkZwizStatus', data).toPromise()
  }

  list() {
    return this._http.get('/extension').toPromise()
  }

  search(data: any) {
    return this._http.post('/extension/search', data).toPromise()
  }

  syncZort(data: any) {
    return this._http.post('/extension/syncZort', data).toPromise()
  }

  syncProductZort(data: any = null) {
    return this._http.post('/extension/syncProductZort', data).toPromise()
  }

  disconnectZort(data: any = null) {
    return this._http.post('/extension/disconnectZort', data).toPromise()
  }

  connectGbPay(data: any) {
    return this._http.post('/extension/connectGbPay', data).toPromise()
  }
  disconnectGbPay(data: any = null) {
    return this._http.post('/extension/disconnectGbPay', data).toPromise()
  }

  connectScbEasy(data: any) {
    return this._http.post('/extension/connectScbEasy', data).toPromise()
  }
  disconnectScbEasy(data: any = null) {
    return this._http.post('/extension/disconnectScbEasy', data).toPromise()
  }

  connectPeak(data: any) {
    return this._http.post('/extension/connectPeak', data).toPromise()
  }
  disconnectPeak(data: any) {
    return this._http.post('/extension/disconnectPeak', data).toPromise()
  }

  createQuotationByOrders(data: any) {
    return this._http.post('/peak/quotations_by_orders', data).toPromise()
  }

  createReceiptsByOrder(data: any) {
    return this._http.post('/peak/receipts_by_orders', data).toPromise()
  }

  checkPendingPeak() {
    return this._http.get('/peak/check_pending').toPromise()
  }

  checkEafy() {
    return this._http.get('/eafy/check_status').toPromise()
  }

  addBizEafy(data: any) {
    return this._http.post('/eafy/addBiz', data).toPromise()
  }

  connectLineChat(data: any) {
    return this._http.post('/extension/connectLineChat', data).toPromise()
  }

  disconnectLineChat() {
    return this._http.post('/extension/disconnectLineChat', {}).toPromise()
  }
  LineChatSaveQuick(obj: any) {
    return this._http.post('/extension/LineChatSaveQuick', obj).toPromise()
  }

  connectWebhook(data: any) {
    return this._http.post('/extension/connectWebhook', data).toPromise()
  }

  connectSMS(data: any) {
    return this._http.post('/extension/connectSMS', data).toPromise()
  }

  searchSMS(data: any) {
    return this._http.post('/services/sms-service/credit/search', data).toPromise()
  }

  sendSMS(data: any) {
    return this._http.post('/services/sms-service/cms/send', data).toPromise()
  }
}
