import { Component, OnInit, Input } from '@angular/core'
import { UtilService } from '@cms/services/util.service'
import * as moment from 'moment'
import { environment } from 'src/environments/environment'
import { AuthenService } from '@cms/services/authen.service'
import { EPaymentService } from '@cms/services/epayment.service'
import { EbalanceItem, EbalanceSearchResponse } from '@cms/system/e-payment/e-payment.component'
import { NzMessageService } from 'ng-zorro-antd'
import * as XLSX from 'xlsx'
import * as ELSX from 'xlsx-js-style'

@Component({
  selector: 'app-modal-export-epayment',
  templateUrl: './modal-export-epayment.component.html',
  styleUrls: ['./modal-export-epayment.component.css'],
})
export class ModalExportEpaymentComponent implements OnInit {
  @Input() data: any
  currentdatetime: any
  rangePicker: any = []
  access_token: string
  api_path: string
  constructor(
    private Service_Util: UtilService,
    private Service_Epayment: EPaymentService,
    private _authen: AuthenService,
    private message: NzMessageService,
  ) {}

  model: any = {
    status: 'ALL',
    ordercode: '',
    transaction_no: '',
    page: 1,
    per_page: 1,
    start_date: '',
    end_date: '',
    warehouse: [],
  }

  value: EbalanceItem[] = []
  count = 0

  async ngOnInit() {
    this.api_path = environment.api_path
    this.access_token = this._authen.getToken().access_token
    this.currentdatetime = await this.Service_Util.getDateTime()
    this.rangePicker = [
      moment(this.currentdatetime.date).add(-1, 'month').format('YYYY-MM-DD'),
      moment(this.currentdatetime.date).format('YYYY-MM-DD'),
    ]
    this.model.start_date = moment(this.rangePicker[0]).format('YYYY-MM-DD')
    this.model.end_date = moment(this.rangePicker[1]).format('YYYY-MM-DD')
  }
  dateRangeChange() {
    try {
      this.model.start_date = moment(this.rangePicker[0]).format('YYYY-MM-DD')
      this.model.end_date = moment(this.rangePicker[1]).format('YYYY-MM-DD')
    } catch (e) {
      this.model.start_date = moment(this.currentdatetime.date).add(-1, 'month').format('YYYY-MM-DD')
      this.model.end_date = moment(this.currentdatetime.date).format('YYYY-MM-DD')
    }
  }

  async ok() {
    $('.theme-loader').fadeIn()
    // $('form#epayment-export').submit()
    await this.search()
    this.model.per_page = this.count
    await this.search()
    const list_export: any[] = []
    const data_details = []

    for (const payment of this.value) {
      const model_details = {
        'Order No': payment.ordercode || '-',
        Type: payment.type,
        Name: payment.provider,
        Transaction: payment.transaction,
        Total: payment.total,
        Status: payment.status_name,
        'Create Date': payment.created_at,
        'Update Date': payment.updated_at,
        'Order Status URL': '-',
        ชื่อคลัง: payment.warehouse_name,
      }
      if (payment.order_type == 'order' && payment.ordercode) {
        model_details['Order Status URL'] =
          `${window.location.origin}/order-page/${this.Service_Util.enCodeId(payment.ordercode)}`
      }
      data_details.push(model_details)
    }

    for (const arr of data_details) {
      list_export.push(arr)
    }

    console.log('this.model', this.model)
    const SheetNames = `${this.model.start_date}-${this.model.end_date}`
    const wb = XLSX.utils.book_new()
    wb.Props = {
      Title: SheetNames,
      CreatedDate: new Date(),
    }
    wb.SheetNames.push(SheetNames)
    const ws = XLSX.utils.json_to_sheet(list_export)
    const head_style = {
      alignment: {
        vertical: 'center',
        horizontal: 'center',
        wrapText: true, // any truthy value here
      },
      font: {
        bold: true,
      },
    }

    for (const name in ws) {
      if (!/[A-Z][1]/.test(name)) continue
      if (name.length > 2) continue
      ws[name].s = head_style
    }

    const body_style = {
      alignment: {
        vertical: 'center',
        horizontal: 'center',
        wrapText: true, // any truthy value here
      },
      font: {
        underline: true,
      },
    }

    for (const name in ws) {
      if (/[A-Z][1]"/.test(`"${name}"`)) continue
      if (!/I/.test(`"${name}"`)) continue

      let a = ''
      if (ws[name]) {
        ws[name].s = body_style

        if (ws[name].v) {
          a = ws[name].v
          ws[name].l = {
            Target: a, // Replace with your actual URL
            Tooltip: 'Click me', // Replace with your tooltip text
          }
        }
      }
    }

    ws['!cols'] = [
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 15 },
      { width: 20 },
      { width: 25 },
      { width: 25 },
      { width: 50 },
      { width: 20 },
      { width: 20 },
    ]
    ws['!rows'] = [{ hpt: 40 }]
    for (let index = 0; index < list_export.length; index++) {
      ws['!rows'].push({ hpt: 30 })
    }
    $('.theme-loader').fadeOut()
    wb.Sheets[SheetNames] = ws
    const wbout = ELSX.write(wb, { bookType: 'xlsx', type: 'base64', cellStyles: true })
    const filePath = 'data:application/octet-stream;base64,' + wbout
    const a = document.createElement('A') as any
    a.href = filePath
    a.download = `Ebalance_report(${this.model.start_date}-${this.model.end_date}).xlsx`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  async search() {
    await this.Service_Epayment.search(this.model)
      .then((res: EbalanceSearchResponse) => {
        this.value = res.data
        this.count = res.count
      })
      .catch((data: any) => {
        this.message.error(`${data.error.message}`, { nzDuration: 6000 })
      })
  }
}
