import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetModalLoginComponent } from './modal-login.component'
import { NzMessageModule } from 'ng-zorro-antd/message'
import { NzModalModule } from 'ng-zorro-antd/modal'
import { RouterModule } from '@angular/router'
import { NzInputModule } from 'ng-zorro-antd/input'
import { KetTranslateModule } from '../translate'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { FormsModule } from '@angular/forms'
import { KetPathLangModule } from '@ketshopweb/ui/path-lang'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { KetModalForgotPasswordModule } from '@ketshopweb/ui/modal-forgot-password'

@NgModule({
  declarations: [KetModalLoginComponent],
  entryComponents: [KetModalLoginComponent],
  imports: [
    CommonModule,
    NzMessageModule,
    NzModalModule,
    RouterModule,
    NzInputModule,
    KetTranslateModule,
    NzButtonModule,
    FormsModule,
    KetPathLangModule,
    NzIconModule,
    KetModalForgotPasswordModule,
  ],
  exports: [KetModalLoginComponent],
})
export class KetModalLoginModule {}
