import { Directive, ElementRef, OnInit, Input, Renderer2, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core'
import { KetTranslateService } from './translate.service'

@Directive({
  selector: '[ket-translate]',
})
export class KetTranslateDirective implements OnInit, AfterViewInit, OnChanges {
  constructor(
    private el: ElementRef,
    private render: Renderer2,
    private Service_Translate: KetTranslateService,
  ) {}

  @Input('ket-translate') ketTranslate: string

  text = ''

  ngOnInit() {}

  ngAfterViewInit() {
    this.setText()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ketTranslate) {
      if (changes.ketTranslate.previousValue !== undefined) {
        this.ketTranslate = changes.ketTranslate.currentValue
        this.setText()
      }
    }
  }

  setText() {
    this.text = this.el.nativeElement.innerText
    if (!this.text) {
      this.text = this.el.nativeElement.innerHTML
    }
    if (!this.text) {
      this.text = this.el.nativeElement.text
    }
    //console.log(this.text)
    this.el.nativeElement.innerText = ''
    this.Service_Translate.translateAsync(this.text, this.ketTranslate).then((text) => {
      this.el.nativeElement.textContent = text
    })
    //console.log(text)
    // this.render.createText(text);
  }
}
