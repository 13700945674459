import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { KetOrdersComponent } from './ket-orders.component'
import { KetMultipleCheckboxModule } from '@ketshopweb/ui/multiple-checkbox'

import { NzTableModule } from 'ng-zorro-antd/table'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzTagModule } from 'ng-zorro-antd/tag'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'
import { FormsModule } from '@angular/forms'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzPopoverModule } from 'ng-zorro-antd/popover'
import { NzStepsModule } from 'ng-zorro-antd/steps'
import { NzMessageModule } from 'ng-zorro-antd/message'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker'
import { NzDrawerModule } from 'ng-zorro-antd/drawer'
import { NzPaginationModule } from 'ng-zorro-antd/pagination'
import { KetOrderLabelModule } from '../order-label'
import { KetChannelsSelectorModule } from '../channels-selector'
import { KetPermissionModule } from '@ketshopweb/ui/permission'
import { NzModalModule } from 'ng-zorro-antd/modal'
import { NzBadgeModule } from 'ng-zorro-antd/badge'
import { NzAvatarModule } from 'ng-zorro-antd'
import { KetAssigneeSelectorModule } from '@ketshopweb/ui/assignee-selector'
import { KetOrderTagsSelectorModule } from '@ketshopweb/ui/order-tags-selector'

@NgModule({
  declarations: [KetOrdersComponent],
  entryComponents: [KetOrdersComponent],
  imports: [
    CommonModule,
    FormsModule,
    // Ket
    KetMultipleCheckboxModule,
    KetOrderLabelModule,
    KetChannelsSelectorModule,
    KetPermissionModule,
    KetAssigneeSelectorModule,
    KetOrderTagsSelectorModule,

    // AntD
    NzTableModule,
    NzButtonModule,
    NzTagModule,
    NzToolTipModule,
    NzIconModule,
    NzPopoverModule,
    NzStepsModule,
    NzMessageModule,
    NzAvatarModule,
    NzInputModule,
    NzCheckboxModule,
    NzSelectModule,
    NzDatePickerModule,
    NzDrawerModule,
    NzPaginationModule,
    NzModalModule,
    NzBadgeModule,
  ],
  exports: [KetOrdersComponent],
})
export class KetOrdersModule {}
