import { h, Fragment } from 'preact'
import { IPurchaseOrderHtml } from './types'
import * as moment from 'moment'
import { isEmpty, isObject } from 'lodash'

export const TmpOrderHeader = ({ order, qr_ordercode }: { order: IPurchaseOrderHtml.Order; qr_ordercode: string }) => {
  function getMarketplaceOrder(str) {
    const words = str.split('_')
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    return capitalizedWords.join(' ')
  }

  return (
    <Fragment>
      <div class="order-header-wrapper">
        <div class="order-header-qrcode">
          <img class="order-header-qrcode-image" src={qr_ordercode} />
        </div>
        <div class="order-header-detail">
          <div class="grid-2column">
            <div>
              <span class="text-title">หมายเลขออเดอร์ / Order No. {order.ordercode}</span>
            </div>
            {isObject(order.marketplace_order) && !isEmpty(order.marketplace_order.id) && (
              <div>
                <span class="text-subtitle">
                  เลขออเดอร์ {getMarketplaceOrder(order.channel)} : {order.marketplace_order.id}
                </span>
              </div>
            )}
          </div>
          <div class="grid-2column">
            <div>วันที่สั่งซื้อ / Order Date : {moment(order.created_at).format('YYYY-MM-DD, HH:mm:ss')}</div>
            <div>สถานะ / Status : {order.status_name}</div>
          </div>
          <div class="grid-2column">
            <div class="text-truncate">เลขพัสดุ / Track Code : {order.trackcode || '-'}</div>
            <div>ช่องทางการขาย / Channel : {order.channel}</div>
          </div>
          <div class="grid-2column">
            <div>การชําระเงิน / Payment : {isObject(order.paymenttype) ? order.paymenttype.name : '-'}</div>
            <div class="text-truncate">อีเมล / Email : {order.customer ? order.customer.username : '-'}</div>
          </div>
          <div class="grid-2column">
            <div>
              ขนาดกล่อง : {order.box_name || '-'} {order.box_value || '-'}
            </div>
            {order.shipping_group_id > 0 && isObject(order.shipping_group) && (
              <div>Batch : {order.shipping_group.shipping_group_name}</div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  )
}
