import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core'
import { AuthenService } from '@cms/services/authen.service'
import { KetshopwebCustomerService } from '@cms/services/ketshopweb-customer.service'
import { UtilService } from '@cms/services/util.service'
import { Store } from '@ngrx/store'
import { NgStore } from '@store/ketshopweb-store'
import { selectors_features } from 'libs/ketshopweb-store/+state/features'
import { NzMessageService, NzModalService } from 'ng-zorro-antd'
import { KetConsentPopupComponent } from '../consent-popup'
import { KetModalLoginComponent } from '../modal-login'
import * as jwt_decode from 'jwt-decode'
import { CustomerService } from '@cms/services/customer.service'
import { Router } from '@angular/router'
import { KetPathLangPipe, KetPathLangService } from '../path-lang'

declare let fbq: any
@Component({
  selector: 'ket-wishlist-button',
  templateUrl: './wishlist-button.component.html',
  styleUrls: ['./wishlist-button.component.scss'],
})
export class KetWishlistButtonComponent implements OnInit {
  constructor(
    private authenService: AuthenService,
    private customerService: CustomerService,
    private utilService: UtilService,
    private ketshopwebCustomerService: KetshopwebCustomerService,
    private nzMessageService: NzMessageService,
    private nzModalService: NzModalService,
    private router: Router,
    private ketPathLangPipe: KetPathLangPipe,
  ) {}

  @Input() renderType: 'frontend' | 'backend' = 'frontend'
  @Input() product: {
    id: number
    wishlist: null | {
      id: number
    }
  } = {
    id: 0,
    wishlist: null,
  }
  @Output() toggleEvent = new EventEmitter()
  is_login = false
  is_loading = false

  public onDestroy(): void {}

  async ngOnInit() {
    this.is_login = this.authenService.getAuthenToken() ? true : false
    console.log('this.is_login', this.is_login)
  }

  click() {
    if (this.is_login) {
      this.toggleStatus()
    } else {
      const path = this.ketPathLangPipe.transform(
        'logon',
        '',
        this.renderType == 'backend' ? '/system/front/pages' : '',
      )
      this.router.navigate([path], {
        queryParams: {
          redirect_path: this.router.url,
        },
      })
      // this.openLoginModal()
    }
  }

  toggleStatus() {
    if (this.is_loading) {
      return
    }
    this.is_loading = true
    if (this.product.wishlist) {
      this.ketshopwebCustomerService.deleteWishlist(this.product.wishlist.id).subscribe({
        next: () => {
          this.product.wishlist = null
          this.is_loading = false
          this.toggleEvent.emit(null)
        },
        error: (err) => {
          this.is_loading = false
          this.nzMessageService.error(err.error ? err.error.message : err.message || 'เกิดข้อผิดพลาด')
        },
      })
    } else {
      this.ketshopwebCustomerService.createWishlist({ product_id: this.product.id }).subscribe({
        next: (res: any) => {
          this.product.wishlist = res
          this.is_loading = false
          this.toggleEvent.emit(res)
        },
        error: (err) => {
          this.is_loading = false
          this.nzMessageService.error(err.error ? err.error.message : err.message || 'เกิดข้อผิดพลาด')
        },
      })

      const properties:any = {
        currency: "THB",
        content_type: "product",
        contents: [{
          content_id: `${this.product['id']}`,
          content_name: this.product['title_lang1'],
          value: this.product['price']
        }]
      }
      this.utilService.tiktok_events({
        event_source: "web",
        data: [{
          event: "AddToWishlist",
          page: {
            url: location.href
          },
          properties: properties
        }]
      }).catch((err)=>{console.log(err)})
    }
  }

  // openLoginModal() {
  //   console.log('1')
  //   const modal = this.nzModalService
  //     .create({
  //       nzTitle: null,
  //       nzContent: KetModalLoginComponent,
  //       nzClassName: 'color_render_wrap modal-small-s',
  //       nzWrapClassName: 'vertical-center-modal',
  //       nzBodyStyle: {
  //         padding: '30px 15px 15px',
  //       },
  //       nzWidth: '90%',
  //       nzFooter: null,
  //       nzKeyboard: false,
  //       nzMaskClosable: false,
  //       nzComponentParams: {
  //         data: {},
  //       },
  //     })
  //     .afterClose.subscribe(async (result) => {
  //       modal.unsubscribe()
  //       console.log(result)
  //       if (result) {
  //         await this.waitForConsent(result.data, result.me)
  //       }
  //     })
  // }

  async waitForConsent(
    access_token: {
      access_token: string
      refesh_token: string
    },
    me: any,
  ): Promise<{
    consent_terms_privacy_policy: boolean
    consent_conditions_news: boolean
  }> {
    return new Promise((resolve, reject) => {
      const consentModal = this.nzModalService.create({
        nzTitle: null,
        nzFooter: null,
        nzContent: KetConsentPopupComponent,
        nzClosable: false,
        nzWidth: '350px',
        nzBodyStyle: {
          padding: '1rem',
        },
        nzWrapClassName: 'vertical-center-modal',
        nzComponentParams: {
          type: me.is_deletion ? 'welcome' : 'consent_login',
        },
      })
      const _m = consentModal.afterClose.subscribe(async (res: any) => {
        _m.unsubscribe()
        if (res) {
          if (!res.consent_terms_privacy_policy) {
            this.nzMessageService.create('error', `กรุณายอมรับข้อกำหนดและเงื่อนไขการใช้งาน`)
            return
          }
          try {
            const token = jwt_decode(access_token.access_token)
            if (token.register) {
              setTimeout(() => {
                const custom_data: any = {}
                const fb_event_data: any = {}
                if (this.utilService.getCookie('_fbp')) {
                  fb_event_data['fbp'] = this.utilService.getCookie('_fbp')
                }
                if (this.utilService.getCookie('_fbc')) {
                  fb_event_data['fbc'] = this.utilService.getCookie('_fbc')
                }
                this.utilService
                  .fb_events({
                    ...fb_event_data,
                    action_source: 'website',
                    events: [
                      {
                        event_name: 'CompleteRegistration',
                        event_url: location.href,
                        custom_data: custom_data,
                      },
                    ],
                  })
                  .then(() => {})
                  .catch(() => {})
                fbq('track', 'CompleteRegistration')

                this.utilService.tiktok_events({
                  event_source: "web",
                  data: [{
                    event: "CompleteRegistration",
                    page: {
                      url: location.href
                    }
                  }]
                }).catch(() => {})

              }, 1200)
            }
          } catch (e) {}
          await this.setLogin(access_token, res.consent_terms_privacy_policy, res.consent_conditions_news)
          resolve({
            consent_terms_privacy_policy: res.consent_terms_privacy_policy,
            consent_conditions_news: res.consent_conditions_news,
          })
        } else {
          resolve({
            consent_terms_privacy_policy: false,
            consent_conditions_news: false,
          })
        }
      })
    })
  }

  async setLogin(
    access_token: {
      access_token: string
      refesh_token: string
    },
    consent_terms_privacy_policy: boolean,
    consent_conditions_news: boolean,
  ) {
    this.authenService.setTokenClient(access_token)
    await this.customerService.updateProfile({
      consent_terms_privacy_policy: consent_terms_privacy_policy,
      consent_conditions_news: consent_conditions_news,
      is_deletion: 0,
      deletion_at: null,
    })
  }
}
