import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetMultipleCheckboxComponent } from './multiple-checkbox.component'
import { NzPopoverModule } from 'ng-zorro-antd/popover'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox'
import { FormsModule } from '@angular/forms'
import { NzIconModule } from 'ng-zorro-antd/icon'

@NgModule({
  declarations: [KetMultipleCheckboxComponent],
  imports: [CommonModule, FormsModule, NzPopoverModule, NzButtonModule, NzCheckboxModule, NzIconModule],
  exports: [KetMultipleCheckboxComponent],
})
export class KetMultipleCheckboxModule {}
