import { SafeAny } from '@ketshopweb/ui/core/types'

export namespace IWarehouseState {
  export enum WarehouseTransfer {
    Transfer = 'transfer',
    Receive = 'receive',
    Withdraw = 'withdraw',
    Adjust = 'adjust',
  }

  export interface CreateWarehouseTransfer {
    warehouse_sender: number
    warehouse_receiver: number
    type: WarehouseTransfer
    note: string
    products: {
      product_id: number
      price: number
      amount: number
      type: string
    }[]
  }

  export interface WarehouseState {
    is_loading: boolean
    list: {
      account_based_on: null | WarehouseAccountBasedOn
      type: null | WarehouseType
      full_address: string | null
      id: number
      name: string
      status: 1 | 0
      pos: {
        serial_number: string
        status: 1 | 0
        pos_id: string
      }[]
      branch_code: string
      org_name: string
      tax_no: string
      org_phone: string
      pin?: string
      subdistrict?: string
      district?: string
      province?: string
      zipcode?: string
      bill_prefix?: string
      tax_request_qr: boolean
      tel: string
    }[]
    info: {
      status: boolean
      logo_status: boolean
      logo: string
      org_name: string
      org_address: string
      tax_no: string
      org_phone: string
      org_tax_phone: string
      receipt: string
      province: string
      district: string
      subdistrict: string
      zipcode: string
    }
    warehouse_transfer: {
      is_loading: boolean
      list: {
        data: WarehouseTransferItem[]
        count: number
      }
      search_model: ListWarehouseTransferModel
    }
    select_list: {
      [key: string]: {
        // unique name
        data: WarehouseProductItem[]
        apply_data: WarehouseProductItem[]
      }
    }
    product_search_model: {
      [key: string]: WarehouseProductSearchModel
    }
    warehouse_product: {
      [key: string]: {
        data: WarehouseProductItem[]
        count: number
      }
    }
    is_loading_warehouse_product: {
      [key: string]: boolean
    }
    warehouse_inventory: {
      data: IInventoryItem[]
      count: number
    }
    warehouse_inventory_search_model: IInventorySearchModel
    warehouse_inventory_loading: boolean
  }

  export interface WarehouseTransferItem {
    id: number
    warehouse_sender: number
    warehouse_receiver: number
    type: string
    is_print: boolean
    status: number
    transfer_code: string
    note: string
    created_at: string
    updated_at: string
    created_by: string
    updated_by: string
    warehouse: WarehouseTransferSenderReceiver
  }

  export interface WarehouseTransferSenderReceiver {
    receiver: WarehouseTransferReceiver
    sender: WarehouseTransferSender
  }

  export interface WarehouseTransferReceiver {
    id: number
    name: string
  }

  export interface WarehouseTransferSender {
    id: number
    name: string
  }

  export interface ListWarehouseTransferModel {
    page: number
    per_page: number
    warehouse_id: number | ''
    type: WarehouseTransfer | ''
    status: 0 | 1 | -1 | ''
    transfer_code: string | ''
    product_id?: number
  }

  export interface WarehouseProductSearchModel {
    warehouse_id: number
    search: string
    page: number
    page_size: number
    sort: string
    source_warehouse: number | null
    type: string
  }

  export interface WarehouseProductItem {
    detail_set?: SafeAny[]
    barcode: string
    cate_id: number
    feature_img: string
    id: number
    instock: number
    permalink_lang1: string
    permalink_lang2: string
    permalink_lang3: string
    permalink_lang4: string
    price: number
    prop_enable: number
    properties_desc: string
    properties_desc2: string
    property_info: string
    property_info2: string
    size_height: number
    size_length: number
    size_width: number
    sku: string
    status: number
    temp_price: number
    title_lang1: string
    title_lang2: string
    title_lang3: string
    title_lang4: string
    weight: number
    selected?: boolean
    amount?: number
    product_type: string
    detail?: SafeAny[]
    product_set?: SafeAny[]
  }

  export enum IInventorySearchSort {
    Id = 'id',
    TotalsAll = 'totals_all',
    TotalsAvailable = 'totals_all_available',
    TotalsReserved = 'totals_all_reserved',
    TotalsWaitingTransfer = 'totals_all_waiting_transfer',
  }

  export interface IInventorySearchModel {
    warehouse_id: string | number
    channels: string[]
    channel_id: string | number
    status: string | number
    category_id: string | number
    search: string
    page: number
    per_page: number
    sort: 'ASC' | 'DESC'
    sort_by: IInventorySearchSort
    warehouse_show_type?: 'all' | 'single'
    type: 'normal' | 'set' | ''
  }

  export interface IInventoryPosItem {
    id: number
    title_lang1: string
    sku: string
    prop_enable: number
    status: number
    property_info: string
    property_info2: string
    properties_desc: string
    properties_desc2: string
    feature_img: string
    available: number
    waiting_transfer: number
    reserved: number
    totals: number
    mkp_totals: number
    mkp_available: number
    mkp_reserved: number
    wh_available: number
    wh_reserved: number
    wh_waiting_transfer: number
    wh_totals: number
    totals_all_available: number
    totals_all_reserved: number
    totals_all_waiting_transfer: number
    totals_all: number
    sold: number
    product_type: string
    marketplace_inventory: {
      id: number
      ket_product_id: number
      totals: number
      available: number
      reserved: number
      sku: string
      channel_name: string
      channel: string
      channel_id: string
      channel_inventory_id: string
      channel_product_id: string
      channel_logo: string
      sold: number
    }[]
    warehouse: {
      product_id: number
      warehouse_id: number
      available: number
      waiting_transfer: number
      reserved: number
      totals: number
      price: number
      sold: number
      warehouse_info: {
        id: number
        name: string
        status: number
      }
      product_set?: WarehouseProductItem
    }
  }

  export interface IInventoryItem {
    id: number
    title_lang1: string
    sku: string
    prop_enable: number
    status: number
    property_info: string
    property_info2: string
    properties_desc: string
    properties_desc2: string
    feature_img: string
    price: number
    temp_price: number
    properties_main: number
    available: number
    waiting_transfer: number
    reserved: number
    totals: number
    mkp_totals: number
    mkp_available: number
    mkp_reserved: number
    wh_available: number
    wh_reserved: number
    wh_waiting_transfer: number
    wh_totals: number
    totals_all_available: number
    totals_all_reserved: number
    totals_all_waiting_transfer: number
    totals_all: number
    sold: number
    product_type: string
    marketplace_inventory: {
      id: number
      ket_product_id: number
      totals: number
      available: number
      reserved: number
      sku: string
      channel_name: string
      channel: string
      channel_id: string
      channel_inventory_id: string
      channel_product_id: string
      channel_logo: string
      sold: number
    }[]
    warehouse: {
      product_id: number
      warehouse_id: number
      available: number
      waiting_transfer: number
      reserved: number
      totals: number
      price: number
      sold: number
      warehouse_info: {
        id: number
        name: string
        status: number
      }
      product_set?: WarehouseProductItem
    }[]
    product_set?: (Omit<IInventoryItem, 'marketplace_inventory' | 'warehouse' | 'product_set'> & {
      qty: number
      status_properties: number
      product_property: Omit<IInventoryItem, 'marketplace_inventory' | 'warehouse' | 'product_set'>[]
    })[]
  }
  export interface FilterTag {
    type: string
    name: string
    img?: string
    value: any
    is_close: boolean
    onClose?: () => IInventorySearchModel
  }

  export type WarehouseType = 'branch' | 'juristic'
  export type WarehouseAccountBasedOn = 'headequarter' | 'self'

  export interface WarehouseSetProduct {
    id: number
    product_set_id: number
    product_id: number
    qty: number
    status_properties: number
    created_at: string
    created_by: string
    updated_at: string
    updated_by: string
    properties_main: number
    warehouse_id: number
    product_property: {
      id: number
      product_set_id: number
      product_id: number
      qty: number
      title_lang1: string
      sku: string
      prop_enable: number
      status: number
      property_info: string
      property_info2: any
      properties_desc: string
      properties_desc2: any
      feature_img: string
      group_id: string
      available: number
      waiting_transfer: number
      reserved: number
      sold: number
      totals: number
      wh_reserved: number
      wh_sold: number
      wh_waiting_transfer: number
    }[]
  }
}
