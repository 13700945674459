import { h, Fragment } from 'preact'
import { CssGlobal } from './css_global'

export const OrderA4Half = ({ elements }: { elements: ([h.JSX.Element, h.JSX.Element] | [h.JSX.Element])[] }) => (
  <Fragment>
    <CssGlobal />
    {elements.map(([a, b], i) => {
      return (
        <Fragment key={i}>
          <div class="page-a4-landscape">
            <div class="page-a4-half">{a}</div>
            {b && <div class="page-a4-half">{b}</div>}
          </div>
          <div class="pagebreak"></div>
        </Fragment>
      )
    })}
  </Fragment>
)
