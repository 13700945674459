import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core'
import { apiError } from '@cms/interface/interface'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal'
import { UtilService } from '@cms/services/util.service'
import { CustomerService } from '@cms/services/customer.service'
import { cloneDeep } from 'lodash'
import { DeliveryService } from '@cms/services/delivery.service'
import { FeatureService } from '@cms/services/feature.service'
import { SettingService } from '@cms/services/setting.service'
import { KetshopwebCustomerService } from '@cms/services/ketshopweb-customer.service'
import { log } from 'ng-zorro-antd'
import { SafeAny } from '../core/types'

@Component({
  selector: 'ket-modal-edit-customer',
  templateUrl: './modal-edit-customer.component.html',
  styleUrls: ['./modal-edit-customer.component.scss'],
})
export class KetModalEditCustomerComponent implements OnInit {
  page_name = 'member-profile'

  @Input() data: any
  @Input() modal_edit: boolean
  @Input() renderType = 'backend'

  @Output() event_callback = new EventEmitter()

  ProfileForm: FormGroup
  provinceList: any[] = []
  District: any[] = []
  SubDistrict: any[] = []
  countries: { country_name: string; country_alpha2_code: string }[] = []
  feature_shipping_inter = false

  model: any = {
    country_name: 'Thailand',
    country_alpha2_code: 'TH',
  }

  render = false
  address_layout = 1
  customer_type = 'member'

  // default member level
  member_level_id = 1
  max_length = 20

  resetpoint = 0
  member_level_status = true

  constructor(
    private fb: FormBuilder,
    private modal: NzModalRef,
    private _utilSvc: UtilService,
    private _customerSvc: CustomerService,
    private Service_Delivery: DeliveryService,
    private Service_Feature: FeatureService,
    private settingService: SettingService,
    private modalService: NzModalService,
    private Service_Ketshopweb_customer: KetshopwebCustomerService,
  ) {}

  // default member_level
  @Input() member_levels = this.Service_Ketshopweb_customer.defaultListMemberLevelCms()

  @ViewChild('EditPointGuest', { static: true }) public EditPointGuest: TemplateRef<SafeAny>

  async ngOnInit() {
    this.render = false
    console.log('this.data', this.data)
    await this._utilSvc.getProvince().then((res: any[]) => {
      this.provinceList = res
      //console.log(res)
    })
    this.feature_shipping_inter = await this.Service_Feature.getFeature('SHIPPING_INTER')

    if (this.feature_shipping_inter) {
      this.countries = await this.Service_Delivery.getAvailbleCountry()
    } else if (this.data.country_alpha2_code && this.data.country_alpha2_code != 'TH') {
      this.countries = [
        {
          country_name: 'Thailand',
          country_alpha2_code: 'TH',
        },
        {
          country_name: this.data.country_name,
          country_alpha2_code: this.data.country_alpha2_code,
        },
      ]
    } else {
      this.countries = [
        {
          country_name: 'Thailand',
          country_alpha2_code: 'TH',
        },
      ]
    }

    this.onSearchDistrict()
    this.onSearchSubDistrict()

    if (this.data.id !== 'new') {
      let validator_email = []
      if (this.data.type == 'member') {
        validator_email = [Validators.email, Validators.required]
      }
      if (this.data.user_member_level) {
        this.member_level_id = this.data.user_member_level.member_level_id || 1
      }
      this.ProfileForm = this.fb.group({
        type: new FormControl({ value: this.data.type, disabled: false }),
        address1: new FormControl({ value: this.data.address1, disabled: false }),
        district: new FormControl({ value: this.data.district, disabled: false }, Validators.required),
        lastname: new FormControl({ value: this.data.lastname, disabled: false }),
        name: new FormControl({ value: this.data.name, disabled: false }, Validators.required),
        province: new FormControl({ value: this.data.province, disabled: false }, Validators.required),
        status: new FormControl({ value: this.data.status, disabled: false }, Validators.required),
        level: new FormControl(
          { value: this.data.type == 'member' ? this.member_level_id : 0, disabled: false },
          Validators.required,
        ),
        subdistrict: new FormControl({ value: this.data.subdistrict, disabled: false }, Validators.required),
        tel: new FormControl({ value: this.data.tel, disabled: false }),
        username: new FormControl({ value: this.data.username, disabled: false }, validator_email),
        zipcode: new FormControl({ value: this.data.zipcode, disabled: false }, Validators.required),
        country_name: new FormControl({ value: this.data.country_name, disabled: false }, Validators.required),
        tax_no: new FormControl({ value: this.data.tax_no, disabled: false }),
        birth_date: new FormControl({ value: this.data.birth_date, disabled: false }),
      })
      this.model.country_name = this.data.country_name
      if (this.model.country_name == 'Thailand') {
        this.max_length = 13
      }
      this.model.country_alpha2_code = this.data.country_alpha2_code
    } else {
      this.ProfileForm = this.fb.group({
        type: new FormControl({ value: 'member', disabled: false }),
        address1: new FormControl({ value: '', disabled: false }, Validators.required),
        district: new FormControl({ value: '', disabled: false }, Validators.required),
        lastname: new FormControl({ value: '', disabled: false }),
        name: new FormControl({ value: '', disabled: false }, Validators.required),
        province: new FormControl({ value: 'กรุงเทพมหานคร', disabled: false }, Validators.required),
        status: new FormControl({ value: 1, disabled: true }, Validators.required),
        level: new FormControl(
          { value: this.data.type == 'member' ? this.member_level_id : 0, disabled: false },
          Validators.required,
        ),
        subdistrict: new FormControl({ value: '', disabled: false }, Validators.required),
        tel: new FormControl({ value: '', disabled: false }, Validators.required),
        username: new FormControl({ value: '', disabled: false }, [Validators.email, Validators.required]),
        zipcode: new FormControl({ value: '', disabled: false }, Validators.required),
        country_name: new FormControl({ value: 'Thailand', disabled: false }, Validators.required),
        tax_no: new FormControl({ value: this.data.tax_no, disabled: false }),
        birth_date: new FormControl({ value: '', disabled: false }),
      })
    }
    this.settingService
      .searchOption({
        pop_key: ['address_layout', 'member_level_status'],
      })
      .then((res: any[]) => {
        for (const obj of res) {
          if (obj.pop_key == 'address_layout') {
            if (!obj.pop_value) obj.pop_value = '1'
            this.address_layout = parseInt(obj.pop_value)
            if (this.address_layout == 2) {
              this.ProfileForm.get('province').disable()
              this.ProfileForm.get('district').disable()
              this.ProfileForm.get('subdistrict').disable()
              this.ProfileForm.get('zipcode').disable()
            }
          }
          if (obj.pop_key == 'member_level_status') {
            this.member_level_status = Boolean(Number(obj.pop_value))
          }
        }
      })
      .catch((err) => console.error(err))
    this.member_levels = this.member_levels.flatMap((x) => (x.id > 0 ? [x] : []))
    this.render = true
    // console.log(this.member_levels)
  }

  onMemberTypeChange(event: string) {
    console.log('onMemberTypeChange', event)
    const key = 'username'
    if (this.ProfileForm.get('type').value == 'guest') {
      // this.ProfileForm.controls['username'].reset()
      const value = this.ProfileForm.controls[key].value
      this.ProfileForm.controls[key].reset()
      this.ProfileForm.controls[key].setValue(value)
    } else {
      if (!this.ProfileForm.get(key).errors) {
        const value = this.ProfileForm.controls[key].value
        this.ProfileForm.controls[key].reset()
        this.ProfileForm.controls[key].setValidators([Validators.email, Validators.required])
        this.ProfileForm.controls[key].setValue(value)
      }
    }
  }
  onUernameChange(event: string) {
    if (this.ProfileForm.get('type').value == 'guest') {
      const key = 'username'
      const value = this.ProfileForm.controls[key].value
      console.log('onUernameChange', this.ProfileForm.controls[key].errors)
      if (!value && this.ProfileForm.controls[key].errors) {
        this.ProfileForm.controls[key].clearValidators()
      } else if (value && !this.ProfileForm.controls[key].errors) {
        this.ProfileForm.controls[key].setValidators([Validators.email])
      }
    }
  }

  async onSave() {
    const m = cloneDeep(this.ProfileForm.value)

    // console.log(m)
    // console.log(!this.ProfileForm.valid)
    // console.log(this.ProfileForm)

    if (this.ProfileForm.get('level').value != 0) {
      this.ProfileForm.controls['username'].setValidators([Validators.email, Validators.required])
    } else {
      // ลบ validator สำหรับฟิลด์ username ถ้าไม่ใช่ member
      this.ProfileForm.controls['username'].clearValidators()
    }

    this.ProfileForm.controls['username'].updateValueAndValidity()

    if (!this.ProfileForm.valid) {
      for (const key in this.ProfileForm.controls) {
        try {
          if (this.ProfileForm.get('type').value == 'guest' && key == 'username') {
            if (this.ProfileForm.get('username').value) {
              this.ProfileForm.controls[key].markAsDirty()
              this.ProfileForm.controls[key].markAsTouched()
              this.ProfileForm.controls[key].markAsUntouched()
            } else {
              // console.log('continue key', key)
              // set error to null
              const value = this.ProfileForm.controls[key].value
              this.ProfileForm.controls[key].reset()
              this.ProfileForm.controls[key].setValue(value)
              continue
            }
          }
          if (key == 'username' && this.ProfileForm.get('type').value == 'member') {
            if (!this.ProfileForm.get('username').errors) {
              this.ProfileForm.controls[key].setValidators([Validators.email, Validators.required])
            }
          }
          if (!this.ProfileForm.controls[key].valid) {
            this.ProfileForm.controls[key].markAsDirty()
            this.ProfileForm.controls[key].markAsTouched()
            this.ProfileForm.controls[key].markAsUntouched()
          }
        } catch (err) {}
      }
      if ((!m.subdistrict || !m.district) && m.country_name == 'Thailand') {
        return
      } else if ((!m.subdistrict || !m.district) && m.country_name != 'Thailand') {
      } else {
        return
      }
    }

    for (const key in this.ProfileForm.controls) {
      this.ProfileForm.value[key] = this.ProfileForm.controls[key].value
    }
    if (!this.ProfileForm.value['lastname']) {
      this.ProfileForm.value['lastname'] = ' '
    }

    if (this.ProfileForm.get('type').value == 'guest' && this.ProfileForm.get('level').value == 1) {
      const modal = this.modalService.create({
        nzContent: this.EditPointGuest,
        nzStyle: { top: '100px' },
        nzWidth: '700px',
        nzTitle: 'ปรับแต้มสะสมคงเหลือปัจจุบัน',
        nzCancelText: null,
        nzKeyboard: false,
        nzClosable: false, // ปิดการแสดงปุ่ม close ที่มุมขวาบน
        nzMaskClosable: false, // ปิดการปิด modal ผ่านการคลิกนอก modal
        nzOnOk: async () => {
          await this.onSaveProfile()
          modal.close()
          return false
        },
        nzComponentParams: {
          data: {},
        },
      })
    } else {
      this.onSaveProfile()
      return false
    }
  }

  onSaveProfile() {
    $('.theme-loader').fadeIn()
    if (this.data.id !== 'new') {
      // member_level
      if (
        this.member_level_id != this.ProfileForm.value['level'] ||
        (this.ProfileForm.value['level'] == 1 && this.ProfileForm.get('type').value == 'guest')
      ) {
        this.Service_Ketshopweb_customer.setUserMemberLevel({
          user_id: this.data.id,
          member_level_id: Number(this.ProfileForm.value['level']) || 1,
        })
          .then((resp) => {
            console.log(resp)
          })
          .catch((err) => {
            console.error(err)
          })
      }

      const model = cloneDeep(this.ProfileForm.value)
      model['id'] = this.data.id
      model['first_name'] = model.name
      model['last_name'] = model.lastname
      model['address'] = model.address1
      model['country_name'] = this.model.country_name
      model['resetpoint'] = this.resetpoint
      model['country_alpha2_code'] = this.countries.find(
        (x) => x.country_name == model['country_name'],
      ).country_alpha2_code
      model['email'] = model.username
      this._customerSvc
        .update(model)
        .then((res: any) => {
          this.modal.close(res)
          $('.theme-loader').fadeOut()
        })
        .catch((err: apiError) => {
          $('.theme-loader').fadeOut()
          console.log(err)
          this.modalService.error({
            nzTitle: `${err.error.message}`,
          })
          // alert(err.error.message)
        })

      return
    } else {
      const model = cloneDeep(this.ProfileForm.value)
      model['first_name'] = model.name
      model['last_name'] = model.lastname
      model['email'] = model.username
      model['address'] = model.address1
      model['country_name'] = this.model.country_name
      model['country_alpha2_code'] = this.countries.find(
        (x) => x.country_name == model['country_name'],
      ).country_alpha2_code
      this._customerSvc
        .create(model)
        .then((res: any) => {
          this.modal.close(res)
          $('.theme-loader').fadeOut()
        })
        .catch((err: apiError) => {
          $('.theme-loader').fadeOut()
          console.log(err)
          this.modalService.error({
            nzTitle: `${err.error.message}`,
          })
          // alert(err.error.message)
        })
      return
    }
  }

  onSearchDistrict(district = '') {
    console.log('onSearchDistrict', district)
    this._utilSvc
      .getDistrict({
        name: district,
        limit: 15,
      })
      .then((res: any[]) => {
        this.District = res
        //console.log(res)
      })
  }

  onSearchSubDistrict(subdistrict = '') {
    this._utilSvc
      .getSubDistrict({
        name: subdistrict,
        limit: 15,
      })
      .then((res: any[]) => {
        this.SubDistrict = res
        //console.log(res)
      })
  }

  handleCancel() {
    this.closeModal()
  }

  ok() {
    this.onSave()
    return false
  }

  closeModal(e = false) {
    this.modal_edit = false
    this.event_callback.emit(e)
  }

  onCountryChange() {
    this.model.country_name = this.ProfileForm.get('country_name').value
    const tel = this.ProfileForm.get('tel').value
    console.log('tel', tel)
    this.model.country_alpha2_code = this.countries.find(
      (x) => x.country_name == this.model.country_name,
    ).country_alpha2_code
    if (this.model.country_alpha2_code == 'TH') {
      this.ProfileForm.get('subdistrict').setValue('')
      this.ProfileForm.get('district').setValue('')
      this.ProfileForm.get('province').setValue('')
      this.ProfileForm.get('zipcode').setValue('')
      if (this.address_layout == 2) {
        this.ProfileForm.get('province').disable()
        this.ProfileForm.get('zipcode').disable()
      }
      if (`${tel}`.length > 10) {
        this.ProfileForm.get('tel').setValue('')
      }
    } else {
      this.ProfileForm.get('subdistrict').setValue('-')
      this.ProfileForm.get('district').setValue('-')
      this.ProfileForm.get('province').setValue('')
      this.ProfileForm.get('zipcode').setValue('')
      if (this.address_layout == 2) {
        this.ProfileForm.get('province').enable()
        this.ProfileForm.get('zipcode').enable()
      }
    }
  }

  addressChange(data: any) {
    const subdistrict = data ? data.district : ''
    const district = data ? data.city : ''
    const zipcode = data ? data.zipcode : ''
    const province = data ? data.province : ''

    this.ProfileForm.get('subdistrict').setValue(subdistrict)
    this.ProfileForm.get('district').setValue(district)
    this.ProfileForm.get('province').setValue(province)
    this.ProfileForm.get('zipcode').setValue(zipcode)

    this.onSearchSubDistrict(subdistrict)
    this.onSearchDistrict(district)
  }
}
