/* eslint-disable @typescript-eslint/no-var-requires */
import { Component, Inject, AfterViewInit, OnDestroy } from '@angular/core'
import { Meta } from '@angular/platform-browser'
import { CookieService } from 'ngx-cookie'
import { UtilService } from './services/util.service'
import { apiError } from './interface/interface'
import { Store, select } from '@ngrx/store'
import { SET_PAGE_LINK, SET_LANG_FORMAT } from './store/action'
import { AuthenService } from './services/authen.service'
import { PageOption, SettingService } from './services/setting.service'
// import { FeatureService } from './services/feature.service';
import { environment } from 'src/environments/environment'
import { Etc } from '@cms/util/etc'
import * as jwt_decode from 'jwt-decode'
import * as moment from 'moment'
import { ActivatedRoute, Router } from '@angular/router'
import { pathLangPipe } from './pipes/path-lang.pipe'
import { NgCoreService } from './services/ng-core.service'
import { DOCUMENT } from '@angular/common'
import { KetshopwebFrontendService } from './services/ketshopweb-frontend.service'
import { UserAgentService } from './services/user-agent.service'
import { HttpClient } from '@angular/common/http'
import { PluginsService } from './services/plugins.service'
import { actions_app, actions_client_user, actions_products } from '@store/ketshopweb-store'
// import * as  from 'web-vitals';
const WebVitals = require('web-vitals')
declare let fbq: any, ga: any, gtag: any, window: any
// import * as Sentry from '@sentry/angular'
import { actions_features } from 'libs/ketshopweb-store/+state/features'
import { actions_page_options } from 'libs/ketshopweb-store/+state/page_options'
import { Subject } from 'rxjs'
import { merge } from 'lodash'
import { CustomerService } from './services/customer.service'
import { KetshopwebSDK } from 'ketshopweb-sdk'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements AfterViewInit, OnDestroy {
  initPage = false
  fbChatPluginSubject = new Subject()
  _subscribe: any = null
  cart_subscribe = KetshopwebSDK.cart('customer').subscribe()

  pluginInit(js_app: string[]) {
    js_app.forEach((js) => {
      if (/(\.js|\.tsx)/g.test(js)) {
        const script = document.createElement('script')
        script.type = 'module'
        script.src = js
        document.body.appendChild(script)
        script.onload = () => {
          const plugins = this.pluginsService.getPluginsByType('data/share-plugin-data')

          for (let i = 0; i < plugins.length; i++) {
            const ele = document.createElement(plugins[i].component)
            document.body.appendChild(ele)
          }
        }
      } else {
        const script = document.createElement('script')
        script.attributes.setNamedItem(document.createAttribute('nomodule'))
        script.src = js
        document.body.appendChild(script)
      }
    })
  }

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _cookie: CookieService,
    private _util: UtilService,
    private _authen: AuthenService,
    private store: Store<{ app_store: any }>,
    private router: Router,
    private ngCoreService: NgCoreService,
    // private Service_Feature: FeatureService,
    private _etc: Etc,
    private path_lang: pathLangPipe,
    private _meta: Meta,
    private activatedRoute: ActivatedRoute,
    private ketshopwebFrontendService: KetshopwebFrontendService,
    private userAgentService: UserAgentService,
    private cms_http: HttpClient,
    private pluginsService: PluginsService,
    private customerService: CustomerService,
  ) {
    console.log('App Start')
    KetshopwebSDK.init({
      base_api: environment.base_api,
      domain: location.origin,
      cart: {
        local_storage_key: 'CART_STORE',
      },
      auth: {
        token_type: 'auto',
        local_storage_client_key: 'client_access_token',
        local_storage_admin_key: 'access_token',
      },
    })
    if (this.cart_subscribe) {
      this.cart_subscribe.unsubscribe()
    }
    this.cart_subscribe = KetshopwebSDK.cart('customer').subscribe()
    this.cart_subscribe.calculate_loading((isLoading, error) => {
      console.log('isLoading', isLoading)
      // this.isLoading = isLoading
      if (error) {
        this.store.dispatch(
          actions_app.AlertMessage({
            message: error,
            types: 'error',
          }),
        )
      }
    })

    const error_alert = {}
    this.cart_subscribe.coupon((res) => {
      // this.isCodeLoading = res.loading
      // this.coupon = res.coupon_code
      // if (!res.is_error && this.coupon) {
      //   this.Service_GoogleAnalytics.select_promotion({
      //     promotion_name: this.coupon,
      //   })
      // }
      if (res.is_error && res.coupon_code && !res.loading) {
        if (!error_alert[res.coupon_code]) {
          error_alert[res.coupon_code] = 1
          const errMsg = res.error_message ? res.error_message : 'Invalid coupon code.'
          this.store.dispatch(
            actions_app.AlertMessage({
              message: errMsg,
              types: 'error',
            }),
          )
        }
      }
      //  else {
      //   if (this.coupon) {
      //     const errMsg = res.error_message ? res.error_message : 'Invalid coupon code.'
      //     this.nzMessageService.error(errMsg)
      //   }
      // }
      // this.isCodeError = res.is_error
    })
    // Http client for all plugin
    window['cms_http'] = this.cms_http
    for (const main_plugin of this.pluginsService.getMainPlugins()) {
      this.pluginInit(main_plugin.resource.js_app)
    }

    if (!window['fbq']) {
      $(`<!-- Facebook Pixel Code -->
          <script>
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          </script>
          <!-- End Facebook Pixel Code -->`).appendTo('head')
    }

    //  denied pdpa
    window.dataLayer = window.dataLayer || []
    window.gtag = function () {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments)
    }
    gtag('consent', 'default', {
      ad_storage: 'denied',
      analytics_storage: 'granted',
    })

    this.loadPageLink()
    this.fbConversion()
    try {
      window['ket_mode'] = localStorage.getItem('ket_mode') || window['ket_mode']
      if (window['ket_mode'] == 'design') {
        // load 1
        const script1 = document.createElement('script')
        script1.onload = () => {
          // load 2
          const script2 = document.createElement('script')
          script2.onload = () => {}
          script2.src = '/assets/persistence/persistence.store.sql.js'
          document.body.appendChild(script2)

          // load 3
          const script3 = document.createElement('script')
          script3.onload = () => {}
          script3.src = '/assets/persistence/persistence.store.websql.js'
          document.body.appendChild(script3)

          // load 4
          const script4 = document.createElement('script')
          script4.onload = () => {}
          script4.src = '/assets/persistence/persistence.store.memory.js'
          document.body.appendChild(script4)
        }
        script1.src = '/assets/persistence/persistence.js'
        document.body.appendChild(script1)
      }
    } catch (err) {}
    // AOS.init();
    // this.Service_Feature._init();

    if (!environment.beta) {
      console.log = () => {
        return 0
      }

      console.table = () => {
        return 0
      }
    }

    try {
      if (!window['seo']) {
        const { access_token } = JSON.parse(localStorage.getItem('client_access_token'))
        const user = jwt_decode(access_token)
        // Sentry.setUser({
        //   id: user.id,
        //   username: `[${user.user_role}]${user.username}`,
        // })
        if (user.user_role == 'guest') {
          localStorage.removeItem('client_access_token')
        }
      }
    } catch (err) {}

    this.resetCookie()
    this.init()

    // this._util.setFeature({id:1,name:'awef'});
    try {
      const langs: any = JSON.parse(localStorage.getItem('langs'))
      let lang = this._etc.getCurrentLang()
      console.log('lang============ang', lang)
      if (!lang) {
        lang = 'lang1'
      }
      let lang_data = langs.find((x: any) => x.ref == lang)

      if (!lang_data) {
        lang_data = langs.find((x: any) => x.ref == 'lang1')
      }
      if (!lang_data) {
        lang_data = langs.find((x: any) => x.ref == 'lang2')
      }
      if (!lang_data) {
        lang_data = langs.find((x: any) => x.ref == 'lang3')
      }
      if (!lang_data) {
        lang_data = langs.find((x: any) => x.ref == 'lang4')
      }

      if (!lang_data) {
        // localStorage.setItem('lang', "lang1")
        this._etc.setCurrentLang('lang1')
      } else {
        this._etc.setCurrentLang(lang_data.ref)
        // localStorage.setItem('lang', lang_data.ref)
      }
    } catch (err) {}

    if (!this._authen.isTokenExpired()) {
      this._authen.setClientProfile()
    }

    this.ketshopwebFrontendService
      .searchOption({
        pop_key: ['theme_value', 'default_no_image', 'favicon_icon', 'fb_chat_plugin'],
      })
      .then((res: any[]) => {
        for (const obj of res) {
          if (obj.pop_key == 'fb_chat_plugin') {
            const popValue = obj.pop_value
            merge(this.fb_chat_plugin, JSON.parse(popValue))
            if (this.fb_chat_plugin.enable && this.fb_chat_plugin.html) {
              this.fbChatPluginSubject.next()
            }
            continue
          }

          switch (obj.pop_key) {
            case 'theme_value':
              try {
                const _data = JSON.parse(obj.pop_value)
                //console.log(_data);
                window['_theme_color'] = _data
              } catch (e) {
                eval(`${obj.pop_value} window._theme_color = _theme_color;`)
              }
              break
            case 'default_no_image':
              localStorage.setItem('default_no_image', obj.pop_value)
              break
            case 'favicon_icon':
              try {
                let _uri = ''
                if (!window['_host']) {
                  _uri = window.location.hostname
                } else {
                  _uri = `${window['_host']}`
                }

                this._document.getElementById('appFavicon').setAttribute('href', `https://${_uri}${obj.pop_value}`)

                //$(`<link rel="icon" type="image/x-icon" href="${obj.pop_value}" />`).appendTo('head')
              } catch (err) {}
              break

            default:
              break
          }
        }
      })
      .catch((e: any) => {
        //console.log(e.message)
      })
  }

  fbConversion() {
    const data: any = {}
  }

  resetCookie() {
    this._cookie.remove('pages')
    this._cookie.remove('langs')
  }

  ngOnDestroy(): void {
    if (this.cart_subscribe) {
      this.cart_subscribe.unsubscribe()
    }
    this.fbChatPluginSubject.unsubscribe()
    if (this._subscribe) this._subscribe.unsubscribe()
  }

  fb_chat_plugin: PageOption.FbChatPlugin = {
    enable: false,
    html: null,
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   console.log('this.activatedRoute.snapshot.queryParamMap',this.activatedRoute.snapshot);
    //   // console.log('utm_source',
    //   // .snapshot.queryParamMap.get('utm_source'));
    //   // this.activatedRoute.paramMap.subscribe

    // }, 1000);

    this.activatedRoute.queryParamMap.subscribe((data) => {
      const utm = data.get('utm_source')
      if (data.get('utm_source')) {
        if (utm == 'priceza') {
          this.store.dispatch(actions_app.SetUTM({ val: location.search }))
        }
      }
    })

    const url = window.location.href
    let urlto = ''
    if (url.search(`${window.location.origin}/system`) == -1) {
      urlto = this.path_lang.transform('thankyou')
    }
    this._subscribe = this.store
      .pipe(select('app_store', 'CURRENT_USER_CLIENT'))
      .subscribe((CURRENT_USER_CLIENT: any) => {
        // console.log('res', res)
        if (url.search(`${window.location.origin}/system`) == -1) {
          // console.log('CURRENT_USER_CLIENT', CURRENT_USER_CLIENT)
          if (CURRENT_USER_CLIENT) {
            this.customerService
              .me()
              .then((res: any) => {
                console.log('_subscribe', res)
                this.store.dispatch(actions_client_user.SetMe({ me: res }))
              })
              .catch((err: any) => {})
          }
        }
      })
    this.fbChatPluginSubject.subscribe(() => {
      try {
        if (
          url.search(`${window.location.origin}/system`) == -1 &&
          !url.includes('credit-redirect') &&
          !url.includes('crypto-redirect') &&
          !url.includes(urlto)
        ) {
          const regex = /chatbox\.setAttribute\(\"page_id\",\s\"(.*?)"\)/g
          const founds = regex.exec(this.fb_chat_plugin.html)
          if (founds.length > 1) {
            const fb_js = `<!-- Messenger ปลั๊กอินแชท Code -->
          <div id="fb-root"></div>

          <!-- Your ปลั๊กอินแชท code -->
          <div id="fb-customer-chat" class="fb-customerchat">
          </div>

          <script>
            var chatbox = document.getElementById('fb-customer-chat');
            chatbox.setAttribute("page_id", "${founds[1]}");
            chatbox.setAttribute("attribution", "biz_inbox");
          </script>

          <script>
            window.fbAsyncInit = function () {
              if(typeof FB !== 'undefined') {
                FB.init({
                  xfbml: true,
                  version: 'v15.0'
                });
                FB.Event.subscribe('customerchat.load', () => {
                  (function (d, s, id) {
                    var js, fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) return;
                    js = d.createElement(s); js.id = id;
                    js.src = 'https://connect.facebook.net/th_TH/sdk/xfbml.customerchat.js';
                    fjs.parentNode.insertBefore(js, fjs);
                  }(document, 'script', 'facebook-jssdk'));
                });
              }
            };
          </script>`
            $('body').append(fb_js)
            setTimeout(() => {
              try {
                window['fbAsyncInit']()
              } catch (err) {
                console.error(err)
              }
            }, 100)
          }
        }
      } catch (err) {
        console.error(err)
      }
    })

    setTimeout(() => {
      const url = window.location.href
      const urlto = this.path_lang.transform('thankyou')
      if (
        url.search(`${window.location.origin}/system`) == -1 &&
        !url.includes('credit-redirect') &&
        !url.includes('crypto-redirect') &&
        !url.includes('order-page') &&
        !url.includes('orderlink') &&
        !url.includes(urlto)
      ) {
        const lastorder = localStorage.getItem('last_order')
        if (lastorder) {
          this.router.navigate([`${urlto}`])
        }
      }
    }, 1500)
    if (
      window.location.href.includes('/system') ||
      window.location.href.includes('/orderlink/') ||
      window.location.href.includes('/order-page/') ||
      window.location.href.includes('/confirmregister') ||
      window.location.href.includes('/forgotpassword') ||
      window.location.href.includes('/crypto-redirect') ||
      window.location.href.includes('/credit-redirect')
    ) {
      this.ngCoreService.zoneIdleCheck()
    }
  }

  async init() {
    /// App init
    if (!window['seo']) {
      const url = window.location.href
      if (url.search(`${window.location.origin}/system`) == -1) {
        $('body').addClass('frontend')
        $('body').removeClass('backend')
        /*
          const sendToAnalytics = (metric: any) =>{
            const body = JSON.stringify(metric);
            if(navigator.sendBeacon){
              console.log('metric', metric)
              let sendBeacon = navigator.sendBeacon('/analytics', body)
              console.log('sendBeacon', sendBeacon)
            }
          }
          WebVitals.onCLS(sendToAnalytics)
          WebVitals.onFCP(sendToAnalytics)
          WebVitals.onFID(sendToAnalytics)
          WebVitals.onINP(sendToAnalytics)
          WebVitals.onLCP(sendToAnalytics)
          WebVitals.onTTFB(sendToAnalytics)
          */
      } else {
        $('body').addClass('backend')
        $('body').removeClass('frontend')
      }
    }

    try {
      if (!window['seo']) {
        window['timemachine'].tick = true
        const _time: any = await this._util.getDateTime()
        // console.log(_time)
        let date_string = moment(_time.date_time).add(5, 's').toString()
        date_string = date_string.replace(/GMT(.*)[0-9]/, 'GMT+0700')
        window['timemachine'].config({
          dateString: `${date_string} (Indochina Time)`,
        })
      }
      // s
    } catch (err) {}

    try {
      await this.getLangFormat()
    } catch (err) {}

    if (!window['seo']) {
      const url = window.location.href
      if (url.search(`${window.location.origin}/system`) == -1) {
        this.loadConversionScript()
      }
    }

    //actions_products.InitFlashSale
    this.store.dispatch(actions_products.InitFlashSale())
    this.store.dispatch(actions_features.LoadFeatures())
    this.store.dispatch(actions_page_options.LoadPageOptions())
  }

  getLangFormat() {
    return new Promise((resolve, reject) => {
      this._util
        .getAllLangFormat()
        .then((res: any) => {
          this.store.dispatch(new SET_LANG_FORMAT(res))
          resolve(true)
        })
        .catch((e: apiError) => {
          reject(true)
          //console.log(e.error.message)
        })
    })
  }

  loadPageLink() {
    this.ketshopwebFrontendService
      .getPageLinkAll()
      .then((res: any) => {
        //console.log(res)
        localStorage.setItem('SET_PAGE_LINK', JSON.stringify(res))

        this.store.dispatch(new SET_PAGE_LINK(res))
        this.initPage = true
        this._authen.setClientProfile()
      })
      .catch((e: apiError) => {
        //console.log(e.error.message)
      })
  }

  timeCheckPDPA = null as any
  async loadConversionScript() {
    const pop_key = [
      'track_script',
      'google_tag_manager_head',
      'google_tag_manager_body',
      'google_adword_remarketing',
      'pixel_code_id',
      'tiktok_pixel',
      'line_pixel_head',
      'line_pixel_event',
      'facebook_domain_verify',
      'google_webmaster_tool',
      'custom_script_head',
      'custom_script_body',
      'custom_script_footer',
      'tiktok_pixel_id',
      'tiktok_pixel_access_token',
    ]
    let option: any = []
    try {
      option = await this.ketshopwebFrontendService.searchOption({ pop_key: pop_key })

      console.log(option)
    } catch (err) {}
    const _option: any = {}
    option.forEach((obj) => {
      _option[obj.pop_key] = obj
    })

    if (_option.google_webmaster_tool.pop_value) {
      this._meta.updateTag({ name: 'google-site-verification', content: _option.google_webmaster_tool.pop_value })
    }

    try {
      if (_option.facebook_domain_verify.pop_value) {
        const current = _option.facebook_domain_verify
        let facebook_domain_verify = current.pop_value
        if (`${current.pop_value}`.match(/content="(.*)"/).length > 0) {
          facebook_domain_verify = `${current.pop_value}`.match(/content="(.*)"/)[1]
        }
        if (`${current.pop_value}`.search(facebook_domain_verify) !== -1) {
          this._meta.updateTag({ name: 'facebook-domain-verification', content: facebook_domain_verify })
        }
      }
    } catch (err) {}

    if (!this._cookie.get('pdpa')) {
      let pages = false
      let langs = false
      if (this._cookie.get('pages')) pages = true
      if (this._cookie.get('langs')) langs = true
      setTimeout(() => {
        const all = this._cookie.getAll()
        for (const key in all) {
          this._cookie.remove(key)
        }
        if (pages) this._cookie.put('pages', `${pages}`)
        if (langs) this._cookie.put('langs', `${langs}`)
      }, 2000)
    }

    if (_option.google_tag_manager_head.pop_value) {
      $(_option.google_tag_manager_head.pop_value).appendTo('head')
    }
    if (_option.google_tag_manager_body.pop_value) {
      $(_option.google_tag_manager_body.pop_value).prependTo('body')
    }

    if (_option.custom_script_head && _option.custom_script_head.pop_value) {
      $(_option.custom_script_head.pop_value).appendTo('head')
    }
    if (_option.custom_script_body && _option.custom_script_body.pop_value) {
      $(_option.custom_script_body.pop_value).prependTo('body')
    }

    if (_option.custom_script_footer && _option.custom_script_footer.pop_value) {
      $(_option.custom_script_footer.pop_value).prependTo('footer')
    }

    //console.log(_option);
    if (_option.track_script.pop_value) {
      $(_option.track_script.pop_value).appendTo('head')
      // When loading `web-vitals` using a classic script, all the public
      // methods can be found on the `webVitals` global namespace.
      const sendToGoogleAnalytics = (data: any) => {
        const { name, delta, value, id } = data
        console.log('sendToGoogleAnalytics', name, delta, id, data)
        // Assumes the global `ga()` function exists, see:
        // https://developers.google.com/analytics/devguides/collection/analyticsjs
        try {
          gtag('event', name, {
            // Built-in params:
            value: delta, // Use `delta` so the value can be summed.
            // Custom params:
            metric_id: id, // Needed to aggregate events.
            metric_value: value, // Optional.
            metric_delta: delta, // Optional.
          })
        } catch {}
        try {
          ga('send', 'event', {
            eventCategory: 'Web Vitals',
            eventAction: name,
            // The `id` value will be unique to the current page load. When sending
            // multiple values from the same page (e.g. for CLS), Google Analytics can
            // compute a total by grouping on this ID (note: requires `eventLabel` to
            // be a dimension in your report).
            eventLabel: id,
            // Google Analytics metrics must be integers, so the value is rounded.
            // For CLS the value is first multiplied by 1000 for greater precision
            // (note: increase the multiplier for greater precision if needed).
            eventValue: Math.round(name === 'CLS' ? delta * 1000 : delta),
            // Use a non-interaction event to avoid affecting bounce rate.
            nonInteraction: true,
            // Use `sendBeacon()` if the browser supports it.
            transport: 'beacon',
            // OPTIONAL: any additional attribution params here.
            // See: https://web.dev/debug-web-vitals-in-the-field/
            // dimension1: '...',
            // dimension2: '...',
            // ...
          })
        } catch {}
      }
      WebVitals.onCLS(sendToGoogleAnalytics)
      WebVitals.onFCP(sendToGoogleAnalytics)
      WebVitals.onFID(sendToGoogleAnalytics)
      WebVitals.onINP(sendToGoogleAnalytics)
      WebVitals.onLCP(sendToGoogleAnalytics)
      WebVitals.onTTFB(sendToGoogleAnalytics)
    }

    let is_by_pass_pdpa = false
    if (location.search.includes('orderlink') || location.search.includes('order-page')) {
      is_by_pass_pdpa = true
    }

    clearInterval(this.timeCheckPDPA)
    this.timeCheckPDPA = setInterval(
      () => {
        const ios = this.userAgentService.getIosVersion()
        console.log('ios', ios)
        if (this._cookie.get('pdpa') || (ios.is_ios && ios.major <= 12) || is_by_pass_pdpa) {
          clearInterval(this.timeCheckPDPA)
          console.log('pdpa accepted')
          if (_option.tiktok_pixel.pop_value) {
            $(_option.tiktok_pixel.pop_value).appendTo('head')
          }

          if (_option.line_pixel_head.pop_value) {
            $(_option.line_pixel_head.pop_value).appendTo('head')
          }

          if (_option.google_adword_remarketing.pop_value) {
            $(_option.google_adword_remarketing.pop_value).prependTo('body')
          }

          // if(_option.tiktok_pixel_access_token && _option.tiktok_pixel_id){

          // }

          if (_option.pixel_code_id.pop_value) {
            const url = window.location.href
            if (url.search(`${window.location.origin}/sale-page/`) == -1) {
              console.log('<!-- Facebook Pixel Code [FRONT-END] -->')
              $(`<!-- [Accept] Facebook Pixel Code -->
                <noscript>
                <img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=${_option.pixel_code_id.pop_value}&ev=PageView&noscript=1"/>
                </noscript>
                <!-- End Facebook Pixel Code -->`).appendTo('head')

              setTimeout(() => {
                try {
                  fbq('init', `${_option.pixel_code_id.pop_value}`)
                  fbq('track', 'PageView')
                } catch (err) {}
                try {
                  // this._util
                  const custom_data: any = {}
                  const fb_event_data: any = {}
                  if (this._util.getCookie('_fbp')) {
                    fb_event_data['fbp'] = this._util.getCookie('_fbp')
                  }
                  if (this._util.getCookie('_fbc')) {
                    fb_event_data['fbc'] = this._util.getCookie('_fbc')
                  }
                  if (this.activatedRoute.snapshot.queryParamMap.get('fbclid')) {
                    fb_event_data['fbc'] = this.activatedRoute.snapshot.queryParamMap.get('fbclid')
                  }

                  this._util
                    .fb_events({
                      ...fb_event_data,
                      action_source: 'website',
                      events: [
                        {
                          event_name: 'PageView',
                          event_url: location.href,
                          custom_data: custom_data,
                        },
                      ],
                    })
                    .then(() => {})
                    .catch(() => {})
                } catch (err) {}
                if (_option.line_pixel_event.pop_value) {
                  $(_option.line_pixel_event.pop_value).prependTo('body')
                }
              }, 1500)
            }
          }

          setTimeout(() => {
            gtag('consent', 'update', {
              ad_storage: 'granted',
            })
            console.log('gtag consent update')
          }, 1000)
        }
      },
      this._cookie.get('pdpa') ? 1 : 3000,
    )
  }
}
