import { KetshopwebServices } from 'ketshopweb-services'
import { cloneDeep } from 'lodash'
import { Injectable, inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { Observable, Subscription, concat, interval, of } from 'rxjs'
import { delay, map, mergeMap, take, takeWhile } from 'rxjs/operators'
import { actions_products } from './actions'
import { IProductsState } from './models'
import { PromotionService } from '@cms/services/promotion.service'
import * as moment from 'moment'
import { ProductsService } from '@cms/services/products.service'
import { SafeAny } from '@ketshopweb/ui/core/types'

@Injectable()
export class ProductsEffects {
  private actions$ = inject(Actions)
  private store = inject(Store)
  private ProductsService = inject(KetshopwebServices.ProductsService)
  private ketshopwebPromotionService = inject(PromotionService)
  private ketshopwebProductService = inject(ProductsService)

  private subscription_search: {
    [key: string]: Subscription
  } = {}

  private subscription_flash_sale: {
    [key: string]: Subscription
  } = {}

  $LoadCategory = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions_products.LoadCategory),
        map(() => {
          this.ProductsService.mainCategory().forEach((data) => {
            this.store.dispatch(
              actions_products.SetCategory({
                data: cloneDeep(data),
              }),
            )
          })
        }),
      ),
    { dispatch: false },
  )

  $InitFlashSale = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions_products.InitFlashSale),
        take(10),
        map(() => {
          this.ketshopwebPromotionService
            .listFlashSale()
            .then((res: SafeAny) => {
              console.log(' $InitFlashSale')
              console.log(res)
              const data = res.data
              for (const promo of data) {
                const promotion_key = `promotion_${promo.id}`
                if (this.subscription_flash_sale[promotion_key]) {
                  this.subscription_flash_sale[promotion_key].unsubscribe()
                }
                const promo_status = promo.date_status
                if (!res.flash_sale_enable) {
                  this.store.dispatch(
                    actions_products.SetFlashSale({
                      key: `${promotion_key}`,
                      data: {
                        data: promo,
                        time_remain_type: promo_status == 'soon' ? 'start_in' : 'end_in',
                        time_remain: {
                          days: 0,
                          hours: 0,
                          minutes: 0,
                          seconds: 0,
                        },
                      },
                    }),
                  )
                  continue
                }
                if (promo_status == 'expired') {
                  this.store.dispatch(
                    actions_products.SetFlashSale({
                      key: `${promotion_key}`,
                      data: {
                        data: promo,
                        time_remain_type: promo_status == 'soon' ? 'start_in' : 'end_in',
                        time_remain: {
                          days: 0,
                          hours: 0,
                          minutes: 0,
                          seconds: 0,
                        },
                      },
                    }),
                  )
                  continue
                } else if (promo_status == 'disabled') {
                  this.store.dispatch(
                    actions_products.SetFlashSale({
                      key: `${promotion_key}`,
                      data: {
                        data: promo,
                        time_remain_type: promo_status == 'soon' ? 'start_in' : 'end_in',
                        time_remain: {
                          days: 0,
                          hours: 0,
                          minutes: 0,
                          seconds: 0,
                        },
                      },
                    }),
                  )
                  continue
                }
                const start_date = promo.start_date
                const end_date = promo.end_date
                const cal_date = promo_status == 'soon' ? start_date : end_date
                const count = moment(cal_date).diff(moment(res.current_date), 'seconds')

                this.subscription_flash_sale[promotion_key] = concat(
                  interval(1000).pipe(
                    take(count),
                    map((v) => {
                      const remaining = count - 1 - v
                      return remaining === 0 ? 0 : remaining
                    }),
                    takeWhile((remaining) => remaining > -1),
                  ),
                  of(0),
                ).subscribe({
                  next: (v) => {
                    const diff = moment.duration(moment(cal_date).add(5, 'seconds').diff(moment()))
                    // let txt = (promo_status == 'soon') ? 'เริ่มใน ':'สิ้นสุดใน ';
                    // console.log(txt,Math.floor(diff.asDays())+' วัน ',diff.hours()+' ชั่วโมง ',diff.minutes()+' นาที ',diff.seconds() + ' วินาที' );
                    const _time_remain = {
                      days: Math.floor(diff.asDays()),
                      hours: diff.hours(),
                      minutes: diff.minutes(),
                      seconds: diff.seconds(),
                    }

                    this.store.dispatch(
                      actions_products.SetFlashSale({
                        key: `${promotion_key}`,
                        data: {
                          data: promo,
                          time_remain_type: promo_status == 'soon' ? 'start_in' : 'end_in',
                          time_remain: _time_remain,
                        },
                      }),
                    )
                    if (
                      _time_remain.days == 0 &&
                      _time_remain.hours == 0 &&
                      _time_remain.minutes == 0 &&
                      _time_remain.seconds == 0
                    ) {
                      if (promo_status == 'soon') {
                        // INIT
                        this.store.dispatch(actions_products.InitFlashSale())
                      }
                      this.subscription_flash_sale[promotion_key].unsubscribe()
                      this.ketshopwebProductService.clearCache()
                    }
                  },
                  complete: () => {
                    console.log('complete')
                  },
                })
              }
            })
            .catch((err) => console.error(err))
        }),
      ),
    { dispatch: false },
  )

  $SearchProducts = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions_products.SearchProducts),
        map((action) => {
          const model = cloneDeep(action.input) as IProductsState.SearchModel
          if (this.subscription_search[action.from]) {
            this.subscription_search[action.from].unsubscribe()
            this.store.dispatch(
              actions_products.SetLoading({
                from: action.from,
                val: false,
              }),
            )
          }
          this.store.dispatch(
            actions_products.SetSearchModel({
              from: action.from,
              input: cloneDeep(model),
            }),
          )
          this.subscription_search[action.from] = new Observable((ob) => {
            this.store.dispatch(
              actions_products.SetLoading({
                from: action.from,
                val: true,
              }),
            )
            ob.next()
          })
            .pipe(
              delay(300),
              mergeMap(() => this.ProductsService.systemSearch(model)),
            )
            .subscribe({
              next: (value) => {
                this.store.dispatch(
                  actions_products.SetProducts({
                    from: action.from,
                    data: {
                      data: value.data,
                      count: value.count,
                    },
                  }),
                )
                this.store.dispatch(
                  actions_products.SetLoading({
                    from: action.from,
                    val: false,
                  }),
                )
              },
              complete() {
                console.log('complete')
                this.store.dispatch(
                  actions_products.SetLoading({
                    from: action.from,
                    val: false,
                  }),
                )
              },
              error(err) {
                this.store.dispatch(
                  actions_products.SetLoading({
                    from: action.from,
                    val: false,
                  }),
                )
                console.log('error', err)
              },
            })
        }),
      ),
    { dispatch: false },
  )
}
