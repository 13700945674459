import { createFeatureSelector, createSelector } from '@ngrx/store'
import { IWarehouseState } from './models'
import { WarehouseFEATURE_KEY } from './reducer'
import { cloneDeep } from 'lodash'
import { selectors_channels } from '../channels'
import { selectors_products } from '../products'

const selectState = createFeatureSelector<IWarehouseState.WarehouseState>(WarehouseFEATURE_KEY)

const warehouse_list = createSelector(selectState, (state: IWarehouseState.WarehouseState) => state.list)

const warehouse_info = createSelector(selectState, (state: IWarehouseState.WarehouseState) => state.info)

const is_loading = createSelector(selectState, (state: IWarehouseState.WarehouseState) => state.is_loading)

const warehouse_transfer = createSelector(
  selectState,
  (state: IWarehouseState.WarehouseState) => state.warehouse_transfer.list,
)

const warehouse_transfer_is_loading = createSelector(
  selectState,
  (state: IWarehouseState.WarehouseState) => state.warehouse_transfer.is_loading,
)

const warehouse_product_search_model = createSelector(
  selectState,
  (state: IWarehouseState.WarehouseState) => state.product_search_model,
)

const list_warehouse_products = createSelector(
  selectState,
  (state: IWarehouseState.WarehouseState) => state.warehouse_product,
)

const warehouse_transfer_search_model = createSelector(
  selectState,
  (state: IWarehouseState.WarehouseState) => state.warehouse_transfer.search_model,
)

const is_loading_warehouse_product = createSelector(selectState, (state) => state.is_loading_warehouse_product)

const select_list_warehouse_product = createSelector(selectState, (state) => state.select_list)

const get_warehouse_inventory_search_model = createSelector(
  selectState,
  (state) => state.warehouse_inventory_search_model,
)

const raw_warehouse_inventory = createSelector(selectState, (state) => state.warehouse_inventory)
const warehouse_inventory = () =>
  createSelector(
    raw_warehouse_inventory,
    get_warehouse_inventory_search_model,
    selectors_channels.list_channel,
    selectors_products.category(),
    warehouse_list,
    (data, search_model, list_channel, category, warehouse_data) => {
      // console.log('warehouse_inventory', category)
      const model = cloneDeep(search_model)
      const filter_tag: IWarehouseState.FilterTag[] = []
      const channels = list_channel.all.filter((x) => model.channels.includes(x.value))
      const channel_pos = channels.filter((x) => x.group == 'pos')
      const channel_marketplace = channels.filter((x) => x.group == 'marketplace')

      if (model.warehouse_id) {
        const warehouse = warehouse_data.find((x) => x.id == model.warehouse_id)
        if (warehouse) {
          filter_tag.push({
            type: 'warehouse',
            name: warehouse.name,
            img: '/assets/images/core-imgs/ch-pos.png',
            value: warehouse.id,
            is_close: true,
            onClose: () => {
              model.warehouse_id = ''
              return model
            },
          })
        }
      }

      channel_marketplace.forEach((c) => {
        filter_tag.push({
          type: 'channel_marketplace',
          name: c.name,
          img: c.img,
          value: c.value,
          is_close: true,
          onClose: () => {
            model.channels = model.channels.filter((x) => x !== c.value)
            return model
          },
        })
      })
      channel_pos.forEach((c) => {
        filter_tag.push({
          type: 'channel_pos',
          name: c.name,
          img: c.img,
          value: c.value,
          is_close: true,
          onClose: () => {
            model.channels = model.channels.filter((x) => x !== c.value)
            return model
          },
        })
      })

      if (model.status === 0 || model.status === 1) {
        // console.log('model.status', model.status)
        filter_tag.push({
          type: 'status',
          name: model.status ? 'ใช้งาน' : 'ไม่ใช้งาน',
          value: model.status,
          is_close: true,
          onClose: () => {
            model.status = ''
            return model
          },
        })
      }

      if (model.category_id && Number(model.category_id)) {
        // console.log('model.category_id', category.find(x=>x.id == model.category_id).lang1 || '')
        filter_tag.push({
          type: 'category_id',
          name: category.find((x) => x.id == model.category_id).lang1 || '',
          value: model.category_id,
          is_close: true,
          onClose: () => {
            model.category_id = ''
            return model
          },
        })
      }

      return {
        ...data,
        filter_tag,
      }
    },
  )

const warehouse_inventory_loading = createSelector(selectState, (state) => state.warehouse_inventory_loading)

const is_loading_warehouse_product_from = (props: { from: string }) =>
  createSelector(is_loading_warehouse_product, (val) => {
    return {
      from: props.from,
      val: val[props.from] || false,
    }
  })

const warehouse_product_search_model_by_name = (name: string) =>
  createSelector(
    warehouse_product_search_model,
    (state) =>
      state[name] || {
        warehouse_id: -1,
        search: '',
        page: 1,
        page_size: 10,
        sort: 'desc',
        source_warehouse: null,
        type: 'normal',
      },
  )

const list_warehouse_products_from = (props: { from: string }) =>
  createSelector(list_warehouse_products, (data) => {
    // console.log('data', props.from, data)
    const products = cloneDeep(
      data[props.from] || {
        data: [],
        count: 0,
      },
    ) as Partial<{
      data: IWarehouseState.WarehouseProductItem[]
      count: number
    }>
    return {
      from: props.from,
      list: products,
    }
  })

const select_list_warehouse_product_from = (props: { from: string }) =>
  createSelector(select_list_warehouse_product, (data) => {
    return {
      from: props.from,
      list: data[props.from] || {
        data: [],
        apply_data: [],
      },
    }
  })

export const selectors_warehouse = {
  warehouse_list,
  is_loading,
  warehouse_info,
  warehouse_transfer: {
    list: warehouse_transfer,
    is_loading: warehouse_transfer_is_loading,
    search_model: warehouse_transfer_search_model,
  },
  warehouse_products: {
    search_model: warehouse_product_search_model_by_name,
    list_products_from: list_warehouse_products_from,
    is_loading: is_loading_warehouse_product_from,
    select_list_warehouse_product_from,
  },
  warehouse_inventory: {
    search_model: get_warehouse_inventory_search_model,
    list: warehouse_inventory,
    is_loading: warehouse_inventory_loading,
  },
}
