import { h, Fragment } from 'preact'
import { IPurchaseOrderHtml } from './types'

export const TmpOrderAddress = ({ order }: { order: IPurchaseOrderHtml.Order }) => {
  const isBillingInter = order.bill_country_alpha2_code != 'TH'
  let bill_address = ''

  if (!isBillingInter) {
    bill_address = `${order.bill_address1} แขวง/ตำบล ${order.bill_subdistrict} เขต/อำเภอ ${order.bill_district} ${order.bill_province} ${order.bill_zipcode}`
  } else {
    bill_address = `${order.bill_address1} ${order.bill_province} ${order.bill_zipcode} ${order.bill_country_name}`
  }

  const isShippingInter = order.country_alpha2_code != 'TH'
  let address = ''

  if (!isShippingInter) {
    address = `${order.address1} แขวง/ตำบล ${order.subdistrict} เขต/อำเภอ ${order.district} ${order.province} ${order.zipcode}`
  } else {
    address = `${order.address1} ${order.province} ${order.zipcode} ${order.country_name}`
  }

  const CustomerAddress = () => {
    return (
      <Fragment>
        <div class="order-address">
          <h2 class="text-subtitle">ที่อยู่สำหรับออกบิล / Billing Address</h2>
          <p class="text-small">
            {order.bill_name} {order.bill_lastname || ''} ({order.bill_tel})
          </p>
          <p class="text-small">{bill_address}</p>
        </div>
        <div class="order-address">
          <h2 class="text-subtitle">ที่อยู่สำหรับจัดส่ง / Shipping Address</h2>
          <p class="text-small">
            {order.name} ({order.tel})
          </p>
          <p class="text-small">{address}</p>
        </div>
      </Fragment>
    )
  }

  const GuestAddress = () => {
    return (
      <Fragment>
        <div class="order-address">
          <h2 class="text-subtitle">ที่อยู่สำหรับออกบิล / Billing Address</h2>
          <p class="text-small">{order.bill_name}</p>
        </div>
        <div class="order-address">
          <h2 class="text-subtitle">ที่อยู่สำหรับจัดส่ง / Shipping Address</h2>
          <p class="text-small">{order.name}</p>
        </div>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div class="order-address-wrapper">{order.user_id !== -1 ? <CustomerAddress /> : <GuestAddress />}</div>
    </Fragment>
  )
}
