import { Action, createReducer, on } from '@ngrx/store'
import { cloneDeep } from 'lodash'
import { actions_products } from './actions'
import { IProductsState } from './models'

export const ProductsFEATURE_KEY = 'products'

export const default_products_search_model = (): IProductsState.SearchModel => {
  let size = 20
  try {
    const p = localStorage.getItem('page_size_backend')
    if (p) {
      const page = JSON.parse(p) as {
        global: number
      }
      size = page.global || 20
    }
  } catch {}
  return cloneDeep({
    lang: 'lang1',
    status: null,
    search: '',
    group: '',
    cate_id: '',
    ids: [],
    sku: '',
    barcode: '',
    orderby: 'product_posts.id',
    sort: 'desc',
    cate_not_in: '',
    low_stock: false,
    instock: '',
    tag_id: null,
    tag_not_in: null,
    havebarcode: false,
    havepoints: null,
    cate_id_nosub: null,
    page: 1,
    perpage: Number(size),
  })
}

export const initialProductsState: IProductsState.ProductsState = {
  select_list: {},
  is_loading: {},
  search_model: {},
  list: {},
  category: {
    data: [],
  },
  flash_sale: {},
}

const reducer = createReducer(
  initialProductsState,
  on(actions_products.SetLoading, (state, action) => {
    const is_loading = cloneDeep(state.is_loading)
    is_loading[action.from] = action.val
    return {
      ...state,
      is_loading: is_loading,
    }
  }),
  on(actions_products.SetSearchModel, (state, action) => {
    const search_model = cloneDeep(state.search_model)
    search_model[action.from] = action.input
    return {
      ...state,
      search_model: search_model,
    }
  }),
  on(actions_products.SetFlashSale, (state, action) => {
    const { key, data } = action
    const model = cloneDeep(state.flash_sale)

    model[key] = cloneDeep(data) as any
    // console.log(model[key]);

    if (
      model[key].time_remain.days == 0 &&
      model[key].time_remain.hours == 0 &&
      model[key].time_remain.minutes == 0 &&
      model[key].time_remain.seconds == 0
    ) {
      model[key].complete = true
    } else {
      model[key].complete = false
    }

    return {
      ...state,
      flash_sale: model,
    }
  }),
  on(actions_products.SetProducts, (state, action) => {
    const list = cloneDeep(state.list)
    if (!list[action.from]) {
      list[action.from] = {
        data: [],
        count: 0,
      }
    }
    list[action.from] = {
      data: action.data.data,
      count: action.data.count,
    }
    return {
      ...state,
      list: list,
    }
  }),
  on(actions_products.InitSelectProducts, (state, action) => {
    const select_list = cloneDeep(state.select_list)
    if (!select_list[action.from]) {
      select_list[action.from] = {
        data: [],
        apply_data: [],
      }
    }
    select_list[action.from].data = cloneDeep(select_list[action.from].apply_data)
    return {
      ...state,
      select_list: select_list,
    }
  }),
  on(actions_products.SelectProducts, (state, action) => {
    const select_list = cloneDeep(state.select_list)
    if (!select_list[action.from]) {
      select_list[action.from] = {
        data: [],
        apply_data: [],
      }
    }
    action.data.forEach((order) => {
      const find_index = select_list[action.from].data.findIndex((x) => x.id == order.id)
      if (action.action == 'add' && find_index == -1) {
        select_list[action.from].data.push(order)
      } else if (action.action == 'del' && find_index !== -1) {
        select_list[action.from].data.splice(find_index, 1)
      }
    })
    return {
      ...state,
      select_list: select_list,
    }
  }),
  on(actions_products.ApplySelectProducts, (state, action) => {
    const select_list = cloneDeep(state.select_list)
    if (!select_list[action.from]) {
      select_list[action.from] = {
        data: [],
        apply_data: [],
      }
    }
    select_list[action.from].apply_data = cloneDeep(select_list[action.from].data)
    return {
      ...state,
      select_list: select_list,
    }
  }),
  on(actions_products.ClearSelectProducts, (state, action) => {
    const select_list = cloneDeep(state.select_list)
    select_list[action.from] = {
      data: [],
      apply_data: [],
    }
    return {
      ...state,
      select_list: select_list,
    }
  }),
  on(actions_products.SetCategory, (state, action) => {
    return {
      ...state,
      category: {
        data: action.data,
      },
    }
  }),
)

export function ProductsReducer(state: IProductsState.ProductsState, action: Action) {
  return reducer(state, action)
}
