import {
  Directive,
  ElementRef,
  OnInit,
  Input,
  Renderer2,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  Pipe,
} from '@angular/core'
import { KetTranslateService } from './translate.service'

@Pipe({
  name: 'ketTranslate',
})
export class KetTranslatePipe {
  constructor(private Service_Translate: KetTranslateService) {}

  transform(value: string, page_name: string): string {
    const text = this.Service_Translate.translate(value, page_name)

    return text
  }
}
