import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root',
})
export class TikTokService {
  constructor(private _http: HttpClient) {}

  listShop() {
    return this._http.get('/services/tiktok/shop/listShop').toPromise() as Promise<any>
  }
  removeShop(shop_id) {
    return this._http.get(`/services/tiktok/shop/remove/` + shop_id).toPromise() as Promise<any>
  }
  packagePickUpConfig(data) {
    return this._http.post(`/services/tiktok/order/packagePickUpConfig`, data).toPromise() as Promise<any>
  }
  searchKetshopProductToTikTok(data) {
    return this._http.post('/services/tiktok/product/searchKetshopProductToTikTok', data).toPromise() as Promise<any>
  }

  deliveredOrderBatch(data: any) {
    return this._http.post('/services/tiktok/order/deliveredOrderBatch', data).toPromise() as Promise<any>
  }

  shippingDocument(data: any) {
    return this._http.post('/services/tiktok/order/shippingDocument', data).toPromise() as Promise<any>
  }

  changeOrderCreateStatus(data: any) {
    return this._http.put(`/services/tiktok/order/changeOrderCreateStatus`, data).toPromise() as Promise<any>
  }
  getProductTikTokByID(shop_id, product_id) {
    return this._http
      .get('/services/tiktok/product/getProductTikTokByID/' + shop_id + '/' + product_id)
      .toPromise() as Promise<any>
  }

  getShippingProvider(shop_id: number,delivery_option_id:any) {
    return this._http.get('/services/tiktok/product/getShippingProvider/' + shop_id+ "/"+delivery_option_id).toPromise() as Promise<any>
  }
  getWarehouseDeliveryOption(data) {
    return this._http.post('/services/tiktok/product/getWarehouseDeliveryOption', data).toPromise() as Promise<any>
  }

  createProduct(data) {
    return this._http.post('/services/tiktok/product/createProduct', data).toPromise() as Promise<any>
  }

  updateProduct(data) {
    return this._http.put('/services/tiktok/product/updateProduct', data).toPromise() as Promise<any>
  }
  activateProduct(data) {
    return this._http.post('/services/tiktok/product/activateProduct', data).toPromise() as Promise<any>
  }
  inActivateProduct(data) {
    return this._http.post('/services/tiktok/product/inActivateProduct', data).toPromise() as Promise<any>
  }
  uploadImage(data) {
    return this._http.post('/services/tiktok/product/uploadImage', data).toPromise() as Promise<any>
  }
  getOrderStatus() {
    return this._http.get('/services/tiktok/order/orderStatus').toPromise() as Promise<any>
  }

  createOrderKetshop(data: any) {
    return this._http.post('/services/tiktok/order/createOrderKetshop', data).toPromise() as Promise<any>
  }

  syncOrdersState() {
    return this._http.get('/services/tiktok/order/syncOrdersState').toPromise() as Promise<any>
  }

  syncOrderByOrderID(data) {
    return this._http.post('/services/tiktok/order/syncOrderByOrderID', data).toPromise() as Promise<any>
  }
  syncOrders() {
    return this._http.get('/services/tiktok/order/syncOrders').toPromise() as Promise<any>
  }
  getWarehouseList(shop_id: number) {
    return this._http.get('/services/tiktok/product/getWarehouseList/' + shop_id).toPromise() as Promise<any>
  }

  authorizationShopUrl(): Promise<{ url: string }> {
    return this._http.get('/services/tiktok/shop/authorizationShopUrl').toPromise() as Promise<{ url: string }>
  }

  syncProductsState(shop_id: number): Promise<any> {
    return this._http.get('/services/tiktok/product/syncProductsState/' + shop_id).toPromise() as Promise<any>
  }
  searchKetshopTikTokOrder(data: any): Promise<any> {
    return this._http.post('/services/tiktok/order/searchKetshopTikTokOrder', data).toPromise() as Promise<any>
  }

  searchKetshopTikTokProduct(data) {
    return this._http.post('/services/tiktok/product/searchKetshopTikTokProduct', data).toPromise() as Promise<any>
  }
  unsyncProducts(shop_id: number): Promise<any> {
    return this._http.get('/services/tiktok/product/unsyncProducts/' + shop_id).toPromise() as Promise<any>
  }
  syncProducts(shop_id: number): Promise<any> {
    return this._http.get('/services/tiktok/product/syncProducts/' + shop_id).toPromise() as Promise<any>
  }
  syncProductsByID(shop_id: number,item_id: number): Promise<any> {
    return this._http.get('/services/tiktok/product/syncProductsByID/' + shop_id + '/'+item_id).toPromise() as Promise<any>
  }
  searchSyncProduct(data: any, shop_id: number): Promise<any> {
    return this._http.post('/services/tiktok/product/searchSyncProduct/' + shop_id, data).toPromise() as Promise<any>
  }
  productStatus(type = 'array') {
    return this._http.get('/services/tiktok/product/productStatus/' + type).toPromise() as Promise<any>
  }

  getCategory(shop_id) {
    return this._http.get('/services/tiktok/product/getCategory/' + shop_id).toPromise() as Promise<any>
  }
  searchBrand(data) {
    return this._http.post('/services/tiktok/product/searchBrand', data).toPromise() as Promise<any>
  }
  getCategoryRule(data) {
    return this._http.post('/services/tiktok/product/getCategoryRule', data).toPromise() as Promise<any>
  }
  getCategoryAttribute(data) {
    return this._http.post('/services/tiktok/product/getCategoryAttribute', data).toPromise() as Promise<any>
  }
}
