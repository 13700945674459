import { actions_app } from '@store/ketshopweb-store'
import { SafeAny } from '@ketshopweb/ui/core/types'
import { Store } from '@ngrx/store'
import { Component, OnInit, Input } from '@angular/core'
import { UtilService } from '@cms/services/util.service'
import * as moment from 'moment'

@Component({
  selector: 'ket-order-label',
  templateUrl: './order-label.component.html',
  styleUrls: ['./order-label.component.scss'],
})
export class KetOrderLabelComponent implements OnInit {
  @Input() ordercode: string
  @Input() ordertype = 'order'
  @Input() permalink = null
  @Input() status = 1
  @Input() expire = 0
  @Input() created_at = null
  @Input() channel_img = null
  @Input() channel_type = null
  @Input() channel_name = null

  constructor(
    private utilService: UtilService,
    private store: Store<SafeAny>,
  ) {}

  channels_service: any

  async ngOnInit() {
    this.enCode(this.ordercode)
  }

  // [DEV-1965] Check มี User กำลังทำการแก้ไขรายการนี้อยู่ หรือไม่ก่อน เปิดรายละเอียด
  async view(ordercode) {
    // ถ้าเป็น Order แล้วสามารถเปิดได้
    if (!this.pending) {
      const url = `${location.origin}/system/e-commerce/order-detail/${ordercode}`
      window.open(url, '_blank', 'menubar=no,toolbar=no,location=no,width=1280,height=720')
    }
  }

  // ✅ Done!
  copyLinkOrderPage(ordercode: any) {
    let set_link: string
    if (this.ordertype == 'order-link') {
      if (this.pending) {
        set_link = `${window.location.origin}/orderlink/${ordercode}`
      } else {
        set_link = `${window.location.origin}/orderlink/${this.permalink}`
      }
      this.store.dispatch(
        actions_app.Copy({
          action: 'text',
          val: set_link,
        }),
      )
    } else {
      // set_link = `${window.location.origin}/order-page/${this.utilService.enCodeId(ordercode)}`
      this.store.dispatch(
        actions_app.Copy({
          action: 'order',
          val: ordercode,
        }),
      )
    }
  }

  convertDate(date) {
    moment.locale('th') // set to Thai
    const date_ = moment(date).format('DD/MM/YYYY, HH:mm:ss')
    return date_
  }

  // permalink:any
  pending = false
  enCode(ordercode = null) {
    const check_permalink = this.utilService.enCodeId(ordercode)

    if (check_permalink) {
      this.pending = false
    } else {
      this.pending = true
    }
  }
}
