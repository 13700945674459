import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetConsentPopupComponent } from './consent-popup.component'
import { KetConsentFormModule } from '../consent-form'
import { NzButtonModule } from 'ng-zorro-antd/button'

@NgModule({
  entryComponents: [KetConsentPopupComponent],
  declarations: [KetConsentPopupComponent],
  imports: [CommonModule, KetConsentFormModule, NzButtonModule],
  exports: [KetConsentPopupComponent],
})
export class KetConsentPopupModule {}
