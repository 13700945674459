import { Component, OnInit, Input } from '@angular/core'
import { UtilService } from '@cms/services/util.service'
import * as moment from 'moment'
import { environment } from 'src/environments/environment'
import { AuthenService } from '@cms/services/authen.service'
import { OrderService } from '@cms/services/order.service'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzDrawerRef } from 'ng-zorro-antd/drawer'
import { DeliveryService } from '@cms/services/delivery.service'
import { Etc } from '@cms/util/etc'
@Component({
  selector: 'app-modal-print-delivery',
  templateUrl: './modal-print-delivery.component.html',
  styleUrls: ['./modal-print-delivery.component.css'],
})
export class ModalPrintDeliveryComponent implements OnInit {
  @Input() data: any

  currentdatetime: any
  rangePicker_manual: any = []
  rangePicker_auto: any = []
  rangePicker_cod: any = []
  access_token: string
  api_path: string
  label: any = 1
  from_pre_shipping = false

  @Input()
  isPayment = false

  constructor(
    private Service_Util: UtilService,
    private _authen: AuthenService,
    private message: NzMessageService,
    private Service_Order: OrderService,
    private _delivery: DeliveryService,
    private _etc: Etc,
    private _NzDrawerRef: NzDrawerRef,
  ) {}
  message_duration = 4000
  selectedall_manual = false
  selectedall_auto = false
  selectedall_cod = false
  model_manual: any = {
    status: '',
    startdate: '',
    enddate: '',
    page: 1,
    perpage: 10,
    type: 'manual',
    all: false,
  }
  model_auto: any = {
    status: '',
    startdate: '',
    enddate: '',
    page: 1,
    perpage: 10,
    type: 'auto',
    all: false,
  }
  model_cod: any = {
    status: '',
    startdate: '',
    enddate: '',
    page: 1,
    perpage: 10,
    type: 'cod',
    all: false,
  }
  total_manual = 0
  order_manual: any = []
  total_cod = 0
  order_cod: any = []
  order_auto: any = []
  selected_order: any = []
  total_auto = 0
  shipping_manual_list: any[] = []
  shipping_auto_list: any[] = []
  statusimg: any
  change_status = 0
  check_all_status_manual = false
  check_all_status_auto = false
  check_all_status_cod = false
  tab_state = 0

  // tmpselected_order:any = [];
  async ngOnInit() {
    try {
      if (this.data.type) {
        this.from_pre_shipping = true
        this.model_auto['from_pre_shipping'] = 1
        this.model_manual['from_pre_shipping'] = 1
        this.model_auto['check_freeshipping'] = this.data.check_freeshipping
        this.model_manual['check_freeshipping'] = this.data.check_freeshipping
        this.model_auto['status'] = '-'
        this.model_manual['status'] = '-'

        this.model_auto['shipping'] = ''
        this.model_manual['shipping'] = ''

        if (this.isPayment) {
          this.model_auto['status'] = '2'
          this.model_manual['status'] = '2'
        }
      }
    } catch (err) {}

    this.api_path = environment.api_path
    this.access_token = this._authen.getToken().access_token
    this.currentdatetime = await this.Service_Util.getDateTime()
    this.rangePicker_manual = [
      moment(this.currentdatetime.date).add(-1, 'month').format('YYYY-MM-DD 00:00:00'),
      moment(this.currentdatetime.date).format('YYYY-MM-DD 23:59:59'),
    ]
    this.model_manual.startdate = moment(this.rangePicker_manual[0]).format('YYYY-MM-DD HH:mm:ss')
    this.model_manual.enddate = moment(this.rangePicker_manual[1]).format('YYYY-MM-DD HH:mm:ss')

    this.rangePicker_auto = [
      moment(this.currentdatetime.date).add(-1, 'month').format('YYYY-MM-DD 00:00:00'),
      moment(this.currentdatetime.date).format('YYYY-MM-DD 23:59:59'),
    ]
    this.model_auto.startdate = moment(this.rangePicker_auto[0]).format('YYYY-MM-DD HH:mm:ss')
    this.model_auto.enddate = moment(this.rangePicker_auto[1]).format('YYYY-MM-DD HH:mm:ss')

    this.rangePicker_cod = [
      moment(this.currentdatetime.date).add(-1, 'month').format('YYYY-MM-DD 00:00:00'),
      moment(this.currentdatetime.date).format('YYYY-MM-DD 23:59:59'),
    ]
    this.model_cod.startdate = moment(this.rangePicker_cod[0]).format('YYYY-MM-DD HH:mm:ss')
    this.model_cod.enddate = moment(this.rangePicker_cod[1]).format('YYYY-MM-DD HH:mm:ss')

    this.searchManual()
    this.searchAuto()
    this.searchCod()

    this.statusimg = this._etc.orderstatus_img()

    this._delivery.getDeliveryManual().then((res: any) => {
      this.shipping_manual_list = res
    })
    this._delivery.getDeliveryAutosetting().then((res: any) => {
      // console.log(res)
      this.shipping_auto_list = res.provider
    })
  }

  dateRangeChange_manual() {
    try {
      this.model_manual.startdate = moment(this.rangePicker_manual[0]).format('YYYY-MM-DD HH:mm:ss')
      this.model_manual.enddate = moment(this.rangePicker_manual[1]).format('YYYY-MM-DD HH:mm:ss')
    } catch (e) {
      this.model_manual.startdate = moment(this.currentdatetime.date).add(-1, 'month').format('YYYY-MM-DD HH:mm:ss')
      this.model_manual.enddate = moment(this.currentdatetime.date).format('YYYY-MM-DD HH:mm:ss')
    }
    this.searchManual()
  }

  tabChange(e: any) {
    this.tab_state = e
  }

  dateRangeChange_auto() {
    try {
      this.model_auto.startdate = moment(this.rangePicker_auto[0]).format('YYYY-MM-DD HH:mm:ss')
      this.model_auto.enddate = moment(this.rangePicker_auto[1]).format('YYYY-MM-DD HH:mm:ss')
    } catch (e) {
      this.model_auto.startdate = moment(this.currentdatetime.date).add(-1, 'month').format('YYYY-MM-DD HH:mm:ss')
      this.model_auto.enddate = moment(this.currentdatetime.date).format('YYYY-MM-DD HH:mm:ss')
    }
    this.searchAuto()
  }

  dateRangeChange_cod() {
    try {
      this.model_cod.startdate = moment(this.rangePicker_cod[0]).format('YYYY-MM-DD HH:mm:ss')
      this.model_cod.enddate = moment(this.rangePicker_cod[1]).format('YYYY-MM-DD HH:mm:ss')
    } catch (e) {
      this.model_cod.startdate = moment(this.currentdatetime.date).add(-1, 'month').format('YYYY-MM-DD HH:mm:ss')
      this.model_cod.enddate = moment(this.currentdatetime.date).format('YYYY-MM-DD HH:mm:ss')
    }
    this.searchCod()
  }

  pagechage_manual(page) {
    this.model_manual.page = page
    this.searchManual()
  }

  pagechange_auto(page) {
    this.model_auto.page = page
    this.searchAuto()
  }

  pagechange_cod(page) {
    this.model_cod.page = page
    this.searchCod()
  }

  searchAuto() {
    const _obj = { ...this.model_auto }
    if (this.model_auto.status == '-') {
      _obj.status = [2, 3, 4]
    }
    this.Service_Order.searchorderdeliveryprint(_obj)
      .then((res: any) => {
        //console.log(res);

        //this.order_auto = res.data;
        // this.order_auto.forEach(obj => {
        //   obj.checked = false;
        //   this.selected_order.forEach(obj2 => {
        //     if (obj.id == obj2.id) {
        //       obj.checked = true;
        //     }
        //   });
        // });

        this.order_auto = []
        for (const obj of res.data) {
          if (this.selected_order.find((x) => x.ordercode == obj.ordercode)) {
            obj['checked'] = true
            this.order_auto = [...this.order_auto, obj]
          } else {
            obj['checked'] = false
            this.order_auto = [...this.order_auto, obj]
          }
        }
        this.total_auto = res.count
        this.check_status_allAuto()
      })
      .catch((data) => {
        this.message.error(data.error.message, { nzDuration: this.message_duration })
      })
  }

  deleteSelected(index) {
    // this.selected_order[index].checked = false;
    const ind = this.order_manual.findIndex((o) => o.id == this.selected_order[index].id)
    const ind2 = this.order_auto.findIndex((o) => o.id == this.selected_order[index].id)
    if (ind != undefined && ind != -1) {
      this.order_manual[ind].checked = false
    }
    if (ind2 != undefined && ind2 != -1) {
      this.order_auto[ind2].checked = false
    }
    this.selected_order.splice(index, 1)
  }

  addAllManual() {
    //console.log(this.check_all_status)
    if (this.check_all_status_manual) {
      for (const obj of this.order_manual) {
        this.del_select_order(obj)
      }
    } else {
      for (const obj of this.order_manual) {
        if (this.selected_order.find((x) => x.ordercode == obj.ordercode)) {
        } else {
          this.selectOrderManual(obj)
        }
      }
    }
  }

  addAllCod() {
    //console.log(this.check_all_status)
    if (this.check_all_status_cod) {
      for (const obj of this.order_cod) {
        this.del_select_order(obj)
      }
    } else {
      for (const obj of this.order_cod) {
        if (this.selected_order.find((x) => x.ordercode == obj.ordercode)) {
        } else {
          this.selectOrderCod(obj)
        }
      }
    }
  }

  addAllAuto() {
    //console.log('auto', this.check_all_status_auto)
    //console.log(this.check_all_status)
    if (this.check_all_status_auto) {
      for (const obj of this.order_auto) {
        this.del_select_order(obj)
      }
    } else {
      for (const obj of this.order_auto) {
        if (this.selected_order.find((x) => x.ordercode == obj.ordercode)) {
        } else {
          this.selectOrderAuto(obj)
        }
      }
    }
  }

  del_select_order(obj: any) {
    this.selected_order = this.selected_order.filter((d) => d.ordercode !== obj.ordercode)

    if (this.tab_state == 0 || (this.from_pre_shipping && this.data.type == 'manual')) {
      var orderIndex = this.order_manual.findIndex((x) => x.ordercode == obj.ordercode)
      if (orderIndex !== -1) {
        this.order_manual[orderIndex]['checked'] = false
      }
      this.check_status_allManual()
    }

    if (this.tab_state == 1 || (this.from_pre_shipping && this.data.type == 'auto')) {
      var orderIndex = this.order_auto.findIndex((x) => x.ordercode == obj.ordercode)

      //console.log(orderIndex)

      if (orderIndex !== -1) {
        this.order_auto[orderIndex]['checked'] = false
      }

      this.check_status_allAuto()
    }

    if (this.tab_state == 2 || (this.from_pre_shipping && this.data.type == 'cod')) {
      var orderIndex = this.order_cod.findIndex((x) => x.ordercode == obj.ordercode)

      //console.log(orderIndex)

      if (orderIndex !== -1) {
        this.order_cod[orderIndex]['checked'] = false
      }

      this.check_status_allCod()
    }
  }

  check_status_allManual() {
    this.check_all_status_manual = true
    for (const obj of this.order_manual) {
      if (this.selected_order.find((x) => x.ordercode == obj.ordercode)) {
      } else {
        obj['checked'] = false
        this.check_all_status_manual = false
      }
    }

    if (this.order_manual.length == 0) {
      this.check_all_status_manual = false
    }
  }

  check_status_allAuto() {
    this.check_all_status_auto = true
    for (const obj of this.order_auto) {
      if (this.selected_order.find((x) => x.ordercode == obj.ordercode)) {
      } else {
        obj['checked'] = false
        this.check_all_status_auto = false
      }
    }

    if (this.order_auto.length == 0) {
      this.check_all_status_auto = false
    }
  }

  check_status_allCod() {
    this.check_all_status_cod = true
    for (const obj of this.order_cod) {
      if (this.selected_order.find((x) => x.ordercode == obj.ordercode)) {
      } else {
        obj['checked'] = false
        this.check_all_status_cod = false
      }
    }

    if (this.order_auto.length == 0) {
      this.check_all_status_cod = false
    }
  }

  selectOrderManual(obj: any) {
    if (!this.selected_order.find((x) => x.ordercode == obj.ordercode)) {
      obj['checked'] = true
      this.selected_order = [...this.selected_order, obj]
    } else {
      this.del_select_order(obj)
    }
    this.check_status_allManual()
  }

  selectOrderAuto(obj: any) {
    if (!this.selected_order.find((x) => x.ordercode == obj.ordercode)) {
      obj['checked'] = true
      this.selected_order = [...this.selected_order, obj]
    } else {
      this.del_select_order(obj)
    }
    this.check_status_allAuto()
  }

  selectOrderCod(obj: any) {
    if (!this.selected_order.find((x) => x.ordercode == obj.ordercode)) {
      obj['checked'] = true
      this.selected_order = [...this.selected_order, obj]
    } else {
      this.del_select_order(obj)
    }
    this.check_status_allCod()
  }

  checkedSelected(obj) {
    console.log(obj)
    // let ind = this.selected_order.findIndex(o => o.id == obj.id);

    if (obj.checked) {
      const ind = this.selected_order.findIndex((o) => o.id == obj.id)
      console.log(ind)
      if (ind == undefined || ind == -1) {
        this.selected_order.push(obj)
      }
    } else {
      const ind = this.selected_order.findIndex((o) => o.id == obj.id)
      console.log(ind)
      if (ind != undefined && ind != -1) {
        this.selected_order.splice(ind, 1)
      }
      // this.selected_order.findIn
    }
    // this.selected_order.push(obj);
    console.log(this.selected_order)
    // this.tmpselected_order = this.selected_order;
  }

  checkSelectAllManual() {
    //console.log(this.model_manual);
    const _obj = { ...this.model_manual }
    if (this.model_manual.status == '-') {
      _obj.status = [2, 3, 4]
    }
    _obj.all = true
    this.Service_Order.searchorderdeliveryprint(_obj)
      .then((res: any) => {
        if (this.selectedall_manual) {
          this.order_manual.forEach((obj) => {
            obj.checked = true
          })
          res.data.forEach((data) => {
            const ind = this.selected_order.findIndex((o) => o.id == data.id)
            if (ind == undefined || ind == -1) {
              this.selected_order.push(data)
            }
          })
        } else {
          this.order_manual.forEach((obj) => {
            obj.checked = false
          })
          res.data.forEach((data) => {
            const ind = this.selected_order.findIndex((o) => o.id == data.id)
            if (ind != undefined && ind != -1) {
              this.selected_order.splice(ind, 1)
            }
          })
        }
      })
      .catch((data) => {
        this.message.error(data.error.message, { nzDuration: this.message_duration })
      })
  }
  checkSelectAllAuto() {
    console.log(this.model_auto)
    const _obj = { ...this.model_auto }
    _obj.all = true
    if (this.model_auto.status == '-') {
      _obj.status = [2, 3, 4]
    }
    this.Service_Order.searchorderdeliveryprint(_obj)
      .then((res: any) => {
        if (this.selectedall_auto) {
          this.order_auto.forEach((obj) => {
            obj.checked = true
          })
          res.data.forEach((data) => {
            const ind = this.selected_order.findIndex((o) => o.id == data.id)
            if (ind == undefined || ind == -1) {
              this.selected_order.push(data)
            }
          })
        } else {
          this.order_auto.forEach((obj) => {
            obj.checked = false
          })
          res.data.forEach((data) => {
            const ind = this.selected_order.findIndex((o) => o.id == data.id)
            if (ind != undefined && ind != -1) {
              this.selected_order.splice(ind, 1)
            }
          })
        }
      })
      .catch((data) => {
        this.message.error(data.error.message, { nzDuration: this.message_duration })
      })
  }

  checkSelectAllCod() {
    console.log(this.model_cod)
    const _obj = { ...this.model_cod }
    _obj.all = true
    if (this.model_cod.status == '-') {
      _obj.status = [2, 3, 4]
    }
    this.Service_Order.searchorderdeliveryprint(_obj)
      .then((res: any) => {
        if (this.selectedall_cod) {
          this.order_cod.forEach((obj) => {
            obj.checked = true
          })
          res.data.forEach((data) => {
            const ind = this.selected_order.findIndex((o) => o.id == data.id)
            if (ind == undefined || ind == -1) {
              this.selected_order.push(data)
            }
          })
        } else {
          this.order_cod.forEach((obj) => {
            obj.checked = false
          })
          res.data.forEach((data) => {
            const ind = this.selected_order.findIndex((o) => o.id == data.id)
            if (ind != undefined && ind != -1) {
              this.selected_order.splice(ind, 1)
            }
          })
        }
      })
      .catch((data) => {
        this.message.error(data.error.message, { nzDuration: this.message_duration })
      })
  }

  searchManual() {
    const _obj = { ...this.model_manual }
    if (this.model_manual.status == '-') {
      _obj.status = [3, 4]
    }
    this.Service_Order.searchorderdeliveryprint(_obj)
      .then((res: any) => {
        // console.log(res);

        // this.order_manual = res.data;
        // this.order_manual.forEach(obj => {
        //   obj.checked = false;
        //     this.selected_order.forEach(obj2 => {
        //         if(obj.id == obj2.id){
        //           obj.checked = true;
        //         }
        //     });
        // });

        this.order_manual = []
        for (const obj of res.data) {
          if (this.selected_order.find((x) => x.ordercode == obj.ordercode)) {
            obj['checked'] = true
            this.order_manual = [...this.order_manual, obj]
          } else {
            obj['checked'] = false
            this.order_manual = [...this.order_manual, obj]
          }
        }

        this.total_manual = res.count

        this.check_status_allManual()
      })
      .catch((data) => {
        this.message.error(data.error.message, { nzDuration: this.message_duration })
      })
  }

  searchCod() {
    const _obj = { ...this.model_cod }
    if (this.model_cod.status == '-') {
      _obj.status = [3, 4]
    }
    this.Service_Order.searchorderdeliveryprint(_obj)
      .then((res: any) => {
        // console.log(res);

        // this.order_manual = res.data;
        // this.order_manual.forEach(obj => {
        //   obj.checked = false;
        //     this.selected_order.forEach(obj2 => {
        //         if(obj.id == obj2.id){
        //           obj.checked = true;
        //         }
        //     });
        // });

        this.order_cod = []
        for (const obj of res.data) {
          if (this.selected_order.find((x) => x.ordercode == obj.ordercode)) {
            obj['checked'] = true
            this.order_cod = [...this.order_cod, obj]
          } else {
            obj['checked'] = false
            this.order_cod = [...this.order_cod, obj]
          }
        }

        this.total_cod = res.count

        this.check_status_allCod()
      })
      .catch((data) => {
        this.message.error(data.error.message, { nzDuration: this.message_duration })
      })
  }

  ok() {
    if (this.selected_order.length == 0) {
      this.message.error('Please select order', { nzDuration: this.message_duration })
      return false
    }
    $('form#order-deliveryprint').submit()
  }

  close() {
    this._NzDrawerRef.close()
  }
}
