import { KetOmniChatService } from './omni-chat.service'
import { Injectable } from '@angular/core'
import { CmsOrdersService } from './orders.service'
import { CmsReportService } from './report.service'
import { CmsProductsService } from './products.service'
import { ketshopweb_services_func } from './func'
import { CmsPaymentGateway } from './payment-gateway.service'
import { CmsWarehouseService } from './warehouse.service'
import { CmsDeliveryService } from './delivery.service'
import { CmsTrcloudGatewayService } from './trcloud-gateway.service'
import { CmsExtensionService } from './extension.service'
import { CmsLineShoppingService } from './line-shopping/line-shopping.service'
import { CmsBusinessService } from './business.service'
import { KetshopwebPromotionService } from '@cms/services/ketshopweb-promotion.service'
import { CmsKetshopwebAccountsService } from './ketshopweb-accounts.service'

// export types
export { IPaymentGateway } from './types/IPaymentGateway'
export { IWarehouse } from './types/IWarehouse'
export { IPriceLevel } from './types/IPriceLevel'

export namespace KetshopwebServices {
  export const func = ketshopweb_services_func

  @Injectable({
    providedIn: 'root',
  })
  export class OrdersService extends CmsOrdersService {}

  @Injectable({
    providedIn: 'root',
  })
  export class OmniChatService extends KetOmniChatService {}

  //CmsReportService
  @Injectable({
    providedIn: 'root',
  })
  export class ReportService extends CmsReportService {}

  //CmsProductsService
  @Injectable({
    providedIn: 'root',
  })
  export class ProductsService extends CmsProductsService {}

  @Injectable({
    providedIn: 'root',
  })
  export class PaymentGateway extends CmsPaymentGateway {}

  // CmsWarehouseService
  @Injectable({
    providedIn: 'root',
  })
  export class WarehouseService extends CmsWarehouseService {}

  @Injectable({
    providedIn: 'root',
  })
  export class DeliveryService extends CmsDeliveryService {}

  @Injectable({
    providedIn: 'root',
  })
  export class TrcloudGatewayService extends CmsTrcloudGatewayService {}

  @Injectable({
    providedIn: 'root',
  })
  export class ExtensionService extends CmsExtensionService {}

  @Injectable({
    providedIn: 'root',
  })
  export class LineShoppingService extends CmsLineShoppingService {}

  @Injectable({
    providedIn: 'root',
  })
  export class BusinessService extends CmsBusinessService {}

  @Injectable({
    providedIn: 'root',
  })
  export class PromotionsService extends KetshopwebPromotionService {}

  // CmsKetshopwebAccountsService
  @Injectable({
    providedIn: 'root',
  })
  export class AccountsService extends CmsKetshopwebAccountsService {}

}

// export const InjectableExports = [
//   KetshopwebServices.OmniChatService,
//   KetshopwebServices.OrdersService,
//   KetshopwebServices.ReportService,
//   KetshopwebServices.ProductsService,
//   KetshopwebServices.PaymentGateway,
//   KetshopwebServices.WarehouseService,
//   KetshopwebServices.DeliveryService,
//   KetshopwebServices.TrcloudGatewayService,
//   KetshopwebServices.ExtensionService,
//   KetshopwebServices.LineShoppingService,
// ]

// For Old Angular
// @NgModule({
//     imports: [],
//     providers: [
//         KetshopwebServices.OmniChatService,
//         KetshopwebServices.OrdersService
//     ]
// })
// export class InjectableExportsModule { }
