import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetWishlistButtonComponent } from './wishlist-button.component'
import { NzIconModule, NzModalModule } from 'ng-zorro-antd'
import { KetModalLoginModule } from '../modal-login'
import { KetConsentPopupModule } from '../consent-popup'
import { KetPathLangModule } from '../path-lang'

@NgModule({
  declarations: [KetWishlistButtonComponent],
  entryComponents: [KetWishlistButtonComponent],
  imports: [CommonModule, NzModalModule, NzIconModule, KetModalLoginModule, KetConsentPopupModule, KetPathLangModule],
  exports: [KetWishlistButtonComponent],
})
export class KetWishlistButtonModule {}
