import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetCardStatisticComponent } from './card-statistic.component'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'
import { FormsModule } from '@angular/forms'

@NgModule({
  declarations: [KetCardStatisticComponent],
  imports: [CommonModule, FormsModule, NzToolTipModule, NzIconModule],
  exports: [KetCardStatisticComponent],
})
export class KetCardStatisticModule {}
