import { Component, OnInit, Input } from '@angular/core'
import { CustomerService } from '@cms/services/customer.service'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal'
import { AuthenService } from '@cms/services/authen.service'
import { ModalForgotpasswordComponent } from '../modal-forgotpassword/modal-forgotpassword.component'
import { GoogleAnalyticsService } from '@cms/services/google-analytics.service'
import * as jwt_decode from 'jwt-decode'
@Component({
  selector: 'app-modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.css'],
})
export class ModalLoginComponent implements OnInit {
  @Input() data: any
  page_name = 'login'
  model: any = {
    username: '',
    password: '',
  }
  message_duration = 4000
  constructor(
    private Service_Customer: CustomerService,
    private modal: NzModalRef,
    private message: NzMessageService,
    private Service_Authen: AuthenService,
    private _modal: NzModalService,
    private Service_GoogleAnalytics: GoogleAnalyticsService,
  ) {}

  ngOnInit() {}

  forgotpassword() {
    const modal = this._modal
      .create({
        nzTitle: null,
        nzContent: ModalForgotpasswordComponent,
        nzClassName: 'color_render_wrap modal-small-s',
        nzWrapClassName: 'vertical-center-modal',
        nzBodyStyle: {
          padding: '30px 15px 15px',
        },
        nzWidth: '90%',
        nzFooter: null,
        nzKeyboard: false,
        nzMaskClosable: false,
        nzOnOk: (componentInstance) => {
          return componentInstance.ok()
        },
        nzOnCancel: (componentInstance) => {},
        nzComponentParams: {
          data: '',
        },
      })
      .afterClose.subscribe((result) => {
        modal.unsubscribe()
        console.log(result)
        if (result) {
          this._modal.success({
            nzTitle: 'Please check your e-mail to change new password',
            // nzContent: 'some messages...some messages...'
          })
        }
      })
  }

  login() {
    // this.modal.close();
    $('#_order_preloader').fadeIn()

    this.Service_Authen.login_frontend({
      domain: window.location.hostname == 'localhost' ? 'dev.ketshoptest.com' : window.location.hostname,
      username: this.model.username,
      password: this.model.password,
    })
      .then((res: any) => {
        console.log(res)

        this.Service_Authen.setTokenClient(res)

        if (!this.Service_Authen.isTokenExpired()) {
          this.Service_Authen.setClientProfile()
        }
        
        this.Service_GoogleAnalytics.login('Email')
        const token = jwt_decode(res.access_token)
        this.Service_GoogleAnalytics.loginID(token.id);

        location.reload()
      })
      .catch((err) => {
        $('#_order_preloader').hide()
        //console.log(JSON.stringify(err));
        this.message.create('error', `${err.error.message}`)
      })
  }

  async ok() {
    try {
      await this.Service_Customer.forgotPassword(this.model)
      this.modal.close(true)
    } catch (e) {
      this.message.error(e.error.message, { nzDuration: this.message_duration })
      return false
    }
  }
}
