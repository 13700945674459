import { Component, OnInit, Output, EventEmitter } from '@angular/core'
import { ExtensionService } from '@cms/services/extension.service'
import { NzModalService } from 'ng-zorro-antd/modal'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzDrawerRef } from 'ng-zorro-antd/drawer'
import { SettingService } from '@cms/services/setting.service'
import * as splitter from 'split-sms'

declare let navigator: any

@Component({
  selector: 'app-extension-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.css'],
})
export class ExtensSmSionComponent implements OnInit {
  @Output() back = new EventEmitter()
  constructor(
    private Service_Extension: ExtensionService,
    private message: NzMessageService,
    private modal: NzModalService,
    private drawerRef: NzDrawerRef<string>,
    private Service_Setting: SettingService,
  ) {}

  tabselected: any = 'setting'

  model: any = {
    custom_key: {
      actions: {
        order_payment: 1,
        order_shiping: 1,
      },
    },
  }
  page: any = []
  _option: any = {}
  message_duration = 4000
  sms_credit: any = 0
  text_sms = ''
  tel_sms = ''
  text_sms_state: any = {}
  tel_sms_send: any[] = []
  send_state = false
  domains: any = {}
  searchModal = {
    page: 1,
    perpage: 10,
    search: '',
  }
  preload = false
  total = 0
  listLogs: any[] = []

  async ngOnInit() {
    const pop_key = ['api_key']
    const option: any = await this.Service_Setting.searchOption({
      pop_key: pop_key,
    }).catch((err) => {
      console.error(err)
      return []
    })
    option.forEach((obj) => {
      this._option[obj.pop_key] = obj.pop_value
    })

    this.model = await this.Service_Extension.search({ name: 'SMS' })

    //console.log(this.model)
    //custom_key

    if (!this.model.custom_key) {
      console.log('gen default')
      const _model = {
        actions: {
          order_payment: 1,
          order_shiping: 1,
        },
      }
      this.model.custom_key = { ..._model }
    } else {
      this.model.custom_key = JSON.parse(this.model.custom_key)
    }

    // this.model.connected = this.model.connected == 0 ? false : true;

    this.Service_Extension.searchSMS({
      perpage: '1',
      page: '1',
    })
      .then((res: any) => {
        this.sms_credit = res.credit
        this.domains = res.domains
      })
      .catch((err) => {})
    this.loadData()
  }

  pagechage(page) {
    this.searchModal.page = page
    this.loadData()
  }

  loadData() {
    this.preload = true

    this.Service_Extension.searchSMS(this.searchModal).then((res: any) => {
      this.listLogs = res.data
      this.total = res.count
      this.preload = false
    })
  }

  textChange() {
    this.text_sms_state = splitter.split(this.text_sms)
    //console.log(this.text_sms_state)

    this.characterSet()

    //console.log(tels)

    this.tel_sms_send = []

    try {
      for (const obj of `${this.tel_sms}`.split(',')) {
        if (this.phoneNumber(obj)) {
          this.tel_sms_send.push(this.phoneNumber(obj))
        }
      }

      if (this.tel_sms_send.length == 0) {
        this.send_state = false
      } else {
        this.send_state = true

        this.characterSet()
      }
    } catch (err) {
      this.send_state = false
    }
  }

  characterSet() {
    if (this.text_sms_state.characterSet !== 'GSM') {
      if (this.text_sms_state.length > 335) {
        this.send_state = false
      } else {
        this.send_state = true
      }
    } else {
      if (this.text_sms_state.length > 779) {
        this.send_state = false
      } else {
        this.send_state = true
      }
    }

    if (this.text_sms_state.length == 0) {
      this.send_state = false
    }
  }

  phoneNumber(phone: string) {
    const phoneRe = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    const digits = `${phone || ''}`.replace(/\D/g, '')
    if (phoneRe.test(digits)) {
      return digits
    } else {
      return false
    }
  }

  copy(key) {
    // this.model.connected = 1;
    try {
      navigator.clipboard.writeText(key).then((res: any) => {
        this.message.success('copy success', {
          nzDuration: this.message_duration,
        })
      })
    } catch (err) {}
  }

  setSelected(tab) {
    this.tabselected = tab
  }

  _back() {
    this.drawerRef.close()
  }

  save() {
    const model = { ...this.model }
    // model.connected = model.connected == false ? 0 : 1;
    model.custom_key = JSON.stringify(model.custom_key)
    $('.theme-loader').fadeIn()
    // $(".theme-loader").fadeOut();

    //console.log(model)

    this.Service_Extension.connectSMS(model)
      .then((res: any) => {
        this.ngOnInit()
      })
      .catch((data) => {
        this.modal.error({
          nzTitle: 'Error',
          nzContent: data.error.message,
        })
      })
      .finally(() => {
        $('.theme-loader').fadeOut()
      })
  }

  send() {
    if (this.sms_credit < this.text_sms_state.parts.length * this.tel_sms_send.length) {
      this.modal.error({
        nzTitle: 'Error',
        nzContent: 'เครดิตไม่พอ',
      })
      return
    }
    $('.theme-loader').fadeIn()
    this.Service_Extension.sendSMS({ message: this.text_sms, tel: this.tel_sms })
      .then((res) => {
        this.text_sms = ''
        this.tel_sms = ''
        this.text_sms_state = {}
        this.send_state = false
        this.tel_sms_send = []
        this.ngOnInit()
      })
      .catch((err) => {})
      .finally(() => {
        $('.theme-loader').fadeOut()
      })
  }
}
