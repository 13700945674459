import { Injectable, inject } from '@angular/core'
import { SettingService } from '@cms/services/setting.service'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { from, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { actions_page_options } from './actions'
import { IPageOptionsState } from './models'
import { KetshopwebFrontendService } from '@cms/services/ketshopweb-frontend.service'

@Injectable()
export class PageOptionsEffects {
  // inject
  private actions$ = inject(Actions)
  private ketshopwebFrontendService = inject(KetshopwebFrontendService)
  private store = inject(Store)

  pop_keys = ['add_to_cart_now', 'add_to_cart_now_setting', 'wishlist_setting']

  $LogCounter = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions_page_options.LoadPageOptions),
        mergeMap(() =>
          from(this.ketshopwebFrontendService.searchOption({ pop_key: this.pop_keys })).pipe(
            map((res: IPageOptionsState.PageOption[]) => {
              this.store.dispatch(
                actions_page_options.SetPageOptions({
                  page_options: res,
                }),
              )
            }),
            catchError((err: any) => {
              console.error(err)
              return of(err)
            }),
          ),
        ),
      ),
    { dispatch: false },
  )
}
