import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetVenoboxDirective } from './venobox.directive'

@NgModule({
  declarations: [KetVenoboxDirective],
  imports: [CommonModule],
  exports: [KetVenoboxDirective],
})
export class KetVenoboxModule {}
