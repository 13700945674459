import { Component, OnInit, Input } from '@angular/core'
import { UtilService } from '@cms/services/util.service'
import * as moment from 'moment'
import { environment } from 'src/environments/environment'
import { AuthenService } from '@cms/services/authen.service'

@Component({
  selector: 'app-modal-export-confirmpayment',
  templateUrl: './modal-export-confirmpayment.component.html',
  styleUrls: ['./modal-export-confirmpayment.component.css'],
})
export class ModalExportConfirmpaymentComponent implements OnInit {
  @Input() data: any
  currentdatetime: any
  rangePicker: any = []
  access_token: string
  api_path: string
  constructor(
    private Service_Util: UtilService,
    private _authen: AuthenService,
  ) {}

  model: any = {
    startdate: '',
    enddate: '',
  }
  async ngOnInit() {
    this.api_path = environment.api_path
    this.access_token = this._authen.getToken().access_token
    this.currentdatetime = await this.Service_Util.getDateTime()
    this.rangePicker = [
      moment(this.currentdatetime.date).add(-1, 'month').format('YYYY-MM-DD'),
      moment(this.currentdatetime.date).format('YYYY-MM-DD'),
    ]
    this.model.startdate = moment(this.rangePicker[0]).format('YYYY-MM-DD')
    this.model.enddate = moment(this.rangePicker[1]).format('YYYY-MM-DD')
  }

  dateRangeChange() {
    try {
      this.model.startdate = moment(this.rangePicker[0]).format('YYYY-MM-DD')
      this.model.enddate = moment(this.rangePicker[1]).format('YYYY-MM-DD')
    } catch (e) {
      this.model.startdate = moment(this.currentdatetime.date).add(-1, 'month').format('YYYY-MM-DD')
      this.model.enddate = moment(this.currentdatetime.date).format('YYYY-MM-DD')
    }
  }

  ok() {
    $('form#confirmpayment-export').submit()
  }
}
