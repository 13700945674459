import { cloneDeep } from 'lodash'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import { IProductsState } from './models'
import { default_products_search_model, ProductsFEATURE_KEY } from './reducer'

const selectState = createFeatureSelector<IProductsState.ProductsState>(ProductsFEATURE_KEY)

const search_model = createSelector(selectState, (state: IProductsState.ProductsState) => state.search_model)

const list_products = createSelector(selectState, (state: IProductsState.ProductsState) => state.list)

const select_list_products = createSelector(selectState, (state: IProductsState.ProductsState) => state.select_list)

const flash_sale = createSelector(selectState, (state: IProductsState.ProductsState) => state.flash_sale)

const flash_sale_by_key = (props: { key: string }) =>
  createSelector(flash_sale, (val) => {
    return {
      key: props.key,
      data: val[props.key] || null,
    }
  })

const is_loading = createSelector(selectState, (state: IProductsState.ProductsState) => state.is_loading)

const raw_category = createSelector(selectState, (state: IProductsState.ProductsState) => state.category)

const category = () =>
  createSelector(raw_category, (state) => {
    const category = cloneDeep(state)
    category.data.unshift({
      id: '-1',
      lang1: 'Uncategory',
      lang2: 'Uncategory',
      lang3: 'Uncategory',
      lang4: 'Uncategory',
    })
    category.data.unshift({ id: '', lang1: 'All', lang2: 'All', lang3: 'All', lang4: 'All' })
    return category.data
  })

// const category = createSelector(
//     selectState,
//     (state: IProductsState.ProductsState) => {
//         let category = cloneDeep(state.category)
//         category.data.unshift({ id: "-1", lang1: "Uncategory", lang2: "Uncategory", lang3: "Uncategory", lang4: "Uncategory" });
//         category.data.unshift({ id: "", lang1: "All", lang2: "All", lang3: "All", lang4: "All" });
//         return category.data
//     }
// );

// by props
const is_loading_from = (props: { from: string }) =>
  createSelector(is_loading, (val) => {
    return {
      from: props.from,
      val: val[props.from] || false,
    }
  })

const search_model_from = (props: { from: string }) =>
  createSelector(search_model, (data) => {
    return {
      from: props.from,
      model: data[props.from] || default_products_search_model(),
      filter_tags: [],
    }
  })

const list_products_from = (props: { from: string; selected?: boolean }) =>
  createSelector(list_products, select_list_products, (data, products_selected) => {
    // console.log('data', props.from, data)
    const products = cloneDeep(
      data[props.from] || {
        data: [],
        count: 0,
      },
    ) as Partial<IProductsState.ProductsState['list']['']>
    if (props.selected) {
      const selected_data = cloneDeep(
        products_selected[props.from] || {
          data: [],
          apply_data: [],
        },
      )
      products.data.map((item) => {
        item.selected = selected_data.data.find((x) => x.id == item.id) ? true : false
        return item
      })
    }
    return {
      from: props.from,
      list: products,
    }
  })

const select_list_products_from = (props: { from: string }) =>
  createSelector(select_list_products, (data) => {
    return {
      from: props.from,
      list: data[props.from] || {
        data: [],
        apply_data: [],
      },
    }
  })

export const selectors_products = {
  search_model_from,
  list_products_from,
  select_list_products_from,
  is_loading_from,
  flash_sale,
  flash_sale_by_key,
  category,
}
