import { Component, OnInit, Input } from '@angular/core'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { Router } from '@angular/router'

type TTypeInput = 'error' | 'warning' | 'success'

@Component({
  selector: 'ket-modal-message',
  templateUrl: './modal-message.component.html',
  styleUrls: ['./modal-message.component.less'],
})
export class KetModalMessageComponent implements OnInit {
  @Input() type: TTypeInput = 'error'
  @Input() useRoute = false
  @Input() routePath = ''
  @Input() btnRoute = ''
  @Input() title = ''
  @Input() message = ''
  @Input() queryParams: any = {}
  @Input() btnClass = 'btn-orange'
  @Input() closeTimeMs = 2000

  constructor(
    private nzModalRef: NzModalRef,
    private router: Router,
  ) {}

  ngOnInit(): void {
    if (this.type == 'success') {
      setTimeout(() => {
        this.nzModalRef.close(true)
      }, this.closeTimeMs)
    }
  }

  close() {
    this.nzModalRef.close(true)
  }

  goRoute() {
    this.router.navigate([this.routePath], { queryParams: this.queryParams })
    this.nzModalRef.close(true)
  }
}
