import { Component, OnInit, OnDestroy, Input } from '@angular/core'
import { ProductsService } from '@cms/services/products.service'
import { UtilService } from '@cms/services/util.service'
import { ModalAddpropfromProductComponent } from '../../modal/modal-addpropfrom-product/modal-addpropfrom-product.component'
import { NzModalService } from 'ng-zorro-antd/modal'
import { NzDrawerRef } from 'ng-zorro-antd/drawer'
import { NzMessageService } from 'ng-zorro-antd/message'
import { cloneDeep } from 'lodash'
import { Router } from '@angular/router'
import { ModalTagComponent } from '../../modal/modal-tag/modal-tag.component'
import { interval } from 'rxjs'
import { SettingService } from '@cms/services/setting.service'
import { environment } from 'src/environments/environment'
import { AuthenService } from '@cms/services/authen.service'
import { FeatureService } from '@cms/services/feature.service'
import { ExtensionService } from '@cms/services/extension.service'
declare let alasql
declare let QRCode: any

@Component({
  selector: 'app-modal-edit-products',
  templateUrl: './modal-edit-products.component.html',
  styleUrls: ['./modal-edit-products.component.css'],
})
export class ModalEditProductsComponent implements OnInit, OnDestroy {
  @Input() product_id: any = ''

  radioValue = 'A'
  radioValueShopee = 'A'

  _productValue = 0
  _productValueShopee = 0

  use_all_properties = {
    product_name: true,
    short_desc: true,
    feature_img: false,
    image_gallery: false,
    section_element: false,
    normal_price: false,
    discount_price: false,
    category: true,
    weight: false,
    size_box: false,
  }

  model: any = {
    id: 'me',
    status: 0,
    weight: 0,
    price: 0,
    instock: 0,
    normal_price: 0,
    discount_price: 0,
    cate_id: '-1',
    prop_id: null,
    prop_enable: 2,
    hilight: false,
    group: '',
    sku: '',
    title_lang1: '',
    title_lang2: '',
    title_lang3: '',
    title_lang4: '',
    permalink_lang1: '',
    permalink_lang2: '',
    permalink_lang3: '',
    permalink_lang4: '',
    feature_img: '',
    feature_img_font: '',
    short_desc_lang1: '',
    short_desc_lang2: '',
    short_desc_lang3: '',
    short_desc_lang4: '',
    product_desc_lang1: '',
    product_desc_lang2: '',
    product_desc_lang3: '',
    product_desc_lang4: '',
    property_info: '',
    properties_desc: '',
    properties_sort: '',
    group_id: '',
    details: [],
    properties_main: 0,
    seo_title_lang1: '',
    seo_title_lang2: '',
    seo_title_lang3: '',
    seo_title_lang4: '',
    buy_limit: 0,
    properties_desc2: null,
    property_info2: null,
    properties_sort2: null,
    size_width: 45,
    size_height: 40,
    size_length: 55,
  }
  model_tmp: any = {}
  // _option: any = [
  //   "bold",
  //   "italic",
  //   "paragraphFormat",
  //   "strikeThrough",
  //   "underline",
  //   "fontFamily",
  //   "fontSize",
  //   "tmtcolors",
  //   "|",
  //   "align",
  //   "insertHR",
  //   "insertLink",
  //   "outdent",
  //   "indent",
  //   "formatOL",
  //   "formatUL",
  //   "|",
  //   "emoticons",
  //   "insertTable",
  //   "undo",
  //   "redo"
  // ];

  // ◼︎◼︎◼︎◼︎◼ Froala Editor Modify Version 8.6.X (2023) ◼︎◼︎◼︎◼︎◼
  _option: any = [
    // --- default ---
    'paragraphFormat',
    '|',
    'fontFamily',
    'fontSize',
    '|',
    'tmtcolors',
    'bold',
    'italic',
    'underline',
    'strikeThrough',
    '|',
    'align',
    // ---------------
    '-',
    'formatUL',
    'formatOL',
    '|',
    'indent',
    'outdent',
    '|',
    'tmtinsertLink',
    'insertTable',
    'insertHR',
    'emoticons',
    '|',
    'undo',
    'redo',
  ]
  // ◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼︎◼◼︎◼︎◼︎◼︎◼︎◼︎◼◼︎◼︎◼︎◼︎◼

  froalaOption: any = {
    toolbarButtons: this._option,
    toolbarButtonsSM: this._option,
    toolbarButtonsMD: this._option,
    height: 150,
  }
  message_duration = 2000
  propertie_tmp: any = [
    {
      id: 'me',
      property_info: '',
      properties_sort: 0,
      status: !!1,
      instock: 0,
      sku: '',
      title_lang1: '',
      expand: false,
      properties_main: !!1,
    },
  ]
  children_prototype: any = {
    id: 'new',
    property_info: '',
    property_info2: '',
    properties_sort: 0,
    status: false,
    instock: 0,
    sku: '',
    title_lang1: '',
    properties_main: 0,
  }
  init_children = cloneDeep(this.children_prototype)

  propertie_tmp2: any = [{ id: 'me', property_info: '', children: [] }]

  propertie_list: any = []
  propertie_list_display: any = []
  propertie_list_total = 0

  groupList: any = []

  _propertie_option: any
  _propertie_option2: any
  marketplace: any = []
  marketplace_tmp: any = []
  product_img_class = ''

  preload = true

  inuseActivityList: any[] = []

  search_model: any = {
    page: 1,
    perpage: 50,
    search: '',
  }

  // input
  active_input = {}
  onActiveInput(sku: string, field: string) {
    for (const key in this.active_input) {
      try {
        for (const key2 in this.active_input[key]) {
          try {
            this.active_input[key][key2] = false
          } catch {}
        }
      } catch {}
    }
    if (!this.active_input[sku]) {
      this.active_input[sku] = {}
    }
    this.active_input[sku][field] = true
  }

  constructor(
    private Service_Setting: SettingService,
    private Service_Extension: ExtensionService,
    private Service_Product: ProductsService,
    private Service_Util: UtilService,
    private modalService: NzModalService,
    private message: NzMessageService,
    private router: Router,
    private _authen: AuthenService,
    private Service_Feature: FeatureService,
    private _drawerRef: NzDrawerRef,
  ) {
    this._propertie_option = {
      handle: '.handle_drag',
      onEnd: (event: any) => {},
    }

    this._propertie_option2 = {
      handle: '.handle_drag',
      onEnd: (event: any) => {},
    }
  }
  lang: any = []
  selectedLang: string
  allcategory: any = []
  product_page: any
  state = 'edit'
  tag: any = []
  tmp_tag: any
  tagList: any = []
  currentpro_id: any = 'me'
  properties_delete: any = []
  show_mainproperty: any = {
    val: this.propertie_tmp[0],
  }
  ref_currentprop_in_tmp: any = {
    val: this.propertie_tmp[0],
  }

  show_main_property2: any = {
    val: this.propertie_tmp2[0].children[0],
  }
  selectProp2: any = this.propertie_tmp2[0]
  subscription_inuse: any
  noimg: any
  access_token: string
  api_path: string
  feature = false
  allstock = 0

  save_state = false

  async ngOnInit() {
    this.feature = await this.Service_Feature.getFeature('PRODUCT_PAGE')
    if (!this.feature) {
      return
    }
    this.api_path = environment.api_path
    this.access_token = this._authen.getToken().access_token
    const product_id = this.product_id
    const pop_key = ['default_no_image', 'product_img_class']
    const option: any = await this.Service_Setting.searchOption({ pop_key: pop_key }).catch((err) => {
      console.error(err)
      return []
    })
    const _option: any = {}
    option.forEach((obj) => {
      _option[obj.pop_key] = obj.pop_value
      //console.log(_option)
    })
    this.noimg = _option.default_no_image
    this.product_img_class = _option.product_img_class

    this.Service_Product.getProductByID(product_id).then(async (data: any) => {
      if (Array.isArray(data)) {
        this.router.navigate(['/system/e-commerce/product'])
      }
      const propobj: any = await this.Service_Product.getProductPropertiesObj(product_id)
      console.log('propobj', propobj)
      console.time('A')
      this.model = data
      this.model.size_width = this.model.size_width || 45
      this.model.size_height = this.model.size_height || 40
      this.model.size_length = this.model.size_length || 55
      //this.currentpro_id = data.id;
      this.propertie_tmp2 = propobj.properties2
      this.propertie_tmp = propobj.properties1
      this.selectProp2 = this.propertie_tmp2[0]

      const inuseDataId: any[] = []

      const _check = propobj.properties1.find((x) => x.properties_main == 1)
      if (!_check) {
        propobj.properties1[0].properties_main = 1
      }
      console.time('loopProperties1')
      for (const _item of propobj.properties1) {
        _item.size_width = _item.size_width || 45
        _item.size_height = _item.size_height || 40
        _item.size_length = _item.size_length || 55

        inuseDataId.push(_item.id)
        if (_item.properties_main == 1) {
          this.model = _item
          this.currentpro_id = _item.id
          this.model.hilight = !!+this.model.hilight
          this.model.discount_price = 0
          this.model.normal_price = 0
          this.model.prop_enable = this.model.prop_enable.toString()
          this.model.image_gallery = this.model.image_gallery || []

          if (!Array.isArray(this.model.image_gallery)) {
            this.model.image_gallery = []
          }

          if (this.model.temp_price) {
            this.model.normal_price = this.model.temp_price
            this.model.discount_price = this.model.price
          } else {
            this.model.normal_price = this.model.price
          }
        } else {
          _item.discount_price = 0
          _item.normal_price = 0
          if (_item.temp_price) {
            _item.normal_price = _item.temp_price
            _item.discount_price = _item.price
          } else {
            _item.normal_price = _item.price
          }
          this.propertie_list.push(_item)
        }
      }

      console.timeEnd('loopProperties1')
      console.time('loopProperties2')
      for (let i = 0; i < this.propertie_tmp2.length; i++) {
        for (let j = 0; j < this.propertie_tmp2[i].children.length; j++) {
          const currentchild = this.propertie_tmp2[i].children[j]
          currentchild.properties_main = !!+currentchild.properties_main
          currentchild.status = !!+currentchild.status
          if (currentchild.properties_main == 1) {
            this.show_main_property2.val = currentchild
          }
        }
      }

      for (let i = 0; i < this.propertie_tmp.length; i++) {
        const current = this.propertie_tmp[i]
        current.properties_main = !!+current.properties_main
        current.status = !!+current.status
        if (current.properties_main) {
          this.show_mainproperty.val = current
        }
      }
      console.timeEnd('loopProperties2')

      this.tag = this.model.tags
      console.time('getLang')
      this.lang = await this.Service_Util.getLangs()
      console.timeEnd('getLang')
      console.log(this.lang)
      console.time('getPageLink')
      this.product_page = await this.Service_Util.getPageLink({ page: 'product' })
      console.timeEnd('getPageLink')
      console.time('productMainCategory')
      this.allcategory = await this.Service_Product.productMainCategory()
      console.timeEnd('productMainCategory')
      this.allcategory.unshift({
        id: '-1',
        lang1: 'Uncategory',
        lang2: 'Uncategory',
        lang3: 'Uncategory',
        lang4: 'Uncategory',
      })
      this.allcategory.unshift({ id: '', lang1: 'All', lang2: 'All', lang3: 'All', lang4: 'All' })
      this.selectedLang = this.lang[0].ref
      this.searchGroup('')
      this.searchTag('')

      try {
        // this.model_tmp = cloneDeep(this.model)
        // this.marketplace = await this.Service_Product.productMarketPlace(product_id)
        // if (this.marketplace.length > 0) {
        //   this.marketplace_tmp = cloneDeep(this.marketplace)
        //   this.calculateInstockWeb()
        // }
        // console.log(this.marketplace)
        new QRCode($('#qr_code')[0], { width: 150, height: 150, text: this.model.sku })
      } catch (err) {}

      // console.log(inuseDataId)
      console.time('checkInuseActivity')
      const inuse: any = await this.checkInuseActivity(inuseDataId)
      console.timeEnd('checkInuseActivity')
      //console.log(inuse)
      try {
        this.subscription_inuse.unsubscribe()
      } catch (err) {}
      const interval_checkstatus = interval(5000)
      this.subscription_inuse = interval_checkstatus.subscribe(this.checkInuseActivity.bind(this, inuseDataId))

      this.preload = false
      console.timeEnd('A')
      this.searchPropertyList()
    })
    // if(!product_id){}
  }

  pagechagePropertieList(page) {
    this.search_model.page = page
    this.searchPropertyList()
  }

  modelChange(item, key) {
    // console.log(item);
    const pd = this.propertie_list.find((x) => x.id == item.id)
    if (pd) {
      // console.log(pd);
      pd[key] = item[key]
    }
  }

  async searchPropertyList(clearpage = false) {
    if (clearpage) {
      this.search_model.page = 1
    }
    // console.log(this.propertie_list);
    const offset_page = Number(this.search_model.perpage) * (Number(this.search_model.page) - 1)
    let sql = 'SELECT * FROM ? WHERE 1=1 '
    let sql_count = 'SELECT COUNT(*) as total FROM ? WHERE 1=1'
    const bindparam = [this.propertie_list]
    if (this.search_model.search !== '') {
      sql += `AND (title_lang1 LIKE ? OR sku LIKE ? ) `
      sql_count += `AND (title_lang1 LIKE ? OR sku LIKE ? ) `
      bindparam.push(`%${this.search_model.search}%`)
      bindparam.push(`%${this.search_model.search}%`)
    }
    if (this.search_model.cate_id) {
      const cate = this.allcategory.find((x) => x.id == this.search_model.cate_id)
      if (cate) {
        let sub = ''
        const sub_arr = cate.search_cate_id.map((x) => `cate_id = ${x}`)
        sub = sub_arr.join(' OR ')
        sql += `AND ( ${sub} )`
        sql_count += `AND ( ${sub} )`
      }
      // bindparam.push
    }
    // sql += 'ORDER id DESC '
    sql += ` LIMIT ${this.search_model.perpage} OFFSET ${offset_page}`

    // console.log(sql);
    this.propertie_list_display = await alasql(sql, bindparam)
    // let max_sort = await alasql('SELECT max(product_sort) as max_sort FROM ?',[this.product_sort]);
    // console.log('max_sort',max_sort[0].max_sort)
    // this.max_sort = max_sort[0].max_sort;
    const rs = await alasql(sql_count, bindparam)
    this.propertie_list_total = rs[0].total
    if (this.propertie_list_display.length == 0 && this.search_model.page > 1) {
      this.search_model.page = this.search_model.page - 1
      this.searchPropertyList()
      return
    }
    // console.log(this.propertie_list_display)
  }

  close() {
    try {
      this.subscription_inuse.unsubscribe()
    } catch (err) {}
    this._drawerRef.close(undefined)
  }

  calculateInstockWeb() {
    let sum_marketplace_stock = 0
    this.marketplace.forEach((obj) => {
      sum_marketplace_stock += obj.stock
    })

    this.allstock = this.model.instock + sum_marketplace_stock
  }

  _product_valoe_click() {
    setTimeout(() => {
      $('#_product_valoe_click input').focus()
    }, 10)
  }

  printQR() {
    $('form#print-qr-product').submit()
  }

  rederStyle() {
    const s = `
      <style>
        #wrap-responsive_filemanager_callbackproduct_img a{
          width: 100%!important;
          height: 100%!important;
        }

        .product-featureimg.horizontal{
          width: 300px;
          height: 225px;
        }
        .product-featureimg.vertical{
          width: 300px;
          height: 450px;
        }
        .product-featureimg.square{
          width: 300px;
          height: 300px;
        }
      </style>
    `
    return s
  }

  onRadioValueShopeeChange() {
    const shopee = this.marketplace.find((x) => x.name == 'Shopee')

    if (this.radioValueShopee == 'C') {
      this._productValueShopee = shopee.instock
    } else {
      this._productValueShopee = 0
    }
  }
  onRadioValueChange() {
    if (this.radioValue == 'C') {
      this._productValue = this.model.instock
    } else {
      this._productValue = 0
    }

    this._product_valoe_click()
  }

  onChangeValueProductShopee() {
    const shopee = this.marketplace.find((x) => x.name == 'Shopee')

    this.model.instock = this.model.instock + (shopee.stock - this._productValueShopee)

    if (this.radioValueShopee == 'A') {
      shopee.stock += this._productValueShopee
    }
    if (this.radioValueShopee == 'B') {
      shopee.stock -= this._productValueShopee
      if (shopee.stock < 0) {
        shopee.stock = 0
      }
    }
    if (this.radioValueShopee == 'C') {
      shopee.stock = this._productValueShopee
      if (shopee.stock < 0) {
        shopee.stock = 0
      }
    }
    // this.instock_change(this.model.id, false, this.model.instock)
    this._productValueShopee = 0

    try {
      this.calculateInstockWeb()

      $('.cdk-overlay-backdrop').click()
    } catch (err) {}
  }

  onChangeValueProduct() {
    if (this.radioValue == 'A') {
      this.model.instock += this._productValue
    }
    if (this.radioValue == 'B') {
      this.model.instock -= this._productValue
      if (this.model.instock < 0) {
        this.model.instock = 0
      }
    }
    if (this.radioValue == 'C') {
      this.model.instock = this._productValue
      if (this.model.instock < 0) {
        this.model.instock = 0
      }
    }
    this.instock_change(this.model.id, false, this.model.instock)
    this._productValue = 0

    try {
      if (this.marketplace.length > 0) {
        this.calculateInstockWeb()
      }
      $('.cdk-overlay-backdrop').click()
    } catch (err) {}
  }

  addImageGallery() {
    if (!Array.isArray(this.model.image_gallery)) {
      this.model.image_gallery = []
    }
    this.model.image_gallery.push({ path: this.noimg, width: 200, height: 200 })
  }
  deleteImageGallery(i) {
    this.model.image_gallery.splice(i, 1)
  }

  ngOnDestroy() {
    this.unsubscribe()
  }

  unsubscribe() {
    if (this.subscription_inuse) {
      this.subscription_inuse.unsubscribe()
    }
  }

  async checkInuseActivity(obj: any) {
    try {
      if (Array.isArray(obj)) {
        var rs: any = await this.Service_Util.inuseActivity({ type: 'product', param_id: obj })
        //console.log(rs)
        this.inuseActivityList = rs
      } else {
        var rs: any = await this.Service_Util.inuseActivity({ type: 'product', param_id: obj.id })
      }
      // console.log(rs);
      return rs
    } catch (err) {
      console.error(err)
    }
  }

  searchTag(event: string) {
    const obj = {
      name: event,
      orderby: 'popular',
      type: 'product',
    }
    this.Service_Product.searchProductTag(obj)
      .then((res: any) => {
        this.tagList = res.data
      })
      .catch((data) => {
        console.log(data.error.message)
      })
  }

  searchGroup(event: string) {
    console.log(event)
    const obj = {
      name: event,
    }
    this.Service_Product.searchProductGroup(obj)
      .then((res: any) => {
        this.groupList = res
      })
      .catch((data) => {
        console.log(data.error.message)
      })
  }

  tagSelect(event: any) {
    console.log(event)
    if (!event) {
      return
    }
    this.tmp_tag = ''
    let chk = false
    for (let i = 0; i < this.tag.length; i++) {
      chk = false
      if (this.tag[i].id == event.id) {
        chk = true
        break
      }
    }

    if (!chk) {
      this.tag.push(event)
    }
  }
  afterTagDelete(i: any) {
    console.log(i)
    this.tag.splice(i, 1)
  }

  add_newprop() {
    const obj = {
      id: 'new',
      property_info: '',
      status: !!0,
      instock: 0,
      properties_sort: this.propertie_tmp.length - 1,
      sku: '',
      title_lang1: this.model.title_lang1,
      expand: false,
      properties_main: false,
    }
    this.propertie_tmp.push(obj)
  }

  main_property_info_change(id, editfromprop = false, event) {
    //console.log(id, this)
    if (this.model.prop_enable == 1) {
      if (id == this.currentpro_id) {
        if (editfromprop) {
          this.model.property_info = event
        } else {
          this.ref_currentprop_in_tmp.val.property_info = event
        }
      }
    } else if (this.model.prop_enable == 2) {
      //console.log(id, this.currentpro_id, event)

      for (const current of this.propertie_tmp2) {
        if (current.id == id) {
          if (this.checkHasDefaultChild(current)) {
            current.property_info = event
            this.model.property_info = event
          }
        }
      }

      // if (id == this.currentpro_id) {
      //   for (let i = 0; i < this.propertie_tmp2.length; i++) {
      //     let current = this.propertie_tmp2[i];
      //     current.property_info = event;
      //     for (let j = 0; j < current.children.length; j++) {
      //       let currentchild = current.children[j];
      //       if (currentchild.id == id) {
      //         if (editfromprop) {
      //           this.model.property_info = event;
      //         } else {
      //           currentchild.property_info = event;
      //         }
      //         break;
      //       }
      //     }
      //   }
      // }
    }
  }

  sku_change(id, editfromprop = false, event) {
    console.log(editfromprop)
    if (this.model.prop_enable == 1) {
      // for(let i=0;i<this.propertie_tmp.length;i++){
      if (id == this.currentpro_id) {
        if (editfromprop) {
          this.model.sku = event
          console.log(1)
        } else {
          this.ref_currentprop_in_tmp.val.sku = event
          console.log(2)
        }
      }
      // if (editfromprop) {
      //   this.model.sku = event;
      // } else {
      //   this.ref_currentprop_in_tmp.val.sku = event;
      // }
    } else if (this.model.prop_enable == 2) {
      if (id == this.currentpro_id) {
        for (let i = 0; i < this.propertie_tmp2.length; i++) {
          const current = this.propertie_tmp2[i]
          for (let j = 0; j < current.children.length; j++) {
            const currentchild = current.children[j]
            if (currentchild.id == id) {
              console.log(1)
              if (editfromprop) {
                this.model.sku = event
              } else {
                currentchild.sku = event
              }
              break
            }
          }
        }
      }
    }
  }

  status_change(id, editfromprop = false, event) {
    console.log(event)
    event = +event ? 1 : 0

    if (this.model.prop_enable == 1) {
      if (id == this.currentpro_id) {
        if (editfromprop) {
          this.model.status = event
          console.log('ss ' + this.model.status)
          console.log(1)
        } else {
          this.ref_currentprop_in_tmp.val.status = !!+event
          console.log(2)
        }
      }
    } else if (this.model.prop_enable == 2) {
      if (id == this.currentpro_id) {
        for (let i = 0; i < this.propertie_tmp2.length; i++) {
          const current = this.propertie_tmp2[i]
          for (let j = 0; j < current.children.length; j++) {
            const currentchild = current.children[j]
            if (currentchild.id == id) {
              if (editfromprop) {
                this.model.status = event
                console.log(1)
              } else {
                currentchild.status = !!+event
                console.log(2)
              }
              break
            }
          }
        }
      }
    }
  }

  instock_change(id, editfromprop = false, event) {
    if (this.model.prop_enable == 1) {
      if (id == this.currentpro_id) {
        if (editfromprop) {
          this.model.instock = event
        } else {
          this.ref_currentprop_in_tmp.val.instock = event
        }
      }
    } else if (this.model.prop_enable == 2) {
      if (id == this.currentpro_id) {
        for (let i = 0; i < this.propertie_tmp2.length; i++) {
          const current = this.propertie_tmp2[i]
          for (let j = 0; j < current.children.length; j++) {
            const currentchild = current.children[j]
            if (currentchild.id == id) {
              if (editfromprop) {
                this.model.instock = event
              } else {
                currentchild.instock = event
              }
              break
            }
          }
        }
      }
    }
  }

  main_property_change(prop, name, id) {
    for (let i = 0; i < this.propertie_tmp.length; i++) {
      this.propertie_tmp[i].properties_main = false
    }
    prop.properties_main = true
    // $('input[type="radio"][name="' + name + '"]').prop('checked', false);
    // $('#' + id).prop('checked', true);
  }

  stopDragProperty(event, radioname, id) {
    console.log(event)
    // return;
    if (event.data.hasOwnProperty('properties_main') && event.data.properties_main) {
      $('input[type="radio"][name="' + radioname + '"]').prop('checked', false)
      $('#' + id + event.index).prop('checked', true)
    }
  }

  propenable_Change() {
    if (this.model.prop_enable) {
      // console.log(this.model.status);
      const status = this.model.status == '1' ? true : false
      this.sku_change(this.currentpro_id, false, this.model.sku)
      this.status_change(this.currentpro_id, false, status)
      this.instock_change(this.currentpro_id, false, this.model.instock)
      this.main_property_info_change(this.currentpro_id, false, this.model.property_info)
    }
  }

  remove_property(i) {
    if (this.propertie_tmp[i].id != 'new') {
      this.properties_delete.push(this.propertie_tmp[i].id)
    }
    this.propertie_tmp.splice(i, 1)
  }

  ///// 2 PROP //////

  stopDragProperty2(event, c) {
    console.log(c)
  }

  checkHasDefaultChild(prop) {
    let chk = false

    for (let i = 0; i < prop.children.length; i++) {
      if (prop.children[i] == this.show_main_property2.val) {
        chk = true
      }
    }
    return chk
  }

  remove_parentproperty(i) {
    for (let j = 0; j < this.propertie_tmp2[i].children.length; j++) {
      const current = this.propertie_tmp2[i].children[j]
      if (current != 'new') {
        this.properties_delete.push(current.id)
      }
    }
    this.propertie_tmp2.splice(i, 1)
    this.selectProp2 = this.propertie_tmp2[0]
  }

  remove_childproperty(i) {
    if (this.selectProp2.children[i].id != 'new') {
      this.properties_delete.push(this.selectProp2.children[i].id)
    }
    this.selectProp2.children.splice(i, 1)
  }

  property_info2_change(id, editfromprop = false, event) {
    if (id == this.currentpro_id) {
      if (editfromprop) {
        this.model.property_info2 = event
      } else {
        for (let i = 0; i < this.propertie_tmp2.length; i++) {
          for (let j = 0; j < this.propertie_tmp2[i].children.length; j++) {
            if (this.propertie_tmp2[i].children[j].id == id) {
              this.propertie_tmp2[i].children[j].property_info2 = event
            }
          }
        }
      }
    }
  }

  add_newchildprop() {
    const copy = cloneDeep(this.children_prototype)
    this.selectProp2.children.push(copy)
  }
  createTag() {
    const modal = this.modalService.create({
      nzTitle: 'Create Tag',
      nzContent: ModalTagComponent,
      nzWidth: '30%',
      nzKeyboard: false,
      nzMaskClosable: false,
      nzOnOk: (componentInstance) => {
        return componentInstance.ok()
      },
      nzOnCancel: (componentInstance) => {},
      nzComponentParams: {
        data: {
          state: 'create',
        },
      },
    })
    // modal.afterOpen.subscribe(() => console.log('[afterOpen] emitted!'));
    // Return a result when closed
    var _modal = modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result)
      if (result) {
        this.tagSelect(result)
      }
      _modal.unsubscribe()
    })
  }
  openmodal_addpropfromproduct() {
    // this.modaladdprop_fromproduct.show = true;
    const modal = this.modalService.create({
      nzTitle: 'เพิ่มคุณสมบัติจากสินค้าที่มี',
      nzContent: ModalAddpropfromProductComponent,
      nzWrapClassName: 'vertical-center-modal',
      nzWidth: '80%',
      nzKeyboard: false,
      nzMaskClosable: false,
      nzOkText: 'เพิ่มคุณสมบัติ',
      nzCancelText: 'ยกเลิก',
      nzOnOk: (componentInstance) => {
        return componentInstance.ok()
      },
      nzOnCancel: (componentInstance) => {},
      nzComponentParams: {
        data: {
          allcategory: this.allcategory,
          currentproduct: this.model,
          propertie_tmp: this.propertie_tmp,
          propertie_tmp2: this.propertie_tmp2,
          selectProp2: this.selectProp2,
        },
      },
    })
    //modal.afterOpen.subscribe(() => console.log('[afterOpen] emitted!'));
    // Return a result when closed
    //modal.afterClose.subscribe((result) => console.log('[afterClose] The result is:', result));
  }

  add_mainprop() {
    this.propertie_tmp2.push({ id: 'new' + this.propertie_tmp2.length, property_info: '', children: [] })
    console.log(this.propertie_tmp2)
  }

  selectMainProp(i) {
    this.selectProp2 = this.propertie_tmp2[i]
    //console.log(this.propertie_tmp2)
  }

  /// END 2 PROP
  validate() {
    for (let i = 0; i < this.lang.length; i++) {
      if (
        this.model['title_' + this.lang[i].ref] == '' ||
        this.model['title_' + this.lang[i].ref] == null ||
        this.model['title_' + this.lang[i].ref] == undefined
      ) {
        this.message.error(`โปรดกรอกชื่อสินค้า ( ${this.lang[i].name} )`, { nzDuration: this.message_duration })

        return false
      }
    }

    this.model.discount_price = Number(this.model.discount_price) || 0
    this.model.normal_price = Number(this.model.normal_price) || 0

    if (this.model.normal_price <= 0) {
      this.message.error(`ราคาปกติต้องมากกว่า 0`, { nzDuration: this.message_duration })
      return false
    }
    if (this.model.discount_price > 0) {
      if (this.model.discount_price > this.model.normal_price) {
        this.message.error(`ราคาลดเหลือ ต้องน้อยกว่า ราคาปกติ`, { nzDuration: this.message_duration })
        return false
      }
    }

    return true
  }

  clearGroup() {
    this.modalService.confirm({
      nzTitle: 'ยกเลิกคุณสมบัติสินค้า',
      nzContent: `<b style="color: red;">คุณต้องการนำสินค้าทั้งหมดออกจากคุณสมบัติสินค้า ?</b>`,
      nzOkText: 'Yes',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.Service_Product.clearProductProperties({ group_id: this.model.group_id })
          .then((data) => {
            location.reload()
          })
          .catch((data) => {
            console.log(data.error.message)
            this.message.error(data.error.message, { nzDuration: this.message_duration })
          })
      },
      nzCancelText: 'No',
      nzOnCancel: () => console.log('Cancel'),
    })
  }
  create() {}
  save() {
    // var add = difference(this.tagadd,this.oldtag);
    // var del = intersection(this.oldtag,this.tagdelete);
    // console.log(add);
    // console.log(del);

    for (const lang of this.lang) {
      if (this.model['title_' + lang.ref].length > 255) {
        alert(`โปรดระบุ  ชื่อสินค้า ภาษา ${lang.name}  ไม่เกิน 255 characters`)
      }
    }

    const alert_data = {
      status: false,
      text: '',
    }
    for (var _i of this.inuseActivityList) {
      if (!_i.available) {
        alert_data['status'] = true
        if (_i.param_id == this.currentpro_id) {
          alert_data['text'] =
            alert_data['text'] + `ผู้ใช้ ${_i.user} กำลังทำการแก้ไข SKU : ${this.model.sku} นี้อยู่\n`
        } else {
          const _product = this.propertie_list.find((x) => x.id == _i.param_id)
          alert_data['text'] = alert_data['text'] + `ผู้ใช้ ${_i.user} กำลังทำการแก้ไข SKU : ${_product.sku} นี้อยู่\n`
        }
      }
    }

    if (alert_data.status) {
      this.modalService.error({
        nzTitle: 'ไม่สามารถบันทึกได้',
        nzContent: alert_data.text,
        nzOkText: 'ปิด',
        nzOnOk: () => {},
      })
      return
    }

    this.model['use_all_properties'] = this.use_all_properties

    const chk = this.validate()
    if (!chk) {
      return
    }
    if (this.model.discount_price > 0 && this.model.discount_price != '') {
      this.model.price = this.model.discount_price
      this.model.temp_price = this.model.normal_price
    } else {
      this.model.price = this.model.normal_price
      this.model.temp_price = null
    }

    if (this.model.prop_enable == 0) {
      this.model.properties_main = 0
      this.model.property_info = null
      this.model.properties_desc = null
      this.model.properties_sort = 0
      this.model.group_id = null
      this.model.details = []
    } else if (this.model.prop_enable == 1) {
      this.model.details = []
      let chk = false
      const duplicate = []
      for (let i = 0; i < this.propertie_tmp.length; i++) {
        const _current = this.propertie_tmp[i]
        let current = this.propertie_list.find((x) => x.id == _current.id)
        if (!current) {
          current = _current
        }
        current.properties_main = 0
        current.properties_desc = this.model.properties_desc

        if (current.id != this.currentpro_id) {
          current.prop_enable = 1
          current.properties_sort = i
          current.instock = Number(current.instock) || 0
          if (current.property_info == '') {
            this.message.error(`โปรดกรอก ชื่อคุณสมบัติ ${current.title_lang1}`, { nzDuration: this.message_duration })
            chk = true
            break
          }
          this.model.details.push(current)
        } else if (current.id == this.currentpro_id) {
          current.properties_main = 1
          if (current.property_info == '') {
            this.message.error(`โปรดกรอก ชื่อคุณสมบัติ ${current.title_lang1}`, { nzDuration: this.message_duration })
            chk = true
            break
          }
          this.model.properties_sort = i
        }
      } // end for

      if (this.show_mainproperty.val && this.show_mainproperty.val.id == this.currentpro_id) {
        this.model.properties_main = 1
      } else {
        if (this.show_mainproperty.val) {
          this.show_mainproperty.val.properties_main = 1
        }
        this.model.properties_main = 0
      }

      const valueArr = this.model.details.map((item) => {
        return item.property_info
      })
      valueArr.push(this.model.property_info)
      let duplicatename = ''
      const isDuplicate = valueArr.some(function (item, idx) {
        if (valueArr.indexOf(item) != idx) {
          duplicatename = item
          return true
        }
      })
      if (isDuplicate) {
        this.message.error(`ชื่อคุณสมบัติ ${duplicatename} ได้ถูกใช้งานแล้ว`, { nzDuration: this.message_duration })
        chk = true
      }
      if (chk) {
        return
      }
    } else if (this.model.prop_enable == 2) {
      this.model.details = []
      let chk = false
      if (this.model.properties_desc == '') {
        this.message.error(`โปรดกรอก ประเภทคุณสมบัติหลัก`, { nzDuration: this.message_duration })
        return
      }
      if (this.model.properties_desc2 == '') {
        this.message.error(`โปรดกรอก ประเภทคุณสมบัติรอง`, { nzDuration: this.message_duration })
        return
      }
      for (let i = 0; i < this.propertie_tmp2.length; i++) {
        const current = this.propertie_tmp2[i]
        if (current.children.length == 0) {
          this.message.error(`โปรดเพิ่มคุณสมบัติรอง`, { nzDuration: this.message_duration })
          chk = true
          break
        }
        for (let j = 0; j < current.children.length; j++) {
          var _currentchild = current.children[j]
          let currentchild = this.propertie_list.find((x) => x.id == _currentchild.id)
          if (!currentchild) {
            currentchild = _currentchild
          }
          currentchild.prop_enable = 2
          currentchild.properties_sort = i
          currentchild.property_info = current.property_info
          currentchild.properties_sort2 = j
          if (current.property_info == '') {
            this.message.error(`โปรดกรอก ชื่อคุณสมบัติหลัก`, { nzDuration: this.message_duration })
            chk = true
            break
          }
          if (currentchild.property_info2 == '') {
            this.message.error(`โปรดกรอก ชื่อคุณสมบัติรอง`, { nzDuration: this.message_duration })
            chk = true
            break
          }
          if (currentchild.id != this.currentpro_id) {
            if (currentchild.instock == '' || currentchild.instock == undefined) {
              currentchild.instock = 0
            }
            this.model.details.push(currentchild)
          } else if (currentchild.id == this.currentpro_id) {
            this.model.properties_sort = i
            this.model.properties_sort2 = j
          }
        }
      } // end for

      if (this.show_main_property2.val && this.show_main_property2.val.id == this.currentpro_id) {
        this.model.properties_main = 1
      } else {
        if (this.show_main_property2.val) {
          this.show_mainproperty.val.properties_main = 1
        }
        this.model.properties_main = 0
      }
      const valueArr = this.propertie_tmp2.map(function (item) {
        return item.property_info
      })
      let duplicatename = ''
      let duplicatemain = ''
      let isDuplicate2 = false
      const isDuplicate = valueArr.some(function (item, idx) {
        if (valueArr.indexOf(item) != idx) {
          duplicatename = item
          return true
        }
      })
      if (isDuplicate) {
        this.message.error(`ชื่อคุณสมบัติหลัก ${duplicatename} ได้ถูกใช้งานแล้ว`, { nzDuration: this.message_duration })
        chk = true
      }

      for (let i = 0; i < this.show_main_property2.length; i++) {
        const current = this.show_main_property2[i]
        const dup = []
        for (let j = 0; j < current.children.length; j++) {
          const currentchild = current.children[j]
          dup.push(currentchild.property_info2)
        }
        isDuplicate2 = dup.some(function (item, idx) {
          if (dup.indexOf(item) != idx) {
            duplicatemain = current.property_info
            duplicatename = item
            return true
          }
        })
      } //end for

      if (isDuplicate2) {
        this.message.error(`ชื่อคุณสมบัติหลัก ${duplicatemain} คุณสมบัติรอง ${duplicatename} ได้ถูกใช้งานแล้ว`, {
          nzDuration: this.message_duration,
        })
        chk = true
      }
      if (chk) {
        return
      }
    }

    // if ((this.model.prop_enable == 1 || this.model.prop_enable == 2) && this.model.details.length == 0) {
    //   this.model.prop_enable = 0;
    //   this.model.properties_main = 0;
    //   this.model.property_info = null;
    //   this.model.properties_desc = null;
    //   this.model.properties_sort = 0;
    //   this.model.group_id = null;
    // }

    $('.theme-loader').fadeIn()
    this.model.tags = this.tag

    this.model.deleteproperties = this.properties_delete

    // console.log(this.model);
    // this.router.navigate(['/system/e-commerce/product/edit/'+this.model.id]);
    // return;
    //console.log(this.model)

    if (this.use_all_properties.normal_price) {
      this.model.details.forEach((obj) => {
        obj.normal_price = this.model.normal_price
      })
    }

    if (this.use_all_properties.discount_price) {
      this.model.details.forEach((obj) => {
        obj.discount_price = this.model.discount_price
      })
    }

    // console.log(this.model.details.find(x=>x.sku =='OKZIZ-2413901'));

    // console.log(this.model);
    // return ;
    this.Service_Product.updateProduct(this.model)
      .then(async (data) => {
        //location.reload();

        console.log(data)
        this._drawerRef.close(true)
      })
      .catch((data) => {
        console.log(data.error.message)
        this.message.error(data.error.message, { nzDuration: 13000 })
      })
      .finally(() => {
        $('.theme-loader').fadeOut()
      })
  }
}
