import { h, Fragment } from 'preact'
import { IPurchaseOrderHtml } from './types'

export const TmpOrderNote = ({
  order,
  option: { include_note },
}: {
  order: IPurchaseOrderHtml.Order
  option: IPurchaseOrderHtml.Option
}) => (
  <Fragment>
    <div class="order-notes-wrapper">
      <div class="order-note">
        <p>
          <strong>หมายเหตุจากลูกค้า / Customer Note</strong> {order.customer_note || '-'}
        </p>
      </div>
      {include_note && (
        <div class="order-note">
          <p>
            <strong>หมายเหตุภายใน / Remark : </strong> {order.note || '-'}
          </p>
        </div>
      )}
    </div>
  </Fragment>
)
