import { Component, OnInit, Input } from '@angular/core'
import { UtilService } from '@cms/services/util.service'
import { BlogsService } from '@cms/services/blogs.service'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzMessageService } from 'ng-zorro-antd/message'

@Component({
  selector: 'app-modal-create-blog-category',
  templateUrl: './modal-create-blog-category.component.html',
  styleUrls: ['./modal-create-blog-category.component.css'],
})
export class ModalCreateBlogCategoryComponent implements OnInit {
  @Input() data: any

  constructor(
    private Service_Util: UtilService,
    private Service_Blog: BlogsService,
    private modal: NzModalRef,
    private message: NzMessageService,
  ) {}

  lang: any = []
  message_duration = 7000
  model: any = {
    lang1: '',
    lang2: '',
    lang3: '',
    lang4: '',
  }
  async ngOnInit() {
    this.lang = await this.Service_Util.getLangs()
    console.log(this.lang)
  }

  async ok() {
    for (let i = 0; i < this.lang.length; i++) {
      console.log(this.model[this.lang[i].ref])
      if (this.model[this.lang[i].ref].length > 100) {
        alert(`โปรดระบุ หมวดหมู่สินค้า ภาษา ${this.lang[i].name}  ไม่เกิน 100 characters`)
      }
      if (this.model[this.lang[i].ref] == null || this.model[this.lang[i].ref].trim() == '') {
        this.message.error(`Please fill tag name ( ${this.lang[i].name} )`, { nzDuration: this.message_duration })
        return false
      }
    }

    try {
      await this.Service_Blog.createCategory(this.model)
      this.modal.close({ data: true })
    } catch (e) {
      console.log(e)
      this.message.error(e.error.message, { nzDuration: this.message_duration })
      return false
    }
  }
}
