import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root',
})
export class CipherService {
  constructor() {}

  getTokenLocalStore(key: string, data: string) {
    const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0))
    const applySaltToChar = (code) => textToChars(key).reduce((a, b) => a ^ b, code)
    const _text = (encoded) =>
      encoded
        .match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 16))
        .map(applySaltToChar)
        .map((charCode) => String.fromCharCode(charCode))
        .join('')
    return _text(data)
  }

  cipher(key: string, data: string) {
    const _0x2586 = ['split', 'map', 'charCodeAt', 'substr', 'join', 'reduce']
    ;(function (_0x55c800, _0x258644) {
      const _0x1369ae = function (_0x184078) {
        while (--_0x184078) {
          _0x55c800['push'](_0x55c800['shift']())
        }
      }
      _0x1369ae(++_0x258644)
    })(_0x2586, 0x1cc)
    const _0x1369 = function (_0x55c800, _0x258644?) {
      _0x55c800 = _0x55c800 - 0x0
      const _0x1369ae = _0x2586[_0x55c800]
      return _0x1369ae
    }
    const textToChars = (_0x2a3164) =>
      _0x2a3164[_0x1369('0x2')]('')[_0x1369('0x3')]((_0x44f8fc) => _0x44f8fc[_0x1369('0x4')](0x0))
    const byteHex = (_0x188fe6) => ('0' + Number(_0x188fe6)['toString'](0x10))[_0x1369('0x5')](-0x2)
    const applySaltToChar = (_0x5d54fa) =>
      textToChars(key)[_0x1369('0x1')]((_0xad6288, _0x456776) => _0xad6288 ^ _0x456776, _0x5d54fa)
    const _text = (_0x4e5cb0) =>
      _0x4e5cb0[_0x1369('0x2')]('')
        ['map'](textToChars)
        ['map'](applySaltToChar)
        [_0x1369('0x3')](byteHex)
        [_0x1369('0x0')]('')
    return _text(data)
  }
}
