import { Component, OnInit } from '@angular/core'
import { FrontsService } from '@cms/services/fronts.service'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalService } from 'ng-zorro-antd/modal'
import { UtilService } from '@cms/services/util.service'
import { ModalEditMenuComponent } from '@cms/system/modal/modal-edit-menu/modal-edit-menu.component'
import { ModalCreateMenuComponent } from '@cms/system/modal/modal-create-menu/modal-create-menu.component'
import { FeatureService } from '@cms/services/feature.service'
import { CookieService } from 'ngx-cookie'
import { NestableSettings } from '@ketshopweb/ngx-nestable'
import { IKetshopwebFrontendService, KetshopwebFrontendService } from '@cms/services/ketshopweb-frontend.service'

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit {
  constructor(
    private Service_Front: FrontsService,
    private Service_Util: UtilService,
    private modal: NzModalService,
    private message: NzMessageService,
    private Service_Feature: FeatureService,
    private _cookie: CookieService,
    private ketshopwebFrontendService: KetshopwebFrontendService,
  ) {}

  options = {
    fixedDepth: false,
    maxDepth: 3,
    threshold: 20,
    group: 1,
    sendToGroups: [2],
  } as NestableSettings
  options2 = {
    fixedDepth: false,
    maxDepth: 3,
    threshold: 20,
    group: 2,
    sendToGroups: [1],
  } as NestableSettings

  menu: any = []
  pagelist: any = []
  en1 = 1
  en0 = 0
  lang: any = []
  tmp_to_pagelist: any = []
  tmp_to_display: any = []
  message_duration = 4000
  feature: any = {}
  limitFeatureMessage = 'แพ็คเกจของคุณไม่สามารถใช้งานส่วนนี้ได้'
  disabled_group = false

  readonly menuType = IKetshopwebFrontendService.MenuType
  async ngOnInit() {
    this.feature.PRODUCT_PAGE = await this.Service_Feature.getFeature('PRODUCT_PAGE')
    this.feature.BLOG_PAGE = await this.Service_Feature.getFeature('BLOG_PAGE')
    this.lang = await this.Service_Util.getLangs()
    this.init(true, true)
  }
  async init(menu_data, pagelist) {
    if (menu_data) {
      // this.menu = await this.Service_Front.getMenuBackEnd();
      const menu = (await this.ketshopwebFrontendService.getMenuBack()) as any[]
      for (let i = 0; i < menu.length; i++) {
        const current = menu[i]
        current.$$expanded = true
        current._checked = false
        current._disabled = false
        for (let j = 0; j < current.childrens.length; j++) {
          const current2 = current.childrens[j]
          current2.$$expanded = true
          current2._checked = false
          current2._disabled = false
          for (let k = 0; k < current2.childrens.length; k++) {
            const current3 = current2.childrens[k]
            current3.$$expanded = true
            current3._checked = false
            current3._disabled = false
          }
        }
      }
      this.menu = menu
      console.log(' this.menu', this.menu)
    }

    if (pagelist) {
      // this.pagelist = await this.Service_Front.getMenuPageList();
      this.pagelist = await this.ketshopwebFrontendService.getMenuPageList()
      for (let i = 0; i < this.pagelist.length; i++) {
        const current = this.pagelist[i]
        current.$$expanded = true
        current._checked = false
        current._disabled = false
        this.chekcFeature(current)
        for (let j = 0; j < current.childrens.length; j++) {
          const current2 = current.childrens[j]
          current2.$$expanded = true
          current2._checked = false
          current2._disabled = false
          this.chekcFeature(current2)
          for (let k = 0; k < current2.childrens.length; k++) {
            const current3 = current2.childrens[k]
            current3.$$expanded = true
            current3._checked = false
            current3._disabled = false
            this.chekcFeature(current3)
          }
        }
      }
    }

    try {
      this._cookie.remove('pages')
      window['_load_header']()
    } catch (err) {}
  }

  // dragStart
  onDragAction(event: any, type: string) {
    if (type == 'drag' && event.item && event.item.column_menu_id) {
      // this.options.fixedDepth = true
      this.options.maxDepth = 1
      // this.options2.fixedDepth = true
      this.options2.maxDepth = 1
      this.disabled_group = true
    }

    if (type == 'listChange') {
      // this.options2.fixedDepth = false
      this.options.maxDepth = 3
      // this.options.fixedDepth = false
      this.options2.maxDepth = 3
      this.disabled_group = false
    }
  }

  chekcFeature(obj) {
    if (obj.page == 'Products' && !this.feature.PRODUCT_PAGE) {
      obj._disabled = true
    }
    if (obj.page == 'Blog' && !this.feature.BLOG_PAGE) {
      obj._disabled = true
    }
  }

  checkChangeMenuPageList(obj) {
    // if(!this.feature.)

    obj.childrens.forEach((data2) => {
      data2._checked = obj._checked
      data2._disabled = obj._checked
      const i = this.tmp_to_display.findIndex((x) => x.page_id == data2.page_id)
      if (i != -1) {
        this.tmp_to_display.splice(i, 1)
      }
      data2.childrens.forEach((data3) => {
        data3._checked = obj._checked
        data3._disabled = obj._checked
        const i = this.tmp_to_display.findIndex((x) => x.page_id == data3.page_id)
        if (i != -1) {
          this.tmp_to_display.splice(i, 1)
        }
      })
    })

    if (obj._checked) {
      this.tmp_to_display.push(obj)
    } else {
      this.tmp_to_display.forEach((element, index) => {
        if (element.page_id == obj.page_id) {
          this.tmp_to_display.splice(index, 1)
        }
      })
    }

    console.log(this.tmp_to_display)
  }

  checkChangeMenuDisplay(obj) {
    console.log(obj)
    obj.childrens.forEach((data2) => {
      data2._checked = obj._checked
      data2._disabled = obj._checked
      const i = this.tmp_to_pagelist.findIndex((x) => x.page_id == data2.page_id)
      if (i != -1) {
        this.tmp_to_pagelist.splice(i, 1)
      }
      data2.childrens.forEach((data3) => {
        data3._checked = obj._checked
        data3._disabled = obj._checked
        const i = this.tmp_to_pagelist.findIndex((x) => x.page_id == data3.page_id)
        if (i != -1) {
          this.tmp_to_pagelist.splice(i, 1)
        }
      })
    })

    if (obj._checked) {
      this.tmp_to_pagelist.push(obj)
    } else {
      this.tmp_to_pagelist.forEach((element, index) => {
        if (element.page_id == obj.page_id) {
          this.tmp_to_pagelist.splice(index, 1)
        }
      })
    }

    console.log(this.tmp_to_pagelist)
  }
  addToDisplay() {
    if (this.tmp_to_display.length == 0) {
      this.message.error('Please select some menu', { nzDuration: this.message_duration })
      return
    }

    this.Service_Front.addToDisplay(this.tmp_to_display)
      .then((res: any) => {
        this.tmp_to_display = []
        this.init(true, true)
      })
      .catch((data) => {
        this.message.error(data.error.message, { nzDuration: this.message_duration })
      })
  }
  addToPageList() {
    console.log(this.tmp_to_pagelist)
    if (this.tmp_to_pagelist.length == 0) {
      this.message.error('Please select some menu', { nzDuration: this.message_duration })
      return
    }

    this.Service_Front.addToPageList(this.tmp_to_pagelist)
      .then((res: any) => {
        this.tmp_to_pagelist = []
        this.init(true, true)
      })
      .catch((data) => {
        this.message.error(data.error.message, { nzDuration: this.message_duration })
      })
  }

  edit(data) {
    console.log(data)
    const modal = this.modal
      .create({
        nzTitle: 'แก้ไขเมนู',
        nzContent: ModalEditMenuComponent,
        nzStyle: { top: '20px' },
        nzWidth: '70%',
        nzKeyboard: false,
        nzMaskClosable: false,
        nzOnOk: (componentInstance) => {
          return componentInstance.ok()
        },
        nzOnCancel: (componentInstance) => {},
        nzComponentParams: {
          data: {
            page: data,
          },
        },
      })
      .afterClose.subscribe((result) => {
        modal.unsubscribe()
        console.log(result)
        if (result) {
          this.init(true, true)
        }
      })
  }

  create(type) {
    const width = type == 'product' || type == 'blog' ? '80%' : '60%'
    const modal = this.modal
      .create({
        nzTitle: 'สร้างเมนู',
        nzContent: ModalCreateMenuComponent,
        nzWrapClassName: 'vertical-center-modal',
        nzWidth: '60%',
        nzKeyboard: false,
        nzMaskClosable: false,
        nzOnOk: (componentInstance) => {
          return componentInstance.ok()
        },
        nzOnCancel: (componentInstance) => {},
        nzComponentParams: {
          data: {
            type: type,
          },
        },
      })
      .afterClose.subscribe((result) => {
        modal.unsubscribe()
        console.log(result)
        if (result) {
          this.init(true, true)
        }
      })
  }

  delete(obj) {
    this.modal.confirm({
      nzTitle: `Are you sure to delete menu ${obj.lang1} ?`,
      nzContent: '',
      nzOkText: 'Yes',
      nzOkType: 'danger',
      nzOnOk: () => {
        // this.Service_Front.deleteMenu(obj)
        this.ketshopwebFrontendService.deleteMenu(obj.page_id).then((res: any) => {
          this.init(true, true)
        })
      },
      nzCancelText: 'No',
      nzOnCancel: () => {},
    })
  }

  changeStatus(data) {
    if (data.page == 'Products' && !this.feature.PRODUCT_PAGE) {
      this.modal.error({
        nzTitle: 'Error',
        nzContent: this.limitFeatureMessage,
        nzOnOk: () => {
          data.enable = false
        },
      })
      return
    }
    if (data.page == 'Blog' && !this.feature.BLOG_PAGE) {
      this.modal.error({
        nzTitle: 'Error',
        nzContent: this.limitFeatureMessage,
        nzOnOk: () => {
          data.enable = false
        },
      })
      return
    }

    // this.Service_Front.updateMenuEnable(data)
    this.ketshopwebFrontendService
      .updateMenuEnable(data)
      .then((res: any) => {})
      .catch((data) => {
        this.message.error(data.error.message, { nzDuration: this.message_duration })
      })
  }

  dropMenu(event: {
    changedElementPosition: boolean
    originalEvent: {
      type: 'group-dispatch' | 'mouseup'
    }
  }) {
    if (!event.changedElementPosition && event.originalEvent.type !== 'group-dispatch') return
    // console.log(event)
    // console.log('dropPageList', event)
    //if (event.changedElementPosition) {
    this.tmp_to_pagelist = []
    // this.init();

    for (let i = 0; i < this.menu.length; i++) {
      const current = this.menu[i]
      current.showinmenu = 1
      for (let j = 0; j < current.childrens.length; j++) {
        const current2 = current.childrens[j]
        current2.showinmenu = 1
        for (let k = 0; k < current2.childrens.length; k++) {
          const current3 = current2.childrens[k]
          current3.showinmenu = 1
        }
      }
    }

    // this.Service_Front.updateMenuSort(this.menu)
    this.ketshopwebFrontendService
      .updateMenuSort(this.menu)
      .then((res: any) => {
        this.init(true, false)
      })
      .catch((data) => {
        this.message.error(data.error.message, { nzDuration: this.message_duration })
      })

    // }
  }

  dropPageList(event: {
    changedElementPosition: boolean
    destination: any
    originalEvent: {
      type: 'group-dispatch' | 'mouseup'
    }
  }) {
    console.log('dropPageList', event)

    if (!event.changedElementPosition) {
      if (event.destination == null) {
        return
      }
    }
    // if (!event.changedElementPosition && event.originalEvent.type !== 'group-dispatch') {
    // console.log('RETURN')
    // return
    // }
    // if(this.menu.lenght == 0){
    //   this.menu.push(this.pagelist[0])

    // }
    // console.log('dropPageList', event)
    //if (event.changedElementPosition) {
    this.tmp_to_pagelist = []
    // this.init();
    for (let i = 0; i < this.pagelist.length; i++) {
      const current = this.pagelist[i]
      current.showinmenu = 0
      for (let j = 0; j < current.childrens.length; j++) {
        const current2 = current.childrens[j]
        current2.showinmenu = 0
        for (let k = 0; k < current2.childrens.length; k++) {
          const current3 = current2.childrens[k]
          current3.showinmenu = 0
        }
      }
    }
    // this.Service_Front.updateMenuSort(this.pagelist)
    this.ketshopwebFrontendService
      .updateMenuSort(this.pagelist)
      .then((res: any) => {
        this.init(false, true)
      })
      .catch((data) => {
        this.message.error(data.error.message, { nzDuration: this.message_duration })
      })

    //}
  }
}
