import { Component, OnInit, Input } from '@angular/core'
import { NzModalRef } from 'ng-zorro-antd/modal'
@Component({
  selector: 'ket-consent-popup',
  templateUrl: './consent-popup.component.html',
  styleUrls: ['./consent-popup.component.less'],
})
export class KetConsentPopupComponent implements OnInit {
  @Input() channel_selected: string
  @Input() type = 'consent'

  constructor(private nzModalRef: NzModalRef) {}

  consent_terms_privacy_policy = false
  consent_conditions_news = false

  ngOnInit(): void {}

  on3rdConsent($event: any) {
    if ($event) {
      if ($event.type == 'consent_terms_privacy_policy') this.consent_terms_privacy_policy = $event.consent
      if ($event.type == 'consent_conditions_news') this.consent_conditions_news = $event.consent
    }
  }

  onCancelConsent() {
    this.nzModalRef.close(false)
  }

  onConsent() {
    this.nzModalRef.close({
      consent_terms_privacy_policy: this.consent_terms_privacy_policy,
      consent_conditions_news: this.consent_conditions_news,
    })
  }
}
