import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetSelectPromoParticipatingProductComponent } from './select-promo-participating-product.component'
import { NzButtonModule, NzEmptyModule, NzModalModule, NzTableModule, NzTagModule } from 'ng-zorro-antd'
import { KetModalAddproductPromotionModule } from '../modal-addproduct-promotion'
import { KetProductItemInlineModule } from '../ket-product-components/product-item-inline'

@NgModule({
  declarations: [KetSelectPromoParticipatingProductComponent],
  imports: [
    CommonModule,
    NzTableModule,
    NzTagModule,
    NzButtonModule,
    NzModalModule,
    NzEmptyModule,
    KetModalAddproductPromotionModule,
    KetProductItemInlineModule,
  ],
  exports: [KetSelectPromoParticipatingProductComponent],
  entryComponents: [KetSelectPromoParticipatingProductComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class KetSelectPromoParticipatingProductModule {}
