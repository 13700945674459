import { h, hydrate, Fragment } from 'preact'

export const CssGlobal = () => (
  <style>
    {`* {
        *, ::before, ::after {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
        }
        html,body{
            font-family: sans-serif;
            background-color: #f5f5f5;
        }
        .text-left{
            text-align: left!important;
        }
        .text-center{
            text-align: center!important;
        }
        .text-right{
            text-align: right!important;
        }
        .text-truncate{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .text-title,
        .text-subtitle{
            font-weight: bold;
        }
        .pagebreak{
            clear: both;
            page-break-after: always;
        }
        .order-header-wrapper{
            display: grid;
            grid-template-columns: 100px 1fr;
            align-items: center;
            border: 1px solid #000;
        }
        .order-header-qrcode{
            padding: 8px;
        }
        .order-header-qrcode-image{
            width: 100%;
            height: auto;
        }
        .grid-2column{
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        .order-address-wrapper{
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        .order-address{
            border: 1px solid #000;
            border-top: 0;
        }
        .order-address:nth-child(2){
            border-left: 0;
        }
        .table-product-wrapper{
            width: 100%;
            border-collapse: collapse;
            border: 1px solid #000;
        }
        .table-product-wrapper thead tr{
            border-top: 1px double #000;
            border-bottom: 1px solid #000;
        }
        .table-product-wrapper tbody tr{
            border-bottom: 1px solid #000;
        }
        .table-product-no{
            display:flex;
            justify-content: center;
            align-items: flex-end;
        }

        .table-product-no-head{
          text-align: center;
              width: 40px;
        }

        .col-product-qty{
            display:flex;
            justify-content: end;
            align-items: flex-start;
        }

         .col-product-qty-head{
          text-align: left;
        }
            
        .table-product-detail{
            text-align: left;
        }
        .table-product-price,
        .table-product-qty,
        .table-product-total{
            text-align: right;
        }
        .product-wrapper{
            display: grid;
            grid-template-columns: auto 1fr;
        }
        .product-wrapper .product-image{
            overflow: hidden;
        }
        .product-wrapper .product-image img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .order-note{
            border: 1px solid #000;
        }

         .page-46n-portrait{
            font-size: 10pt;
            line-height: 1.3;
            max-width: 6in;
            background-color: #fff;
            margin-bottom: 16px;
            height: 100%;
        }

         .page-46n2-portrait{
            font-size: 10pt;
            line-height: 1.3;
            max-width: 6in;
            background-color: #fff;
            margin-bottom: 16px;
            grid-template-rows: repeat(2, 1fr);
            h
        }


        .page-a4-8-portrait{
          min-height: 250px;
          display: grid;
          grid-template-rows: repeat(4, 1fr);
          grid-template-columns: 1fr 1fr;
          aspect-ratio: 1/1.4;
          background-color: #fff;
          margin: auto;
        }
        
        .page-a4-8{
          padding: 2mm;
          max-height: 300px;
        }

        .page-a4-portrait{
            font-size: 10pt;
            line-height: 1.3;
            max-width: 800px;
            aspect-ratio: 1/1.4;
            background-color: #fff;
            margin: auto;
            margin-bottom: 16px;
        }
        .page-a4-portrait .page-a4-full{
            padding: 4mm;
        }
        .page-a4-portrait .text-title{
            font-size: 12pt;
            margin-bottom: 4px;
        }
        .page-a4-portrait .text-subtitle{
            font-size: 11pt;
            margin-bottom: 4px;
        }
        .page-a4-portrait .text-small{
            font-size: 9pt;
        }
        .page-a4-portrait .order-address{
            padding: 8px;
        }
        .page-a4-portrait .table-product-wrapper thead th,
        .page-a4-portrait .table-product-wrapper tbody td{
            padding: 4px 8px;
        }
        .page-a4-portrait .table-product-wrapper tfoot td{
            padding: 2px 8px;
        }
        .page-a4-portrait .table-product-no{
            width: 50px;
        }
        .page-a4-portrait .table-product-qty{
            width: 60px;
        }
        .page-a4-portrait .table-product-price,
        .page-a4-portrait .table-product-total{
            width: 86px;
        }
        .page-a4-portrait .product-wrapper .product-image{
            width: 36px;
            height: 36px;
        }
        .page-a4-portrait .product-wrapper .product-detail{
            padding-left: 8px;
        }
        .page-a4-portrait .order-note{
            padding: 8px;
        }

        .page-a4-landscape{
            display: grid;
            grid-template-columns: 1fr 1fr;
            font-size: 8pt;
            line-height: 1.25;
            max-width: 1120px;
            aspect-ratio: 1.4/1;
            background-color: #fff;
            margin: auto;
            margin-bottom: 16px;
        }
        .page-a4-landscape .page-a4-half{
            padding: 2mm;
        }
        .page-a4-landscape .text-title{
            font-size: 9pt;
        }
        .page-a4-landscape .text-subtitle{
            font-size: 8.5pt;
        }
        .page-a4-landscape .text-small{
            font-size: 7pt;
        }
        .page-a4-landscape .order-header-wrapper{
            grid-template-columns: 64px 1fr;
            align-items: start;
        }
        .page-a4-landscape .order-header-qrcode{
            padding: 4px;
        }
        .page-a4-landscape .order-header-detail{
            padding: 4px 0;
        }
        .page-a4-landscape .order-address{
            padding: 4px;
        }
        .page-a4-landscape .table-product-wrapper thead th, 
        .page-a4-landscape .table-product-wrapper tbody td{
            padding: 4px;
        }
        .page-a4-landscape .table-product-wrapper tfoot td{
            padding: 0px 4px;
        }
        .page-a4-landscape .table-product-no{
            width: 30px;
        }
        .page-a4-landscape .table-product-qty{
            width: 40px;
        }
        .page-a4-landscape .table-product-price,
        .page-a4-landscape .table-product-total{
            width: 64px;
        }
        .page-a4-landscape .product-wrapper .product-image{
            width: 26px;
            height: 26px;
        }
        .page-a4-landscape .product-wrapper .product-detail{
            padding-left: 4px;
        }
        .page-a4-landscape .order-note{
            padding: 4px;
        }

        .tmt-break-page.size-4x6{
            max-width: 6in;
        }

        @media print{
            .page-a4-portrait,
            .page-46n-portrait,
            .page-46n-portrait,
            .page-a4-landscape{
                max-width: none;
                aspect-ratio: auto;
                margin: unset;
                margin-bottom: 0;
            }   

            .tmt-break-page.size-4x6{
               max-width: 6in;
               max-height: none;
               aspect-ratio: auto;
               margin: unset;
               margin-bottom: 0;
            }  
        }
    }`}
  </style>
)
