import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetModalQuotationComponent } from './modal-quotation.component'
import { FormsModule } from '@angular/forms'
import { SharedModule } from '@cms/shared/shared.module'


@NgModule({
  declarations: [
    KetModalQuotationComponent
  ],
  entryComponents:[
    KetModalQuotationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule
  ],
  exports:[
    KetModalQuotationComponent
  ]
})
export class KetModalQuotationModule { }