import { Component, OnInit, Input } from '@angular/core'
import { Router } from '@angular/router'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzMessageService } from 'ng-zorro-antd/message'
import { CustomerService } from '@cms/services/customer.service'
import { OrderService } from '@cms/services/order.service'
import { Etc } from '@cms/util/etc'
import { UtilService } from '@cms/services/util.service'

declare let navigator: any

@Component({
  selector: 'app-modal-view-customer',
  templateUrl: './modal-view-customer.component.html',
  styleUrls: ['./modal-view-customer.component.css'],
})
export class ModalViewCustomerComponent implements OnInit {
  @Input() data: any
  message_duration = 4000
  model: any = {}
  statusList: any
  shippingaddress: any
  order: any[] = []
  total = 0
  modelsearch: any = {
    startdate: '',
    enddate: '',
    status: '',
    ordercode: '',
    email: '',
    trackcode: '',
    search: '',
    paymenttype: '',
    user_id: '',
    created_by: '',
    page: 1,
    perpage: 5,
  }
  statusimg: any
  paymentimg: any
  loading = false
  shippopimg: any = {}
  constructor(
    private message: NzMessageService,
    private Service_Order: OrderService,
    private Service_Util: UtilService,
    private _etc: Etc,
  ) {}

  channels: any = {}
  listChannels: any[] = []

  async ngOnInit() {
    this.listChannels = (await this.Service_Util.getChannels()) as any[]
    for (const ch of this.listChannels) {
      this.channels[ch.value] = {
        img: ch.img,
        name: ch.name,
      }
    }
    this.shippopimg = this.Service_Util.getShippopImage()
    this.statusimg = this._etc.orderstatus_img()
    this.paymentimg = this._etc.payment_img()
    this.model = this.data.customer
    this.modelsearch.user_id = this.model.id
    this.statusList = this.data.statusList
    let _shippingaddress = this.model.address.find((data) => {
      return data.id == this.model.lastbill
    })
    if (!_shippingaddress) {
      _shippingaddress = {
        name: `${this.model.name} ${this.model.lastname}`,
        address1: this.model.address1,
        subdistrict: this.model.subdistrict,
        district: this.model.district,
        tel: this.model.tel,
        province: this.model.province,
        zipcode: this.model.zipcode,
      }
    }
    this.shippingaddress = _shippingaddress
    this.search()
  }

  copy(t: any) {
    try {
      const text = `${window.location.origin}/order-page/${this.Service_Util.enCodeId(t)}`
      navigator.clipboard.writeText(text).then((res: any) => {
        this.message.success('copy success')
      })
    } catch (err) {}
  }

  pagechage(page) {
    this.modelsearch.page = page
    this.search()
  }
  search() {
    this.loading = true
    this.Service_Order.search(this.modelsearch)
      .then((res: any) => {
        console.log(res)
        this.order = res.data
        this.total = res.count
      })
      .catch((data) => {
        console.log(data.error.message)
      })
      .finally(() => {
        this.loading = false
      })
  }
  editOrder(obj) {
    const url = '/system/e-commerce/order-detail/' + obj.ordercode
    window.open(url, '_blank', 'menubar=no,toolbar=no,location=no,width=1280,height=720')
  }
  ok() {}
}
