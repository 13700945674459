import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { TeamsEffects } from './effects'
import { TeamsState_FEATURE_KEY, TeamsStateReducer } from './reducer'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(TeamsState_FEATURE_KEY, TeamsStateReducer),
    EffectsModule.forFeature([TeamsEffects]),
  ],
})
export class TeamsSateModule {}
