import { Component, OnInit, Input, ViewChild } from '@angular/core'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzMessageService } from 'ng-zorro-antd/message'
import { UtilService } from '@cms/services/util.service'
import * as moment from 'moment'
import { FeatureService } from '@cms/services/feature.service'
import { IKetshopwebPromotionService, KetshopwebPromotionService } from '@cms/services/ketshopweb-promotion.service'
import { IProduct } from '@cms/types'
import { KetSelectPromoParticipatingProductComponent } from '@ketshopweb/ui/select-promo-participating-product'
import { ICmsCustomer, KetshopwebCustomerService } from '@cms/services/ketshopweb-customer.service'

export namespace IModalAddEditCouponComponent {
  export enum StateEnum {
    Create = 'create',
    Edit = 'edit',
  }

  export type Model = {
    [Property in keyof Pick<
      IKetshopwebPromotionService.ICoupon.CreateData,
      'status' | 'is_use_total' | 'is_use_max_discount' | 'freeshipping' | 'display'
    >]-?: boolean
  } & Pick<
    IKetshopwebPromotionService.ICoupon.CreateData,
    | 'title'
    | 'prefix'
    | 'code'
    | 'type'
    | 'startdate'
    | 'enddate'
    | 'value'
    | 'value_type'
    | 'max_use'
    | 'total'
    | 'total_type'
    | 'max_discount'
    | 'coupon_type'
    | 'promo_participating_products'
  >
}
@Component({
  selector: 'app-modal-add-edit-coupon',
  templateUrl: './modal-add-edit-coupon.component.html',
  styleUrls: ['./modal-add-edit-coupon.component.css'],
})
export class ModalAddEditCouponComponent implements OnInit {
  @Input() data: {
    coupon_id: number
    generate_type: IKetshopwebPromotionService.ICoupon.GenerateTypeEnum
    state: IModalAddEditCouponComponent.StateEnum
  } = {
    coupon_id: 0,
    generate_type: IKetshopwebPromotionService.ICoupon.GenerateTypeEnum.Single,
    state: IModalAddEditCouponComponent.StateEnum.Create,
  }
  readonly stateEnum = IModalAddEditCouponComponent.StateEnum
  readonly generateTypeEnum = IKetshopwebPromotionService.ICoupon.GenerateTypeEnum
  readonly typeEnum = IKetshopwebPromotionService.ICoupon.TypeEnum
  readonly valueTypeEnum = IKetshopwebPromotionService.ICoupon.ValueTypeEnum
  readonly couponTypeEnum = IKetshopwebPromotionService.ICoupon.CouponTypeEnum
  @ViewChild(KetSelectPromoParticipatingProductComponent, { static: false })
  ketSelectPromoParticipatingProductComponent: KetSelectPromoParticipatingProductComponent

  constructor(
    private modal: NzModalRef,
    private message: NzMessageService,
    private Service_Util: UtilService,
    private Service_Feature: FeatureService,
    private Service_KetshopwebPromotion: KetshopwebPromotionService,
    private Service_Ketshopweb_Customer: KetshopwebCustomerService,
  ) {
    // private settingService: SettingService,
  }

  coupon: IKetshopwebPromotionService.ICoupon.EntityWithRelated = null
  model: IModalAddEditCouponComponent.Model = {
    prefix: '',
    type: IKetshopwebPromotionService.ICoupon.TypeEnum.All,
    status: true,
    title: '',
    code: '',
    startdate: '',
    enddate: '',
    value: 0,
    value_type: IKetshopwebPromotionService.ICoupon.ValueTypeEnum.Normal,
    is_use_total: false,
    max_use: 0,
    total_type: IKetshopwebPromotionService.ICoupon.TotalTypeEnum.Value,
    total: 0,
    is_use_max_discount: false,
    max_discount: 0,
    freeshipping: false,
    display: false,
    coupon_type: IKetshopwebPromotionService.ICoupon.CouponTypeEnum.Order,
    promo_participating_products: [],
  }
  initial_participating_product_ids: number[] = []
  rangePicker: any = []
  currentdatetime: any
  message_duration = 4000
  feature_shipping_inter = false

  promo_type = 'coupon'
  promo_id = 0
  member_level_select = [0, 1]
  @Input() member_levels = [] as ICmsCustomer.MemberLevelCms[]
  @Input() apply_member_level = [] as IKetshopwebPromotionService.PromoApplyMemberLevelEntity[]
  @Input() member_level_status = false

  async ngOnInit() {
    // const pageOptions: any = await this.settingService.searchOption({ pop_key: ['member_level_status'] })
    // console.log('pageOptions', pageOptions)
    // for (const pageOption of pageOptions) {
    //   if (pageOption.pop_key == 'member_level_status') {
    //     this.member_level_status = Boolean(Number(pageOption.pop_value))
    //   }
    // }
    this.feature_shipping_inter = await this.Service_Feature.getFeature('SHIPPING_INTER')
    if (this.data.coupon_id) {
      this.coupon = await this.Service_KetshopwebPromotion.getCoupon(this.data.coupon_id).catch((err) => {
        this.message.error(err.error.message)
        return null
      })
    }

    if (this.data.state === IModalAddEditCouponComponent.StateEnum.Create && !this.coupon) {
      this.currentdatetime = await this.Service_Util.getDateTime()
      this.rangePicker = [
        moment(this.currentdatetime.date).format('YYYY-MM-DD'),
        moment(this.currentdatetime.date).add(1, 'month').format('YYYY-MM-DD'),
      ]
      this.model.startdate = moment(this.rangePicker[0]).format('YYYY-MM-DD')
      this.model.enddate = moment(this.rangePicker[1]).format('YYYY-MM-DD')
    }

    if (this.coupon) {
      for (const key in this.model) {
        if (this.coupon.hasOwnProperty(key)) {
          this.model[key] = typeof this.model[key] === 'boolean' ? Boolean(this.coupon[key]) : this.coupon[key]
        }
      }
      this.rangePicker = [this.model.startdate, this.model.enddate]
      if (!Object.values(IKetshopwebPromotionService.ICoupon.TypeEnum).includes(this.model.type)) {
        this.model.type = IKetshopwebPromotionService.ICoupon.TypeEnum.All
      }
      if (!Object.values(IKetshopwebPromotionService.ICoupon.TotalTypeEnum).includes(this.model.total_type)) {
        this.model.total_type = IKetshopwebPromotionService.ICoupon.TotalTypeEnum.Value
      }
      this.initial_participating_product_ids = this.model.promo_participating_products.map((x) => x.product_id)

      if (this.coupon.promo_apply_member_level) {
        this.applyMemberLevel(this.coupon.promo_apply_member_level)
      }
    }

    // member_level
    this.memberLevelList()
    // this.applyMemberLevel(this.apply_member_level)
  }

  applyMemberLevel(inputs: IKetshopwebPromotionService.PromoApplyMemberLevelEntity[]) {
    if (Array.isArray(inputs) && inputs.length != 0) {
      this.member_level_select = inputs.flatMap((x) => (x.is_active ? [x.member_level_id] : []))
    }
  }

  async memberLevelList() {
    if (Array.isArray(this.member_levels) && this.member_levels.length != 0) {
      return
    }

    try {
      const memberLevelFromService = await this.Service_Ketshopweb_Customer.listLevelTitle()

      this.member_levels = this.Service_Ketshopweb_Customer.mapMemberLevelEntityToCms(memberLevelFromService)
    } catch (err) {
      console.error('memberLevelList', err)
    }
  }

  dateRangeChange() {
    try {
      this.model.startdate = moment(this.rangePicker[0]).format('YYYY-MM-DD')
      this.model.enddate = moment(this.rangePicker[1]).format('YYYY-MM-DD')
    } catch (e) {
      console.log(e)
      this.model.startdate = ''
      this.model.enddate = ''
    }
  }

  generate(obj) {
    let text = ''
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const num = '0123456789'
    for (let i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length))
    }
    text += num.charAt(Math.floor(Math.random() * 10))
    // console.log(text);
    obj.code = text
  }

  toUppercase() {
    this.model.code = this.model.code.toUpperCase()
  }

  validate() {
    const codetrim =
      this.data.generate_type === IKetshopwebPromotionService.ICoupon.GenerateTypeEnum.Single
        ? this.model.code.trim()
        : null
    if (!codetrim && this.data.generate_type === IKetshopwebPromotionService.ICoupon.GenerateTypeEnum.Single) {
      this.message.error('โปรดกรอก รหัสคูปอง', {
        nzDuration: this.message_duration,
      })
      return false
    } else if (!this.model.title) {
      this.message.error('โปรดกรอกชื่อคูปอง', {
        nzDuration: this.message_duration,
      })
      return false
    } else if (this.model.startdate == '' || this.model.enddate == '') {
      this.message.error('โปรดเลือก ระยะเวลาให้ถูกต้อง', {
        nzDuration: this.message_duration,
      })
      return false
    } else if (Number(this.model.value) <= 0 && !this.model.freeshipping) {
      this.message.error('มูลค่าต้องมากกว่า 0', {
        nzDuration: this.message_duration,
      })
      return false
    } else if (Number(this.model.value) > 100 && this.model.value_type == 'percent') {
      this.message.error('มูลค่าต้องไม่เกิน 100 %', {
        nzDuration: this.message_duration,
      })
      return false
    } else if (Number(this.model.max_discount) == 0 && this.model.is_use_max_discount) {
      this.message.error('ส่วนลดสูงสุดต้องมากกว่า 0', {
        nzDuration: this.message_duration,
      })
      return false
    } else if (
      !(this.model.max_use > 0) &&
      this.data.state === IModalAddEditCouponComponent.StateEnum.Create &&
      this.data.generate_type === IKetshopwebPromotionService.ICoupon.GenerateTypeEnum.Group
    ) {
      this.message.error('จำนวนคูปองต้องมากกว่า 0', {
        nzDuration: this.message_duration,
      })
      return false
    } else if (
      this.data.state === IModalAddEditCouponComponent.StateEnum.Create &&
      this.model.coupon_type === IKetshopwebPromotionService.ICoupon.CouponTypeEnum.Product &&
      this.model.promo_participating_products.length === 0
    ) {
      this.message.error('จำนวนสินค้าร่วมรายการต้องมากกว่า 0', {
        nzDuration: this.message_duration,
      })
      return false
    }
    return true
  }

  async ok() {
    console.log(this.model)

    if (!this.validate()) {
      return false
    }

    try {
      const saveData = {} as IKetshopwebPromotionService.ICoupon.CreateData &
        IKetshopwebPromotionService.ICoupon.UpdateData
      for (const key in this.model) {
        console.log(key)
        saveData[key] = typeof this.model[key] === 'boolean' ? Number(this.model[key]) : this.model[key]
      }
      if (this.data.state === IModalAddEditCouponComponent.StateEnum.Create) {
        const result = await this.Service_KetshopwebPromotion.createCoupon(this.data.generate_type, saveData)
        if (result.hasOwnProperty('id')) {
          this.promo_id = result['id']
        }
      }
      if (this.data.state === IModalAddEditCouponComponent.StateEnum.Edit && this.coupon.use === 0) {
        const result = await this.Service_KetshopwebPromotion.updateCoupon(this.coupon.id, saveData)
        if (result.hasOwnProperty('id')) {
          this.promo_id = result['id']
        }
      }
      if (this.data.state === IModalAddEditCouponComponent.StateEnum.Edit && this.coupon.use > 0) {
        const result = await this.Service_KetshopwebPromotion.partialUpdateCoupon(this.coupon.id, {
          status: saveData.status,
        })
        if (result.hasOwnProperty('id')) {
          this.promo_id = result['id']
        }
      }
      this.modal.close(true)
    } catch (err) {
      console.error('ok?', err)
      this.message.error(err.error.message, { nzDuration: 5000 })
      return false
    }
  }

  onTypeChange() {
    if (this.model.value_type !== 'percent') {
      this.model.is_use_max_discount = false
      this.model.max_discount = 0
    }
  }

  onProductsChange(products: IProduct.IProductSearchResponse['data'][number][]) {
    if (products.length > 0) {
      this.model.promo_participating_products = products.map((x) => ({
        product_id: x.id,
      }))
    } else {
      this.model.promo_participating_products = []
    }
  }
}
