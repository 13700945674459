import { SafeAny } from '@ketshopweb/ui/core/types'
import { Store } from '@ngrx/store'
import { actions_orders, IOrdersState } from '@store/ketshopweb-store'
import { cloneDeep } from 'lodash'

export function check_operation(
  store: Store<SafeAny>,
  operation: {
    type: string
    value: SafeAny
  },
  selection_name: string,
  select_list: {
    from: string
    list: {
      data: IOrdersState.OrderSearchItem[]
      apply_data: IOrdersState.OrderSearchItem[]
    }
  },
  orders: IOrdersState.OrderSearchItem[],
) {
  // console.log('checkOperation', operation)
  if (operation.type == 'status') {
    if (Number(operation.value) == -1) {
      const temp_order = cloneDeep(select_list.list.apply_data)
      const og_order = cloneDeep(select_list.list.apply_data)
      const remove: IOrdersState.OrderSearchItem[] = []
      for (const obj of temp_order) {
        var find_index = -1
        if (obj.order_complete == 1 || (obj.status == 4 && obj.purchase_id) || obj.status == -1) {
          find_index = temp_order.findIndex((x) => x.id == obj.id)
        }
        if (find_index !== -1) {
          remove.push(og_order[find_index])
        }
      }
      store.dispatch(
        actions_orders.SelectOrders({
          from: selection_name,
          data: cloneDeep(remove),
          action: 'del',
        }),
      )
      for (const obj of orders) {
        if (obj.order_complete == 1 || (obj.status == 4 && obj.purchase_id) || obj.status == -1) {
          obj['disabled'] = true
          obj['selected'] = false
        }
      }
      store.dispatch(
        actions_orders.ApplySelectOrders({
          from: selection_name,
        }),
      )
      store.dispatch(
        actions_orders.InitSelectOrders({
          from: selection_name,
        }),
      )
    } else if (Number(operation.value) == 3) {
      const temp_order = cloneDeep(select_list.list.apply_data)
      const og_order = cloneDeep(select_list.list.apply_data)
      const remove: IOrdersState.OrderSearchItem[] = []
      for (const obj of temp_order) {
        var find_index = -1
        if (obj.order_complete == 1 || obj.status > 3 || obj.status == -1 || obj.order_expire) {
          find_index = og_order.findIndex((x) => x.id == obj.id)
        }
        // console.log('_index', _index)
        if (find_index !== -1) {
          remove.push(og_order[find_index])
        }
      }
      store.dispatch(
        actions_orders.SelectOrders({
          from: selection_name,
          data: cloneDeep(remove),
          action: 'del',
        }),
      )
      for (const obj of orders) {
        if (obj.order_complete == 1 || obj.status > 3 || obj.status == -1 || obj.order_expire) {
          obj['selected'] = false
          obj['disabled'] = true
        }
      }
      store.dispatch(
        actions_orders.ApplySelectOrders({
          from: selection_name,
        }),
      )
      store.dispatch(
        actions_orders.InitSelectOrders({
          from: selection_name,
        }),
      )
    } else if (Number(operation.value) == 5) {
      const temp_order = cloneDeep(select_list.list.apply_data)
      const og_order = cloneDeep(select_list.list.apply_data)
      const remove: IOrdersState.OrderSearchItem[] = []
      for (const obj of temp_order) {
        var find_index = -1
        if (obj.order_complete == 0 && obj.status == 4) {
        } else {
          find_index = og_order.findIndex((x) => x.id == obj.id)
        }
        if (find_index !== -1) {
          // og_order.splice(find_index, 1);
          remove.push(og_order[find_index])
        }
      }
      store.dispatch(
        actions_orders.SelectOrders({
          from: selection_name,
          data: cloneDeep(remove),
          action: 'del',
        }),
      )
      for (const obj of orders) {
        if (obj.order_complete == 0 && obj.status == 4 && !obj.purchase_id) {
        } else {
          obj['selected'] = false
          obj['disabled'] = true
        }
      }
      store.dispatch(
        actions_orders.ApplySelectOrders({
          from: selection_name,
        }),
      )
      store.dispatch(
        actions_orders.InitSelectOrders({
          from: selection_name,
        }),
      )
    }
  }
}
