import { SafeAny } from '@ketshopweb/ui/core/types'
import { Observable } from 'rxjs'
import { inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { CookieService } from 'ngx-cookie'
import { Store, select } from '@ngrx/store'
import { SET_PROVINCE, SET_LANGS } from '../store/action'
import Hashids from 'hashids/dist/hashids'
import { environment } from 'src/environments/environment'
import { store_utils } from '@store/ketshopweb-store'
import { AppStoreSelector } from '@cms/store/store'
import * as moment from 'moment'

let IS_FB_EVENT_API = true
let IS_TIKTOK_EVENT_API = true
const memory_cache: any = {
  fonts_json: null,
}

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  feature: any = {}

  private http: HttpClient = inject(HttpClient)
  private cookie: CookieService = inject(CookieService)
  private store: Store<SafeAny> = inject(Store)

  cacheCall(_promise: Observable<any>, key1: string) {
    return new Promise((resolve, reject) => {
      if (memory_cache[key1]) {
        return resolve(memory_cache[key1])
      } else {
        _promise
          .toPromise()
          .then((res) => {
            memory_cache[key1] = res
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      }
    })
  }

  searchMap(keyword = '') {
    return this.http
      .post('/util/searchMap', {
        search: keyword,
      })
      .toPromise()
  }

  filterShippingAddress(data) {
    return this.http.post('/util/filterShippingAddress', data).toPromise()
  }

  limitFeature() {
    return this.http.get('/util/limitFeature').toPromise()
  }

  getSLA() {
    return this.http.get('/util/sla').toPromise()
  }
  acceptSLA(data: any) {
    return this.http.post('/util/acceptSLA', data).toPromise()
  }

  listCountry(): Promise<any[]> {
    return this.http.get('/util/country').toPromise() as any
  }

  getWebsiteUsage() {
    return this.http.get('/util/websiteUsage').toPromise()
  }

  getWebsiteExpire() {
    return this.http.get('/util/websiteExpire').toPromise()
  }

  websiteDetail() {
    return this.http.get('/util/websiteDetail').toPromise()
  }

  getKetshopOrder() {
    return this.http.get('/util/ketshopOrder').toPromise()
  }

  getShippopName() {
    return this.http.get('/util/shippopname').toPromise()
  }

  getShippopImage() {
    const shippop = {
      //"THP": "/assets/images/core-imgs/ems.jpg",
      THP: '/system/core-imgs/ems.jpg',
      EMST: '/system/core-imgs/ems.jpg',
      // "TP2": "/assets/images/core-imgs/thpost.jpg",
      TP2: '/system/core-imgs/thpost.jpg',
      //"APF": "/assets/images/core-imgs/alphafast.jpg",
      APF: '/system/core-imgs/alphafast.jpg',
      KRYP: '/system/core-imgs/KRYP.jpg',
      KRYD: '/system/core-imgs/KRYD.jpg',
      JNTP: '/system/core-imgs/jntp.jpg',
      JNTD: '/system/core-imgs/jntd.jpg',
      BEST: '/system/core-imgs/best.jpg',
      RSB: '',
      //"SKT": "/assets/images/core-imgs/skootar.jpg",
      SKT: '/system/core-imgs/skootar.jpg',
      GRE: '',
      GRB: '',
      DHL: '',
      //"LLM": "/assets/images/core-imgs/lalamove.jpg",
      LLM: '/system/core-imgs/lalamove.jpg',
      //"NIK": "/assets/images/core-imgs/nikologistic.jpg",
      NIK: '/system/core-imgs/nikologistic.jpg',
      //"SCG": "/assets/images/core-imgs/scgexpress.jpg",
      SCG: '/system/core-imgs/scgexpress.jpg',
      //"NJV": "/assets/images/core-imgs/ninjavan.jpg",
      NJV: '/system/core-imgs/ninjavan.jpg',
      //"SCGF": "/assets/images/core-imgs/scg15.jpg",
      SCGF: '/system/core-imgs/scg15.jpg',
      //"SCGC": "/assets/images/core-imgs/sgc8.jpg",
      SCGC: '/system/core-imgs/sgc8.jpg',
      //"CJE": "/assets/images/core-imgs/cjlogistics.png"
      CJE: '/system/core-imgs/cjlogistics.png',
      FLE: '/system/core-imgs/flashexpress.png',
      TRUE: '/system/core-imgs/true-l.png',
    }
    return shippop
  }

  sendMailContactProduct(data: any) {
    return this.http.post('/util/sendMailContactProduct', data).toPromise()
  }

  sendMailQuotationProduct(input: any) {
    return this.http.post('/services/ketshopweb-frontend/forms/request_quotation', input).toPromise()
  }

  sendMailContact(data: any) {
    return this.http.post('/util/sendMailContact', data).toPromise()
  }

  getBoxSize() {
    return this.http.get('/delivery/boxsize').toPromise()
  }

  getEmailTemplate() {
    return this.http.get('/util/emailTemplate').toPromise()
  }

  getEmailTemplates() {
    return this.http.get('/util/emailTemplates').toPromise()
  }

  saveEmailTemplate(obj) {
    return this.http.post('/util/saveEmailTemplate', obj).toPromise()
  }

  saveEmailTemplates(obj) {
    return this.http.post('/util/saveEmailTemplates', obj).toPromise()
  }

  getFonts() {
    return this.cacheCall(this.http.get('/assets/default/fonts.json'), 'fonts_json')
    // return this.http.get("/assets/default/fonts.json").toPromise();
  }

  getDateTime() {
    return new Promise((reslove) => {
      return this.http
        .get('/services/v2/ketcms-frontend/utils/datetime')
        .toPromise()
        .then((res: any) => reslove(res))
        .catch(() => {
          const mm = moment()
          reslove({
            date: mm.format('YYYY-MM-DD'),
            date_string: mm.toString(),
            date_time: mm.format('YYYY-MM-DD HH:mm:ss'),
            time: mm.format('HH:mm:ss'),
          })
        })
    }) as Promise<{ date: string; date_string: string; date_time: string; time: string }>
  }

  getProvince() {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const provinces = await store_utils.store_query(this.store, AppStoreSelector.province)
          if (provinces.length === 0) {
            this.http
              .get('/assets/default/province.json')
              .toPromise()
              .then((resp: any[]) => {
                this.store.dispatch(new SET_PROVINCE(resp))
                resolve(resp)
              })
              .catch((err) => {
                reject(err)
              })
          } else {
            resolve(provinces)
          }
          // let _subscribe = this.store
          //   .pipe(select("app_store", "PROVINCE"))
          //   .subscribe((res: any[]) => {
          //     if (res.length == 0) {
          //       this.http
          //         .get("/assets/default/province.json")
          //         .toPromise()
          //         .then((resp: any[]) => {
          //           this.store.dispatch(new SET_PROVINCE(resp));
          //         })
          //         .catch(err => {
          //           reject(err);
          //         });
          //     } else {
          //       if(_subscribe){
          //         setTimeout(()=>{
          //           try {
          //             _subscribe.unsubscribe()
          //           } catch {}
          //         },100)
          //       }
          //       resolve(res);
          //     }
          //   });
        } catch (err) {
          reject(err)
        }
      })()
    })
  }

  getDistrict(obj: any) {
    return this.http.post('/util/searchAmphur', obj).toPromise()
  }

  getSubDistrict(obj: any) {
    return this.http.post('/util/searchTambon', obj).toPromise()
  }

  getInstagram(link: string) {
    return this.http.get(`${link}?__a=1`).toPromise()
    //return this.http.post('/util/instagram', {link:link}).toPromise();
  }

  searchPageLink(data: any) {
    return this.http.post('/page/searchPageLink', data).toPromise()
  }

  getPageLink(data: any) {
    if (!data.page) {
      return new Promise((resolve, reject) => {
        reject('page Not Found')
      })
    }
    const key: any = `pagelink_${data.page}`
    if (this.cookie.get(key)) {
      return new Promise((resolve, reject) => {
        try {
          resolve(JSON.parse(localStorage.getItem(key)))
        } catch (err) {
          this.cookie.remove(key)
          reject(err)
        }
      })
    } else {
      return this.http.post('/page/pagelink', data).toPromise()
    }
    //return this.http.post('/page/pagelink',data).toPromise();
  }

  getPageLinkAll() {
    return this.http.get('/page/pagelink_all').toPromise()
  }

  getStaticPage() {
    return this.http.get('/page/static_page').toPromise()
  }

  getFeatureLang() {
    return this.http.get('/util/featureLang').toPromise()
  }

  saveLangSetting(obj: any): Promise<any> {
    return this.http.post('/util/languageSetting', obj).toPromise()
  }

  searchLangMessage(obj: any) {
    return this.http.post('/util/searchLangMessage', obj).toPromise()
  }

  saveLangMessage(obj: any) {
    return this.http.put('/util/saveLangMessage/' + obj.id, obj).toPromise()
  }

  getChannels() {
    return this.http.get('/util/getChannels').toPromise()
  }

  getAllLang() {
    return this.http.get('/util/allLang').toPromise()
  }

  getFooter(_lang: string = undefined) {
    // if (lang) {
    //   return this.http.get(`/util/footer?lang=${lang}`).toPromise()
    // } else {
    //   return this.http.get('/util/footer').toPromise()
    // }
    return this.http.get('/services/v2/ketcms-frontend/pages/get_footer').toPromise()
  }

  saveFooter(obj: any) {
    return this.http.post('/util/saveFooter', obj).toPromise()
  }

  getDefaultFooter() {
    return this.http.get('/assets/default/default-footer.json').toPromise()
  }

  LangFormat = []
  is_lang_format_processing = false
  getAllLangFormat() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      if (this.is_lang_format_processing) {
        while (this.is_lang_format_processing) {
          console.log('waiting for lang format processing')
          await new Promise((res) => setTimeout(res, 100))
        }
        return resolve(this.LangFormat)
      }
      if (!this.is_lang_format_processing) {
        this.is_lang_format_processing = true
      }
      if (this.LangFormat.length) {
        this.is_lang_format_processing = false
        return resolve(this.LangFormat)
      }
      this.http.get('/services/v2/ketcms-frontend/utils/lang_format').toPromise()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any[]) => {
          this.LangFormat = res
          this.is_lang_format_processing = false
          resolve(res)
        })
        .catch((err) => {
          this.is_lang_format_processing = false
          reject(err)
        })
    })
  }
  searchLangFormat(data) {
    return this.http.post('/util/searchLangFormat', data).toPromise()
  }
  saveLangFormat(data) {
    return this.http.post('/util/saveLangFormat', data).toPromise()
  }

  get_langs_processing = false
  langs = []
  getLangs() {
    if (window['seo']) {
      return this.http.get('/services/v2/ketcms-frontend/utils/langs').toPromise()
    }
    return new Promise((resolve, reject) => {
      try {
        const _subLang = this.store.pipe(select('app_store', 'LANGS')).subscribe(async (res: any[]) => {
          if (res.length == 0) {
            if (this.get_langs_processing) {
              while (this.get_langs_processing) {
                console.log('waiting for langs processing')
                await new Promise((res) => setTimeout(res, 100))
              }
              return resolve(this.langs)
            }
            if (!this.get_langs_processing) {
              this.get_langs_processing = true
            }
            this.http
              .get('/services/v2/ketcms-frontend/utils/langs')
              .toPromise()
              .then((resp: any[]) => {
                this.langs = resp
                if (resp.length > 0) {
                  this.store.dispatch(new SET_LANGS(resp))
                } else {
                  // resolve(res);
                  //
                  this.store.dispatch(
                    new SET_LANGS([
                      {
                        id: 1,
                        lang: 'th-TH',
                        lang_type: 'th',
                        iso_code: 'TH',
                        name: 'TH',
                        pic: '/upload-img/Default/icon/flag-th.png',
                        status: 1,
                        sort: 1,
                        ref: 'lang1',
                        default: 1,
                        updated_at: '2019-05-31 22:15:55',
                        created_at: '2016-11-08 14:59:20',
                      },
                      {
                        id: 2,
                        lang: 'kw-GB',
                        lang_type: 'kw',
                        iso_code: 'GB',
                        name: 'EN',
                        pic: '/upload-img/Default/icon/flag-en.png',
                        status: 1,
                        sort: 2,
                        ref: 'lang2',
                        default: 0,
                        updated_at: '2022-03-03 14:40:04',
                        created_at: '2016-11-08 14:59:20',
                      },
                    ]),
                  )
                }
                this.get_langs_processing = false
              })
              .catch((err) => {
                this.get_langs_processing = false
                reject(err)
              })
          } else {
            setTimeout(() => {
              if (_subLang) {
                _subLang.unsubscribe()
              }
            }, 100)
            resolve(res)
          }
        })
      } catch (err) {
        reject(err)
      }
    })
  }
  inuseActivity(data) {
    return this.http.post('/util/inuseActivity', data).toPromise()
  }

  getNotificationCount() {
    return this.http.get('/util/notificationCount').toPromise()
  }

  getKeyCodes(code: any) {
    const _keycode = {
      0: 'That key has no keycode',
      3: 'break',
      8: 'backspace / delete',
      9: 'tab',
      12: 'clear',
      13: 'enter',
      16: 'shift',
      17: 'ctrl',
      18: 'alt',
      19: 'pause/break',
      20: 'caps lock',
      21: 'hangul',
      25: 'hanja',
      27: 'escape',
      28: 'conversion',
      29: 'non-conversion',
      32: 'spacebar',
      33: 'page up',
      34: 'page down',
      35: 'end',
      36: 'home',
      37: 'left arrow',
      38: 'up arrow',
      39: 'right arrow',
      40: 'down arrow',
      41: 'select',
      42: 'print',
      43: 'execute',
      44: 'Print Screen',
      45: 'insert',
      46: 'delete',
      47: 'help',
      48: '0',
      49: '1',
      50: '2',
      51: '3',
      52: '4',
      53: '5',
      54: '6',
      55: '7',
      56: '8',
      57: '9',
      58: ':',
      59: 'semicolon (firefox), equals',
      60: '<',
      61: 'equals (firefox)',
      63: 'ß',
      64: '@ (firefox)',
      65: 'a',
      66: 'b',
      67: 'c',
      68: 'd',
      69: 'e',
      70: 'f',
      71: 'g',
      72: 'h',
      73: 'i',
      74: 'j',
      75: 'k',
      76: 'l',
      77: 'm',
      78: 'n',
      79: 'o',
      80: 'p',
      81: 'q',
      82: 'r',
      83: 's',
      84: 't',
      85: 'u',
      86: 'v',
      87: 'w',
      88: 'x',
      89: 'y',
      90: 'z',
      91: 'Windows Key / Left ⌘ / Chromebook Search key',
      92: 'right window key',
      93: 'Windows Menu / Right ⌘',
      95: 'sleep',
      96: 'numpad 0',
      97: 'numpad 1',
      98: 'numpad 2',
      99: 'numpad 3',
      100: 'numpad 4',
      101: 'numpad 5',
      102: 'numpad 6',
      103: 'numpad 7',
      104: 'numpad 8',
      105: 'numpad 9',
      106: 'multiply',
      107: 'add',
      108: 'numpad period (firefox)',
      109: 'subtract',
      110: 'decimal point',
      111: 'divide',
      112: 'f1',
      113: 'f2',
      114: 'f3',
      115: 'f4',
      116: 'f5',
      117: 'f6',
      118: 'f7',
      119: 'f8',
      120: 'f9',
      121: 'f10',
      122: 'f11',
      123: 'f12',
      124: 'f13',
      125: 'f14',
      126: 'f15',
      127: 'f16',
      128: 'f17',
      129: 'f18',
      130: 'f19',
      131: 'f20',
      132: 'f21',
      133: 'f22',
      134: 'f23',
      135: 'f24',
      144: 'num lock',
      145: 'scroll lock',
      151: 'airplane mode',
      160: '^',
      161: '!',
      162: '؛ (arabic semicolon)',
      163: '#',
      164: '$',
      165: 'ù',
      166: 'page backward',
      167: 'page forward',
      168: 'refresh',
      169: 'closing paren (AZERTY)',
      170: '*',
      171: '~ + * key',
      172: 'home key',
      173: 'minus (firefox), mute/unmute',
      174: 'decrease volume level',
      175: 'increase volume level',
      176: 'next',
      177: 'previous',
      178: 'stop',
      179: 'play/pause',
      180: 'e-mail',
      181: 'mute/unmute (firefox)',
      182: 'decrease volume level (firefox)',
      183: 'increase volume level (firefox)',
      186: 'semi-colon / ñ',
      187: 'equal sign',
      188: 'comma',
      189: 'dash',
      190: 'period',
      191: 'forward slash / ç',
      192: 'grave accent / ñ / æ / ö',
      193: '?, / or °',
      194: 'numpad period (chrome)',
      219: 'open bracket',
      220: 'back slash',
      221: 'close bracket / å',
      222: 'single quote / ø / ä',
      223: '`',
      224: 'left or right ⌘ key (firefox)',
      225: 'altgr',
      226: '< /git >, left back slash',
      230: 'GNOME Compose Key',
      231: 'ç',
      233: 'XF86Forward',
      234: 'XF86Back',
      235: 'non-conversion',
      240: 'alphanumeric',
      242: 'hiragana/katakana',
      243: 'half-width/full-width',
      244: 'kanji',
      251: 'unlock trackpad (Chrome/Edge)',
      255: 'toggle touchpad',
    }
    return _keycode[code]
  }

  widgetAnalytic(data: any) {
    /* 
      {
        type: string;
        value: string;
      }
      type example: 'header', 'contact_button'
    */
    return this.http.post('/util/widgetAnalytic', data).toPromise()
  }

  enCodeId(text: string): string {
    let public_key = environment.public_key
    public_key = public_key.replace(/(\r\n|\n|\r)/gm, '')
    const hashids = new Hashids(public_key)
    const encode = hashids.encode([text])
    return encode
  }

  deCodeId(encode: string): any {
    let public_key = environment.public_key
    public_key = public_key.replace(/(\r\n|\n|\r)/gm, '')
    const hashids = new Hashids(public_key)
    const data = hashids.decode(encode)
    return data[0]
  }

  tiktok_events(data: any) {
    return new Promise((resolve, reject) => {
      if (!IS_TIKTOK_EVENT_API) {
        return resolve(true)
      }
      this.http
        .post('/services/business/conversion/events/tiktok', data)
        .toPromise()
        .then((res: any) => {
          if (!res.status) {
            IS_TIKTOK_EVENT_API = false
          }
          resolve(res)
        })
        .catch((err) => reject(err))
    })
  }

  fb_events(data: any) {
    return new Promise((resolve, reject) => {
      if (!IS_FB_EVENT_API) {
        return resolve(true)
      }
      //   this.http
      //     .post('/util/fb_events', data)
      //     .toPromise()
      //     .then((res: any) => {
      //       if (!res.status) {
      //         IS_FB_EVENT_API = false
      //       }
      //       resolve(res)
      //     })
      //     .catch((err) => reject(err))
      // })
      this.http
        .post('/services/business/conversion/events/facebook', data)
        .toPromise()
        .then((res: any) => {
          if (!res.status) {
            IS_FB_EVENT_API = false
          }
          resolve(res)
        })
        .catch((err) => reject(err))
    })
  }

  getCookie(name: string) {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
  }

  savePdpaLog(data: any) {
    return this.http.post(`/util/savePdpaLog`, data).toPromise()
  }

  searchPdpaLog(data: any) {
    return this.http.post(`/util/searchPdpaLog`, data).toPromise()
  }
}
