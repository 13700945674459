import { SafeAny } from '@ketshopweb/ui/core/types'
import { actions_app } from './actions'
import { NzMessageService } from 'ng-zorro-antd/message'
import { Injectable, inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map } from 'rxjs/operators'
import Hashids from 'hashids/dist/hashids'
import { environment } from '@cms/../environments/environment'

@Injectable()
export class AppEffects {
  private actions$ = inject(Actions)
  private messageService = inject(NzMessageService)

  enCodeId(text: string): string {
    let public_key = environment.public_key
    public_key = public_key.replace(/(\r\n|\n|\r)/gm, '')
    const hashids = new Hashids(public_key)
    const encode = hashids.encode([text])
    return encode
  }

  $Copy = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions_app.Copy),
        map((action) => {
          let text = ''
          if (action.action == 'text') {
            text = action.val
          } else if (action.action == 'order') {
            text = `${window.location.origin}/order-page/${this.enCodeId(action.val)}`
          }
          navigator.clipboard
            .writeText(text)
            .then((_: SafeAny) => {
              this.messageService.success(action.message ? action.message : 'คัดลอกลิงก์สำเร็จ!')
            })
            .catch((_) => null)
        }),
      ),
    { dispatch: false },
  )

  $AlertMessage = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions_app.AlertMessage),
        map((action) => {
          const duration = action.duration || 5000
          this.messageService[action.types](action.message, { nzDuration: duration })
        }),
      ),
    { dispatch: false },
  )
}
