import { HttpClient } from '@angular/common/http'
import { inject } from '@angular/core'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class CmsBlogsService {
  public http: HttpClient = inject(HttpClient)

  prefix = '/services/plugins-api/sandclockbooks'

  getBlogRelateds(id: number, data?: { limit: number }) {
    const params = {}

    if (data) {
      for (const key in data) {
        if (data[key]) {
          params[key] = data[key]
        }
      }
    }
    return this.http.get<any>(`${this.prefix}/blogs/${id}/blog_relateds`, { params })
  }

  saveBlogRelateds(blog_id: number, data: { blog_related_ids: number[] }) {
    return this.http.post<{ error: boolean; message: string }>(`${this.prefix}/blogs/${blog_id}/blog_relateds`, data)
  }
}
