import { createFeatureSelector, createSelector } from '@ngrx/store'
import { IClientUuserState } from './models'
import { ClientUuserFEATURE_KEY } from './reducer'

const selectState = createFeatureSelector<IClientUuserState.ClientUuserState>(ClientUuserFEATURE_KEY)

const get_me = createSelector(selectState, (state: IClientUuserState.ClientUuserState) => state.me)

export const selectors_client_user = {
  get_me,
}
