import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

const memory_cache: any = {
  listsectionelementFront: {},
  headers_json: null,
  elements_json: null,
  sections_json: null,
  header_json: null,
  searchProductIndex: {},
  filterProperties: null,
  fonts_json: null,
}

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  constructor() {}

  cacheCall(_promise: Observable<any>, key1: string, key2: string = null) {
    return new Promise((resolve, reject) => {
      if (key2) {
        // console.log('cacheCall',key1,key2, 'memory_cache[key1][key2]', memory_cache[key1][key2]);
        if (memory_cache[key1][key2]) {
          return resolve(memory_cache[key1][key2])
        } else {
          // console.log('_promise 1')
          return _promise
            .toPromise()
            .then((res) => {
              memory_cache[key1][key2] = res
              resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
        }
      }
      if (memory_cache[key1]) {
        return resolve(memory_cache[key1])
      } else {
        // console.log('_promise 2')
        _promise
          .toPromise()
          .then((res) => {
            memory_cache[key1] = res
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      }
    })
  }
}
