import { h, hydrate, Fragment } from 'preact'
import { format_money } from '../func/format_money'
import { qr_to_base64 } from '../func/qr_to_base64'
import { IOrders } from '../types/IOrders'
import { isObject } from 'lodash'

export namespace IOrderHtml {
  export type OrderDetail = Pick<
    IOrders.GetOrderV1Response['details'][number],
    | 'sku'
    | 'title_lang1'
    | 'prop_enable'
    | 'properties_desc'
    | 'properties_desc2'
    | 'property_info'
    | 'property_info2'
    | 'feature_img'
    | 'temp_price'
    | 'product_price'
    | 'qty'
    | 'totalprice'
  >
  export type Order = Pick<
    IOrders.GetOrderV1Response,
    | 'ordercode'
    | 'statusname'
    | 'channel'
    | 'amount'
    | 'discount'
    | 'discountaddon'
    | 'vat'
    | 'vatrate'
    | 'weight'
    | 'cod'
    | 'cod_value'
    | 'totals'
    | 'user_id'
    | 'country_alpha2_code'
    | 'bill_country_alpha2_code'
    | 'freeshipping'
    | 'purchase_id'
    | 'delicharge'
    | 'delichargeaddon'
    | 'delivery'
    | 'trackcode'
    | 'promotion'
    | 'bill_name'
    | 'bill_lastname'
    | 'bill_tel'
    | 'bill_address1'
    | 'bill_subdistrict'
    | 'bill_district'
    | 'bill_province'
    | 'bill_zipcode'
    | 'bill_country_name'
    | 'shipping_name'
    | 'shipping_tel'
    | 'shipping_address1'
    | 'shipping_subdistic'
    | 'shipping_district'
    | 'shipping_province'
    | 'shipping_zipcode'
    | 'country_name'
    | 'customer'
    | 'paymenttype'
    | 'note'
    | 'customer_note'
    | 'box_name'
    | 'box_value'
    | 'free_item'
    | 'created_at'
    | 'shipping_group_id'
    | 'shipping_group'
    | 'amount_no_vat'
  > & {
    details: any[]
  }
}

export const order_html = {
  async generate(order: IOrderHtml.Order, { include_remark }: { include_remark?: boolean } = {},cus_id) {
    let isShippingInter = false
    let isBillingInter = false
    if (order.country_alpha2_code != 'TH') {
      isShippingInter = true
    }
    if (order.bill_country_alpha2_code != 'TH') {
      isBillingInter = true
    }
    if (order.user_id == -1) {
      order['customer'] = {
        username: '-',
      }
    }
    let shipping_name = ''
    let shipping_price: string | number = '0'
    if (order.freeshipping == 1) {
      shipping_name = 'ฟรีค่าขนส่ง / Free'
    }
    if (order.purchase_id && order.freeshipping == 0) {
      shipping_price = order.delicharge
      if (order.delichargeaddon > 0) {
        shipping_price = order.delicharge + order.delichargeaddon
      }

      if (isObject(order.delivery) && order.delivery.name) {
        shipping_name = order.delivery.name
      }
    }
    if (!order.purchase_id && order.freeshipping == 0 && order.user_id !== -1) {
      // console.log(order)
      shipping_price = order.delicharge

      if (isObject(order.delivery) && order.delivery.name) {
        shipping_name = order.delivery.name
      }
    }
    shipping_price = format_money(shipping_price)

    if (order.user_id == -1) {
      shipping_name = 'รับหน้าร้าน / Pickup'
    }

    let promotion_state = false
    let promotion_name = ''
    order.promotion.forEach((obj) => {
      if (obj.promotion_type == 'coupon') {
        promotion_state = true
        promotion_name = `ส่วนลดคูปอง / Coupon : ${obj.coupon}`
        if (obj.value_type == 'percent') {
          promotion_name += ` ( ${obj.value}% )`
        }
      } else if (obj.promotion_type == 'condition') {
        promotion_state = true
        promotion_name = `ส่วนลดโปรโมชั่น / Promotion : ${obj.title}`
        if (obj.value_type == 'percent') {
          promotion_name += ` ( ${obj.value}% )`
        }
      }
    })
    let bill_address = ''
    if (!isBillingInter) {
      bill_address = `${order.bill_address1} แขวง/ตำบล ${order.bill_subdistrict} เขต/อำเภอ ${order.bill_district} ${order.bill_province} ${order.bill_zipcode}`
    } else {
      bill_address = `${order.bill_address1} ${order.bill_province} ${order.bill_zipcode} ${order.bill_country_name}`
    }
    let shipping_address = ''
    if (!isShippingInter) {
      shipping_address = `${order.shipping_address1} แขวง/ตำบล ${order.shipping_subdistic} เขต/อำเภอ ${order.shipping_district} ${order.shipping_province} ${order.shipping_zipcode}`
    } else {
      shipping_address = `${order.shipping_address1} ${order.shipping_province} ${order.shipping_zipcode} ${order.country_name}`
    }

    // bill footer
    const out_of_stock = order.free_item.filter((x: any) => x.out_of_stock == 1)

    const CustomerAddress = () => {
      return (
        <div class="address">
          <div class="row">
            <div class="col-md-6">
              <p>
                <strong>
                  <u>ที่อยู่สำหรับออกบิล / Billing Address</u>
                </strong>
              </p>
              <p>
                {order.bill_name} {order.bill_lastname || ''} ({order.bill_tel})
              </p>
              <p>{bill_address}</p>
            </div>
            <div class="col-md-6">
              <p>
                <strong>
                  <u>ที่อยู่สำหรับจัดส่ง / Shipping Address</u>
                </strong>
              </p>
              <p>
                {order.shipping_name} ({order.shipping_tel})
              </p>
              <p>{shipping_address}</p>
            </div>
          </div>
        </div>
      )
    }

    const GuestAddress = () => {
      return (
        <div class="address">
          <div class="row">
            <div class="col-md-6">
              <p>
                <strong>
                  <u>ที่อยู่สำหรับออกบิล / Billing Address</u>
                </strong>
              </p>
              <p>{order.bill_name}</p>
            </div>
            <div class="col-md-6">
              <p>
                <strong>
                  <u>ที่อยู่สำหรับจัดส่ง / Shipping Address</u>
                </strong>
              </p>
              <p>{order.shipping_name}</p>
            </div>
          </div>
        </div>
      )
    }

    // alert('order.amount_no_vat: ' + order.amount_no_vat)

    const qr_ordercode = (await qr_to_base64(cus_id +'-'+ order.ordercode, {
      scale: 0,
      width: 120,
      margin: 1,
    })) as string

    const OrderHtml = (
      <Fragment>
        <div class="page-single">
          <div class="header">
            <div class="header_qrcode">
              <img style={{ width: '100%' }} src={qr_ordercode} />
            </div>
            <div class="header_detail">
              <div class="row">
                <div class="col-md-6">
                  <strong>คำสั่งซื้อหมายเลข / Order No. {order.ordercode}</strong>
                </div>
                {order.shipping_group_id > 0 && order.shipping_group && (
                  <div class="col-md-6">
                    <strong>Batch : {order.shipping_group.shipping_group_name}</strong>
                  </div>
                )}
              </div>
              <div class="row">
                <div class="col-md-6">วันที่สั่งซื้อ / Order Date : {order.created_at}</div>
                <div class="col-md-6">สถานะ / Status : {order.statusname}</div>
                <div class="col-md-6">เลขพัสดุ / Track Code : {order.trackcode || '-'}</div>
                <div class="col-md-6">ช่องทางการขาย / Channel : {order.channel}</div>
                <div class="col-md-6">การชําระเงิน / Payment : {order.paymenttype.name}</div>
                <div class="col-md-6">อีเมล์ / Email : {order.customer.username}</div>
              </div>
              <p></p>
              <p></p>
              <p></p>
            </div>
          </div>
          {order.user_id !== -1 ? <CustomerAddress /> : <GuestAddress />}
          <div class="product">
            <table class="table-product">
              <thead>
                <tr>
                  <th class="col-number">No.</th>
                  <th class="col-number">Image</th>
                  <th class="col-product">Product</th>
                  <th class="col-price">Price</th>
                  <th class="col-qty">Qty</th>
                  <th class="col-price">Total</th>
                </tr>
              </thead>
              <tbody>
                {order.details.map((obj, i: number) => {
                  if (obj.prop_enable == 1) {
                    obj.title_lang1 = `${obj.title_lang1} (${obj.properties_desc} - ${obj.property_info})`
                  }
                  if (obj.prop_enable == 2) {
                    obj.title_lang1 = `${obj.title_lang1} (${obj.properties_desc} - ${obj.property_info} | ${obj.properties_desc2} - ${obj.property_info2})`
                  }
                  return (
                    <tr>
                      <td class="col-number">{i + 1}</td>
                      <td class="col-image">
                        <figure class="product-image">
                          <img src={obj.feature_img} />
                        </figure>
                      </td>
                      <td class="col-product">
                        <div class="product-name">{obj.title_lang1}</div>
                        <div class="product-sku">SKU : {obj.sku}</div>
                        {obj.set_detail.map((set) => {
                          if (set.prop_enable == 1) {
                            set.title_lang1 = `${set.title_lang1} (${set.properties_desc} - ${set.property_info})`
                          }
                          if (obj.prop_enable == 2) {
                            set.title_lang1 = `${set.title_lang1} (${set.properties_desc} - ${set.property_info} | ${set.properties_desc2} - ${set.property_info2})`
                          }
                          return (
                            <div class="product-sku">
                              x{set.qty} {set.title_lang1}
                            </div>
                          )
                        })}
                      </td>
                      <td class="col-price">
                        {obj.temp_price > 0 ? <s class={'product-sell-price'}>{obj.temp_price}</s> : null}
                        {format_money(obj.product_price)} ฿
                      </td>
                      <td class="col-qty">{obj.qty}</td>
                      <td class="col-price">{format_money(obj.totalprice)} ฿</td>
                    </tr>
                  )
                })}
                {order.free_item.length && order.free_item.length > out_of_stock.length ? (
                  <Fragment>
                    <tr class="pb-0">
                      <td colSpan={6} class="pb-0">
                        <strong>สินค้าฟรี</strong>
                      </td>
                    </tr>
                    {order.free_item.map((free: any, i: number) => {
                      if (free.out_of_stock) {
                        return null
                      }
                      return (
                        <tr>
                          <td class="col-number text-center">{i + 1}</td>
                          <td class="col-image">
                            <figure class="product-image">
                              <img src={free.feature_img} />
                            </figure>
                          </td>
                          <td class="col-product">
                            {free.title_lang1}{' '}
                            {free.promotion_type == 'product_point' ? `| ใช้แต้มแลก ${free.value} แต้ม` : ''}
                          </td>
                          <td class="col-price text-right">0.00 ฿</td>
                          <td class="col-qty text-center">{free.qty}</td>
                          <td class="col-price text-right">0.00 ฿</td>
                        </tr>
                      )
                    })}
                  </Fragment>
                ) : null}
              </tbody>
              <tfoot>
                {!order.amount_no_vat ? (
                  <tr>
                    <td colSpan={4} style="text-align:right">
                      ราคารวมสินค้า / Amount
                    </td>
                    <td colSpan={2} style="text-align:right">
                      {format_money(order.amount)} ฿
                    </td>
                  </tr>
                ) : (
                  <Fragment>
                    <tr>
                      <td colSpan={4} style="text-align:right">
                        มูลค่ารายการยกเว้นภาษี / Amount (VAT Excluded)
                      </td>
                      <td colSpan={2} style="text-align:right">
                        {format_money(order.amount_no_vat)} ฿
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4} style="text-align:right">
                        มูลค่ารายการที่คำนวณภาษี / Amount (VAT Included)
                      </td>
                      <td colSpan={2} style="text-align:right">
                        {format_money(order.amount - order.amount_no_vat)} ฿
                      </td>
                    </tr>
                  </Fragment>
                )}

                {order.discountaddon > 0 ? (
                  <tr>
                    <td colSpan={4} style="text-align:right">
                      ส่วนลดพิเศษ / Discount
                    </td>
                    <td colSpan={2} style="text-align:right">
                      -{format_money(order.discountaddon)} ฿
                    </td>
                  </tr>
                ) : null}
                {promotion_state ? (
                  <tr>
                    <td colSpan={4} style="text-align:right">
                      {promotion_name}
                    </td>
                    <td colSpan={2} style="text-align:right">
                      -{format_money(order.discount)} ฿
                    </td>
                  </tr>
                ) : null}
                {order.vat > 0 ? (
                  <tr>
                    <td colSpan={4} style="text-align:right">
                      ภาษีมูลค่าเพิ่ม {order.vatrate}%
                    </td>
                    <td colSpan={2} style="text-align:right">
                      {format_money(order.vat)} ฿
                    </td>
                  </tr>
                ) : null}
                <tr>
                  <td colSpan={4} style="text-align:right">
                    {shipping_name} ( {Number(order.weight).toFixed(2)} กรัม / g )
                  </td>
                  <td colSpan={2} style="text-align:right">
                    {shipping_price} ฿
                  </td>
                </tr>
                {order.cod ? (
                  <tr>
                    <td colSpan={4} style="text-align:right">
                      ค่าบริการ COD / COD Fee
                    </td>
                    <td colSpan={2} style="text-align:right">
                      {Number(order.cod_value).toFixed(2)} ฿
                    </td>
                  </tr>
                ) : null}
                <tr>
                  <td colSpan={4} style="text-align:right">
                    <strong>ราคารวมทั้งหมด / Total</strong>
                  </td>
                  <td colSpan={2} style="text-align:right">
                    <strong>{format_money(order.totals)} ฿</strong>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          {include_remark && (
            <div class="note">
              <div class="row">
                <div class="col-md-6">
                  <div class="rounded border p-2">
                    <p>
                      <strong>หมายเหตุภายใน / Remark : </strong>
                    </p>
                    <p>{order.note || '-'}</p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="rounded border p-2">
                    <p>
                      <strong>หมายเหตุจากลูกค้า / Customer Note : </strong>
                    </p>
                    <p>{order.customer_note || '-'}</p>
                  </div>
                </div>
                <div class="col-md-6 col-boxsize">
                  <div class="rounded border p-2 ">
                    <p>
                      <strong>ขนาดกล่อง</strong>
                    </p>
                    <p>
                      {order.box_name || '-'} {order.box_value || '-'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div class="pagebreak"></div>
      </Fragment>
    )

    return OrderHtml
  },
  to_html(Tsx: h.JSX.Element[]) {
    const ctx = document.createElement('body')
    hydrate([...Tsx, order_html_css], ctx)
    return `<!DOCTYPE html>
        <html>
            <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>พิมพ์ใบสั่งซื้อ</title>
            </head>
            ${ctx.outerHTML}
        </html>
        `
  },
}

const order_html_css = (
  <style>
    {`* {
                margin: 0;
                padding: 0;
                box-sizing: border-box;
                font-size: 14px;
              }
              
              body {
                font-family: sans-serif;
              }
              
              p {
                margin-top: 0;
                margin-bottom: 2px;
              }
              
              p:last-child {
                margin-bottom: 0;
              }
              
              .page-single {
                width: 100%;
                font-size: 14px;
                padding: 15px;
              }
              
              .page-double {
                width: 50%;
                float: left;
                font-size: 13px;
                padding: 15px;
              }
              
              .row {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                margin-right: -15px;
                margin-left: -15px;
              }
              
              .col-md-6 {
                position: relative;
                width: 100%;
                min-height: 1px;
                padding-right: 15px;
                padding-left: 15px;
                -webkit-box-flex: 0;
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
              }
              
              .header,
              .address,
              .product {
                padding: 10px 0;
              }
              
              .product {
                margin-bottom: 1rem;
              }
              
              .note {
                width: 75%;
              }
              
              .header {
                font-size: 90%;
                border-bottom: 1px dashed #000;
              }
              
              .header:after,
              .address:after {
                content: '';
                display: block;
                clear: both;
              }
              
              .header_qrcode {
                width: 74px;
                height: 74px;
                float: left;
              }
              
              .header_detail {
                width: calc(100% - 74px);
                float: left;
                padding-left: 10px;
              }
              
              .header .order-number {
                font-size: 120%;
              }
              
              .address {
                font-size: 90%;
              }
              
              .table-product {
                width: 100%;
                border-collapse: collapse;
              }
              
              .table-product thead tr th {
                border-top: 1px solid #000;
                border-bottom: 1px solid #000;
                border-collapse: collapse;
              }
              
              .table-product thead tr th,
              .table-product tbody tr td {
                padding: 7px;
              }
              
              .table-product tfoot tr td {
                padding: 3px 7px;
              }
              
              .table-product tbody tr td {
                border-bottom: 1px dotted #d5d5d5;
              }
              
              .table-product tfoot .order-total td {
                border-top: 1px solid #000;
                border-bottom: 1px solid #000;
                padding: 10px 7px;
                font-size: 110%;
              }
              
              .table-product .col-product {
                text-align: left;
              }
              
              .table-product .col-number,
              .table-product .col-qty {
                text-align: center;
                width: 50px;
              }
              
              .table-product .col-price {
                text-align: right;
                min-width: 75px;
              }
              
              .table-product .col-image {
                width: 58px;
                text-align: center;
              }

              .col-boxsize{
                z-index: 10;
                margin-top: -130px;
              }
              
              .table-product .product-image {
                width: 44px;
                height: 44px;
                overflow: hidden;
                margin: auto;
              }
              
              .table-product .product-image img {
                width: 100%;
                height: auto;
              }
              
              .table-product .product-name {
                margin-bottom: 5px;
              }
              
              .table-product .product-sku {
                opacity: 0.7;
                font-size: 90%;
              }
              
              .table-product .product-sell-price {
                opacity: 0.7;
                font-size: 90%;
              }
              
              .p-2 {
                padding: .5rem;
              }
              
              .border {
                border: 1px solid #dee2e6;
              }
              
              .rounded {
                border-radius: .25rem;
              }
              
              .pagebreak {
                clear: both;
                page-break-after: always;
              }`}
  </style>
)
