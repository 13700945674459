/* eslint-disable @typescript-eslint/no-explicit-any */
import { StoreAtom } from 'ketshopweb-sdk'

export const SocketIOStore = StoreAtom({
  socket: null as {
    on: (event: string, callback: (...args: any[]) => void) => void
    once: (event: string, callback: (...args: any[]) => void) => void
    emit: (event: string, ...args: any[]) => void,
    disconnect: () => void,
    connect: () => void,
    off: (event: string, callback?: (...args: any[]) => void) => void
    connected: boolean,
    auth: any
    id: string
  }
})
