import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetFeatureLandingComponent } from './feature-landing.component'

@NgModule({
  declarations: [KetFeatureLandingComponent],
  imports: [CommonModule],
  exports: [KetFeatureLandingComponent],
})
export class KetFeatureLandingModule {}
