import { cloneDeep } from 'lodash'
import { h, hydrate, VNode } from 'preact'

import { IProductPrintLabel } from './product_print_label_a4'

export const product_export_label_a10: IProductPrintLabel = {
  init() {
    this.container_vnode = []
    return this
  },
  container_vnode: [] as VNode[],
  render(title: string) {
    const ctx = document.createElement('body')
    hydrate(this.container_vnode, ctx)
    return `<!DOCTYPE html>
    <html>
      <head>
        <meta charset="UTF-8">
        <title>${title}</title>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/html2canvas/1.4.1/html2canvas.min.js" 
          integrity="sha512-BNaRQnYJYiPSqHHDb58B0yaPfCu+Wgds8Gp/gU33kqBtgNS4tSPHuGibyoeqMV/TJlSKda6FXzoEyYGjTe+vXA==" 
          crossorigin="anonymous" 
          referrerpolicy="no-referrer"
        ></script>
        ${product_export_label_a10_css}
      </head>
      ${ctx.outerHTML}
      <script>
         html2canvas(document.querySelector("html")).then(canvas => {
          // document.body.appendChild(canvas)
          // download canvas image
          let link = document.createElement('a');
          link.download = '${title}.png';
          link.href = canvas.toDataURL()
          link.click();
          link.remove();
        });
      </script>
    </html>`
  },
  page_vnode: (
    <div class={'page-print-barcodee-a10'}>
      <div class={'grid-container'}></div>
    </div>
  ),
  page() {
    const page = cloneDeep(this.page_vnode) as VNode
    const ctx = page.props.children['props']
    ctx.children = []
    this.container_vnode.push(page)
    return {
      add_item: (
        type: 'qrcode' | 'barcode',
        props: {
          title: string
          price: string
          img: string
        },
      ) => {
        ctx.children.push(
          <div class={'sticker ' + type}>
            <div class={'text-truncate'}>{props.title}</div>
            <img src={props.img} />
            <div class={'text-truncate'}>{props.price}</div>
          </div>,
        )
      },
    }
  },
}
const product_export_label_a10_css = `<style>
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
@page {
  size: A4;
  padding: 0;
  margin: 0;
}
html,
body{
  width: 100%;
  height: 100%;
  font-family: 'Arial', sans-serif;
}
.page-print-barcodee-a10{
  width: 210mm;
  height: 297mm;
  padding: 1.27mm 20mm;
  margin: 4mm auto;
  page-break-after: always;
}
.page-print-barcodee-a10 .grid-container{
  width: 100%;
  height: 100%;
  display: grid;
  display: -ms-grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(10, 1fr);
  column-gap: 10mm;
  row-gap: 2.5mm;
  justify-content: center;
}
.page-print-barcodee-a10 .sticker{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .08);
}
.page-print-barcodee-a10 .sticker .text-truncate{
  width: 46mm;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 7pt;
  line-height: 120%;
}
.page-print-barcodee-a10 .sticker img{
  display: block;
  object-fit: contain;
}
.page-print-barcodee-a10 .sticker.qrcode img{
  width: 14mm;
  height: 14mm;
  margin: 1mm 0;
}
.page-print-barcodee-a10 .sticker.barcode img{
  width: 46mm;
  height: 16mm;
  margin: 0;
}
@media print{
  body{
    margin: 0;
    padding: 0;
  }
  .page-print-barcodee-a10{
    width: 100%;
    height: 100%;
    margin: 0;
  }
  .page-print-barcodee-a10 .sticker{
    box-shadow: none;
  }
}
</style>`
