import { Component, OnInit, Input, OnDestroy } from '@angular/core'
import { interval, Subscription } from 'rxjs'
import { KetshopwebPaymentsService } from '@cms/services/ketshopweb-payment.service'
import { NzDrawerRef, NzModalService } from 'ng-zorro-antd'
declare let QRCode: any
@Component({
  selector: 'ket-modal-payment-test',
  templateUrl: './modal-payment-test.component.html',
  styleUrls: ['./modal-payment-test.component.scss'],
})
export class KetModalPaymentTestComponent implements OnInit, OnDestroy {
  @Input() data: any = null
  @Input() sub_cus_id: any = null
  summary = {
    total: 0,
  }
  model_credit = {
    name: '',
    number: '',
    expire: '',
    securityCode: '',
  }
  step = 0
  selectTab = 'credit'
  model_installment = {
    bankins: '',
    monthins: 0,
  }

  tabs = {
    credit: {
      is_required_card: 0,
      available: true,
      min_pay: 0,
      data: null,
    },
    qr: {
      available: true,
      min_pay: 0,
      data: null,
    },
    installment: {
      available: true,
      min_pay: 0,
      data: null,
    },
  } as {
    [key: string]: {
      is_required_card?: number
      available: boolean
      min_pay: number
      data: any
    }
  }
  timeoutFn: NodeJS.Timeout
  isLoading = false
  subscription: Subscription
  installment: any = []
  monthins: any = []
  qr_render = false
  payment_finish_data = {
    name: '',
    total: 0,
    status: 0,
  }

  constructor(
    // private orderService: OrderService,
    // private confirmPaymentsSerivce: ConfirmPaymentService,
    private modalService: NzModalService,
    private drawerRef: NzDrawerRef,
    // private confirmPaymentService: ConfirmPaymentService,
    private ServicePaymentGateway: KetshopwebPaymentsService,
  ) {}

  ngOnDestroy() {
    try {
      this.subscription.unsubscribe()
    } catch (e) {}
  }

  ngOnInit(): void {
    this.getPayments()
      .then(() => (this.isLoading = false))
      .catch(() => {
        this.isLoading = false
      })
  }

  close(returnData = null) {
    this.drawerRef.close(returnData)
  }

  async next() {
    console.log('this.selectTab', this.selectTab)
    if (this.selectTab == 'credit') {
      if (this.tabs.credit.is_required_card) {
        this.step = 1
      } else {
        this.creditWithOutOrder()
      }
    } else if (this.selectTab == 'installment') {
      this.step = 1
    } else if (this.selectTab == 'qr') {
      this.step = 1
      this.qrWithOutOrder()
    }
  }

  qrWithOutOrder() {
    this.isLoading = true
    this.qr_render = false
    const model: any = {
      amount: this.summary.total,
      order_type: 'test',
      return_url: location.href,
      sub_cus_id: this.sub_cus_id,
    }
    this.ServicePaymentGateway.qrWithOutOrder(model)
      .then((res) => {
        console.log(res)
        this.isLoading = false
        if (res.is_form) {
          const form = document.createElement('form')
          form.method = res.form.method
          form.target = '_top'
          form.action = res.form.url
          for (const index of res.form.fields) {
            const from_input = document.createElement('input')
            from_input.type = index.input_type
            from_input.name = index.name
            from_input.value = index.value
            form.append(from_input)
          }
          document.body.appendChild(form)
          form.submit()
        } else {
          this.qr_render = true
          setTimeout(() => {
            try {
              // console.log('this.qr_data.qrcode => ', this.qr_data.qrcode)
              new QRCode($('#qr_code')[0], { width: 250, height: 250, text: res.qrcode })
            } catch (err) {}
          }, 10)
          this.payment_finish_data.name = 'คิวอาร์'
          this.payment_finish_data.total = this.summary.total

          const interval_checkstatus = interval(1000)
          if (this.subscription) {
            this.subscription.unsubscribe()
          }
          this.subscription = interval_checkstatus.subscribe((n) => {
            this.ServicePaymentGateway.checkOrderReference(res.reference_no).then((res) => {
              if (res.transaction_status == 1) {
                this.step = 2
                this.payment_finish_data.status = res.transaction_status
                this.subscription.unsubscribe()
              }
            })
          })
        }
      })
      .catch((data: any) => {
        this.isLoading = false
        this.modalService.error({
          nzTitle: 'เกิดข้อผิดพลาด',
          nzContent: data.error ? data.error.message : data.message || '',
        })
      })
  }

  prev(step: number = null) {
    if (step === 0) {
      this.isLoading = true
      this.getPayments()
        .then(() => {
          this.step = step
          this.isLoading = false
        })
        .catch(() => {
          this.step = step
          this.isLoading = false
        })
    } else if (step) {
      this.step = step
    } else {
      this.step--
    }
  }

  onInstallmentChange() {
    console.log(this.model_installment)
    this.model_installment.monthins = 0
    const find = this.installment.find((x) => x.code == this.model_installment.bankins)
    console.log(find)
    if (find) {
      this.monthins = find.terms
    }
  }

  async getPayments(): Promise<void> {
    try {
      this.ServicePaymentGateway.listSetting({ sub_cus_id: this.sub_cus_id })
        .then((res) => {
          console.log(res)
          this.installment = res.installment
          if (Array.isArray(res.installment)) {
            const first = res.installment[0]
            if (first) {
              this.model_installment.bankins = first.code
              this.monthins = first.terms
            }
          }

          for (const obj of res.channels) {
            if (obj.channel_type == 'Credit') {
              this.tabs.credit.is_required_card = obj.is_required_card
              this.tabs.credit.min_pay = obj.min_pay
            }
          }
        })
        .catch((data: any) => {
          console.log(data)
        })
    } catch (err) {
      throw err
    }
  }

  checkRequireField(): string {
    if (this.model_credit.name.length === 0) return 'Card owner name field is missing'
    if (this.model_credit.number.length !== 16) return 'Card no. field is missing or invalid'
    if (this.model_credit.expire.length !== 5) return 'Card expire field is missing or invalid'
    if (this.model_credit.securityCode.length !== 3) return 'CVC field is missing or invalid'
    return ''
  }

  installmentWithOutOrder() {
    this.isLoading = true
    const model: any = {
      amount: this.summary.total,
      installment: this.model_installment,
      order_type: 'test',
      return_url: location.href,
      sub_cus_id: this.sub_cus_id,
    }
    this.ServicePaymentGateway.installmentWithOutOrder(model)
      .then((res: any) => {
        console.log(res)
        const form = document.createElement('form')
        form.method = res.form.method
        form.target = '_top'
        form.action = res.form.url
        for (const index of res.form.fields) {
          const from_input = document.createElement('input')
          from_input.type = index.input_type
          from_input.name = index.name
          from_input.value = index.value
          form.append(from_input)
        }
        document.body.appendChild(form)
        form.submit()
      })
      .catch((data: any) => {
        this.isLoading = false
        this.modalService.error({
          nzTitle: 'เกิดข้อผิดพลาด',
          nzContent: data.error ? data.error.message : data.message || '',
        })
      })
    console.log(model)
  }

  creditWithOutOrder() {
    this.isLoading = true
    const model: any = {
      amount: this.summary.total,
      order_type: 'test',
      return_url: location.href,
      sub_cus_id: this.sub_cus_id,
    }
    if (this.tabs.credit.is_required_card) {
      model.card = {
        cvv: this.model_credit.securityCode,
        exp_month: this.model_credit.expire.split('/').shift(),
        exp_year: this.model_credit.expire.split('/').pop(),
        name: this.model_credit.name,
        number: this.model_credit.number,
      }
    }
    this.ServicePaymentGateway.creditWithOutOrder(model)
      .then((res: any) => {
        console.log(res)
        const form = document.createElement('form')
        form.method = res.form.method
        form.target = '_top'
        form.action = res.form.url
        for (const index of res.form.fields) {
          const from_input = document.createElement('input')
          from_input.type = index.input_type
          from_input.name = index.name
          from_input.value = index.value
          form.append(from_input)
        }
        document.body.appendChild(form)
        form.submit()
      })
      .catch((data: any) => {
        this.isLoading = false
        this.modalService.error({
          nzTitle: 'เกิดข้อผิดพลาด',
          nzContent: data.error ? data.error.message : data.message || '',
        })
      })
  }

  async confirm() {
    console.log(this.selectTab)
    if (this.selectTab == 'credit') {
      if (this.tabs.credit.is_required_card) {
        try {
          const missingField = this.checkRequireField()
          if (missingField) {
            throw new Error(missingField)
          }
          this.creditWithOutOrder()
        } catch (err) {
          this.isLoading = false
          this.modalService.error({
            nzTitle: 'เกิดข้อผิดพลาด',
            nzContent: err.error ? err.error.message : err.message || '',
          })
        }
      }
    } else if (this.selectTab == 'installment') {
      try {
        if (this.model_installment.bankins.length < 3 || !this.model_installment.monthins) {
          throw new Error('โปรดเลือกธนาคาร และ การผ่อนชำระ')
        }
        this.installmentWithOutOrder()
      } catch (err) {
        this.isLoading = false
        this.modalService.error({
          nzTitle: 'เกิดข้อผิดพลาด',
          nzContent: err.error ? err.error.message : err.message || '',
        })
      }
    }
  }
}
