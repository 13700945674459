import { Component, OnInit, Output, EventEmitter, AfterViewInit, Input } from '@angular/core'
import { KetSettingGbprimepayService } from './setting-gbprimepay.service'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalService } from 'ng-zorro-antd/modal'
import { KetModalGbpayComponent } from './modal-gbpay.component'
import { NzDrawerService } from 'ng-zorro-antd/drawer'
import { FeatureService } from '@cms/services/feature.service'
import { KetModalMessageComponent } from '../modal-message'

import { ActivatedRoute, Router } from '@angular/router'
import { KetshopwebPaymentsService } from '@cms/services/ketshopweb-payment.service'
import { cloneDeep } from 'lodash'
import { KetModalPaymentTestComponent } from '../modal-payment-test'
import { ConfirmPaymentService } from '@cms/services/confirmpayment.service'
declare let navigator: any

@Component({
  selector: 'ket-setting-gbprimepay',
  templateUrl: './setting-gbprimepay.component.html',
  styleUrls: ['./setting-gbprimepay.component.less'],
})
export class KetSettingGbprimepayComponent implements OnInit, AfterViewInit {
  constructor(
    private service: KetSettingGbprimepayService,
    private ServicePaymentGateway: KetshopwebPaymentsService,
    private message: NzMessageService,
    private modal: NzModalService,
    private drawerService: NzDrawerService,
    private Service_Feature: FeatureService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private confirmPaymentService: ConfirmPaymentService,
  ) {}

  @Output() onChange = new EventEmitter()
  @Input() sub_cus_id = null

  feature_epayment = false
  installments: any[] = []
  installmentEdit: any = null
  channel_credit: any = {}
  channel_qr: any = {}
  channel_installment: any = {}
  model: any = {}
  async ngOnInit() {
    this.feature_epayment = await this.Service_Feature.getFeature('EPAYMENT')

    this.ServicePaymentGateway.listSetting({
      sub_cus_id: this.sub_cus_id,
    })
      .then((res) => {
        console.log(res)
        this.model = res
        this.channel_credit = res.channels.find((x) => x.channel_type == 'Credit')
        this.channel_qr = res.channels.find((x) => x.channel_type == 'QR')
        this.channel_installment = res.channels.find((x) => x.channel_type == 'Installment')
        this.installments = this.model.installment
      })
      .catch((data: any) => {
        console.log(data)
      })

    // this.service
    //   .search({ name: 'gbpay' })
    //   .then((res: any) => {
    //     console.log('[KetSettingGbprimepayComponent]', res)
    //     if (res.custom1 && !res.custom1.hasOwnProperty('available')) {
    //       res.custom1['available'] = {
    //         Credit: res.available.Credit,
    //         PromptPay: res.available.PromptPay,
    //         Installment: 0,
    //       }
    //     }
    //     this.model = res
    //     this.model.custom1 = !this.model.custom1
    //       ? {
    //           is_show_installment_rate: false,
    //           min_pay_installment: 3000,
    //           min_pay: 0,
    //           available: { Credit: 0, PromptPay: 0, Installment: 0 },
    //         }
    //       : this.model.custom1
    //     if (res.custom1 && !res.custom1.hasOwnProperty('is_show_installment_rate')) {
    //       this.model.custom1['is_show_installment_rate'] = false
    //     }
    //     if (res.custom1 && !res.custom1.hasOwnProperty('min_pay_installment')) {
    //       this.model.custom1['min_pay_installment'] = 3000
    //     }
    //   })
    //   .catch((data) => {})

    // this.service
    //   .installmentBankAll('GbPay')
    //   .then((res: any[]) => {
    //     console.log('installmentBankAll', res)
    //     this.installments = res
    //     this.onInstallmentStatus()
    //   })
    //   .catch((err) => {})
  }

  ngAfterViewInit() {
    const from = this.activatedRoute.snapshot.queryParamMap.get('from')
    if (from === 'test-payment') {
      const data = {
        from: from,
        refNo: this.activatedRoute.snapshot.queryParamMap.get('refNo'),
        providerRefNo: this.activatedRoute.snapshot.queryParamMap.get('providerRefNo'),
      }
      this.openTestGBPay(data)
      this.router.navigate([], {
        queryParams: {},
      })
    }
  }

  copylink(key) {
    // this.model.connected = 1;
    try {
      navigator.clipboard.writeText(key).then((res: any) => {
        this.message.success('copy success')
      })
    } catch (err) {}
  }

  change_key() {
    this.model.connected = 0
  }
  disconnect() {
    $('#preloadwraper').fadeIn()
    this.service
      .disconnectGbPay(this.model)
      .then((res: any) => {
        $('#preloadwraper').hide()
        // this.message.success("Success")
        this.ngOnInit()

        this.modal.create<KetModalMessageComponent>({
          nzTitle: null,
          nzContent: KetModalMessageComponent,
          nzClosable: false,
          nzVisible: true,
          nzFooter: null,
          nzWidth: '350px',
          nzBodyStyle: {
            padding: '1rem',
          },
          nzWrapClassName: 'vertical-center-modal',
          nzComponentParams: {
            type: 'success',
            title: 'บันทึกเรียบร้อยแล้ว',
          },
        })
      })
      .catch((data) => {
        // this.modal.error({
        //   nzTitle: 'Error',
        //   nzContent: data.error.message
        // });
        $('#preloadwraper').hide()
        this.modal.create<KetModalMessageComponent>({
          nzTitle: null,
          nzContent: KetModalMessageComponent,
          nzClosable: false,
          nzVisible: true,
          nzFooter: null,
          nzWidth: '350px',
          nzBodyStyle: {
            padding: '1rem',
          },
          nzWrapClassName: 'vertical-center-modal',
          nzComponentParams: {
            type: 'error',
            // btnClass: "btn-primary",
            title: 'ไม่สามารถบันทึกได้',
            message: data.error.message,
          },
        })
      })
  }
  connect() {
    $('#preloadwraper').show()
    const obj = cloneDeep(this.model)
    const check_status = this.model.channels.find((x) => x.status)
    console.log(check_status)
    if (!check_status || (check_status && check_status.length == 1 && check_status[0].channel_type == 'Installment')) {
      this.confirmPaymentService
        .bankStatus({ status: true })
        .then((res: any) => {
          console.log(res)
        })
        .catch((e: any) => {
          console.log(e)
        })
    }

    this.ServicePaymentGateway.saveSetting(obj)
      .then((res: any) => {
        $('#preloadwraper').hide()
        this.ngOnInit()
        this.onChange.emit(this.model)
        this.modal.create<KetModalMessageComponent>({
          nzTitle: null,
          nzContent: KetModalMessageComponent,
          nzClosable: false,
          nzVisible: true,
          nzFooter: null,
          nzWidth: '350px',
          nzBodyStyle: {
            padding: '1rem',
          },
          nzWrapClassName: 'vertical-center-modal',
          nzComponentParams: {
            type: 'success',
            title: 'บันทึกเรียบร้อยแล้ว',
          },
        })
      })
      .catch((data) => {
        $('#preloadwraper').hide()
        this.modal.create<KetModalMessageComponent>({
          nzTitle: null,
          nzContent: KetModalMessageComponent,
          nzClosable: false,
          nzVisible: true,
          nzFooter: null,
          nzWidth: '350px',
          nzBodyStyle: {
            padding: '1rem',
          },
          nzWrapClassName: 'vertical-center-modal',
          nzComponentParams: {
            type: 'error',
            // btnClass: "btn-primary",
            title: 'ไม่สามารถบันทึกได้',
            message: data.error.message,
          },
        })
      })
    return
    $('#preloadwraper').show()
    this.service
      .connectGbPay(this.model)
      .then((res: any) => {
        $('#preloadwraper').hide()
        // this.message.success("Success")
        this.ngOnInit()
        this.onChange.emit(this.model)
        this.modal.create<KetModalMessageComponent>({
          nzTitle: null,
          nzContent: KetModalMessageComponent,
          nzClosable: false,
          nzVisible: true,
          nzFooter: null,
          nzWidth: '350px',
          nzBodyStyle: {
            padding: '1rem',
          },
          nzWrapClassName: 'vertical-center-modal',
          nzComponentParams: {
            type: 'success',
            title: 'บันทึกเรียบร้อยแล้ว',
          },
        })
      })
      .catch((data) => {
        // this.modal.error({
        //   nzTitle: 'Error',
        //   nzContent: data.error.message
        // });

        $('#preloadwraper').hide()
        this.modal.create<KetModalMessageComponent>({
          nzTitle: null,
          nzContent: KetModalMessageComponent,
          nzClosable: false,
          nzVisible: true,
          nzFooter: null,
          nzWidth: '350px',
          nzBodyStyle: {
            padding: '1rem',
          },
          nzWrapClassName: 'vertical-center-modal',
          nzComponentParams: {
            type: 'error',
            // btnClass: "btn-primary",
            title: 'ไม่สามารถบันทึกได้',
            message: data.error.message,
          },
        })
      })
  }

  openGBPay() {
    const drawerRef = this.drawerService
      .create<KetModalGbpayComponent, any>({
        nzTitle: 'ตั้งค่า GB PRIME PAY',
        nzWidth: '70%',
        nzContent: KetModalGbpayComponent,
        nzContentParams: {
          model: this.model,
        },
      })
      .afterClose.subscribe((data) => {
        drawerRef.unsubscribe()
        console.log('Drawer data', data)
        // if(data !== undefined){
        //   this.ngOnInit()
        // }
        this.ngOnInit()
      })
  }

  openTestGBPay(queryParam: any = null) {
    const drawerRef = this.drawerService
      .create<KetModalPaymentTestComponent>({
        nzTitle: 'ทดสอบการชำระเงิน',
        nzWidth: '500px',
        nzContent: KetModalPaymentTestComponent,
        nzMaskClosable: false,
        nzWrapClassName: 'body-nopadding',
        nzContentParams: {
          data: queryParam,
          sub_cus_id: this.sub_cus_id,
        },
      })
      .afterClose.subscribe((data) => {
        drawerRef.unsubscribe()
      })
  }

  customMinPay() {
    this.connect()
  }

  debug() {
    console.log(this.model)
  }

  isInstallmentDisable = false
  onInstallmentStatus() {
    let active_count = 0
    for (const obj of this.installments) {
      if (obj.status) active_count++
    }
    if (active_count == 1) {
      this.isInstallmentDisable = true
    } else {
      this.isInstallmentDisable = false
    }
  }

  onSelectInstallment(obj: any) {
    this.installmentEdit = { ...obj }
  }

  onInstallmentSave(obj: any) {
    const ins = this.installments.find((x) => x.id == obj.id)
    if (!ins) {
      return
    }
    ins.status = obj.status
    ins.max_term = obj.max_term
    this.model.installment = this.installments
    this.installmentEdit = null
    this.connect()
    return
    const find = this.installments.find((x) => x.id == obj.id)
    find.status = obj.status
    find.max_term = obj.max_term
    this.installmentEdit = null
    $('#preloadwraper').fadeIn()
    this.service
      .updateInstallmentBank(this.installments)
      .then((res: any) => {
        $('#preloadwraper').hide()
        // this.message.success("Success")
        this.ngOnInit()
        this.onChange.emit(this.model)
        this.modal.create<KetModalMessageComponent>({
          nzTitle: null,
          nzContent: KetModalMessageComponent,
          nzClosable: false,
          nzVisible: true,
          nzFooter: null,
          nzWidth: '350px',
          nzBodyStyle: {
            padding: '1rem',
          },
          nzWrapClassName: 'vertical-center-modal',
          nzComponentParams: {
            type: 'success',
            title: 'บันทึกเรียบร้อยแล้ว',
          },
        })
      })
      .catch((data) => {
        $('#preloadwraper').hide()
        this.modal.create<KetModalMessageComponent>({
          nzTitle: null,
          nzContent: KetModalMessageComponent,
          nzClosable: false,
          nzVisible: true,
          nzFooter: null,
          nzWidth: '350px',
          nzBodyStyle: {
            padding: '1rem',
          },
          nzWrapClassName: 'vertical-center-modal',
          nzComponentParams: {
            type: 'error',
            // btnClass: "btn-primary",
            title: 'ไม่สามารถบันทึกได้',
            message: data.error.message,
          },
        })
      })
  }
}
