/* eslint-disable @typescript-eslint/no-explicit-any */
import qrcode from 'qrcode'

export function qr_to_base64(
  text: string,
  opt = {
    scale: 0,
    width: 120,
    margin: 1,
  } as {
    scale: number
    width: number
    margin: number
    type?: string
  },
): Promise<string> {
  return new Promise((resolve, reject) => {
    // option
    // https://www.npmjs.com/package/qrcode
    qrcode.toDataURL(text, opt, function (err: any, code: any) {
      if (err) {
        reject(reject)
        return
      }
      resolve(code)
    })
  })
}
