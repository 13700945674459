import { custom_genedenim } from './custom_genedenim'
export const custom_feature = () => {
  let feature_default = {
    product: {
      detail: false,
    },
    header_custom: {
      genedenim: false,
    },
    sections: [],
  }

  const custom_genedenim_domain = [
    'genedenim.com',
    'genedenim.ketshoptest.com',
    'uat.genedenim.com',
    'chompoodesign.ketshopweb.com',
  ]
  if (custom_genedenim_domain.includes(location.hostname)) {
    feature_default = custom_genedenim
  }
  return feature_default
}
