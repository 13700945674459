import { SafeAny } from '@ketshopweb/ui/core/types'
import { Component, forwardRef, OnInit, Input } from '@angular/core'
import { Store } from '@ngrx/store'
import { IChannelsState, NgStore, selectors_channels } from '@store/ketshopweb-store'
import { cloneDeep, groupBy } from 'lodash'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { OnChangeType, OnTouchedType } from '@ketshopweb/ui/core/types/control-value-accessor'
import { selectors_warehouse } from '@store/ketshopweb-store'
import { KetSettingGbprimepayService } from '@ketshopweb/ui/setting-gbprimepay'
import { log } from 'ng-zorro-antd'
@Component({
  selector: 'ket-channels-selector',
  templateUrl: './channels-selector.component.html',
  styleUrls: ['./channels-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => KetChannelsSelectorComponent),
      multi: true,
    },
  ],
})
export class KetChannelsSelectorComponent extends NgStore implements ControlValueAccessor {
  onChange: OnChangeType = () => {}
  onTouched: OnTouchedType = () => {}

  constructor(
    public store: Store<SafeAny>,
    private ketSettingGbprimepayService: KetSettingGbprimepayService,
  ) {
    super(store)
  }

  // state
  list_channel: Partial<IChannelsState.ChannelsState['list_channel']> = {
    all: [],
    website: [],
    marketplace: [],
    pos: [],
    warehouse: [],
  }
  list_channel_wharehouse: IChannelsState.Channel[] = []
  check_all_disable = false
  check_all = false
  // all check
  marketplace_all = false
  website_all = false
  pos_all = false
  warehouse_all = false

  gbpay = null as {
    api_key: string
    api_secret: string
    custom_key: string
    connected: number
  }
  // Input
  @Input() mode = 'collapse'
  @Input() ignore_marketplace = false
  @Input() is_wharehouse = false
  @Input() warehouse = false
  @Input() exclude_channels = [] as string[]

  onDestroy() {}

  async writeValue(value: string[]) {
    const list_channel = await this.storeQuery(selectors_channels.list_channel)
    this.list_channel = cloneDeep(list_channel)

    this.subscribe('warehouse_list', selectors_warehouse.warehouse_list, (data) => {
      this.list_channel.warehouse = cloneDeep(data)
    })

    for (const warehouse of this.list_channel.warehouse) {
      warehouse['group'] = 'warehouse'
      warehouse['value'] = warehouse.id
    }

    this.list_channel.warehouse.push({
      group: 'warehouse',
      value: -1,
      name: 'Website',
    })

    if (this.warehouse) {
      this.list_channel_wharehouse = [...this.list_channel.warehouse]
      this.list_channel.all = this.list_channel_wharehouse
    } else if (this.is_wharehouse) {
      this.list_channel_wharehouse = [...this.list_channel.marketplace, ...this.list_channel.pos]
      this.list_channel.all = this.list_channel_wharehouse
    } else {
      this.list_channel.all = [...this.list_channel.website, ...this.list_channel.marketplace, ...this.list_channel.pos]
    }

    if (this.exclude_channels.length > 0) {
      this.list_channel.all = this.list_channel.all.filter((x) => !this.exclude_channels.includes(x.value))
      this.list_channel.marketplace = this.list_channel.marketplace.filter(
        (x) => !this.exclude_channels.includes(x.value.toString()),
      )
      this.list_channel.website = this.list_channel.website.filter(
        (x) => !this.exclude_channels.includes(x.value.toString()),
      )
      this.list_channel.warehouse = this.list_channel.warehouse.filter(
        (x) => !this.exclude_channels.includes(x.value.toString()),
      )
      this.list_channel_wharehouse = this.list_channel_wharehouse.filter(
        (x) => !this.exclude_channels.includes(x.value.toString()),
      )
      this.list_channel.pos = this.list_channel.pos.filter((x) => !this.exclude_channels.includes(x.value.toString()))
    }

    if (!value || value.length === 0) {
      this.list_channel.all.forEach((c) => {
        c.checked = true
      })
      this.onCheckState()
      return []
    }

    this.list_channel.all.forEach((c) => {
      if (value.includes(c.value.toString())) {
        c.checked = true
      }
    })

    this.onCheckState()
  }

  registerOnChange(fn: OnChangeType): void {
    this.onChange = fn
  }

  registerOnTouched(fn: OnTouchedType): void {
    this.onTouched = fn
  }

  onCheckAll() {
    if (this.check_all) {
      this.website_all = true
      this.marketplace_all = true
      this.pos_all = true
      this.warehouse_all = true
      this.checkAllByGroup('website', false)
      this.checkAllByGroup('marketplace', false)
      this.checkAllByGroup('pos', false)
      this.checkAllByGroup('warehouse', false)
      this.onCheckState()
    }
  }

  checkAllByGroup(type: 'website' | 'marketplace' | 'pos' | 'warehouse', onCheckState = true) {
    if (type == 'website') {
      this.list_channel.all.map((v) => {
        if (v.group !== 'website') return v
        v.checked = this.website_all
        return v
      })
    } else if (type == 'marketplace') {
      this.list_channel.all.map((v) => {
        if (v.group !== 'marketplace') return v
        v.checked = this.marketplace_all
        return v
      })
    } else if (type == 'pos') {
      this.list_channel.all.map((v) => {
        if (v.group !== 'pos') return v
        v.checked = this.pos_all
        return v
      })
    } else if (type == 'warehouse') {
      this.list_channel.all.map((v) => {
        if (v.group !== 'warehouse') return v
        v.checked = this.warehouse_all
        return v
      })
    }

    if (onCheckState) this.onCheckState()
  }

  check(channel: IChannelsState.Channel) {
    const m_channel = this.list_channel.all.find((x) => x.value == channel.value)
    if (m_channel) {
      m_channel.checked = channel.checked
    }
    this.onCheckState()
  }

  onCheckState() {
    const selectVals: IChannelsState.Channel[] = []

    this.list_channel.all.forEach((c) => {
      if (c.checked) selectVals.push(c)
    })
    const marketplace = selectVals.filter((x) => x.group == 'marketplace')
    const website = selectVals.filter((x) => x.group == 'website')
    const pos = selectVals.filter((x) => x.group == 'pos')
    const warehouse = selectVals.filter((x) => x.group == 'warehouse')

    if (marketplace.length == this.list_channel.marketplace.length) {
      this.marketplace_all = true
    } else {
      this.marketplace_all = false
    }

    if (pos.length == this.list_channel.pos.length) {
      this.pos_all = true
    } else {
      this.pos_all = false
    }

    if (website.length == this.list_channel.website.length) {
      this.website_all = true
    } else {
      this.website_all = false
    }

    if (warehouse.length == this.list_channel.warehouse.length) {
      this.warehouse_all = true
    } else {
      this.warehouse_all = false
    }
    if (this.warehouse && this.warehouse_all) {
      this.check_all = true
      this.check_all_disable = true
    } else if (this.website_all && this.marketplace_all && this.pos_all) {
      this.check_all = true
      this.check_all_disable = true
    } else if (this.is_wharehouse && this.marketplace_all && this.pos_all) {
      this.check_all = true
      this.check_all_disable = true
    } else {
      this.check_all = false
      this.check_all_disable = false
    }
    // console.log('this.list_channel.marketplace', this.list_channel.marketplace)

    if (this.check_all) {
      this.onChange([])
    } else {
      const vals = groupBy<string>(selectVals.map((x) => x.value))
      this.onChange(Object.keys(vals))
    }
  }
}
