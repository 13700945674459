import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { BlogDisplayRoutingModule } from './blog-display-routing.module'
import { BlogDisplayComponent } from './blog-display.component'
import { KetFeatureLandingModule } from '@ketshopweb/ui/feature-landing'
import { KetPermissionModule } from '@ketshopweb/ui/permission'
import { NzGridModule } from 'ng-zorro-antd/grid'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { FormsModule } from '@angular/forms'
import { NzInputNumberModule } from 'ng-zorro-antd/input-number'
import { NzInputModule } from 'ng-zorro-antd/input'
import { KetSelectFontModule } from '@ketshopweb/ui/select-font'
import { ColorPickerModule } from 'ngx-color-picker'
import { NzModalModule } from 'ng-zorro-antd/modal'
import { KetBlogDisplaySortModule } from '@ketshopweb/ui/blog-display-sort'

@NgModule({
  declarations: [BlogDisplayComponent],
  imports: [
    CommonModule,
    BlogDisplayRoutingModule,

    KetFeatureLandingModule,
    KetPermissionModule,
    KetSelectFontModule,
    KetBlogDisplaySortModule,

    NzGridModule,
    NzButtonModule,
    NzSelectModule,
    FormsModule,
    NzInputNumberModule,
    NzInputModule,
    ColorPickerModule,
    NzModalModule,
  ],
})
export class BlogDisplayModule {}
