import { NzAffixModule } from 'ng-zorro-antd/affix'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { ProductDisplayRoutingModule } from './product-display-routing.module'
import { ProductDisplayComponent } from './product-display.component'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzModalModule } from 'ng-zorro-antd/modal'
import { KetPermissionModule } from '@ketshopweb/ui/permission'
import { NzTabsModule } from 'ng-zorro-antd/tabs'
import { NzGridModule } from 'ng-zorro-antd/grid'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { FormsModule } from '@angular/forms'
import { NzInputNumberModule } from 'ng-zorro-antd/input-number'
import { ColorPickerModule } from 'ngx-color-picker'
import { KetFeatureLandingModule } from '@ketshopweb/ui/feature-landing'
import { KetSelectFontModule } from '@ketshopweb/ui/select-font'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzSwitchModule } from 'ng-zorro-antd/switch'
import { KetFontModule } from '@ketshopweb/ui/font'
import { NzAlertModule } from 'ng-zorro-antd/alert'
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox'
import { KetSanitizeHtmlModule } from '@ketshopweb/ui/sanitize-html'
import { NzRadioModule } from 'ng-zorro-antd/radio'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzPopoverModule } from 'ng-zorro-antd/popover'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'
import { KetProductDisplaySortModule } from '@ketshopweb/ui/product-display-sort'
import { NzUploadModule } from 'ng-zorro-antd/upload'
import { KetFilemanagerModule } from '@ketshopweb/ui/filemanager'
import { KetWishlistButtonModule } from '@ketshopweb/ui/wishlist-button'
import { KetOwlCarouselModule } from '@ketshopweb/ui/owl-carousel'
import { KetModalTagPromoPointModule } from '@ketshopweb/ui/modal-tag-promo-point'
import { NzDividerModule } from 'ng-zorro-antd/divider'
@NgModule({
  declarations: [ProductDisplayComponent],
  imports: [
    CommonModule,
    ProductDisplayRoutingModule,
    NzButtonModule,
    NzModalModule,
    KetPermissionModule,
    NzTabsModule,
    NzGridModule,
    NzSelectModule,
    FormsModule,
    NzInputNumberModule,
    ColorPickerModule,
    KetFeatureLandingModule,
    KetSelectFontModule,
    NzInputModule,
    NzSwitchModule,
    KetFontModule,
    NzAlertModule,
    NzCheckboxModule,
    NzRadioModule,
    KetSanitizeHtmlModule,
    NzPopoverModule,
    NzIconModule,
    NzToolTipModule,
    KetProductDisplaySortModule,
    NzUploadModule,
    NzAffixModule,
    KetFilemanagerModule,
    KetModalTagPromoPointModule,
    KetWishlistButtonModule,
    KetOwlCarouselModule,
    NzDividerModule,
  ],
})
export class ProductDisplayModule {}
