import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { PageOptionsEffects } from './effects'
import { PageOptionsFEATURE_KEY, PageOptionsReducer } from './reducer'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(PageOptionsFEATURE_KEY, PageOptionsReducer),
    EffectsModule.forFeature([PageOptionsEffects]),
  ],
})
export class PageOptionsSateModule {}
