import { Directive, ElementRef, Input, OnChanges, OnInit, Optional, SimpleChanges } from '@angular/core'
import * as jwt_decode from 'jwt-decode'
import { extend as _extend } from 'lodash'

@Directive({
  selector: '[ket-permission]',
})
export class KetPermissionDirective implements OnInit, OnChanges {
  current_user: any
  _option: any = {
    action: 'delete',
  }
  constructor(@Optional() private el?: ElementRef) {
    try {
      const _token = JSON.parse(localStorage.getItem('access_token'))
      const user = jwt_decode(_token.access_token)
      this.current_user = user
    } catch (e) {
      // console.log(e)
      this.current_user = {
        user_role: 'unknown',
      }
    }
    // console.log('PermissionDirective CONSTRUCTOR');
  }
  @Input('ket-permission') permission: any

  @Input('ket-permission-option') option: any

  ngOnInit() {
    this.exec()
  }

  exec(permission = null as any[]) {
    this._option = _extend({}, this._option, this.option)
    // // console.log(this.option);
    // console.log(this.permission);
    // console.log('permission2', this.permission)
    // console.log(this.current_user);
    this.el.nativeElement.style['pointerEvents'] = ''
    this.el.nativeElement.style['backgroundColor'] = ''
    this.el.nativeElement['disabled'] = false

    if (!this.checkPermission(permission ? permission : this.permission)) {
      switch (this._option.action) {
        case 'delete':
          this.el.nativeElement.remove()
          break
        case 'disabled':
          this.el.nativeElement.disabled = true
          this.el.nativeElement.style['pointerEvents'] = 'none'
          // background-color: rgb(239 239 239 / 48%);
          this.el.nativeElement.style['backgroundColor'] = 'rgb(239 239 239 / 48%)'
          break
        case 'disabled-pointerevent':
          this.el.nativeElement.style['pointerEvents'] = 'none'
          break
        default:
          this.el.nativeElement.remove()
          break
      }
    } else {
    }
    // this.el.nativeElement.innerText = this.Service_Translate.translate(this.el.nativeElement.innerText, this.tmtTranslate);
  }

  checkPermission(permission: any[]) {
    if (location.pathname.includes('/system/')) {
      return permission.includes(this.current_user.user_role)
    } else {
      return true
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('changeschanges', changes)
    if (changes && changes.permission && changes.permission && changes.permission.currentValue) {
      // console.log(this.el.nativeElement.dataset,JSON.stringify(changes.permission.currentValue), changes.permission)
      this.exec(changes.permission.currentValue)
    }
  }
}
