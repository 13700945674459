import { Fragment, h, hydrate } from 'preact'
import { IPurchaseOrderHtml } from './types'
import { OrderA4Full } from './order_a4_full_html'
import { OrderA4Half } from './order_a4_half_html'
import { qr_to_base64 } from 'libs/ketshopweb-services/func/qr_to_base64'
import * as moment from 'moment'
import { TmpBodyShippingJT } from './tmp_body_shipping_jt'
import { Order46N } from './order_46n_html'
import { OrderA48P } from './order_a4_8_html'
import { Order46N2 } from './order_46n2_html'
declare let JsBarcode: any

export const shipping_label = {
  to_element: async (
    order: IPurchaseOrderHtml.Order,
    option: IPurchaseOrderHtml.Option,
    cus_id: number,
    i: number,
    all_page: number,
  ) => {
    const qr_ordercode = (await qr_to_base64(`${cus_id}-${order.ordercode}`, {
      scale: 0,
      width: 120,
      margin: 1,
    })) as string

    const canvas = document.createElement('canvas')
    // Option
    // https://github.com/lindell/JsBarcode
    let trackcode = 'null'
    if (order.trackcode) {
      trackcode = order.trackcode
    }

    JsBarcode(canvas, trackcode, {
      format: 'CODE128',
      lineColor: '#000',
      width: option.layout === 8 ? 5 : 3,
      height: 40,
      displayValue: false,
    })
    const barcode = canvas.toDataURL('image/png')

    return (
      <Fragment>
        <TmpBodyShippingJT
          order={order}
          barcode={barcode}
          qr_ordercode={qr_ordercode}
          layout={option.layout}
          number={i}
          all_page={all_page}
        />
      </Fragment>
    )
  },
  generate: async (orders: IPurchaseOrderHtml.Order[], option: IPurchaseOrderHtml.Option, cus_id: number) => {
    let i = 1
    const all_page = orders.length  
     
    if (option.layout == IPurchaseOrderHtml.Layout.A4FUll) {
      const elements: h.JSX.Element[] = []
      for (const order of orders) {
        elements.push(await shipping_label.to_element(order, option, cus_id, i, all_page))
        i++
      }
      return [<OrderA4Full elements={elements} />]
    }
    if (option.layout == IPurchaseOrderHtml.Layout.A46N || option.layout == IPurchaseOrderHtml.Layout.A46NSku || option.layout == IPurchaseOrderHtml.Layout.A46N2Sku) {
      const elements: h.JSX.Element[] = []
      for (const order of orders) {
        elements.push(await shipping_label.to_element(order, option, cus_id, i, all_page))
        i++
      }

      return [<Order46N elements={elements} />]
    }

    if (option.layout == IPurchaseOrderHtml.Layout.A46N2) {
        const elements: ([h.JSX.Element, h.JSX.Element] | [h.JSX.Element])[] = []
        for (let i = 0; i < orders.length; i += 2) {
            const order = orders[i]
            const order2 = orders[i + 1]
            const item = [await shipping_label.to_element(order, option, cus_id, i, all_page)] as
              | [h.JSX.Element, h.JSX.Element]
              | [h.JSX.Element]
            if (order2) {
              item.push(await shipping_label.to_element(order2, option, cus_id, i, all_page))
            }
    
            elements.push(item)
            i++
          }
    
  
        return [<Order46N2 elements={elements} />]
      }


    if (option.layout == IPurchaseOrderHtml.Layout.A48Label) {
      const elements: ([h.JSX.Element, h.JSX.Element, h.JSX.Element, h.JSX.Element, h.JSX.Element, h.JSX.Element, h.JSX.Element, h.JSX.Element] | [h.JSX.Element])[] = []
      for (let i = 0; i < orders.length; i += 8) {
        const order = orders[i]
        const order2 = orders[i + 1]
        const order3 = orders[i + 2]
        const order4 = orders[i + 3]
        const order5 = orders[i + 4]
        const order6 = orders[i + 5]
        const order7 = orders[i + 6]
        const order8 = orders[i + 7]

        
        const item = [await shipping_label.to_element(order, option, cus_id, i, all_page)] as
          | [h.JSX.Element, h.JSX.Element,h.JSX.Element, h.JSX.Element,h.JSX.Element, h.JSX.Element,h.JSX.Element, h.JSX.Element]
          | [h.JSX.Element]
        if (order2) {
          item.push(await shipping_label.to_element(order2, option, cus_id, i, all_page))
        }
        if (order3) {
          item.push(await shipping_label.to_element(order3, option, cus_id, i, all_page))
        }
        if (order4) {
          item.push(await shipping_label.to_element(order4, option, cus_id, i, all_page))
        }
        if (order5) {
          item.push(await shipping_label.to_element(order5, option, cus_id, i, all_page))
        }
        if (order6) {
          item.push(await shipping_label.to_element(order6, option, cus_id, i, all_page))
        }
        if (order7) {
          item.push(await shipping_label.to_element(order7, option, cus_id, i, all_page))
        }
        if (order8) {
          item.push(await shipping_label.to_element(order8, option, cus_id, i, all_page))
        }

        elements.push(item)
        i++
      }
      

      return [<OrderA48P elements={elements} />]
    }

    if (option.layout == IPurchaseOrderHtml.Layout.A4Half) {
      const elements: ([h.JSX.Element, h.JSX.Element] | [h.JSX.Element])[] = []
      for (let i = 0; i < orders.length; i += 2) {
        const order = orders[i]
        const order2 = orders[i + 1]
        const item = [await shipping_label.to_element(order, option, cus_id, i, all_page)] as
          | [h.JSX.Element, h.JSX.Element]
          | [h.JSX.Element]
        if (order2) {
          item.push(await shipping_label.to_element(order2, option, cus_id, i, all_page))
        }

        elements.push(item)
        i++
      }
      return [<OrderA4Half elements={elements} />]
    }

    return []
  },
  to_html: (
    Tsx: h.JSX.Element[],
    orientation: 'landscape' | 'portrait' | '4in 6in' | 'A4' = 'portrait',
    batch = '',
  ) => {
    const ctx = document.createElement('body')
    hydrate([...Tsx, class_html], ctx)
    return `<!DOCTYPE html>
        <html>
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>พิมพ์ใบสั่งซื้อ ${batch} ${moment().format('YYYY-MM-DD HH_mm_ss')}</title>
            <link
                href="https://fonts.googleapis.com/css?family=Open+Sans:400,700|Roboto:400,700|Sarabun:400,700&amp;display=swap"
                rel="stylesheet">
            <link href="/kerry/style-print-label.css" rel="stylesheet">
            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
                integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
            <link rel="stylesheet" type="text/css" href="style-print-label.css">
        </head>
         <style>
                @page {
                    size: ${orientation};
                }
          </style>
            ${ctx.outerHTML}
        </html>
        `
  },
}

const class_html = (
  <style>
    {`* 
    
    @media print {
    body.col-count-2{
        column-count: 2;
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-rule: 1px dotted #CCCCCC;
    }
}
body{
    margin: 0;
    font-family: 'Arial', 'Helvetica', sans-serif;
}
.tmt-break-page{
    padding: 2mm;
}
.tmt-break-page.size-a4{
    page-break-inside: always;
    max-width: 1000px;
}
.size-paperang{
    max-width: 57mm;
    page-break-after: always;
}
.grid {
    max-width: 210mm;
    display: grid;
    gap: 0;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: max-content;
}

.col-count-2 .tmt-break-page.size-a4{
    page-break-inside: avoid;
    break-before: column;
    break-after: column;
    min-height: 200mm;
    padding: 0;
}

.tmt-break-page.half{
    width:100%; 
    max-width: 4in;
    display: flex; 
    flex-direction: column; 
    clear: both;
    padding: 0 !important;
    page-break-before: unset !important;
}
.tmt-label-half:nth-child(odd):not(:nth-child(1)){
    page-break-inside: always;
}
.tmt-label-half{
    flex:1; 
    padding: 2mm;
    min-height: calc( 3in - 10mm );
}
.tmt-label-half:nth-child(odd){
    border-bottom: 1px dotted #CCCCCC; 
}
.label-8-persheet{
    min-height: 250px;
    padding: 2mm;
}
.label-8-persheet:nth-child(8n+1){
    page-break-inside: always;
}
.label-8-persheet:not(:nth-child(8n+1)):not(:nth-child(9n+1)):not(:nth-child(-n+2)){
    border-top: 1px solid #CCCCCC;
}
.label-8-persheet:nth-child(odd){
    border-right: 1px solid #CCCCCC;
}
.tmt-wrap-detail{
    width: 100%;
}
table{ width: 100%; }
table.label,
table.tmt-detail,
table.label th,
table.tmt-detail tr.header,
table.label td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 4px;
}
table.tmt-detail tr{
    page-break-before: auto;
}
table.tmt-detail td{
    vertical-align: top;
    padding: 4px;
}
.flex-wrap{
    display: flex; 
    flex-wrap: wrap;
    width: 100%;
}
.text-left{ text-align: left; }
.text-center{ text-align: center; }
.text-right{ text-align: right; }

.tmp-large .font-l{
    font-size: 48px !important;
}
.tmp-large .font-m{
    font-size: 36px !important;
}
.tmp-large{
    font-size: 20px;
}
.tmp-large .font-s{
    font-size: 16px !important;
}
.tmp-large .font-xs{
    font-size: 12px !important;
}
.tmt-large .wrap-scan-image{
    width: 1.25in;
    vertical-align: middle;
    text-align: center;
    padding: 4px 0;
}

.font-l{
    font-size: 28px
}
.font-m{
    font-size: 32px !important;
}
.tmp-default{
    font-size: 24px;
}
.font-s{
    font-size: 16px !important;
}
.font-xs{
    font-size: 14px !important;
}
.wrap-scan-image{
    width: 40mm;
    vertical-align: middle;
    text-align: center;
    padding: 4px 0;
}

.tmp-small .font-l{
    font-size: 28px !important;
}
.tmp-small .font-m{
    font-size: 20px !important;
}
.tmp-small{
    font-size: 10px;
}
.tmp-small .font-sm{
    font-size: 14px !important;
}
.tmp-small .font-s{
    font-size: 10px !important;
}
.tmp-small .font-xs{
    font-size: 8px !important;
}
.tmp-small .wrap-scan-image{
    width: 32mm;
    vertical-align: middle;
    text-align: center;
    padding: 4px 0;
}

.tmp-extrasmall .font-l{
    font-size: 18px !important;
}
.tmp-extrasmall .font-m{
    font-size: 14px !important;
}
.tmp-extrasmall{
    font-size: 10px;
}
.tmp-extrasmall .font-s{
    font-size: 8px !important;
}
.tmp-extrasmall .font-xs{
    font-size: 8px !important;
}
.tmp-extrasmall .wrap-scan-image{
    width: 18mm;
    vertical-align: middle;
    text-align: center;
    padding: 4px 0;
}


.tmp-paperang .font-l{
    font-size: 12px !important;
}
.tmp-paperang .font-m{
    font-size: 8px !important;
}
.tmp-paperang{
    font-size: 6px;
}
.tmp-paperang .font-s{
    font-size: 4px !important;
}
.tmp-paperang .font-xs{
    font-size: 4px !important;
}
.tmp-paperang .wrap-scan-image{
    width: 10mm;
    vertical-align: middle;
    text-align: center;
    padding: 0;
}


.wrap-scan-image img{
    max-width: 100%; 
    height: auto;
}

[class$="bold"]{font-weight: bold;}

.mb-1{ margin-bottom: 4px;}
.mb-2{ margin-bottom: 8px;}
.vertical-b{vertical-align: bottom;}
.product-name{ word-break: break-word;}
.pagebreak {
    clear: both;
    page-break-after: always;
}

.box-product{
    padding: 0mm 2mm;
}
.product-image{
    position: relative;
    width: 40px;
    overflow: hidden;
    margin: auto;
}
.product-image:before{
    content: '';
    display: block;
    padding-top: 100%;
}
.product-image img{
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.box-product table{
    width: 100%;
}
.box-product table{
    font-size: 20px;
}
.tmp-small .box-product table{
    font-size: 12px;
}
.box-product table thead{
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;

}
.box-product table thead tr th,
.box-product table tbody tr td,
.box-product table tfoot tr td{
    padding: 5px 10px;
    line-height: 1.1;
}
.box-product table tfoot{
    border-top: 1px solid #000;
}
.box-product table tfoot tr td{
    padding: 2px 10px;
}
.box-product table s{
    font-size: 11px;
}

.border-print {
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
}

.b-t {
    border-top: 1px solid #000 !important;
}

.b-r {
    border-right: 1px solid #000 !important;
}

.b-l {
    border-left: 1px solid #000 !important;
}

.border-none{
    border: none;
}


.h-100{
    height: 100%;
}
.w-100{
    width: 100%;
}

.text-vertical-lr{
    writing-mode: vertical-lr; 
    text-orientation: mixed;
    font-size: 22px;
    font-weight: 700;
    padding: 4px 0;
}

.text-rotate-D180{
    transform: rotate(-180deg);
}
.flex-row-start{
    display: flex;
    flex-direction: row; 
    justify-content: start;
}

.flex-row-end{
    display: flex;
    flex-direction: row; 
    justify-content: end;
}

.p-orderprint{
    padding-bottom: 180px;
}
.flex-row-between{
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
}
.flex-row{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.text-nowrap{
    text-wrap: nowrap;
}

.justify-center{
    display: flex;
    justify-content: center;
}

.bold{
    font-weight: 700;
}

.text-circle{
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content:center;
    border: 2px solid #000;
    border-radius: 100%;
    font-size: 18px;
    line-height: normal;
    font-weight: 600;
    aspect-ratio: 1/1;
}

.text-circle-A48{
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content:center;
    border: 2px solid #000;
    border-radius: 100%;
    font-size: 12px;
    line-height: normal;
    font-weight: 400;
    aspect-ratio: 1/1;
}

.text-circle-black{
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content:center;
    border: 2px solid #000;
    border-radius: 100%;
    font-size: 18px;
    line-height: normal;
    font-weight: 600;
    aspect-ratio: 1/1;
    background-color: #212529;
    border-inline: 18px solid #000;
    color: #ffffff;
}

.text-circle-black-A48{
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content:center;
    border: 2px solid #000;
    border-radius: 100%;
    font-size: 12px;
    line-height: normal;
    font-weight: 400;
    aspect-ratio: 1/1;
    background-color: #212529;
    border-inline: 12px solid #000;
    color: #ffffff;
}

 .p-2 {
        padding: .5rem;
      }
        
 .p-1 { 
        padding:0.25rem
      }

.text-jnt-red {
    color: rgb(213, 41, 41);
}`}
  </style>
)
