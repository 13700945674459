import { createFeatureSelector, createSelector } from '@ngrx/store'
import { IPageOptionsState } from './models'
import { PageOptionsFEATURE_KEY } from './reducer'

const selectState = createFeatureSelector<IPageOptionsState.PageOptionsState>(PageOptionsFEATURE_KEY)

const add_to_cart_now = createSelector(
  selectState,
  (state: IPageOptionsState.PageOptionsState) => state.page_option && state.page_option.add_to_cart_now,
)
const add_to_cart_now_setting = createSelector(
  selectState,
  (state: IPageOptionsState.PageOptionsState) => state.page_option && state.page_option.add_to_cart_now_setting,
)
const wishlist_setting = createSelector(
  selectState,
  (state: IPageOptionsState.PageOptionsState) => state.page_option && state.page_option.wishlist_setting,
)

export const selectors_page_options = {
  add_to_cart_now,
  add_to_cart_now_setting,
  wishlist_setting,
}
