import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Category, ListLineProduct, ProductInput, SearchKetshopProductToLineShopping } from './models'

@Injectable({
  providedIn: 'root',
})
export class CmsLineShoppingService {
  private http = inject(HttpClient)

  categories() {
    return this.http.get<{
      message: string
      status: number
      data: Category[]
    }>('/services/v2/line-shopping/products/categories')
  }

  searchKetshopProductToLineShopping(model: {
    search: string
    shop_id: string
    perpage: number
    page: number
    cate_id: number
  }) {
    return this.http.post<SearchKetshopProductToLineShopping>(
      '/services/v2/line-shopping/products/search_ketproduct_to_line',
      model,
    )
  }

  createProduct(shop_id: string, model: ProductInput) {
    return this.http.post<{
      message: string
      status: number
      data: any
    }>(`/services/v2/line-shopping/products/create/${shop_id}`, model)
  }

  updateProduct(shop_id: string, product_id: string, model: ProductInput) {
    return this.http.post<{
      message: string
      status: number
      data: any
    }>(`/services/v2/line-shopping/products/update/${shop_id}/${product_id}`, model)
  }

  listProduct(
    model: {
      search: string
      perpage: number
      page: number
      sort_by: 'product_id' | 'on_hand_number' | 'reserved_number' | 'ready_to_ship_number' | 'available_number'
      sort_direction: 'desc' | 'asc'
      // 0 = all, 1 = inactivate, 2 = activate
      is_display: 0 | 1 | 2
    },
    shop_id: string,
  ) {
    model['shop_id'] = shop_id
    return this.http.post<{
      message: string
      status: number
      data: {
        data: ListLineProduct[]
        count: number
      }
    }>(`/services/v2/line-shopping/products/list`, model)
  }

  get_update_model(shop_id: string, product_id: string) {
    return this.http.get<{
      message: string
      status: number
      data: ProductInput
    }>(`/services/v2/line-shopping/products/update_model/${shop_id}/${product_id}`)
  }
}
