import { Component, OnInit, Input } from '@angular/core'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzMessageService } from 'ng-zorro-antd/message'
import { EmailService } from '@cms/services/email.service'

@Component({
  selector: 'app-modal-change-email-quota',
  templateUrl: './modal-change-email-quota.component.html',
  styleUrls: ['./modal-change-email-quota.component.css'],
})
export class ModalChangeEmailQuotaComponent implements OnInit {
  @Input() data: any
  message_duration = 4000
  domain: string = '@' + location.hostname
  free = 0
  model: any = {
    email: '',
    quota: '',
  }

  constructor(
    private modal: NzModalRef,
    private message: NzMessageService,
    private Service_Email: EmailService,
  ) {}

  ngOnInit() {
    if (this.data.information.MAXQOUTA == 'unlimited') {
      this.data.information.MAXQOUTA = Infinity
    }
    if (this.data.email.QUOTA == 'unlimited') {
      this.data.email.QUOTA = Infinity
    }
    const old_quota = this.data.email.QUOTA
    this.free = Number(old_quota) + Number(this.data.information.MAXQOUTA) - Number(this.data.information.quota_use)
    this.model.email = this.data.email.MAIL
    this.model.quota = this.data.email.QUOTA
  }

  ok() {
    if (this.model.quota <= 0) {
      this.message.error('พื่นที่ต้องมากกว่า 0', { nzDuration: this.message_duration })
      return false
    } else if (Number(this.model.quota) > Number(this.free)) {
      this.message.error('พื้นที่ไม่พอใช้งาน', { nzDuration: this.message_duration })
      return false
    }

    $('.theme-loader').show()
    this.Service_Email.changeQuota(this.model)
      .then((res: any) => {
        this.modal.close(true)
      })
      .catch((data: any) => {
        this.message.error(data.error.message, { nzDuration: this.message_duration })
        return false
      })
      .finally(() => {
        $('.theme-loader').hide()
      })

    return false
  }
}
