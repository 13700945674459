import { createAction, props } from '@ngrx/store'
import { IProductsState } from './models'

export const actions_products = {
  LoadCategory: createAction<string>('[CMS][Products] Load Category'),
  SetCategory: createAction<string, { data: IProductsState.CategoryItem[] }>(
    '[CMS][Products] SetCategory',
    props<{
      data: IProductsState.CategoryItem[]
    }>(),
  ),
  SetSearchModel: createAction<string, { input: IProductsState.SearchModel; from: string }>(
    '[CMS][Products] SetSearchModel',
    props<{
      from: string
      input: IProductsState.SearchModel
    }>(),
  ),
  SearchProducts: createAction<string, { input: IProductsState.SearchModel; from: string }>(
    '[CMS][Products/Api] SearchProducts',
    props<{
      from: string
      input: IProductsState.SearchModel
    }>(),
  ),
  SetProducts: createAction<string, { data: { data: IProductsState.ProductItem[]; count: number }; from: string }>(
    '[CMS][Products] SetProducts',
    props<{
      from: string
      data: {
        data: IProductsState.ProductItem[]
        count: number
      }
    }>(),
  ),
  SetLoading: createAction<string, { val: boolean; from: string }>(
    '[CMS][Products] SetLoading',
    props<{
      from: string
      val: boolean
    }>(),
  ),
  InitSelectProducts: createAction<string, { from: string }>(
    '[CMS][Products] InitSelectProducts',
    props<{
      from: string
    }>(),
  ),
  InitFlashSale: createAction('[CMS][Products] InitFlashSale'),
  SetFlashSale: createAction(
    '[CMS][Products] SetFlashSale',
    props<{
      key: string
      data: Partial<IProductsState.ProductsState['flash_sale']['']>
    }>(),
  ),

  SelectProducts: createAction<string, { data: IProductsState.ProductItem[]; from: string; action: 'add' | 'del' }>(
    '[CMS][Products] SelectProducts',
    props<{
      action: 'add' | 'del'
      from: string
      data: IProductsState.ProductItem[]
    }>(),
  ),
  ApplySelectProducts: createAction<string, { from: string }>(
    '[CMS][Products] ApplySelectProducts',
    props<{
      from: string
    }>(),
  ),
  ClearSelectProducts: createAction<string, { from: string }>(
    '[CMS][Products] ClearSelectProducts',
    props<{
      from: string
    }>(),
  ),
}
