import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetOrderLabelComponent } from './order-label.component'
import { FormsModule } from '@angular/forms'
import { NzMessageModule } from 'ng-zorro-antd/message'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'

@NgModule({
  declarations: [KetOrderLabelComponent],
  entryComponents: [KetOrderLabelComponent],
  imports: [CommonModule, FormsModule, NzMessageModule, NzIconModule, NzToolTipModule],
  exports: [KetOrderLabelComponent],
})
export class KetOrderLabelModule {}
