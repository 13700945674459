import { HttpClient } from '@angular/common/http'
import { inject } from '@angular/core'

export class CmsKetshopwebAccountsService {
    private http = inject(HttpClient)

    GetAccount() {
      return this.http.get<{
        id: number
        web_id: number
        type: string
        account: string
        bill_prefix: string
        created_at: string
        updated_at: string
      }>(
        '/services/ketshopweb-accounts/account/get_account',
      ).toPromise()
    }

    SearchBilling(input: {
      startdate: string
      enddate: string
      bill_id: string
      status: string
      ordercode: string
      tax: string
      page: number
      perpage: number
      warehouse_id: number
      type: string
      provider_bill_ref?: string
      status_provider?: string
    }) {
      return this.http.post<{
        data: {
          id: number
          account_id: number
          web_id: number
          bill_number: string
          ordercode: string
          complete: number
          cancel: number
          contact_id: number
          vat_type: string
          subtotal: number
          totals: number
          discount: number
          payment_type: string
          created_at: string
          updated_at: string
          issued_date: string
          provider_request: {
            id: number
            web_id: number
            provider_ref_id: number
            provider_bill_ref: string
            bill_id: number
            status: string
            void: number
            provider: string
            check_hash: string
            document_type: string
            request_url: string
            document_data: string
            response_data: string
            provider_bill_link: string
            error_message: string
            created_at: string
            updated_at: string
          }
          contact: {
            id?: number
            web_id: number
            ket_id: number
            code: string
            name: string
            type: string
            juristic_no: string
            tax_no: string
            branch_no: string
            tel: string
            email: string
            address: string
            subdistrict: string
            district: string
            province: string
            zipcode: string
            location: string
            created_at: string
            updated_at: string
          }
        }[]
        count: number
      }>('/services/ketshopweb-accounts/billing/search_billing', input).toPromise()
    }
    

}