import { inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { KetshopwebServices } from 'ketshopweb-services'
import { map } from 'rxjs/operators'
import { actions_channels } from './actions'

@Injectable()
export class ChannelsEffects {
  private actions$ = inject(Actions)
  private ketshopwebOrdersService = inject(KetshopwebServices.OrdersService)
  private store = inject(Store)

  $LoadChannel = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions_channels.LoadChannel),
        map(() => {
          this.ketshopwebOrdersService
            .listChannel()
            .forEach((data) => {
              this.store.dispatch(
                actions_channels.SetChannel({
                  channels: data,
                }),
              )
            })
            .catch(() => null)
        }),
      ),
    { dispatch: false },
  )

  $LoadSellChannel = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions_channels.LoadSellChannel),
        map(() => {
          this.ketshopwebOrdersService
            .listSellChannel()
            .forEach((data) => {
              this.store.dispatch(
                actions_channels.SetSellChannel({
                  channels: data,
                }),
              )
            })
            .catch(() => null)
        }),
      ),
    { dispatch: false },
  )
}
