import { Component, OnInit, Input } from '@angular/core'
import { ProductsService } from '@cms/services/products.service'
import { NzModalRef } from 'ng-zorro-antd/modal'
declare let alasql

@Component({
  selector: 'ket-modal-addproduct-promotion',
  templateUrl: './modal-addproduct-promotion.component.html',
  styleUrls: ['./modal-addproduct-promotion.component.less'],
})
export class KetModalAddproductPromotionComponent implements OnInit {
  @Input() type: any
  @Input() selectedProducts: any

  model: any = {
    lang: 'lang1',
    status: '',
    search: '',
    cate_id: '',
    group: '',
    page: 1,
    perpage: 10,
    sort: 'desc',
    orderby: '3',
    type: '',
  }
  product: any = []
  message_duration = 2000
  total = 0

  selectedAll = false
  selectedAllPerPage = false

  allcategory: any = []

  selectedProducts_display: any = []
  selectedProducts_total = 0
  search_model_selected: any = {
    province: '',
    subdistrict: '',
    district: '',
    zipcode: '',
    page: 1,
    perpage: 10,
  }

  _orderBy: { id: string; name: string }[] = [
    { id: '3', name: 'สินค้าล่าสุด' },
    { id: '2', name: 'สินค้าเก่าสุด' },
    { id: '0', name: 'สินค้าจาก ก - ฮ' },
    { id: '1', name: 'สินค้าจาก ฮ - ก' },
    { id: '4', name: 'ราคา ต่ำ - สูง' },
    { id: '5', name: 'ราคา สูง - ต่ำ' },
    { id: '8', name: 'สินค้าขายดี' },
    { id: '7', name: 'สินค้าแนะนำ' },
  ]

  constructor(
    private Service_Product: ProductsService,
    private modal: NzModalRef,
  ) {}

  async ngOnInit() {
    if (this.type == 'free') {
      this.model.type = 'normal'
    }

    this.allcategory = await this.Service_Product.productMainCategory()
    this.allcategory.unshift({
      id: '-1',
      lang1: 'Uncategory',
      lang2: 'Uncategory',
      lang3: 'Uncategory',
      lang4: 'Uncategory',
    })
    this.allcategory.unshift({ id: '', lang1: 'All', lang2: 'All', lang3: 'All', lang4: 'All' })

    this.search()
    this.searchSelectProduct()
  }

  selectedAllPerPageChange() {
    if (this.selectedAllPerPage) {
      this.product.forEach((o) => {
        const checkSelected = this.selectedProducts.find((x) => x.id == o.id)
        if (!checkSelected) {
          this.selectedProducts = [...this.selectedProducts, o]
        }
      })
      this.checkSelected()
      this.searchSelectProduct()
    }
  }

  search(clearpage = false) {
    if (clearpage) {
      this.model.page = 1
    }
    this.Service_Product.searchProduct(this.model)
      .then((res: any) => {
        res.data.forEach((obj) => {
          obj.qty = 1
        })
        this.product = res.data
        this.total = res.count
        this.checkSelected()
      })
      .catch((data) => {
        console.log(data.error.message)
      })
  }
  pagechage(page) {
    this.model.page = page
    this.selectedAllPerPage = false
    this.search()
  }
  add(data) {
    const checkSelected = this.selectedProducts.find((x) => x.id == data.id)
    if (!checkSelected) {
      this.selectedProducts = [...this.selectedProducts, data]
      this.checkSelected()
    }
    this.searchSelectProduct()
    console.log('selectedProducts----', this.selectedProducts)
  }

  async searchSelectProduct(clearpage = false) {
    if (clearpage) {
      this.search_model_selected.page = 1
    }
    const offset_page = Number(this.search_model_selected.perpage) * (Number(this.search_model_selected.page) - 1)
    let sql = 'SELECT * FROM ? WHERE 1=1 '
    const sql_count = 'SELECT COUNT(*) as total FROM ? WHERE 1=1'
    const bindparam = [this.selectedProducts]

    sql += `LIMIT ${this.search_model_selected.perpage} OFFSET ${offset_page}`

    this.selectedProducts_display = await alasql(sql, bindparam)

    const rs = await alasql(sql_count, bindparam)
    console.log(sql)
    // console.log(sql_count);
    this.selectedProducts_total = rs[0].total
    if (this.selectedProducts_display.length == 0 && this.search_model_selected.page > 1) {
      this.search_model_selected.page = this.search_model_selected.page - 1
      this.searchSelectProduct()
      return
    }
  }

  pagechageSelected(page) {
    this.search_model_selected.page = page
    this.searchSelectProduct()
  }

  remove(data) {
    this.selectedProducts = this.selectedProducts.filter((d) => d.id !== data.id)
    this.checkSelected()
    this.searchSelectProduct()
  }

  checkSelected() {
    for (let i = 0; i < this.product.length; i++) {
      const check = this.selectedProducts.find((x) => x.id == this.product[i].id)
      check ? (this.product[i].selected = true) : (this.product[i].selected = false)
    }
  }
  qtyChange(obj) {
    const pd = this.selectedProducts.find((x) => x.id == obj.id)
    if (pd) {
      pd.qty = +obj.qty || 1
    }
  }

  ok() {
    this.modal.close(this.selectedProducts)
  }
}
