import { Component, OnInit, Input, forwardRef } from '@angular/core'
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { apiError } from '@cms/interface/interface'
import { EPaymentService } from '@cms/services/epayment.service'
import { NzModalService } from 'ng-zorro-antd/modal'

interface IModelCredit {
  name: string
  number: string
  expire: string
  securityCode: string
}

@Component({
  selector: 'ket-pay-credit',
  templateUrl: './pay-credit.component.html',
  styleUrls: ['./pay-credit.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => KetPayCreditComponent),
      multi: true,
    },
  ],
})
export class KetPayCreditComponent implements OnInit, ControlValueAccessor {
  constructor(
    private activatedRoute: ActivatedRoute,
    private ePaymentService: EPaymentService,
    private modal: NzModalService,
  ) {}

  onChange: any = () => {}

  @Input() model: IModelCredit = {
    name: '',
    number: '',
    expire: '',
    securityCode: '',
  }

  page_name = 'confirm-order'

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: { resultCode: string }) => {
      console.log('params', params)
      if (params && params.resultCode) {
        this.ePaymentService
          .creditStatusMessage(params.resultCode)
          .then((res: any) => {
            console.log('[resultCode] res', res)
            if (res && res.code !== '00') {
              this.modal.error({
                nzTitle: `ไม่สามารถชำระบัตรเครดิตได้`,
                nzContent: `<b>${res.code} : ${res.meaning}</b><p>${res.message}</p>`,
                nzOkText: 'ปิด',
                nzKeyboard: false,
                nzClosable: false,
                nzMaskClosable: false,
                nzOnOk: () => {
                  // location.replace(err.error.lasturl);
                  history.pushState({}, null, location.pathname)
                },
              })
            }
          })
          .catch((err: apiError) => {})
      }
    })
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }

  registerOnTouched() {}

  writeValue(value: any) {
    if (value) {
      this.model = value
    }
  }

  onBlur() {
    this.onChange(this.model)
  }
}
