// plugins.service.ts
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { CacheService } from './cache.service'

export interface IPlugin {
  component: string
  path: string
  plugin_type: string
  is_menu: boolean
  menu: {
    key: string
    langs: any
  }
}

export interface IMainPlugin {
  name: string
  resource: {
    js_app: string[]
  }
  plugins: IPlugin[]
}

export namespace IWtk {
  export interface Brand {
    id: number
    lang1: string
    lang2: string
    lang3: string
    lang4: string
  }
  export interface Company {
    id: number
    lang1: string
    lang2: string
    lang3: string
    lang4: string
  }
}

@Injectable({
  providedIn: 'root',
})
export class PluginsService {
  constructor(
    private _http: HttpClient,
    private cacheService: CacheService,
  ) {
    try {
      if (window['cms_plugins'] && window['cms_plugins'][location.host]) {
        const cms_plugins = window['cms_plugins'][location.host]['plugins'] || []
        for (const mp of cms_plugins) {
          this.main_plugins.push(mp)
          for (const p of mp.plugins) {
            this.plugins.push(p)
          }
        }
      }
    } catch {}
  }

  main_plugins: IMainPlugin[] = []
  plugins: IPlugin[] = []

  getMainPlugins(): IMainPlugin[] {
    if (this.main_plugins.length > 0) return this.main_plugins
    try {
      if (window['cms_plugins'] && window['cms_plugins'][location.host]) {
        const cms_plugins = window['cms_plugins'][location.host]['plugins'] || []
        for (const mp of cms_plugins) {
          this.main_plugins.push(mp)
        }
      }
    } catch {}
    return this.main_plugins
  }

  getPluginsAll(): IPlugin[] {
    if (this.plugins.length > 0) return this.plugins
    try {
      if (window['cms_plugins'] && window['cms_plugins'][location.host]) {
        const cms_plugins = window['cms_plugins'][location.host]['plugins'] || []
        for (const mp of cms_plugins) {
          for (const p of mp.plugins) {
            this.plugins.push(p)
          }
        }
      }
    } catch {}
    return this.plugins
  }

  getSharePluginData(): any {
    try {
      if (window['share_plugin_data'] && window['share_plugin_data'][location.host]) {
        const cms_plugin_data = window['share_plugin_data'][location.host] || null
        if (cms_plugin_data) {
          return cms_plugin_data
        }
      }
    } catch {}
    return null
  }

  getPluginsMenu(): IPlugin[] {
    return this.plugins.filter((p) => p.is_menu)
  }

  getPluginsByType(plugin_type: string): IPlugin[] {
    if (this.plugins.length === 0) {
      this.getPluginsAll()
    }
    return this.plugins.filter((p) => p.plugin_type === plugin_type)
  }

  getPluginByTypeAndPath(plugin_type: string, path: string): IPlugin {
    return this.plugins.find((p) => p.plugin_type === plugin_type && p.path === path)
  }

  getPluginByComponent(component_tag: string): IPlugin {
    return this.plugins.find((p) => p.component === component_tag)
  }

  getSharePluginDataByKey(key: string): any {
    const data = this.getSharePluginData()
    if (data) {
      return data[key]
    }
    return null
  }

  renderPlugin(
    component_tag: string,
    componentProps?: {
      [key: string]: any
    },
  ) {
    const component = document.createElement(component_tag)
    if (!componentProps) componentProps = {}
    Object.assign(component, componentProps)
    return component
  }

  getGenedenimProductProperty() {
    return this.cacheService.cacheCall(
      this._http.get('/services/plugins-api/genedenim/product-property/filter'),
      'filterProperties',
    )
  }

  getWtkSupermarketFrontendBrand() {
    return this._http.get('/services/plugins-api/wtksupermarket/frontend/brands').toPromise() as Promise<IWtk.Brand[]>
  }

  getWtkSupermarketFrontendCompany() {
    return this._http.get('/services/plugins-api/wtksupermarket/frontend/companies').toPromise() as Promise<
      IWtk.Company[]
    >
  }
}
