import { cloneDeep } from 'lodash'
import { Injectable, inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { KetshopwebServices } from 'ketshopweb-services'
import { map } from 'rxjs/operators'
import { actions_teams } from './actions'

@Injectable()
export class TeamsEffects {
  private actions$ = inject(Actions)
  private ReportService = inject(KetshopwebServices.ReportService)
  private store = inject(Store)

  $GetReportById = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions_teams.GetReportById),
        map((action) => {
          this.ReportService.saleReportById({
            startdate: action.model.startdate,
            enddate: action.model.enddate,
            seller_id: action.model.seller_id,
            created_by: action.model.created_by,
          }).subscribe((value: { type: string; data: any }) => {
            console.log('v', value)
            if (value.type == 'get_sales_summary') {
              this.store.dispatch(
                actions_teams.SetReport({
                  data: cloneDeep(value.data['all']),
                }),
              )
            } else if (value.type == 'saleReportProvince') {
              this.store.dispatch(
                actions_teams.SetReportProvince({
                  data: cloneDeep(value.data),
                }),
              )
            } else if (value.type == 'topBestSeller') {
              this.store.dispatch(
                actions_teams.SetReportBestSeller({
                  data: cloneDeep(value.data),
                }),
              )
            }
          })
        }),
      ),
    { dispatch: false },
  )
}
