import { Component, OnInit } from '@angular/core'
import { UtilService } from '@cms/services/util.service'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalService } from 'ng-zorro-antd/modal'
import { EmailService } from '@cms/services/email.service'
import { ModalAddEmailComponent } from '../modal/modal-add-email/modal-add-email.component'
import { ModalChangeEmailPasswordComponent } from '../modal/modal-change-email-password/modal-change-email-password.component'
import { ModalChangeEmailQuotaComponent } from '../modal/modal-change-email-quota/modal-change-email-quota.component'
import { FeatureService } from '@cms/services/feature.service'

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css'],
})
export class EmailComponent implements OnInit {
  constructor(
    private Service_Util: UtilService,
    private message: NzMessageService,
    private modal: NzModalService,
    private Service_Email: EmailService,
    private Service_Feature: FeatureService,
  ) {}
  message_duration = 4000
  information: any = {}
  mailAccount: any = []
  domain: string = location.hostname
  feature = false
  pieChartEmail: any = {
    chartType: 'PieChart',
    dataTable: [],
    options: {
      title: '',
      height: 300,
      legend: { position: 'top', alignment: 'start' },
      colors: ['#f8ca50', '#5ec84e', '#6a8cc7', '#da6272'],
      tooltip: { isHtml: true },
      chartArea: { height: '500', width: '200', bottom: 12, top: 50 },
    },
  }
  async ngOnInit() {
    this.feature = await this.Service_Feature.getFeature('MAILTAB')
    if (!this.feature) {
      return
    }
    this._init()
  }

  _init() {
    this.pieChartEmail.dataTable = [['Usage', 'Free Space']]
    this.Service_Email.listEmail()
      .then((res: any) => {
        console.log(res)
        this.information = res.data.data.information
        res.data.data.mailaccount.forEach((obj) => {
          // obj.QUOTA = +obj.QUOTA;
          if (obj.QUOTA == 'unlimited') {
            obj.QUOTA = Infinity
          } else {
            obj.QUOTA = +obj.QUOTA
          }
          obj.U_DISK = +obj.U_DISK
          obj.usagepercent = (obj.U_DISK * 100) / obj.QUOTA
          obj.usagepercent = Math.round(obj.usagepercent * 100) / 100
        })
        this.mailAccount = res.data.data.mailaccount
        try {
          this.pieChartEmail.dataTable.push(['Usage', +this.information.U_DISK])
          this.pieChartEmail.dataTable.push(['Free', +this.information.MAXQOUTA])
          this.pieChartEmail.component.draw()
        } catch (e) {}
      })
      .catch((data: any) => {
        this.message.error(data.error.message, { nzDuration: this.message_duration })
      })
  }

  addEmail() {
    const modal = this.modal
      .create({
        nzTitle: 'CREATE',
        nzContent: ModalAddEmailComponent,
        nzStyle: { top: '20px' },
        // nzWidth: "95%",
        nzKeyboard: false,
        nzMaskClosable: false,
        nzOnOk: (componentInstance) => {
          return componentInstance.ok()
        },
        nzOnCancel: (componentInstance) => {},
        nzComponentParams: {
          data: {
            information: this.information,
          },
        },
      })
      .afterClose.subscribe((result) => {
        modal.unsubscribe()
        if (result) {
          this._init()
        }
      })
  }

  changePassword(obj) {
    const modal = this.modal
      .create({
        nzTitle: 'Change Password',
        nzContent: ModalChangeEmailPasswordComponent,
        nzStyle: { top: '20px' },
        // nzWidth: "95%",
        nzKeyboard: false,
        nzMaskClosable: false,
        nzOnOk: (componentInstance) => {
          return componentInstance.ok()
        },
        nzOnCancel: (componentInstance) => {},
        nzComponentParams: {
          data: {
            information: this.information,
            email: obj,
          },
        },
      })
      .afterClose.subscribe((result) => {
        modal.unsubscribe()
        if (result) {
          this._init()
        }
      })
  }

  changequota(obj) {
    const modal = this.modal
      .create({
        nzTitle: 'Change Quota',
        nzContent: ModalChangeEmailQuotaComponent,
        nzStyle: { top: '20px' },
        // nzWidth: "95%",
        nzKeyboard: false,
        nzMaskClosable: false,
        nzOnOk: (componentInstance) => {
          return componentInstance.ok()
        },
        nzOnCancel: (componentInstance) => {},
        nzComponentParams: {
          data: {
            information: this.information,
            email: obj,
          },
        },
      })
      .afterClose.subscribe((result) => {
        modal.unsubscribe()
        if (result) {
          this._init()
        }
      })
  }

  deleteEmail(obj) {
    this.modal.confirm({
      nzTitle: `Are you sure to delete account  ${obj.MAIL} ?`,
      nzContent: '',
      nzOkText: 'Yes',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.Service_Email.deleteEmail(obj).then((res: any) => {
          this._init()
        })
      },
      nzCancelText: 'No',
      nzOnCancel: () => {},
    })
  }
}
