import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root',
})
export class EPaymentService {
  constructor(private _http: HttpClient) {}

  // search(data: any) {
  //   return this._http.post('/payment', data).toPromise()
  // }

  search(data: any) {
    return this._http.post('/services/v2/payment-gateway/transaction/ebalance', data).toPromise()
  }

  allStatus() {
    return this._http.get('/payment/auto-status').toPromise()
  }

  checkTxn(transaction: any, action: string) {
    return this._http.get(`/order/checkPayment/${transaction}/${action}`).toPromise()
  }

  creditStatusMessage(code: string) {
    return this._http.get(`/payment/credit-status-message?resultCode=${code}`).toPromise()
  }

  // /services/v2/payment-gateway/inquiry
  inquiry(data: { is_postback: boolean; ordercode: string; reference_no: string,  force?: boolean }) {
    return this._http.post('/services/v2/payment-gateway/inquiry', data).toPromise()
  }
}
