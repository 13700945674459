import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { PagesEffects } from './effects'
import { PagesFEATURE_KEY, PagesReducer } from './reducer'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(PagesFEATURE_KEY, PagesReducer),
    EffectsModule.forFeature([PagesEffects]),
  ],
})
export class PagesSateModule {}
