import { Action } from '@ngrx/store'
import { ITrigger } from '@cms/types'

export enum ActionTypes {
  SET_TRIGGER = 'SET_TRIGGER',
  SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
  SET_CURRENT_USER = 'SET_CURRENT_USER',
  SET_LANGS = 'SET_LANGS',
  SET_PROVINCE = 'SET_PROVINCE',
  SET_PAGE_LINK = 'SET_PAGE_LINK',
  SET_CURRENT_USER_CLIENT = 'SET_CURRENT_USER_CLIENT',
  SET_LANG_FORMAT = 'SET_LANG_FORMAT',
  SALE_PAGE_SELECT_PRODUCT = 'SALE_PAGE_SELECT_PRODUCT',
  SET_COUNT_MESSENGER = 'SET_COUNT_MESSENGER',
  UPDATE_PRODUCT_STORE = 'UPDATE_PRODUCT_STORE',
}

export class SET_LANG_FORMAT implements Action {
  readonly type = ActionTypes.SET_LANG_FORMAT
  constructor(public playload: any) {}
}

export class SET_CURRENT_PAGE implements Action {
  readonly type = ActionTypes.SET_CURRENT_PAGE
  constructor(public playload: any) {}
}

export class SET_CURRENT_USER implements Action {
  readonly type = ActionTypes.SET_CURRENT_USER
  constructor(public playload: any) {}
}

export class SET_LANGS implements Action {
  readonly type = ActionTypes.SET_LANGS
  constructor(public playload: any) {}
}

export class SET_PROVINCE implements Action {
  readonly type = ActionTypes.SET_PROVINCE
  constructor(public playload: any) {}
}

export class SET_PAGE_LINK implements Action {
  readonly type = ActionTypes.SET_PAGE_LINK
  constructor(public playload: any) {}
}

export class SET_CURRENT_USER_CLIENT implements Action {
  readonly type = ActionTypes.SET_CURRENT_USER_CLIENT
  constructor(public playload: any) {}
}

export class SALE_PAGE_SELECT_PRODUCT implements Action {
  readonly type = ActionTypes.SALE_PAGE_SELECT_PRODUCT
  constructor(public playload: any) {}
}

export class SET_COUNT_MESSENGER implements Action {
  readonly type = ActionTypes.SET_COUNT_MESSENGER
  constructor(public playload: number) {}
}

export class UPDATE_PRODUCT_STORE implements Action {
  readonly type = ActionTypes.UPDATE_PRODUCT_STORE
  constructor(public playload: any) {}
}

export class SET_TRIGGER implements Action {
  readonly type = ActionTypes.SET_TRIGGER
  constructor(public playload: ITrigger) {}
}

export type All =
  | SET_CURRENT_PAGE
  | SET_CURRENT_USER
  | SET_LANGS
  | SET_PROVINCE
  | SET_PAGE_LINK
  | SET_CURRENT_USER_CLIENT
  | SET_LANG_FORMAT
  | SALE_PAGE_SELECT_PRODUCT
  | SET_COUNT_MESSENGER
  | UPDATE_PRODUCT_STORE
  | SET_TRIGGER
