import { Component, OnInit, ViewEncapsulation } from '@angular/core'

@Component({
  selector: 'font-face',
  template: ` <div></div> `,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./font-face.css'],
})
export class FontFaceComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
