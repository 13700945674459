import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ICmsOrder } from '@cms/types/ICmsOrder'

// interface ILineShop

export namespace ILineShopping {
  export interface ShopsEntity {
    api_key?: string | null
    created_at?: string | null
    created_by?: string | null
    cus_id?: number | null
    id?: number
    shop_id?: any
    name?: string | null
    status?: number | null
    updated_at?: string | null
    updated_by?: string | null
  }
  export interface ShopInfo {
    shop: ShopsEntity
    verify: string
    cus_code: string
    url: string
  }

  export interface VariantsEntity {
    options?: any | null
    shop_id?: number | null
    variant_id: number
  }

  export interface InventoryEntity {
    available_number?: number | null
    barcode?: string | null
    discounted_price?: number | null
    inventory_id: number
    ketshopweb_product_id?: number | null
    on_hand_number?: number | null
    price?: number | null
    product_id?: number | null
    ready_to_ship_number?: number | null
    reserved_number?: number | null
    shop_id?: number | null
    sku?: string | null
    variant_id?: number | null
    weight?: number | null
  }

  export interface ProductsEntity {
    brand?: string | null
    category_id?: number | null
    category_name?: string | null
    code?: string | null
    description?: any | null
    has_only_default_variant?: number | null
    id: number
    image_url?: string | null
    is_display?: number | null
    name?: string | null
    shop_id?: number | null
  }

  export interface Variant {
    name: string
    value: string
  }

  export interface LineShoppingEntitiesVariantsEntity extends VariantsEntity {
    options: Variant[]
  }

  export interface LineShoppingEntitiesInventoryEntity extends InventoryEntity {
    variant: LineShoppingEntitiesVariantsEntity
    product: ProductsEntity
  }

  export interface LineShoppingEntitiesProductsEntity extends ProductsEntity {
    inventory: LineShoppingEntitiesInventoryEntity[]
  }

  export interface SyncProductState {
    in_progress: boolean
    total_new_product: number
    total_update_product: number
    total_no_sku: number
    total_inprogress: number
    sync_percent: number
    total_sync: number
    last_update?: string
    no_sku_inventory_ids: any[]
    sku_inventory_ids: any
  }
  export interface InventoryUpdateModel {
    inventory_info: {
      discounted_price: number
      available_number: number
      ready_to_ship_number: number
      reserved_number: number
      price: number
      on_hand_number: number
      is_display: number
    }
    ketshopweb: {
      size_height: number
      size_length: number
      size_width: number
      weight: number
      price: number
      instock: number
    }
  }

  export interface ShipmentDetail {
    name: string
    description: string
    type: string
    trackingNumber: string
    isCod: boolean
  }

  export interface ShippingAddress {
    recipientName: string
    address: string
    province: string
    postalCode: string
    country: string
    phoneNumber: string
    email: string
  }

  export interface Order {
    id: number
    shop_id: number
    x_request_id: string
    order_number: string
    ket_ordercode?: any
    order_create_status: number
    order_status: string
    payment_method: string
    discount_amount: number
    payment_status: string
    remark_buyer: string
    shipment_detail: ShipmentDetail
    shipment_price: number
    shipment_status: string
    shipping_address: ShippingAddress
    subtotal_price: number
    total_price: number
    weight: number
    checkout_at: string
    last_updated_at: string
    ket_order_date: string
    paid_at: string
    details: OrderDetails[]
    shop?: {
      name: string
      id: number
      status: number
    }
    created_at?: string
    updated_at?: string
  }

  export interface OrderDetails {
    id: number
    shop_id: number
    order_id: number
    barcode: string
    image_url: string
    price: number
    discounted_price: number
    name: string
    quantity: number
    sku: string
    weight: number
    product_id: number
    variant_id: number
    variants: Variant[]
  }

  export interface SearchOrderResponse {
    count: number
    data: Order[]
  }

  export interface IModelSearchOrder {
    search: string
    order_number: string
    ket_ordercode: string
    page: number
    per_page: number
    shop_ids: any[]
    order_create_status: number | string
    start_date: string | null
    end_date: string | null
  }

  export interface OrdersSyncState {
    in_progress: boolean
    total_new: number
    total_update: number
    total_pending: number
    total_inprogress: number
    sync_percent: number
    total_sync: number
    last_update: string
    total_pending_order_numbers: any[]
  }

  export interface IModelSearchDeliveryOrder {
    search: string
    ordercode: string
    page: number
    per_page: number
    status: number[]
    delivery: 'all' | 'delivered' | 'not_delivered'
    delivery_type: 'all' | 'cod' | 'normal'
    shop_ids: any[]
  }
}

@Injectable({
  providedIn: 'root',
})
export class LineShoppingService {
  constructor(private _http: HttpClient) {}

  listShop() {
    return this._http.get('/services/line-shopping/setting/list_shop').toPromise() as Promise<ILineShopping.ShopInfo[]>
  }

  createShop(model: { name: string; api_key: string }) {
    return this._http
      .post('/services/line-shopping/setting/create_shop', model)
      .toPromise() as Promise<ILineShopping.ShopInfo>
  }

  // update_shop
  updateShop(
    model: {
      name: string
      api_key: string
    },
    id: any,
  ) {
    return this._http
      .post('/services/line-shopping/setting/update_shop/' + id, model)
      .toPromise() as Promise<ILineShopping.ShopInfo>
  }

  // delete_shop
  deleteShop(id: any) {
    return this._http.delete('/services/line-shopping/setting/delete_shop/' + id).toPromise() as Promise<any>
  }


  unsyncProductByID(data,shop_id){
    return this._http.post('/services/line-shopping/products/unsyncProductByID/' + shop_id,data).toPromise() as Promise<any>
  }
  unsyncProducts(shop_id: number): Promise<any> {
    return this._http.get('/services/line-shopping/products/unsyncProducts/' + shop_id).toPromise() as Promise<any>
  }
  searchProduct(
    model: {
      page?: number
      per_page?: number
      search?: string
      sort?: 'asc' | 'desc'
      sort_by?: string
      is_display?: number | string
      olny_connected?: boolean
      ids?: any[]
    },
    shop_id: any,
  ) {
    return this._http.post('/services/line-shopping/products/search/' + shop_id, model).toPromise() as Promise<{
      count: number
      data: ILineShopping.LineShoppingEntitiesInventoryEntity[]
    }>
  }

  // get inventory_info
  getInventoryInfo(shop_id: any) {
    return this._http.get('/services/line-shopping/products/inventory_info/' + shop_id).toPromise() as Promise<{
      shop_info: ILineShopping.ShopsEntity
      product_total: number
      product_connected: number
    }>
  }

  // syncProductsState
  // sync_products_state/:shop_id
  syncProductsState(shop_id: any) {
    return this._http
      .get('/services/line-shopping/products/sync_products_state/' + shop_id)
      .toPromise() as Promise<ILineShopping.SyncProductState>
  }

  syncProductsByID(shop_id:string,product_id: string): Promise<any> {
    return this._http.get('/services/line-shopping/products/syncProductsByID/' + shop_id + '/' + product_id).toPromise() as Promise<any>

  }
  // sync_products/:shop_id
  syncProducts(shop_id: any) {
    return this._http.get('/services/line-shopping/products/sync_products/' + shop_id).toPromise() as Promise<any>
  }

  // get update_model/:shop_id/:inventory_id
  getUpdateModel(shop_id: any, inventory_id: any) {
    return this._http
      .get('/services/line-shopping/products/update_model/' + shop_id + '/' + inventory_id)
      .toPromise() as Promise<ILineShopping.InventoryUpdateModel>
  }

  // inventory_update/:shop_id/:inventory_id
  inventoryUpdate(shop_id: any, inventory_id: any, model: ILineShopping.InventoryUpdateModel) {
    return this._http
      .post('/services/line-shopping/products/inventory_update/' + shop_id + '/' + inventory_id, model)
      .toPromise() as Promise<any>
  }

  //search order
  searchOrder(model: ILineShopping.IModelSearchOrder) {
    return this._http
      .post('/services/line-shopping/orders/search', model)
      .toPromise() as Promise<ILineShopping.SearchOrderResponse>
  }

  syncOrdersState() {
    return this._http
      .get('/services/line-shopping/orders/sync_order_state')
      .toPromise() as Promise<ILineShopping.OrdersSyncState>
  }

  syncOrders() {
    return this._http.get('/services/line-shopping/orders/sync_orders').toPromise() as Promise<any>
  }

  syncOrder(shop_id: any, order_number: any) {
    return this._http
      .get(`/services/line-shopping/orders/sync_order/${shop_id}/${order_number}`)
      .toPromise() as Promise<any>
  }

  changeOrderCreateStatus(model: {
    status: number
    data: {
      marketplace_order_id: string
      shop_id: string
    }[]
  }) {
    return this._http
      .put(`/services/line-shopping/orders/change_order_create_status`, model)
      .toPromise() as Promise<any>
  }

  // post sync_order_by_order_number
  syncOrderByOrderNumber(model: {
    data: {
      marketplace_order_id: string
      shop_id: string
    }[]
  }) {
    return this._http
      .post(`/services/line-shopping/orders/sync_order_by_order_number`, model)
      .toPromise() as Promise<any>
  }

  // post delivery search_orders
  searchDeliveryOrder(model: ILineShopping.IModelSearchDeliveryOrder) {
    return this._http
      .post(`/services/line-shopping/delivery/search_orders`, model)
      .toPromise() as Promise<ICmsOrder.OrderResponse>
  }
}
