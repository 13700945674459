import { SocketIOStore } from '@cms/store/SocketIOStore'
import { uuIdV4 } from 'ketshopweb-sdk'

export function SyncProducts(input: {
  rebaseSkus: boolean
} = {
  rebaseSkus: false
}, callback?: (data: {
  request_id: string
  data: {
    status: 'progress' | 'success' | 'error' | 'started'
    data: null | {
      total: number;
      current: number;
      sys_key: string;
    }
    message: string | null
  }
}) => void) {
  const socket = SocketIOStore.get().socket
  if (socket) {
    const model = {
      request_id: uuIdV4(),
      app: 'trcloud',
      action: 'syncProducts',
      data: input
    }

    if (callback) {
      const listen_key = `TR_CLOUD.services.syncProducts[${model.request_id}]`
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      socket.on(listen_key, (data: any) => {
        callback(data)

        if (data.data.status !== 'progress' && data.data.status !== 'started') {
          console.info('Unsubscribing from socket event:', listen_key)
          socket.off(listen_key)
        }
      })
    }

    socket.emit('services', model)
    return {
      request_id: model.request_id
    }
  }
}
