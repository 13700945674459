import { NzModalService } from 'ng-zorro-antd/modal'
import { cloneDeep } from 'lodash'
import { Component, Input, OnInit } from '@angular/core'
import * as moment from 'moment'
import { Router } from '@angular/router'
import { TeamService } from '@cms/services/team.service'
import { actions_teams, ITeamsState, NgStore, selectors_teams, initialTeamsState } from '@store/ketshopweb-store'
import { Store } from '@ngrx/store'
import { from, Observable } from 'rxjs'
import { delay, switchMap } from 'rxjs/operators'
declare let Chart: any

@Component({
  selector: 'app-report-team-sale',
  templateUrl: './report-team-sale.component.html',
  styleUrls: ['./report-team-sale.component.css'],
})
export class ReportTeamSaleComponent extends NgStore implements OnInit {
  // Input
  @Input() id: number

  team_data: any = {}
  data_chart: 'total_amount' | 'total_order' = 'total_amount'
  totalOrderChart: any = null // chart data
  totalAmountChart: any = null // chart data
  saleProvince_sort = 'value'
  best_seller_item_sort = 'value'
  rangePicker = [] as any[]
  model_order = {
    created_by: '',
    assignee_ids: []
  }
  model: {
    startdate: string
    enddate: string
    seller_id: number
    created_by: string
  } = {
    startdate: '',
    enddate: '',
    seller_id: -1,
    created_by: '',
  }
  report: ITeamsState.Report = cloneDeep(initialTeamsState.report)
  province: ITeamsState.Province[] = []
  best_seller: ITeamsState.BestSeller[] = []

  constructor(
    private router: Router,
    private nzModalService: NzModalService,
    private ServiceTeam: TeamService,
    public store: Store,
  ) {
    super(store)
  }

  onDestroy() {}

  async ngOnInit() {
    this.report = cloneDeep(initialTeamsState.report)
    this.store.dispatch(
      actions_teams.SetReport({
        data: cloneDeep(initialTeamsState.report),
      }),
    )
    this.store.dispatch(
      actions_teams.SetReportProvince({
        data: [],
      }),
    )
    this.model.seller_id = this.id
    this.team_data = await this.ServiceTeam.getTeamById(this.id)
    // this.model_order.created_by = this.team_data.usr_name
    this.model_order.assignee_ids = [this.id];
    console.log('this.model_order.assignee_idsthis.model_order.assignee_ids',this.model_order.assignee_ids)
    this.model.created_by = this.team_data.usr_name
    // sub data
    this.subscribe('[ReportTeamSaleComponent] report', selectors_teams.report, (report) => {
      console.log('[ReportTeamSaleComponent] r[ReportTeamSaleComponent] r[ReportTeamSaleComponent] r',report)
      this.report = cloneDeep(report)
      this.chartChange()
    })
    this.subscribe(
      '[ReportTeamSaleComponent] report_item_province',
      selectors_teams.report_item_province,
      (province) => {
        this.province = cloneDeep(province)
        this.provice_sort()
      },
    )
    this.subscribe(
      '[ReportTeamSaleComponent] report_item_best_seller',
      selectors_teams.report_item_best_seller,
      (best_seller) => {
        this.best_seller = cloneDeep(best_seller)
        this.item_sort()
      },
    )
    this.filterRangeChange()
  }

  dateRangeChange() {
    this.model.startdate = moment(this.rangePicker[0]).format('YYYY-MM-DD')
    this.model.enddate = moment(this.rangePicker[1]).format('YYYY-MM-DD')
    const maxrange = moment(this.model.startdate).add(2, 'month').format('x')
    const enddate = moment(this.model.enddate).format('x')
    if (enddate > maxrange && this.filterRange == 0) {
      this.model.enddate = moment(this.model.startdate).add(2, 'month').format('YYYY-MM-DD')
      this.rangePicker = [this.model.startdate, this.model.enddate]
      this.nzModalService.warning({
        nzTitle: '',
        nzContent: `ขออภัย เนื่องจากท่านได้ทำการเลือกช่วงเวลาเกิน 60 วัน\nดังนั้นข้อมูลที่แสดงจะเป็นข้อมูลนับจาก วันเริ่มต้นไป 60 วันเท่านั้น`,
      })
    }
    this.store.dispatch(
      actions_teams.GetReportById({
        model: cloneDeep(this.model),
      }),
    )
  }

  backto_teams() {
    this.router.navigate(['/system/users/teams'])
  }

  // ◼︎◼︎◼︎◼︎◼◼︎◼︎◼︎◼︎◼◼︎◼︎◼︎◼︎◼◼︎◼︎◼︎◼︎◼◼︎◼︎◼︎◼︎◼◼︎◼︎◼︎◼︎◼◼︎◼︎◼︎◼︎◼◼︎◼︎◼︎◼︎◼
  // ◼︎◼︎◼︎◼︎◼◼︎◼︎◼︎◼︎◼ Version 8.6 (2023) ◼︎◼︎◼︎◼︎◼◼︎◼︎◼︎◼︎◼
  // ◼︎◼︎◼︎◼︎◼◼︎◼︎◼︎◼︎◼◼︎◼︎◼︎◼︎◼◼︎◼︎◼︎◼︎◼◼︎◼︎◼︎◼︎◼◼︎◼︎◼︎◼︎◼◼︎◼︎◼︎◼︎◼◼︎◼︎◼︎◼︎◼
  filterRange = 2
  filterRangeChange() {
    switch (this.filterRange) {
      case 1: // 7 วันล่าสุด
        this.rangePicker = [moment().add(-6, 'day').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
        break
      case 2: // 30 วันล่าสุด ** Default **
        this.rangePicker = [moment().add(-1, 'month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
        break
      case 3: // 2 เดือนล่าสุด
        this.rangePicker = [moment().add(-2, 'month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
        break
      case 0: // กำหนดเอง
        break
      default:
        break
    }
    this.dateRangeChange()
  }

  provice_sort() {
    switch (this.saleProvince_sort) {
      case 'value':
        this.province.sort((a, b) => b.total - a.total)
        break
      case 'qty':
        this.province.sort((a, b) => b.qty - a.qty)
        break
      default:
        break
    }
  }

  item_sort() {
    switch (this.best_seller_item_sort) {
      case 'value':
        this.best_seller.sort((a, b) => b.total - a.total)
        break
      case 'qty':
        this.best_seller.sort((a, b) => b.qty - a.qty)
        break
      default:
        break
    }
  }
  formatNumber(value, n = 2) {
    const re = '\\d(?=(\\d{' + 3 + '})+' + (n > 0 ? '\\.' : '$') + ')'
    return Number(value).toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,')
  }


  async chartChange() {
    from([
      new Observable((ob) => {
        let chart_data = null as any
        let elementById = ''
        if (this.data_chart == 'total_amount') {
          elementById = 'totalAmountChart'
          const labels = []
          const dataset = {
            backgroundColor: '#1890FF',
            borderColor: '#1890FF',
            label: 'มูลค่า',
            fill: false,
            data: [],
          }
          for (const data of this.report.graph_data) {
            const set_date = moment(data.date).format('DD MMM')
            labels.push(set_date)
            dataset.data.push(data.total)
          }
          chart_data = {
            type: 'line',
            data: {
              labels: labels,
              datasets: [dataset],
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              hoverMode: 'index',
              tooltips: {
                mode: 'index',
                intersect: false,
                titleFontFamily: '\'Poppins\',\'Prompt\'',
                bodyFontFamily: '\'Poppins\',\'Prompt\'',
                callbacks: {
                  label: (tooltipItem, data) => {
                    let label = ' '
                    if (label) {
                      label +=
                        data.datasets[tooltipItem.datasetIndex].label +
                        ' | ' +
                        this.formatNumber(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])
                    }
                    return label
                  },
                },
              },
              scales: {
                xAxes: [
                  {
                    display: true,
                    ticks: {
                      fontFamily: '\'Poppins\',\'Prompt\'',
                      fontSize: '10',
                      fontColor: '#888888',
                    },
                    scaleLabel: {
                      display: false,
                    },
                  },
                ],
                yAxes: [
                  {
                    display: true,
                    min: 0,
                    ticks: {
                      suggestedMax: 200,
                      fontFamily: '\'Poppins\',\'Prompt\'',
                      fontSize: '10',
                    },
                  },
                ],
              },
              legend: {
                display: false,
                align: 'end',
                labels: {
                  fontFamily: '\'Poppins\',\'Prompt\'',
                },
              },
            },
          }
        }
        if (this.data_chart == 'total_order') {
          elementById = 'totalOrderChart'
          const labels = []
          const dataset = {
            backgroundColor: '#1890FF',
            borderColor: '#1890FF',
            label: 'เปิดบิล',
            fill: false,
            data: [],
          }
          for (const data of this.report.graph_data) {
            const set_date = moment(data.date).format('DD MMM')
            labels.push(set_date)
            dataset.data.push(data.count)
          }
          chart_data = {
            type: 'line',
            data: {
              labels: labels,
              datasets: [dataset],
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              hoverMode: 'index',
              tooltips: {
                mode: 'index',
                intersect: false,
                titleFontFamily: '\'Poppins\',\'Prompt\'',
                bodyFontFamily: '\'Poppins\',\'Prompt\'',
                callbacks: {
                  label: (tooltipItem, data) => {
                    let label = ' '
                    if (label) {
                      label +=
                        data.datasets[tooltipItem.datasetIndex].label +
                        ' | ' +
                        this.formatNumber(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index],0)
                    }
                    return label
                  },
                },
              },
              scales: {
                xAxes: [
                  {
                    display: true,
                    ticks: {
                      fontFamily: '\'Poppins\',\'Prompt\'',
                      fontSize: '10',
                      fontColor: '#888888',
                    },
                    scaleLabel: {
                      display: false,
                    },
                  },
                ],
                yAxes: [
                  {
                    display: true,
                    min: 0,
                    ticks: {
                      suggestedMax: 6,
                      fontFamily: '\'Poppins\',\'Prompt\'',
                      fontSize: '10',
                    },
                  },
                ],
              },
              legend: {
                display: false,
                align: 'end',
                labels: {
                  fontFamily: '\'Poppins\',\'Prompt\'',
                },
              },
            },
          }
        }
        ob.next({
          elementById: elementById,
          chart_data: chart_data,
        })
      }),
    ])
      .pipe(
        switchMap((data) => {
          try {
            this.totalAmountChart.destroy()
          } catch {}
          try {
            this.totalOrderChart.destroy()
          } catch {}
          return data
        }),
        delay(100),
      )
      .subscribe({
        next: (data: any) => {
          console.log('complete', data)
          this.totalAmountChart = new Chart(document.getElementById(data.elementById), data.chart_data)
        },
      })
  }
}
