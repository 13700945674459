import { HttpClient } from '@angular/common/http'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { forkJoin, Observable } from 'rxjs'
import { environment } from '@cms/../environments/environment'
import { AuthenService } from '@cms/services/authen.service'
import { retry } from 'rxjs/operators'

export function event_source(http: HttpClient, auth: AuthenService, path: string, query: any = {}) {
  let query_str = ''
  for (const key in query) {
    query_str += `${key}=${query[key]}&`
  }
  if (query_str) {
    const index = query_str.lastIndexOf('&')
    query_str = query_str.substring(0, index)
  }
  return new Observable<{
    type: string
    data: any
  }>((res_observ) => {
    forkJoin([
      http.get('/services/ketshopweb-reports/group-report/token-check').pipe(retry(2)),
      new Observable<{
        type: string
        data: any
      }>((observ) => {
        const url = `${environment.api_path.replace('/api/v1', '')}${path}${query_str ? `?${query_str}` : ''}`
        const eventSource = new EventSourcePolyfill(url, {
          headers: {
            Authorization: `Bearer ${auth.getAuthenToken()}`,
          },
        })
        eventSource.onmessage = (ev: any) => {
          const data = JSON.parse(ev.data) as {
            type: string
            data: any
          }
          res_observ.next(data)
          if (data.type == 'complete') {
            eventSource.close()
            observ.complete()
          }
        }
      }),
    ]).subscribe({
      next(v) {
        // console.log(v)
        res_observ.next(v[1])
      },
      complete() {
        console.log('complete')
        setTimeout(() => res_observ.complete(), 10)
      },
    })
  })
}
