import { NgModule } from '@angular/core'

import { FroalaEditorModule } from './ng-editor'
import { FroalaViewModule } from './ng-view'

export { FroalaEditorDirective, FroalaEditorModule } from './ng-editor'

export { FroalaViewDirective, FroalaViewModule } from './ng-view'

const MODULES = [FroalaEditorModule, FroalaViewModule]

@NgModule({
  imports: [FroalaEditorModule.forRoot(), FroalaViewModule.forRoot()],
  exports: MODULES,
})
export class FERootModule {}
