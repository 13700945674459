import { Injectable } from '@angular/core'
import { Etc } from '../util/etc'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(
    private _etc: Etc,
    private _http: HttpClient,
  ) {}

  rx_search(data: any) {
    return this._http.post('/customer/search', data)
  }

  search(data: any) {
    // return this._http.post('/customer/search', data).toPromise()
    // /services/ketshopweb-customer/search/search
    return this._http.post('/services/ketshopweb-customer/search/search', data).toPromise()
  }

  create(data: any) {
    return this._http.post('/customer', data).toPromise()
  }

  getPointToUse(data: any = null) {
    return this._http.post('/customer/getPointToUse', data).toPromise()
  }

  searchLogPoint(data) {
    return this._http.post('/customer/searchLogPoint', data).toPromise()
  }
  getCustomerPoint(data) {
    return this._http.post('/customer/getCustomerPoint', data).toPromise()
  }
  adjustPoint(data: { desc: string; point: number; type: 'increase' | 'decrease' | 'equal'; user_id: number }) {
    return this._http
      .put('/services/ketshopweb-customer/memberpoint/adjust', {
        type: data.type,
        point: data.point,
        note: data.desc,
        target: {
          type: 'id',
          value: data.user_id,
        },
      })
      .toPromise()
  }

  update(data: any) {
    return this._http.put('/customer/' + data.id, data).toPromise()
  }

  delete(data: any) {
    return this._http.delete('/customer/' + data.is_deletion + '/' + data.id).toPromise()
  }

  confirmRegister(data: any) {
    return this._http.post('/customer/confirmregister', data).toPromise()
  }

  forgotPassword(data: any) {
    return this._http.post('/customer/forgotpassword', data).toPromise()
  }

  checkForgotPassword(data: any) {
    return this._http.post('/customer/checkForgotPasswordToken', data).toPromise()
  }

  register(data: any) {
    return this._http.post('/customer/register', data).toPromise()
  }

  changePasswordForgot(data: any) {
    return this._http.post('/customer/changePasswordForgot', data).toPromise()
  }

  me(header: any = null) {
    return header ? this._http.get('/services/ketshopweb-customer/customers/me', header).toPromise() : this._http.get('/services/ketshopweb-customer/customers/me').toPromise()
  }

  byId(id: number | string) {
    // return this._http.get(`/customer/${id}`).toPromise()
    return this._http.get(`/services/ketshopweb-customer/customers/by-id/${id}`).toPromise()
  }
  logView(user_id: number) {
    return this._http.post(`/services/ketshopweb-logs/customer/log_view/${user_id}`, null).toPromise()
  }

  byFacebookId(id: any) {
    if (!id) {
      id = -1
    }
    return this._http.get(`/customer/facebook/${id}`).toPromise() as any
  }

  exportCustomer(data = null) {
    return this._http.post(`/customer/export`, data).toPromise()
  }

  summary(input: {
    channels: string[]
    consent_conditions_news: 'consent' | 'unconsent' | ''
    consent_terms_privacy_policy: 'consent' | 'unconsent' | ''
    start_date?:string 
    end_date?:string
  }) {
    return this._http.post(`/services/ketshopweb-reports/customer/summary`, input).toPromise()
  }

  customerTopSpenderValue(input:{
    start_date?:string 
    end_date?:string
    all?:boolean
  }){
    return this._http.post(`/services/ketshopweb-reports/customer/top-spender-by-value`, input).toPromise()
  }
  customerTopSpenderQty(input:{
    start_date?:string 
    end_date?:string
    all?:boolean
  }){
    return this._http.post(`/services/ketshopweb-reports/customer/top-spender-by-qty`, input).toPromise()
  }

  customerProvince(input:{
    start_date?:string 
    end_date?:string
  }){
    return this._http.post(`/services/ketshopweb-reports/customer/by-province`, input).toPromise()
  }

  repeatCustomer(input:{
    start_date?:string 
    end_date?:string
    all?:boolean
  }){
    return this._http.post(`/services/ketshopweb-reports/customer/repeat-customer`, input).toPromise()
  }

  repeatCustomerByChannel(input:{
    start_date?:string 
    end_date?:string
  }){
    return this._http.post(`/services/ketshopweb-reports/customer/repeat-customer-by-channel`, input).toPromise()
  }

  newCustomerReport(input:{
    start_date?:string 
    end_date?:string
    all?:boolean
  }){
    return this._http.post(`/services/ketshopweb-reports/customer/new-customer`, input).toPromise()
  }

  newCustomerChannel(input:{
    start_date?:string 
    end_date?:string
  }){
    return this._http.post(`/services/ketshopweb-reports/customer/new-customer-channel`, input).toPromise()
  }

  updateProfile(data: any) {
    return this._http.post('/customer/updateProfile', data).toPromise()
  }

  createSendingAddress(data: any) {
    return this._http.post('/customer/address', data).toPromise()
  }

  updateSendingAddress(data: any) {
    return this._http.put('/customer/address/' + data.id, data).toPromise()
  }

  deleteSendingAddress(id) {
    return this._http.delete('/customer/address/' + id).toPromise()
  }

  orderHistory(data: any, from = '') {
    return this._http.post(`/customer/orderHistory?from=${from}`, data).toPromise()
  }

  registerGuest(data: any) {
    return this._http.post('/customer/registerGuest', data).toPromise()
  }
}
