import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetThailandAddressComponent } from './thailand-address.component'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { hilightPipe } from './hilight.pipe'
import { FormsModule } from '@angular/forms'
import { NzSpinModule } from 'ng-zorro-antd/spin'
import { NzDropDownModule } from 'ng-zorro-antd/dropdown'

@NgModule({
  declarations: [KetThailandAddressComponent, hilightPipe],
  imports: [CommonModule, NzSelectModule, FormsModule, NzSpinModule, NzDropDownModule],
  exports: [KetThailandAddressComponent],
})
export class KetThailandAddressModule {}
