import { Component, OnInit } from '@angular/core'
import { BlogsService } from '@cms/services/blogs.service'
import { Etc } from '@cms/util/etc'
import { NzMessageService, NzModalRef, NzModalService } from 'ng-zorro-antd'

interface Blog {
  post_id: number
  name: string
  category_name?: string
  create_date: string
  sort?: number
  status: number
}
declare let alasql
@Component({
  selector: 'ket-blog-display-sort',
  templateUrl: './blog-display-sort.component.html',
  styleUrls: ['./blog-display-sort.component.scss'],
})
export class KetBlogDisplaySortComponent implements OnInit {
  constructor(
    protected Service_Blog: BlogsService,
    private modalService: NzModalService,
    private modalRef: NzModalRef,
    private message: NzMessageService,
    protected _etc: Etc,
  ) {}

  mmm: any = []
  product_sort: any = []
  blog_data: Blog[]
  blog_data_display: Blog[] = []
  count = 0
  categoryList: any[] = []
  search_model_sort: { search: string; cate_id: string; page: number; perpage: number } = {
    search: '',
    cate_id: '',
    page: 1,
    perpage: 10,
  }
  current_lang = 'lang1'
  ngOnInit() {
    this.getBlogFront()
    this.Service_Blog.BlogCategory()
      .then((res: any) => {
        this.current_lang = this._etc.getCurrentLang()
        this.current_lang = this.current_lang || 'lang1'
        res.forEach((obj: any) => {
          obj['title'] = obj[this.current_lang]
          obj['key'] = obj.id
          obj['children'] = obj['childrens']
          if (obj.children.length > 0) {
            for (const obj2 of obj.children) {
              obj2['title'] = obj2[this.current_lang]
              obj2['key'] = obj2.id
            }
          } else {
            obj['isLeaf'] = true
          }

          this.categoryList.push(obj)
        })
      })
      .catch((err) => {})
  }

  async getBlogFront() {
    await this.Service_Blog.getBlogFront()
      .then((res: any) => {
        this.product_sort = res.data
        this.searchProductSort(true)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  sortKeyup(data) {
    data.sort = `${data.sort}`.replace(/^([^0-9]*)$/g, '')
  }
  async searchBlogSort(clearpage = false) {
    await this.Service_Blog.searchBlogststem(this.search_model_sort)
      .then((res: any) => {
        this.blog_data_display = res.data
        this.count = res.count
        this.clonetoblog_data()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  sort() {
    this.blog_data.sort((a, b) => {
      if (a.sort === null && b.sort !== null) {
        return 1 // Place null values at the end
      } else if (a.sort !== null && b.sort === null) {
        return -1 // Place null values at the beginning
      } else if (a.sort === null && b.sort === null) {
        return 0 // Keep null values together
      } else {
        return a.sort - b.sort // Sort by age for non-null values
      }
    })
  }

  async sortBlur(data) {
    console.log(data)
    console.log(this.product_sort)
    const duplicate_sort = this.product_sort.find(
      (x) => x.sort !== null && x.sort !== '' && x.sort == data.sort && x.post_id != data.post_id,
    )
    if (duplicate_sort) {
      const chk: any = await this.confirmReplaceDuplicateSort(duplicate_sort)
      if (!chk) {
        data.sort = null
        return
      }
    }

    this.product_sort
      .filter((x) => x.sort == data.sort)
      .forEach((obj) => {
        obj.sort = null
      })

    const original_data = this.product_sort.find((x) => x.post_id == data.post_id)
    if (original_data) {
      if (data.sort === '' || data.sort === null || data.sort == undefined) {
        original_data.sort = null
      } else {
        original_data.sort = +data.sort
      }

      this.searchProductSort()
    }
    console.log(this.product_sort)
  }

  clonetoblog_data() {
    for (const blog_data of this.blog_data) {
      for (const blog_data_display of this.blog_data_display) {
        if (blog_data.post_id == blog_data_display.post_id) {
          blog_data.sort = blog_data_display.sort
        }
      }
    }
    this.sort()
  }

  confirmReplaceDuplicateSort(data) {
    return new Promise((resolve, reject) => {
      this.modalService.confirm({
        nzTitle: 'คุณแน่ใจหรือไม่?',
        nzContent: `อันดับสินค้าจะมีการเปลี่ยนแปลงไปแทนที่ 
          <div class="row">
            <div class="col-10">
             <span class="modal-confirm-txt">${data.name}</span>
            </div>
           
           
          </div>`,
        nzOnOk: () => {
          resolve(true)
        },
        nzOnCancel: () => {
          resolve(false)
        },
      })
    })
  }

  async update() {
    const obj = this.product_sort
      .filter((x) => x.sort != '' && x.sort != null)
      .map((x) => {
        return { post_id: x.post_id, sort: x.sort }
      })

    $('.theme-loader').show()
    await this.Service_Blog.updateBlog_v2(obj)
      .then((res: any) => {
        $('.theme-loader').hide()
        this.message.success('Save Complete', { nzDuration: 4000 })
        this.getBlogFront()
      })
      .catch((data) => {
        $('.theme-loader').hide()
        this.message.error(`${data.error.message}`, { nzDuration: 4000 })
        console.log(data.error.message)
      })

    // return;
    // $('.theme-loader').show()
    // this.Service_Product.updateProductSort({ data: obj })
    //   .then((res: any) => {
    //     $('.theme-loader').hide()
    //     this.message.success('Save Complete', { nzDuration: 4000 })
    //     this.getAllProductBySort()
    //   })
    //   .catch((data) => {
    //     $('.theme-loader').hide()
    //     this.message.error(`${data.error.message}`, { nzDuration: 4000 })
    //     console.log(data.error.message)
    //   })
  }

  pagechageProductInCate(page) {
    this.search_model_sort.page = page
    this.searchBlogSort(true)
  }
  pageSizeChange(page) {
    this.search_model_sort.perpage = page
    this.searchBlogSort(true)
  }

  close() {
    this.modalRef.close(null)
  }

  async searchProductSort(clearpage = false) {
    if (clearpage) {
      this.search_model_sort.page = 1
    }
    const offset_page = Number(this.search_model_sort.perpage) * (Number(this.search_model_sort.page) - 1)
    let sql = 'SELECT * FROM ? WHERE 1=1 '
    let sql_count = 'SELECT COUNT(*) as total FROM ? WHERE 1=1'
    const bindparam = [this.product_sort]
    if (this.search_model_sort.search !== '') {
      sql += `AND (title_lang1 LIKE ? OR sku LIKE ? ) `
      sql_count += `AND (title_lang1 LIKE ? OR sku LIKE ? ) `
      bindparam.push(`%${this.search_model_sort.search}%`)
      bindparam.push(`%${this.search_model_sort.search}%`)
    }
    if (this.search_model_sort.cate_id) {
      const cate = this.categoryList.find((x) => x.id == this.search_model_sort.cate_id)
      if (cate) {
        let sub = ''
        const sub_arr = `cate_id = ${cate.id}` as any
        sub = sub_arr.join(' OR ')
        sql += `AND ( ${sub} )`
        sql_count += `AND ( ${sub} )`
      }
      // bindparam.push
    }
    sql += 'ORDER BY sort  IS NULL,sort ,id DESC '
    sql += `LIMIT ${this.search_model_sort.perpage} OFFSET ${offset_page}`

    this.blog_data_display = await alasql(sql, bindparam)
    // let max_sort = await alasql('SELECT max(product_sort) as max_sort FROM ?',[this.product_sort]);
    // console.log('max_sort',max_sort[0].max_sort)
    // this.max_sort = max_sort[0].max_sort;
    const rs = await alasql(sql_count, bindparam)
    this.count = rs[0].total
    if (this.blog_data_display.length == 0 && this.search_model_sort.page > 1) {
      this.search_model_sort.page = this.search_model_sort.page - 1
      this.searchProductSort()
      return
    }
  }

  pagechageProductSort(page) {
    this.search_model_sort.page = page
    this.searchProductSort()
  }
}
