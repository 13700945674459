import { h, Fragment } from 'preact'
import { IPurchaseOrderHtml } from './types'

export const TmpHeaderJT = ({
  order,
  barcode,
  layout,
}: {
  order: IPurchaseOrderHtml.Order
  barcode: string
  layout: number
}) => {
  let code = '-'
  if (order.log_jt && order.log_jt.result) {
    const res = order.log_jt.result.responseitems.find((x) => x.success)
    if (res) {
      code = res.sortingcode
    }
  }

  return (
    <Fragment>
      <div class="flex-row-between align-items-end px-4 w-100 pb-2">
        <div style={`${layout == 8 || layout === 4 ? 'width: 20%;' : 'width: 30%;'}`}>
          <img class="w-100" src="https://uat.ketshoptest.com/assets/images/core-imgs/logo-delivery/logo-jnt.png" />
        </div>
        <div style="width: 70%; text-align: end; word-break: break-all;" className={`bold ${layout == 8 || layout === 4 ? 'font-xs':'font-l'}`} >
          Order No {order.ordercode}
        </div>
      </div>
      <div class="flex-row border-print b-t" style={`${layout == 8 ? 'height: 70px;' : ''}`}>
        <div class="b-r p-2 px-4" style="width: 80%">
          <div style="width: 100%;justify-content: center;display: flex;">
            <img style={`${layout == 4 ? '' : 'width: 80%;'}object-fit: cover;`} src={barcode} />
          </div>

          <div style="width: 100%;justify-content: center;display: flex;">
            <div class="bold" className={`${layout == 8 || layout === 4 ? 'font-xs' : 'font-m'}`}>
              {order.trackcode}
            </div>
          </div>
        </div>

        <div style="width: 20%; justify-content: center;display: flex;" class="bold">
          <div>
            {order.cod == 0 && <div>NON</div>}
            <div>COD</div>
          </div>
        </div>
      </div>
      <div class="border-print">
        <div
          style="display: flex;"
          className={`p-2 bold  justify-center ${layout == 8 || layout === 4 ? 'font-xs' : 'font-l'}`}
        >
          <div>{code}</div>
        </div>
      </div>
      <div className={`border-print p-1 text-center bold ${layout == 8 || layout === 4 ? 'font-xs' : 'font-l'}`}>
        {order.bill_address1}
      </div>
      <div class="flex-row border-print">
        <div style="width: 15%; justify-content: center;display: flex;" class="p-2">
          <div className={`${layout == 8 || layout === 4 ? 'text-circle-A48' : 'text-circle'}`}>ส่ง</div>
        </div>
        <div style={`width: 80%;${layout === 8 ? 'font-size:10px' : 'font-size:14px'}`} class="b-l p-2">
          <div>
            {order.sender_address.name} ({order.sender_address.tel})
          </div>
          <div>
            {order.sender_address.address} ,แขวง/ตำบล {order.sender_address.district} ,เขต/อำเภอ{' '}
            {order.sender_address.state} ,{order.sender_address.province} {order.sender_address.postcode}
          </div>
        </div>
      </div>
      <div class="flex-row border-print">
        <div style="width: 15%; justify-content: center;display: flex;" class="p-2">
          <div className={`${layout == 8 || layout === 4 ? 'text-circle-black-A48' : 'text-circle-black'}`}>
            <span>รับ</span>
          </div>
        </div>
        <div style={`width: 80%;${layout === 8 ? 'font-size:10px' : 'font-size:14px'}`} class="b-l p-2">
          <div>
            {order.bill_name} ({order.bill_tel})
          </div>
          <div>
            {order.bill_address1} ,แขวง/ตำบล {order.bill_district} ,เขต/อำเภอ {order.bill_subdistrict} ,
            {order.bill_province} {order.zipcode}
          </div>
        </div>
      </div>
    </Fragment>
  )
}
