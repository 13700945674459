import { Injectable } from '@angular/core'
import { Etc } from '../util/etc'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root',
})
export class TagService {
  constructor(
    private _etc: Etc,
    private _http: HttpClient,
  ) {}
  search(data: any) {
    return this._http.post('/tags/search', data).toPromise()
  }

  create(data: any) {
    return this._http.post('/tags', data).toPromise()
  }

  update(data: any) {
    return this._http.put('/tags/' + data.id, data).toPromise()
  }

  delete(data: any) {
    return this._http.delete('/tags/' + data.id).toPromise()
  }
}
