import { HttpClient } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import { IKetshopwebOrdersService } from '@cms/services/ketshopweb-orders.service'
import { IChannelsState, IOrdersState } from '@store/ketshopweb-store'
import { Observable } from 'rxjs'
import { IDelivery } from './types/IDelivery'
import { IOrders } from './types/IOrders'
import { isArray } from 'lodash'

@Injectable({
  providedIn: 'root',
})
export class CmsOrdersService {
  private http = inject(HttpClient)

  statusName(status: IOrders.Status) {
    switch (status) {
      case -1:
        return 'ยกเลิก'
      case 0:
        return 'สั่งซื้อ'
      case 1:
        return 'รอชำระเงิน'
      case 2:
        return 'ได้รับเงิน'
      case 3:
        return 'จัดเตรียมสินค้า'
      case 3.5:
        return 'เตรียมจัดส่ง'
      case 4:
        return 'จัดส่ง'
      case 5:
        return 'สำเร็จ'
      default:
        return 'ไม่ทราบสถานะ'
    }
  }

  getById(id, data: { include_detail?: boolean } = {}) {
    const params = {}
    Object.keys(data).forEach((x) => {
      if (data[x]) params[x] = data[x]
    })

    return this.http.get(`/services/ketshopweb-orders/orders/${id}`, { params }) as Observable<
      IOrders.OrdersEntity & { details?: IOrders.OrderDetailsEntity[] }
    >
  }

  simpleSearch(model: IKetshopwebOrdersService.SimpleSearch) {
    return this.http.post(`/services/ketshopweb-orders/search/simpleSearch`, model) as Observable<{
      data: IOrdersState.OrderSearchItem[]
      count: number
    }>
  }

  listChannel() {
    return this.http.get(`/services/ketshopweb-orders/channels/list_channel`) as Observable<IChannelsState.Channel[]>
  }

  // old api
  // /api/v1/order/listOrderMarketPlaceShop
  listSellChannel() {
    return this.http.get(`/services/ketshopweb-orders/channels/list_sell_channel`) as Observable<
      IChannelsState.SellChannel[]
    >
  }

  // old api
  // /api/v1/order/createdby
  createdby() {
    return this.http.get(`/order/createdby`) as Observable<Partial<IOrdersState.OrdersState['list_staff']>>
  }

  // old api
  // /api/v1/order/updatestatus
  updateOrderStatus(order_id: number, model: IOrders.UpdateOrderStatus) {
    return this.http.put('/order/updatestatus/' + order_id, model) as Observable<IOrders.UpdateOrderStatusResponse>
  }

  getPurchaseOrderPrintData(data: { order_ids?: number[]; shipping_group_ids?: number[] }) {
    // const params = new URLSearchParams()
    // Object.keys(data).forEach((x) => {
    //   if (data[x]) {
    //     params.append(x, data[x])
    //   }
    // })

    const params = {}
    Object.keys(data).forEach((x) => {
      if (data[x]) params[x] = data[x]
    })

    return this.http.get(`/services/ketshopweb-orders/orders/purchase-order-print-data`, {
      params,
    }) as Observable<IOrders.GetPurchaseOrderPrintDataResponse>
  }
}
