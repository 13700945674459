import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
@Injectable({
  providedIn: 'root',
})
export class KetSettingGbprimepayService {
  constructor(private _http: HttpClient) {}

  search(data: any) {
    return this._http.post('/extension/search', data).toPromise()
  }

  paymentInfo(warehouse_id) {
    return this._http.get('/services/payment-gateway/account/payment_info/' + warehouse_id).toPromise()
  }

  connectGbPay(data: any) {
    return this._http.post('/extension/connectGbPay', data).toPromise()
  }
  disconnectGbPay(data: any = null) {
    return this._http.post('/extension/disconnectGbPay', data).toPromise()
  }

  installmentBankAll(provider) {
    return this._http.get('/payment/installmentBankAll/' + provider).toPromise()
  }

  updateInstallmentBank(obj: any) {
    return this._http.put('/payment/installmentBank', obj).toPromise()
  }
}
