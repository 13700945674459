import { Component, OnInit, Input } from '@angular/core'
import { CustomerService } from '@cms/services/customer.service'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'

@Component({
  selector: 'ket-modal-forgot-password',
  templateUrl: './modal-forgot-password.component.html',
  styleUrls: ['./modal-forgot-password.component.less'],
})
export class KetModalForgotPasswordComponent implements OnInit {
  @Input() data: any
  page_name = 'login'
  model: any = {
    email: '',
  }
  message_duration = 4000
  error_message = null
  validateStatus = null

  constructor(
    private Service_Customer: CustomerService,
    private modal: NzModalRef,
  ) {}

  ngOnInit() {}

  async ok() {
    try {
      await this.Service_Customer.forgotPassword(this.model)
      this.validateStatus = null
      this.modal.close(true)
    } catch (e) {
      this.error_message = e.error.message
      this.validateStatus = 'warning'
      // this.message.error(e.error.message, { nzDuration: this.message_duration});
      return false
    }
  }

  close() {
    this.modal.close(false)
  }
}
