import { Directive, ElementRef, AfterViewInit, Input, HostListener } from '@angular/core'

import { cloneDeep } from 'lodash'

@Directive({
  selector: '[ket-owl-carousel]',
})
export class KetOwlCarouselDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  @Input('ket-owl-carousel') owlcarousel: any = {
    autoplay: true,
  }

  ngAfterViewInit() {
    $(document).ready(() => {
      //console.log('carousel_init ngAfterViewInit');
      $(this.el.nativeElement)['owlCarousel'](cloneDeep(this.owlcarousel))
      //console.log('carousel_init ngAfterViewInit',this.owlcarousel);
    })
  }

  @HostListener('window:carousel_init', ['$event'])
  onInitCarousel() {
    try {
      //console.log('carousel_init HostListener');
      // console.log('TEST')
      $(this.el.nativeElement)['owlCarousel']('destroy')
      setTimeout(() => {
        $(this.el.nativeElement)['owlCarousel'](cloneDeep(this.owlcarousel))
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'))
        }, 500)
      }, 100)
    } catch {}
  }
}
