import { SafeAny } from '@ketshopweb/ui/core/types'
import { DefaultProjectorFn, MemoizedSelector, Store } from '@ngrx/store'

export function store_query<State, Result, ProjectorFn = DefaultProjectorFn<Result>>(
  store: Store<SafeAny>,
  selector: MemoizedSelector<State, Result, ProjectorFn>,
): Promise<Result> {
  return new Promise((s, _) => {
    const sub = store.select(selector).subscribe((res) => {
      s(res)
      setTimeout(() => sub.unsubscribe(), 1)
    })
  })
}
