import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { KetshopwebFrontendService } from '@cms/services/ketshopweb-frontend.service'
@Component({
  selector: 'ket-consent-form',
  templateUrl: './consent-form.component.html',
  styleUrls: ['./consent-form.component.less'],
})
export class KetConsentFormComponent implements OnInit {
  @Input() PDPAType: string // term, sub
  @Input() PDPAStyle: string // primary, transparent
  @Output() onConsent = new EventEmitter()

  censent = false
  consentDetail: any = {
    lang1: '',
    lang2: '',
    lang3: '',
    lang4: '',
  }

  constructor(private ketshopwebFrontendService: KetshopwebFrontendService) {}

  _option: any = {}
  consent_terms_privacy_policy_status: any = 0
  consent_conditions_news_status: any = 0

  ngOnInit(): void {
    this.ketshopwebFrontendService
      .searchOption({
        pop_key: ['consent_terms_privacy_policy', 'consent_conditions_news'],
      })
      .then((res: any[]) => {
        // console.log('[PageRegisterComponent]', res)
        for (const obj of res) {
          this._option[obj.pop_key] = obj
          if (/consent_conditions_news/.test(obj.pop_key)) {
            this._option[obj.pop_key].pop_value = Number(obj.pop_value)
          } else {
            this._option[obj.pop_key].pop_value = JSON.parse(obj.pop_value)
          }
          for (const key in this._option[obj.pop_key]) {
            try {
              if (/lang[1-9]/.test(key)) {
                this._option[obj.pop_key][key] = JSON.parse(this._option[obj.pop_key][key])
                if (obj.pop_key == this.PDPAType) {
                  this.consentDetail[key] = this._option[obj.pop_key][key].text
                }
              }
            } catch (e) {}
          }
        }
        // console.log('[PageRegisterComponent] this._option',this._option)
        if (this.PDPAType == 'consent_terms_privacy_policy') {
          this.consent_terms_privacy_policy_status = false
          try {
            const _value = this._option.consent_terms_privacy_policy.pop_value
            console.log('_value', _value)
            this.consent_terms_privacy_policy_status = _value.status
          } catch (e) {}
          if (!this.consent_terms_privacy_policy_status) {
            setTimeout(() => {
              this.onConsent.emit({
                consent: true,
                type: this.PDPAType,
              })
            }, 500)
          }
        }
        if (this.PDPAType == 'consent_conditions_news') {
          this.consent_conditions_news_status = this._option.consent_conditions_news.pop_value
        }
        console.log('this.consentDetail', this.consentDetail)
      })
      .catch((err) => {
        console.log('[PageRegisterComponent] err', err)
      })
  }

  onChangeCensent() {
    this.onConsent.emit({
      consent: this.censent,
      type: this.PDPAType,
    })
  }
}
