import { Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

@Pipe({
  name: 'sanitizeHtml',
})
export class SanitizeHtmlPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}

  transform(v: string, type: any = 'html'): SafeHtml {
    //console.log(v)
    switch (type) {
      case 'html':
        try {
          v = v.replace(/<a href="\/filemanager\/dialog.php(.*?)<\/a>/g, '')
        } catch (err) {}
        return this._sanitizer.bypassSecurityTrustHtml(v)
      case 'url':
        return this._sanitizer.bypassSecurityTrustResourceUrl(v)
      default:
        return v
    }
  }
}
