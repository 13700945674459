import { Action, createReducer, on } from '@ngrx/store'
import { cloneDeep } from 'lodash'
import { actions_channels } from './actions'
import { IChannelsState } from './models'

export const FEATURE_KEY = 'channels'

export const initialChannelsState: IChannelsState.ChannelsState = {
  list_channel: {
    all: [],
    marketplace: [],
    website: [],
    pos: [],
    warehouse: [],
  },
  sell_channel: [],
}

const reducer = createReducer(
  initialChannelsState,
  on(actions_channels.SetChannel, (state, action) => {
    const channels = cloneDeep(state.list_channel)
    channels.all = action.channels
    for (const ch of action.channels) {
      if (ch.group == 'website') {
        channels.website.push(ch)
      } else if (ch.group == 'marketplace') {
        channels.marketplace.push(ch)
      } else if (ch.group == 'pos') {
        channels.pos.push(ch)
      }
    }
    return {
      ...state,
      list_channel: channels,
    }
  }),
  on(actions_channels.SetSellChannel, (state, action) => {
    return {
      ...state,
      sell_channel: action.channels,
    }
  }),
  on(actions_channels.ClearChannel, (state, action) => {
    return {
      ...state,
      list_channel: initialChannelsState.list_channel,
    }
  }),
)

export function Reducer(state: IChannelsState.ChannelsState, action: Action) {
  return reducer(state, action)
}
