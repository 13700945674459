import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { ITrcloudGateway } from './types/ITrcloudGateway'
import { Observable } from 'rxjs'
import { update } from 'lodash'

@Injectable({
  providedIn: 'root',
})
export class CmsTrcloudGatewayService {
  private http = inject(HttpClient)

  searchQuotation(input: ITrcloudGateway.CommonSearchBody) {
    return this.http.post<{ data: ITrcloudGateway.Quotation[]; count: number }>(
      '/services/trcloud/quotations/search',
      input,
    )
  }

  // resendQuotation(id: string) {
  //   return this.http.post<{ error: boolean; message: string }>(`/services/trcloud/quotations/${id}/resend`, {})
  // }

  searchBill(input: ITrcloudGateway.CommonSearchBody) {
    return this.http.post<{ data: ITrcloudGateway.Bill[]; count: number }>('/services/trcloud/bills/search', input)
  }

  resendBill(id: string) {
    return this.http.post<{ error: boolean; message: string }>(`/services/trcloud/bills/${id}/resend`, {})
  }

  searchInvoice(input: ITrcloudGateway.CommonSearchBody) {
    return this.http.post<{ data: ITrcloudGateway.Invoice[]; count: number }>(
      '/services/trcloud/invoices/search',
      input,
    )
  }

  resendInvoice(id: string) {
    return this.http.post<{ error: boolean; message: string }>(`/services/trcloud/invoices/${id}/resend`, {})
  }

  searchReceipt(input: ITrcloudGateway.CommonSearchBody) {
    return this.http.post<{ data: ITrcloudGateway.Invoice[]; count: number }>(
      '/services/trcloud/receipts/search',
      input,
    )
  }

  resendReceipts(id: string) {
    return this.http.post<{ error: boolean; message: string }>(`/services/trcloud/receipts/${id}/resend`, {})
  }

  // searchContact(input: ITrcloudGateway.ContactSearchBody) {
  //   return this.http.post<{ data: ITrcloudGateway.Contact[]; count: number }>(
  //     '/services/trcloud/contacts/search',
  //     input,
  //   )
  // }

  searchInventory(input: ITrcloudGateway.InventorySearchBody) {
    return this.http.post<{ data: ITrcloudGateway.Inventory[]; count: number }>(
      '/services/trcloud/inventories/search',
      input,
    )
  }

  searchWarehouse(input: ITrcloudGateway.WarehouseSearchBody) {
    return this.http.post<{ data: ITrcloudGateway.Warehouse[]; count: number }>(
      '/services/trcloud/warehouses/search',
      input,
    )
  }

  searchTrcloudCategory(input: ITrcloudGateway.TrcloudCategorySearchBody) {
    return this.http.post<{ data: ITrcloudGateway.TrcloudCategory[] }>(
      '/services/trcloud/trcloud-categories/search',
      input,
    )
  }

  // createAccount(input: ITrcloudGateway.CreateAccountBody) {
  //   return this.http.post<ITrcloudGateway.Account>('/services/trcloud/accounts/me', input)
  // }

  fetchAccount() {
    return this.http.get<ITrcloudGateway.Account>('/services/trcloud/accounts/me')
  }

  updateAccount(input: ITrcloudGateway.CreateAccountBody) {
    return this.http.put<ITrcloudGateway.Account>('/services/trcloud/accounts/me', input)
  }

  deleteAccount() {
    return this.http.delete<{ error: boolean; message: string }>('/services/trcloud/accounts/me')
  }

  getWebhookURLs() {
    return this.http.get<ITrcloudGateway.WebhookURLGroup[]>('/services/trcloud/webhook/urls')
  }

  fetchContactReport() {
    return this.http.get<ITrcloudGateway.SyncContactReport>('/services/trcloud/contacts/sync')
  }

  fetchInventoryReport() {
    return this.http.get<ITrcloudGateway.SyncInventoryReport>('/services/trcloud/inventories/sync')
  }

  fetchWarehouseReport() {
    return this.http.get<ITrcloudGateway.SyncInventoryReport>('/services/trcloud/warehouses/sync')
  }

  syncInventory() {
    return this.http.post<ITrcloudGateway.SyncInventoryReport>('/services/trcloud/inventories/sync', {})
  }

  syncWarehouse() {
    return this.http.post<ITrcloudGateway.SyncInventoryReport>('/services/trcloud/warehouses/sync', {})
  }

  patchInventory(id: string, input: ITrcloudGateway.PatchInventoryInput) {
    return this.http.patch<ITrcloudGateway.Inventory>(`/services/trcloud/inventories/${id}`, input)
  }

  patchWarehouse(id: string, input: ITrcloudGateway.PatchWarehouseInput) {
    return this.http.patch<ITrcloudGateway.SyncInventoryReport>(`/services/trcloud/warehouses/${id}`, input)
  }

  getSettingTrcloud() {
    return this.http.get(
      `/services/ketshopweb-accounts/trcloud/get_setting_trcloud`,
    ) as Observable<ITrcloudGateway.GetSettingTrcloud>
  }

  createAccount(input: ITrcloudGateway.CreateAccountBody) {
    return this.http.post(`/services/ketshopweb-accounts/trcloud/connect_trcloud`, input) as Observable<{
      status: string
      id: number
    }>
  }

  getTrcloudWarehouse() {
    return this.http.get(`/services/ketshopweb-accounts/trcloud/get_trcloud_warehouse`) as Observable<{
      data: ITrcloudGateway.TrcloudWarehousedetail[]
    }>
  }

  SearchBilling(input: ITrcloudGateway.SearchBilling) {
    return this.http.post(`/services/ketshopweb-accounts/billing/search_billing`, input) as Observable<{
      data: ITrcloudGateway.TrcloudBill[]
      count: number
    }>
  }

  searchContact(input: ITrcloudGateway.ContactSearchBody) {
    return this.http.post<{ data: ITrcloudGateway.Contact[]; count: number }>(
      '/services/ketshopweb-accounts/contact/search_contact',
      input,
    )
  }

  getTrcloudOption() {
    return this.http.get<ITrcloudGateway.TrcloudOption>('/services/ketshopweb-accounts/trcloud/get_trcloud_option')
  }

  listTrcloudWarehouse() {
    return this.http.get<ITrcloudGateway.ListTrcloudWarehouse>(
      '/services/ketshopweb-accounts/trcloud/list_trcloud_warehouse',
    )
  }

  SaveTrcloudOption(input: ITrcloudGateway.SaveTrcloudOption) {
    return this.http.post('/services/ketshopweb-accounts/trcloud/save_trcloud_option', input)
  }
  searchTaxinfo(input: ITrcloudGateway.TaxinfoSearchBody) {
    return this.http.post<{ data: ITrcloudGateway.TaxinfoSearcRes[]; count: number }>(
      '/services/ketshopweb-customer/search/tax_info',
      input,
    )
  }

  searchProduct(input: ITrcloudGateway.ProductSearchBody) {
    return this.http.post<{
      data: ITrcloudGateway.ProductSearchRes[]
      report: {
        sync_ketshop: number
      }
      count: number
    }>('/services/ketshopweb-accounts/trcloud/search_product', input)
  }

  checkProviderConnect() {
    return this.http.get('/services/ketshopweb-accounts/trcloud/check_provider_connect')
  }

  updateFormTrcloud(input: ITrcloudGateway.UpdateFormTrcloud) {
    return this.http.post<{ status: string; id: number }>(
      '/services/ketshopweb-accounts/trcloud/update_form_trcloud',
      input,
    )
  }
  updatePrefixAccount(input: ITrcloudGateway.UpdatePrefixAccount) {
    return this.http.post<{ status: string; id: number }>(
      '/services/ketshopweb-accounts/account/update_prefix_account',
      input,
    )
  }
  getAccount() {
    return this.http.get(
      '/services/ketshopweb-accounts/account/get_account',
    ) as Observable<ITrcloudGateway.GetAccountResponse>
  }
  providerConnect(input: ITrcloudGateway.ProviderConnect[]) {
    return this.http.post<{ status: string }>('/services/ketshopweb-accounts/trcloud/provider_connect', input)
  }

  resendQuotation(input: { ordercode: string }) {
    return this.http.post(`/services/ketshopweb-accounts/billing/update_status_provider_request`, input)
  }

  disconnectTrcloud(input: { id: number }) {
    return this.http.post<{ status: string; id: number }>(
      `/services/ketshopweb-accounts/trcloud/disconnect_trcloud`,
      input,
    )
  }

  searchProductTrcloud(input: { search: string; start: number; limit: number }) {
    return this.http.post<{ product_id: string; product_name: string; thumbnail: string }[]>(
      '/services/ketshopweb-accounts/trcloud/search_product_trcloud',
      input,
    )
  }

  connectProductTrcloud(input: ITrcloudGateway.ConnectProductTrcloud) {
    return this.http.post<{ status: string; id: number }>(
      '/services/ketshopweb-accounts/trcloud/connect_product_trcloud',
      input,
    )
  }
  disConnectProductTrcloud(input: { product_id: string }) {
    return this.http.post<{ status: string; id: number }>(
      '/services/ketshopweb-accounts/trcloud/dis_connect_product_trcloud',
      input,
    )
  }
}
