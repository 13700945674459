import { AuthenService } from '@cms/services/authen.service'
import { HttpClient } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import { event_source } from './event_source'
import { cloneDeep } from 'lodash'

@Injectable({
  providedIn: 'root',
})
export class CmsReportService {
  private auth = inject(AuthenService)
  private http = inject(HttpClient)

  export_order(model: any) {
    return event_source(this.http, this.auth, '/services/ketshopweb-reports/data-exports/order-stream', {
      input: btoa(JSON.stringify(model)),
    })
  }

  export_inventory_activity(model: any) {
    return event_source(this.http, this.auth, '/services/ketshopweb-reports/data-exports/inventory-activity-stream', {
      input: btoa(JSON.stringify(model)),
    })
  }

  groupSaleReport(model: {
    date_type: string
    payment_type: number
    startdate: string
    enddate: string
    channels: any
    assignee_ids?: any
    sell_channel?: any
  }) {
    const _mode = cloneDeep(model)
    _mode.channels = btoa(JSON.stringify(_mode.channels))
    if (_mode.sell_channel && _mode.sell_channel.length) {
      const tmp = []
      for (const ch of _mode.sell_channel) {
        tmp.push({
          channel_id: ch.channel_id,
          channel: ch.channel,
        })
      }

      console.log('tmp', tmp)
      _mode.sell_channel = btoa(JSON.stringify(tmp))
    }
    if (_mode.assignee_ids) {
      _mode.assignee_ids = btoa(JSON.stringify(_mode.assignee_ids))
    }
    return event_source(this.http, this.auth, '/services/ketshopweb-reports/group-report/report-sale', _mode)
  }

  saleReportById(model: { seller_id: number; startdate: string; enddate: string; created_by: string }) {
    const seller_id = model.seller_id
    delete model.seller_id
    return event_source(this.http, this.auth, `/services/ketshopweb-reports/group-report/seller/${seller_id}`, model)
  }

  bestSeller(data: {
    page?: number
    page_size?: number
    date_type?: string | 'created_at'
    search?: string
    start_date?: string
    end_date?: string
    order_by?: 'qty' | 'price'
    paymenttype?: string
    channels?: string[]
    seller_id?: number
    assignee_ids?: number[]
    product_id?: number
    sell_channel?: any
    group_sku?: string
  }) {
    return this.http.post<{
      count: number
      data: {
        id: number
        qty: number
        price: number
        group_id: string
        sku: string
        title_lang1: string
        feature_img: string
        prop_enable: number
        property_info: string
        property_info2: string
        properties_desc: string
        properties_desc2: string
        product_type: string
        product: {
          id: number
          qty: number
          price: number
          group_id: string
          sku: string
          title_lang1: string
          feature_img: string
          prop_enable: number
          property_info: string
          property_info2: string
          properties_desc: string
          properties_desc2: string
        }[]
      }[]
    }>(`/services/ketshopweb-reports/products-report/best_seller`, data)
  }

  customerBuyProduct(model: {
    page: number
    per_page: number
    product_id: number
    is_all?: boolean
    start_date?: string
    end_date?: string
  }) {
    return this.http.post<{
      count: number
      data: {
        sku: string
        prop_enable: number
        property_info: string
        property_info2: string
        product_id: number
        group_id: string
        qty: number
        price: number
        id: number
        type: string
        name: string
        lastname: string
        title?: string
      }[]
    }>('/services/ketshopweb-reports/products-report/customer_buy_product', model)
  }

  bestWishlists(data: { limit?: number; is_all?: boolean; search?: string; start_date?: string; end_date?: string }) {
    return this.http.post<
      {
        sku: string
        title_lang1: string
        feature_img: string
        prop_enable: number
        id: number
        property_info: string
        property_info2: string
        properties_desc: string
        properties_desc2: string
        product_count: number
        group_id: number
        product_type: string
        product: {
          id: number
          qty: number
          price: number
          group_id: string
          sku: string
          title_lang1: string
          feature_img: string
          prop_enable: number
          property_info: string
          property_info2: string
          properties_desc: string
          properties_desc2: string
        }[]
      }[]
    >(`/services/ketshopweb-reports/products-report/best_wishlists`, data)
  }

  customerWishlistsProduct(model: {
    page: number
    per_page: number
    product_id: number
    is_all?: boolean
    start_date?: string
    end_date?: string
  }) {
    return this.http.post<{
      count: number
      data: {
        sku: string
        product_id: number
        group_id: string
        qty: number
        price: number
        id: number
        type: string
        name: string
        lastname: string
        title?: string
      }[]
    }>('/services/ketshopweb-reports/products-report/customer_wishlists_product', model)
  }

  mostfreeitemredeempoint(data: {
    type: 'product_point' | 'free_item'
    limit?: number
    is_all?: boolean
    search?: string
    start_date?: string
    end_date?: string
  }) {
    return this.http.post<
      {
        sku: string
        title_lang1: string
        feature_img: string
        prop_enable: number
        id: number
        property_info: string
        property_info2: string
        properties_desc: string
        properties_desc2: string
        product_count: number
        group_id: number
        qty: number
        value: number
        product_type: string
        product: {
          id: number
          qty: number
          price: number
          group_id: string
          sku: string
          title_lang1: string
          feature_img: string
          prop_enable: number
          property_info: string
          property_info2: string
          properties_desc: string
          properties_desc2: string
        }[]
      }[]
    >(`/services/ketshopweb-reports/products-report/most_freeitem_redeem_point`, data)
  }

  customerFreeItemRedeemPointProduct(model: {
    page: number
    per_page: number
    product_id: number
    is_all?: boolean
    type?: string
    start_date?: string
    end_date?: string
  }) {
    return this.http.post<{
      count: number
      data: {
        sku: string
        product_id: number
        group_id: string
        qty: number
        value: number
        id: number
        type: string
        name: string
        lastname: string
        title?: string
      }[]
    }>('/services/ketshopweb-reports/products-report/customer_freeitem_redeem_point', model)
  }

  orderByTime(model: {
    start_date: string
    end_date: string
    paymenttype: string
    channels: string[]
    sell_channel: any[]
  }) {
    return this.http.post('/services/ketshopweb-reports/orders-report/orders-by-time', model)
  }
}
