import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzGridModule } from 'ng-zorro-antd/grid'
import { KetOrdersModule } from '@ketshopweb/ui/ket-order-components/ket-orders'
import { NzPaginationModule } from 'ng-zorro-antd/pagination'
import { NzTagModule } from 'ng-zorro-antd/tag'
import { NzTableModule } from 'ng-zorro-antd/table'
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker'
import { NzTabsModule } from 'ng-zorro-antd/tabs'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'
import { NzAvatarModule } from 'ng-zorro-antd/avatar'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { KetPermissionModule } from '@ketshopweb/ui/permission'
import { ReportTeamWarehouseComponent } from './report-team-warehouse.component'
import { KetProductItemInlineModule } from '@ketshopweb/ui/ket-product-components/product-item-inline'

@NgModule({
  declarations: [ReportTeamWarehouseComponent],
  imports: [
    CommonModule,
    FormsModule,
    NzAvatarModule,
    NzToolTipModule,
    NzTabsModule,
    NzDatePickerModule,
    NzTableModule,
    NzTagModule,
    NzPaginationModule,
    NzGridModule,
    NzButtonModule,

    KetPermissionModule,
    KetOrdersModule,
    KetProductItemInlineModule,
  ],
  exports: [ReportTeamWarehouseComponent],
})
export class ReportTeamWarehouseModule {}
