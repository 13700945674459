import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { IWarehouseState } from 'libs/ketshopweb-store/+state/warehouse'
import { Observable } from 'rxjs'
import { IWarehouse } from './types/IWarehouse'
import { IInventory } from '@cms/services/ketshopweb-inventory.service'

@Injectable({
  providedIn: 'root',
})
export class CmsWarehouseService {
  private http = inject(HttpClient)

  // export {
  //     create_warehouse,
  //     get_warehouse_by_id,
  //     list_warehouse,
  //     update_warehouse
  //     remove_warehouse
  // }

  create_warehouse(model: {
    account_based_on?: IWarehouseState.WarehouseAccountBasedOn
    type?: IWarehouseState.WarehouseType
    name: string
    full_address: string
    branch_code?: string
    org_name?: string
    tax_no?: string
    org_phone?: string
    pin?: string
  }) {
    return this.http.post('/services/ketshopweb-products/warehouse/create_warehouse', model) as Observable<
      Partial<IWarehouseState.WarehouseState['list'][0]>
    >
  }

  update_warehouse(
    id: number,
    model: {
      account_based_on?: IWarehouseState.WarehouseAccountBasedOn
      type?: IWarehouseState.WarehouseType
      name: string
      full_address: string
      branch_code?: string
      org_name?: string
      tax_no?: string
      org_phone?: string
      pin?: string
      bill_prefix?: string
      tax_request_qr: boolean
      subdistrict: string
      district: string
      province: string
      zipcode: string
      tel: string
    },
  ) {
    return this.http.put(`/services/ketshopweb-products/warehouse/update_warehouse/${id}`, model) as Observable<
      Partial<IWarehouseState.WarehouseState['list'][0]>
    >
  }

  get_warehouse_by_id(id: number) {
    return this.http.get(`/services/ketshopweb-products/warehouse/get_warehouse_by_id/${id}`) as Observable<
      Partial<IWarehouseState.WarehouseState['list'][0]>
    >
  }

  get_warehouse_pin_by_id(id: number) {
    return this.http.get(`/services/ketshopweb-products/warehouse/get_warehouse_pin_by_id/${id}`) as Observable<
      Partial<{ pin: string }>
    >
  }

  list_warehouse() {
    return this.http.get(`/services/ketshopweb-products/warehouse/list_warehouse`) as Observable<
      Partial<IWarehouseState.WarehouseState['list']>
    >
  }

  remove_warehouse(id: number) {
    return this.http.delete(`/services/ketshopweb-products/warehouse/remove_warehouse/${id}`) as Observable<boolean>
  }

  warehouse_info() {
    return this.http.get(`/services/ketshopweb-products/warehouse/warehouse_info`) as Observable<
      Partial<IWarehouseState.WarehouseState['info']>
    >
  }

  update_warehouse_info(model: {
    status: boolean
    logo_status: boolean
    logo: string
    org_name: string
    org_address: string
    tax_no: string
    org_phone: string
    org_tax_phone: string
    receipt: string
    province: string
    district: string
    subdistrict: string
    zipcode: string
  }) {
    return this.http.put(`/services/ketshopweb-products/warehouse/update_warehouse_info`, model) as Observable<
      Partial<IWarehouseState.WarehouseState['info']>
    >
  }

  warehouse_transfer_get(model: any) {
    return this.http.post(`/services/ketshopweb-products/warehouse/list_warehouse_transfer`, model) as Observable<
      Partial<IWarehouseState.WarehouseState['warehouse_transfer']['list']>
    >
  }

  search_product(model: IWarehouseState.WarehouseProductSearchModel) {
    return this.http.post(`/services/ketshopweb-products/warehouse/search_warehouse_product`, model) as Observable<{
      count: number
      data: IWarehouseState.WarehouseProductItem[]
    }>
  }

  create_warehouse_transfer(model: IWarehouseState.CreateWarehouseTransfer) {
    return this.http.post(`/services/ketshopweb-products/warehouse/create_warehouse_transfer`, model) as Observable<{
      id: number
    }>
  }

  create_warehouse_returned(model: IWarehouseState.CreateWarehouseReturned) {
    return this.http.post(`/services/ketshopweb-products/warehouse/create_warehouse_returned`, model) as Observable<{
      id: number
    }>
  }

  run_import(data: {
    type: string
    map_columns: any[]
    xlsx_path: string
    file_name: string
    warehouse_sender: number
    warehouse_receiver: number
  }) {
    return this.http.post('/services/ketshopweb-products/warehouse/run_import', data).toPromise() as Promise<{
      job_id: any
      job_ref: string
    }>
  }

  getMapColumnsImport(type = '') {
    let qry = ''
    if (type) {
      qry = `?type=${type}`
    }
    return this.http.get('/services/ketshopweb-products/warehouse/get_map_columns' + qry).toPromise()
  }

  warehouse_transfer_by_id(id: number) {
    return this.http.get(
      `/services/ketshopweb-products/warehouse/warehouse_transfer_detail/${id}`,
    ) as Observable<IWarehouse.WarehouseTransferDetail>
  }

  warehouse_transfer_by_code(code: string) {
    return this.http.get(
      `/services/ketshopweb-products/warehouse/warehouse_transfer_detail/${code}?type=code`,
    ) as Observable<IWarehouse.WarehouseTransferDetail>
  }

  transfer_comment(model: { warehouse_transfer_id: number; message: string; is_print?: boolean }) {
    return this.http.post(`/services/ketshopweb-products/warehouse/transfer_comment`, model) as Observable<boolean>
  }

  transfer_state(model: {
    warehouse_transfer_id: number
    state: IWarehouse.WarehouseTransferState
    reason: string
    confirm_reject?: boolean
  }) {
    return this.http.put(`/services/ketshopweb-products/warehouse/transfer_state`, model) as Observable<boolean>
  }

  search_inventory(model: IWarehouseState.IInventorySearchModel) {
    return this.http.post(`/services/ketshopweb-products/warehouse/warehouse_inventory`, model) as Observable<{
      count: number
      data: IWarehouseState.IInventoryItem[]
    }>
  }

  getMarketplace(): Observable<IInventory.Marketplace[]> {
    return this.http.get('/services/ketshopweb-products/inventory/marketplace') as Observable<IInventory.Marketplace[]>
  }

  update_warehouse_inventory_model(model: { product_id: number; type: 'group' | 'single'; is_edit_price?: boolean }) {
    return this.http.get(
      `/services/ketshopweb-products/warehouse/update_warehouse_inventory_model/${model.product_id}?type=${model.type}&is_edit_price=${model.is_edit_price}`,
    ) as Observable<IWarehouse.UpdateWarehouseInventoryModel>
  }

  update_warehouse_inventory(model: IWarehouse.UpdateWarehouseInventoryModel) {
    return this.http.put(`/services/ketshopweb-products/warehouse/update_warehouse_inventory`, model) as Observable<{
      errors: {
        error: string
        to: string
        warehouse_id: number
        amount: number
        channel: string
        channel_id: string
        channel_inventory_id: string
        channel_product_id: string
        product_id: number
      }[]
    }>
  }

  warehouse_transfer_export(model: any) {
    return this.http.post(`/services/ketshopweb-products/warehouse/export_warehouse_transfer`, model)
  }

  search_inventory_set(model: any) {
    return this.http.post(`/services/ketshopweb-products/warehouse/warehouse_inventory_set`, model) as Observable<{
      count: number
      data: IWarehouseState.IInventoryItem[]
    }>
  }

  warehouse_set_product(model: any) {
    return this.http.post(`/services/ketshopweb-products/warehouse/warehouse_set_product`, model) as Observable<{
      count: number
      data: IWarehouseState.WarehouseSetProduct[]
    }>
  }

  search_inventory_available(model: any) {
    return this.http.post(`/services/ketshopweb-products/inventory/search_inventory_available`, model) as Observable<{
      count: number
      data: any
    }>
  }

  search_inventory_pos(model: any) {
    return this.http.post(`/services/ketshopweb-products/inventory/search_inventory_pos`, model) as Observable<{
      count: number
      data: any
    }>
  }

  search_inventory_price(model: any) {
    return this.http.post(`/services/ketshopweb-products/inventory/search_inventory_price`, model) as Observable<{
      count: number
      data: any
    }>
  }

  search_warehouse_price(model: any) {
    return this.http.post(`/services/ketshopweb-products/inventory/search_warehouse_price`, model) as Observable<{
      count: number
      data: any
    }>
  }

  search_inventory_equalize(model: any) {
    return this.http.post(`/services/ketshopweb-products/inventory/search_inventory_equalize`, model) as Observable<{
      count: number
      data: any
    }>
  }

  update_equalize_percent(data) {
    return this.http.post(`/services/ketshopweb-products/inventory/update_equalize_percent`, data) as Observable<any>
  }

  update_price(model: {
    note?: string
    products: {
      type: string
      product_id: number
      sku?: string
      temp_price: number
      price: number
      to: 'warehouse' | 'marketplace'
      warehouse_id?: number
      channel?: string
      channel_id?: string
      channel_inventory_id?: string
      channel_product_id?: string
    }[]
  }) {
    return this.http.post(`/services/ketshopweb-products/warehouse/price`, model) as Observable<any>
  }

  update_stock(model: {
    note?: string
    activity_by: string
    products: {
      type: string
      product_id: number
      sku?: string
      amount: number
      to: 'warehouse' | 'marketplace'
      warehouse_id?: number
      channel?: string
      channel_id?: string
      channel_inventory_id?: string
      channel_product_id?: string
    }[]
    code?: string
    code_type?: string
    activity_type?: string
    related_website_stock?: boolean
  }) {
    return this.http.post(`/services/ketshopweb-products/warehouse/stock`, model) as Observable<any>
  }

  searchLogImport(data: any) {
    return this.http.post(`/services/ketshopweb-products/warehouse/search_log_import`, data).toPromise()
  }

  //reject_transfer
  rejectTransfer(data: any) {
    return this.http.post(`/services/ketshopweb-products/warehouse/reject_transfer`, data).toPromise()
  }
}
