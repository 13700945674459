import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { OrdersEffects } from './effects'
import { FEATURE_KEY, Reducer } from './reducer'

@NgModule({
  imports: [CommonModule, StoreModule.forFeature(FEATURE_KEY, Reducer), EffectsModule.forFeature([OrdersEffects])],
})
export class OrdersSateModule {}
