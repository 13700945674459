import { HttpClient } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'

export namespace IKetshopwebPromotionService {
  export enum PromotionType {
    Coupon = 'coupon',
  }
  export namespace ICoupon {
    export enum GenerateTypeEnum {
      Single = 'single',
      Group = 'group',
    }

    export enum TypeEnum {
      Unlimit = 'unlimit',
      All = 'all',
    }

    export enum TypeLabelEnum {
      Unlimit = 'unlimit',
      All = 'all',
    }

    export enum TotalTypeEnum {
      Value = 'value',
      Quantity = 'quantity',
    }

    export enum ValueTypeEnum {
      Normal = 'normal',
      Percent = 'percent',
    }

    export enum CouponTypeEnum {
      Order = 'order',
      Product = 'product',
    }

    export interface SaveData {
      max_use: number
      freeshipping: number
      is_use_total: number
      total_type: TotalTypeEnum
      total: number
      title: string
      type: TypeEnum
      value_type: ValueTypeEnum
      value: number
      is_use_max_discount: number
      max_discount: number
      startdate: string
      enddate: string
      status: number
      display: number
      coupon_type: CouponTypeEnum
      promo_participating_products?: { product_id: number }[]
    }

    export interface CreateData extends SaveData {
      prefix?: string
      code?: string
    }

    export interface UpdateData extends Partial<SaveData> {
      startdate: string
      enddate: string
    }

    export interface Entity {
      code?: string
      coupon_type?: string
      created_at?: string
      created_by?: string
      display?: number
      enddate?: string
      freeshipping?: number
      id?: number
      is_group?: number
      is_use_max_discount?: number
      is_use_total?: number
      max_discount?: number
      max_use?: number
      prefix?: string
      startdate?: string
      status?: number
      title?: string
      total?: number
      total_type?: string
      type?: string
      updated_at?: string
      updated_by?: string
      use?: number
      value?: number
      value_type?: string
    }

    export interface GroupEntity {
      code?: string
      created_at?: string
      id?: number
      ordercode?: string
      promo_coupon_id?: number
      status?: number
      updated_at?: string
    }

    export interface EntityWithRelated extends Entity {
      promo_coupon_groups: GroupEntity[]
      promo_participating_products: IParticipatingProduct.Entity[]
      promo_apply_member_level?: PromoApplyMemberLevelEntity[]
    }
  }

  export namespace IParticipatingProduct {
    export interface Entity {
      created_at?: string
      created_by?: string
      id?: number
      product_id?: string
      promotion_id?: number
      promotion_type?: string
      updated_at?: string
      updated_by?: string
    }
  }

  export interface ListApplyMemberLevelInput {
    promo_type: string
    promo_id: number
    member_level_id?: number
    is_active?: boolean
  }

  export interface SetApplyMemberLevelInput {
    promo_type: string
    promo_id: number
    member_level_ids: number[]
  }

  export interface PromoApplyMemberLevelEntity {
    created_at?: string | null
    created_by?: string | null
    is_active?: number | null
    member_level_id?: number | null
    promo_id: number
    promo_type: string
    updated_at?: string | null
    updated_by?: string | null
  }
}

@Injectable({
  providedIn: 'root',
})
export class KetshopwebPromotionService {
  private http = inject(HttpClient)

  constructor() {}

  createCoupon(
    type: IKetshopwebPromotionService.ICoupon.GenerateTypeEnum,
    input: IKetshopwebPromotionService.ICoupon.SaveData,
  ) {
    return this.http
      .post(`/services/ketshopweb-promotion/coupons?generate_type=${type}`, input)
      .toPromise() as Promise<IKetshopwebPromotionService.ICoupon.EntityWithRelated>
  }

  updateCoupon(coupon_id: number, input: IKetshopwebPromotionService.ICoupon.UpdateData) {
    return this.http
      .put(`/services/ketshopweb-promotion/coupons/${coupon_id}`, input)
      .toPromise() as Promise<IKetshopwebPromotionService.ICoupon.EntityWithRelated>
  }

  getMapColumnsQRPointImport() {
    return this.http.get('/services/ketshopweb-promotion/member-points/getMapColumnsQRPointImport').toPromise()
  }

  importQRPointBackground(data) {
    return this.http.post('/services/ketshopweb-promotion/member-points/importQRPointBackgroundMode', data).toPromise()
  }

  importQRPointBackgroundState(ref) {
    return this.http.get('/services/ketshopweb-promotion/member-points/background_mode_state/' + ref).toPromise()
  }

  download_error(ref) {
    return this.http.get('/services/ketshopweb-promotion/member-points/download_error/' + ref).toPromise()
  }

  partialUpdateCoupon(
    coupon_id: number,
    input: {
      status: number
    },
  ) {
    return this.http
      .patch(`/services/ketshopweb-promotion/coupons/${coupon_id}`, input)
      .toPromise() as Promise<IKetshopwebPromotionService.ICoupon.EntityWithRelated>
  }

  getCoupon(id: number) {
    return this.http
      .get(`/services/ketshopweb-promotion/coupons/${id}`)
      .toPromise() as Promise<IKetshopwebPromotionService.ICoupon.EntityWithRelated>
  }

  listApplyMemberLevel(data: IKetshopwebPromotionService.ListApplyMemberLevelInput) {
    const params = {}
    Object.keys(data).forEach((x) => {
      if (data[x]) params[x] = data[x]
    })
    return this.http
      .get(`/services/ketshopweb-promotion/apply-member-level`, {
        params,
      })
      .toPromise() as Promise<IKetshopwebPromotionService.PromoApplyMemberLevelEntity[]>
  }

  setApplyMemberLevel(input: IKetshopwebPromotionService.SetApplyMemberLevelInput) {
    return this.http.put(`/services/ketshopweb-promotion/apply-member-level`, input).toPromise() as Promise<
      IKetshopwebPromotionService.PromoApplyMemberLevelEntity[]
    >
  }
}
