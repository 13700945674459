/* eslint-disable @typescript-eslint/no-explicit-any */
import { StoreAtom } from 'ketshopweb-sdk'
import { SocketFn } from './SocketFn'

export const SocketIOStore = StoreAtom({
  socket: null as {
    on: (event: string, callback: (...args: any[]) => void) => void
    once: (event: string, callback: (...args: any[]) => void) => void
    emit: (event: string, ...args: any[]) => void,
    disconnect: () => void,
    connect: () => void,
    off: (event: string, callback?: (...args: any[]) => void) => void
    connected: boolean,
    auth: any
    id: string
  }
})

export const SocketIOStoreActions = {
  watch_room: StoreAtom(''),
  services: {
    trcloud: {
      SyncProducts: SocketFn.trcloud.SyncProducts
    }
  }
}

let isSubscribed = false
SocketIOStore.subscribe((state) => {
  const socket = state.socket
  if (socket && !isSubscribed) {
    isSubscribed = true
    socket.on('connect', () => {
      console.log('Connected to Socket.IO server')
      // socket 
      socket.once(`join_${socket.id}`, (data: {
        watch_room: string
        jwt_data: {
          id: number
        }
      }) => {
        console.log('joined room', data)
        SocketIOStoreActions.watch_room.set(data.watch_room)
        window['SocketIOStoreActions'] = SocketIOStoreActions
      })
    })
    socket.on('disconnect', () => {
      console.log('Disconnected from Socket.IO server')
    })
  }
})