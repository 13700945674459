import { Component, OnInit, AfterViewInit, OnDestroy, Output, EventEmitter, Renderer2 } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { pathLangPipe } from 'src/app/pipes/path-lang.pipe'
import { Etc } from 'src/app/util/etc'
import { HttpClient } from '@angular/common/http'
import { FocusMonitor } from '@angular/cdk/a11y'
// import { FocusMonitor } from '@angular/cdk/a11y';

declare let alasql: any

@Component({
  selector: 'ket-thailand-address',
  templateUrl: './thailand-address.component.html',
  styleUrls: ['./thailand-address.component.scss'],
})
export class KetThailandAddressComponent implements OnInit, AfterViewInit, OnDestroy {
  search: any
  selected: any
  isLoading = false
  db: any = []
  result: any = []
  perpage = 10
  page = 1
  @Output() onSelected = new EventEmitter()

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected pathLang: pathLangPipe,
    protected route: Router,
    private _etc: Etc,
    private http: HttpClient,
    private focusMonitor: FocusMonitor,
  ) {}

  ngOnDestroy() {}

  async ngOnInit() {
    // let db = this._etc.getThailandAddress();
    // if(db.length == 0){
    this.db = await this.http.get('/assets/db-thailand.json').toPromise()
    // }
    console.time('start')
    for (let i = 0; i < this.db.length; i++) {
      const current = this.db[i]
      const ziparr = `${current.zipcode}`.split(',')
      if (ziparr.length > 1) {
        current.zipcode = ziparr[0]
        for (let j = 1; j < ziparr.length; j++) {
          const obj = {
            city: current.city,
            district: current.district,
            province: current.province,
            zipcode: ziparr[j],
          }
          this.db.splice(i, 0, obj)
        }
      }
    }
    console.timeEnd('start')
    // console.log(address);
  }
  ngAfterViewInit() {}

  _onSelected() {
    console.log(this.selected)
    this.onSelected.emit(this.selected)
  }

  async onSearch(search: any) {
    const key_length = search.length
    this.search = search
    this.page = 1
    this.result = await this.findAddress(search)
  }

  async findAddress(search) {
    search = search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
    const offset_page = Number(this.perpage) * (Number(this.page) - 1)
    return await alasql(
      `SELECT * FROM ? WHERE province LIKE ? OR city LIKE ? OR district LIKE ? OR zipcode LIKE ? LIMIT ${this.perpage} OFFSET ${offset_page}`,
      [this.db, `%${search}%`, `%${search}%`, `%${search}%`, `%${search}%`],
    )
  }

  async loadMore() {
    this.isLoading = true
    console.log('loadmore')
    this.page++
    const rs = await this.findAddress(this.search)
    if (rs.length) {
      this.result = [...this.result, ...rs]
    }

    this.isLoading = false
  }

  onClick($event: MouseEvent) {
    const target = $event.target as HTMLElement
    const input = target.offsetParent.querySelectorAll('input')
    try {
      try {
        const addr = document.querySelectorAll('[name="address1"]')[0] as HTMLInputElement
        // console.log('addr', addr)
        this.focusMonitor.focusVia(addr, 'keyboard')
      } catch {}
      setTimeout(() => {
        this.focusMonitor.focusVia(input[0], 'keyboard')
        this.onSearch('')
      }, 10)
    } catch {}
  }
}
