import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor(private _http: HttpClient) {}

  listEmail() {
    return this._http.get('/email/listEmail').toPromise()
  }

  createEmailAccount(obj: any) {
    return this._http.post('/email/createEmailAccount', obj).toPromise()
  }

  changePassword(obj: any) {
    return this._http.post('/email/changePassword', obj).toPromise()
  }

  deleteEmail(obj: any) {
    return this._http.post('/email/deleteEmailAccount', { email: obj.MAIL }).toPromise()
  }

  changeQuota(obj: any) {
    return this._http.post('/email/changeQuotaMailAccount', obj).toPromise()
  }
}
