import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root',
})
export class DeliveryService {
  constructor(private _http: HttpClient) {}

  ordersListDeliver(obj: any) {
    return this._http.post('/delivery/getOrder', obj).toPromise()
  }

  getListDeliveryAuto() {
    return this._http.get(`/delivery/deliveryautosetting`).toPromise()
  }

  getListDeliveryManual() {
    return this._http.get(`/delivery/getDeliveryManual`).toPromise()
  }

  getListDeliveryDirect() {
    return this._http.get(`/delivery/getDeliveryDirect`).toPromise()
  }

  getDeliveryAvaliable() {
    return this._http.get(`/delivery/getDeliveryAvaliable`).toPromise()
  }

  // getDeliveryOverview() {
  //   return this._http.get('/delivery/overview').toPromise()
  // }

  getDelivery(type = '', courier_code = '') {
    const params = {}
    if (type) {
      params['type'] = type
    }
    if (courier_code) {
      params['courier_code'] = courier_code
    }

    return this._http.get(`/delivery`, { params }).toPromise()
  }

  getDeliveryById(id: any) {
    return this._http.get(`/delivery/${id}`).toPromise()
  }

  addDelivery(obj: any) {
    return this._http.post('/delivery', obj).toPromise()
  }

  updateDeliveryStatus(obj: any) {
    return this._http.put(`/delivery/status/${obj.id}`, obj).toPromise()
  }

  updateDelivery(obj: any) {
    return this._http.put(`/delivery/${obj.id}`, obj).toPromise()
  }

  deleteDelivery(obj: any) {
    return this._http.delete(`/delivery/${obj.id}`).toPromise()
  }

  searchDeliverybypass(data) {
    return this._http.post(`/delivery/deliverybypass`, data).toPromise()
  }

  getDeliveryManual(name?: string) {
    return this._http.get(`/delivery/deliverymanual${name ? `?name=${name}` : ''}`).toPromise()
  }

  updateDeliveryManual(data: any) {
    return this._http.put(`/delivery/deliverymanual/${data.id}`, data).toPromise()
  }

  createDeliveryManual(data: any) {
    return this._http.post(`/delivery/deliverymanual`, data).toPromise()
  }

  deleteDeliveryManual(data: any) {
    return this._http.delete(`/delivery/deliverymanual/${data.id}`).toPromise()
  }

  searchCreditShipping(data?: any) {
    const search = data || {
      page: 1,
      perpage: 10,
      trackingcode: '',
      date: '',
    }

    return this._http.post('/delivery/creditshippinglog', search).toPromise()
  }

  getDeliveryAutosetting() {
    return this._http.get(`/delivery/deliveryautosetting`).toPromise()
  }

  saveDeliveryAutosetting(obj: any) {
    return this._http.put(`/delivery/deliveryautosetting`, obj).toPromise()
  }

  checkPriceManual(
    obj: any = {
      from: '10900',
      to: '10310',
      weight: '4000',
      box: '14x20x6',
    },
  ) {
    return this._http.post(`/delivery/checkprice-1`, obj).toPromise()
  }

  bookDelivery(obj: any) {
    return this._http.post(`/delivery/delivered`, obj).toPromise()
  }

  getDeliveryAsSet() {
    return this._http.get(`/delivery/deliveryfromorder`).toPromise()
  }

  getDeliveryAtStore() {
    return this._http.get(`/delivery/deliverypickatstore`).toPromise()
  }

  getOrderFreeShipping(
    obj: any = {
      sort: 'desc',
      ordercode: '',
      search: '',
      page: 1,
      perpage: 10,
    },
  ) {
    return this._http.post(`/delivery/orderfreeshipping`, obj).toPromise()
  }

  checkPriceAsSet(obj: any) {
    return this._http.post('/delivery/checkprice-3', obj).toPromise()
  }

  bookDeliveredFromOrder(obj: any) {
    return this._http.post('/delivery/deliveredfromorder', obj).toPromise()
  }

  checkPriceAtStore(obj: any) {
    return this._http.post('/delivery/checkprice-2', obj).toPromise()
  }

  shippingstatus(obj: any) {
    return this._http.post('/delivery/shippingstatus', obj).toPromise()
  }

  checkInterShippingEnable() {
    return this._http.get('/delivery/checkInterShipping').toPromise()
  }

  getAvailbleCountry(): Promise<
    {
      country_name: string
      country_alpha2_code: string
    }[]
  > {
    return this._http.get('/delivery/availbleCountry').toPromise() as any
  }
}
