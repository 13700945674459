import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { FontSettingRoutingModule } from './font-setting-routing.module'
import { FontSettingComponent } from './font-setting.component'
import { NzGridModule } from 'ng-zorro-antd/grid'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzSelectModule } from 'ng-zorro-antd/select'

@NgModule({
  declarations: [FontSettingComponent],
  imports: [
    CommonModule,
    FontSettingRoutingModule,
    NzGridModule,
    NzButtonModule,
    NzIconModule,
    NzInputModule,
    NzSelectModule,
  ],
})
export class FontSettingModule {}
