import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { AuthenService } from './services/authen.service'
import * as jwt_decode from 'jwt-decode'

@Injectable({
  providedIn: 'root',
})
export class AuthenGuard implements CanActivate {
  profile_guard = false

  constructor(
    private router: Router,
    private _authen: AuthenService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    try {
      if (!this._authen.getAuthenToken()) {
        this.router.navigate(['/system/login'])
      } else {
        try {
          const _admin = jwt_decode(this._authen.getToken().access_token)
          if (_admin.user_role !== 'customer') {
            return true
          } else {
            this.router.navigate(['/system/login'])
            return false
          }
        } catch (err) {
          this.router.navigate(['/system/login'])
          return false
        }
      }
    } catch {
      this.router.navigate(['/system/login'])
      return false
    }
  }
}
