import { NzToolTipModule } from 'ng-zorro-antd/tooltip'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetProductItemInlineComponent } from './product-item-inline.component'
import { NzPopoverModule, NzTagModule } from 'ng-zorro-antd'
import { KetModalTagPromoPointModule } from '@ketshopweb/ui/modal-tag-promo-point'

@NgModule({
  declarations: [KetProductItemInlineComponent],
  imports: [CommonModule, NzPopoverModule, NzTagModule, NzToolTipModule, KetModalTagPromoPointModule],
  exports: [KetProductItemInlineComponent],
})
export class KetProductItemInlineModule {}
