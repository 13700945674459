import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'limitString',
})
export class limitStringPipe implements PipeTransform {
  constructor() {}

  transform(v: string, amount: number) {
    if (v == null || v == undefined) {
      return ''
    }
    return `${v}`.length > amount ? `${v}`.substring(0, amount) + '...' : v
  }
}
