import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { WarehouseEffects } from './effects'
import { WarehouseFEATURE_KEY, WarehouseReducer } from './reducer'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(WarehouseFEATURE_KEY, WarehouseReducer),
    EffectsModule.forFeature([WarehouseEffects]),
  ],
})
export class WarehouseSateModule {}
