import { createAction, props } from '@ngrx/store'
import { IPageOptionsState } from './models'

export const actions_page_options = {
  LoadPageOptions: createAction<string>('[CMS][PageOptions/API] LoadPageOptions'),
  SetPageOptions: createAction<string, { page_options: IPageOptionsState.PageOption[] }>(
    '[CMS][PageOptions] SetPageOptions',
    props<{
      page_options: IPageOptionsState.PageOption[]
    }>(),
  ),
}
