import { HttpClient } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import { AuthenService } from '@cms/services/authen.service'
import { Observable } from 'rxjs'
import { switchMap, mergeMap, map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { IPaymentGateway } from './types/IPaymentGateway'
import { cloneDeep } from 'lodash'
// old api
declare let fbq: any
let IS_FB_EVENT_API = true
let IS_TIKTOK_EVENT_API = true
@Injectable({
  providedIn: 'root',
})
export class CmsPaymentGateway {
  private http = inject(HttpClient)
  private auth = inject(AuthenService)

  createOrderCredit(input: IPaymentGateway.CreateOrderCredit, totals: number, payment?: IPaymentGateway.PaymentType) {
    return this.http.get<IPaymentGateway.TokenResponse>('/services/payment-gateway/credit_cards/tokens').pipe(
      switchMap((value) => {
        const model = value.model
        model.rememberCard = false
        model.card = input.card
        return this.http.post<IPaymentGateway.GbPrimepayTokenResponse>(value.tokens_url, model, {
          headers: {
            Authorization: value.client_authorization,
          },
        })
      }),
      switchMap(async (value) => {
        const model = input
        model['just_mark'] = true
        model.card = value.card as any

        if (input.ordercode) {
          return new Observable<{
            ordercode: string
          }>((ob) => {
            ob.next({
              ordercode: input.ordercode,
            })
            ob.complete()
          })
        }
        return this.http.post<{
          ordercode: string
        }>('/services/ketshopweb-orders/purchase/order-credit', model)
      }),
      mergeMap((value) => value),
      map(async (value) => {
        if (payment) {
          const paymentType = cloneDeep(payment)
          delete paymentType['card']
          const last_order = {
            resultCode: '100',
            order: {
              totals: totals,
              ordercode: value.ordercode || null,
            },
            paymentType: paymentType,
            delivery: input.model.delivery,
          }
          if (input.redirect.from == 'sale-page') {
            try {
              await this.salePageToFacebook(input, value.ordercode, totals)
            } catch {}
          } else {
            localStorage.setItem('last_order', JSON.stringify(last_order))
          }
        }
        input.ordercode = value.ordercode
        const base_url = environment.api_path.replace('/api/v1', '')
        // Authorization: `Bearer ${auth.getAuthenToken()}`
        const form = document.createElement('form')
        form.method = 'POST'
        form.target = '_top'
        form.action = `${base_url}/services/payment-gateway/credit_cards/charge_redirect`

        // set Authorization
        const from_input = document.createElement('input')
        from_input.type = 'hidden'
        from_input.name = 'authorization'
        from_input.value = `${this.auth.getAuthenToken()}`
        form.append(from_input)

        // set domain
        const domain_input = document.createElement('input')
        domain_input.type = 'hidden'
        domain_input.name = 'domain'
        domain_input.value = location.host
        form.append(domain_input)

        // set Data
        const from_input_data = document.createElement('input')
        from_input_data.type = 'hidden'
        from_input_data.name = 'data'
        from_input_data.value = JSON.stringify(input)
        form.append(from_input_data)
        document.body.appendChild(form)

        form.submit()
        return value
      }),
    )
  }

  // /services/v2/payment-gateway/credit-card/installment
  async installment(input: IPaymentGateway.InstallmentInput) {
    const model = {
      ordercode: input.ordercode,
      return_url: input.return_url,
    }
    if (input.installment) {
      model['installment'] = input.installment
    }

    return this.http
      .post<IPaymentGateway.InstallmentResponse>('/services/v2/payment-gateway/credit-card/installment', model)
      .toPromise()
  }

  async redirect(input: IPaymentGateway.Redirect) {
    const model = {
      ordercode: input.ordercode,
      return_url: input.return_url,
    }

    if (input.card) {
      model['card'] = input.card
    }

    return this.http
      .post<IPaymentGateway.InstallmentResponse>('/services/v2/payment-gateway/credit-card/redirect', model)
      .toPromise()
  }

  async inquiry(input: IPaymentGateway.Inquiry) {
    return this.http.post<any>('/services/v2/payment-gateway/inquiry', input).toPromise()
  }

  async generator_qr(ordercode: string, return_url: string, transaction_no = '') {
    const model = { ordercode: ordercode, return_url: return_url }
    if (transaction_no) {
      model['transaction_no'] = transaction_no
    }

    return this.http.post<any>('/services/v2/payment-gateway/qrcode/generator', model).toPromise()
  }

  async generator_alipay(ordercode: string, return_url: string, transaction_no = '') {
    const model = { ordercode: ordercode, return_url: return_url }
    if (transaction_no) {
      model['transaction_no'] = transaction_no
    }
    return this.http.post<any>('/services/v2/payment-gateway/alipay/pay', model).toPromise()
  }

  async generator_truemoneywallet(ordercode: string, return_url: string, transaction_no = '') {
    const model = { ordercode: ordercode, return_url: return_url }
    if (transaction_no) {
      model['transaction_no'] = transaction_no
    }
    return this.http.post<any>('/services/v2/payment-gateway/truemoneywallet/pay', model).toPromise()
  }

  async salePageToFacebook(input: IPaymentGateway.CreateOrderCredit, ordercode: string, totals: any) {
    try {
      const fb_pixel_product = []
      for (const o of input.model.details) {
        const data = {
          id: o.product_id,
          quantity: o.qty,
        }
        fb_pixel_product.push(data)
      }

      const event_name = 'Purchase'
      const custom_data: any = {
        order_id: ordercode,
        value: `${totals}`,
        currency: 'THB',
        contents: fb_pixel_product,
        content_type: 'product',
        content_name: 'PurchaseSalePage',
      }
      const fb_event_data = {}
      if (this.getCookie('_fbp')) {
        fb_event_data['fbp'] = this.getCookie('_fbp')
      }
      if (this.getCookie('_fbc')) {
        fb_event_data['fbc'] = this.getCookie('_fbc')
      }
      await this.fb_events({
        ...fb_event_data,
        action_source: 'website',
        events: [
          {
            event_name: event_name,
            event_url: location.href,
            custom_data: custom_data,
          },
        ],
      })
        .then(() => {})
        .catch(() => {})
      fbq('track', event_name, custom_data)
    } catch (err) {}

    try{
      const properties:any = {
        order_id:  ordercode,
        currency: "THB",
        value: Number(totals),
      }
      this.tiktok_events({
        event_source: "web",
        data: [{
          event: "PlaceAnOrder",
          page: {
            url: location.href
          },
          properties: properties
        }]
      }).catch((err)=>{console.log(err)})
    }catch(e){

    }
  }

  getCookie(name: string) {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
  }

  tiktok_events(data:any){
    return new Promise((resolve, reject) => {
      if (!IS_TIKTOK_EVENT_API) {
        return resolve(true)
      }
      this.http
        .post('/services/business/conversion/events/tiktok', data)
        .toPromise()
        .then((res: any) => {
          if (!res.status) {
            IS_TIKTOK_EVENT_API = false
          }
          resolve(res)
        })
        .catch((err) => reject(err))
    })
  }

  fb_events(data: any) {
    return new Promise((resolve, reject) => {
      if (!IS_FB_EVENT_API) {
        return resolve(true)
      }
      this.http
        .post('/services/business/conversion/events/facebook', data)
        .toPromise()
        .then((res: any) => {
          if (!res.status) {
            IS_FB_EVENT_API = false
          }
          resolve(res)
        })
        .catch((err) => reject(err))
    })
  }

  check_slip(id: number) {
    return this.http.get(`/services/payment-gateway/slip/verify/${id}`).toPromise()
  }

  createConfirmpaymentFrontEnd(model: IPaymentGateway.Confirm_PaymentType) {
    return this.http.post(`/services/payment-gateway/slip/confirmpaymentFrontend`, { model }).toPromise()
  }
  createConfirmpaymentBackEnd(model: IPaymentGateway.Confirm_PaymentType) {
    return this.http.post(`/services/payment-gateway/slip/confirmpaymentBackend`, { model }).toPromise()
  }

  search(model: any) {
    return this.http.post(`/services/payment-gateway/creditslip/search`, { model }).toPromise()
  }

  payment_rate(input: { warehouse_id: number }) {
    const param = new URLSearchParams()
    if (input.warehouse_id) {
      param.append('warehouse_id', input.warehouse_id.toString())
    }
    const param_str = param.toString()
    return this.http
      .get(`/services/payment-gateway/credit_cards/rate${param_str ? `?${param_str}` : ''}`)
      .toPromise() as Promise<{ [type: string]: IPaymentGateway.PaymentRate }>
  }
}
