import { Pipe, PipeTransform } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { Etc } from '@ketshopweb/ui/core/services/etc.service'
import { Subscription } from 'rxjs'

@Pipe({
  name: 'ketLangs',
})
export class KetLangsPipe implements PipeTransform {
  PageLink: any[]

  _subscribe: Subscription = null

  constructor(
    private store: Store<any>,
    private _etc: Etc,
  ) {
    if (!this._subscribe) {
      this._subscribe = this.store.pipe(select('app_store', 'PAGE_LINK')).subscribe((res: any[]) => {
        this.PageLink = res
      })
    }
  }

  transform(v: any, key: string) {
    // var lang = localStorage.getItem('lang');
    let lang = this._etc.getCurrentLang()
    lang = lang || 'lang1'
    if (typeof v == 'string') {
      const pageLink: any = this.PageLink.find((x) => x.page == v)

      return pageLink ? pageLink[`${key}${lang}`] : key
    } else {
      return v[`${key}${lang}`]
    }
  }
}
