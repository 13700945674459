import { Component, OnInit, Input } from '@angular/core'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzMessageService } from 'ng-zorro-antd/message'
import { UtilService } from '@cms/services/util.service'
import { ProductsService } from '@cms/services/products.service'
import { FrontsService } from '@cms/services/fronts.service'
import { cloneDeep } from 'lodash'
import { IKetshopwebFrontendService, KetshopwebFrontendService } from '@cms/services/ketshopweb-frontend.service'
import { BlogsService } from '@cms/services/blogs.service'
@Component({
  selector: 'app-modal-edit-menu',
  templateUrl: './modal-edit-menu.component.html',
  styleUrls: ['./modal-edit-menu.component.css'],
})
export class ModalEditMenuComponent implements OnInit {
  @Input() data: any

  constructor(
    private modal: NzModalRef,
    private message: NzMessageService,
    private Service_Util: UtilService,
    private Service_Product: ProductsService,
    private Service_Front: FrontsService,
    private ketshopwebFrontendService: KetshopwebFrontendService,
    private blogsService: BlogsService,
  ) {}
  lang: any = []
  editmenu: any = {}
  allcategory: any = []
  allBlogCategory = [] as any[]
  message_duration = 4000
  menuForCanonical: any[] = []
  readonly menuType = IKetshopwebFrontendService.MenuType

  async ngOnInit() {
    // console.log(this.data)

    this.lang = await this.Service_Util.getLangs()
    if (this.data.page.type === IKetshopwebFrontendService.MenuType.ProductCategory) {
      this.allcategory = await this.Service_Product.productMainCategory()
    }
    if (this.data.page.type === IKetshopwebFrontendService.MenuType.BlogCategory) {
      this.allBlogCategory = (await this.blogsService.allCategory()) as any[]
    }
    this.editmenu = cloneDeep(this.data.page)
    try {
      // Legacy code for old customer
      // this.editmenu['SEO_TITLE_lang1'] = this.editmenu['SEO_TITLE_lang1'] || this.editmenu['SEO_TITLE']
      // this.editmenu['SEO_KEYWORD_lang1'] = this.editmenu['SEO_KEYWORD_lang1'] || this.editmenu['SEO_KEYWORD']
      // this.editmenu['SEO_DES_lang1'] = this.editmenu['SEO_DES_lang1'] || this.editmenu['SEO_DES']
      // this.editmenu['SEO_IMAGE_lang1'] = this.editmenu['SEO_IMAGE_lang1'] || this.editmenu['SEO_IMAGE']

      console.log('SEO_DATA', this.editmenu)
    } catch (err) {}
    // console.log(this.editmenu);
    //console.log(this.editmenu)
    if (!this.checkURLImage(`${this.editmenu.SEO_IMAGE}`)) {
      this.editmenu.SEO_IMAGE = null
    }

    if (!this.editmenu.canonical_type) {
      this.editmenu.canonical_type = 'page_id'
    }

    for (const _lang of this.lang) {
      if (!this.checkURLImage(`${this.editmenu[`SEO_IMAGE_${_lang.ref}`]}`)) {
        this.editmenu[`SEO_IMAGE_${_lang.ref}`] = null
      }
    }

    // console.log(this.editmenu)

    const _menuForCanonical: any = await this.Service_Front.getMenuForCanonical()
    this.menuForCanonical = _menuForCanonical
  }

  checkURLImage(url: string) {
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null
  }

  checkKeyword(lang = 'lang1') {
    const s: any[] = `${this.editmenu[`SEO_KEYWORD_${lang}`]}`.split(',')
    for (const i in s) {
      if (!`${s[i]}`.trim()) {
        s.splice(Number(i), 1)
      }
    }
    return s.length
  }

  async ok() {
    for (let i = 0; i < this.lang.length; i++) {
      if (this.editmenu[this.lang[i].ref] == '' || this.editmenu[this.lang[i].ref] == undefined) {
        this.message.error('Please fill Menu name (' + this.lang[i].name + ') ', { nzDuration: this.message_duration })
        return false
      } else if (/[\\\/*"%#?']/g.test(this.editmenu[this.lang[i].ref])) {
        // alert('Menu name ('+$scope.languages[i].name+') have invalid character \\ / * " % # \' ');
        this.message.error('Menu name (' + this.lang[i].name + ') have invalid character \\ / * " % # ? \' ', {
          nzDuration: this.message_duration,
        })
        return false
      }
    }

    try {
      // await this.Service_Front.updateMenuName(this.editmenu);
      await this.ketshopwebFrontendService.updateMenuName(this.editmenu)
      this.modal.close(true)
    } catch (e) {
      this.message.error(e.error.message, { nzDuration: this.message_duration })
    }
  }
}
