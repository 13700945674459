import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { SettingService } from '@cms/services/setting.service'
import { apiError } from '@cms/interface/interface'
import { FeatureService } from '@cms/services/feature.service'
import { cloneDeep, merge } from 'lodash'
import { NzModalService } from 'ng-zorro-antd/modal'
import { KetProductDisplaySortComponent } from '@ketshopweb/ui/product-display-sort'
import { IProductSetting } from '@cms/types'
import { KetshopwebFrontendService } from '@cms/services/ketshopweb-frontend.service'
import { PluginsService } from '@cms/services/plugins.service'
import { NgStoreCore } from '@store/ketshopweb-store'
import { Store } from '@ngrx/store'
import { selectors_features } from 'libs/ketshopweb-store/+state/features'
import { style } from '@angular/animations'

@Component({
  selector: 'app-product-display',
  templateUrl: './product-display.component.html',
  styleUrls: ['./product-display.component.css'],
})
export class ProductDisplayComponent implements OnInit, AfterViewInit {
  avatarUrl: string
  product_varaint_img = {
    status: true,
    property: 1,
  }

  displayList = [
    {
      id: 1,
      desc: 'สินค้าที่มีหมวดหมู่หลายชั้น',
      img: '/system/core-imgs/product-display-tmp/product-tmp-01.jpg',
    },
    {
      id: 2,
      desc: 'สินค้าที่มีหลากหลายหมวดหมู่',
      img: '/system/core-imgs/product-display-tmp/product-tmp-02.jpg',
    },
    {
      id: 3,
      desc: 'ต้องการแสดงหมวดหมู่ให้เด่น',
      img: '/system/core-imgs/product-display-tmp/product-tmp-03.jpg',
    },
    {
      id: 4,
      desc: 'สินค้าที่มีการกรองหลายระดับ',
      img: '/system/core-imgs/product-display-tmp/product-tmp-04.jpg',
    },
    {
      id: 5,
      desc: 'สินค้าที่ไม่มีหมวดหมู่',
      img: '/system/core-imgs/product-display-tmp/product-tmp-05.jpg',
    },
    {
      id: 6,
      desc: 'สินค้าที่มีจำนวนมาก หมวดหมู่ไม่ซับซ้อน',
      img: '/system/core-imgs/product-display-tmp/product-tmp-06.jpg',
    },
  ]

  displayListModel = 1
  modal_edit = false
  catalog_template_setting = 1
  product_img_class = 'horizontal'
  product_default_filter = 1
  product_blog_title_font = ''
  product_display_item_perpage = 12
  displayListPreview: any
  select_font_render = false
  options: any[] = []
  default_no_image = '/assets/images/core-imgs/ex_imgBg_df.png'
  feature: any = false
  product_list_font_size = 16
  catalog_social_share_setting = false
  show_product_outstock = false
  ecommerce_setting = 0
  catalog_contact_setting = false
  product_details_gallery_pos = 'bottom'
  product_details_short_desc_pos = 'upper'
  price_setting = 0
  sku_setting = false
  quote_setting = false
  weight_setting = false
  add_to_cart_now = false
  product_details_prop_style = 'dropdown'
  show_uncategory = true

  add_to_cart_now_setting: any = {
    format: 'icon',
    align: 'right',
    font: '',
  }

  button: any = {
    backgroundcolor: '#525e68',
    backgroundcolorhover: '#fff',
    border: '2',
    bordercolor: '#525e68',
    bordercolorhover: '#525e68',
    borderhover: '2',
    borderradius: '9',
    borderradiushover: '9',
    fontcolor: '#fff',
    fontcolorhover: '#525e68',
  }
  ecommerce: any = {
    backtotopbackgroundcolor: '#fac769',
    backtotopfontcolor: '#a66900',
    backtotophover: '#fac769',
    cartbuttoncolor: '#fac769',
    cartbuttonhover: '#fac769',
    menucarthover: '#cccccc',
    cartbuttonfontcolor: '#fff',
    cartbuttonbgfontcolor: '#000',
  }

  setting: IProductSetting.Setting
  _theme_color: any

  load_setting = {
    status: true,
    mobile: false,
    desktop: false,
  }
  product_img_crop = false

  // get document by id bodyContentBox
  @ViewChild('bodyContentBox', {
    static: true,
  })
  bodyContentBox: HTMLElement
  wishlist_setting = {
    status: false,
  }
  feature_wishlist = false
  product_font_bold = 0
  price_font_bold = 0
  limit_product_names_display = {
    status: false,
    limite: 1,
  }
  is_sandclockbooks = false
  show_stock = false
  button_contect = 2
  buttonStyle = 'flat'
  button_contact_position = 'left'
  button_contact_radius_type = 'px'
  price_style = {
    position: 'inside',
    font: '',
    font_size: 16,
    font_bold: true,
    font_color: '#000000',
  }
  button_contact_style = {
    style: 'flat',
    grid: 1,
    radius: 20,
    position: 'center',
    quotation: {
      image_icon: '',
      image_custom: '',
      title: 'ขอใบเสนอราคา',
      description: 'ส่งคำขอ',
    },
    email: {
      image_icon: '',
      image_custom: '',
      title: 'สอบถามเพิ่มเติม',
      description: 'ส่งอีเมล',
    },
    line: {
      image_icon: '',
      image_custom: '',
      title: 'สอบถามเพิ่มเติม',
      description: 'คลิกเพื่อแอดไลน์ได้เลยค่ะ',
    },
    messenger: {
      image_icon: '',
      image_custom: '',
      title: 'สอบถามเพิ่มเติม',
      description: 'คลิกเพื่อพูดคุยผ่าน Messenger',
    },
  }

  constructor(
    private _setting: SettingService,
    private Service_Feature: FeatureService,
    private modalService: NzModalService,
    private ketshopwebFrontendService: KetshopwebFrontendService,
    private pluginsService: PluginsService,
    private store: Store<{ app_store: any }>,
  ) {
    this.setting = this.ketshopwebFrontendService.getDefaultProductOption()
  }

  async ngOnInit() {
    let is_system = false
    if (location.pathname.includes('/system')) {
      is_system = true
    }
    const option = this.pluginsService.getSharePluginDataByKey('product-display-options')
    if (option) {
      this.displayList.push(...option)
    }

    if (['sandclockbooks.com', 'sandclockbooks.ketshoptest.com'].includes(location.host)) {
      this.is_sandclockbooks = true
      this.displayList.push({
        id: 9,
        desc: 'เทมเพลตสำหรับ SandClock',
        img: '/assets/images/private-shop/sandclockbooks/product-blog-tmt-07-custom.svg',
      })
    }

    this.feature = await this.Service_Feature.getFeature('PRODUCT_PAGE')
    if (!this.feature) {
      return
    }
    const feature_wishlist = await new NgStoreCore(this.store).storeQuery(selectors_features.wishlist)
    if (feature_wishlist && Number(feature_wishlist.value)) {
      this.feature_wishlist = true
    }

    this.default_no_image = localStorage.getItem('default_no_image')
    this.ketshopwebFrontendService
      .searchOption(
        {
          pop_key: [
            'product_img_class',
            'catalog_template_setting',
            'product_default_filter',
            'product_blog_title_font',
            'product_display_item_perpage',
            'product_list_font_size',
            'product_setting',
            'add_to_cart_now',
            'catalog_social_share_setting',
            'show_product_outstock',
            'ecommerce_setting',
            'catalog_contact_setting',
            'price_setting',
            'sku_setting',
            'weight_setting',
            'theme_value',
            'add_to_cart_now_setting',
            'product_details_prop_style',
            'sale_tag_display',
            'product_details_gallery_pos',
            'product_details_short_desc_pos',
            'product_img_crop',
            'show_uncategory',
            'wishlist_setting',
            'product_varaint_img',
            'product_font_bold',
            'price_font_bold',
            'limit_product_names_display',
            'show_stock',
            'quote_setting',
            'price_style',
            'button_contact_style',
          ],
        },
        is_system,
      )
      .then((res: any[]) => {
        console.log(res)
        this.options = res
        for (const obj of res) {
          //console.log(obj)

          switch (obj.pop_key) {
            case 'add_to_cart_now_setting':
              {
                let add_to_cart_now_setting = null
                try {
                  add_to_cart_now_setting = JSON.parse(obj.pop_value)
                  if (!add_to_cart_now_setting.hasOwnProperty('font')) {
                    add_to_cart_now_setting.font = ''
                  }
                  // console.log('add_to_cart_now_setting', add_to_cart_now_setting)
                } catch (err) {}
                if (!add_to_cart_now_setting || typeof add_to_cart_now_setting !== 'object') {
                  add_to_cart_now_setting = this.add_to_cart_now_setting
                }
                this.add_to_cart_now_setting = add_to_cart_now_setting
              }
              break
            case 'add_to_cart_now':
              this.add_to_cart_now = Number(obj.pop_value) ? true : false
              break
            case 'weight_setting':
              this.weight_setting = Boolean(Number(obj.pop_value))
              break
            case 'sku_setting':
              this.sku_setting = Boolean(Number(obj.pop_value))
              break
            case 'quote_setting':
              this.quote_setting = Boolean(Number(obj.pop_value))
              break
            case 'price_setting':
              this.price_setting = Number(obj.pop_value)
              break
            case 'catalog_social_share_setting':
              this.catalog_social_share_setting = Boolean(Number(obj.pop_value))
              break
            case 'show_product_outstock':
              this.show_product_outstock = Boolean(Number(obj.pop_value))
              break
            case 'ecommerce_setting':
              this.ecommerce_setting = Number(obj.pop_value)
              break
            case 'catalog_contact_setting':
              this.catalog_contact_setting = Boolean(Number(obj.pop_value))
              break
            case 'catalog_template_setting':
              this.catalog_template_setting = Number(obj.pop_value)
              this.displayListPreview = this.displayList.find((x) => x.id == this.catalog_template_setting)
              break
            case 'product_img_class':
              this.product_img_class = obj.pop_value
              break
            case 'product_default_filter':
              this.product_default_filter = Number(obj.pop_value)
              break
            case 'product_blog_title_font':
              this.product_blog_title_font = obj.pop_value
              this.select_font_render = true
              break
            case 'product_display_item_perpage':
              this.product_display_item_perpage = Number(obj.pop_value)
              break
            case 'product_list_font_size':
              this.product_list_font_size = Number(obj.pop_value)
              break
            case 'product_setting':
              {
                //console.log(obj.pop_value)
                let _data = obj.pop_value
                try {
                  _data = JSON.parse(_data)
                } catch (err) {}
                //console.log(typeof _data)
                if (typeof _data == 'object') {
                  //console.log(obj.pop_value)
                  if (_data) this.setting = merge(this.setting, _data)
                }

                if (!this.setting.hasOwnProperty('promo_point_display')) {
                  this.setting['promo_point_display'] = {
                    type: '1',
                    size: '',
                  }
                }
                if (!this.setting.hasOwnProperty('messenger_button')) {
                  this.setting['messenger_button'] = {
                    link: '',
                    status: false,
                  }
                }

                if (!this.setting.hasOwnProperty('line_button')) {
                  this.setting['line_button'] = {
                    link: '',
                    status: false,
                  }
                }

                if (!this.setting.hasOwnProperty('load_more')) {
                  this.setting['load_more'] = '0'
                }

                if (Number(this.setting['load_more']) == 0) {
                  this.load_setting.desktop = false
                  this.load_setting.mobile = false
                  this.load_setting.status = false
                } else if (Number(this.setting['load_more']) == 1) {
                  this.load_setting.desktop = false
                  this.load_setting.mobile = true
                  this.load_setting.status = true
                } else if (Number(this.setting['load_more']) == 2) {
                  this.load_setting.desktop = true
                  this.load_setting.mobile = false
                  this.load_setting.status = true
                } else if (Number(this.setting['load_more']) == 3) {
                  this.load_setting.desktop = true
                  this.load_setting.mobile = true
                  this.load_setting.status = true
                }
              }
              break
            case 'theme_value':
              this._theme_color = obj.pop_value

              try {
                const _data = JSON.parse(obj.pop_value)
                //console.log(_data);
                window['_theme_color'] = _data
              } catch (e) {
                eval(`${obj.pop_value} window._theme_color = _theme_color;`)
              }
              setTimeout(() => {
                try {
                  this.button = merge(this.button, cloneDeep(window['_theme_color']['button']))
                  this.ecommerce = merge(this.ecommerce, cloneDeep(window['_theme_color']['ecommerce']))
                } catch (err) {}
              }, 100)
              break
            case 'product_details_prop_style':
              this.product_details_prop_style = obj.pop_value
              break
            case 'product_details_gallery_pos':
              this.product_details_gallery_pos = obj.pop_value
              break
            case 'product_details_short_desc_pos':
              this.product_details_short_desc_pos = obj.pop_value
              break
            case 'product_img_crop':
              this.product_img_crop = Number(obj.pop_value) ? true : false
              break
            case 'show_uncategory':
              this.show_uncategory = Number(obj.pop_value) ? true : false
              break

            case 'wishlist_setting':
              try {
                this.wishlist_setting = JSON.parse(obj.pop_value)
              } catch (err) {
                console.error(err)
              }
              break

            case 'show_stock':
              try {
                this.show_stock = JSON.parse(obj.pop_value)
              } catch (err) {
                console.error(err)
              }
              break
            case 'product_varaint_img':
              try {
                this.product_varaint_img = JSON.parse(obj.pop_value)
              } catch (err) {
                console.error(err)
              }
              break
            case 'product_font_bold':
              this.product_font_bold = obj.pop_value
              break
            case 'price_font_bold':
              this.price_font_bold = obj.pop_value
              break
            case 'limit_product_names_display':
              this.limit_product_names_display = JSON.parse(obj.pop_value)
              break
            case 'price_style':
              try {
                this.price_style = JSON.parse(obj.pop_value)
              } catch (err) {
                console.error(err)
              }
              break
            case 'button_contact_style':
              try {
                this.button_contact_style = JSON.parse(obj.pop_value)
                console.log('button_contact_style', this.button_contact_style)
              } catch (err) {
                console.error(err)
              }
              break
            default:
              break
          }
        }
      })
      .catch((e: apiError) => {
        console.log(e.error.message)
      })
    console.log(this.setting)
  }

  ngAfterViewInit(): void {
    // timeout to wait for the DOM to be rendered
    setTimeout(() => {
      this.bodyContentBox = document.getElementById('bodyContentBox')
      console.log('bodyContentBox', this.bodyContentBox)
    }, 1)
  }

  openModal() {
    this.displayListModel = this.catalog_template_setting
    this.modal_edit = true
  }

  handleOk() {
    this.catalog_template_setting = this.displayListModel
    this.displayListPreview = this.displayList.find((x) => x.id == this.catalog_template_setting)
    this.modal_edit = false
  }

  handleCancel() {
    this.modal_edit = false
  }

  save() {
    for (const obj of this.options) {
      switch (obj.pop_key) {
        case 'add_to_cart_now_setting':
          obj.pop_value = JSON.stringify(this.add_to_cart_now_setting)
          break
        case 'add_to_cart_now':
          obj.pop_value = this.add_to_cart_now ? 1 : 0
          break
        case 'catalog_template_setting':
          obj.pop_value = this.catalog_template_setting
          break
        case 'product_img_class':
          obj.pop_value = this.product_img_class
          break
        case 'product_default_filter':
          obj.pop_value = this.product_default_filter
          break
        case 'product_blog_title_font':
          obj.pop_value = this.product_blog_title_font
          break
        case 'product_display_item_perpage':
          obj.pop_value = this.product_display_item_perpage
          break
        case 'product_list_font_size':
          obj.pop_value = this.product_list_font_size
          break
        case 'product_setting':
          if (this.load_setting.status) {
            if (this.load_setting.mobile) {
              this.setting['load_more'] = 1
            } else if (this.load_setting.desktop) {
              this.setting['load_more'] = 2
            } else {
              this.setting['load_more'] = 0
            }

            if (this.load_setting.mobile && this.load_setting.desktop) {
              this.setting['load_more'] = 3
            }
          } else {
            this.setting['load_more'] = 0
          }
          obj.pop_value = JSON.stringify(this.setting)
          break
        case 'catalog_social_share_setting':
          obj.pop_value = this.catalog_social_share_setting
          break
        case 'show_product_outstock':
          obj.pop_value = this.show_product_outstock
          break
        case 'ecommerce_setting':
          obj.pop_value = this.ecommerce_setting
          break
        case 'price_setting':
          obj.pop_value = this.price_setting
          break
        case 'sku_setting':
          obj.pop_value = this.sku_setting
          break
        case 'quote_setting':
        obj.pop_value = this.quote_setting
          break
        case 'weight_setting':
          obj.pop_value = this.weight_setting
          break
        case 'catalog_contact_setting':
          obj.pop_value = this.catalog_contact_setting
          break
        case 'product_details_prop_style':
          obj.pop_value = this.product_details_prop_style
          break
        case 'product_details_gallery_pos':
          obj.pop_value = this.product_details_gallery_pos
          break
        case 'product_details_short_desc_pos':
          obj.pop_value = this.product_details_short_desc_pos
          break
        case 'product_img_crop':
          obj.pop_value = this.product_img_crop
          break
        case 'show_uncategory':
          obj.pop_value = this.show_uncategory ? 1 : 0
          break
        case 'wishlist_setting':
          obj.pop_value = JSON.stringify(this.wishlist_setting)
          break
        case 'show_stock':
          obj.pop_value = JSON.stringify(this.show_stock)
          break
        case 'product_varaint_img':
          obj.pop_value = JSON.stringify(this.product_varaint_img)
          break
        case 'product_font_bold':
          obj.pop_value = this.product_font_bold
          break
        case 'price_font_bold':
          obj.pop_value = this.price_font_bold
          break
        case 'limit_product_names_display':
          obj.pop_value = JSON.stringify(this.limit_product_names_display)
          break
        case 'price_style':
          obj.pop_value = JSON.stringify(this.price_style)
          console
          break
        case 'button_contact_style':
          obj.pop_value = JSON.stringify(this.button_contact_style)
          console.log('button_contact_style', obj.pop_value)
          break
        default:
          break
      }
    }

    $('#preloadwraper').fadeIn()
    this._setting
      .saveOption(this.options)
      .then((res) => {
        $('#preloadwraper').fadeOut()
        this.ngOnInit()
        // var url = decodeURI(this.router.url);
        // this.router.navigated = false;
        // this.router.navigate(['/system']).then(()=>this.router.navigate([url]));
      })
      .catch((e: apiError) => {
        $('#preloadwraper').fadeOut()
        console.log(e)
      })
  }

  genStyle() {
    const s = `
        <style>
            .tmt-btn-all{
              background-color: ${this.button.backgroundcolor};
              color:  ${this.button.fontcolor};
              border: solid  ${this.button.border}px;
              border-color:  ${this.button.bordercolor};
              border-radius: ${this.button.borderradius}px;
            }
            .tmt-btn-all:hover{
              background-color: ${this.button.backgroundcolorhover};
              color:  ${this.button.fontcolorhover};
              border: solid  ${this.button.borderhover}px;
              border-color:  ${this.button.bordercolorhover};
              border-radius: ${this.button.borderradiushover}px;
            }

            .tmt-btn-all:focus{
              background-color: ${this.button.backgroundcolor};
              color:  ${this.button.fontcolor};
              border: solid  ${this.button.border}px;
              border-color:  ${this.button.bordercolor};
              border-radius: ${this.button.borderradius}px;
            }

            .tmt-btn-all.hover{
              background-color: ${this.button.backgroundcolorhover};
              color:  ${this.button.fontcolorhover};
              border: solid  ${this.button.borderhover}px;
              border-color:  ${this.button.bordercolorhover};
              border-radius: ${this.button.borderradiushover}px;
            }

            .static-bag{
                background-color: ${this.ecommerce.cartbuttoncolor};
            }
            .static-bag:hover{
                background-color: ${this.ecommerce.cartbuttonhover};
            }
            .static-top{
                background-color: ${this.ecommerce.backtotopbackgroundcolor};
            }
            .static-top i{
                color: ${this.ecommerce.backtotopfontcolor};
            }
            .static-top:hover{
                background-color: ${this.ecommerce.backtotophover};
            }

            .static-bag .number-on-bag-float{
              background-color: ${this.ecommerce.cartbuttonbgfontcolor};
              color: ${this.ecommerce.cartbuttonfontcolor};
            }

        </style>
    `

    return s
  }

  log() {
    console.log(this.setting)
  }

  open_pdSort() {
    const modal = this.modalService
      .create<KetProductDisplaySortComponent>({
        nzTitle: 'กำหนดลำดับสินค้า',
        nzFooter: null,
        nzWidth: '1000px',
        nzStyle: { top: '40px' },
        nzMaskClosable: false,
        nzContent: KetProductDisplaySortComponent,
      })
      .afterClose.subscribe((result) => {
        modal.unsubscribe()
      })
  }
}
