import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetProductFilterComponent } from './product-filter.component'
import { FormsModule } from '@angular/forms'
import { KetTranslateModule } from '../translate'
import { KetLangsModule } from '../langs'
import { KetPathLangModule } from '../path-lang'
import { RouterModule } from '@angular/router'
import { NzSliderModule } from 'ng-zorro-antd/slider'

@NgModule({
  declarations: [KetProductFilterComponent],
  entryComponents: [KetProductFilterComponent],
  imports: [
    CommonModule,
    FormsModule,
    KetTranslateModule,
    KetLangsModule,
    KetPathLangModule,
    RouterModule,
    NzSliderModule,
  ],
  exports: [KetProductFilterComponent],
})
export class KetProductFilterModule {}
