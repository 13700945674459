// import { Injectable } from '@angular/core'
import { CmsFrontendService as FrontendService } from './frontend.service'
import { CmsProductsService as ProductsService } from './products.service'
// import { HttpClient } from '@angular/common/http'
import { CmsBlogsService as BlogsService } from './blogs.service'

// export namespace Sandclockbooks {
//   @Injectable({
//     providedIn: 'root',
//   })
//   export class FrontendService extends CmsFrontendService { }

//   @Injectable({
//     providedIn: 'root',
//   })
//   export class ProductsService extends CmsProductsService {}

//   @Injectable({
//     providedIn: 'root',
//   })
//   export class BlogsService extends CmsBlogsService {}
// }

// export const Sandclockbooks = {
//   FrontendService: CmsFrontendService,
//   ProductsService: CmsProductsService,
//   BlogsService: CmsBlogsService
// }

export { FrontendService, ProductsService, BlogsService }
