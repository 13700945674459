import { cloneDeep } from 'lodash'
import { h, hydrate, VNode } from 'preact'

export interface IProductPrintLabel {
  init(): IProductPrintLabel
  container_vnode: VNode[]
  render(title: string): string
  page_vnode: VNode
  page(): {
    add_item: (
      type: 'qrcode' | 'barcode',
      props: {
        title: string
        price: string
        img: string
      },
    ) => void
  }
}

export const product_export_label_a4: IProductPrintLabel = {
  init() {
    this.container_vnode = []
    return this as IProductPrintLabel
  },
  container_vnode: [] as any[],
  render(title: string) {
    const ctx = document.createElement('body')
    hydrate(this.container_vnode, ctx)
    return `<!DOCTYPE html>
    <html>
      <head>
        <meta charset="UTF-8">
        <title>${title}</title>
        ${product_export_label_a4_css}
      </head>
      ${ctx.outerHTML}
      <script>
         html2canvas(document.querySelector("html")).then(canvas => {
          // document.body.appendChild(canvas)
          // download canvas image
          let link = document.createElement('a');
          link.download = '${title}.png';
          link.href = canvas.toDataURL()
          link.click();
          link.remove();
        });
      </script>
    </html>`
  },
  page_vnode: (
    <div class={'page-print-a4'}>
      <div class={'grid-container'}></div>
    </div>
  ),
  page() {
    const page = cloneDeep(this.page_vnode) as VNode
    const ctx = page.props.children['props']
    ctx.children = []
    this.container_vnode.push(page)
    return {
      add_item: (
        type: 'qrcode' | 'barcode',
        props: {
          title: string
          price: string
          img: string
        },
      ) => {
        ctx.children.push(
          <div class={'sticker ' + type}>
            <div class={'text-truncate'}>{props.title}</div>
            <img src={props.img} />
            <div class={'text-truncate'}>{props.price}</div>
          </div>,
        )
      },
    }
  },
}
const product_export_label_a4_css = `<style>
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
@page {
  size: A4;
  padding: 0;
  margin: 0;
}
html,
body{
  width: 100%;
  height: 100%;
  font-family: 'Arial', sans-serif;
}
.page-print-a4{
  width: 210mm;
  height: 297mm;
  padding: 10mm;
  margin: 0 auto;
  page-break-after: always;
}
.page-print-a4 .grid-container{
  width: 100%;
  height: 100%;
  display: grid;
  display: -ms-grid;
  grid-template-columns: repeat(3, 33.333%);
  grid-template-rows: repeat(10, 10%);
  justify-content: center;
}
.page-print-a4 .sticker{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid #000;
  border-top: 0;
}
.page-print-a4 .sticker:nth-child(1),
.page-print-a4 .sticker:nth-child(2),
.page-print-a4 .sticker:nth-child(3){
  border-top: 1px solid #000;
}
.page-print-a4 .sticker:nth-child(3n - 1),
.page-print-a4 .sticker:nth-child(3n){
  border-left: 0;
}
.page-print-a4 .sticker .text-truncate{
  width: 60mm;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 7pt;
  line-height: 120%;
}
.page-print-a4 .sticker img{
  display: block;
  object-fit: contain;
}
.page-print-a4 .sticker.qrcode img{
  width: 16mm;
  height: 16mm;
  margin: 1mm 0;
}
.page-print-a4 .sticker.barcode img{
  width: 60mm;
  height: 18mm;
  margin: 0;
}
@media print{
  body{
    margin: 0;
    padding: 0;
  }
  .page-print-a4{
    width: 100%;
    height: 100%;
    margin: 0;
  }
}
</style>`
