import { Component, OnInit, Input } from '@angular/core'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal'
import { cloneDeep } from 'lodash'
import { ProductsService } from '@cms/services/products.service'
import { ExtensionService } from '@cms/services/extension.service'
import { ModalDeleteLazadaProductComponent } from '../modal-delete-lazada-product/modal-delete-lazada-product.component'

declare let alasql: any

@Component({
  selector: 'app-modal-edit-lazada-product',
  templateUrl: './modal-edit-lazada-product.component.html',
  styleUrls: ['./modal-edit-lazada-product.component.css'],
})
export class ModalEditLazadaProductComponent implements OnInit {
  @Input() data: any

  constructor(
    private Service_Extension: ExtensionService,
    private modalService: NzModalService,
    private modalRef: NzModalRef,
  ) {}

  model: any = { id: '', stock: 0, status: '', price: 0 }
  available_stock = 0
  ketshop_product: any = {}
  lazada_product: any = {}
  ngOnInit() {
    // console.log(this.data.item);
    this.byID()
  }

  byID() {
    this.Service_Extension.getProductLazadaByID(this.data.item.id)
      .then((res: any) => {
        console.log(res)

        this.model.id = this.data.item.id
        this.model.stock = res.lazada_product.quantity
        this.model.price = res.lazada_product.price
        this.model.status = res.lazada_product.status

        this.lazada_product = res.lazada_product

        this.ketshop_product = res.ketshop_product
        this.available_stock = this.ketshop_product.instock + this.model.stock
      })
      .catch((data: any) => {})
  }
  delete() {
    const modal = this.modalService
      .create({
        nzTitle: 'ลบสินค้า Lazada',
        nzContent: ModalDeleteLazadaProductComponent,
        nzStyle: { top: '20px' },
        nzWidth: '30%',
        nzClassName: 'modal-medium',
        nzKeyboard: false,
        nzMaskClosable: false,
        nzFooter: null,
        nzOnOk: (componentInstance) => {
          return componentInstance.ok()
        },
        nzOnCancel: (componentInstance) => {},
        nzComponentParams: {
          data: this.lazada_product,
        },
      })
      .afterClose.subscribe((result) => {
        modal.unsubscribe()
        if (result) {
          this.modalRef.close(true)
        }
      })
  }

  ok() {
    $('.theme-loader').fadeIn()
    this.Service_Extension.updateProductLazada({ model: this.model })
      .then((res: any) => {
        $('.theme-loader').fadeOut()
        this.modalRef.close(true)
        console.log(res)
      })
      .catch((data: any) => {
        this.modalService.error({
          nzTitle: 'Error',
          nzContent: data.error.message,
          nzOnOk: () => {},
        })
        $('.theme-loader').fadeOut()
      })

    return false
  }
}
