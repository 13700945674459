import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetConsentFormComponent } from './consent-form.component'
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox'
import { FormsModule } from '@angular/forms'
import { KetLangsModule } from '../langs'
import { KetSanitizeHtmlModule } from '../sanitize-html'

@NgModule({
  declarations: [KetConsentFormComponent],
  imports: [CommonModule, FormsModule, NzCheckboxModule, KetLangsModule, KetSanitizeHtmlModule],
  exports: [KetConsentFormComponent],
  entryComponents: [KetConsentFormComponent],
})
export class KetConsentFormModule {}
