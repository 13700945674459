import { NzCheckboxModule } from 'ng-zorro-antd/checkbox'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetChannelsSelectorComponent } from './channels-selector.component'
import { FormsModule } from '@angular/forms'
import {
  NzAvatarModule,
  NzIconModule,
  NzInputModule,
  NzPaginationModule,
  NzSelectModule,
  NzTableModule,
  NzTagModule,
} from 'ng-zorro-antd'

@NgModule({
  declarations: [KetChannelsSelectorComponent],
  imports: [
    CommonModule,
    FormsModule,
    NzCheckboxModule,
    NzInputModule,
    NzSelectModule,
    NzAvatarModule,
    NzIconModule,
    NzPaginationModule,
    NzTableModule,
    NzTagModule,
  ],
  exports: [KetChannelsSelectorComponent],
})
export class KetChannelsSelectorModule {}
