import { createAction, props } from '@ngrx/store'
import { IChannelsState } from './models'

export const actions_channels = {
  LoadChannel: createAction<string>('[CMS][Orders/API] LoadChannel'),
  ClearChannel: createAction<string>('[CMS][Orders/API] ClearChannel'),
  // ClearSellChannel: createAction<string>('[CMS][Orders/API] ClearSellChannel'),
  SetChannel: createAction<string, { channels: IChannelsState.Channel[] }>(
    '[CMS][Orders] SetChannel',
    props<{
      channels: IChannelsState.Channel[]
    }>(),
  ),
  LoadSellChannel: createAction<string>('[CMS][Orders/API] LoadSellChannel'),
  SetSellChannel: createAction<string, { channels: IChannelsState.SellChannel[] }>(
    '[CMS][Orders] SetSellChannel',
    props<{
      channels: IChannelsState.SellChannel[]
    }>(),
  ),
}
