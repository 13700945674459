import { cloneDeep } from 'lodash'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
// import { CookieService } from 'ngx-cookie';
// import { Store } from '@ngrx/store';
// import { SET_PROVINCE, SET_LANGS } from '../store/action';
import * as moment from 'moment'

let FEATURES: any[] = []
@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  private feature: any = null
  private date_time: any
  constructor(private _http: HttpClient) {}

  async allFeature() {
    if (this.feature === null) {
      await this._init()
    }
    return this.feature
  }

  async getFeature(key = null) {
    if (this.feature === null) {
      await this._init()
    }
    if (key) {
      const _f = this.feature.find((x) => x.name == key)
      if (['SALEPAGE', 'IMPORT_VARIANT'].includes(key)) {
        if (!_f) {
          return false
        }
      }
      if (!_f) {
        return true
      }
      if (_f.hasOwnProperty('type') && _f.type) {
        if (_f.type == 'status') {
          if (_f.value == '1') {
            return true
          } else if (_f.value == '0') {
            return false
          }
        } else if (_f.type == 'date') {
          const daypass = moment(this.date_time).diff(moment(_f.value), 'second')
          if (daypass > 0) {
            return false
          } else {
            return true
          }
        }
      } else {
        if (
          _f.name == 'FBCHAT' ||
          _f.name == 'PROMOTION' ||
          _f.name == 'PREMADE' ||
          _f.name == 'BLOG_PAGE' ||
          _f.name == 'PRODUCT_PAGE' ||
          _f.name == 'PREMADE' ||
          _f.name == 'CUSTOM_ELEMENT' ||
          _f.name == 'LINECHAT' ||
          _f.name == 'PROMOTION' ||
          _f.name == 'MEMBER_POINT' ||
          _f.name == 'FREE_ITEM' ||
          _f.name == 'SECTION_BOOTSTRAP' ||
          _f.name == 'OMNICHAT'
        ) {
          if (_f.value == '1') {
            return true
          } else if (_f.value == '0') {
            return false
          } else {
            const daypass = moment(this.date_time).diff(moment(_f.value), 'second')
            if (daypass > 0) {
              return false
            } else {
              return true
            }
          }
        } else {
          if (_f.value == '1') {
            return true
          } else if (_f.value == '0') {
            return false
          }
        }
      }
    }
    return this.feature
  }

  async getFeatureValue(key: string) {
    const targetFeature: any = await this.getAllFeature()
    return targetFeature.find((x) => x.name == key)
  }

  async getFormatDate(key: string) {
    const targetFeature = await this.getFeatureValue(key)
    if (!targetFeature || (targetFeature && !targetFeature.hasOwnProperty('value'))) return ''
    const regex = new RegExp('[0-9]{4}-[0-9]{2}-[0-9]{2}')
    if (!regex.test(targetFeature.value)) return ''
    return moment(targetFeature.value).format('D MMM YYYY')
  }

  // setFeature(obj){
  //     this.feature = obj;
  //     Object.freeze(this.feature);
  // }
  get_feature_process = false
  getAllFeature() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (s, j) => {
      try {
        if (this.get_feature_process) {
          while (this.get_feature_process) {
            if (FEATURES.length) {
              console.log('get feature success', this.get_feature_process)
              this.get_feature_process = false
              s(FEATURES)
              break
            }
            console.log('wait get feature, sleep 10ms')
            await new Promise((r) => setTimeout(r, 10))            
          }
        }
        // console.log('FEATURES.length', FEATURES.length)
        if (!FEATURES.length) {
          this.get_feature_process = true
          this._http.get('/services/v2/ketcms-frontend/utils/feature_config').toPromise().then((r) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            FEATURES = r as any[]
            this.get_feature_process = false
            s(FEATURES)
          }).catch((r) => {
            this.get_feature_process = false
            j(r)
          })
        } else {
          s(FEATURES)
        }
      } catch (err) {
        this._http.get('/services/v2/ketcms-frontend/utils/feature_config').toPromise().then(s).catch(j)
      }
    })
  }

  getDateTime() {
    return new Promise((reslove) => {
      return this._http
        .get('/services/v2/ketcms-frontend/utils/datetime')
        .toPromise()
        .then((res: any) => reslove(res))
        .catch(() => {
          const mm = moment()
          reslove({
            date: mm.format('YYYY-MM-DD'),
            date_string: mm.toString(),
            date_time: mm.format('YYYY-MM-DD HH:mm:ss'),
            time: mm.format('HH:mm:ss'),
          })
        })
    }) as Promise<{ date: string; date_string: string; date_time: string; time: string }>
  }

  async _init() {
    console.log('_init feature ')
    const date: any = await this.getDateTime()
    this.date_time = date.date_time
    const obj = (await this.getAllFeature()) as any[]
    FEATURES = cloneDeep(obj)
    this.feature = obj
    Object.freeze(this.feature)
    console.log(this.feature)
  }
}
