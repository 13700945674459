import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { ITrcloudGateway } from './types/ITrcloudGateway'

@Injectable({
  providedIn: 'root',
})
export class CmsTrcloudGatewayService {
  private http = inject(HttpClient)

  searchQuotation(input: ITrcloudGateway.CommonSearchBody) {
    return this.http.post<{ data: ITrcloudGateway.Quotation[]; count: number }>(
      '/services/trcloud/quotations/search',
      input,
    )
  }

  resendQuotation(id: string) {
    return this.http.post<{ error: boolean; message: string }>(`/services/trcloud/quotations/${id}/resend`, {})
  }

  searchBill(input: ITrcloudGateway.CommonSearchBody) {
    return this.http.post<{ data: ITrcloudGateway.Bill[]; count: number }>('/services/trcloud/bills/search', input)
  }

  resendBill(id: string) {
    return this.http.post<{ error: boolean; message: string }>(`/services/trcloud/bills/${id}/resend`, {})
  }

  searchInvoice(input: ITrcloudGateway.CommonSearchBody) {
    return this.http.post<{ data: ITrcloudGateway.Invoice[]; count: number }>(
      '/services/trcloud/invoices/search',
      input,
    )
  }

  resendInvoice(id: string) {
    return this.http.post<{ error: boolean; message: string }>(`/services/trcloud/invoices/${id}/resend`, {})
  }

  searchReceipt(input: ITrcloudGateway.CommonSearchBody) {
    return this.http.post<{ data: ITrcloudGateway.Invoice[]; count: number }>(
      '/services/trcloud/receipts/search',
      input,
    )
  }

  resendReceipts(id: string) {
    return this.http.post<{ error: boolean; message: string }>(`/services/trcloud/receipts/${id}/resend`, {})
  }

  searchContact(input: ITrcloudGateway.ContactSearchBody) {
    return this.http.post<{ data: ITrcloudGateway.Contact[]; count: number }>(
      '/services/trcloud/contacts/search',
      input,
    )
  }

  searchInventory(input: ITrcloudGateway.InventorySearchBody) {
    return this.http.post<{ data: ITrcloudGateway.Inventory[]; count: number }>(
      '/services/trcloud/inventories/search',
      input,
    )
  }

  searchWarehouse(input: ITrcloudGateway.WarehouseSearchBody) {
    return this.http.post<{ data: ITrcloudGateway.Warehouse[]; count: number }>(
      '/services/trcloud/warehouses/search',
      input,
    )
  }

  searchTrcloudCategory(input: ITrcloudGateway.TrcloudCategorySearchBody) {
    return this.http.post<{ data: ITrcloudGateway.TrcloudCategory[] }>(
      '/services/trcloud/trcloud-categories/search',
      input,
    )
  }

  createAccount(input: ITrcloudGateway.CreateAccountBody) {
    return this.http.post<ITrcloudGateway.Account>('/services/trcloud/accounts/me', input)
  }

  fetchAccount() {
    return this.http.get<ITrcloudGateway.Account>('/services/trcloud/accounts/me')
  }

  updateAccount(input: ITrcloudGateway.CreateAccountBody) {
    return this.http.put<ITrcloudGateway.Account>('/services/trcloud/accounts/me', input)
  }

  deleteAccount() {
    return this.http.delete<{ error: boolean; message: string }>('/services/trcloud/accounts/me')
  }

  getWebhookURLs() {
    return this.http.get<ITrcloudGateway.WebhookURLGroup[]>('/services/trcloud/webhook/urls')
  }

  fetchContactReport() {
    return this.http.get<ITrcloudGateway.SyncContactReport>('/services/trcloud/contacts/sync')
  }

  fetchInventoryReport() {
    return this.http.get<ITrcloudGateway.SyncInventoryReport>('/services/trcloud/inventories/sync')
  }

  fetchWarehouseReport() {
    return this.http.get<ITrcloudGateway.SyncInventoryReport>('/services/trcloud/warehouses/sync')
  }

  syncInventory() {
    return this.http.post<ITrcloudGateway.SyncInventoryReport>('/services/trcloud/inventories/sync', {})
  }

  syncWarehouse() {
    return this.http.post<ITrcloudGateway.SyncInventoryReport>('/services/trcloud/warehouses/sync', {})
  }

  patchInventory(id: string, input: ITrcloudGateway.PatchInventoryInput) {
    return this.http.patch<ITrcloudGateway.Inventory>(`/services/trcloud/inventories/${id}`, input)
  }

  patchWarehouse(id: string, input: ITrcloudGateway.PatchWarehouseInput) {
    return this.http.patch<ITrcloudGateway.SyncInventoryReport>(`/services/trcloud/warehouses/${id}`, input)
  }
}
