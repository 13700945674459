import { Directive, ElementRef, OnInit, Input } from '@angular/core'
import * as jwt_decode from 'jwt-decode'
import * as _ from 'lodash'

@Directive({
  selector: '[tmt-permission]',
})
export class PermissionDirective implements OnInit {
  current_user: any
  _option: any = {
    action: 'delete',
  }
  constructor(private el?: ElementRef) {
    try {
      const _token = JSON.parse(localStorage.getItem('access_token'))
      const user = jwt_decode(_token.access_token)
      this.current_user = user
    } catch (e) {
      // console.log(e)
      this.current_user = {
        user_role: 'unknown',
      }
    }
  }
  @Input('tmt-permission') permission: any

  @Input('tmt-permission-option') option: any

  ngOnInit() {
    this._option = _.extend({}, this._option, this.option)
    // // console.log(this.option);
    // console.log('permission1', this.permission)
    // console.log(this.current_user);
    if (!this.checkPermission(this.permission)) {
      switch (this._option.action) {
        case 'delete':
          this.el.nativeElement.remove()
          break
        case 'disabled':
          this.el.nativeElement.disabled = true
          break
        case 'disabled-pointerevent':
          this.el.nativeElement.style = 'pointer-events: none;'
          break
        default:
          this.el.nativeElement.remove()
          break
      }
    }
    // this.el.nativeElement.innerText = this.Service_Translate.translate(this.el.nativeElement.innerText, this.tmtTranslate);
  }

  checkPermission(permission: any[]) {
    if (location.pathname.includes('/system/')) {
      return permission.includes(this.current_user.user_role)
    } else {
      return true
    }

    // const check = permission.find((x) => x == this.current_user.user_role)
    // if (!check) {
    //   return false
    // } else {
    //   return true
    // }
  }
}
