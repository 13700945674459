import { Pipe, PipeTransform } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { CURRENT_PAGE } from '../interface/interface'
import { Etc } from '../util/etc'

@Pipe({
  name: 'langs',
})
export class LangsPipe implements PipeTransform {
  PageLink: CURRENT_PAGE[]

  _subscribe: any = null

  constructor(
    private store: Store<{ app_store: any }>,
    private _etc: Etc,
  ) {
    if (!this._subscribe) {
      this._subscribe = this.store.pipe(select('app_store', 'PAGE_LINK')).subscribe((res: CURRENT_PAGE[]) => {
        this.PageLink = res
      })
    }
  }

  transform(v: any, key: string) {
    // var lang = localStorage.getItem('lang');
    let lang = this._etc.getCurrentLang()
    lang = lang || 'lang1'
    if (typeof v == 'string') {
      const pageLink: CURRENT_PAGE = this.PageLink.find((x) => x.page == v)

      return pageLink ? pageLink[`${key}${lang}`] : key
    } else {
      return v[`${key}${lang}`]
    }
  }

  getLang() {
    let lang = this._etc.getCurrentLang()
    lang = lang || 'lang1'
    return lang
  }
}
