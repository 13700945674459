import { ICartState } from 'ketshopweb-sdk'

export namespace IPaymentGateway {
  export enum CardLocation {
    Credit = 'credit',
    Installment = 'installment',
  }

  export interface Installment {
    code: string
    term: number
  }

  export interface Card {
    name: string
    number: string
    expirationMonth: string
    expirationYear: string
    securityCode: string
  }

  export interface CreateOrderCredit {
    type: CardLocation
    model: ICartState.ICart
    installment?: Installment
    card: Card
    redirect: {
      href: string
      from: 'order' | 'sale-page'
    }
    just_mark?: boolean
    ordercode?: string
    channel?: any
  }

  export interface TokenResponse {
    tokens_url: string
    client_authorization: string
    model: {
      rememberCard: boolean
      card: {
        number: string
        expirationMonth: string
        expirationYear: string
        securityCode: string
        name: string
      }
    }
  }

  export enum CardType {
    VISA = 'VIS',
    MasterCard = 'MAS',
    JCB = 'JCB',
    AMEX = 'AME',
    UnionPay = 'UP',
  }

  export interface GbPrimepayTokenResponse {
    rememberCard: boolean
    card: {
      token: string
      number: string
      expirationMonth: string
      expirationYear: string
      name: string
      securityCode: null
      cardLocation: 'I' | 'D' // Card Location (D = Domestic / I = Inter)
      cardType: CardType // VIS = VISA, MAS = Master Card, JCB = JCB, AME = AMEX, UP = UnionPay
    }
  }

  export interface PaymentType {
    id: number
    name: string
    provider: string
    custom1?: any
  }

  export interface Confirm_PaymentType {
    bank: string
    bank_data: {
      account: string
      acnum: string
      code: string
      created_at: string
      id: number
      logo: string
      name: string
      show_acnum: number
      updated_at: string
    }
    date: string
    email: string
    lastname: string
    name: string
    ordercode: string
    picture: string
    remark: string
    tel: string
    time: string
    value: number
  }

  export interface PaymentRate {
    payment_fee: any
    payment_rate: any
    payment_rate_type: any
    cost_rate: any
    cost_rate_type: any
    cost_value: any
    margin_rate: any
    margin_rate_type: any
    margin_value: any
    warehouse_id: number
    payment_api_key: any
  }

  export interface InstallmentInput {
    installment?: {
      bankins: string
      monthins: number
    }
    ordercode: string
    return_url: string
  }

  export interface InstallmentResponse {
    amount: number
    form: {
      fields: {
        input_type: string
        name: string
        value: string
      }[]
      method: string
      url: string
    }
    message: string
    status: number
    reference_no: string
  }

  export interface Redirect {
    card?: {
      name: string
      number: string
      exp_month: string
      exp_year: string
      cvv: string
    }
    ordercode: string
    return_url: string
  }

  export interface Inquiry {
    is_postback: boolean
    ordercode: string
    reference_no: string
  }
}
