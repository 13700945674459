import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { ProductsEffects } from './effects'
import { ProductsFEATURE_KEY, ProductsReducer } from './reducer'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(ProductsFEATURE_KEY, ProductsReducer),
    EffectsModule.forFeature([ProductsEffects]),
  ],
})
export class ProductsSateModule {}
