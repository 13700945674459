import { Component, OnInit, Input } from '@angular/core'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzMessageService } from 'ng-zorro-antd/message'
import { ProductsService } from '@cms/services/products.service'
import { cloneDeep } from 'lodash'

@Component({
  selector: 'ket-modal-product-in-category',
  templateUrl: './modal-product-in-category.component.html',
  styleUrls: ['./modal-product-in-category.component.scss'],
})
export class KetModalProductInCategoryComponent implements OnInit {
  constructor(
    private modal: NzModalRef,
    private message: NzMessageService,
    private Service_Product: ProductsService,
  ) {}
  @Input() data: any
  @Input() selected: any = []
  currenttab = 0
  model: any = {
    lang: 'lang1',
    status: '',
    search: '',
    cate_id: '',
    group: '',
    page: 1,
    perpage: 10,
    ids: '',
    orderby: 'id',
    sort: 'desc',
  }

  model_add: any = {
    lang: 'lang1',
    status: '',
    search: '',
    cate_id: '',
    group: '',
    page: 1,
    perpage: 10,
    ids: '',
    orderby: 'id',
    sort: 'desc',
  }
  product_add: any = []
  total_add: any = 0
  selecteditem_add: any = []

  product: any = []
  total: any = 0

  model_delete: any = {
    lang: 'lang1',
    status: '',
    search: '',
    cate_id_nosub: '',
    group: '',
    page: 1,
    perpage: 10,
    ids: '',
    orderby: 'id',
    sort: 'desc',
  }
  product_delete: any = []
  total_delete: any = 0
  selecteditem_delete: any = []
  allcategory: any = []

  async ngOnInit() {
    this.allcategory = await this.Service_Product.productMainCategory()
    this.allcategory.unshift({
      id: -1,
      lang1: 'Uncategory',
      lang2: 'Uncategory',
      lang3: 'Uncategory',
      lang4: 'Uncategory',
    })
    this.allcategory.unshift({
      id: '',
      lang1: 'หมวดหมู่ทั้งหมด',
      lang2: 'All Category',
      lang3: 'All Category',
      lang4: 'All Category',
    })
    if (this.data) {
      this.model.cate_id_nosub = this.data.id
      this.model_delete.cate_id_nosub = this.data.id
      this.model_add.cate_not_in = this.data.id
    }
    this.selecteditem_add = cloneDeep(this.selected)
    this.search()
    this.search_add()
    // this.search_delete();
  }
  pagechage(page) {
    this.model.page = page
    this.search()
  } // tab1

  search() {
    this.Service_Product.searchProduct(this.model)
      .then((res: any) => {
        console.log(res)
        this.product = res.data
        this.total = res.count
      })
      .catch((data) => {
        console.log(data.error.message)
      })
  } // tab1

  pagechage_add(page) {
    this.model_add.page = page
    this.search_add()
  } // tab2

  search_add(clearpage = false) {
    if (clearpage) {
      this.model_add.page = 1
    }
    this.Service_Product.searchProduct(this.model_add)
      .then((res: any) => {
        console.log(res)
        this.product_add = res.data
        this.total_add = res.count
      })
      .catch((data) => {
        console.log(data.error.message)
      })
  } // tab2

  addProductToCate(obj) {
    this.selecteditem_add.push(obj)
  }
  deleteSelectedAdd(i) {
    this.selecteditem_add.splice(i, 1)
  }

  checkItemAdd(item) {
    return !this.selecteditem_add.find((el) => el.id == item.id)
  }
  ///////
  pagechage_delete(page) {
    this.model_delete.page = page
    this.search_delete()
  } // tab2

  search_delete() {
    this.Service_Product.searchProduct(this.model_delete)
      .then((res: any) => {
        console.log(res)
        this.product_delete = res.data
        this.total_delete = res.count
      })
      .catch((data) => {
        console.log(data.error.message)
      })
  } // tab2

  addProductRemoveFromCate(obj) {
    this.selecteditem_delete.push(obj)
  }

  deleteSelectedRemove(i) {
    this.selecteditem_delete.splice(i, 1)
  }

  checkItemDelete(item) {
    return !this.selecteditem_delete.find((el) => el.id == item.id)
  }

  ////////
  tabchange(e) {
    this.currenttab = e.index
  }

  async ok() {
    // console.log(this.selecteditem_add);
    this.modal.close(this.selecteditem_add)
    // console.log(this.selecteditem_delete);
    return
    const _add = this.selecteditem_add.map((data) => {
      return data.id
    })
    const _remove = this.selecteditem_delete.map((data) => {
      return data.id
    })

    if (this.selecteditem_add.length == 0 && this.selecteditem_delete.length == 0) {
      this.modal.close()
      return
    }
    const rs = {
      cate_id: this.data.id,
      add: _add,
      remove: _remove,
    }
    try {
      await this.Service_Product.updateProductToCategoryMultiple(rs)
      this.modal.close(true)
    } catch (e) {
      console.log(e)
      this.message.error(e.error.message, { nzDuration: 5000 })
    }

    return false
  }
}
