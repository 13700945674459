import { withdraw_html } from './../html_ts/withdraw_html'
import { order_html } from './../html_ts/order_html'
import { product_print_label } from './product_print_label'
import { qr_to_base64 } from './qr_to_base64'
import { format_money } from './format_money'
import { encode_ids } from './encode_ids'
import { product_export_label } from './product_export_label'
import { purchase_order } from '../html_ts/purchase_order/purchase_order'
import { qr_point_print_label } from './qr_point_print_label'
import { qr_point_export_label } from './qr_point_export_label'

export const ketshopweb_services_func = {
  product_print_label,
  product_export_label,
  order_html,
  qr_to_base64,
  withdraw_html,
  format_money,
  encode_ids,
  purchase_order,
  qr_point_print_label,
  qr_point_export_label,
}
