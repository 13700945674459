import { Component, OnInit, Input } from '@angular/core'
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal'
import { NzMessageService } from 'ng-zorro-antd/message'
import { ConfirmPaymentService } from '@cms/services/confirmpayment.service'
import { find, findIndex } from 'rxjs/operators'
import { CmsPaymentGateway } from 'libs/ketshopweb-services/payment-gateway.service'

@Component({
  selector: 'app-modal-edit-confirmpayment',
  templateUrl: './modal-edit-confirmpayment.component.html',
  styleUrls: ['./modal-edit-confirmpayment.component.css'],
})
export class ModalEditConfirmpaymentComponent implements OnInit {
  @Input() data: any
  model: any = {
    status: '',
  }
  allstatus: any = []
  old_status: any

  loading = true

  constructor(
    private modal: NzModalRef,
    private message: NzMessageService,
    private Service_ConfirmPayment: ConfirmPaymentService,
    private Service_Payment: CmsPaymentGateway,
    private nzModalService: NzModalService,
  ) {}

  async ngOnInit() {
    this.old_status = this.data.confirm.status
    this.allstatus = await this.Service_ConfirmPayment.confirmstatus()

    for (const obj of this.allstatus) {
      if (obj.status == -1) {
        obj.name = 'ไม่ผ่านการตรวจสอบ'
      } else if (obj.status == 1) {
        obj.name = 'ผ่านการตรวจสอบ'
      } else if (obj.status == 0) {
        obj.name = 'รอตรวจสอบ'
      }
    }

    this.Service_ConfirmPayment.byID(this.data.confirm.id)
      .then((res: any) => {
        this.model = res
        console.log(this.model)
      })
      .catch((data: any) => {
        console.log(data.error.message)
      })
    setTimeout(() => {
      this.loading = false
    }, 250)
  }

  check_slip() {
    this.loading = true
    this.Service_Payment.check_slip(this.data.confirm.id)
      .then((res: any) => {
        this.model.status = res.status
        this.model.updated_at = res.updated_at
        setTimeout(() => {
          this.loading = false
        }, 250)
      })
      .catch((err: any) => {
        console.log(err.error)
      })
  }

  ok() {
    console.log(this.model)
    $('.theme-loader').show()
    this.Service_ConfirmPayment.updateStatus(this.model)
      .then((res: any) => {
        // this.modal.close({ data: this.model })
        console.log(res)
        $('.theme-loader').hide()
        this.modal.close({ data: this.model })
        
      })
      .catch((data: any) => {
        $('.theme-loader').hide()
        this.message.error(`${data.error.message}`, { nzDuration: 6000 })
        return false
      })
    return false
  }

  viewOrder(code) {
    const url = window.location.origin + '/system/e-commerce/order-detail/' + code
    window.open(url, '_blank', 'menubar=no,toolbar=no,location=no,width=1280,height=720')
  }
}
