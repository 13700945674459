import {
  Directive,
  EventEmitter,
  Input,
  ChangeDetectorRef,
  Output,
  ElementRef,
  HostListener,
  Inject,
  Renderer2,
  Injector,
  Optional,
} from '@angular/core'
import { NgModel, NgControl } from '@angular/forms'

@Directive({
  selector: '[ket-trim]',
})
export class KetTrimDirective {
  @Input('ket-trim') ketTrim = true

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    @Optional() private ngModel: NgModel,
    @Optional() private ngControl: NgControl,
  ) {}

  @HostListener('blur')
  onBlur() {
    if (this.ketTrim) {
      let value = this.getValue()
      if (value) {
        value = value.trim()
        this.renderer.setProperty(this.elementRef.nativeElement, 'value', value)
        this.renderer.setAttribute(this.elementRef.nativeElement, 'value', value)
        this.setValue(value)
      } else {
        this.renderer.setProperty(this.elementRef.nativeElement, 'value', null)
        this.renderer.setAttribute(this.elementRef.nativeElement, 'value', null)
        this.setValue('')
      }
    }
  }

  getValue() {
    if (this.ngModel) return this.ngModel.model
    if (this.ngControl) return this.ngControl.control.value
    return this.elementRef.nativeElement.form
  }

  setValue(value) {
    if (this.ngModel) this.ngModel.update.emit(value)
    if (this.ngControl) this.ngControl.control.setValue(value)
  }
}
