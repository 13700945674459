import { Directive, ElementRef, OnInit, Input, AfterViewInit, OnChanges, SimpleChanges, Renderer2 } from '@angular/core'
import { TranslateService } from '@cms/services/translate.service'
@Directive({
  selector: '[tmt-translate]',
})
export class TranslateDirective implements OnInit, AfterViewInit, OnChanges {
  constructor(
    private el: ElementRef,
    private render2: Renderer2,
    private Service_Translate: TranslateService,
  ) {}

  @Input('tmt-translate') tmtTranslate: string

  text = ''
  input = ''

  ngOnInit() {}

  ngAfterViewInit() {
    this.setText()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tmtTranslate) {
      if (changes.tmtTranslate.previousValue !== undefined) {
        this.tmtTranslate = changes.tmtTranslate.currentValue
        this.setText()
      }
    }
  }

  setText() {
    if (this.el.nativeElement.nodeName.toUpperCase() === 'INPUT') {
      this.setPlaceholder()
      return
    }

    this.text = this.el.nativeElement.innerText
    if (!this.text) {
      this.text = this.el.nativeElement.innerHTML
    }
    if (!this.text) {
      this.text = this.el.nativeElement.text
    }
    this.el.nativeElement.innerText = ''
    const text = this.Service_Translate.translate(this.text, this.tmtTranslate)
    // this.render2.createText(text);
    this.el.nativeElement.textContent = text
  }

  setPlaceholder() {
    this.input = this.el.nativeElement.attributes.placeholder.value
    !this.input && (this.input = this.el.nativeElement.attributes.placeholder.textContent)
    !this.input && (this.input = this.el.nativeElement.attributes.placeholder.nodeValue)
    const regex = new RegExp(/[.]{3}/)
    let isAppendDots = false
    if (regex.test(this.input)) {
      isAppendDots = true
      this.input = this.input.replace(/[.]{3}/, '')
    }
    let input = this.Service_Translate.translate(this.input, this.tmtTranslate)
    input = isAppendDots ? input + '...' : input
    this.render2.setAttribute(this.el.nativeElement, 'placeholder', input)
  }
}
