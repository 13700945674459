import { NzEmptyModule } from 'ng-zorro-antd/empty'
import { NzPaginationModule } from 'ng-zorro-antd/pagination'
import { NzTagModule } from 'ng-zorro-antd/tag'
import { NzAvatarModule } from 'ng-zorro-antd/avatar'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { FormsModule } from '@angular/forms'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzListModule } from 'ng-zorro-antd/list'
import { NzTableModule } from 'ng-zorro-antd/table'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { KetModalProductInCategoryComponent } from './modal-product-in-category.component'
import { KetProductItemInlineModule } from '../ket-product-components/product-item-inline'

@NgModule({
  declarations: [KetModalProductInCategoryComponent],
  entryComponents: [KetModalProductInCategoryComponent],
  imports: [
    CommonModule,
    NzTableModule,
    NzListModule,
    NzInputModule,
    FormsModule,
    NzSelectModule,
    NzIconModule,
    NzAvatarModule,
    NzTagModule,
    NzPaginationModule,
    NzEmptyModule,

    KetProductItemInlineModule,
  ],
  exports: [KetModalProductInCategoryComponent],
})
export class KetModalProductInCategoryModule {}
