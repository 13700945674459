import { Component, OnInit, AfterViewInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { DeliveryService } from '@cms/services/delivery.service'
import { SettingService } from '@cms/services/setting.service'
import { NzModalService } from 'ng-zorro-antd/modal'
import { NzMessageService } from 'ng-zorro-antd/message'
import { AuthenService } from '@cms/services/authen.service'
import { environment } from 'src/environments/environment'
import { UtilService } from '@cms/services/util.service'

@Component({
  selector: 'app-delivery-view',
  templateUrl: './delivery-view.component.html',
  styleUrls: ['./delivery-view.component.css'],
})
export class DeliveryViewComponent implements OnInit {
  message_duration = 4000
  access_token: string
  api_path: string
  model: any = {}
  shippopImage: any = {}
  shippopName: any = {}
  constructor(
    private modal: NzModalService,
    private message: NzMessageService,
    private _authen: AuthenService,
    private activatedRoute: ActivatedRoute,
    private Service_Delivery: DeliveryService,
    private Service_Util: UtilService,
  ) {}

  async ngOnInit() {
    this.shippopName = await this.Service_Util.getShippopName()
    this.api_path = environment.api_path

    this.access_token = this._authen.getToken().access_token
    const trackcode = this.activatedRoute.snapshot.paramMap.get('trackcode')
    this.shippopImage = this.Service_Util.getShippopImage()
    this.Service_Delivery.searchDeliverybypass({ trackcode: trackcode })
      .then((res: any) => {
        console.log(res)
        this.model = res
      })
      .catch((data) => {})
      .finally(() => {
        $('.theme-loader').fadeOut()
      })
  }

  printDelivery() {
    $('form#order-deliveryprint').submit()
  }

  close() {
    window['close']()
  }
}
