import { h, Fragment } from 'preact'
import { CssGlobal } from './css_global'

export const OrderA48P = ({
  elements,
}: {
  elements: (
    | [
        h.JSX.Element,
        h.JSX.Element,
        h.JSX.Element,
        h.JSX.Element,
        h.JSX.Element,
        h.JSX.Element,
        h.JSX.Element,
        h.JSX.Element,
      ]
    | [h.JSX.Element]
  )[]
}) => (
  <Fragment>
    <CssGlobal />
    {elements.map(([a, b, c, d, e, f, g, j], i) => {
      return (
        <Fragment key={i}>
          <div class="page-a4-8-portrait">
            <div class="page-a4-8">{a}</div>
            {b && <div class="page-a4-8">{b}</div>}
            {c && <div class="page-a4-8">{c}</div>}
            {d && <div class="page-a4-8">{d}</div>}
            {e && <div class="page-a4-8">{e}</div>}
            {f && <div class="page-a4-8">{f}</div>}
            {g && <div class="page-a4-8">{g}</div>}
            {j && <div class="page-a4-8">{j}</div>}
          </div>
          <div class="pagebreak"></div>
        </Fragment>
      )
    })}
  </Fragment>
)
